import moment from "moment";
import { useState } from "react";

export function TableGamesRed({
    gamesForUser,
}) {
    const [codeToView, setCodeToView] = useState("LG")
    const lastTimeApproved = gamesForUser ? gamesForUser[0].lastTimeApproved.split(' ') : ""
    const lastHourApproved = lastTimeApproved ? lastTimeApproved[1] : "" 
    const lastDateApproved = lastTimeApproved ? lastTimeApproved[0] : ""
    return (
        <>
        <div style={{textAlign:'center'}}>
        <p style={{margin:'auto'}}>Esta Liga fue aprovada a las: {lastHourApproved != "" ? lastHourApproved : "Liga Sin Aprovar" } hs  - Fecha: {lastDateApproved != "" ? lastDateApproved : "Liga Sin Aprovar"}</p>
        </div>
        <table className="table table-hover team-tables-list align-middle">
           
            <tbody>
                
                <tr>
                    <th scope="col">Hora</th>
                    <th scope="col">Team</th>
                    <th scope="col">ML</th>
                    <th scope="col">RL/T</th>
                    <th scope="col">H1 RL/T</th>
                    <th>Total</th>
                    <th>P Mas</th>
                    <th>P menos</th>
                    <th>Solo </th>
                    <th>Solo (+)</th>
                    <th>Solo (-)</th>
                </tr>
            </tbody>
            <tbody id="bodyPlayToday">
                {
                    gamesForUser?.map((game, index) => (
                        <tr key={index}>
                            <th scope="row" style={{
                                textAlign: 'center',
                            }}>
                                {moment(game.time).tz('America/New_York').format('hh:mm A')}
                            </th>
                            <th>
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center gap-2">
                                        {
                                            game.localTeam?.image && (
                                                <img
                                                    src={game.localTeam?.image}
                                                    alt={game.localTeam?.name}
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        marginRight: '5px',
                                                        marginBottom: '5px'
                                                    }}

                                                />
                                            )
                                        }
                                        {game.manual ? `${game.localTeam.name}(M)` : game.localTeam?.name}
                                        {' - '}
                                        {
                                            codeToView === "RD" ? game.localTeam.codeRD : game.localTeam.code ? game.localTeam.code : game.localTeam.codeRD
                                        }
                                    </div>
                                    {/* <br /> */}
                                    <div className="d-flex align-items-center gap-2">
                                        {
                                            game.visitingTeam?.image && (
                                                <img
                                                    src={game.visitingTeam?.image}
                                                    alt={game.visitingTeam?.name}
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        marginRight: '5px'
                                                    }}
                                                />
                                            )
                                        }
                                        {game.manual ? `${game.visitingTeam.name}(M)` : game.visitingTeam?.name}
                                        {' - '}
                                        {codeToView === "RD" ? game.visitingTeam.codeRD : game.visitingTeam.code ? game.visitingTeam.code : game.visitingTeam.codeRD}
                                    </div>
                                </div>
                            </th>
                            {/* <td>
                            {game.localTeam?.name}
                            <br />
                            {game.visitingTeam?.name}
                        </td> */}
                            <td>
                                <span
                                    style={{
                                        color: game.localLine?.moneyLine?.value && game.localLine?.moneyLine?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.localLine?.moneyLine?.value || '-'}
                                </span>
                                <br />
                                <span style={{
                                    color: game.localLine?.moneyLine?.value && game.localLine?.moneyLine?.value < 0 ? '#32a852' : '#a83232'
                                }}
                                >
                                    {game.visitingLine?.moneyLine?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.localLine?.gavela?.value && game.localLine?.gavela?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.localLine?.gavela?.value || '-'}
                                    {game.localLine?.price?.value || '-'}
                                </span>
                                <br />
                                <span
                                    style={{
                                        color: game.visitingLine?.gavela?.value && game.visitingLine?.gavela?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.visitingLine?.gavela?.value || '-'}
                                    {game.visitingLine?.price?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.localLine?.h1RlGavela?.value && game.localLine?.h1RlGavela?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.localLine?.h1RlGavela.value || '-'}
                                    {game.localLine?.h1Rl.value || '-'}
                                </span>
                                <br />
                                <span
                                    style={{
                                        color: game.visitingLine?.h1RlGavela?.value && game.visitingLine?.h1RlGavela?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.visitingLine?.h1RlGavela?.value || '-'}
                                    {game.visitingLine?.h1Rl?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.localLine?.total?.value && game.localLine?.total?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.localLine?.total?.value || '-'}
                                </span>
                                <br />
                                <span
                                    style={{
                                        color: game.visitingLine?.total?.value && game.visitingLine?.total?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.visitingLine?.total?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.localLine?.posPlus?.value && game.localLine?.posPlus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.localLine?.posPlus?.value || '-'}
                                </span>
                                <br />
                                <span
                                    style={{
                                        color: game.visitingLine?.posPlus?.value && game.visitingLine?.posPlus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.visitingLine?.posPlus?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.localLine?.posMinus?.value && game.localLine?.posMinus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.localLine?.posMinus?.value || '-'}
                                </span>
                                <br />
                                <span
                                    style={{
                                        color: game.visitingLine?.posMinus?.value && game.visitingLine?.posMinus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.visitingLine?.posMinus?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.localLine?.negPlus?.value && game.localLine?.negPlus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.localLine?.single?.value || '-'}
                                </span>
                                <br />
                                <span
                                    style={{
                                        color: game.visitingLine?.negPlus?.value && game.visitingLine?.negPlus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.visitingLine?.single?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.localLine?.negMinus?.value && game.localLine?.negMinus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.localLine?.singlePlus?.value || '-'}
                                </span>
                                <br />
                                <span
                                    style={{
                                        color: game.visitingLine?.negMinus?.value && game.visitingLine?.negMinus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.visitingLine?.singlePlus?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.localLine?.singleMinus?.value && game.localLine?.singleMinus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.localLine?.singleMinus?.value || '-'}
                                </span>
                                <br />
                                <span
                                    style={{
                                        color: game.visitingLine?.singleMinus?.value && game.visitingLine?.singleMinus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.visitingLine?.singleMinus?.value || '-'}
                                </span>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
        </>

    )
}
