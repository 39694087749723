import { createContext, useEffect, useRef, useState } from "react";
import { redApi } from "../../../../../config/Axios";
import { getTerminals } from "../../../../../utils/getUsers";
import moment from 'moment';


export const ReportsContext = createContext();

export const ReportsProvider = ({ children }) => {

    const mountedRef = useRef(true);
    const [terminals, setTerminals] = useState([]);
    const [consortiums, setConsortiums] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);

    const [monitor, setMonitor] = useState([]);
    const [earLost, setEarLost] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [IngEgr, setIngEgr] = useState([]);
    const [ventas, setVentas] = useState([]);
    const [games, setGames] = useState([]);

    const[group, setGroup] = useState([]);

    const fetchAllDataPromise = async ()=> {
        const dataToday =  moment().format('YYYY-MM-DD');

        return new Promise(async (resolve, reject) => {
            try {

                const { data: posData } = await redApi.get("/users?userLevel=3&populates=ownerOf");
                const { data : monitors} = await redApi.get(`/reports/getmonitor?date=${dataToday}&pos=&consortium=`)
                const { data: consorciosData } = await redApi.get("/users?userLevel=2");
                const {data : leagues}  = await redApi.get("/leagues");
                const { data : earn } = await redApi.get("/EarnLosses", { params: { initDate: dataToday, finalDate:  "", pos: "", consorcio: "",}});
                const { data : account  } =  await redApi.get(`/accountings`);
                const { data : IngEgrs  } = await redApi.get(`/accountings/transHist?initDate=${dataToday}`);
                const {data : venta } = await redApi.get(`/reports/getventagral`);
                const {data : games } = await redApi.get(`/games?date=${dataToday}&league=&team=&estatus=`);
                // const { data: group } = await redApi.get(`users/sendTicketGR?date=${dataToday}`);
                const { data: group } = await redApi.get('users/sendTicketGR');

                setTerminals(posData);
                setMonitor(monitors);
                setConsortiums(consorciosData);
                setLeagues(leagues);
                if (leagues[0] && leagues[0]._id) {
                    await getTeams(leagues[0]._id);
                };
                setEarLost(earn);
                setAccounts(account);
                setIngEgr(IngEgrs);
                setVentas(venta);
                setGames(games);
                setGroup(group);

                resolve();
            } catch (error) {
                console.error('Error al cargar datos:', error);
                setLoading(true);
                reject(error); 
            }

        });



    };

const fetchAllData = async () => {
    useEffect(() => {
        fetchAllDataPromise().then(()=>{
            setLoading(false)
        })        
        .catch((error) => {
            console.log(error)
            console.error('Error al cargar datos:', error);
        })

    }, []);
}


    const getTerminalsByOwner = async (owner) => {
        const terminalsData = await getTerminals(owner);
        if (!mountedRef.current) return;
        setTerminals(terminalsData);
    };
    const getLeagues = async () => {
        const res = await redApi.get("/leagues");


        setLeagues(res.data);
        if (res.data[0] && res.data[0]._id) {
            await getTeams(res.data[0]._id);
        }
    };
    const getTeams = async (leagueId) => {
        const res = await redApi.get(`/teams?league=${leagueId}`);
        if (!mountedRef.current) return;
        setTeams(res.data);
    };
    const resetTeams = () => setTeams([])

    return (
        <ReportsContext.Provider
            value={{
                terminals,
                consortiums,
                leagues,
                teams,
                monitor,
                loading,
                accounts,
                IngEgr,
                earLost,
                games,
                ventas,
                setLoading,
                resetTeams,
                getTeams,
                getLeagues,
                getTerminalsByOwner,
                fetchAllData,
                group
            }}
        >
            {children}
        </ReportsContext.Provider>
    );
};