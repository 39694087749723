import React from 'react'

function Futbol_Soccer() {
  return (
    <div className="rules-sport">
      <h1>
        Futbol (Soccer)
      </h1>
      <div>
        <span>
          All plays are valid under the final result of the game after 90 minutes, plus the replacement time, neither overtime (extra time) nor the golden goal are taken into account.
        </span>
        <p>
          There are two options or modalities for the plays of soccer (soccer) which are:
        </p>
        <div>
          <h2>
            OPTION A (MONEY LINE)
          </h2>
          <p>
            Which means you can play team “A” team “B” or “TIE”
            * Under this modality, if you bet on the team (A or B) and if there is a tie in the final result, the play is losing; in case of a tie only the plays on the tie option will be winners.
          </p>
          <span>
            Example BRAZIL vs MEXICO <br />
            BRASIL -125<br />
            MEXICO +175 2.5 GOALS<br />
            EMPATE +220<br />
          </span>
          <p>
            It means that if the final result is (1-1) all bets on Brazil or Mexico are considered losers, only the one who played a draw is the winner. Also the result was given to less, therefore, all plays to less are also winners.
          </p>
          <p>
            The result of the match is decided after 90 minutes of play plus the replacement time. Neither overtime (extra time) nor the golden goal are taken into account to decide the plays on football. This replacement time considered by the referees is part of the 90 minutes of play.
          </p>
          <span>
            If a game is abandoned or postponed, all moves are void.
          </span>
          <p>
            The plays to (+) or (-) will be considered valid under the final result after 90 minutes of play plus the replacement time. Neither overtime (extra time) nor the golden goal are taken into account to decide the plays on football.
          </p>
        </div>
        <div>
          <h2>
            OPTION B (RUN LINE)
          </h2>
          <p>
            Under this modality you give or take goals according to your preference.
            The result of the match is decided after 90 minutes of play plus the replacement time.
            Neither overtime (overtime) nor the golden goal are taken into account in deciding plays on football unless otherwise stipulated.
          </p>
          <span>
            If a game is abandoned or postponed, all moves are void.
          </span>
          <p>
            The plays to (+) or (-) will be considered valid under the final result after 90 minutes of play plus the replacement time. Neither overtime (extra time) nor the golden goal are taken into account to decide the plays on football.
          </p>
          <p>
            See an example: <br />
            Imagine a game Brazil vs Mexico (see line below) and the game after having finished the 90 minutes plus the time considered by the referees, is 1-1: for the plays on option A (money line) the result of the plays for those who played Brazil or Mexico the play is LOSING, and those who played the draw WINNER. For option B (run line) those who played Mexico WON the play because in this example Mexico takes 1/2 goal, likewise those who played Brazil LOSE their play because it is giving 1/2 goal.
          </p>
          <p>
            The race is valid with the final result of the game after 90 minutes plus what the referees add. Neither overtime (extra time) nor the golden goal are taken into account to decide the plays on football.
          </p>
          <table>
            <thead>
              <tr>
                <th>
                  TEAMS
                </th>
                <th>
                  MONEY LINE
                </th>
                <th>
                  RUN LINE
                </th>
                <th>
                  TOTAL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  BRASIL
                </td>
                <td>
                  -130
                </td>
                <td>
                  -1/2 110
                </td>
                <td>
                  2.5 over 120
                </td>
              </tr>
              <tr>
                <td>
                  MEXICO
                </td>
                <td>
                  +280
                </td>
                <td>
                  +1/2 110
                </td>
                <td>

                </td>
              </tr>
              <tr>
                <td>
                  EMPATE
                </td>
                <td>
                  +400
                </td>
                <td>

                </td>
                <td>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h2>
            ASIAN HANDICAP
          </h2>
          <p>
            “Handicap” (igual que el “spred” o Run Line en los deportes estadounidenses) significa una ventaja ficticia dada a uno de los equipos (por defecto, el que NO es favorito), virtualmente ganando el juego por 0.25 de Gol, 0.5 de Gol,  0.75 de Gol, 1 Gol, 1.5 de Gol, etc. El juagdor debe, ya sea respaldar al favorito para vencer esta desventaja o al equipo contrario para sostenerla.
          </p>
          <p>
            The default handicap could also be (zero (0) Goals), when both teams are estimated to be more or less equal.
          </p>
          <p>
            The Asian handicap play is resolved by adding the handicap to the game result.
          </p>
          <p>
            For example, if Brazil has received (+0.5) the goal advantage against Mexico (-0.5) and the game ends with a draw, the move to Brazil wins by half a goal. The plays to Mexico would win only if they win the match, no matter with which result.
          </p>
          <p>
            When we have -1.5 handicap, the favorite must win the game by at least 2 goals, otherwise the NO Favorite (+1.5) is winning by half or more goals. For (0, 1 and 2) handicap goals, the result of the play could be a virtual tie and in this case all plays are reimbursed. Another example, if Brazil is at least one goal from Mexico and wins by exactly 1 goal, the result of the Asian handicap is a tie and all the plays are a tie.
            DIVIDED HANDICAP (QUARTER BALL)
          </p>
        </div>
      </div>
    </div>
  )
}

export default Futbol_Soccer