import React, { useEffect, useState } from "react";
import moment from "moment";

// export const HyLClock = () => {
//   const [localTime, setLocalTime] = useState("--:--:--");

//   useEffect(() => {
//     let interval = setTimeout(() => {
//       setLocalTime(moment().format("HH:mm:ss"));
//     }, 1000);
//     return () => clearInterval(interval);
//   }, [])
  
//   return <label className="server_time_date">{localTime}</label>;
// };

export const HyLClock = () => {
  const [localTime, setLocalTime] = useState("--:--:--");

  useEffect(() => {
    const interval = setInterval(() => {
      setLocalTime(moment().format("HH:mm:ss"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <label className="server_time_date">{localTime}</label>;
};