import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { redApi } from "../../config/Axios";

// [
//     {
//         "username": "mnb",
//         "name": null,
//         "terminalData": [
//             {
//                 "terminal": "solos",
//                 "ticketsFound": 0,
//                 "betsFound": 0,
//                 "gamesFound": 0,
//                 "games": [],
//                 "playTickets": [],
//                 "winnerTickets": [],
//                 "losserTickets": [],
//                 "ifWinnerTickets": [],
//                 "cancelTickets": [],
//                 "sellAmount": 0,
//                 "payAmount": 0
//             },
//             {
//                 "terminal": "terminalmnb",
//                 "ticketsFound": 1,
//                 "betsFound": 1,
//                 "gamesFound": 1,
//                 "games": [
//                     {
//                         "_id": "649ae24b4fcb3758968b552f",
//                         "time": "2023-06-27T23:05:00.000Z",
//                         "league": "6447e2b729d06c3563c0f5e1",
//                         "status": "approved",
//                         "localTeam": {
//                             "_id": "6447e2b729d06c3563c0f62d",
//                             "code": 4010,
//                             "codeRD": 4026,
//                             "name": "Padres",
//                             "alias": "4026 Padres",
//                             "createdBy": "6442250035eafd3d735b19a8",
//                             "league": [
//                                 "6447e2b729d06c3563c0f5e1"
//                             ],
//                             "image": "https://s3.amazonaws.com/tombolasports/uploads/team/image/84c4edb3-b71f-4cd5-89f2-f4ced60f7f53/thumb_san_diego_padres.png",
//                             "createdAt": "2023-04-25T14:24:55.959Z",
//                             "updatedAt": "2023-04-27T14:19:05.148Z",
//                             "__v": 0,
//                             "abb": "SD"
//                         },
//                         "visitingTeam": {
//                             "_id": "6447e2b729d06c3563c0f5f1",
//                             "code": 4009,
//                             "codeRD": 4017,
//                             "name": "Pirates",
//                             "alias": "4017 Pirates",
//                             "createdBy": "6442250035eafd3d735b19a8",
//                             "league": [
//                                 "6447e2b729d06c3563c0f5e1"
//                             ],
//                             "image": "https://s3.amazonaws.com/tombolasports/uploads/team/image/614a9cb8-e18a-4780-96fe-4b3464a162b2/thumb_Pittsburgh_Pirates_MLB_Logo.svg.png",
//                             "createdAt": "2023-04-25T14:24:55.361Z",
//                             "updatedAt": "2023-04-27T14:19:03.980Z",
//                             "__v": 0,
//                             "abb": "PIT"
//                         },
//                         "localLine": "649ae24b4fcb3758968b552b",
//                         "visitingLine": "649ae24b4fcb3758968b552d",
//                         "winnerLine": null,
//                         "winnerTeam": null,
//                         "winType": null,
//                         "createdAt": "2023-06-27T13:21:15.952Z",
//                         "updatedAt": "2023-06-27T13:22:36.390Z",
//                         "__v": 0,
//                         "ticketsCounter": 1
//                     }
//                 ],
//                 "playTickets": [
//                     {
//                         "_id": "649b3192481c72f73c9c6d04",
//                         "ticketId": 392,
//                         "amount": 20,
//                         "amountIf": 0,
//                         "toWinIf": 0,
//                         "toWin": 11,
//                         "toCollect": 31,
//                         "bets": [
//                             {
//                                 "_id": "649b3194481c72f73c9c6d11",
//                                 "teamCode": 4010,
//                                 "teamAlias": "4026 Padres",
//                                 "betState": "Play",
//                                 "ticketState": "Play",
//                                 "playType": "M",
//                                 "league": {
//                                     "_id": "6447e2b729d06c3563c0f5e1",
//                                     "name": "MLB"
//                                 },
//                                 "game": {
//                                     "_id": "649ae24b4fcb3758968b552f",
//                                     "time": "2023-06-27T23:05:00.000Z",
//                                     "league": "6447e2b729d06c3563c0f5e1",
//                                     "status": "approved",
//                                     "localTeam": {
//                                         "_id": "6447e2b729d06c3563c0f62d",
//                                         "code": 4010,
//                                         "codeRD": 4026,
//                                         "name": "Padres",
//                                         "alias": "4026 Padres",
//                                         "createdBy": "6442250035eafd3d735b19a8",
//                                         "league": [
//                                             "6447e2b729d06c3563c0f5e1"
//                                         ],
//                                         "image": "https://s3.amazonaws.com/tombolasports/uploads/team/image/84c4edb3-b71f-4cd5-89f2-f4ced60f7f53/thumb_san_diego_padres.png",
//                                         "createdAt": "2023-04-25T14:24:55.959Z",
//                                         "updatedAt": "2023-04-27T14:19:05.148Z",
//                                         "__v": 0,
//                                         "abb": "SD"
//                                     },
//                                     "visitingTeam": {
//                                         "_id": "6447e2b729d06c3563c0f5f1",
//                                         "code": 4009,
//                                         "codeRD": 4017,
//                                         "name": "Pirates",
//                                         "alias": "4017 Pirates",
//                                         "createdBy": "6442250035eafd3d735b19a8",
//                                         "league": [
//                                             "6447e2b729d06c3563c0f5e1"
//                                         ],
//                                         "image": "https://s3.amazonaws.com/tombolasports/uploads/team/image/614a9cb8-e18a-4780-96fe-4b3464a162b2/thumb_Pittsburgh_Pirates_MLB_Logo.svg.png",
//                                         "createdAt": "2023-04-25T14:24:55.361Z",
//                                         "updatedAt": "2023-04-27T14:19:03.980Z",
//                                         "__v": 0,
//                                         "abb": "PIT"
//                                     },
//                                     "localLine": "649ae24b4fcb3758968b552b",
//                                     "visitingLine": "649ae24b4fcb3758968b552d",
//                                     "winnerLine": null,
//                                     "winnerTeam": null,
//                                     "winType": null,
//                                     "createdAt": "2023-06-27T13:21:15.952Z",
//                                     "updatedAt": "2023-06-27T13:22:36.390Z",
//                                     "__v": 0,
//                                     "ticketsCounter": 1
//                                 },
//                                 "line": "649ae24b4fcb3758968b552b",
//                                 "team": "6447e2b729d06c3563c0f62d",
//                                 "spread": "",
//                                 "price": "-190",
//                                 "isIf": false,
//                                 "ticket": "649b3192481c72f73c9c6d04",
//                                 "createdAt": "2023-06-27T18:59:32.346Z",
//                                 "updatedAt": "2023-06-27T18:59:32.346Z",
//                                 "__v": 0
//                             }
//                         ],
//                         "betsIf": [],
//                         "createdBy": "647e00aaa074f010556f2266",
//                         "loggedBy": "647e00aaa074f010556f2266",
//                         "ticketState": "Play",
//                         "payState": "No",
//                         "ticketType": "Directo",
//                         "createdAt": "2023-06-27T18:59:33.273Z",
//                         "updatedAt": "2023-06-27T18:59:33.273Z",
//                         "__v": 0
//                     }
//                 ],
//                 "winnerTickets": [],
//                 "losserTickets": [],
//                 "ifWinnerTickets": [],
//                 "cancelTickets": [],
//                 "sellAmount": 20,
//                 "payAmount": 0
//             },
//             {
//                 "terminal": "juan",
//                 "ticketsFound": 0,
//                 "betsFound": 0,
//                 "gamesFound": 0,
//                 "games": [],
//                 "playTickets": [],
//                 "winnerTickets": [],
//                 "losserTickets": [],
//                 "ifWinnerTickets": [],
//                 "cancelTickets": [],
//                 "sellAmount": 0,
//                 "payAmount": 0
//             }
//         ]
//     },
//     {
//         "username": "test",
//         "name": null,
//         "terminalData": [
//             {
//                 "terminal": "terminaltest",
//                 "ticketsFound": 0,
//                 "betsFound": 0,
//                 "gamesFound": 0,
//                 "games": [],
//                 "playTickets": [],
//                 "winnerTickets": [],
//                 "losserTickets": [],
//                 "ifWinnerTickets": [],
//                 "cancelTickets": [],
//                 "sellAmount": 0,
//                 "payAmount": 0
//             },
//             {
//                 "terminal": "ron",
//                 "ticketsFound": 0,
//                 "betsFound": 0,
//                 "gamesFound": 0,
//                 "games": [],
//                 "playTickets": [],
//                 "winnerTickets": [],
//                 "losserTickets": [],
//                 "ifWinnerTickets": [],
//                 "cancelTickets": [],
//                 "sellAmount": 0,
//                 "payAmount": 0
//             },
//             {
//                 "terminal": "tes3",
//                 "ticketsFound": 0,
//                 "betsFound": 0,
//                 "gamesFound": 0,
//                 "games": [],
//                 "playTickets": [],
//                 "winnerTickets": [],
//                 "losserTickets": [],
//                 "ifWinnerTickets": [],
//                 "cancelTickets": [],
//                 "sellAmount": 0,
//                 "payAmount": 0
//             }
//         ]
//     },
//     {
//         "username": "admin",
//         "name": "admin",
//         "terminalData": [
//             {
//                 "terminal": "flaco",
//                 "ticketsFound": 0,
//                 "betsFound": 0,
//                 "gamesFound": 0,
//                 "games": [],
//                 "playTickets": [],
//                 "winnerTickets": [],
//                 "losserTickets": [],
//                 "ifWinnerTickets": [],
//                 "cancelTickets": [],
//                 "sellAmount": 0,
//                 "payAmount": 0
//             },
//             {
//                 "terminal": "testterminal",
//                 "ticketsFound": 0,
//                 "betsFound": 0,
//                 "gamesFound": 0,
//                 "games": [],
//                 "playTickets": [],
//                 "winnerTickets": [],
//                 "losserTickets": [],
//                 "ifWinnerTickets": [],
//                 "cancelTickets": [],
//                 "sellAmount": 0,
//                 "payAmount": 0
//             }
//         ]
//     }
// ]
const PrintResumen = ({
    userId,
    isPrint,
    summary,
    setIsPrinting

}) => {


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => setIsPrinting(false)
    });

    useEffect(() => {
        if (isPrint) {
            handlePrint();
        }
    }, [isPrint]);

    return (
        <div
            style={{ display: "none" }}
        >
            <div ref={componentRef}>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="ticket-header" >
                                <img src="/img/sports-logo-.png" alt="Sports" className={"filter-img"} />
                                <br></br>
                                <h3 style={{ fontWeight: "20.2vm" }}>{moment().format("DD[/]MMM[/]YYYY hh:mm A")}</h3>
                            </div>
                            {
                                summary.map((cons, index) => (
                                    <div className="ticket-body">
                                        <div className="row">
                                            {/* <div className="col-12 col-md-6"
                                                style={{
                                                    textAlign: "left",
                                                    fontSize: "1.5rem",
                                                    fontWeight: "bold",
                                                    display: "flex",
                                                    justifyContent: "left",
                                                    alignItems: "left",
                                                    backgroundColor: "#202020",
                                                    width: "75%",
                                                    color: "#fff",
                                                }}
                                            >
                                                {
                                                    cons.username &&
                                                    <>
                                                        <strong>
                                                            Consorcio:
                                                        </strong>
                                                        <span style={{ marginLeft: "1rem", textTransform: "uppercase" }}>
                                                            {cons.username}
                                                        </span>
                                                    </>

                                                }
                                            </div> */}
                                        </div>

                                        <div>
                                            {
                                                cons.terminalData.map((terminal, index) => (
                                                    <>
                                                        <div className="row"
                                                            style={{
                                                                marginBottom: "1rem",
                                                                borderBottom: "2px  #000 point",
                                                            }}
                                                        >
                                                            <div className="col-12" style={{
                                                                backgroundColor: "#202020",
                                                                color: "#fff",

                                                            }}>
                                                                <div className="row">
                                                                    <div className="col-12"
                                                                        style={{
                                                                            textAlign: "center",
                                                                            fontSize: "1.1rem",
                                                                            fontWeight: "bold",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <strong>Terminal:</strong>
                                                                        <span style={{ marginLeft: "1rem", textTransform: "uppercase" }}>
                                                                            {
                                                                                terminal.terminal
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-6"
                                                                        style={{
                                                                            textAlign: "left",
                                                                            fontSize: "1.0rem",
                                                                            fontWeight: "bold",
                                                                            display: "flex",
                                                                            justifyContent: "left",
                                                                            alignItems: "left",
                                                                        }}
                                                                    >
                                                                        <strong>Tickets:</strong>
                                                                        <span style={{ marginLeft: "1rem", textTransform: "uppercase" }}>
                                                                            {terminal.ticketsFound}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-6"
                                                                        style={{
                                                                            textAlign: "left",
                                                                            fontSize: "1.0rem",
                                                                            fontWeight: "bold",
                                                                            display: "flex",
                                                                            justifyContent: "left",
                                                                            alignItems: "left",
                                                                        }}
                                                                    >
                                                                        <strong>Bets:</strong>
                                                                        <span style={{ marginLeft: "0.5rem", textTransform: "uppercase" }}>
                                                                            {terminal.betsFound}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-6"
                                                                        style={{
                                                                            textAlign: "left",
                                                                            fontSize: "1.0rem",
                                                                            fontWeight: "bold",
                                                                            display: "flex",
                                                                            justifyContent: "left",
                                                                            alignItems: "left",
                                                                        }}
                                                                    >
                                                                        <strong>Ventas:</strong>
                                                                        <span style={{ marginLeft: "1rem", textTransform: "uppercase" }}>
                                                                            {terminal.sellAmount}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-6"
                                                                        style={{
                                                                            textAlign: "left",
                                                                            fontSize: "1.0rem",
                                                                            fontWeight: "bold",
                                                                            display: "flex",
                                                                            justifyContent: "left",
                                                                            alignItems: "left",
                                                                        }}
                                                                    >
                                                                        <strong>Pagos:</strong>
                                                                        <span style={{ marginLeft: "0.5rem", textTransform: "uppercase" }}>
                                                                            {terminal.payAmount}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-6"
                                                                        style={{
                                                                            textAlign: "left",
                                                                            fontSize: "1.0rem",
                                                                            fontWeight: "bold",
                                                                            display: "flex",
                                                                            justifyContent: "left",
                                                                            alignItems: "left",
                                                                        }}
                                                                    >
                                                                        <strong>Games:</strong>
                                                                        <span style={{ marginLeft: "1rem", textTransform: "uppercase" }}>
                                                                            {terminal.gamesFound}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div style={{
                                                                    textAlign: "center",
                                                                    fontSize: "1.1rem",
                                                                    marginTop: "1rem",
                                                                }}>
                                                                    <strong>Tickets Pendientes</strong>
                                                                </div>
                                                                <div className="row" style={{ width: "100%" }}>
                                                                    <div className="col-12 col-md-6" style={{
                                                                        textAlign: "left",
                                                                        fontSize: "1.1rem",
                                                                        fontWeight: "bold",
                                                                        display: "flex",
                                                                        justifyContent: "left",
                                                                        alignItems: "left",
                                                                        backgroundColor: "#202020",
                                                                        width: "100%",
                                                                        color: "#fff",
                                                                    }}>
                                                                        {/* <strong>Apuestas</strong> */}

                                                                    </div>
                                                                </div>
                                                                <table
                                                                    style={{
                                                                        // width: "75vw",
                                                                        textAlign: "left",
                                                                        fontSize: "0.8rem",
                                                                        marginTop: "0.8rem",
                                                                        borderCollapse: "collapse",
                                                                        border: "1px solid #202020",
                                                                    }}
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                <strong>Ticket</strong>
                                                                            </th>
                                                                            <th>
                                                                                <strong>Venta</strong>
                                                                            </th>
                                                                            {/* <th>
                                                                                <strong>Venta IF</strong>
                                                                            </th> */}
                                                                            <th>
                                                                                <strong>A ganar</strong>
                                                                            </th>
                                                                            {/* <th>
                                                                                <strong>A ganar IF</strong>
                                                                            </th> */}
                                                                            <th>
                                                                                <strong>Pago</strong>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            terminal.playTickets.length ? terminal.playTickets.map((ticket, index) => (
                                                                                <tr className="col-12" key={index}>
                                                                                    <td>
                                                                                        <strong>#{ticket.ticketId}</strong>
                                                                                    </td>
                                                                                    <td>
                                                                                        <strong>${ticket.amount}</strong>
                                                                                    </td>
                                                                                    {/* <td>
                                                                                        ${ticket.amountIf}
                                                                                    </td> */}
                                                                                    <td>
                                                                                        <strong>${ticket.toWin}</strong>
                                                                                    </td>
                                                                                    {/* <td>
                                                                                        ${ticket.toWinIf}
                                                                                    </td> */}
                                                                                    <td>
                                                                                        <strong>${ticket.toCollect}</strong>
                                                                                    </td>
                                                                                </tr>
                                                                            )) : (
                                                                                <tr>
                                                                                    <td colSpan="6">
                                                                                        Esta terminal no tiene tickets en juego
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div style={{
                                                                textAlign: "center",
                                                                fontSize: "1.1rem",
                                                                marginTop: "1rem",
                                                            }}>
                                                                <strong>Tickets Ganadores</strong>
                                                            </div>
                                                            <div className="col-12">
                                                                <table
                                                                    style={{
                                                                        // width: "75vw",
                                                                        textAlign: "left",
                                                                        fontSize: "0.8rem",
                                                                        marginTop: "0.8rem",
                                                                        borderCollapse: "collapse",
                                                                        border: "1px solid #202020",
                                                                    }}
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                <strong>Ticket</strong>
                                                                            </th>
                                                                            <th>
                                                                                <strong>Venta</strong>
                                                                            </th>
                                                                            {/* <th>
                                                                                <strong>Venta IF</strong>
                                                                            </th> */}
                                                                            <th>
                                                                                <strong>A ganar</strong>
                                                                            </th>
                                                                            {/* <th>
                                                                                <strong>A ganar IF</strong>
                                                                            </th> */}
                                                                            <th>
                                                                                <strong>Pago</strong>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            terminal.winnerTickets.length ? terminal.winnerTickets.map((ticket, index) => (
                                                                                <tr className="col-12" key={index}>
                                                                                    <td>
                                                                                        <strong>#{ticket.ticketId}</strong>
                                                                                    </td>
                                                                                    <td>
                                                                                        <strong>${ticket.amount}</strong>

                                                                                    </td>
                                                                                    {/* <td>
                                                                                        ${ticket.amountIf}
                                                                                    </td> */}
                                                                                    <td>
                                                                                        <strong>${ticket.toWin}</strong>

                                                                                    </td>
                                                                                    {/* <td>
                                                                                        ${ticket.toWinIf}
                                                                                    </td> */}
                                                                                    <td>
                                                                                        <strong>${ticket.toCollect}</strong>

                                                                                    </td>
                                                                                </tr>
                                                                            )) : (
                                                                                <tr>
                                                                                    <td colSpan="6">
                                                                                        Esta terminal no tiene tickets ganadores
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div style={{
                                                                textAlign: "center",
                                                                fontSize: "1.1rem",
                                                                marginTop: "1rem",
                                                            }}>
                                                                <strong>Tickets Cancelados</strong>
                                                            </div>
                                                            <div className="col-12">
                                                                <table
                                                                    style={{
                                                                        // width: "75vw",
                                                                        textAlign: "left",
                                                                        fontSize: "0.8rem",
                                                                        marginTop: "0.8rem",
                                                                        borderCollapse: "collapse",
                                                                        border: "1px solid #202020",
                                                                    }}
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                <strong>Ticket</strong>
                                                                            </th>
                                                                            <th>
                                                                                <strong>Venta</strong>
                                                                            </th>
                                                                            {/* <th>
                                                                                <strong>Venta IF</strong>
                                                                            </th> */}
                                                                            <th>
                                                                                <strong>A ganar</strong>
                                                                            </th>
                                                                            {/* <th>
                                                                                <strong>A ganar IF</strong>
                                                                            </th> */}
                                                                            <th>
                                                                                <strong>Pago</strong>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            terminal.cancelTickets.length ? terminal.cancelTickets.map((ticket, index) => (
                                                                                <tr className="col-12" key={index}>
                                                                                    <td>
                                                                                        <strong>#{ticket.ticketId}</strong>
                                                                                    </td>
                                                                                    <td>
                                                                                        <strong>${ticket.amount}</strong>

                                                                                    </td>
                                                                                    {/* <td>
                                                                                        ${ticket.amountIf}
                                                                                    </td> */}
                                                                                    <td>
                                                                                        <strong>${ticket.toWin}</strong>

                                                                                    </td>
                                                                                    {/* <td>
                                                                                        ${ticket.toWinIf}
                                                                                    </td> */}
                                                                                    <td>
                                                                                        <strong>${ticket.toCollect}</strong>

                                                                                    </td>
                                                                                </tr>
                                                                            )) : (
                                                                                <tr>
                                                                                    <td colSpan="6">
                                                                                        Esta terminal no tiene tickets cancelados
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {/* <div>
                                                                <div style={{
                                                                    textAlign: "left",
                                                                    fontSize: "1.5rem",
                                                                    marginTop: "1rem",
                                                                }}>
                                                                    <strong>Partidos:</strong>
                                                                </div>

                                                                {
                                                                    terminal.games.length ? terminal.games.map((game, index) => (

                                                                        <div style={{
                                                                            textAlign: "left",
                                                                            fontSize: "1.1rem",
                                                                            marginTop: "1rem",
                                                                            borderCollapse: "collapse",
                                                                            border: "1px solid #202020",
                                                                            borderBottom: "2px solid #202020"
                                                                        }}>
                                                                            <div className="row">
                                                                                <td className="col-12">
                                                                                    <strong style={{ fontSize: "1.1rem" }}>
                                                                                        {game.localTeam.name}{game.localTeam.code ? `(${game.localTeam.code})` : ""} vs {game.visitingTeam.name}{game.visitingTeam.code ? `(${game.visitingTeam.code})` : ""}
                                                                                    </strong>
                                                                                </td>
                                                                                <td className="col-12">
                                                                                    Estado: {game.status}
                                                                                </td>
                                                                                <td className="col-12">
                                                                                    <strong>
                                                                                        Score: {game.localLine?.scores?.total} - {game.visitingLine?.scores?.total
                                                                                        }
                                                                                    </strong>
                                                                                </td>
                                                                            </div>

                                                                        </div>
                                                                    )) : (
                                                                        <tr>
                                                                            <td colSpan="3">
                                                                                Esta terminal no tiene partidos
                                                                            </td>
                                                                        </tr>
                                                                    )

                                                                }
                                                                {/* <table style={{
                                                                    width: "75%",
                                                                    textAlign: "left",
                                                                    fontSize: "5vw",
                                                                    marginTop: "1.2rem",
                                                                    borderCollapse: "collapse",
                                                                    border: "1px solid #202020",
                                                                }}
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Equipos
                                                                            </th>
                                                                            <th>
                                                                                Estado
                                                                            </th>
                                                                            <th>
                                                                                Marcador
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            terminal.games.length ? terminal.games.map((game, index) => (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <strong style={{ fontSize: "5vw" }}>
                                                                                            {game.localTeam.name}{game.localTeam.code ? `(${game.localTeam.code})` : ""} vs {game.visitingTeam.name}{game.visitingTeam.code ? `(${game.visitingTeam.code})` : ""}
                                                                                        </strong>
                                                                                    </td>
                                                                                    <td>
                                                                                        {game.status}
                                                                                    </td>
                                                                                    <td>
                                                                                        <strong>
                                                                                            {game.localLine?.scores?.total} - {game.visitingLine?.scores?.total
                                                                                            }
                                                                                        </strong>
                                                                                    </td>
                                                                                </tr>
                                                                            )) : (
                                                                                <tr>
                                                                                    <td colSpan="3">
                                                                                        Esta terminal no tiene partidos
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table> */}
                                                            {/* </div> */}
                                                        </div>
                                                        {/* <div
                                                            style={{
                                                                marginBottom: "1rem",
                                                                borderBottom: "3px dashed #000",
                                                            }}
                                                        ></div> */}

                                                    </>
                                                ))
                                            }
                                        </div >
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrintResumen