import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { redApi } from '../../../../../config/Axios'

const TicketStatus = () => {
  const [tickets, setTickets] = useState([])
  const [filters, setFilters] = useState({
    initDate: moment().startOf('day').format("YYYY-MM-DD"),
    finalDate: moment().endOf('day').format("YYYY-MM-DD"),
    status: ''
  })
  const [isLoading, setIsLoading] = useState(false)

  const getTickets = async () => {
    setIsLoading(true)
    try {
      const { data } = await redApi.get(`/tickets?initDate=${filters.initDate}&finalDate=${filters.finalDate}${filters.ticketStatus !== '' ? `&ticketStatus=${filters.ticketStatus}` : ''}`)
      setTickets(data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTickets()
  }, [filters])

  const changeFilter = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
  }

  const [ticketSelected, setTicketSelected] = useState(null)

  const selectTicket = (ticket) => {
    setTicketSelected(ticket)
  }

  const handlePrevTicket = () => {
    if (!ticketSelected) {
      setTicketSelected(tickets[0])
      return
    }
    const index = tickets.findIndex(ticket => ticket._id === ticketSelected._id)
    if (index > 0) {
      setTicketSelected(tickets[index - 1])
    }
  }

  const handleNextTicket = () => {
    if (!ticketSelected) {
      setTicketSelected(tickets[0])
      return
    }
    const index = tickets.findIndex(ticket => ticket._id === ticketSelected._id)
    if (index < tickets.length - 1) {
      setTicketSelected(tickets[index + 1])
    }
  }

  return (
    <>
      <div className='sales-report-date'>
        <div>
          <input type="date" data-date="" data-date-format="DD MMMM YYYY" defaultValue={moment().startOf('day').format("YYYY-MM-DD")} onChange={changeFilter} name='initDate' />
          <span>
            to
          </span>
          <input type="date" data-date="" data-date-format="DD MMMM YYYY" defaultValue={moment().endOf('day').format("YYYY-MM-DD")} onChange={changeFilter} name='finalDate' />
        </div>
        <button className='btn btn-primary' onClick={getTickets}>
          Show Reports
        </button>
      </div>
      <div className='d-flex justify-content-center align-items-center mt-4 gap-2'
        style={{
          position: 'sticky',
          top: '120px',
          zIndex: '1',
        }}
      >
        <select name="status" id="filterType" onChange={changeFilter}>
          <option value="All">All</option>
          <option value="Play">Pending</option>
          <option value="Winner">Winner</option>
          <option value="Loser">Loser</option>
          <option value="Cancelled">Cancelled</option>
        </select>
      </div>
      <div className='sales-report-table mt-4'>
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Date</th>
              <th>Time</th>
              <th>Bet</th>
              <th>Prize</th>
              <th>IF Bet</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              isLoading ? (
                <tr>
                  <td colSpan='8'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                tickets?.map(ticket => (
                  <tr key={ticket._id} onClick={() => selectTicket(ticket)} className={ticketSelected?._id === ticket._id ? 'selected' : ''}>
                    <td>
                      #
                      {
                        ticket.ticketId
                      }
                    </td>
                    <td>
                      {
                        moment(ticket.createdAt).format('DD/MM/YYYY')
                      }
                    </td>
                    <td>
                      {
                        moment(ticket.createdAt).format('hh:mm A')
                      }
                    </td>
                    <td>
                      $
                      {
                        ticket.amount.toFixed(2)
                      }
                    </td>
                    <td>
                      $
                      {
                        ticket.toWin.toFixed(2)
                      }
                    </td>
                    <td>
                      $
                      {
                        ticket.toWinIf.toFixed(2)
                      }
                    </td>
                    <td>
                      <div>
                        <div className={`ticket-status ${ticket.ticketState === 'Play' ? 'pending' : ticket.ticketState === 'Winner' ? 'winner' : ticket.ticketState === 'Loser' ? 'loser' : 'cancelled'}`}>
                          {
                            ticket.ticketState === 'Play' ? 'Pending' : ticket.ticketState
                          }
                        </div>
                      </div>
                    </td>
                  </tr>
                )))
            }
          </tbody>
          <tfoot>
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td>
                {
                  tickets.length
                }
              </td>
              <td>
                VENTA
              </td>
              <td>
                $
                {
                  tickets.reduce((acc, ticket) => acc + ticket.amount, 0).toFixed(2)
                }
              </td>
              <td>
                $
                {
                  tickets.reduce((acc, ticket) => acc + ticket.toWin, 0).toFixed(2)
                }
              </td>
              <td
                colSpan={2}
              ></td>
            </tr>
          </tfoot>
        </table>
        <div>
          <div>
            <button onClick={handlePrevTicket}>
              <i className="fas fa-arrow-left" style={{ marginRight: '4px' }}></i>
              Prev
            </button>
            <button onClick={handleNextTicket}>
              Next
              <i className="fas fa-arrow-right" style={{ marginLeft: '4px' }}></i>
            </button>
          </div>
          <div className='selected-ticket'>
            {
              ticketSelected ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          TIME
                        </th>
                        <th>
                          LEAGUE
                        </th>
                        <th>
                          TEAM
                        </th>
                        <th>
                          TYPE
                        </th>
                        <th>
                          SPREAD
                        </th>
                        <th>
                          PRICE
                        </th>
                        <th>
                          STATUS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        ticketSelected?.bets.map(bet => (
                          <tr key={bet._id}>
                            <td>
                              {
                                moment(bet.game.time).format('hh:mm A')
                              }
                            </td>
                            <td>
                              {
                                bet.league.name
                              }
                            </td>
                            <td>
                              {
                                bet.team.name
                              }
                            </td>
                            <td>
                              {
                                bet.playType
                              }
                            </td>
                            <td>
                              {
                                bet.spread
                              }
                            </td>
                            <td>
                              {
                                bet.price
                              }
                            </td>
                            <td>
                              <div>
                                <div className={bet.betState === 'Play' ? 'pending' : bet.betState === 'Winner' ? 'winner' : bet.betState === 'Loser' ? 'loser' : 'cancelled'}>
                                  {
                                    bet.betState === 'Play' ? 'Pending' : bet.betState
                                  }
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </>

              ) : (
                <>
                  <i className="fas fa-arrow-circle-left" style={{
                    fontSize: '44px',
                    marginTop: '24px',
                  }}></i>
                  <span>
                    SELECT THE TIKCET
                  </span>
                  <span>
                    YOU WANT TO SEE
                  </span>
                </>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default TicketStatus

