
import {
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
//CLIENT
// import Home from '../components/client/pages/Home'
import Score from '../components/client/pages/Score';
import NBA from '../components/client/pages/NBA';
// import NotFoundClient from '../components/client/pages/NotFound';
// import NewHome from '../components/client/pages/NewHome';
import LegacyNBA from '../components/client/pages/Leagues/Legacy_NBA';
import { AuthContext } from '../auth';
import WebuserClient from '../components/client/pages/webuser/WebuserClient';
import Tmobile from '../components/client/pages/tmobile/Tmobile';
import GamesT3 from '../components/client/pages/theme3/Legacy_NBA';
import Theme4_container from '../components/client/pages/theme4/Theme4_container';
import F83Theme from '../components/client/pages/theme-F83/F83Theme';



export default function ClientRoutes({ userLevel }) {

  const { userTheme, TMobile } = useContext(AuthContext);
  const [tmobile, setTmobile] = useState(TMobile)
  const [isMobile, setIsMobile] = useState(false)

  const verifyTmobile = async () => {
    let locast = localStorage.getItem('TMobile')
    if (locast === 'true') {
      setTmobile(true)
    } else {
      setTmobile(false)
    }
  }
  useEffect(() => {
    verifyTmobile()
  }, [TMobile || localStorage.getItem('TMobile')])


  useEffect(() => {
    if (window.innerWidth < 768)
      setIsMobile(true)
    else
      setIsMobile(false);

    const resizeFunction = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    window.addEventListener('resize', resizeFunction)

    return () => window.removeEventListener('resize', resizeFunction);
  }, [])



  return (
    <Routes>

      <Route path='/scores/:league' element={<Score />} />
      {
        getRoutes(isMobile, userTheme, userLevel, tmobile)
      }


      {
        userLevel === 4 && <Route path='/:league' element={<WebuserClient />} />
      }

      {
        tmobile === true && <Route path='/:league' element={<Tmobile />} />
      }

      <Route path='/*' element={<Navigate to="/client/index" />} />

    </Routes>
  )
}


const getRoutes = (isMobile, userTheme, userLevel, tmobile) => {
  // if (isMobile) {
  //   if (userTheme !== 3 && userLevel !== 4 && tmobile === false)
  //     return <Route path='/:league' element={<Theme4_container />} />
  //   else
  //     return <Route path='/:league' element={<GamesT3 />} />
  // } else {
  if (userLevel !== 4 && tmobile === false) {
    switch (userTheme) {
      case 1: return <Route path='/:league' element={<LegacyNBA />} />;
      case 2: return <Route path='/:league' element={<NBA />} />;
      case 3: return <Route path='/:league' element={<GamesT3 />} />;
      case 4: return <Route path='/:league' element={<Theme4_container />} />;
      case 5: return <Route path='/:league' element={<F83Theme />} />;
      default: return <></>;
    }
    // } else
    //   return <></>
  }
}