import React, { useEffect } from 'react'
import { redApi } from '../../../../../config/Axios'

const LimiteDeHembras = ({ createConsorcios, setCreateConsorcios }) => {

    const handleLimit = (e, index1, index2) => {
        let hembraLimits = [...createConsorcios.hembraLimits]
        hembraLimits[index1].ticketType[index2].value = Number(e.target.value)
        setCreateConsorcios({
            ...createConsorcios,
            hembraLimits
        })
    }

    return (
        <div className="row" id='ConsorcioTlmtLiga'>
            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <h6 className='text-center h2 m-2'>Limite de Hembras</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">todos</th>
                                    {
                                        createConsorcios.hembraLimits[0].ticketType.map((item, index) => {
                                            return (
                                                <th key={index} scope="col">{item.betsNumber}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    createConsorcios.hembraLimits.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.all}</td>
                                                {
                                                    item.ticketType.map((item, ind) => {
                                                        return (
                                                            <td key={ind}>
                                                                <input type="number" name={item.betsNumber} className="form-control" value={item.value} onChange={(e) => handleLimit(e, index, ind)} />
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LimiteDeHembras