import {
    Routes,
    Route,
    Navigate,
    useNavigate,
} from 'react-router-dom';

import { useLines, useLeagues } from '../utils/Hooks';
import { useContext, useEffect, useState } from 'react';
import LinesGamesVertical from '../components/public/lines/LinesGamesVertical';
import LoadingScreen from '../components/layout/LoadingScreen';
import "./../components/public/styles/LayoutLines.scss";
import LinesGamesResponsive from '../components/public/lines/LinesGamesResponsive';


export const PublicLinesRoutes = () => {
    const { lines: games, getLines, loading } = useLines();
    const { leagues, getLeagues } = useLeagues();
    const [leagueIndex, setLeagueIndex] = useState(0);
    const [head, setHead] = useState(null);

    useEffect(() => {
        getLeagues();
    }, []);

    // cada 60 segundos se actualiza la data con una nueva liga con: getLines(league._id)
    useEffect(() => {
        if (leagues.length > 0) {
            const interval = setInterval(() => {
                setLeagueIndex(leagueIndex => leagueIndex + 1);
                if (leagueIndex >= leagues.length) {
                    setLeagueIndex(0);
                }
            }, 60000);
            return () => clearInterval(interval);
        }
    }, [leagues]);

    useEffect(() => {
        if (leagues.length > 0 && leagues[leagueIndex]?._id !== undefined) {
            getLines(leagues[leagueIndex]?._id);
        } else {
            // console.log("reset leagues");
            setLeagueIndex(0);
        }
    }, [leagueIndex, leagues]);

    return (
        <Routes>
            {/* <Route path='/' element={<LinesGamesVertical games={games} leagues={leagues} />} /> */}
            {
                // loading ? <Route path='/' element={<LoadingScreen />} /> :
                games.length > 0 ?
                    <>
                        <Route path='/' element={<LinesGamesVertical games={games} leagues={leagues} />} />
                        <Route path='/lines' element={<div className="lines-games-responsive"><LinesGamesResponsive games={games} leagues={leagues} /></div>} />
                    </>
                    : <Route path='/' element={<h1>No hay juegos disponibles</h1>} />

            }
        </Routes>
    )
};