import TableHeader from "../tableHeader/TableHeader"
import TableBody from "../tablebody/TableBody"
import "./Table.css"
import { useEffect, useState } from "react"
import { sendlimitLine } from "../servicesLimitXconsorcio"

const Table = ({ periods, consorcio }) => {

  const [selectedSport, setSelectedSport] = useState('');
  const [datosCasillas, setDatosCasillas] = useState([]);

  // const headerCasillas = [
  //   'MONEY +', 'MONEY -', 'MH +', 'MH -', 'MH2 +', 'MH2 -', 'MONEY 1Q +', 'MONEY 1Q -', 'MONEY 2Q +',
  //   'MONEY 2Q -', 'MONEY 3Q +', 'MONEY 3Q -', 'MONEY 4Q +', 'MONEY 4Q -', 'MONEY 1T +', 'MONEY 1T -', 'MONEY 2T +', 'MONEY 2T -',
  //   'MONEY 3T +', 'MONEY 3T -', 'PRECIO RL/+', 'PRECIO RL/-', 'PRECIO RL/H +', 'PRECIO RL/H -', 'PRECIO RL/H2 +', 'PRECIO RL/H2 -',
  //   'PRECIO RL/1Q + ', 'PRECIO RL/1Q -', 'PRECIO RL/2Q +', 'PRECIO RL/2Q -', 'PRECIO RL/3Q +', 'PRECIO RL/3Q -', 'PRECIO RL/4Q +', 'PRECIO RL/4Q -', 'PRECIO RL/1T +',
  //   'PRECIO RL/1T -', 'PRECIO RL/2T +', 'PRECIO RL/2T -', 'PRECIO RL/3T +', 'PRECIO RL/3T -',
  // ].map(casilla => casilla.trim());




  const getLimit = async () => {
    try {
      const respuesta = await sendlimitLine(consorcio)

      setDatosCasillas(respuesta.data)
    } catch (error) {
      console.log("Error: ", error)
      throw error
    }
  }

  useEffect(() => {
    getLimit(consorcio)
  }, [])

  useEffect(() => {
    getLimit(consorcio);
  }, [consorcio]);


  const isCasillaDisabled = (league, casilla) => {
    const leagueData = datosCasillas.limits.find((row) => row.league.name === league);

    const allowedCasillasFromPeriods = leagueData.periodsInfo.map((casilla) => casilla.trim());
    // console.log("aaaaa: ", allowedCasillasFromPeriods, casilla);

    return !allowedCasillasFromPeriods.includes(casilla);
  };

  return (
    <div className="table-container-limit">
      <table className="table-limit">
        <TableHeader

          periods={periods}
        />
        <TableBody

          periods={periods}
          datosCasillas={datosCasillas}
          isCasillaDisabled={isCasillaDisabled}
          setSelectedSport={setSelectedSport}
          getLimit={getLimit}
        />
      </table>
    </div>
  )
}

export default Table