import React, { useState, useEffect } from "react";
import { redApi } from "../../../../config/Axios";
import { Modal } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function TmobileRightContainer({
  isLoading,
  setIsLoading,
  gamesForUser,
}) {
  const navigate = useNavigate();
  // change theme
  const [userTheme, setUserTheme] = useState(1);
  const handleChangeTheme = (e) => {
    setUserTheme(e.target.value);
  };

  // modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    setIsModalVisible(false);
    await redApi.get("/verify").then(async (res) => {
      console.log(res.data);
      if (res.data) {
        if (res.data._id) {
          await redApi
            .put(`/users/${res.data._id}`, { theme: userTheme })
            .then((res) => {
              window.location.reload();
              Swal.fire({
                icon: "success",
                title: "Theme changed",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      }
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <div id="gamesforuserRight" className={`container-fluid d-flex flex-column justify-content-center align-items-center bg-card`}>
      {
        (
          <div className="w-100 d-flex flex-column justify-content-start align-items-center gap-2 px-2 h-100 py-2">
            <div className="Tables_right_container">
              {isLoading ? (
                <div
                  className="spinner-border text-danger z-index-999 position-absolute top-50 start-50"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <div className="table-responsive">
                  <div className="d-flex flex-row justify-content-center align-items-center text-dark">
                    Full Game/Half
                  </div>
                  <table className="">
                    <thead>
                      <tr>
                        <th scope="col">Hora</th>
                        <th scope="col">Cod</th>
                        <th scope="col">Team</th>
                        <th scope="col">ML</th>
                        <th scope="col">RL/T</th>
                        <th scope="col">H1 RL/T</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gamesForUser?.map((game, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              borderBottom: "1px solid #131313",
                            }}
                          >
                            <th
                              scope="row"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {moment(game.time)
                                .tz("America/New_York")
                                .format("hh:mm A")}
                            </th>
                            <th>
                              {game.localTeam.codeRD}
                              <br />
                              {game.visitingTeam.codeRD}
                            </th>
                            <td>
                              {game.localTeam.name}
                              <br />
                              {game.visitingTeam.name}
                            </td>
                            <td>
                              {game.localLine?.moneyLine?.value || "~"}
                              <br />
                              {game.visitingLine?.moneyLine?.value || "~"}
                            </td>
                            <td>
                              {game.localLine?.gavela?.value || "~"}
                              {game.localLine?.price?.value || "~"}
                              <br />
                              {game.visitingLine?.gavela?.value || "~"}
                              {game.visitingLine?.price?.value || "~"}
                            </td>
                            <td>
                              {game.localLine?.HGavela?.value || "~"}
                              {game.localLine?.h1Rl?.value || "~"}
                              <br />
                              {game.visitingLine?.HGavela?.value || "~"}
                              {game.visitingLine?.h1Rl?.value || "~"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )
      }

    </div>
  );
}
