import React, { useState } from 'react'
import { redApi } from '../../../../config/Axios';
import Swal from 'sweetalert2';

export const SendTicket = ({setTicketsFilter, ticketsFilter, contacts, tickets, handleBetPost}) => {


  const [phone, setPhone] = useState("");
  const [selectedContact, setSelectedContact] = useState("")
  const handlerOnChange = (e) => {
    setTicketsFilter({...ticketsFilter, [e.target.name]: e.target.value});
  }
  const writePhone = (e) => {
    if (e.target.value.length > 10) return;
    if (selectedContact) setSelectedContact("");
    setPhone(e.target.value);
  }
  const changeContact = (e) => {
    setSelectedContact(e.target.value);
    setPhone(e.target.value);
  }

  const sendPrevTicket = async (ticketNumber) => {
    if (!phone || isNaN(Number(phone)) || phone.length < 10) {
      Swal.fire({
        icon: "error",
        title: "Ingrese un número de teléfono válido",
        timer: 1500,
        showConfirmButton: false
      })
      return;
    }
    Swal.fire({
        title: `Desea enviar el ticket #${ticketNumber} al contacto: ${phone}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '7E22CE',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
    }).then( async (result) => {
      if (result.isConfirmed) {
        Swal.showLoading();
        try {
          await redApi.post("/whatsapp/sendmsg", {
            ticketNumber,
            phone
          });
          Swal.fire({
            icon: "success",
            title: "Ticket enviado!",
            timer: 1500,
            showConfirmButton: false
          })
        } catch (error) {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Error al enviar el ticket",
            timer: 1500,
            showConfirmButton: false
          })
        }
      }
    })
  }

  const printAndSend = async () => {
    Swal.fire({
      title: `Desea imprimir el ticket actual y enviar al contacto: ${phone}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '7E22CE',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar'
    }).then( async (result) => {
      if (result.isConfirmed) {
        const print = await handleBetPost("Print&Send");
        console.log({print})
        if (typeof print === "number") {
          try {
            await redApi.post("/whatsapp/sendmsg", {
              ticketNumber: print,
              phone
            });
            Swal.fire({
              icon: "success",
              title: "Ticket enviado!",
              timer: 1500,
              showConfirmButton: false
            })
          } catch (error) {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Error al enviar el ticket",
              timer: 1500,
              showConfirmButton: false
            })
          }
        }
      }
    });

  }

  return (
    <div className="container-fluid p-0 py-2 m-0" style={{minHeight: "50vh"}}>
      <div className="row mb-3 justify-content-center justify-content-md-between align-items-end form-contacts">
        <div className="col-6 col-md-3 mb-2">
            <label>Teléfono</label>
            <input minLength={10} maxLength={10} value={phone} onChange={writePhone} type="tel" className="form-control" name="phone" placeholder="(123)4567890"/>
        </div>
        <div className="col-6 col-md-3 mb-2">
            <label>Contacto</label>
            <select value={selectedContact} className="form-select" onChange={changeContact}>
              <option value="">Seleccionar contacto</option>
              {
                contacts.map((item, index) => (
                  <option key={index} value={item.phone}>{item.name}</option>
                ))
              }
            </select>
        </div>
        <div className="col-6 col-md-3">
          <button onClick={printAndSend}>Print & Send</button>
        </div>
      </div>
      <hr />
      <span style={{fontSize: "1.3rem", fontWeight: "bold"}}>Enviar Ticket Anterior</span>
      <div className="row mb-2 justify-content-between">
        <div className="col-6 col-md-3">
            <label>Fecha:</label>
            <input type="date" className="form-control" name="initDate" value={ticketsFilter.initDate} onChange={handlerOnChange}/>
        </div>
        <div className="col-6 col-md-3">
            <label>Fin:</label>
            <input type="date" className="form-control" name="finalDate" value={ticketsFilter.finalDate} onChange={handlerOnChange}/>
        </div>
      </div>
      <div className="w-100" id="customers" style={{maxHeight: "35vh", overflowY: "auto"}}>
          <table className="table table-sm table-hover">
              <thead>
                  <tr>
                      <th>Ticket</th>
                      <th>Amount</th>
                  </tr>
              </thead>
              <tbody>
                  { tickets.loading
                      ? <tr><td colSpan={4}>Cargando...</td></tr>
                      : tickets.tickets.length === 0
                          ? <tr><td colSpan={4}>No hay tickets.</td></tr>
                          : tickets.tickets.filter(e => e.createdBy).map((item, index) => (
                              <tr
                                key={index}
                                style={{cursor: "pointer"}}
                                onClick={() => {sendPrevTicket(item.ticketId)}}
                              >
                                  <td>#{item.ticketId}</td>
                                  <td>${item.amount}</td>
                              </tr>
                              ))
                  }
              </tbody>
          </table>
      </div>
  </div>
  )
}
