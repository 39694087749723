import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment-timezone';
import { redApi } from '../../../config/Axios';
import { Printer } from '../../printer/Print';

export default function RightContainer({ setBetToAdd, handleChangeBetToAdd, betToAdd, bets, setBets, totalToWin, setTotalToWin, setSelectedBet, selectedBet, printTicket, setPrintTicket, ticketNumber, handleBetPost }) {

    const [todayAndTime, setTodayAndTime] = useState(moment.tz('America/New_York').format('YYYY-MM-DD hh:mm A'));

    useEffect(() => {
        const interval = setInterval(() => {
            // date time Coordinated Universal Time (UTC) with .format('YYYY-MM-DD HH:mm')
            let mommentResult = moment.tz('America/New_York').format('YYYY-MM-DD hh:mm A');
            setTodayAndTime(mommentResult);
        }, 60000);
        return () => clearInterval(interval);
    }, []);


    function getToWin(bets, amount) {
        let result = 0;
        for (let i = 0; i < bets.length; i++) {
            const multiplier = bets[i].price >= 0
                ? bets[i].price / 100
                : 1 / ((bets[i].price * -1) / 100);
            result = ((result + amount) * multiplier + result);
        }
        result = Math.floor(result);
        return result
    }

    // let ifWinContainer = document.querySelector('#ifWinContainer');
    // let newInput = document.createElement('input');
    // newInput.type = 'number';
    // newInput.className = 'form-control col';
    // newInput.name = 'IfWinQuantity';
    // ifWinContainer.appendChild(newInput);
    // newInput.focus();

    const handleEnterKey = useCallback((e) => {
        if (e.key === 'Enter' && bets.bets.length > 0) {
            let ifWinInput = document.querySelector('#ifWinInput');
            let quantityInput = document.querySelector('#quantityInput');
            let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
            if (parseInt(ammountBet) > 0) {
                let betsTowin = bets.bets.map((bet) => {
                    return {
                        price: parseInt(bet.Price),
                    }
                })

                ifWinInput.disabled = true;
                quantityInput.disabled = true;

                let totalToWin = getToWin(betsTowin, parseInt(ammountBet));
                setTotalToWin(totalToWin);
            }

            setBets({
                ...bets,
                ammount: parseInt(ammountBet),
                readyToSubmit: true
            })
            setBetToAdd({
                TeamCode: '',
                PlayType: '',
                Quantity: 0,
                IfWin: 0
            })

        }
    }, [bets])

    useEffect(() => {
        let ifWinInput = document.querySelector('#ifWinInput');
        ifWinInput.addEventListener('keydown', handleEnterKey);

        return () => {
            ifWinInput.removeEventListener('keydown', handleEnterKey);
        }
    }, [bets])

    useEffect(() => {
        let quantityInput = document.querySelector('#quantityInput');
        quantityInput.addEventListener('keydown', handleQuantityEnterKey);

        return () => {
            quantityInput.removeEventListener('keydown', handleQuantityEnterKey);
        }
    }, []);

    const handleQuantityEnterKey = (e) => {
        if (e.key === 'Enter') {
            document.querySelector('#ifWinInput').focus();
            document.querySelector('#ifWinInput').select();
        }
    }

    const handleSpaceKey = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
            if (bets.bets.length > 0) {
                document.querySelector('#quantityInput').focus();
                document.querySelector('#quantityInput').select();
            } else {
                document.querySelector('#TeamCode').focus();
                document.querySelector('#TeamCode').select();
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleSpaceKey);

        return () => {
            document.removeEventListener('keydown', handleSpaceKey);
        }
    }, [bets])

    const getCurrentUser = async () => {
        await redApi.get(`/verify`)
            .then(res => {
                setTerminalLogged(res.data)
            })
            .catch(err => console.log(err));
    }
    const [terminalLogged, setTerminalLogged] = useState({})
    const [terminalsToLogin, setTerminalsToLogin] = useState([]);
    const getTerminals = async () => {
        if (terminalLogged && terminalLogged.userLevel < 3) {
            await redApi.get('/users/getterminalsofconsortium')
                .then(res => {
                    setTerminalsToLogin(res.data);
                }).catch(err => console.log(err));
        }
    }
    useEffect(() => {
        getCurrentUser();
    }, [])
    useEffect(() => {
        getTerminals().catch(err => console.log(err));;
    }, [terminalLogged])


    return (
        <div className="sright-sidebar" style={{ height: '100%' }}>
            {
                printTicket.print ?
                    <Printer ticketId={printTicket.ticketId} printTicket={printTicket} setPrintTicket={setPrintTicket} />
                    :
                    null
            }
            {/* <!--main tabs--> */}
            <ul className=" d-none nav main-tabs-data nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link slink-main-tab active" id="bet-slip-tab" data-bs-toggle="pill" data-bs-target="#bet-slip" type="button" role="tab" aria-controls="bet-slip" aria-selected="true">Bet Slip</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link slink-main-tab" id="mybets-tab" data-bs-toggle="pill" data-bs-target="#mybets" type="button" role="tab" aria-controls="mybets" aria-selected="false">My Bets</button>
                </li>

            </ul>
            <div className="tab-content" id="pills-tabContent" style={{ height: '100%' }}>
                <div className="tab-pane fade show active" style={{ height: '100%' }} id="bet-slip" role="tabpanel" aria-labelledby="bet-slip-tab">
                    {/* <!--inner-tab--> */}
                    <ul className="d-none nav bet-inner-tabs nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="single-tab" data-bs-toggle="tab" data-bs-target="#single" type="button" role="tab" aria-controls="single" aria-selected="true">Single</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="multibet-tab" data-bs-toggle="tab" data-bs-target="#multibet" type="button" role="tab" aria-controls="multibet" aria-selected="false">Multibet</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="system-tab" data-bs-toggle="tab" data-bs-target="#system" type="button" role="tab" aria-controls="system" aria-selected="false">System</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent" style={{ height: '100%' }}>
                        <div className="tab-pane fade show active" style={{ height: '100%', backgroundColor: '#eaeaea' }} id="single" role="tabpanel" aria-labelledby="single-tab">
                            <div className="tab-select" style={{ height: '100%' }}>
                                <div className="filter-wrapper" style={{ height: '100%' }}>
                                    <form className="right-side-form" style={{ backgroundColor: '#fff', height: '33.33%' }}
                                        onSubmit={e => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <div className="row mb-4" style={{ height: '100%' }}>
                                            <div className="col-md-6">
                                                <span>Fecha</span>
                                                <input id="input-bet-date" disabled readOnly value={
                                                    moment().format('YYYY-MM-DD')
                                                } type="date" className="form-control" />
                                            </div>
                                            <div className="col-md-6">
                                                <span>Usuario</span>
                                                <select disabled={terminalLogged?.userLevel < 3 ? false : true} className="form-select" id="input-bet-username">
                                                    {
                                                        terminalLogged?.userLevel < 3
                                                            ? terminalsToLogin.map((item, index) => (
                                                                <option key={index} value={item._id}>{item.username}</option>
                                                            ))
                                                            : (<option value={terminalLogged?._id}>{terminalLogged?.username}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <span>Codigo</span>
                                                <input type="text" className="form-control" name='TeamCode' id='TeamCode' value={betToAdd.TeamCode} onChange={handleChangeBetToAdd} />
                                            </div>
                                            <div className="col-md-6">
                                                <span>Jugada</span>
                                                <input type="text" className="form-control" name='PlayType' value={betToAdd.PlayType} onChange={handleChangeBetToAdd} id="betTypeSelect" />
                                                {/* <select className='form-select' id="input-bet-type" name="betType" onChange={''} value={''}>
                                                    {betType.map((bet, index) => (
                                                        <option key={index} value={bet}>{bet}</option>
                                                    ))}
                                                </select> */}
                                            </div>
                                            <div className="col-md-6">
                                                <span>Cantidad</span>
                                                <input type="text" className="form-control" name='Quantity' value={betToAdd.Quantity} onChange={handleChangeBetToAdd} id="quantityInput" />
                                            </div>
                                            <div className="col-md-6">
                                                <span>IF</span>
                                                {/* <input type="text" className="form-control" name="betIf" onChange={''} value={''} /> */}
                                                <div id='ifWinContainer' className="col d-flex flex-row justify-content-center align-items-center gap-2">
                                                    <input type="number" className="form-control col" name='IfWin' value={betToAdd.IfWin} onChange={handleChangeBetToAdd} id="ifWinInput" />
                                                </div>
                                            </div>
                                            <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                                                <div className="col d-flex flex-column justify-content-center align-items-center">
                                                    <button className='btn btn-danger my-2' id='postbetmobile'
                                                        style={{
                                                            display: "none"
                                                        }}
                                                        onClick={() => {
                                                            console.log("POST BET");
                                                            handleBetPost("Print&Send");
                                                        }}
                                                    >
                                                        POST BET
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className='p-2' style={{ backgroundColor: '#fff', height: '33.33%' }}>
                                        <div className="select-text">Ticket #{ticketNumber + 1}</div>
                                        <div className='table-responsive border' style={{ height: '80%' }}>
                                            <table className='table table-sm table-bordered' style={{ minHeight: '30px' }}>
                                                <thead className='table-secondary'>
                                                    <tr>
                                                        <th scope="col" className="text-center">Code</th>
                                                        <th scope="col" className="text-center">Team</th>
                                                        <th scope="col" className="text-center">Type</th>
                                                        <th scope="col" className="text-center">Spread</th>
                                                        <th scope="col" className="text-center">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        bets.bets?.map((bet, index) => {
                                                            return (
                                                                <tr style={{ cursor: 'pointer' }} onClick={() => { setSelectedBet(bet.teamCode) }} key={index} className={'tr_table_bets ' + (selectedBet === bet.teamCode ? 'bet-selected' : '')}>
                                                                    <td className="text-center">{bet.teamCode}</td>
                                                                    <td className="text-center">
                                                                        {bet.TeamName}
                                                                    </td>
                                                                    <td className="text-center">{bet.playType}</td>
                                                                    <td className="text-center">{bet.Spread}</td>
                                                                    <td className="text-center">{bet.Price}</td>
                                                                </tr>
                                                            )
                                                        }
                                                        )
                                                    }
                                                    {/* {
                                                        betsAdded && betsAdded.map((bet, index) => (
                                                            <tr key={index}>
                                                                <td>{bet.betCode}</td>
                                                                <td>{bet.betTeam}</td>
                                                                <td>{bet.betType}</td>
                                                                <td>{bet.betSpread}</td>
                                                                <td>{bet.betPrice}</td>
                                                            </tr>
                                                        ))
                                                    } */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="bet-footer-side" style={{ backgroundColor: '#eaeaea', height: '33.33%' }}>
                                        <table className="table tb-t table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th>Venta</th>
                                                    <th>$ {bets.ammount > 0 ? bets.ammount : ''}</th>
                                                </tr>
                                                <tr className='md-d-none'>
                                                    <th>Ticket Total</th>
                                                    <th>$ 0.00</th>
                                                </tr>
                                                <tr>
                                                    <th>A Ganar</th>
                                                    <th>${totalToWin > 0 ? totalToWin : 0.00}</th>
                                                </tr>
                                                <tr>
                                                    <th>A Ganar IF</th>
                                                    <th>$ 0.00</th>
                                                </tr>
                                                <tr>
                                                    <th>A Pagar</th>
                                                    <th>$ {totalToWin > 0 ? totalToWin + bets.ammount : 0.00}</th>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <th><input type="checkbox" /></th>
                                                    <th>Nombre del Cliente</th>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <th>Fecha/Hora Local</th>
                                                    <th>00:00</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end filter content wrapper--> */}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="multibet" role="tabpanel" aria-labelledby="multibet-tab">
                            <div className="tab-select">
                                <div className="rm-filter-wrapper">
                                    <div className="rm-filter">
                                        <h6>Remove All Bets</h6>
                                        <span className="rm-close-icon"><i className="fa fa-times"></i></span>
                                    </div>
                                    <div className="select-text">1 Selection have changed</div>
                                </div>
                                {/* <!--filter end--> */}
                                {/* <!-- filter content--> */}
                                <div className="filter-wrapper">
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span className="active-price">1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bet-footer-side">
                                        <div className="filter-items-wrapper tfilter-items-wrapper">
                                            <div className="filter-item-left">
                                                <div className="form-check-label bet-tlabel" htmlFor="flexCheckDefault">
                                                    <strong>Stack Per Bet</strong>
                                                    <span className="rem-bet">(6 bets)</span>
                                                </div>

                                            </div>
                                            <div className="filter-item-right">
                                                <div className="caret-icon-price">
                                                    <div className="icon-btn-caret icon-btn-tcaret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                                </div>
                                            </div>
                                        </div>
                                        <table className="table tb-t table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th>Total Stack</th>
                                                    <th>30.00 $</th>
                                                </tr>
                                                <tr>
                                                    <th>Possible Winnings</th>
                                                    <th>55.75 $</th>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <div className="notice-bet">
                                            <p>The odds, markets or availability of your bet has changed. Please review your bet slip before you place your bet!!! </p>
                                        </div>
                                        <div className="pl-bet">
                                            Place Bet
                                        </div>
                                        <p className="terms">
                                            By placing a bet,you automatically accept the most recent version of the <a href="#">Terms & Condition.</a>
                                        </p>
                                    </div>
                                </div>
                                {/* <!--end filter content wrapper--> */}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="system" role="tabpanel" aria-labelledby="system-tab">
                            <div className="tab-select">
                                <div className="rm-filter-wrapper">
                                    <div className="rm-filter">
                                        <h6>Remove All Bets</h6>
                                        <span className="rm-close-icon"><i className="fa fa-times"></i></span>
                                    </div>
                                    <div className="select-text">1 Selection have changed</div>
                                </div>
                                {/* <!--filter end--> */}
                                {/* <!-- filter content--> */}
                                <div className="filter-wrapper">
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span className="active-price">1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bet-footer-side">
                                        <div className="filter-items-wrapper tfilter-items-wrapper">
                                            <div className="filter-item-left">
                                                <div className="form-check-label bet-tlabel" htmlFor="flexCheckDefault">
                                                    <strong>Stack Per Bet</strong>
                                                    <span className="rem-bet">(6 bets)</span>
                                                </div>

                                            </div>
                                            <div className="filter-item-right">
                                                <div className="caret-icon-price">
                                                    <div className="icon-btn-caret icon-btn-tcaret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                                </div>
                                            </div>
                                        </div>
                                        <table className="table tb-t table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th>Total Stack</th>
                                                    <th>30.00 $</th>
                                                </tr>
                                                <tr>
                                                    <th>Possible Winnings</th>
                                                    <th>55.75 $</th>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <div className="notice-bet">
                                            <p>The odds, markets or availability of your bet has changed. Please review your bet slip before you place your bet!!! </p>
                                        </div>
                                        <div className="pl-bet">
                                            Place Bet
                                        </div>
                                        <p className="terms">
                                            By placing a bet,you automatically accept the most recent version of the <a href="#">Terms & Condition.</a>
                                        </p>
                                    </div>
                                </div>
                                {/* <!--end filter content wrapper--> */}
                            </div></div>
                    </div>
                    {/* <!--end inner--> */}
                </div>
                <div className="tab-pane fade" id="mybets" role="tabpanel" aria-labelledby="mybets-tab">
                    {/* <!--inner-tab--> */}
                    <ul className="nav bet-inner-tabs nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="single-tab" data-bs-toggle="tab" data-bs-target="#single" type="button" role="tab" aria-controls="single" aria-selected="true">Single</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="multibet-tab" data-bs-toggle="tab" data-bs-target="#multibet" type="button" role="tab" aria-controls="multibet" aria-selected="false">Multibet</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="system-tab" data-bs-toggle="tab" data-bs-target="#system" type="button" role="tab" aria-controls="system" aria-selected="false">System</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="single" role="tabpanel" aria-labelledby="single-tab">
                            <div className="tab-select">
                                <div className="rm-filter-wrapper">
                                    <div className="rm-filter">
                                        <h6>Remove All Bets</h6>
                                        <span className="rm-close-icon"><i className="fa fa-times"></i></span>
                                    </div>
                                    <div className="select-text">1 Selection have changed</div>
                                </div>
                                {/* <!--filter end--> */}
                                {/* <!-- filter content--> */}
                                <div className="filter-wrapper">
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span className="active-price">1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bet-footer-side">
                                        <div className="filter-items-wrapper tfilter-items-wrapper">
                                            <div className="filter-item-left">
                                                <div className="form-check-label bet-tlabel" htmlFor="flexCheckDefault">
                                                    <strong>Stack Per Bet</strong>
                                                    <span className="rem-bet">(6 bets)</span>
                                                </div>

                                            </div>
                                            <div className="filter-item-right">
                                                <div className="caret-icon-price">
                                                    <div className="icon-btn-caret icon-btn-tcaret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                                </div>
                                            </div>
                                        </div>
                                        <table className="table tb-t table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th>Total Stack</th>
                                                    <th>30.00 $</th>
                                                </tr>
                                                <tr>
                                                    <th>Possible Winnings</th>
                                                    <th>55.75 $</th>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <div className="notice-bet">
                                            <p>The odds, markets or availability of your bet has changed. Please review your bet slip before you place your bet!!! </p>
                                        </div>
                                        <div className="pl-bet">
                                            Place Bet
                                        </div>
                                        <p className="terms">
                                            By placing a bet,you automatically accept the most recent version of the <a href="#">Terms & Condition.</a>
                                        </p>
                                    </div>
                                </div>
                                {/* <!--end filter content wrapper--> */}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="multibet" role="tabpanel" aria-labelledby="multibet-tab">
                            <div className="tab-select">
                                <div className="rm-filter-wrapper">
                                    <div className="rm-filter">
                                        <h6>Remove All Bets</h6>
                                        <span className="rm-close-icon"><i className="fa fa-times"></i></span>
                                    </div>
                                    <div className="select-text">1 Selection have changed</div>
                                </div>
                                {/* <!--filter end--> */}
                                {/* <!-- filter content--> */}
                                <div className="filter-wrapper">
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span className="active-price">1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bet-footer-side">
                                        <div className="filter-items-wrapper tfilter-items-wrapper">
                                            <div className="filter-item-left">
                                                <div className="form-check-label bet-tlabel" htmlFor="flexCheckDefault">
                                                    <strong>Stack Per Bet</strong>
                                                    <span className="rem-bet">(6 bets)</span>
                                                </div>

                                            </div>
                                            <div className="filter-item-right">
                                                <div className="caret-icon-price">
                                                    <div className="icon-btn-caret icon-btn-tcaret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                                </div>
                                            </div>
                                        </div>
                                        <table className="table tb-t table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th>Total Stack</th>
                                                    <th>30.00 $</th>
                                                </tr>
                                                <tr>
                                                    <th>Possible Winnings</th>
                                                    <th>55.75 $</th>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <div className="notice-bet">
                                            <p>The odds, markets or availability of your bet has changed. Please review your bet slip before you place your bet!!! </p>
                                        </div>
                                        <div className="pl-bet">
                                            Place Bet
                                        </div>
                                        <p className="terms">
                                            By placing a bet,you automatically accept the most recent version of the <a href="#">Terms & Condition.</a>
                                        </p>
                                    </div>
                                </div>
                                {/* <!--end filter content wrapper--> */}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="system" role="tabpanel" aria-labelledby="system-tab">
                            <div className="tab-select">
                                <div className="rm-filter-wrapper">
                                    <div className="rm-filter">
                                        <h6>Remove All Bets</h6>
                                        <span className="rm-close-icon"><i className="fa fa-times"></i></span>
                                    </div>
                                    <div className="select-text">1 Selection have changed</div>
                                </div>
                                {/* <!--filter end--> */}
                                {/* <!-- filter content--> */}
                                <div className="filter-wrapper">
                                    <div className="filter-items-wrapper">

                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span className="active-price">1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items-wrapper">
                                        <div className="filter-item-left">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <div className="form-check-label bet-label" htmlFor="flexCheckDefault">
                                                    <strong>Hornets -3,5</strong>
                                                    <p>Handicap</p>
                                                    <p>Hornets - Celtics</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-item-right">
                                            <div className="p-without-currency">
                                                <span>1.65</span>
                                                <button type="button" className="btn-cur-close btn-close" disabled aria-label="Close"></button>
                                            </div>
                                            <div className="caret-icon-price">
                                                <div className="icon-btn-caret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bet-footer-side">
                                        <div className="filter-items-wrapper tfilter-items-wrapper">
                                            <div className="filter-item-left">
                                                <div className="form-check-label bet-tlabel" htmlFor="flexCheckDefault">
                                                    <strong>Stack Per Bet</strong>
                                                    <span className="rem-bet">(6 bets)</span>
                                                </div>

                                            </div>
                                            <div className="filter-item-right">
                                                <div className="caret-icon-price">
                                                    <div className="icon-btn-caret icon-btn-tcaret"><span><i className="fa-solid fa-caret-down"></i></span>5.00 $</div>
                                                </div>
                                            </div>
                                        </div>
                                        <table className="table tb-t table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th>Total Stack</th>
                                                    <th>30.00 $</th>
                                                </tr>
                                                <tr>
                                                    <th>Possible Winnings</th>
                                                    <th>55.75 $</th>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <div className="notice-bet">
                                            <p>The odds, markets or availability of your bet has changed. Please review your bet slip before you place your bet!!! </p>
                                        </div>
                                        <div className="pl-bet">
                                            Place Bet
                                        </div>
                                        <p className="terms">
                                            By placing a bet,you automatically accept the most recent version of the <a href="#">Terms & Condition.</a>
                                        </p>
                                    </div>
                                </div>
                                {/* end filter content wrapper */}
                            </div></div>
                    </div>
                    {/* end inner */}
                </div>
            </div>
            {/* end main-tabs */}
        </div>
    )
}
