import React from 'react'

const Horarios = (props) => {
    return (
        <div className="row">
            <div className="col-12 d-flex gap-4 justify-content-start align-items-center">
                <input type="checkbox" className="form-control" style={{ width: '20px', height: '20px' }} />
                <label className="form-label d-flex justify-content-center align-items-center">Activar</label>
            </div>
        </div>
    )
}

export default Horarios