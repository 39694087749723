import React from 'react'

const MoneyTransactionsConfirmation_t4_mobile = () => {
  return (
    <div className='menu_container'>
      <div className='top_container'>
        <div>
          <span>
            Money Transactions Confirmation
          </span>
        </div>
        <div className='balance_container'>
          <span>
            Balance: $0.00
          </span>
        </div>
      </div>
      <div className='top_container'>
        <div>
          <label htmlFor="PaymentAmount">PAYMENT AMOUNT</label>
          <input type="text" name="" id="" />
          <div className='d-flex w-100 justify-content-center gap-4'>
            <div className='d-flex gap-1'>
              <input type="radio" name="" id="" style={{
                width: '20px',
                minWidth: '20px',
              }} />
              <span>Cuadre</span>
            </div>
            <div className='d-flex gap-1'>
              <input type="radio" name="" id="" style={{
                width: '20px',
                minWidth: '20px',
              }} />
              <span>Premio</span>
            </div>
          </div>
          <button type="button">
            send confirmation
          </button>
        </div>
      </div>
      <div className='bottom_container'>
        {/* <div role='alert' className='alert alert-danger' style={{ textAlign: 'center' }}>
          No records where found
        </div> */}
        <div className='tables_container'>
          <table>
            <thead>
              <tr style={{
                backgroundColor: '#f1f1f1',
              }}>
                <th colSpan={6}>
                  <div >
                    <span style={{
                      color: '#000',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                      fontSize: '12px',
                    }}>
                      last deliverys
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <th>
                  id
                </th>
                <th>
                  fecha
                </th>
                <th>
                  hora
                </th>
                <th>
                  monto
                </th>
                <th>
                  tipo
                </th>
                <th>
                  estado
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={6}>
                  <div role='alert' className='alert alert-danger' style={{ textAlign: 'center' }}>
                    No records where found
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default MoneyTransactionsConfirmation_t4_mobile