import { create } from "zustand";
import { redApi } from "../../config/Axios";
import { matchupLeagues } from "../../utils/matchUpLeagues";
import moment from "moment";
import Cookies from 'universal-cookie';

export const useGames = create((set, get) => ({
  gamesToday: [],
  ticketNumber: 0,
  isLoading: false,
  getGamesToday: async (leagueSelected) => {
    try {
      set({ isLoading: true });
      await redApi.get(
        matchupLeagues.includes(leagueSelected.name)
          ? `/games?leagueName=${leagueSelected.name}&date=${moment().format("YYYY-MM-DD")}&matchUps=true`
          : `/games?leagueName=${leagueSelected.name}&date=${moment().format("YYYY-MM-DD")}`
      )
        .then((res) => {
          set({ gamesToday: res.data });
        })
        .catch(res => console.log(res));

      const response2 = await redApi.get("/tickets?lastId=true"); // response2.data.lastTicketId
      const newToken2 = response2.data.newToken
      // const newToken = response2.headers['newToken'] || response2.headers['newtoken'] || response2.headers['NewToken'];
      if (newToken2) {
        // Guardar en cookies
       const cookies = new Cookies();
       cookies.remove("jwt", { path: '/' });
       cookies.set("jwt", newToken2, { path: '/'});
       console.log("Token guardado!!!");
        sessionStorage.setItem("token", newToken2);
      } else {
        console.log("newToken es undefined o no está presente.");
      }
      set({ ticketNumber: response2.data.lastTicketId });
      set({ isLoading: false });
    } catch (error) {
      console.log(error);
    }
  },
}));