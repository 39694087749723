import React from 'react'

export const CashOutTicket = () => {
  return (
    <>
      <div className='sales-report-date'>
        <div>
          <input type="text" placeholder='Enter the Ticket Payment Code' style={{ width: '300px', margin: '0 auto' }} />
        </div>
        <button>
          cash out
        </button>
      </div>
    </>
  )
}