import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import Swal from 'sweetalert2';
import { redApi } from '../../../../config/Axios';
import { SendTicket } from './SendTicket';
import { Contacts } from './Contacts';
import moment from 'moment';

export const WhatsAppModal = ({open, setOpen, handleBetPost}) => {
    const [sessionLoaded, setSessionLoaded] = useState(false);
    const [loadingContacts, setLoadingContacts] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [tickets, setTickets] = useState({loading: true, tickets: []});

    const [ticketsFilter, setTicketsFilter] = useState({initDate: "", finalDate: ""});

    const fetchSession = async () => {
        try {
            const req = await redApi.get("/whatsapp/checksession");
            if (req.status !== 200) {
              throw new Error("Error al obtener la sesion");
            }
            setSessionLoaded(true);
          } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Servicio inactivo",
                text: "Por favor contacte al administrador",
                timer: 1500,
                showConfirmButton: false
            })
            setOpen(false);
          }
    }
    useEffect(() => {
      if (open) {
        fetchSession();
        // setSessionLoaded(true);
        setTicketsFilter({
            initDate: moment().format("YYYY-MM-DD"), 
            finalDate: moment().add(1, "day").format("YYYY-MM-DD")
        })
      }
    }, [open]);
    
    const getContacts = async () => {
        setLoadingContacts(true);
        try {
            const { data } = await redApi.get("/whatsapp/contacts");
            setContacts(data);
        } catch (error) {
            console.log(error);
        }
        setLoadingContacts(false);
    }

    const getTickets = async () => {
        setTickets({...tickets, loading: true});
        try {
            const { data } = await redApi.get("/tickets", {
                params: {
                    initDate: ticketsFilter.initDate,
                    finalDate: ticketsFilter.finalDate
                }
            })
            console.log(data)
            setTickets({loading: false, tickets: data});
        } catch (error) {
            setTickets({loading: false, tickets: []});
            console.log(error);
        }
    }
    const fetchData = async () => {
        await getContacts();
        await getTickets();
    }
    useEffect(() => {
      if (sessionLoaded && ticketsFilter.initDate && ticketsFilter.finalDate) {
        fetchData()
      }
    }, [sessionLoaded, ticketsFilter]);

    const [menuActive, setMenuActive] = useState("Send");

    return (
        <Modal show={open} onHide={() => {setOpen(false)}} size="xl">
            <Modal.Header className="justify-content-center">
                <Modal.Title>WhatsApp</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="reportes-modal">
                    <div className="reportes-modal__menu">
                        <div
                            className={`reportes-modal__menu-item ${menuActive == "Send" ? "active" : ""}`}
                            onClick={() => setMenuActive("Send")}
                        >
                            <span>Send Ticket</span>
                        </div>
                        <div
                            className={`reportes-modal__menu-item ${menuActive == "Contact" ? "active" : ""}`}
                            onClick={() => setMenuActive("Contact")}
                        >
                            <span>Contacts</span>
                        </div>
                    </div>
                    <div className="reportes-modal__content">
                        { menuActive == "Send" 
                            ?   <SendTicket 
                                    setTicketsFilter={setTicketsFilter} 
                                    ticketsFilter={ticketsFilter}
                                    contacts={contacts}
                                    getTickets={getTickets}
                                    tickets={tickets}
                                    handleBetPost={handleBetPost}
                                />
                            : <Contacts menuActive={menuActive} setMenuActive={setMenuActive} contacts={contacts} getContacts={getContacts} loading={loadingContacts}/>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn' style={{backgroundColor: "#BDD4F4", color: "#131313", border: "1px solid #131313"}} type="button" onClick={() => {setOpen(false)}}>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}