import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../../auth';
import { redApi as api } from '../../../../../config/Axios';
import moment from 'moment';
import { Printer } from '../../../../printer/Print';
import Swal from 'sweetalert2';
import WebuserModal from '../../../../admin/pages/reportes/WebuserModals/WebuserModal';
import ReportesModal from '../../reportes/ReportesModal';
import { WhatsAppModal } from '../../whatsapp/WhatsAppModal';
import ModalDinamic from '../modals/ModalDinamic';
export default function Theme4_menu_mobile({ useTicketToSend, handleBetPost, menuOpen, handleChangeMenuMobile, handleOpenMenu }) {
  const { logout, userId, userName } = useContext(AuthContext);

  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    navigate('/', {
      replace: true
    });
  }

  const [whatsappModal, setWhatsappModal] = useState(false)
  const [showReports, setShowReports] = useState(false);
  const [tickets, setTickets] = useState([])
  const [inLoading, setInLoading] = useState(false)
  const [reportsType, setReportsType] = useState('')
  const handleReports = (show) => {
    setShowReports(!showReports)
    setReportsType(show)
  }
  const [reportsModalOpen, setReportsModalOpen] = useState(false)
  const handleReportsModal = () => {
    setReportsModalOpen(!reportsModalOpen)
  }
  useEffect(() => {
    if (showReports) {
      setInLoading(true)
      let initDate = moment().format('YYYY-MM-DD')
      let finalDate = moment().format('YYYY-MM-DD') + 'T23:59:59'
      api.get(`/tickets?initDate=${initDate}&finalDate=${finalDate}`) //today tickets
        .then(res => {
          let tickets = res.data.filter(ticket => {
            if (reportsType === 'reports') return ticket
            if (reportsType === 'cancelticket') return ticket.ticketState === 'Play'
            if (reportsType === 'reprint') return ticket.ticketState !== 'Cancelled'
            if (reportsType === 'salesResults') return ticket.ticketState === 'Winner' || ticket.ticketState === 'Loser'
          })
          setTickets(tickets)
          setInLoading(false)
        })
        .catch(err => console.log(err))
    }
  }, [showReports])



  const [printTicket, setPrintTicket] = useState({
    ticketId: 0,
    print: false
  });
  const handleRePrint = (ticketId) => {
    setPrintTicket({ ticketId, print: true })
  }

  const handleCancelTicket = (ticketId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        api.put(`/tickets/${ticketId}/cancel`)
          .then(res => {
            if (res.status === 200) {
              Swal.fire(
                'Canceled!',
                'Your ticket has been canceled.',
                'success'
              )
              // change ticket status 
              let newTickets = tickets.map(ticket => {
                if (ticket.ticketId === ticketId) {
                  ticket.status = 'Cancelled'
                  return ticket
                }
                return ticket
              })
              setTickets(newTickets)
              return
            }
          })
          .catch((err) => {
            console.log(err.response.data.message, 'err')
            Swal.fire(
              'Error!',
              `${err.response.data.message || 'Something went wrong'}`,
              'error',

            )
          })
      }
    })
  }


  // help modal 
  const [showHelp, setShowHelp] = useState(false)
  const hadnleshowHelp = () => {
    setShowHelp(!showHelp)
  }

  let helpTexts = [
    { key: 'm', text: 'Money Line' },
    { key: 'mh', text: 'Money Line H1' },
    { key: 'mt1', text: 'Money Line P1' },
    { key: 'mt2', text: 'Money Line P2' },
    { key: 'mt3', text: 'Money Line P3' },
    { key: 'r', text: 'Run Line' },
    { key: 'h', text: 'Run Line H' },
    { key: 'q1', text: 'Run Line Q1' },
    { key: 'q2', text: 'Run Line Q2' },
    { key: 'q3', text: 'Run Line Q3' },
    { key: 'q4', text: 'Run Line Q4' },
    { key: 't1', text: 'Run Line P1' },
    { key: 't2', text: 'Run Line P2' },
    { key: 't3', text: 'Run Line P3' },
    { key: 'hr2', text: 'Run Line H2' },
    { key: '+', text: 'Over' },
    { key: 'h+', text: 'Over H' },
    { key: 'q1+', text: 'Over Q1' },
    { key: 'q2+', text: 'Over Q2' },
    { key: 'q3+', text: 'Over Q3' },
    { key: 'q4+', text: 'Over Q4' },
    { key: 't1+', text: 'Over P1' },
    { key: 't2+', text: 'Over P2' },
    { key: 't3+', text: 'Over P3' },
    { key: 'h2+', text: 'Over H2' },
    { key: '-', text: 'Under' },
    { key: 'h-', text: 'Under H' },
    { key: 'q1-', text: 'Under Q1' },
    { key: 'q2-', text: 'Under Q2' },
    { key: 'q3-', text: 'Under Q3' },
    { key: 'q4-', text: 'Under Q4' },
    { key: 't1-', text: 'Under P1' },
    { key: 't2-', text: 'Under P2' },
    { key: 't3-', text: 'Under P3' },
    { key: 'h2-', text: 'Under H2' },
    { key: 's+', text: 'Solo +' },
    { key: 'h1s+', text: 'Solo +H' },
    { key: 'q1s+', text: 'Solo +Q1' },
    { key: 'q2s+', text: 'Solo +Q2' },
    { key: 'q3s+', text: 'Solo +Q3' },
    { key: 'q4s+', text: 'Solo +Q4' },
    { key: 'h2s+', text: 'Solo +H' },
    { key: 's-', text: 'Solo -' },
    { key: 'h1s-', text: 'Solo -H' },
    { key: 'q1s-', text: 'Solo -Q1' },
    { key: 'q2s-', text: 'Solo -Q2' },
    { key: 'q3s-', text: 'Solo -Q3' },
    { key: 'q4s-', text: 'Solo -Q4' },
    { key: 'h2s-', text: 'Solo -H' },
    { key: 'p', text: 'Super Run Line' },
    { key: 'y', text: 'Yes anotaran' },
    { key: 'n', text: 'No anotaran' },
    { key: 'k+', text: 'Strikeout +' },
    { key: 'k-', text: 'Strikeout -' },
    { key: 'f', text: 'First run' },
    { key: 'c', text: 'Buying' },
    { key: 'v', text: 'Senta' },
    { key: 'c+', text: 'B Over' },
    { key: 'c-', text: 'B Under' },
    { key: 'j1', text: 'Players 1' },
    { key: 'j2', text: 'Players 2' },
    { key: 'j3', text: 'Players 3' },
    { key: 'j4', text: 'Players 4' },
    { key: 'j5', text: 'Players 5' },
    { key: 'g', text: 'GS ML' },
    { key: 'g+', text: 'GS Over' },
    { key: 'g-', text: 'GS Under' },
    { key: 'se', text: 'Serie' },
    { key: 'ar', text: 'Run Line Alternativo' },
    { key: 't', text: 'Tercio' },
    { key: 't+', text: 'Tercio Mas' },
    { key: 't-', text: 'Tercio Menos' },
    { key: 'v+', text: 'S a mas' },
    { key: 'v-', text: 'S a menos' },
    { key: 'e', text: 'Draw' },
    { key: 'hr1', text: 'Run Line H' },
    { key: 'h1+', text: 'Over H' },
    { key: 'h1-', text: 'Under H' }
  ]


  const HelpModal = () => {
    return (
      <Modal
        open={showHelp}
        onCancel={hadnleshowHelp}
        footer={null}
      >
        <div className="row">
          <div className="col">
            <h3>How to use the system</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Enter</th>
                  <th scope="col">A Juego</th>
                </tr>
              </thead>
              <tbody>
                {
                  helpTexts.map((helpText, index) => (
                    <tr key={index}>
                      <td>{helpText.key}</td>
                      <td>{helpText.text}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    )
  }

  // date filters
  const handleFilters = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
  }

  const [filters, setFilters] = useState({
    fromDate: '',
    toDate: ''
  })

  // get tickets if filters change
  const handleGetTickets = async () => {
    let { fromDate, toDate } = filters
    // if toDate is today, add 24 hours to get all tickets
    if (toDate === moment().format('YYYY-MM-DD')) {
      toDate = moment().add(1, 'days').format('YYYY-MM-DD')
    }

    setInLoading(true)
    await api.get(`/tickets?initDate=${fromDate}&finalDate=${toDate}`)
      .then(res => {
        let tickets = res.data.filter(ticket => {
          if (reportsType === 'reports') return ticket
          if (reportsType === 'cancelticket') return ticket.ticketState === 'Play'
          if (reportsType === 'reprint') return ticket.ticketState !== 'Cancelled'
          if (reportsType === 'salesResults') return ticket.ticketState === 'Winner' || ticket.ticketState === 'Loser'
        })
        setTickets(tickets)
        setInLoading(false)
      })
      .catch(err => {
        console.log(err)
      }
      )
  }

  const [modalCrear, setModalCrear] = useState(false)
  const [userSelected, setUserSelected] = useState(null)
  const [modalEditar, setModalEditar] = useState(false)
  const [data, setData] = useState({
    // username: "",
    isGroup: "",
    // email: "",
    theme: 1,
    ownedBy: userId ? userId : '',
  });

  const habdleModalCrear = () => {
    setModalCrear(!modalCrear)
  }
  const [modalSelect, setModalSelect] = useState('')
  const modalToggle = (modalname) => {
    setModalSelect(modalname)
    setOpen(!open)
    console.log(modalname)
  }


  const [open, setOpen] = useState(false)

  let menuItemsMobile = [
    {
      name: 'Sales',
      icon: 'fas fa-chart-line',
    },
    {
      name: 'Historic Sales',
      icon: 'fas fa-history',
    },
    {
      name: 'Monitoring',
      icon: 'fas fa-tv',
    },
    {
      name: 'Pending Of Payment',
      icon: 'fas fa-money-bill-alt',
    },
    {
      name: 'Cancel ticket',
      icon: 'fas fa-times',
    },
    {
      name: 'Ticket payout',
      icon: 'fas fa-ticket-alt',
    },
    {
      name: 'Copy ticket',
      icon: 'fas fa-copy',
    },
    {
      name: 'Money transactions confirmation',
      icon: 'fas fa-money-check-alt',
    },
    {
      name: 'Online accounts',
      icon: 'fas fa-user',
    },
    {
      name: 'Show odds',
      icon: 'fas fa-eye',
    },
    {
      name: 'Logout',
      icon: 'fas fa-sign-out-alt',
    }
  ]


  return (
    <nav className={`menu_container`} id='menu_mobile'>
      {
        menuItemsMobile.map((item, index) => {
          return (
            <div key={index} className='header_container_item' onClick={item.name !== 'Logout' ? () => {
              handleChangeMenuMobile(item.name)
              handleOpenMenu()
            }
              : onLogout
            }>
              <i className={item.icon}></i>
              <span>{item.name}</span>
            </div>
          )
        })
      }
    </nav>
  )
}
