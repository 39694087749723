import React, { useEffect, useState } from 'react'
import Layout from '../structures/layout'
import { redApi } from '../../../config/Axios'
import moment from 'moment'

export default function Prizes() {
  const [leagues, setLeagues] = React.useState([])
  const [games, setGames] = React.useState([])
  const [ticketsWinners, setTicketsWinners] = React.useState([])
  const [tickets, setTickets] = React.useState([])
  const [saleByConsorcio, setSaleByConsorcio] = React.useState([])
  // filters
  const [filters, setFilters] = React.useState({
    date: moment().format('YYYY-MM-DD'),
    league: ''
  })

  const handleFilters = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
  }

  const getLeagues = async () => {
    await redApi.get('/leagues')
      .then(res => {
        setLeagues(res.data)
      })
  }
  const getGames = async () => {
    if (filters.league === '') {
      await redApi.get(`/games?status=true&date=${filters.date}&prize=true`)
        .then(res => {
          setGames(res.data.games)
          let tickets = []
          res.data?.tickets?.map(ticket => {
            ticket.ticketState = 'Winner' ? tickets.push(ticket) : null
          })
          setTicketsWinners(tickets)
          setTickets(res.data.tickets)
          setSaleByConsorcio(res.data.salesByConsorcio)
        })
      return
    }
    await redApi.get(`/games?status=true&date=${filters.date}&league=${filters.league}&prize=true`)
      .then(res => {
        // console.log(res.data)
        setGames(res.data.games)
        let tickets = []
        res.data?.tickets?.map(ticket => {
          ticket.ticketState = 'Winner' ? tickets.push(ticket) : null
        })
        setTicketsWinners(tickets)
        setSaleByConsorcio(res.data.salesByConsorcio)
        setTickets(res.data.tickets)
      })
  }
  // useEffect(() => {
  //   getGames()
  // }, [])

  useEffect(() => {
    getLeagues()
    getGames()
  }, [filters])

  return (
    <Layout>
      <div className="container bg-card p-4">
        <div className='row mb-2'>
          <div className='col-12'>
            <h5>Juegos Ganadores</h5>
          </div>
        </div>
        <form>
          <div className="row button-nav mb-4 d-flex align-items-center">
            <div className="col-6 mb-2 d-flex align-items-center justify-content-center">
              <label htmlFor="datePremios">Seleccione la Fecha</label>
              <input type="date" id="datePremios" name='date' onChange={handleFilters} defaultValue={moment().format("YYYY-MM-DD")} />
            </div>
            <div className="col-6 mb-2 d-flex align-items-center justify-content-center">
              <label htmlFor="lotTypeSelect">Liga: </label>
              <select id="lotTypeSelect" name='league' onChange={handleFilters} >
                <option value={'all'}>Todas</option>
                {
                  leagues.map((league, index) => {
                    return (
                      <option key={index} value={league._id}>{league.name}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>
        </form>

        <div className="row">
          <div className="col-12">
            <div id="divTablesPrizesv2" className="">
              <div className="">
                <div id="">
                  <table className="">
                    <thead id=" ">
                      <tr>
                        <th
                          style={{
                            textAlign: "center",
                          }}
                        >Fecha</th>
                        <th
                          style={{
                            textAlign: "center",
                          }}
                        >Liga</th>
                        <th
                          style={{
                            textAlign: "center",
                          }}
                        >Teams</th>
                        <th
                          style={{
                            textAlign: "center",
                          }}
                        >Resultado</th>
                      </tr>
                    </thead>
                    <tbody id="">
                      {
                        games?.map((game, index) => {
                          return (
                            <tr key={index}
                              style={{
                                height: "53px",
                              }}
                            >
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {moment(game.time).format('ddd DD MMM YYYY')}
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}>
                                  {game.league.name}
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  {game.localTeam.name} <br /> vs <br /> {game.visitingTeam.name}
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    lineHeight: "0.8"
                                  }}
                                >
                                  {game.localLine.scores?.total} <br />  - <br />  {game.visitingLine.scores?.total}
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }

                      {
                        games?.length === 0 &&
                        <tr>
                          <td colSpan="4">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                textAlign: "center",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              No hay juegos para esta fecha y/o liga
                            </div>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="">
                <div className="" id="">
                  <table className="">
                    <thead id="tableHeadLotPrizes">
                      <tr>
                        {/* <th>Games</th> */}
                        <th style={{
                          textAlign: "center",
                        }}>#Ticket </th>
                        <th style={{
                          textAlign: "center",
                        }}>Tipo de ticket</th>
                        <th style={{
                          textAlign: "center",
                        }}>Premio</th>
                      </tr>
                    </thead>
                    <tbody id="tableListLotPrizes">
                      {
                        ticketsWinners.map((ticket, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}>
                                  #{ticket.ticketId}
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  {ticket.bets.length === 1 ? 'Direct' : ticket.bets.length === 2 ? 'Pale' : ticket.bets.length === 3 ? 'Tripleta' : ticket.bets.length === 4 ? 'Cuadruple' : ticket.bets.length === 5 ? 'Quintuple' : ticket.bets.length === 6 ? 'Sextuple' : ticket.bets.length === 7 ? 'Septuple' : ticket.bets.length === 8 ? 'Octuple' : ticket.bets.length === 9 ? 'Nonuple' : ticket.bets.length === 10 ? 'Decuple' : 'N/A'}
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  $ {ticket?.toCollect || 0}
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="">
                <table className="">
                  <thead>
                    <tr>
                      <th style={{
                        textAlign: "center",
                      }} className='col'>Consorcio</th>
                      <th style={{
                        textAlign: "center",
                      }} className='col'>Venta Bruta</th>
                      <th style={{
                        textAlign: "center",
                      }} className='col'>Comisión</th>
                      <th style={{
                        textAlign: "center",
                      }} className='col'>Sacado</th>
                      <th style={{
                        textAlign: "center",
                      }} className='col'>Resumen</th>
                      <th style={{
                        textAlign: "center",
                      }} className='col'>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      saleByConsorcio?.map((sale, index) => {
                        return (
                          <tr key={index}>
                            <td style={{
                              textAlign: "center",
                            }} className='col'>
                              {sale.consorcio}
                            </td>
                            <td style={{
                              textAlign: "center",
                            }} className={`col ${sale.totalSale < 0 ? 'text-danger' : ''}`}>
                              ${sale.totalSale}
                            </td>
                            <td style={{
                              textAlign: "center",
                            }} className={`col ${sale.fee < 0 ? 'text-danger' : ''}`}>
                              ${sale.fee}
                            </td>
                            <td style={{
                              textAlign: "center",
                            }} className={`col ${sale.totalToPay < 0 ? 'text-danger' : ''}`}>
                              ${sale.totalToPay}
                            </td>
                            <td style={{
                              textAlign: "center",
                            }} className={`col ${sale.summary < 0 ? 'text-danger' : ''}`}>
                              ${sale.summary}
                            </td>
                            <td style={{
                              textAlign: "center",
                              color: `${sale.summary < 0 ? 'white' : 'white'}`,
                              fontWeight: 'bold',
                              backgroundColor: `${sale.summary < 0 ? '#ff8282' : 'green'}`,
                            }} className={`col`}>
                              $ {sale.summary}
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  <tbody id="tableTotal">
                    <tr>
                      <td className='text-center'>
                        {saleByConsorcio?.length > 0 && 'Total'}
                      </td>
                      <td className='text-center'>
                        ${saleByConsorcio?.length > 0 && saleByConsorcio?.reduce((a, b) => a + b.totalSale, 0)}
                      </td>
                      <td className='text-center'>
                        ${saleByConsorcio?.length > 0 && saleByConsorcio?.reduce((a, b) => a + b.fee, 0)}
                      </td>
                      <td className='text-center'>
                        ${saleByConsorcio?.length > 0 && saleByConsorcio?.reduce((a, b) => a + b.totalToPay, 0)}
                      </td>
                      <td className='text-center'>
                        ${saleByConsorcio?.length > 0 && saleByConsorcio?.reduce((a, b) => a + (b.summary), 0)}
                      </td>
                      <td className='text-center'>
                        ${saleByConsorcio?.length > 0 && saleByConsorcio?.reduce((a, b) => a + (b.summary), 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
