import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Sidebar from '../navigation/Sidebar';
import SidebarNew from '../navigation/sidebarNew';
import './layout.scss';

const Layout = ({ children }, alignment) => {

    return (
        <main id="bodyAdmin" className='container-fluid p-0 m-0'>
            {/* <Row> */}
            <SidebarNew />
            {/* <Col xs={12} lg={3} className="sidebar">
                    <Sidebar />
                </Col> */}
            <div id="containerHome" className="body"  >
                {children}
            </div>
            {/* </Row> */}
        </main>
    );


}

export default Layout;