import React, { useEffect, useState } from 'react'

const ParlayCalculator = () => {
  const [odds, setOdds] = useState([])
  const [betAmount, setBetAmount] = useState(0)
  const handleAdd = () => {
    const betAmount = document.getElementById('betAmount').value
    const oddPrice = document.getElementById('oddPrice').value
    if (betAmount && oddPrice) {
      setOdds([...odds, { oddPrice }])
      setBetAmount(betAmount)
    }
  }

  useEffect(() => {
    console.log({ odds })
    console.log({ betAmount })
  }, [odds])
  return (
    <>
      <div className='d-flex justify-content-center align-items-center gap-4 mb-4'>
        <div className='d-flex flex-column gap-2'>
          <label htmlFor="betAmount">Bet Amount:</label>
          <input type="text" name="betAmount" id="betAmount" />
        </div>
        <div className='d-flex flex-column gap-2'>
          <label htmlFor="oddPrice">Odd:</label>
          <input type="text" name="oddPrice" id="oddPrice" />
        </div>
        <button onClick={handleAdd}
          className='btn btn-info'
          style={{
            color: '#fff',
          }}
        >Add</button>
      </div>
      {
        odds.length > 0 ? (
          <div className='sales-report-tables'>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Odd</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    odds.map((odd, index) => (
                      <tr key={index}>
                        <td>{odd.oddPrice}</td>
                        <td>
                          <button onClick={() => setOdds(odds.filter((odd, i) => i !== index))}
                            style={{
                              backgroundColor: '#d9534f',
                              color: '#fff',
                              border: 'none',
                              padding: '1px 5px',
                              borderRadius: '3px'
                            }}><i className="fas fa-trash-alt"></i></button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="alert alert-warning">
            Insert atleast one Odd and press <b>Enter</b>
          </div>
        )
      }
      <div className='d-flex justify-content-center align-items-center gap-4 mt-4'>
        <div>
          <label htmlFor="toWin">To Win</label>
          <input type="text" name="toWin" id="toWin" disabled />
        </div>
        <div>
          <label htmlFor="payout">Payout</label>
          <input type="text" name="payout" id="payout" disabled />
        </div>
      </div>
    </>
  )
}

export default ParlayCalculator