import React, { useEffect } from 'react'
import Ruleswallpaper from '../../../../assets/img/Rules-wallpaper-07-scaled.jpg'
function Mma() {

  useEffect(() => {
    let container = document.querySelector(".rules-sport");
    // set background image
    container.style.backgroundImage = `url(${Ruleswallpaper})`
    container.style.backgroundSize = "cover"
    container.style.backgroundPosition = "center"
    container.style.backgroundRepeat = "no-repeat"
    container.style.backgroundAttachment = "fixed"
    container.style.height = "40vh"
    container.style.width = "100vw"
    container.style.display = "flex"
    container.style.flexDirection = "column"
    container.style.justifyContent = "center"
    container.style.alignItems = "center"
  }, [])
  return (
    <div className="rules-sport mma">
      <h1>
        Mixed Martial Arts
      </h1>
      <ul>
        <li>
          The result at the end of the event is final. This includes any count of the judges’ cards. Any subsequent changes made by regulatory bodies do not count for the purpose of the bet.
        </li>
        <li>
          Ties: If a fight ends in a tie and no odds have been offered for this option, all bets will be void.
        </li>
      </ul>
    </div>
  )
}

export default Mma