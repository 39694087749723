import { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
// import WebuserModal from './WebuserModals/WebuserModal'
import { redApi } from '../../../../../config/Axios'
import { Modal } from 'antd'
import Swal from 'sweetalert2'
import moment from 'moment'

const ModalCargaYDescarga = ({ modalCargaYDescarga, setModalCargaYDescarga, data, setData }) => {

    const handleInputChange = (e) => {
        e.preventDefault();
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await redApi.put(`/users/webUser/wallet`, {
            ...data,
            amount: parseInt(data.amount),
        })
            .then(res => {
                Swal.fire({
                    icon: 'success',
                    title: 'Transacción realizada con éxito',
                    showConfirmButton: false,
                    timer: 1500
                })
                setModalCargaYDescarga(false)
                setData({
                    userId: '',
                    transactionType: '',
                    description: '',
                    amount: '',
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Modal
            title="Carga y Descarga"
            open={modalCargaYDescarga}
            onCancel={() => setModalCargaYDescarga(false)}
            footer={null}
        >
            <form onSubmit={handleSubmit}>
                <div className="row m-0 p-0">
                    <div className="col-12 m-0 p-0">
                        <label htmlFor="quantity">Cantidad</label>
                        <input
                            type="number"
                            className="form-control"
                            name="amount"
                            onChange={handleInputChange}
                            value={data.amount}
                            required
                            id="quantity"
                        />
                    </div>
                    <div className="col-12 m-0 p-0">
                        <label htmlFor="description">Descripción</label>
                        <input
                            type="text"
                            className="form-control"
                            name="description"
                            onChange={handleInputChange}
                            value={data.description}
                            id="description"
                        />
                    </div>
                    <div className="col-12 m-0 p-0">
                        <Button type="submit" className="btn btn-primary mt-3">Confirmar</Button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

const ModalHistorial = ({ modalHistorial, setModalHistorial, userId }) => {
    let [historial, setHistorial] = useState([])
    const [check, setCheck] = useState(false)
    const [date, setDate] = useState({
        initDate: moment().format('YYYY-MM-DD'),
        finalDate: check ? moment().format('YYYY-MM-DD') : ''
    })

    const [errors, setErrors] = useState({
        initDate: '', // Fecha de inicio no puede ser mayor a la fecha final, Fecha de inicio no puede ser mayor a la fecha actual
        finalDate: '' // Fecha final no puede ser mayor a la fecha actual
    })


    const handleCkeck = (e) => {
        if (e.target.checked) {
            setCheck(true)
            setDate({
                ...date,
                finalDate: moment().format('YYYY-MM-DD'),
                initDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
            })
        } else {
            setCheck(false)
            setDate({
                ...date,
                finalDate: '',
                initDate: moment().format('YYYY-MM-DD')
            })
            setErrors({
                ...errors,
                finalDate: ''
            })
        }
    }

    const handleChangeDate = (e) => {
        if (e.target.name === 'initDate' && date.finalDate && moment(e.target.value).isAfter(date.finalDate)) {
            setErrors({
                ...errors,
                initDate: 'Fecha de inicio no puede ser mayor a la fecha final'
            })
            return
        } else if (e.target.name === 'initDate' && moment(e.target.value).isAfter(moment().format('YYYY-MM-DD'))) {
            setErrors({
                ...errors,
                initDate: 'Fecha de inicio no puede ser mayor a la fecha actual'
            })
            return
        } else if (e.target.name === 'finalDate' && moment(e.target.value).isAfter(moment().format('YYYY-MM-DD'))) {
            setErrors({
                ...errors,
                finalDate: 'Fecha final no puede ser mayor a la fecha actual'
            })
            return
        } else if (e.target.name === 'finalDate' && date.initDate && moment(e.target.value).isBefore(date.initDate)) {
            setErrors({
                ...errors,
                finalDate: 'Fecha final no puede ser menor a la fecha de inicio'
            })
            return
        }
        else {
            setErrors({
                ...errors,
                initDate: '',
                finalDate: ''
            })
        }

        if (errors.initDate || errors.finalDate) return

        setDate({
            ...date,
            [e.target.name]: e.target.value
        })
    }

    const getHistorial = async () => {
        if (date.initDate && date.finalDate && moment(date.initDate).isAfter(date.finalDate)) return
        if (userId === '' || userId === undefined) return
        await redApi.get(`/users/webUser/transactions?userId=${userId}${date.initDate && date.finalDate ? '&initDate=' + date.initDate + '&finalDate=' + date.finalDate : date.initDate ? '&initDate=' + date.initDate : ''}`)
            .then(res => {
                setHistorial(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getHistorial()
    }, [userId, date.initDate || date.finalDate])

    return (
        <Modal
            title="Historial"
            open={modalHistorial}
            onCancel={() => setModalHistorial(false)}
            footer={null}
            width={900}
        >
            <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center gap-4 mb-4">
                    <div>
                        <label htmlFor="initDate">Fecha {check ? 'de inicio' : ''}</label>
                        <input
                            type="date"
                            name='initDate'
                            className="date input-group-text"
                            onChange={handleChangeDate}
                            value={date.initDate}
                            id="initDate"
                            style={{
                                border: errors.initDate ? '1px solid red' : '1px solid #ced4da',
                            }}
                        />
                        {errors.initDate && <p className="text-danger">{errors.initDate}</p>}
                    </div>
                    <div className='d-flex gap-2'>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={handleCkeck}
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Rango de fechas
                        </label>
                    </div>
                    {
                        check &&
                        <div>
                            <label htmlFor="finalDate">Fecha final</label>
                            <input
                                type="date"
                                name='finalDate'
                                className="date input-group-text"
                                onChange={handleChangeDate}
                                value={date.finalDate}
                                id="finalDate"
                                style={{
                                    border: errors.finalDate ? '1px solid red' : '1px solid #ced4da',
                                }}
                            />
                            {errors.finalDate && <p className="text-danger">{errors.finalDate}</p>}
                        </div>
                    }
                </div>
                <div className="col-12 m-0 p-0" id="table_container">
                    <table className="table table-striped table-hover table-bordered table-sm">
                        <thead>
                            <tr>
                                <th scope="col" className='text-center'>Fecha</th>
                                <th scope="col" className='text-center'>Descripción</th>
                                <th scope="col" className='text-center'>Cantidad</th>
                                <th scope="col" className='text-center'>Venta</th>
                                <th scope="col" className='text-center'>Premios</th>
                                <th scope="col" className='text-center'>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                historial?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='text-center'>{moment(item.time).format('DD/MM/YYYY HH:mm')}</td>
                                            <td className='text-center'>{item.description}</td>
                                            <td className='text-center'>{item.amount}</td>
                                            <td className='text-center'>{item.sell}</td>
                                            <td className='text-center'>{item.price}</td>
                                            <td className='text-center'>{item.balance}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>
    )
}



export default function WebUsers() {

    const [posSelected, setPosSelected] = useState(null)

    const [consorcios, setConsorcios] = useState([])
    const [pos, setPos] = useState([])
    const [posToRender, setPosToRender] = useState([])

    // filters by consorcio and pos
    const [consorcioSelected, setConsorcioSelected] = useState(null)


    const handlchangeConsorcio = (e) => {
        setConsorcioSelected(e.target.value)
        if (e.target.value === 'all') {
            setPosToRender(pos)
        } else {
            let posFiltered = pos.filter(p => p.ownedBy._id === e.target.value)
            setPosToRender(posFiltered)
        }
    }

    const handleChangePos = (e) => {
        if (e.target.value === 'all') {
            setPosToRender(pos)
        } else {
            let posFiltered = pos.filter(p => p._id === e.target.value)
            setPosToRender(posFiltered)
        }
    }

    const getPos = async () => {
        redApi.get('/users?userLevel=3&populates=ownedBy,ownerOf')
            .then(res => {
                setPos(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getCosorcios = async () => {
        redApi.get('/users?userLevel=2')
            .then(res => {
                setConsorcios(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getCosorcios()
        getPos()
    }, [])

    const handleClick = (pos) => {
        setPosSelected(pos)
    }

    const [modalCargaYDescarga, setModalCargaYDescarga] = useState(false)
    const [data, setData] = useState({
        userId: '',
        transactionType: '',
        description: '',
        amount: ''
    })

    const handleUserSelected = (user, type) => {
        setData({
            ...data,
            userId: user._id,
            transactionType: type,
            description: type === 'Carga' ? 'Carga de saldo' : 'Descarga de saldo'
        })
        setModalCargaYDescarga(true)
    }

    const [modalHistorial, setModalHistorial] = useState(false)
    const [userSelected, setUserSelected] = useState(null)
    const handelModalHistorial = (user) => {
        setUserSelected(user)
        setModalHistorial(true)
    }


    return (
        <div className="container-fluid p-0  m-0" id='webuser_container'>
            <ModalCargaYDescarga modalCargaYDescarga={modalCargaYDescarga} setModalCargaYDescarga={setModalCargaYDescarga} data={data} setData={setData} />
            <ModalHistorial userId={userSelected?._id} modalHistorial={modalHistorial} setModalHistorial={setModalHistorial} />

            {/* <WebuserModal setModalEditar={setModalEditar} modalEditar={modalEditar} usuario={userSelected} modalCrear={modalCrear} setModalCrear={setModalCrear} /> */}
            <div className="row m-0 p-0">
                <div className="col-12  m-0 p-0 bg-gray-800 py-4">
                    <div className="row m-0 p-0 gap-3">
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Consorcio</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control"
                                                onChange={handlchangeConsorcio}
                                            >
                                                <option value='all'>Todos</option>
                                                {consorcios?.map((cons, index) => (
                                                    <option key={index} value={cons._id}>{cons.username}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">POS</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control"
                                                onChange={handleChangePos}
                                            >
                                                <option value='all'>Todos</option>
                                                {pos?.map((p, index) => (
                                                    <option key={index} value={p._id}>{p.username}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0 text-nowrap">Web User</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <input type="button" value="Refrescar" />
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 py-4" id="table_container">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Consorcio</th>
                                <th scope="col">POS</th>
                                <th scope="col"></th>
                                <th scope="col">Web User</th>
                                <th scope="col">Carga</th>
                                <th scope="col">Descarga</th>
                                <th scope="col"></th>
                                <th scope="col">Balance Actual</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                posToRender?.length > 0 ? posToRender?.map((p, index) => (
                                    <>
                                        <tr key={index} onClick={() => handleClick(p)}>
                                            <td>
                                                {p.ownedBy?.username}
                                            </td>
                                            <td>
                                                <div className='col-12'>
                                                    {p.username}
                                                </div>
                                            </td>
                                            <td>
                                                {/* <button>
                                                    crear usuario
                                                </button> */}
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#f3f3f3" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <circle cx="12" cy="7" r="4" />
                                                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                </svg>
                                            </td>
                                            <td>

                                            </td>
                                            <td>

                                            </td>
                                            <td>
                                                <button className='button'
                                                    onClick={() => handelModalHistorial(p)}
                                                >
                                                    ver historial
                                                </button>
                                            </td>
                                            <td>

                                            </td>
                                        </tr>
                                        {
                                            posSelected?._id === p._id && (
                                                p?.ownerOf?.map((w, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            {w.username}
                                                        </td>
                                                        <td>
                                                            <button className='carga_descarga'
                                                                onClick={() => handleUserSelected(w, 'Carga')}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <line x1="12" y1="5" x2="12" y2="19" />
                                                                    <line x1="5" y1="12" x2="19" y2="12" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button className='carga_descarga'
                                                                onClick={() => handleUserSelected(w, 'Descarga')}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-minus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <line x1="5" y1="12" x2="19" y2="12" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button className='button'
                                                                onClick={() => handelModalHistorial(w)}
                                                            >
                                                                ver historial
                                                            </button>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                ))
                                            )
                                        }
                                    </>
                                ))
                                    : pos?.map((p, index) => (
                                        <>
                                            <tr key={index} onClick={() => handleClick(p)}>
                                                <td>
                                                    {p.ownedBy.username}
                                                </td>
                                                <td>
                                                    <div className='col-12'>
                                                        {p.username}
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* <button>
                                                    crear usuario
                                                </button> */}
                                                </td>
                                                <td>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#f3f3f3" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <circle cx="12" cy="7" r="4" />
                                                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                    </svg>
                                                </td>
                                                <td>

                                                </td>
                                                <td>

                                                </td>
                                                <td>
                                                    <button className='button'
                                                        onClick={() => handelModalHistorial(p)}
                                                    >
                                                        ver historial
                                                    </button>
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            {
                                                posSelected?._id === p._id && (
                                                    p?.ownerOf?.map((w, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                {w.username}
                                                            </td>
                                                            <td>
                                                                <button className='carga_descarga'
                                                                    onClick={() => handleUserSelected(w, 'Carga')}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button className='carga_descarga'
                                                                    onClick={() => handleUserSelected(w, 'Descarga')}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-minus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button className='button'
                                                                    onClick={() => handelModalHistorial(w)}
                                                                >
                                                                    ver historial
                                                                </button>
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    ))
                                                )
                                            }
                                        </>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    )
}
