import React from 'react'

export default function BtnTicket() {

    // MENU DESPLEGABLE
    const menuTickets = () => {
        document.getElementById('left-container').classList.remove('col-lg-3');
        document.getElementById('left-container').classList.add('col-lg-auto');
        //document.getElementById('left-container').classList.add('d-none');
        document.getElementById('upcomingMatches').setAttribute('class', 'd-none')
        document.getElementById('middle-container').setAttribute('class', 'col bg-light px-1')
        //document.getElementById('right-container').classList.remove('col-lg-0');
        //document.getElementById('right-container').classList.remove('d-none');
        //document.getElementById('right-container').classList.add('col-lg-3');
        document.getElementById('btnTicketsMenu').classList.add('d-none');
        document.getElementById('btnTicketsCloseMenu').classList.remove('d-none');
    }
    const menuTicketsClose = () => {
        document.getElementById('left-container').classList.remove('col-lg-0');
        document.getElementById('left-container').classList.remove('d-none');
        document.getElementById('left-container').classList.add('col-lg-auto');
        document.getElementById('upcomingMatches').setAttribute('class', 'live-match-wrapper live-bg col-custom-9 d-flex flex-column')
        document.getElementById('middle-container').setAttribute('class', 'col bg-light px-1')
        //document.getElementById('right-container').classList.remove('col-lg-3');
        //document.getElementById('right-container').classList.add('col-lg-0');
        //document.getElementById('right-container').classList.add('d-none');
        document.getElementById('btnTicketsCloseMenu').classList.add('d-none');
        document.getElementById('btnTicketsMenu').classList.remove('d-none');
    }

  return (
    <>
        <button id="btnTicketsMenu" onClick={menuTickets}>Toggle</button>
        <button className="d-none" id="btnTicketsCloseMenu" onClick={menuTicketsClose}>Show</button>
    </>
  )
}
