import React from 'react'
import KeyboardVrtual from '../KeyboardVrtual'

const NewAccount_t4_mobile = ({ setMenuActive, menuActive }) => {
  return (
    <div className='sub_menu-container'>
      <button onClick={() => setMenuActive('')}>
        <i className="fas fa-arrow-left"></i>
      </button>
      <div className='container_sub_menu'>
        <div>
          <label htmlFor="selectUser">
            NEW ACCOUNT
          </label>
        </div>
        <div className='bottom_container'>
          <div>
            <div className='d-flex flex-column justify-content-center align-items-start w-100'>
              <label htmlFor="">
                Name:
              </label>
              <input type="text" className='w-100' name='name' id='name' />
            </div>
            <div className='d-flex flex-column justify-content-center align-items-start w-100'>
              <label htmlFor="">
                Username:
              </label>
              <input type="text" className='w-100' name='username' id='username' />
            </div>
            <div className='d-flex flex-column justify-content-center align-items-start w-100'>
              <label htmlFor="">
                Password:
              </label>
              <input type="text" className='w-100' name='password' id='password' />
            </div>
            <div className='d-flex flex-column justify-content-center align-items-start w-100'>
              <label htmlFor="">
                Language:
              </label>
              <select name="language" id="language" className='w-100'>
                <option value="English">English</option>
                <option value="Español">Español</option>
              </select>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-start w-100'>
              <label htmlFor="">
                Name:
              </label>
              <input type="text" className='w-100' name='name' id='name' />
            </div>
            <div className='d-flex flex-column justify-content-center align-items-start w-100 mb-3'>
              <label htmlFor="">
                Beginning Credit:
              </label>
              <input type="number" className='w-100' name='beginningCredit' id='beginningCredit' />
            </div>
            <div className='d-flex justify-content-center align-items-start w-100 gap-4'>
              <button className='d-flex justify-content-center align-items-center gap-1'
                style={{
                  backgroundColor: '#397cb3',
                  boxShadow: '0px 0px 5px 0px #397cb3',
                  width: '100px',
                  minWidth: '100px'
                }}
              >
                <i className="fa fa-floppy-o" aria-hidden="true"></i>
                <span>
                  Create
                </span>
              </button>
              <button className='d-flex justify-content-center align-items-center gap-1'
                style={{
                  backgroundColor: '#dc3545',
                  boxShadow: '0px 0px 5px 0px #dc3545',
                  width: '100px',
                  minWidth: '100px'
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
                <span>
                  Delete
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewAccount_t4_mobile




