import { types } from '../types/types';


export const authReducer = ( state = {}, action ) => {


    switch (action.type) {
        case types.login:
            return {
                ...state,
                logged: true,
                token: action.payload.token,
                userLevel: action.payload.userLevel,
                userTheme: action.payload.userTheme,
                userId: action.payload.userId
            };

        case types.logout:
            return {
                logged: false,
            };
    
        default:
            return state;
    }
}