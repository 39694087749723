
const ticketTypeDictionary = {
    "1": "Direct",
    "2": "Pale",
    "3": "Tripleta",
    "4": "Cuarteta",
    "5": "Quinteta",
    "6": "Sexteta",
    "7": "Septeta",
    "8": "Octeta",
    "9": "Noneta",
    "10": "Decima",
    "11": "Decima primera",
    "12": "Decima segunda",
    "13": "Decima tercera",
    "14": "Decima cuarta",
    "15": "Decima quinta",
    "16": "Decima sexta",

}
export const getTicketType = (ticketBetsLength) => {
    const ticketType = ticketTypeDictionary?.[`${ticketBetsLength}`] ?? "N/A";
    return ticketType;
    // switch (ticketBetsLength) {
    //     case 1: return "Direct";
    //     case 2: return "Pale";
    //     case 3: return "Tripleta";
    //     case 4: return "Cuarteta";
    //     case 5: return "Quinteta";
    //     case 6: return "Sexteta";
    //     case 7: return "Septeta";
    //     case 8: return "Octeta";
    //     case 9: return "Noneta";
    //     case 10: return "Decima";
    //     case 11: return "Decima primera";
    //     case 12: return "Decima segunda";
    //     case 13: return "Decima tercera";
    //     case 14: return "Decima cuarta";
    //     case 15: return "Decima quinta";
    //     case 16: return "Decima sexta";
    //     default: return "N/A";
    // }
}
