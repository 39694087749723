import moment from 'moment'
import React from 'react'

const WinningTickets = () => {
  return (
    <>
      <div className='sales-report-date'>
        <div>
          <input type="date" data-date="" data-date-format="DD MMMM YYYY" defaultValue={moment().startOf('day').format("YYYY-MM-DD")} />
          <span>
            to
          </span>
          <input type="date" data-date="" data-date-format="DD MMMM YYYY" defaultValue={moment().endOf('day').format("YYYY-MM-DD")} />
        </div>
        <button>
          Show Reports
        </button>
      </div>
    </>
  )
}

export default WinningTickets