import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { redApi } from '../../../../config/Axios'

const CrtlDeCompra = () => {
    const [menuSelects, setMenuSelects] = useState([{
        id: 1,
        label: 'league',
        options: []
    },
    {
        id: 2,
        label: 'line',
        options: ["Todos", "RL", "A MAS", "A MENOS"]
    },
    {
        id: 3,
        label: 'polarity',
        options: ['Todos', 'HEMBRA', 'MACHO']
    },
    {
        id: 4,
        label: 'points',
        input: true
    }
    ])

    const [dataTable, setDataTable] = useState([])
    const [filters, setFilters] = useState({
        league: '',
        line: '',
        polarity: '',
        range: '',
        points: ''
    })

    const getLinesControl = async () => {
        const params = {
            typeControl: "BUY"
        };
        if (filters.league) params.league = filters.league;
        if (filters.line) params.line = filters.line;
        if (filters.polarity) params.polarity = filters.polarity;
        if (filters.range) params.range = filters.range;
        if (filters.points) params.points = filters.points;
        try {
            const { data } = await redApi.get("linesControl", { params });
            setDataTable(data)
        } catch (error) {
            console.log(error)
        }
    }
    const initFetchData = async () => {
        await getLinesControl();
        const { data } = await redApi.get('/leagues');
        setMenuSelects(prevState => {
            return prevState.map(item => {
                if (item.id === 1) {
                    return {
                        ...item,
                        options: data.map(item => {
                            return {
                                id: item._id,
                                name: item.name
                            }
                        })
                    }
                } else {
                    return item
                }
            })
        })
    }
    useEffect(() => {
        initFetchData()
    }, [])

    useEffect(() => {
        getLinesControl();
        return () => {
            setDataTable(prev => prev)
        }
    }, [filters])

    const [updates, setUpdates] = useState([])

    const handleUpdate = (_id, price) => {
        setUpdates([
            ...updates,
            {
                id: _id,
                price
            }
        ])
    }

    const handleSave = () => {
        Swal.fire({
            title: 'Actualizando...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        })
        redApi.put('/linesControl', {
            updates
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: `${response.data.message}`,
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-3 d-flex mb-2">
                            <button 
                                className="btn btn-outline-primary" 
                                style={{ fontSize: '12px' }}
                                onClick={() => getLinesControl()}
                            >Refrescar</button>
                            <button 
                                className="btn btn-outline-primary" 
                                style={{ fontSize: '12px' }}
                                onClick={() => handleSave()}
                            >Salvar</button>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12">
                        <div className="row">
                            {menuSelects.map((item, index) => {
                                return (
                                    <div key={index} className="col-3">
                                        <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                            <label className='w-25' style={{ fontSize: '12px' }} >{item.label}</label>
                                            {item.input ?
                                                <input type="number" className="w-75 input-group-text" onBlur={(e) => setFilters(prevState => ({ ...prevState, points: e.target.value }))} />
                                                :
                                                <select className="w-75 form-select" aria-label="Default select example" onChange={(e) => setFilters(prevState => ({ ...prevState, [item.label.toLowerCase()]: e.target.value }))}>
                                                    <option value="">Todos</option>
                                                    {item.options.map((option, index) => {
                                                        return (
                                                            <option key={index} value={item.label === 'league' ? option.id : option}>{option.name ? option.name : option}</option>
                                                        )
                                                    })}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </div>
                <div className="col-12">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr className="table-primary w-100">
                                <th scope="col">Liga</th>
                                <th scope="col">Tipo de Linea</th>
                                <th scope="col">Polaridad</th>
                                <th scope="col">Rango</th>
                                <th scope="col">Puntos</th>
                                <th scope="col">Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataTable?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.league?.name}</td>
                                        <td>{item.lineType}</td>
                                        <td>{item.polarity}</td>
                                        <td>{item.range}</td>
                                        <td>{item.points}</td>
                                        <td>
                                            <div>
                                                <input type="text" defaultValue={item.price} onBlur={(e) => handleUpdate(item._id, e.target.value)} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CrtlDeCompra