

export const getBetType = (betLength) => {
    switch (betLength) {
        case 1: return "" 
        case 2: return "Direct";
        case 3: return "Pale";
        case 4: return "Tripleta";
        case 5: return "Cuarteta";
        case 6: return "Quinteta";
        case 7: return "Sexteta";
        case 8: return "Septeta";
        case 9: return "Octeta";
        case 10: return "Noneta";
        case 11: return "Decima primera";
        case 12: return "Decima segunda";
        case 13: return "Decima tercera";
        case 14: return "Decima cuarta";
        case 15: return "Decima quinta";
        case 16: return "Decima sexta";

        default: return "N/A";
    }
}
