import {
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import LoadingScreen from '../components/layout/LoadingScreen';
import { useLines, useLeagues } from '../utils/Hooks';
import { useEffect, useState } from 'react';
import LineHome from '../components/public/home/LineHome';
import Navbar from '../components/public/home/ui/Navbar';
import "../components/public/home/style.scss"
import PLayCalculator from '../components/public/home/PLayCalculator';
import SportRules from '../components/public/home/SportRules';
import RulesSport from '../components/public/home/ui/RulesSport';

const LinesPublic = () => {

  const { lines: games, getLines, loading, getLinesByDate } = useLines();
  const { leagues, getLeagues } = useLeagues();
  const [leagueSelected, setLeagueSelected] = useState(null);

  useEffect(() => {
    getLeagues();
  }, []);
  useEffect(() => {
    if (leagueSelected) {
      getLinesByDate(leagueSelected._id || leagues?.[0]?._id);
    } else {
      getLinesByDate(leagues?.[0]?._id);
      setLeagueSelected(leagues?.[0]);
    }
  }, [leagueSelected, leagues]);



  if (loading) {
    return <LoadingScreen />
  }

  return (
    <div className="lines-public-container">
      <Navbar leagues={leagues} />
      <Routes>
        <Route path="/lines" element={<LineHome setLeagueSelected={setLeagueSelected} leagueSelected={leagueSelected} games={games} leagues={leagues} getLines={getLines} getLeagues={getLeagues} />} />
        <Route path="/play-calculator" element={<PLayCalculator />} />
        <Route path="/rules" element={<SportRules />} />
        {/* query ?sport=baseball */}
        <Route path="/rules/:sport" element={<RulesSport />} />
        <Route path="*" element={<Navigate to="/lines" />} />
      </Routes>
    </div>
  )
}

export default LinesPublic