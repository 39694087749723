import React from 'react'

function Football() {
  return (
    <div className="rules-sport">
      <h1>Football</h1>
      <div>
        <ul>
          <li>
            All football bets will be valid when halftime has ended.
          </li>
          <li>
            All bets on plus (+) or minus (-) will be valid when all regular times have been played.
          </li>
          <li>
            Bets on more (+) or less (-) per individual team (solo) will be valid when all regular periods have been played.
          </li>
          <li>
            Bets on more (+) or less (-) on individual team (solo) are worth the extra time in the full game.
          </li>
          <li>
            Bets on more (+) or less (-) in individual team for quarters will be valid when the quarter to which you have bet has concluded.
          </li>
          <li>
            Second Half (2M) bets include points scored in overtime or overtime.
          </li>
          <li>
            Bets on the 4th. Time does not include points scored in overtime or overtime.
          </li>
          <li>
            The purchase of points will be valid when you have completed at least half time.
          </li>
          <li>
            All bets on the money line will be valid when halftime has ended.
          </li>
          <li>
            The chalk play will be valid when halftime has ended.
          </li>
        </ul>
        <div>
          <h2>
            Rules for Betting with Football Proposals
          </h2>
          <ul>
            <li>
              In propositions where there are two players, both must enter to play at some point in the game. Otherwise they are suspended.
            </li>
            <li>
              In propositions where there is only one player, he must enter to play at some point in the game. Otherwise they are suspended.
            </li>
            <li>
              If a game is suspended, all propositions for this game are suspended.
            </li>
            <li>
              Bets on the first (1st) score are valid when the team scores the first point of the game regardless of what time or period it was scored.
            </li>
            <li>
              All football bets will be valid when halftime has ended.
            </li>
            <li>
              All bets on plus (+) or minus (-) will be valid when all regular times have been played.
            </li>
            <li>
              Bets on more (+) or less (-) per individual team (solo) will be valid when all regular periods have been played.
            </li>
            <li>
              Bets on more (+) or less (-) on individual team (solo) are worth the extra time in the full game.
            </li>
            <li>
              Bets on more (+) or less (-) in individual team for quarters will be valid when the quarter to which you have bet has concluded.
            </li>
            <li>
              Second Half (2M) bets include points scored in overtime or overtime.
            </li>
            <li>
              Bets on the 4th. Time does not include points scored in overtime or overtime.
            </li>
            <li>
              The purchase of points will be valid when you have completed at least half time.
            </li>
            <li>
              All bets on the money line will be valid when halftime has ended.
            </li>
            <li>
              The chalk play will be valid when halftime has ended.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Football