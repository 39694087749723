import moment from "moment";
import { useState } from "react";

export function TableMatchUpsRed({
    gamesForUser,
}) {
    const [codeToView, setCodeToView] = useState("LG");
    return (
        <table className="table table-hover team-tables-list align-middle">
            <tbody>
                <tr>
                    <th scope="col">Hora</th>
                    <th scope="col">Team</th>
                    <th scope="col">ML</th>
                    <th scope="col">RL/T</th>
                    <th scope="col">H1 RL/T</th>
                    <th>Total</th>
                    <th>P Mas</th>
                    <th>P menos</th>
                    <th>Solo </th>
                    <th>Solo (+)</th>
                    <th>Solo (-)</th>
                </tr>
            </tbody>
            <tbody id="bodyPlayToday">
                {
                    gamesForUser?.map((game, index) => (
                        <tr key={index}>
                            <th scope="row" style={{
                                textAlign: 'center',
                            }}>
                                {moment(game.time).tz('America/New_York').format('hh:mm A')}
                            </th>
                            <th>
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center gap-2">
                                        {
                                            game.player?.image && (
                                                <img
                                                    src={game.player?.image}
                                                    alt={game.player?.name}
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        marginRight: '5px',
                                                        marginBottom: '5px'
                                                    }}

                                                />
                                            )
                                        }
                                        {game.player?.name}
                                        {' - '}
                                        {
                                            codeToView === "RD" ? game?.player?.codeRD : game?.player?.code ? game?.player?.code : game?.player?.codeRD
                                        }
                                    </div>
                                </div>
                            </th>
                            <td>
                                <span
                                    style={{
                                        color: game.playerLine?.moneyLine?.value && game.playerLine?.moneyLine?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.playerLine?.moneyLine?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.playerLine?.gavela?.value && game.playerLine?.gavela?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.playerLine?.gavela?.value || '-'}
                                    {game.playerLine?.price?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.playerLine?.h1RlGavela?.value && game.playerLine?.h1RlGavela?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.playerLine?.h1RlGavela?.value || '-'}
                                    {game.playerLine?.h1Rl?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.playerLine?.total?.value && game.playerLine?.total?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.playerLine?.total?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.playerLine?.posPlus?.value && game.playerLine?.posPlus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.playerLine?.posPlus?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.playerLine?.posMinus?.value && game.playerLine?.posMinus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.playerLine?.posMinus?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.playerLine?.negPlus?.value && game.playerLine?.negPlus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.playerLine?.single?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.playerLine?.negMinus?.value && game.playerLine?.negMinus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.playerLine?.singlePlus?.value || '-'}
                                </span>
                            </td>
                            <td>
                                <span
                                    style={{
                                        color: game.playerLine?.singleMinus?.value && game.playerLine?.singleMinus?.value < 0 ? '#a83232' : '#32a852'
                                    }}
                                >
                                    {game.playerLine?.singleMinus?.value || '-'}
                                </span>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
