import React from 'react'

const CanccelTicket = () => {
  return (
    <>
      <div className='sales-report-date'>
        <div>
          <input type="text" style={{ width: '300px', margin: '0 auto' }} placeholder='Enter the Ticket SN' />
        </div>
        <button>
          Cancel Ticket
        </button>
      </div>
    </>
  )
}

export default CanccelTicket