import { useState, useEffect } from "react";
import { redApi as api } from "../../../../../config/Axios";
import LoadingScreen from "../../../../layout/LoadingScreen";
import moment from "moment";
import { Modal } from "antd";
import Swal from "sweetalert2";

export default function PayTickets({posTerminals,consorcios,}) {

    const [data, setData] = useState([])
    const [onLoading, setOnLoading] = useState(false)
    const [terminals, setTerminals] = useState(posTerminals)
    const [consorcio, setConsorcio] = useState(consorcios)
    const [code, setCode] = useState('')
    const [selectTickets, setSelectTickets] = useState([])
    const [tickets, setTickets] = useState([])
    const [initDate, setInitDate] = useState(moment().format('YYYY-MM-DD'));

    const handleFilterFecha = (event) => {
        setInitDate(event.target.value);
    };

    const terminalId = terminals[0]?._id;

    const getData = async () => {
        try {
            setOnLoading(true)
            const res = await api.get(`ticketsWins/?initDate=${initDate}&terminal=${terminalId}&code=${code}`)
            setData(res.data.tickets)
            setOnLoading(false)
            return res
        } catch (error) {
            console.log("error: ", error)
            setOnLoading(false)
        }
    }

    useEffect(()=> {
        getData()
    },[initDate])

    let totalAmount = 0
    data.reduce((acc,ele)=>{
        return totalAmount = acc + ele.amount
    },0)

    const handleFilter = (e) => {
        setCode(e.target.value)
    }
    

    const sendCode = () => {
        getData()      
        setCode('')
    }

    const edit = (obj,index) => {
        
        if (tickets.some(ticket => ticket.ticketId === obj.ticketId)) {
            setTickets(tickets.filter(ticket => ticket.ticketId !== obj.ticketId));
        } else {
            setTickets([...tickets, obj]);
        }

        if (selectTickets.includes(index)) {
            setSelectTickets(selectTickets.filter(i => i !== index));
        } else {
            setSelectTickets([...selectTickets, index]);
        }
        
    }

    const paidTicket = async () => {
        if(tickets.length === 0) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Debes almenos seleccionar un Ticket",
                focusConfirm: true,
              });
              return
        }else {
            const res = await api.put('/tickets/:ticketId?',{
                tickets
            })
            Swal.fire({
                icon: "success",
                title: "Exito!",
                text: "Se pagaron los tickets seleccionados",
                focusConfirm: true,
              });
              getData()
              setCode('')
              setTickets([]); 
              setSelectTickets([]); 
            return res
        }
    }
    return(
        <div className="container-fluid p-0  m-0">
            <div className="row m-0 p-0">
                <div className="col-12  m-0 p-0 bg-gray-800 py-4 hide_mobile">
                    <div className="row m-0 p-0 gap-3">
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">POS</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name="pos" disabled>
                                                {
                                                    terminals && terminals?.map((termianl, index) => (
                                                        <option key={index} value={termianl?.username} >{termianl?.username}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Consorcio</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name="consorcio" disabled>
                                                {
                                                    consorcios && consorcios?.map((consorcio, index) => (
                                                        <option key={index} value={consorcio?.username} >{consorcio?.username}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-start align-items-center">
                                            {/* <input type="checkbox" onChange={handleCheck} />
                                            <label className="m-0 p-0">Rango</label> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Refrezcar" name="refresh" className="btn btn-primary"
                                                style={{
                                                    border: 'none',
                                                }}
                                                // onClick={handleFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className="m-0 p-0 text-start text-nowrap">Fecha Inicial</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            {/* <input type="date" className="form-control" name="initDate" value={filter.initDate} onChange={handleFilter} /> */}
                                            <input type="date" className="form-control" name="initDate" value={initDate} onChange={handleFilterFecha} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">

                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Imprimir" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                       
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            {/* <label className={`m-0 p-0 text-start text-nowrap ${!showcheck ? 'invisible' : ''}`}>Fecha final</label> */}
                                            <label className='m-0 p-0 text-start text-nowrap'>Fecha final</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            {/* <input type="date" className={`form-control ${!showcheck ? 'invisible' : ''}`} name="endDate" onChange={handleFilter} /> */}
                                            {/* <input type="date" className='form-control ' name="endDate" onChange={handleFilter} /> */}
                                            <input type="date" className='form-control ' name="endDate"  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            {/* <label className={`m-0 p-0 text-start text-nowrap ${!showcheck ? 'invisible' : ''}`}>#Ticket</label> */}
                                            <label className='m-0 p-0 text-start text-nowrap'>#Ticket</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            {/* <input type="date" className={`form-control ${!showcheck ? 'invisible' : ''}`} name="ticket"  /> */}
                                            <input type="text" className='form-control' name="ticket" value={code}  onChange={handleFilter}/>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>

                        <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            {/* <input type="date" className={`form-control ${!showcheck ? 'invisible' : ''}`} name="ticket"  /> */}
                                            <input type="button" value="Buscar" name="Buscar" className="btn btn-primary"
                                                style={{
                                                    border: 'none',
                                                }}
                                                onClick={sendCode}
                                            />
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                        <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            {/* <input type="date" className={`form-control ${!showcheck ? 'invisible' : ''}`} name="ticket"  /> */}
                                            <input type="button" value="Pagar Ticket" name="Paid" className="btn btn-primary"
                                                style={{
                                                    border: 'none',
                                                }}
                                                onClick={paidTicket}
                                            />
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 py-4" id="customers">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th className="text-center" scope="col"># de Ticket</th>
                                <th className="text-center" scope="col">Fecha/Hora</th>
                                <th className="text-center" scope="col">Tipo de Ticket</th>
                                <th className="text-center" scope="col">A pagar</th>
                                <th className="text-center" scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                onLoading ? (
                                    <tr>
                                        <td colSpan="5" className="text-center">Cargando...</td>
                                    </tr>
                                ) : <>
                                    {
                                        data.length === 0 && (
                                            <tr>
                                                <td colSpan="5" className="text-center">No hay datos</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        data.length === 'No tickets found' && (
                                            <tr>
                                                <td colSpan="5" className="text-center">No hay datos</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        data?.map((terminal, index) => (
                                            <tr key={index} style={{backgroundColor: selectTickets.includes(index) ? '#CC2F48' : ''}} onClick={()=>edit(terminal,index)}>
                                                <td className="text-center">{terminal.ticketId}</td>
                                                <td className="text-center">{moment(terminal.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                                                <td className="text-center">{terminal.ticketType}</td>
                                                <td className="text-center">{terminal.amount}</td>
                                                <td className="text-center" style={{backgroundColor:'green', color:'white'}}>{terminal.ticketState}</td>
                                            </tr>
                                        ))
                                    }
                                </>

                            }

                        </tbody>
                        <tfoot>
                            {
                                data?.length > 0 ? (
                                    <tr>

                                        <td colSpan="2" className="text-center">Total de tickets: {data.length}</td>
                                        <td colSpan="3" className="text-center">Total a pagar: {totalAmount}</td>
                                        
                                    </tr>

                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">No hay datos</td>
                                    </tr>

                                )
                            }
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}