import React from 'react'

const CopyTicket = () => {
  return (
    <>
      <div className='sales-report-date'>
        <div>
          <input type="text" placeholder='Ticket SN' style={{ width: '300px', margin: '0 auto' }} />
        </div>
        <button>
          <i className="far fa-copy" style={{ marginRight: '5px' }}></i>
          copy ticket
        </button>
      </div>
    </>
  )
}

export default CopyTicket