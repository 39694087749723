import React, { useContext, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { HyRContext } from "../../../HyLUtils/context/HyLContext";
import { AuthContext } from "../../../auth";
import { useHyL } from "../../../HyLUtils/hooks/useHyL";

export default function LeftContainer() {
  const { logout, userLevel } = useContext(AuthContext);
  const navigate = useNavigate();
  const onLogout = () => {
    logout();
    navigate("/", { replace: true });
  };

  const { methods, states, setStates } = useHyL();
  const { setLeague, setLeagueSelected } = setStates;
  const { onLoading, leaguesWithGames, } = useContext(HyRContext);
  const liga = useLocation();

  // useEffect(() => {
  //   if (leaguesWithGames.length > 0) {
  //     const ligaName = liga.pathname.split("/").at(-1);
  //     console.log({ leaguesWithGames, ligaName })
  //     leaguesWithGames.forEach((el, index) => {
  //       if (ligaName !== el.name) {
  //         console.log("no es igual", index)
  //         if (index === leaguesWithGames.length - 1)
  //           navigate(`/admin/horarioslineas/${leaguesWithGames[0].name}`, { replace: true });
  //       }
  //     })
  //   }
  // }, [leaguesWithGames])

  return (
    <div className="left-container-l">
      <div className="backtoadmin">
        <Link to="/admin/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-arrow-back-up"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#dc3545"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1" />
          </svg>
        </Link>
      </div>
      <div className="logos" id="logosContainer" style={{
        minWidth: "40px",
      }}>
        {onLoading
          ? (
            <div className="spinner-border text-danger" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )
          : (
            <>
              {leaguesWithGames?.map((league) => {
                league.name = league.name.replaceAll(' ', '%20')
                return (
                  <div className="logo " key={league._id}>
                    <NavLink
                      onClick={() => {
                        setLeague(league);
                        setLeagueSelected(league);
                      }}
                      className="exit-button-hyl"
                      to={`/admin/horarioslineas/${league.name
                        .replaceAll(" ", "%")
                        .replaceAll("/", "_")}?table=lineas`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <img
                        src={
                          league.image
                            ? league.image
                            : `/logos/${league.name
                              .replaceAll(" ", "_")
                              .replaceAll("/", "_")}.png`
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = "/logos/logodefault.png";
                        }}
                        alt={league.name}
                        className="active"
                      />
                    </NavLink>
                  </div>
                );
              })}
            </>
          )
        }
        {
          userLevel === 10
          && (
            <div className="logo">
              <button
                className="exit-button-hyl"
                onClick={onLogout}
              >
                <i className="fas fa-power-off" ></i>
              </button>
            </div>
          )
        }

      </div>
    </div>
  );
}
