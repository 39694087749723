


export const replaceSpaces = (value) => {
    return value.replaceAll('%20', ' ');
}

export const replaceSpacesAndApostrophe = (value) => {
    return value.replaceAll('%20', ' ').replaceAll("\’", "%E2%80%99");
}

