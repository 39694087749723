import styled from 'styled-components';
import { useTickets } from '../../../../hooks/clientHooks/useTickets';
import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { useGames } from '../../../../hooks/clientHooks/useGames';
import { useLeagues } from '../../../../utils/Hooks';
import { Printer } from '../../../printer/Print';

const Leftcontainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  min-width: 350px;
  max-width: 400px;
  `;

const CardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #00000010;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 5px 0 20px #00000008;`;


const InputCtrl = styled.input`
  width: 100%;
  padding: 5px;
  padding-left: 10%;
  border: 1px solid #ddd;
  border-radius: 5px;
  `;

const SelectTsr = styled.select`
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #F1F0F4;
  `;

const Label = styled.label`
  `;

const Column = styled.div`
  width: fit-content;
  height: fit-content;
`;

const Row = styled.div`
  display: flex;
  height: fit-content;
  `;

const Button = styled.button`
  width: 80px;
  height: fit-content;
  border: 2px dashed #0158AD;
  padding: 5px;
  background-color:  ${props => props.$bgcolor || "transparent"};
  color: #0158AD;
  font-weight: 500;
  `;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead th {
    color: #878D8F;
    font-weight: 500;
    text-align: center;
  }

  tbody tr {
    text-align: center;

    &:nth-child(even) {
      background-color: #F1F0F4;
    }
  }
`;

const AmountsContainer = styled.div`
  width: 100%;
  border: 1px solid green;
  border-radius: 10px;
  padding: 2px 5px;

  table {
    width: 100%;
    border-collapse: collapse;

    thead th {
      color: #333;
      font-weight: 500;
      text-align: center;
    }

    tbody tr {
      text-align: center;

      input {
        width: 100%;
        padding: 5px;
        border: 1px solid #ddd;
        border-radius: 5px;
      }
    }
  }
`;

const F83left = () => {
  const { ticket, betToAdd, handleChangeBetToAdd, handleAddBet, handleChangeTicket, handleRemoveBet, leagueSelected, handlePostTicket, setPrintTicket, printTicket } = useTickets();
  // const { leagues, loadingLeagues, getLeagues, leagueSelected, handleLeagueSelected } = useLeagues();
  const teamCodeInput = useRef(null);
  const playTypeInput = useRef(null);
  const amountInput = useRef(null);
  const amountIfInput = useRef(null);
  const amountIfInput2 = useRef(null);

  const handleRemoveBetClick = (index) => {
    Swal.fire({
      title: '¿Desea eliminar la apuesta?',
      text: "No podrá deshacer esta acción",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0158AD',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveBet(index);
        Swal.fire(
          'Eliminado!',
          'La apuesta ha sido eliminada',
          'success'
        )
      }
    })
  };
  const [isAmountClicked, setIsAmountClicked] = useState(false);

  function getToWin(bets, amount) {
    let result = 0;
    for (let i = 0; i < bets.length; i++) {

      if (bets[i].price.toString().includes('+') && bets[i].price.toString().includes('-')) {
      }
      const multiplier = bets[i].price >= 0
        ? bets[i].price / 100
        : 1 / ((bets[i].price * -1) / 100);
      result = Math.round((result + amount) * multiplier + result);
    }
    result = Math.round(result);
    return result
  }

  const handleClickAmount = () => {
    if (ticket.bets.length === 0) {
      Swal.fire({
        title: 'No hay apuestas',
        text: "Debe agregar al menos una apuesta",
        icon: 'warning',
        confirmButtonColor: '#0158AD',
      })
      return
    }
    setIsAmountClicked(true);
    amountInput.current.disabled = true;

    let ifWinInput = amountIfInput.current;
    // if (parseInt(ifWinInput.value) > 0) {
    //     let ifWinContainer = document.querySelector('#ifWinContainer');
    //     console.log({ ifWinContainer });
    //     if (ifWinContainer.children.length === 1) {
    //         let newInput = document.createElement('input');
    //         newInput.type = 'number';
    //         newInput.className = 'form-control col';
    //         newInput.name = 'IfWinQuantity';
    //         newInput.id = 'IfWinQuantity';
    //         ifWinContainer.appendChild(newInput);
    //         newInput.focus();
    //         setIfWinQuantity(newInput);
    //         return;
    //     } else {
    //         document.querySelector('#IfWinQuantity').focus();
    //         return;
    //     }
    // }
    let quantityInput = amountInput.current;
    let ammountBet = ticket.amount == 0 ? quantityInput.value : ticket.amount;
    if (parseInt(ammountBet) > 0) {
      let betsTowin = ticket.bets.map((bet) => {
        let price = bet.price.toString().slice(-4);
        return {
          price: price
        }
      })

      // ifWinInput.disabled = true;
      // quantityInput.disabled = true;

      let totalToWin = getToWin(betsTowin, parseInt(ammountBet));
      let winnerAmount = document.querySelector('#winneramount');
      let collectAmount = document.querySelector('#collectamount');
      winnerAmount.textContent = totalToWin;
      collectAmount.textContent = Number(totalToWin) + Number(ammountBet);
      handleChangeTicket("totalToWin", totalToWin);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', async (e) => {
      if (e.key === "Enter" && isAmountClicked) {
        await handlePostTicket();
        amountInput.current.disabled = false;
        amountInput.current.focus();
        amountInput.current.value = '';
        amountIfInput.current.value = '';
        setIsAmountClicked(false);
        let winnerAmount = document.querySelector('#winneramount');
        let collectAmount = document.querySelector('#collectamount');
        winnerAmount.textContent = 0.00;
        collectAmount.textContent = 0.00;
        let refreshreportstickets = document.querySelector('#refreshreportstickets');
        if (refreshreportstickets) {
          refreshreportstickets.click();
        }
        return
      }
    });
  }, [isAmountClicked]);
  const [IfWinQuantityShow, setIfWinQuantityShow] = useState(false);
  useEffect(() => {
    if (IfWinQuantityShow && amountIfInput2.current) {
      amountIfInput2.current.focus();
    }
  }, [IfWinQuantityShow, amountIfInput2]);
  return (
    <Leftcontainer>
      {
        printTicket.print ? (
          <Printer
            ticketId={printTicket.ticketId}
            printTicket={printTicket}
            setPrintTicket={setPrintTicket}
          />
        ) :
          null
      }
      <CardContainer>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <Row style={{ gap: 5 }}>
            <Column style={{ width: '100%' }}>
              <Label style={{ fontWeight: 'bold' }}>
                Codigo
              </Label>
              <InputCtrl type="text" placeholder="- - - -" id="teamCodeInput" value={betToAdd.teamCode} onChange={(e) => {
                handleChangeBetToAdd("teamCode", e.target.value)
                if (e.target.value.length === 4) return playTypeInput.current.focus();
              }}
                onKeyDown={(e) => e.key === "Enter" && playTypeInput.current.focus()}
                ref={teamCodeInput}
                onFocus={(e) => e.target.select()}
              />
            </Column>
            <Column style={{ width: '100%' }}>
              <Label style={{ fontWeight: 'bold' }}>
                Tipo
              </Label>
              <InputCtrl type="text" placeholder="- - - -" id="playTypeInput" value={betToAdd.playType} onChange={(e) => handleChangeBetToAdd("playType", e.target.value.toUpperCase())}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddBet(leagueSelected);
                    teamCodeInput.current.focus();
                    handleChangeBetToAdd("playType", "");
                  }
                }}
                ref={playTypeInput}
                onFocus={(e) => e.target.select()}
                style={{ textTransform: 'uppercase' }}
              />
            </Column>
          </Row>
          <Row style={{ borderBottom: '1px solid #ddd', paddingBottom: '15px' }}>
            <Button $bgcolor="#0158AD30" style={{
              borderTopLeftRadius: '5px',
              borderBottomLeftRadius: '5px',
              borderRight: 'none'
            }}>
              Normal
            </Button>
            <Button style={{ borderRight: 'none' }}>
              Teaser
            </Button>
            <Button style={{
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
            }}>
              RR
            </Button>
          </Row>
        </div>
        <div style={{ flex: 1, overflowY: 'auto' }}>
          <Table>
            <thead>
              <tr>
                <th>
                  Codigo
                </th>
                <th>
                  Equipo
                </th>
                <th>
                  Apuesta
                </th>
                <th>
                  Sp.
                </th>
                <th>
                  Precio
                </th>
              </tr>
            </thead>
            <tbody>
              {
                ticket.bets.length > 0 ? ticket.bets.map((bet, index) => (
                  <tr key={index} style={{ cursor: 'pointer' }} onClick={() => handleRemoveBetClick(index)}>
                    <td>
                      {bet.teamCode}
                    </td>
                    <td>
                      {bet.teamName}
                    </td>
                    <td>
                      {bet.playType}
                    </td>
                    <td>
                      {bet?.spread}
                    </td>
                    <td>
                      {bet.price}
                    </td>
                  </tr>
                ))
                  : <tr>
                    <td colSpan="5" style={{ textAlign: 'center' }}>
                      No hay apuestas
                    </td>
                  </tr>
              }
            </tbody>
          </Table>
        </div>
      </CardContainer>
      <AmountsContainer>
        <table>
          <thead>
            <tr>
              <th>
                Totales
              </th>
              <th>
                $Cantidad
              </th>
              <th>
                $Ganador
              </th>
              <th>
                $Pagar
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Bet-0:
              </td>
              <td>
                <div>
                  <input type="text" defaultValue="0.00" ref={amountInput}
                    onChange={(e) => handleChangeTicket("amount", Number(e.target.value))}
                    onKeyDown={(e) => e.key === "Enter" && handleClickAmount()}
                    onFocus={(e) => e.target.select()}
                  />
                </div>
              </td>
              <td style={{ color: "green", fontWeight: "600" }} id='winneramount'>
                0.00
              </td>
              <td id='collectamount'>
                0.00
              </td>
            </tr>
            <tr>
              <td>
                IF -0:
              </td>
              <td>
                <div className='d-flex gap-2'>
                  <input type="number" defaultValue="" ref={amountIfInput}
                    onChange={(e) => handleChangeTicket("amountIf", Number(e.target.value))}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && Number(e.target.value) > 0) {
                        setIfWinQuantityShow(true);
                      }
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                  {
                    IfWinQuantityShow &&
                    <input type="number" defaultValue="" ref={amountIfInput2}
                      onChange={(e) => { }}
                      onKeyDown={(e) => { }}
                      onFocus={(e) => e.target.select()}
                    />
                  }
                </div>
              </td>
              <td style={{ color: "green", fontWeight: "600" }}>
                0.00
              </td>
              <td>
                0.00
              </td>
            </tr>
          </tbody>
        </table>
      </AmountsContainer>
    </Leftcontainer>
  )
}

export default F83left