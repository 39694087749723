import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { Printer } from './Print';

export const PrintWhatsapp = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const ticketId = searchParams.get("ticketId");

  if (!userId || !ticketId) return <div id="invalid-params">Invalid params</div>
  const [printTicket, setPrintTicket] = useState({ticketId, print: false})

  return (
    <>
      <Printer 
        ticketId={printTicket.ticketId} 
        printTicket={printTicket} 
        setPrintTicket={setPrintTicket} 
        userId={ userId } 
        sendWs 
      />
    </>
  )
}
