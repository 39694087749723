import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import search from '../../../../assets/logos/search1.svg'
import { useState } from 'react';

const SearchTextField = ({setSearch}) => {
    const [isInputEmpty, setIsInputEmpty] = useState(true);

      const handleInputChange = (event) => {
      setIsInputEmpty(event.target.value === '');
     };
  return (
    // <TextField

    //   style={{backgroundColor:'blue'}}
      
    //   InputProps={{
    //     style: {
    //       border: 'none', // Establecer borde a ninguno
    //       backgroundColor:'red',
    //       alignContent:'center'
          
    //     },
    //     startAdornment: (
    //       <InputAdornment position="start"
    //       style={{
    //         border: 'none', // Establecer borde a ninguno para el InputAdornment
    //         alignItems: 'center', // Alinear el contenido al centro
    //         backgroundColor:'yellow',
    //         justifyContent:'center'
    //       }}
    //       >
      
    //         <img
    //           src={search}
    //           alt="Search"
    //           style={{
    //             width: '24px',
    //             height: '24px',
    //             color: '#7E7E7E',
    //             display: isInputEmpty ? 'block' : 'none',
    //           }}
    //         />
    //         <p
    //           style={{
    //             color: '#B5B7C0',
    //             fontFamily: 'Poppins',
    //             fontSize: '14px',
    //             fontStyle: 'normal',
    //             fontWeight: '400',
    //             lineHeight: 'normal',
    //             letterSpacing: '-0.14px',
    //             display: isInputEmpty ? 'block' : 'none',
    //           }}
    //         >
    //           Search
    //         </p>
            
    //       </InputAdornment>
    //     ),
    //   }}
    //   onChange={handleInputChange}
    // />

    <TextField
    type="input"
    id="outlined-basic"
    label="Search"
    onChange={(e) => setSearch(e.target.value)}
    style={{ margin: '10px' , float: 'right', width:'200px', border:'none' }}
    InputProps={{
        startAdornment: (
            <InputAdornment position="start">
                <img src={search} alt="Search Icon" style={{ height: '20px', width: '20px' }} />
            </InputAdornment>
        ),
    }}
/>
  );
};

export default SearchTextField;
