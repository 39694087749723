
import {
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import ScrollToTop from '../components/scrollToTop';

// Login Page
import LoginPage from '../auth/pages/LoginPage';

import ClientRoutes from './ClientRoutes';
import AdminRoutes from './AdminRoutes';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { useContext } from 'react';
import { AuthContext } from '../auth/context/AuthContext';
import Score from '../components/client/pages/Score';
import { PublicLinesRoutes } from './PublicLinesRoutes';
import LinesPublic from './LinesPublic';
import { PrintWhatsapp } from '../components/printer/PrintWhatsapp';

export default function AppRouter() {

  const { userLevel } = useContext(AuthContext);

  return (
    <>
      <ScrollToTop />
      <Routes>

        <Route path='/testt' element={<Score />} />
        {/* <Route path='/menulogin' element={<MenuLogin />} /> */}
        <Route path='/login' element={
          <PublicRoute userLevel={userLevel}>
            <LoginPage />
          </PublicRoute>
        }
        />

        <Route path='/' element={
          <PublicRoute userLevel={userLevel}>
            <LoginPage />
          </PublicRoute>
        }
        />
        <Route path='/admin/*' element={
          <PrivateRoute>
            <AdminRoutes />
          </PrivateRoute>}
        />
        <Route path='/client/*' element={
          <PrivateRoute>
            <ClientRoutes userLevel={userLevel} />
          </PrivateRoute>}
        />

        {/* <Route exact path='/404' component={ NotFoundClient } />
          <Navigate to="/404" /> */}

        <Route path='/*' element={<Navigate to="/" />} />
        <Route path='/view/*' element={<PublicLinesRoutes />} />

        <Route path='/home/*' element={<LinesPublic />} />

        <Route path='/ticketview' element={<PrintWhatsapp />} />
      </Routes>
    </>
  )
}
