import { useContext } from "react"
import { Navigate } from "react-router-dom"
import { AuthContext } from "../auth/context/AuthContext"


export const PublicRoute = ({ children, userLevel }) => {
  const { logged } = useContext(AuthContext);

  if (logged) {
    let newPath = '/';
    if (userLevel === 1 || userLevel === 2 || userLevel === 10 || userLevel === 5) {
      newPath = '/admin';
    } else {
      newPath = '/client/';
    }
    // console.log({ newPath });
    return <Navigate to={newPath} />
  }
  return children
}