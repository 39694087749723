const playTypes = [
    {
        type: 'R', //== RunLine: Al equipo que gane por diferencia de puntos
        disabled: ['M', 'S+', 'S-', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T', 'MH'],
    },
    {
        type: 'M', //== MoneyLine: Al equipo que gane
        disabled: ['R', 'S+', 'S-', 'M', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T', 'MH'],
    },
    {
        type: '+', //== P+: Si el puntaje de ambos equipos es más que el spread
        disabled: ['S+', 'S-', '+', '-'],
    },
    {
        type: '-', //== P-: Si el puntaje de ambos equipos es menos que el spread
        disabled: ['S+', 'S-', '-', '+'],
    },
    {
        type: 'S+', //== Solo+: Si el puntaje del equipo es mas que el spread
        disabled: ['M', 'R', '+', '-', 'S+'],
    },
    {
        type: 'S-', //== Solo-: Si el puntaje del equipo es menos que el spread
        disabled: ['M', 'R', '+', '-', 'S-'],
    },
    {
        type: 'MH', //== MoneyLineHalf: Al equipo lleve la delantera en el half
        disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T', 'S+', 'S-', 'MH'],
    },
    {
        type: 'H', //== RunLine Half
        disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
    },
    {
        type: 'Y', //== Si anotan en el primer inning
        disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
    },
    {
        type: 'N', //== Si no anota en el primer inning
        disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
    },
    {
        type: 'AR', //== Alternative RunLine
        disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
    },
    {
        type: 'P', //== Super RunLine
        disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
    },
    {
        type: 'F', // El equipo que anote primero
        disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
    },
    {
        type: 'K+', // Al equipo que haga mas ponches
        disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
    },
    {
        type: 'K-', // Al equipo que haga menos ponches
        disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
    },
    {
        type: 'T', //== Tercio: Al equipo que lleve la delantera en el primer tercio
        disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
    },
    {
        type: 'E', //== Draw: Si los equipos empatan
        disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T', 'MH'],
    },
    {
        type: 'Q1', //== Q1: Al equipo que lleve la delantera en el primer cuarto
        disabled: [],
    },
    {
        type: 'Q2',
        disabled: [],
    },
    {
        type: 'Q3',
        disabled: [],
    },
    {
        type: 'Q4',
        disabled: [],
    },
    {
        type: 'Q1+',
        disabled: [],
    },
    {
        type: 'Q2+',
        disabled: [],
    },
    {
        type: 'Q3+',
        disabled: [],
    },
    {
        type: 'Q4+',
        disabled: [],
    },
    {
        type: 'Q1-',
        disabled: [],
    },
    {
        type: 'Q2-',
        disabled: [],
    },
    {
        type: 'Q3-',
        disabled: [],
    },
    {
        type: 'Q4-',
        disabled: [],
    },
    {
        type: 'QS1+',
        disabled: [],
    },
    {
        type: 'QS2+',
        disabled: [],
    },
    {
        type: 'QS3+',
        disabled: [],
    },
    {
        type: 'QS4+',
        disabled: [],
    },
    {
        type: 'QS1-',
        disabled: [],
    },
    {
        type: 'QS2-',
        disabled: [],
    },
    {
        type: 'QS3-',
        disabled: [],
    },
    {
        type: 'QS4-',
        disabled: [],
    },
    {
        type: 'T1',
        disabled: [],
    },
    {
        type: 'T2',
        disabled: [],
    },
    {
        type: 'T3',
        disabled: [],
    },
    {
        type: 'T1+',
        disabled: [],
    },
    {
        type: 'T2+',
        disabled: [],
    },
    {
        type: 'T3+',
        disabled: [],
    },
    {
        type: 'T1-',
        disabled: [],
    },
    {
        type: 'T2-',
        disabled: [],
    },
    {
        type: 'T3-',
        disabled: [],
    },
    {
        type: 'MT1',
        disabled: [],
    },
    {
        type: 'MT2',
        disabled: [],
    },
    {
        type: 'MT3',
        disabled: [],
    },
    {
        type: 'J2',
        disabled: [],
    },
    {
        type: 'J3',
        disabled: [],
    },
    {
        type: 'J4',
        disabled: [],
    },
    {
        type: 'J5',
        disabled: [],
    },
]

// const restrictions = [
//     {
//         _id: "6471192faaef292e4591fd81",
//         NDI: 29,
//         user: {
//             _id: "6466d347cdc3de9df6b97810",
//             username: "new york",
//             userLevel: 2
//         },
//         type: "PC",
//         league: {
//             _id: "6447e2b929d06c3563c0f6c2",
//             name: "NBA"
//         },
//         ticketType: [],
//         lineType: [],
//         period: [],
//         playType1: [
//             "EVEN",
//             "EVEN H1"
//         ],
//         playType2: [
//             "Solo +H",
//             "Solo -H"
//         ],
//         createdAt: "2023-05-26T20:40:15.998Z",
//         updatedAt: "2023-06-12T13:11:07.409Z"
//     }
// ]

function ReadPlayType(playType) {
    const dictionary = {
        M: 'EVEN',
        MH: 'EVEN H1',
        MT1: 'EVEN P1',
        MT2: 'EVEN P2',
        MT3: 'EVEN P3',
        R: 'R/L',
        H: 'R/L H1',
        Q1: 'R/L Q1',
        Q2: 'R/L Q2',
        Q3: 'R/L Q3',
        Q4: 'R/L Q4',
        T1: 'R/L P1',
        T2: 'R/L P2',
        T3: 'R/L P3',
        HR2: 'R/L H2',
        S: "Solo",
        "S+": "Solo +",
        '+': 'A Mas',
        'H+': 'A Mas H',
        'Q1+': 'A Mas Q1',
        'Q2+': 'A Mas Q2',
        'Q3+': 'A Mas Q3',
        'Q4+': 'A Mas Q4',
        'T1+': 'A Mas P1',
        'T2+': 'A Mas P2',
        'T3+': 'A Mas P3',
        'H2+': 'A Mas H2',
        '-': 'A Menos',
        'H-': 'A Menos H',
        'Q1-': 'A Menos Q1',
        'Q2-': 'A Menos Q2',
        'Q3-': 'A Menos Q3',
        'Q4-': 'A Menos Q4',
        'T1-': 'A Menos P1',
        'T2-': 'A Menos P2',
        'T3-': 'A Menos P3',
        'H2-': 'A Menos H2',
        'S+': 'Solo +',
        'H1S+': 'Solo +H',
        'Q1S+': 'Solo +Q1',
        'Q2S+': 'Solo +Q2',
        'Q3S+': 'Solo +Q3',
        'Q4S+': 'Solo +Q4',
        'H2S+': 'Solo +H2',
        'S-': 'Solo -',
        'H1S-': 'Solo -H',
        'Q1S-': 'Solo -Q1',
        'Q2S-': 'Solo -Q2',
        'Q3S-': 'Solo -Q3',
        'Q4S-': 'Solo -Q4',
        'H2S-': 'Solo -H2',
        P: 'SR/L',
        Y: 'Si',
        N: 'No',
        'K+': 'K+',
        'K-': 'K-',
        F: '1era',
        C: 'Compra',
        V: 'Venta',
        'C+': 'C A Mas',
        'C-': 'C A Menos',
        J1: 'J1',
        J2: 'J2',
        J3: 'J3',
        J4: 'J4',
        J5: 'J5',
        SE: 'Serie',
        AR: 'AR/L',
        T: 'Tercio',
        'T+': 'Tercio Mas',
        'T-': 'Tercio Menos',
        'V+': 'V A Mas',
        'V-': 'V A Menos',
        E: 'Empate',
    }
    return dictionary[playType]
}
const validateBets = (
    bets = [],
    restrictions = [],
    betToAdd = {
        TeamCode: "",
        PlayType: "",
    },
    game = {},
    isMatchup) => {
    let valid = true;
    let message = '';
    let localTeam = isMatchup ? game.player.codeRD : game.localTeam.codeRD == 0 ? game.localTeam.code : game.localTeam.codeRD;
    let visitingTeam = isMatchup ? null : game.visitingTeam.codeRD == 0 ? game.visitingTeam.code : game.visitingTeam.codeRD;
    let betType = betToAdd.PlayType;
    let betTeam = Number(betToAdd.TeamCode);
    let playType = playTypes.find(playType => playType.type === betType);

    if (isMatchup) {
        if (localTeam == betTeam) {
            bets.forEach(bet => {
                if (bet.teamCode == visitingTeam) {
                    if (playType.disabled.includes(bet.playType)) {
                        valid = false;
                        message = `No se puede jugar ${betType} al equipo local porque ya se jugó ${bet.playType} al equipo visitante`;
                    }
                } else if (bet.teamCode == localTeam) {
                    if (playType.disabled.includes(bet.playType)) {
                        valid = false;
                        message = `No se puede jugar ${betType} al equipo local porque ya se jugó ${bet.playType} al equipo local`;
                    }
                }
            });
        }
        return { valid, message };

    }
    ///Combination Restrictions
    if (bets.length > 0) {
        const typeBetAdd = ReadPlayType(betToAdd.PlayType)
        restrictions.map(rectriction => {
            if (rectriction.league.name === game.league.name) {
                const resctriction1 = rectriction.playType1.includes(typeBetAdd)
                const resctriction2 = rectriction.playType2.includes(typeBetAdd)
                if (resctriction1 || resctriction2) {
                    bets.map(bet => {
                        if (game._id == bet.game) {
                            let typePlayBet = ReadPlayType(bet.playType)
                            const SearchPlayType = resctriction1 ? rectriction.playType2 : rectriction.playType1
                            if (SearchPlayType.includes(typePlayBet)) {
                                valid = false
                                message = `El tipo de jugada ${betToAdd.PlayType} no puede combinarse con el tipo de jugada ${bet.playType}`;
                            }
                        }
                    })
                }
            }
        })
    }

    if (localTeam == betTeam) {
        bets.forEach(bet => {
            if (bet.teamCode == visitingTeam) {
                if (betType == bet.playType) {
                    valid = false;
                    message = `No se puede jugar ${betType} al equipo local porque ya se jugó ${bet.playType} al equipo visitante`;
                }
            } else if (bet.teamCode == localTeam) {
                if (betType == bet.playType) {
                    valid = false;
                    message = `No puedes repetir jugadas al mismo equipo`;
                }
            } if ((betType == '-' && bet.playType == '+') || (betType == '+' && bet.playType == '-')) {
                valid = false;
                message = `No puedes jugar + y - en el mismo juego`;
            }
        });
    } else if (visitingTeam == betTeam) {
        bets.forEach(bet => {
            if (bet.teamCode == localTeam) {
                if (betType == bet.playType) {
                    valid = false;
                    message = `No se puede jugar ${betType} al equipo visitante porque ya se jugó ${bet.playType} al equipo local`;
                }
            } else if (bet.teamCode == visitingTeam) {
                if (betType == bet.playType) {
                    valid = false;
                    message = `No puedes repetir jugadas al mismo equipo`;
                }
            } if ((betType == '-' && bet.playType == '+') || (betType == '+' && bet.playType == '-')) {
                valid = false;
                message = `No puedes jugar + y - en el mismo juego`;
            }
        });
    }
    return { valid, message };
}
export default validateBets;

