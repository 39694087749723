import Swal from "sweetalert2";

export const Swals = {
    success(title = 'Completado', message = '', type) {
        return Swal.fire({
            icon: 'success',
            title,
            text: message,
            confirmButtonColor: '#CC2F48',
        })
    },
    error(title = 'Error', message = '', type) {
        return Swal.fire({
            icon: 'error',
            title,
            text: message,
            confirmButtonColor: '#CC2F48',
        })
    },
}
