import React from 'react'

const Pendingwithdrawals = () => {
  return (
    <>
      <div className='sales-report-tables'>
        <div>
          <table>
            <thead>
              <tr>
                <th colSpan={5}>
                  pending  withdrawals
                </th>
              </tr>
              <tr>
                <th>
                  date
                </th>
                <th>
                  method
                </th>
                <th>
                  dep. id
                </th>
                <th>
                  amount
                </th>
                <th>
                  actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={5}>
                  No pending withdrawals
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Pendingwithdrawals