import React from 'react'
import { Container } from 'react-bootstrap'

export default function CreateTerminal() {
  return (
    <div className="modal fade" id="createUserModal" tabIndex="-1" aria-labelledby="createUserModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="CreateUserModalLabel">Crear Usuario</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Container fluid>
                <form>
                  <div className="row mb-2">

                    <div className="col-12 mb-2 col-md-6">
                      <label htmlFor="username">Usuario:</label>
                      <input type="text" id="username"/>
                    </div>

                    <div className="col-12 mb-2 col-md-6">
                      <label htmlFor="userEmail">Email:</label>
                      <input type="email" id="userEmail"/>
                    </div>

                    <div className="col-12 mb-2 col-md-6">
                      <label htmlFor="pass">Contraseña:</label>
                      <input type="password" id="pass"/>
                    </div>

                    <div className="col-12 mb-2 col-md-6">
                      <label htmlFor="theme">Theme:</label>
                      <input type="number" min="1" max="2" id="theme"/>
                    </div>
                  </div>
                </form>

              </Container>
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <button type="button" className="btn btn-ok mb-2" data-bs-dismiss="modal">Crear</button>
              <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
  )
}
