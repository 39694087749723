import React from 'react'

const WebTickets_t4_mobile = ({ setMenuActive, menuActive }) => {
  return (
    <div className='sub_menu-container'>
      <button onClick={() => setMenuActive('')}>
        <i className="fas fa-arrow-left"></i>
      </button>
      <div className='container_sub_menu'>
        <div className='top_container'>
          <div>
            <div className='_3_inputs p-0'>
              <div className='date_container'>
                <label htmlFor="date">Date</label>
                <input type="date" name="" id="" defaultValue={new Date().toISOString().slice(0, 10)} />
              </div>
              <div className='date_container'>
                <label htmlFor="date">Customer:</label>
                <select name="" id="">
                  <option value="">All</option>
                </select>
              </div>
              <div className='date_container'>
                <label htmlFor="date">Type:</label>
                <select name="" id="">
                  <option value="">All</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="pending">Pending</option>
                  <option value="winner">Winner</option>
                  <option value="loser">Loser</option>
                </select>
              </div>
            </div>
            <button type="button">
              search
            </button>
          </div>
        </div>
        <div className='bottom_container'>
          <div className='tables_container'>
            <table>
              <thead>
                <tr>
                  <th>TICKETS DE DEPORTE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div role='alert' className='alert alert-danger' style={{ textAlign: 'center' }}>
                      No tickets where found
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th>TICKETS DE LOTERIA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div role='alert' className='alert alert-danger' style={{ textAlign: 'center' }}>
                      No tickets where found
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebTickets_t4_mobile