import { useEffect, useReducer, useState } from "react";
import { AuthContext } from "./AuthContext";
import { authReducer } from "./authReducer";
import Cookies from "universal-cookie";
import { redApi } from "../../config/Axios";
import { types } from "../types/types";
import { socket } from "../../socket";
import Swal from "sweetalert2";

const cookie = new Cookies();

const AUTH_INITIAL_STATE = {
  logged: false,
  token: undefined,
  userLevel: 0,
  userTheme: undefined,
  userId: undefined,
  userName: undefined,
  dailyMessage: undefined,
  messageIsRead: false,
  TMobile: false,
  userData: {},
  privileges: {},
};

export const AuthProvider = ({ children }) => {
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [authState, dispatch] = useReducer(authReducer, AUTH_INITIAL_STATE);

  const checkToken = async () => {
    try {
      // let token = undefined;
      // token = cookie.get("jwt");
      // if (!token) {
      //   return setLoadingProfile(false);
      // }
      const { data } = await redApi.get(`/verify`);

      if (data.userLevel === 1) {
        socket.on("userConnect", (userId) => {
          console.log("El usuario " + userId + " Se ha conectado");
        });
        socket.on("userDisconnect", (userId) => {
          console.log("SE HA DESCONECTADO AL USUARIO", userId)
        });
      }

      socket.emit("reconnectingUser", data._id);
      console.log("SE HA CONECTADO AL USUARIO", { data })
      dispatch({
        type: types.login,
        payload: {
          ...data,
          token: data.token,
          userLevel: data.userLevel,
          userTheme: data.theme,
          userId: data._id,
          userName: data.username,
          dailyMessage: data.dailyMessage,
          userData: data,
        },
      });
    } catch (error) {
      console.log({ error })
    }
    setLoadingProfile(false);
  };

  useEffect(() => {
    checkToken().then(() => {
      const ruta = window.location.pathname.split('/')
      if (ruta[1] == 'admin' && cookie.get("loginAs"))
        cookie.remove("loginAs", { path: "/" });
    });
  }, []);

  const login = (token = "", userLevel, userTheme, userId, userName, dailyMessage, TMobile, userData) => {
    socket.emit('reconnectingUser', sessionStorage.userId);

    console.log("SE HA CONECTADO AL USUARIO", { userData })

    const action = {
      type: types.login,
      payload: { token, userLevel, userTheme, userId, userName, dailyMessage, userData },
    };

    authState.userName = action.payload.userName

    if (TMobile) {
      authState.TMobile = true;
    }

    if (userLevel === 1) {
      socket.on("userConnect", (userId) => {
        console.log("El usuario " + userId + " Se ha conectado");
      });
      socket.on("userDisconnect", (userId) => {
        console.log("El usuario " + userId + " Se ha desconectado");
      });
    }
    cookie.set("jwt", token, { path: "/" });
    cookie.set("userId", userId, { path: "/" });

    if (dailyMessage) {
      Swal.fire({
        title: "Mensaje del día",
        text: dailyMessage,
        icon: "info",
        confirmButtonText: "Ok",
      });
    }

    let newPath = "/";
    // switch (userLevel) {
    //   case 1:
    //     newPath = "/admin";
    //     break;
    //   case 2:
    //     newPath = "/admin/horarioslineas/NBA?table=lineas";
    //     break;
    //   case 3:
    //     newPath = "/client/index";
    //     break;
    //   default:
    //     newPath = "/";
    //     break;
    // }
    // window.location.href = newPath;
    dispatch(action);
  };

  const logout = () => {
    // console.log("AUTHH", authState)
    if (cookie.get("loginAs")) {
      cookie.remove("loginAs", { path: "/" });
      window.location.href = "/admin/venta-admin";
      return;
    }
    socket.emit("logoutUser", authState.userId || cookie.get("userId"));
    socket.off();
    cookie.remove("jwt", { path: "/" });
    cookie.remove("userId", { path: "/" });
    dispatch({ type: types.logout });
  };
  return loadingProfile ? null : (
    <AuthContext.Provider
      value={{
        ...authState,
        // data: data,
        //Methods
        login,
        logout,
        userId: authState.userId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
