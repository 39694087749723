import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import Swal from 'sweetalert2';
// import { Printer } from '../../printer/Print';
import Layout from '../structures/layout';
import BtnTicket from '../BtnTicket';
import { redApi } from '../../../config/Axios';
import moment from 'moment';
import { matchupLeagues } from '../../../utils/matchUpLeagues';
import { TableGamesRed } from './theme-red/TableGames';
import { TableMatchUpsRed } from './theme-red/TableMatchUps';
import validateBets from '../../../utils/validateBets';
import Cookies from 'universal-cookie';

export default function NBA({ setUserTheme }) {

console.log("entrooo")

    const params = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [gamesForUser, setGamesForUser] = useState(null);
    const [liveGames, setLiveGames] = useState([]);
    const [leagueData, setLeagueData] = useState(null)
    const [ticketNumber, setTicketNumber] = useState(null);
    const [selectedBet, setSelectedBet] = useState(null);

    var isRunning = false
    const loadGames = async () => {
        console.log("entrooooo")
        setIsLoading(true);
        if (params.league !== 'index') {
            try {
                await redApi.get(`/leagues/getbyname?leagueName=${params.league}`).then(res => {
                    setLeagueData(res.data)
                }).catch(res => console.log(res));
                await redApi.get(
                    matchupLeagues.includes(params.league)
                        ? `/games?leagueName=${params.league}&date=${moment().format("YYYY-MM-DD")}&matchUps=true`
                        : `/games?leagueName=${params.league}&date=${moment().format("YYYY-MM-DD")}`
                )
                    .then((res) => {
                        setGamesForUser(res.data);
                    })
                    .catch(res => console.log(res));

                await redApi.get(`/games/getlivegames`).then(res => {
                    setLiveGames(res.data);
                }).catch(res => console.log(res));
                setIsLoading(false);
                const response2 = await redApi.get('/tickets?lastId=true');
                const newToken2 = response2.data.newToken
                // const newToken = response2.headers['newToken'] || response2.headers['newtoken'] || response2.headers['NewToken'];
                if (newToken2) {
                    // Guardar en cookies
                   const cookies = new Cookies();
                   cookies.remove("jwt", { path: '/' });
                   cookies.set("jwt", newToken2, { path: '/' });
                  sessionStorage.setItem("token", newToken2);
                  console.log("Token guardado!!!");
                } else {
                  console.log("newToken es undefined o no está presente.");
                }
                setTicketNumber(response2.data.lastTicketId);
                // console.log(response2.data.length);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        loadGames().catch(e => console.log(e));
    }, [params]);

    // bets
    const [bets, setBets] = useState({
        ammount: 0,
        bets: [],
        betsIf: 0
    });
    const [betToAdd, setBetToAdd] = useState({
        TeamCode: '',
        PlayType: '',
        Quantity: 0,
        IfWin: 0,
        Spread: '',
    });

    useEffect(() => {
        window.addEventListener('keydown', handleBetAddToTable);
        return () => {
            window.removeEventListener('keydown', handleBetAddToTable);
        };
    }, [betToAdd]);

    useEffect(() => {
        window.addEventListener('keydown', handleDeleteBet);
        return () => {
            window.removeEventListener('keydown', handleDeleteBet);
        };
    }, [selectedBet]);

    const handleDeleteBet = (e) => {
        if (e.key === 'Delete' && selectedBet != null) {
            const newBets = bets.bets.filter(e => e.teamCode != selectedBet);
            setBets({
                ammount: 0,
                bets: newBets
            })
            setSelectedBet(null);

            document.querySelector('#ifWinInput').disabled = false;
            document.querySelector('#quantityInput').disabled = false;
            setTotalToWin(0)
        }
    }

  const [restrictionC, setRestrictionC] = useState([]);


    const handleBetAddToTable = async (e) => {
        console.log("entroooo handleBetAddToTable")

        if (
            e.target.name === "PlayType" &&
            e.key === "Enter" &&
            betToAdd.TeamCode !== ""
        ) {
            let game = gamesForUser.find(
                (game) => {
                    if (matchupLeagues.includes(game.league.name)) {
                        return game.player.codeRD == betToAdd.TeamCode
                    } else {
                        return game.localTeam.codeRD == betToAdd.TeamCode || game.visitingTeam.codeRD == betToAdd.TeamCode
                    }
                }
            );

            if (game) {
                const isMatchUp = matchupLeagues.includes(game.league.name);
                const localLine = game.localLine;
                const visitingLine = game.visitingLine;
                let line;
                if (isMatchUp) {
                    line = game.playerLine;
                } else {
                    line = betToAdd.TeamCode == game.localTeam.codeRD || betToAdd.TeamCode == game.localTeam.code ? localLine : visitingLine;
                }
                // console.log(betToAdd.TeamCode == game.localTeam.codeRD || betToAdd.TeamCode == game.localTeam.code ? localLine : visitingLine)

                let lineToCheck;
                let price;
                let spreadChanged = false
                function returnLine(lineR) {
                    if (line[lineR] === null) return null
                    else if (['j1', 'j2', 'j3', 'j4', 'j5',].includes(lineR) && line[lineR] !== null) {
                        lineToCheck = line[lineR].moneyLine
                        return line[lineR].moneyLine.value
                    }
                    lineToCheck = line[lineR]
                    return line[lineR]?.value
                }

                switch (betToAdd.PlayType) {
                    case "M":
                        price = returnLine('moneyLine')
                        break;
                    case "R":
                        price = returnLine('price')
                        spreadChanged = true
                        betToAdd.Spread = line.gavela.value
                        break;
                    case "-":
                        price = returnLine('posMinus')
                        spreadChanged = true
                        if (isMatchUp) {
                            betToAdd.Spread = game.playerLine?.total?.value
                        } else {
                            betToAdd.Spread = visitingLine?.total.value
                        }
                        break;
                    case "+":
                        price = returnLine('posPlus')
                        spreadChanged = true
                        if (isMatchUp) {
                            betToAdd.Spread = game.playerLine?.total?.value
                        } else {
                            betToAdd.Spread = localLine?.total.value
                        }
                        break;
                    case "S+":
                        price = returnLine('singlePlus')
                        spreadChanged = true
                        betToAdd.Spread = line.single.value
                        break;
                    case "S-":
                        price = returnLine('singleMinus')
                        spreadChanged = true
                        betToAdd.Spread = line.single.value
                        break;
                    case "P":
                        price = returnLine('superRunLine')
                        spreadChanged = true
                        betToAdd.Spread = line.superGavela.value
                        break;
                    case "AR":
                        price = returnLine('altRunLine')
                        spreadChanged = true
                        betToAdd.Spread = line.altGavela.value
                        break;
                    case "H":
                        price = returnLine('h1Rl')
                        spreadChanged = true
                        betToAdd.Spread = line.h1RlGavela.value
                        break;
                    case "MH":
                        price = returnLine('h1')
                        break;
                    case "Y":
                        price = returnLine('sia')
                        break;
                    case "N":
                        price = returnLine('noa')
                        break;
                    case "F":
                        price = returnLine('firstRun')
                        break;
                    case "K+":
                        price = returnLine('strikeoutsPlus')
                        spreadChanged = true
                        betToAdd.Spread = line.strikeoutsGavela.value
                        break;
                    case "K-":
                        price = returnLine('strikeoutsMinus')
                        spreadChanged = true
                        betToAdd.Spread = line.strikeoutsGavela.value
                        break;
                    case "T":
                        price = returnLine('third')
                        break;
                    case "T+":
                        price = returnLine('moneyLineT3')
                        break;
                    case "T-":
                        price = returnLine('moneyLineT3')
                        break;
                    case "E":
                        price = returnLine('draw')
                        break;
                    case "Q1":
                        price = returnLine('RunLineQ1')
                        spreadChanged = true
                        betToAdd.Spread = line.RunLineQ1Gavela.value
                        break;
                    case "Q2":
                        price = returnLine('RunLineQ2')
                        spreadChanged = true
                        betToAdd.Spread = line.RunLineQ2Gavela.value
                        break;
                    case "Q3":
                        price = returnLine('RunLineQ3')
                        spreadChanged = true
                        betToAdd.Spread = line.RunLineQ3Gavela.value
                        break;
                    case "Q4":
                        price = returnLine('RunLineQ4')
                        spreadChanged = true
                        betToAdd.Spread = line.RunLineQ4Gavela.value
                        break;
                    case "Q1+":
                        price = returnLine('priceQ1Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ1Over.value
                        break;
                    case "Q2+":
                        price = returnLine('priceQ2Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ2Over.value
                        break;
                    case "Q3+":
                        price = returnLine('priceQ3Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ3Over.value
                        break;
                    case "Q4+":
                        price = returnLine('priceQ4Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ4Over.value
                        break;
                    case "Q1-":
                        price = returnLine('priceQ1Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ1Under.value
                        break;
                    case "Q2-":
                        price = returnLine('priceQ2Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ2Under.value
                        break;
                    case "Q3-":
                        price = returnLine('priceQ3Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ3Under.value
                        break;
                    case "Q4-":
                        price = returnLine('priceQ4Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ4Under.value
                        break;
                    case "Q1S+":
                        price = returnLine('priceQ1SOver')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ1S.value
                        break;
                    case "Q2S+":
                        price = returnLine('priceQ2SOver')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ2S.value
                        break;
                    case "Q3S+":
                        price = returnLine('priceQ3SOver')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ3S.value
                        break;
                    case "Q4S+":
                        price = returnLine('priceQ4SOver')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ4S.value
                        break;
                    case "Q1S-":
                        price = returnLine('priceQ1SUnder')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ1S.value
                        break;
                    case "Q2S-":
                        price = returnLine('priceQ2SUnder')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ2S.value
                        break;
                    case "Q3S-":
                        price = returnLine('priceQ3SUnder')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ3S.value
                        break;
                    case "Q4S-":
                        price = returnLine('priceQ4SUnder')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ4S.value
                        break;
                    case "T1":
                        price = returnLine('runLineT1')
                        spreadChanged = true
                        betToAdd.Spread = line.RLT1Gavela.value
                        break;
                    case "T2":
                        price = returnLine('runLineT2')
                        spreadChanged = true
                        betToAdd.Spread = line.RLT2Gavela.value
                        break;
                    case "T3":
                        price = returnLine('runLineT3')
                        spreadChanged = true
                        betToAdd.Spread = line.RLT3Gavela.value
                        break;
                    case "T1+":
                        price = returnLine('priceT1Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT1.value
                        break;
                    case "T2+":
                        price = returnLine('priceT2Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT2.value
                        break;
                    case "T3+":
                        price = returnLine('priceT3Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT2.value
                        break;
                    case "T1-":
                        price = returnLine('priceT1Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT1.value
                        break;
                    case "T2-":
                        price = returnLine('priceT2Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT2.value
                        break;
                    case "T3-":
                        price = returnLine('priceT3Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT2.value
                        break;
                    case "MT1":
                        price = returnLine('moneyLineT1')
                        break;
                    case "MT2":
                        price = returnLine('moneyLineT2')
                        break;
                    case "MT3":
                        price = returnLine('moneyLineT3')
                        break;
                    case "HR2":
                        price = returnLine('moneyLineT3')
                        break;
                    case "H+":
                        spreadChanged = true
                        betToAdd.Spread = line.HGavela.value
                        price = returnLine('HOver')
                        break;
                    case "H-":
                        spreadChanged = true
                        betToAdd.Spread = line.HGavela.value
                        price = returnLine('HUnder')
                        break;
                    case "H2+":
                        spreadChanged = true
                        betToAdd.Spread = line.H2Gavela.value
                        price = returnLine('H2Over')
                        break;
                    case "H2-":
                        spreadChanged = true
                        betToAdd.Spread = line.H2Gavela.value
                        price = returnLine('H2Over')
                        break;
                    case "H1S+":
                        spreadChanged = true
                        betToAdd.Spread = line.HSGavela.value
                        price = returnLine('HSOver')
                        break;
                    case "H1S-":
                        spreadChanged = true
                        betToAdd.Spread = line.HSGavela.value
                        price = returnLine('HSUnder')
                        break;
                    case "H2S+":
                        spreadChanged = true
                        betToAdd.Spread = line.H2SGavela.value
                        price = returnLine('H2SOver')
                        break;
                    case "H2S-":
                        spreadChanged = true
                        betToAdd.Spread = line.H2SGavela.value
                        price = returnLine('H2SUnder')
                        break;
                    case "J1":
                        price = returnLine('j1')
                        if (price === undefined || price === null) {
                            return await Swal.fire({
                                title: "Error",
                                text: 'Esta línea no está disponible',
                                icon: "error",
                                timer: 3000,
                                showConfirmButton: false,
                            });
                        }
                        break;
                    case "J2":
                        price = returnLine('j2')
                        if (price === undefined || price === null) {
                            return await Swal.fire({
                                title: "Error",
                                text: 'Esta línea no está disponible',
                                icon: "error",
                                timer: 3000,
                                showConfirmButton: false,
                            });
                        }
                        break;
                    case "J3":
                        price = returnLine('j3')
                        if (price === undefined || price === null) {
                            return await Swal.fire({
                                title: "Error",
                                text: 'Esta línea no está disponible',
                                icon: "error",
                                timer: 3000,
                                showConfirmButton: false,
                            });
                        }
                        break;
                    case "J4":
                        price = returnLine('j4')
                        if (price === undefined || price === null) {
                            return await Swal.fire({
                                title: "Error",
                                text: 'Esta línea no está disponible',
                                icon: "error",
                                timer: 3000,
                                showConfirmButton: false,
                            });
                        }
                        break;
                    case "J5":
                        price = returnLine('j5')
                        if (price === undefined || price === null) {
                            return await Swal.fire({
                                title: "Error",
                                text: 'Esta línea no está disponible',
                                icon: "error",
                                timer: 3000,
                                showConfirmButton: false,
                            });
                        }
                        break;
                    case "C":
                        setModalBuyPoints(true)
                        return
                    case "C+":
                        setModalBuyPoints(true)
                        return
                    case "C-":
                        setModalBuyPoints(true)
                        return
                    case "":
                        if (game?.league?.name === 'MLB') {
                            price = returnLine('moneyLine')
                        } else {
                            price = returnLine('runLine')
                        }
                        break;
                    default:
                        Swal.fire({
                            title: "Error",
                            text: 'Este tipo de jugada no existe',
                            icon: "error",
                            timer: 3000,
                            showConfirmButton: false,
                        });
                        return;
                }

                if (lineToCheck && lineToCheck.status !== "approved") {
                    Swal.fire({
                        title: "Error",
                        text: 'Esta línea no está aprobada',
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
                }

                if (spreadChanged == true && betToAdd.Spread == "") {
                    Swal.fire({
                        title: "Error",
                        text: 'Este línea requiere un spread',
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
                }

                const { valid, message } = validateBets(bets.bets, restrictionC, betToAdd, game, game.player ? true : false);



                if (!valid) {
                    Swal.fire({
                        title: "Error",
                        text: message,
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
                }

                console.log("PRICEEEEE", price)
                if (!price || price === "" || price === "~" || price === "0") {
                    Swal.fire({
                        title: "Error",
                        text: `La apuesta ${betToAdd.PlayType} no tiene precio`,
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
                }
                if (price === "Bet not found") {
                    Swal.fire({
                        title: "Error",
                        text: "Por el momento no se puede apostar a este juego 1",
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
                }

                if (bets.bets.length > 0) {
                    await redApi.get('/verify').then(res => {
                        let multPlays = res.data.multPlays;
                        if (multPlays) {
                            bets.bets.forEach(bet => {
                                if (bet.Price === '-110' || bet.Price === -110)
                                    bet.Price = multPlays;
                            });
                            if (price === '-110' || Number(price) === -110) {
                                price = multPlays;
                            }
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                }

                let TeamName;
                if (isMatchUp) {
                    TeamName = game.player.name;
                } else {
                    console.log("ACAAA")
                    TeamName = ['+', '-', 'H+', 'H-', 'H2+', 'H2-', 'H1+', 'H1-'].includes(betToAdd.PlayType) ? `${game.localTeam.name} vs ${game.visitingTeam.name}` : betToAdd.TeamCode == game.localTeam.code || betToAdd.TeamCode == game.localTeam.codeRD ? game.localTeam.name : game.visitingTeam.name;
                }

                const newBet = {
                    teamCode: betToAdd.TeamCode,
                    teamCodeRD: matchupLeagues.includes(game.league.name) ? game.player.codeRD : betToAdd.TeamCode == game.localTeam.code || betToAdd.TeamCode == game.localTeam.codeRD ? game.localTeam.codeRD : game.visitingTeam.codeRD,
                    playType: betToAdd.PlayType === "" ? "M" : betToAdd.PlayType,
                    Spread: betToAdd.Spread,
                    Price: price,
                    TeamName,
                    line: line._id,
                    game: game._id,
                };
                console.log("NEW BET", newBet);
                setBets({
                    ammount: parseInt(bets.ammount) + parseInt(betToAdd.Quantity),
                    bets: [...bets.bets, newBet],
                });

                setBetToAdd({
                    TeamCode: "",
                    PlayType: "",
                    Quantity: 0,
                    IfWin: 0,
                });
                //******************************************************************************* */
                let filasRows = document.querySelectorAll('.tr_table_bets');
                // Elimina 'bet-selected' de todas las filas excepto de la última
                filasRows.forEach((fila, index) => {
                    if (index !== filasRows.length - 1) {
                        fila.classList.remove('bet-selected');
                    }
                });
                //******************************************************************************** */

                // document.getElementById('quantityInput').focus();
                document.querySelector("#TeamCode").focus();
            } else {
                console.log("no game found");
            }
        }
    };

    const [printTicket, setPrintTicket] = useState({
        ticketId: 0,
        print: false
    });

    const legueNameParams = params.league
    console.log(legueNameParams)

    useEffect(() => {
        document.querySelector('#TeamCode').focus()
    }, [legueNameParams])

    // const handleBetPost = async (e) => {
    //     if ((e.key === "*" || e.key === "Enter" || e === "Print&Send") && !isRunning) {
    //         if (e === "Print&Send") {
    //             if (bets.bets.length === 0) {
    //                 Swal.fire({ icon: "error", title: "No hay apuestas insertadas", timer: 1500, showConfirmButton: false })
    //                 return;
    //             }
    //             if (!bets.ammount) {
    //                 Swal.fire({ icon: "error", title: "Monto de apuestas inválido", timer: 1500, showConfirmButton: false })
    //                 return;
    //             }
    //         }
    //         try {
    //             isRunning = true
    //             let ticket = {
    //                 amount: bets.ammount,
    //                 bets: bets.bets.map(bet => {
    //                     return {
    //                         teamCode: bet.teamCode,
    //                         playType: bet.playType,
    //                         game: bet.game,
    //                         line: bet.line,
    //                     }
    //                 }),
    //                 totalToWin
    //             }
    //             await redApi.post('/tickets', ticket)
    //                 .then(res => {
    //                     setBets([]);
    //                     setPrintTicket({
    //                         ticketId: res.data.ticketId,
    //                         print: true
    //                     });
    //                     setTotalToWin(0);
    //                     setTicketNumber(ticketNumber + 1);
    //                     setBets({
    //                         ammount: 0,
    //                         bets: []
    //                     });
    //                     let quantityInput = document.querySelector('#quantityInput');
    //                     let ifWinInput = document.querySelector('#ifWinInput');
    //                     quantityInput.value = '';
    //                     ifWinInput.disabled = false;
    //                     quantityInput.disabled = false;
    //                     setBetToAdd({
    //                         TeamCode: '',
    //                         PlayType: '',
    //                         Quantity: 0,
    //                         IfWin: 0
    //                     });
    //                     isRunning = false
    //                 })
    //         } catch (error) {
    //             console.log(error);
    //             isRunning = true
    //             Swal.fire({
    //                 title: 'Error',
    //                 text: 'Ha ocurrido un error al realizar tu apuesta',
    //                 html: `<p>Error: ${error?.response?.data?.message}</p>`,
    //                 icon: 'error',
    //                 showConfirmButton: true,
    //                 confirmButtonText: 'Ok',
    //                 confirmButtonColor: '#d33'
    //             })
    //         }
    //     }
    // }
    const [totalToWinIF, setTotalToWinIF] = useState(0);

    // useEffect(() => {
    //     window.addEventListener('keydown', handleBetPost);
    //     return () => {
    //         window.removeEventListener('keydown', handleBetPost);
    //     };
    // }, [bets]);
    const handleBetPost = async (e) => {
        console.log("entroooo betPost")
        if ((e.key === "*" || e.key === "Enter" || e === "Print&Send") && !isRunning) {
            if (!bets.readyToSubmit) return;
            console.log({ e }, "post bet01010")
            if (e === "Print&Send") {
                if (bets.bets.length === 0) {
                    Swal.fire({ icon: "error", title: "No hay apuestas insertadas", timer: 1500, showConfirmButton: false })
                    return;
                }
                if (!bets.ammount) {
                    Swal.fire({ icon: "error", title: "Monto de apuestas inválido", timer: 1500, showConfirmButton: false })
                    return;
                }
            }
            try {
                isRunning = true;
                let ticket = {
                    amount: bets.ammount,
                    bets: bets.bets.map((bet) => {
                        return {
                            teamCode: bet.teamCodeRD,
                            playType: bet.playType,
                            game: bet.game,
                            line: bet.line,
                        };
                    }),
                    totalToWin,
                    betsIf: bets.betsIf,
                    toWinIf: totalToWinIF,
                    amountIf: bets.IfWinQuantity
                };
                console.log("ticket", ticket);
                Swal.fire({
                    title: "Imprimiendo ticket",
                    text: "Espere un momento por favor...",
                    icon: "info",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                const { data } = await redApi.post("/tickets", ticket);
                if (data.error) {
                    Swal.fire({
                        title: "Error",
                        text: data.error,
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
                }
                setBets([]);
                setPrintTicket({
                    ticketId: data.ticketId,
                    print: true,
                });
                setTotalToWin(0);
                setTicketNumber(ticketNumber + 1);
                setBets({
                    ammount: 0,
                    bets: [],
                });
                let quantityInput = document.querySelector("#quantityInput");
                let ifWinInput = document.querySelector("#ifWinInput");
                quantityInput.value = "";
                ifWinInput.disabled = false;
                quantityInput.disabled = false;
                setBetToAdd({
                    TeamCode: "",
                    PlayType: "",
                    Quantity: 0,
                    IfWin: 0,
                });
                setTotalToWinIF(0)
                isRunning = false;
                let postbetmobile = document.getElementById("postbetmobile")
                if (postbetmobile) postbetmobile.style.display = "none"
                Swal.fire({
                    title: "Ticket impreso",
                    text: "El ticket se ha impreso correctamente",
                    icon: "success",
                    showConfirmButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#3085d6",
                });
                return data.ticketId;

            } catch (error) {
                console.log(error);
                isRunning = true;
                Swal.fire({
                    title: "Error",
                    text: "Ha ocurrido un error al realizar tu apuesta",
                    html: `<p>Error: ${error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data[0]?.msg ? "Monto inválido" : "Error desconocido, por favor comunicarse con soporte técnico"}</p>`,
                    icon: "error",
                    showConfirmButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#d33",
                });
                return false
            }
        }
    };
   
    const handleBetPostEnter = async (e) => {
        if (e.key === 'Enter' && bets.bets.length > 0 && bets.ammount > 0 && !isRunning) {
            console.log('enter pressed', e.key === 'Enter' && bets.bets.length > 0 && bets.ammount > 0 && !isRunning);
            // console.log('enter pressed', e.key === 'Enter' && bets.bets.length > 0 && bets.ammount > 0 && !isRunning);
            handleBetPost(e);
            return;
        }
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setMobile(window.innerWidth <= 768)
        })
    }, [])

    useEffect(() => {
        if (bets?.bets?.length > 0)
            setSelectedBet({
                teamCode: bets.bets[bets.bets.length - 1].teamCode,
                playType: bets.bets[bets.bets.length - 1].playType
            });
        if (!bets.readyToSubmit) return;
        if (mobile) {
            let postbetmobile = document.getElementById("postbetmobile")
            if (postbetmobile)
                postbetmobile.style.display = "block"
        } else {
            setTimeout(function () {
                document.addEventListener('keydown', handleBetPostEnter);
            }, 10);
            return () => {
                document.removeEventListener('keydown', handleBetPostEnter);
            }
        }
    }, [bets])

    const [totalToWin, setTotalToWin] = useState(0);


    useEffect(() => {
        document.getElementById('left-container').classList.remove('col-lg-3');
        document.getElementById('left-container').classList.add('col-lg-auto');
        //document.getElementById('left-container').classList.add('d-none');
        document.getElementById('upcomingMatches').setAttribute('class', 'd-none')
        document.getElementById('middle-container').setAttribute('class', 'col bg-light px-1')
        //document.getElementById('right-container').classList.remove('col-lg-0');
        //document.getElementById('right-container').classList.remove('d-none');
        //document.getElementById('right-container').classList.add('col-lg-3');
        document.getElementById('btnTicketsMenu').classList.add('d-none');
        document.getElementById('btnTicketsCloseMenu').classList.remove('d-none');
    }, []);


    const renderTable = () => {
        if (matchupLeagues.includes(params.league)) {
            return <TableMatchUpsRed gamesForUser={gamesForUser} />
        } else {
            return <TableGamesRed gamesForUser={gamesForUser} />
        }
    }
    const [mobile, setMobile] = useState(window.innerWidth <= 768)
    useEffect(() => {
        window.addEventListener('resize', () => {
            setMobile(window.innerWidth <= 768)
        })
    }, [])
    return (
        <Layout liveGames={liveGames} bets={bets} setBets={setBets} betToAdd={betToAdd} setBetToAdd={setBetToAdd} gamesForUser={gamesForUser} setGamesForUser={setGamesForUser} isLoading={isLoading} setUserTheme={setUserTheme} totalToWin={totalToWin} setTotalToWin={setTotalToWin} selectedBet={selectedBet} setSelectedBet={setSelectedBet} printTicket={printTicket} setPrintTicket={setPrintTicket} ticketNumber={ticketNumber} mobile={mobile} handleBetPost={handleBetPost}>
            {
                isLoading ? (
                    <div className='loader-container'>
                        <span class="loader-new"></span>
                    </div>
                )
                    : (
                        <>
                            <div className="row middle-sec" style={{ height: '20%' }}>
                                <div className="middle-header" style={{ height: '100%' }}>
                                    <div className="middle-header-content">
                                        <div className="match-detail col-auto d-flex flex-row">
                                            <span className="icon-match col-auto">
                                                <img className="img-custom" src={leagueData ? leagueData.image : ''} alt="MLB" />
                                            </span>
                                            <div className="match-detail col-auto">
                                                <h6 className="main-title">Featured</h6>
                                                <p className="sm-time">Today Live, <span id="span-today-date">{
                                                    moment().format('D MMMM')
                                                }</span></p>
                                            </div>
                                        </div>
                                        <div className="time-btn col-auto d-flex flex-column">
                                            {/* <a className="btn mb-2 btn-secondary dropdown-toggle bg-dark" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                UTC-4
                            </a> */}
                                            <BtnTicket />
                                        </div>
                                    </div>
                                    {/* middle tabs */}

                                    {/* <div className="team-tabs-match mb-2">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <button className="nav-link active" id="today-tab" data-bs-toggle="tab" data-bs-target="#today" type="button" role="tab" aria-controls="today" aria-selected="true">Today</button >
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" id="tomorrow-tab" data-bs-toggle="tab" data-bs-target="#tomorrow" type="button" role="tab" aria-controls="tomorrow" aria-selected="true">Tomorrow</button >
                            </li>
                        </ul>
                    </div> */}

                                </div>
                            </div>
                            <div className="tab-content" id="myTabContent" style={{ height: '80%' }}>

                                <div className="tab-pane fade show active" id="today" role="tabpanel" aria-labelledby="today-tab" style={{ height: '100%' }}>
                                    <div className="table-responsive" style={{ height: '100%' }}>
                                        {
                                            renderTable()
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
            }

        </Layout >
    )
}

