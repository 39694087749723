import React from 'react'

const UsuariosPermitidos = (props) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-6">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Consorcio</th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"><button className="btn btn-outline-primary">Agregar</button></th>
                                    <th scope="col"><button className="btn btn-outline-primary">Eliminar</button></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsuariosPermitidos