import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './auth/context/AuthProvider';
import App from './App';
import './assets/css/bootstrap.css'
import './index.css';
import "moment/locale/es";
import 'typeface-poppins';
import FaviconChanger from "../src/faviconChange/FaviconChanger"

require('dotenv').config();

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <FaviconChanger />
        <App />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
