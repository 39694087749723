import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { redApi } from '../../../../../config/Axios'

const NewAccount = ({ data, setData }) => {


  const [allgroups, setAllgroups] = useState([])

  useEffect(() => {
    redApi.get(`/users/groups/get/name?name=WebUsers`)
      .then(res => {
        setAllgroups(res.data)
      })
      .catch(err => console.log(err));
  }, [])

  useEffect(() => {
    setData({
      ...data,
      isGroup: allgroups._id
    })
  }, [allgroups])

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  return (
    <>
      <div className="d-flex justify-content-center gap-4 mb-2 createaccount">
        <label htmlFor="name">Name:</label>
        <input type="text" name="name" id="name" placeholder='Name' onChange={handleChange} />
      </div>
      <div className="d-flex justify-content-center gap-4 mb-2 createaccount">
        <label htmlFor="username">Username:</label>
        <input type="text" name="username" id="username" placeholder='Username' onChange={handleChange} />
      </div>
      <div className="d-flex justify-content-center gap-4 mb-2 createaccount">
        <label htmlFor="password">Password:</label>
        <input type="password" name="password" id="password" placeholder='Password' onChange={handleChange} />
      </div>
      <div>
        <div className="d-flex justify-content-center gap-4 mb-2 createaccount">
          <label htmlFor="email">Email:</label>
          <input type="email" name="email" id="email" placeholder='Email' onChange={handleChange} />
        </div>
      </div>
      <div className="d-flex justify-content-center gap-4 mb-2 createaccount">
        <label>Group</label>
        <select className="form-control" name='isGroup' value={allgroups._id || ''} disabled onChange={handleChange} >
          <option value={allgroups._id} selected>{allgroups.name}</option>
        </select>
        {/* <label htmlFor="language">Language:</label>
        <select name="language" id="language">
          <option value="0">English</option>
          <option value="1">Spanish</option>
        </select> */}
      </div>
      <div className="d-flex justify-content-center gap-4 mb-2 createaccount">
        <label htmlFor="credits">Credits:</label>
        <input type="number" name="credits" id="credits" placeholder='Credits' onChange={handleChange} />
      </div>
    </>
  )
}

export default NewAccount