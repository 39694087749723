import React, { useState, useEffect, useContext } from "react";
import { redApi } from "../../../config/Axios";
import { Modal } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLeagues } from "../../../utils/Hooks";
import { TableLines } from "../../public/layout/TableLines";
import { TableGames } from "../structures/TableGames";
import { matchupLeagues } from "../../../utils/matchUpLeagues";
import { TableMatchUps } from "../structures/LegacyTableMatchUps";
import { AuthContext } from "../../../auth";

export default function RightContainer({
  isLoading,
  setIsLoading,
  gamesForUser,
}) {
  const navigate = useNavigate();
  // change theme
  const [userTheme, setUserTheme] = useState(1);
  const handleChangeTheme = (e) => {
    setUserTheme(e.target.value);
  };
  const { logout, userTheme: themeofuser } = useContext(AuthContext);

  // modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    setIsModalVisible(false);
    await redApi.get("/verify").then(async (res) => {
      if (res.data) {
        if (res.data._id) {
          await redApi
            .put(`/users/${res.data._id}`, { theme: userTheme })
            .then((res) => {
              Swal.fire({
                icon: "success",
                title: "Theme changed",
                showConfirmButton: false,
                timer: 1500,
              });
              window.location.reload();
            });
        }
      }
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { leagues, getLeagues } = useLeagues();
  useEffect(() => {
    getLeagues();
  }, []);
  const [currentleague, setCurrentLeague] = useState({});//new

  useEffect(() => {
    let current = leagues?.find(league => league._id === gamesForUser?.[0]?.league._id);
    setCurrentLeague(current);//new
    // console.log(current);
    // setHead(current?.lineType[0]?.periods[1]?.lines);
    // setHead2(current?.lineType[0]?.periods[2]?.lines);
  }, [gamesForUser, leagues])

  const [lineTypeSelected, setLineTypeSelected] = useState("all");
  const handleChangeLineType = (e) => {
    let name = e.target.value;
    let value = name === "all" ? "all" : currentleague?.lineType?.find(lineType => lineType.name === name);
    setLineTypeSelected(value);
  }

  const renderTable = () => {
    if (!currentleague) return <></>;

    switch (lineTypeSelected) {
      case "all":
        return (
          currentleague?.lineType?.map((lineType, indexLineType) => (
            <div key={indexLineType} className="lines-games-vertical__table mb-4">
              {
                lineType?.periods?.map((period, indexPeriod) => (
                  period.period !== "Completo / 1ra Mitad " && (
                    <div key={indexPeriod} className="lines-games-vertical__table__period mb-4">
                      <div className="lines-games-vertical__table__period__title">
                        <span>{period.period}</span>
                      </div>
                      {
                        matchupLeagues.includes(currentleague.name)
                          ? (<TableMatchUps head={period.lines} games={gamesForUser} />)
                          : (<TableGames head={period.lines} games={gamesForUser} />)
                      }
                    </div>
                  )
                ))
              }
            </div>
          ))
        )

      default:
        return (
          <div className="lines-games-vertical__table mb-4">
            {
              lineTypeSelected?.periods?.map((period, indexPeriod) => (
                period.period !== "Completo / 1ra Mitad " && (
                  <div key={indexPeriod} className="lines-games-vertical__table__period mb-4">
                    <div className="lines-games-vertical__table__period__title">
                      <span>{period.period}</span>
                    </div>
                    {
                      matchupLeagues.includes(currentleague.name)
                        ? (<TableMatchUps head={period.lines} games={gamesForUser} />)
                        : (<TableGames head={period.lines} games={gamesForUser} />)
                    }
                  </div>
                )
              ))
            }
          </div>
        )
    }
  }

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center h-100 bg-card">
      <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 p-2 mb-2">
        <div className="col d-flex flex-column justify-content-center align-items-center">
          <select className="right_button w-100"
            style={{
              textAlign: "center",
            }}
            onChange={(e) => handleChangeLineType(e)}
          >
            <option value="all">Linea (Todas)</option>
            {
              currentleague?.lineType?.map((lineType, indexLineType) => (
                <option key={indexLineType} value={lineType.name}>{lineType.name}</option>
              ))
            }
          </select>
        </div>
        <div className="col d-flex flex-column justify-content-center align-items-center h-100">
          <button
            className="right_button w-100 h-100"
            onClick={() => navigate("/client/scores/top")}
          >
            Score
          </button>
        </div>
      </div>
      <div className="w-100 d-flex flex-column justify-content-start align-items-center gap-2 px-2 h-100 py-2">
        <div className="w-100 d-flex flex-row justify-content-start align-items-center gap-2">
          <div className="col-3 d-flex flex-column justify-content-center align-items-center">
            <button className="right_button w-100">
              <i className="fas fa-print px-1"></i>
              Print
            </button>
          </div>
          <div className="col-3 d-flex flex-column justify-content-center align-items-center">
            <button className="right_button w-100">
              <i className="fas fa-print px-1"></i>
              Todas
            </button>
          </div>
          <div className="col d-flex flex-column justify-content-center align-items-center">
            <button className="right_button w-100" onClick={showModal}>
              <i className="fas fa-adjust px-1"></i>
              change theme
            </button>
            <Modal
              title="Change Theme"
              open={isModalVisible}
              onCancel={handleCancel}
              width={"30%"}
              onOk={handleOk}
            >
              <div className="d-flex flex-column justify-content-center align-items-center py-2">
                <i className="fas fa-adjust px-1 h1"></i>
              </div>
              <div className="row d-flex flex-column justify-content-center align-items-center">
                <select
                  defaultValue={themeofuser || 0}
                  className="form-select col-6"
                  aria-label="Default select example"
                  onChange={handleChangeTheme}
                >
                  <option disabled value={0}>Choose theme</option>
                  <option value="1" selected={themeofuser === 1} disabled={themeofuser === 1}>Legacy</option>
                  <option value="2" selected={themeofuser === 1} disabled={themeofuser === 2}>Red</option>
                  <option value="3" selected={themeofuser === 1} disabled={themeofuser === 3}>Azul</option>
                  <option value="4" selected={themeofuser === 1} disabled={themeofuser === 4}>Theme 4</option>
                  <option value="5" selected={themeofuser === 1} disabled={themeofuser === 4}>F83</option>
                </select>
              </div>
            </Modal>
          </div>
        </div>
        <div className="Tables_right_container">
          {isLoading ? (
            <div
              className="spinner-border text-danger z-index-999 position-absolute top-50 start-50"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="table-responsive">
              {renderTable()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
