import { useEffect, useState } from 'react'
import moment from 'moment'
import "./LayoutTableGames.scss"
import Swal from 'sweetalert2'


export function TableMatchUps({
    games,
    // head: OLDHEAD,
    head,
    setBetToAdd,
    betToAdd,
    handleBetAddToTable
}) {
    // const head = [
    //   { name: 'MONEY', property: 'moneyLine' },
    //   { name: 'P MAS', property: 'posPlus' },
    //   { name: 'P MENOS', property: 'posMinus' },
    // ]

    const [codeToView, setCodeToView] = useState("LG")
    const [updatedbets, setUpdatedbets] = useState(null)

    const returnPlayType = (name) => {
        switch (name) {
            case "MONEY":
                return "M"
            case "GAVELA R/L":
                return "R"
            case "PRECIO R/L":
                return "R"
            case "TOTAL":
                return "P"
            case "SOLO (+)":
                return "S+"
            case "SOLO (-)":
                return "S-"
            case "GAVELA RL/H":
                return "H"
            case "TOTAL H":
                return "H2"
            case "SOLO (+) H":
                return "H1S+"
            case "SOLO (-) H":
                return "H1S-"
            case "SI":
                return "Y"
            case "NO":
                return "N"
            default:
                return name
        }
    }

    const handleaddBet = (line, game, head, teamCode) => {
        let PlayType = returnPlayType(head.name)
        let isLocal = teamCode === game.player.code || teamCode === game.player.codeRD ? true : false
        setBetToAdd({
            ...betToAdd,
            TeamCode: teamCode,
            PlayType: PlayType === "P" ? isLocal ? "+" : "-" : PlayType,
        })
        setUpdatedbets(true)
        Swal.fire({
            title: 'Agregando apuesta',
            text: 'Espere un momento por favor',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            timer: 400,
        })
    }


    useEffect(() => {
        if (updatedbets) {
            handleBetAddToTable(
                {
                    target: { name: "PlayType" },
                    key: "Enter"
                }
            )
            setUpdatedbets(false)
        }
    }, [betToAdd, updatedbets])

    return (
        <div id='table-matchups-lines-responsive'>
            {
                games.map((game, indexGame) => (
                    <div className='table-line' key={indexGame}>
                        <div className='container-div time-game'>
                            <div
                                className='header-div'

                            >
                                {moment(game.time).format("hh:mm A")}
                            </div>
                            <div className="body-lines">
                                <div className="team-container"
                                    onClick={() => {
                                        setBetToAdd({
                                            ...betToAdd,
                                            TeamCode: codeToView === "RD" ? game.player?.codeRD : game.player?.code ? game.player?.code : game.player?.codeRD,
                                        })
                                        document.getElementById("betTypeSelect").focus()
                                    }}
                                >
                                    <img src={game.player?.image} alt="" />
                                    {" | "}
                                    <span>
                                        {`${game.player?.name} (${game.matchType}) - ${game.player.code || game.player.codeRD}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {
                            head.map((head, indexHead) => (
                                head.name === "TOTAL" || head.name === "GAVELA R/L" || head.name === "TOTAL H" || head.name === "GAVELA RL/H" ? (
                                    <div
                                        className='container-div'
                                        key={indexHead}
                                    >
                                        <div className='header-div'

                                        >
                                            {head.name === "GAVELA R/L" ? "R.L" : head.name === "GAVELA RL/H" ? "R.L/H" : head.name}
                                        </div>
                                        <div className='body-lines'>
                                            <div className="line-container"
                                                onClick={() => handleaddBet(game.playerLine, game, head, game.player?.code || game.player?.codeRD)}
                                            >
                                                {
                                                    head.name === "TOTAL"
                                                        ? `${game.playerLine?.[head.property]?.value} OVER ${game.playerLine?.posPlus?.value !== "-" ? game.playerLine?.posPlus?.value : ""}` || '-'
                                                        : `${game.playerLine?.[head.property]?.value || "N/A"} ${game.playerLine?.price?.value || "N/A"}`
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ) : head.name !== "P MAS" && head.name !== "P MENOS" && head.name !== "PRECIO R/L" && head.name !== "SOLO" && head.name !== "SOLO H" && head.name !== "P MAS H" && head.name !== "P MENOS H" && head.name !== "PRECIO RL/H" ? (
                                    <div
                                        className='container-div'
                                        key={indexHead}
                                    >

                                        <div className='header-div'

                                        >
                                            {head.name === "MONEY" ? "M.L" : head.name}
                                        </div>

                                        <div className='body-lines'>
                                            <div className="line-container"
                                                onClick={() => handleaddBet(game.playerLine, game, head, game.player?.code || game.player?.codeRD)}
                                            >
                                                <span>

                                                    {
                                                        game.playerLine?.[head.property]?.value || "N/A"
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            ))
                        }
                    </div>
                ))
            }
        </div>
    )
}