import moment from 'moment';
import React, { useEffect, useState } from 'react'


const LineHome = ({ games, leagues, setLeagueSelected, leagueSelected }) => {

    useEffect(() => {
        (games, leagues);
    }, [games])

    useEffect(() => {
        // verify screen size
        const verifyScreenSize = () => {
            const width = window.innerWidth
            if (width < 768) {
                const container = document.querySelector(".line-home-container")
                // create popup arrow for scroll table
                const arrow = document.createElement("div")
                arrow.classList.add("arrow")
                arrow.innerHTML = `<div style="display: flex; justify-content: center; padding: 10px 10px; align-items: center; height: 100%; width: 100%; background-color: #202020; border-radius: 50%;"><i class="fas fa-chevron-right"></i></div>`
                container.appendChild(arrow)
                // styles for popup arrow
                arrow.style.position = "absolute"
                arrow.style.top = "50%"
                arrow.style.right = "0"
                arrow.style.transform = "translateY(-50%)"
                arrow.style.fontSize = "1.5rem"
                arrow.style.color = "#fff"
                arrow.style.cursor = "pointer"
                arrow.style.zIndex = "1"
                // add animation for popup bounce
                arrow.classList.add("animate__animated", "animate__bounce")
                // add event for popup arrow
                arrow.addEventListener("click", () => {
                    container.scrollLeft += 100
                }
                )
            }

            if (width > 768) {


            }
        }

        verifyScreenSize()

        window.addEventListener("resize", verifyScreenSize)

        return () => {
            window.removeEventListener("resize", verifyScreenSize)
        }

    }, [])

    return (
        <div className="line-home-container">
            <div className="line-home-container__leagues">
                {/* {
                    leagues?.map((league, index) => {
                        return (
                            <button key={index} onClick={() => setLeagueSelected(league)}>{league.name}</button>
                        )
                    })
                } */}
                {
                    leagues?.map((league, index) => {
                        return (
                            <div key={index} onClick={() => setLeagueSelected(league)}>
                                <img src={league.image} alt="" />
                            </div>
                        )
                    })
                }
            </div>
            <div className="line-home-container__table">
                {
                    leagueSelected?.lineType[0]?.periods?.map((period, index) => {
                        if (period.period === "Completo / 1ra Mitad ") {
                            return null
                        }
                        return (
                            <div key={index}>
                                <span>
                                    {period.period}
                                </span>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Tiempo</th>
                                            <th>Juego</th>
                                            {
                                                period.lines?.map((line, index) => {
                                                    return (
                                                        <th key={index}>{line.name}</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            games?.map((game, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    {moment(game.time).format("MM/DD")}
                                                                </div>
                                                                <div>
                                                                    {moment(game.time).format("h:mm A")}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div style={{

                                                                }}>
                                                                    <img src={game.localTeam.image} alt="" /> | {game.localTeam.name} - {game.localTeam.codeRD}
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    justifyContent: "start",
                                                                    padding: "0 8px"
                                                                }}>
                                                                    <img src={game.visitingTeam.image} alt="" /> | {game.visitingTeam.name} - {game.visitingTeam.codeRD}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {
                                                            period.lines?.map((item, index) => (
                                                                <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>
                                                                    <div className="container_inputs">
                                                                        <div
                                                                            style={{
                                                                                color: game.localLine[item.property]?.value > 0 ? "#022e01" : "#2e0101"
                                                                            }}
                                                                        >
                                                                            {game.localLine[item.property]?.value}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: game.visitingLine[item.property]?.value > 0 ? "#022e01" : "#2e0101"
                                                                            }}
                                                                        >
                                                                            {game.visitingLine[item.property]?.value}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default LineHome