import React, { useState } from 'react';
import Baseball from './rules/Baseball';
import Basketball from './rules/Basketball';
import Football from './rules/Football';
import Futbol_Soccer from './rules/Futbol_Soccer';
import Hockey from './rules/Hockey';
import Boxing from './rules/Boxing';
import Mma from './rules/Mma';
import Tennis from './rules/Tennis';


function SportRules() {

  let sportsRules = [
    {
      sport: "Baseball",
      icon: "fas fa-baseball-ball",
    },
    {
      sport: "Basketball",
      icon: "fas fa-basketball-ball",
    },
    {
      sport: "Football",
      icon: "fas fa-football-ball",
    },
    {
      sport: "Futbol (Soccer)",
      icon: "fas fa-futbol",
    },
    {
      sport: "Hockey",
      icon: "fas fa-hockey-puck",
    },
    {
      sport: "Boxing",
      icon: "fas fa-fist-raised",
    },
    {
      sport: "MMA",
      icon: "fas fa-fist-raised",
    },
    {
      sport: "Tennis",
      icon: "fas fa-table-tennis",
    }
  ]

  const [selectedSport, setSelectedSport] = useState(null);

  const handleSport = (sport) => {
    setSelectedSport(sport);
  }

  return (
    <div className="rules-sport-container">
      <div>
        <h1>SportsBook Rules</h1>
      </div>
      <div>
        {
          selectedSport ?
            <div>
              <div>
                <button
                  onClick={() => setSelectedSport(null)}
                >
                  <i className="fas fa-arrow-left"></i>
                  <span>Back</span>
                </button>
              </div>
              <div>
                {
                  selectedSport.sport === "Baseball" ?
                    <Baseball />
                    : selectedSport.sport === "Basketball" ?
                      <Basketball />
                      : selectedSport.sport === "Football" ?
                        <Football />
                        : selectedSport.sport === "Futbol (Soccer)" ?
                          <Futbol_Soccer />
                          : selectedSport.sport === "Hockey" ?
                            <Hockey />
                            : selectedSport.sport === "Boxing" ?
                              <Boxing />
                              : selectedSport.sport === "MMA" ?
                                <Mma />
                                : selectedSport.sport === "Tennis" ?
                                  <Tennis />
                                  : null
                }
              </div>
            </div>
            : null
        }
        {
          !selectedSport ?
            <div className="menu-sports">
              <ul>
                {
                  sportsRules.map((sport, index) => {
                    return (
                      <li key={index} onClick={() => handleSport(sport)}>
                        <i className={sport.icon}></i>
                        <span>{sport.sport}</span>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            : null
        }
      </div>
    </div>
  )
}

export default SportRules