import React, { useState, useEffect, useContext, useRef } from "react";
import { redApi as api } from "../../../../config/Axios";
import LoadingScreen from "../../../layout/LoadingScreen";
import moment from "moment";
import { Modal } from "antd";
import { getToday } from "../../../../utils/dateToday";
import Swal from "sweetalert2";
import { Printer } from "../../../printer/Print";
import { getBetType } from "../../../../utils/getBetType";
import { ReportsContext } from "./context/ReportesContext";
import { getTicketType } from "../../../../utils/getTicketType";


let tipoDeTicket = ["Todos"];
let tipoDeJugada = ["Todas"];
let tipoDeUsuario = ["Todos"];
let pagado = ["Todos"]; // , "Si", "No"
let statusJugada;// , "Winner", "Loser", "Cancelled"
statusJugada = ["Play", "Winner", "Loser", "Cancelled"];
let aaystatusJugada = ["Play", "Winner", "Loser", "Cancelled"]; //Title options


export default function TicketsReports() {
  const firstRender = useRef(true);


  useEffect(() => {
    return () => firstRender.current = false;
  }, []);


  const [showcheck, setShowcheck] = useState(false);
  const {
    terminals: posTerminals,
    consortiums: consorcios,
    leagues,
    teams,
    loading: onLoading,
    resetTeams,
    fetchAllData,
    getTeams,
    getLeagues,
    group
  } = useContext(ReportsContext)
  fetchAllData()


  const [loading, setLoading] = useState(onLoading);
  const handleCheck = (e) => {
    if (e.target.checked) {
      setShowcheck(true);
    } else {
      setShowcheck(false);
    }
  };

  const [tickets, setTickets] = useState([]);
  const [ticketTotals, setTicketTotals] = useState({totalAmount: 0, totalPrizes: 0, totalTickets: 0})
  const [loadingMessage, setLoadingMessage] = useState("");

  // const getAllTickets = async () => {
  //   const { data } = await api.get("/tickets", {params: { initDate: moment().format("YYYY-MM-DD")}})
  //   setTickets(data);
  // };

  useEffect(() => {
    if (loading) {
      setLoadingMessage("Cargando tickets...")
    } else {
      setLoadingMessage("")
    }
  }, [loading])


  const getTickets = async () => {
  
    setLoading(true);
    const params = {};
    if (filter.fechaInicio) {
      params.initDate = filter.fechaInicio;
      if (filter.fechaFinal)
        params.finalDate = moment(filter.fechaFinal).endOf("day").format("YYYY-MM-DD");
      else
        params.finalDate = moment(filter.fechaInicio).endOf("day").format("YYYY-MM-DD");
    } else {
      params.initDate = moment().format("YYYY-MM-DD");
    }
    if (filter.consorcio !== "all") {
      params.consortium = filter.consorcio;
    }
    if (filter.liga !== "all") {
      params.league = filter.liga;
    }
    if (filter.equipo !== "all") {
      params.team = filter.equipo;
    }
    if (filter.pos !== "all") {
      params.pos = filter.pos;
    }
    if (filter.statusJugada !== "all") {
      params.ticketStatus = filter.statusJugada;
    }
    if (filter.ticketId !== "all") {
      params.ticketId = filter.ticketId;
    }
    if (filter.group !== "all") {
      params.group = filter.group
    }
    
    const { data } = await api.get("/tickets", { params });
    
    if (!firstRender.current) return;

    setTickets(data);
    let totalAmount = 0;
    let totalPrizes = 0;
    let totalTickets = data.length > 0 ? data.length : 0;
    data.forEach(ticket => {
      totalAmount += ticket.amount;
      totalPrizes += ticket.toCollect;
    });
    setTicketTotals({totalAmount, totalPrizes, totalTickets})
      setLoading(false);
    
  };

  // filters

  const [filter, setFilter] = useState({
    consorcio: "all",
    liga: "all",
    equipo: "all",
    group: "all",
    pos: "all",
    status: "all",
    tipoDeTicket: "all",
    tipoDeJugada: "all",
    tipoDeUsuario: "all",
    pagado: "all",
    statusJugada: "all",
    fechaInicio: getToday(),
    fechaFinal: "",
    ticketId: "",
  });

  const handleFilter = (e) => {
    console.log("entroooooo a handle")
    // if (e.target.name === "liga") {
    //   api
    //     .get(
    //       `/teams?league=${e.target.value}&initDate=${moment().format(
    //         "YYYY-MM-DD"
    //       )}`
    //     )
    //     .then((res) => {
    //       setTeams(res.data);
    //     });
    // }
   
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (filter.liga) {
      const leagueId = leagues.find((league) => league._id === filter.liga);
      if (leagueId)
        getTeams(leagueId);
      else
        resetTeams();
    }
  }, [filter.liga]);


  // set all filters in tickets
 
  useEffect(() => {
    getTickets();
  }, [filter.consorcio, filter.equipo, filter.fechaFinal, filter.fechaInicio, filter.liga, filter.pos, filter.statusJugada, filter.ticketId, filter.group]);

  

  const [showModalTicket, setShowModalTicket] = useState(false);
  const [ticketSelected, setTicketSelected] = useState({});

  const handleSelectTicket = (ticket) => {
    setShowModalTicket(true);
    setTicketSelected(ticket);
  };

  const nextTicket = () => {
    let ticketIndex = tickets.findIndex(
      (ticket) => ticket._id === ticketSelected._id
    );
    if (ticketIndex < tickets.length - 1) {
      setTicketSelected(tickets[ticketIndex + 1]);
    }
    else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No hay más tickets para mostrar, cambie los filtros",
        focusConfirm: true,
      });
    }
  };

  const prevTicket = () => {
    let ticketIndex = tickets.findIndex(
      (ticket) => ticket._id === ticketSelected._id
    );
    if (ticketIndex > 0) {
      setTicketSelected(tickets[ticketIndex - 1]);
    }
  };
  // export const Printer = ({ticketId, printTicket, setPrintTicket, sendWs = false, userId = null}) => { 
  const [printTicket, setPrintTicket] = useState({ ticketId: 0, print: false });
  const [sendWs, setSendWs] = useState(false);
  const [userId, setUserId] = useState(null);
  const [ticketId, setTicketId] = useState(null);
  const rePrintTicket = () => {
    // setPrintTicket({ ticketId: 0, print: false });
    setPrintTicket({ ticketId: ticketSelected.ticketId, print: true });
    setSendWs(false);
    setUserId(ticketSelected.createdBy._id);
    setTicketId(ticketSelected?.ticketId);
  }
  const getPriceValue = (playType, betLine) => {
    let price;

    switch (playType) {
      case "M":
        price = betLine.moneyLine.value
        break;
      case "R":
        price = betLine.price.value
        break;
      case "-":
        price = betLine.posMinus.value
        break;
      case "+":
        price = betLine.posPlus.value
        break;
      case "S+":
        price = betLine.singlePlus.value
        break;
      case "S-":
        price = betLine.singleMinus.value
        break;
      case "P":
        price = betLine.superRunLine.value
        break;
      case "AR":
        price = betLine.altRunLine.value
        break;
      case "H":
        price = betLine.h1Rl.value
        break;
      case "MH":
        price = betLine.h1.value
        break;
      case "Y":
        price = betLine.sia.value
        break;
      case "N":
        price = betLine.noa.value
        break;
      case "F":
        price = betLine.firstRun.value
        break;
      case "K+":
        price = betLine.strikeoutsPlus.value
        break;
      case "K-":
        price = betLine.strikeoutsMinus.value
        break;
      case "T":
        price = betLine.third.value
        break;
      case "E":
        price = betLine.draw.value
        break;
      default:
        price = betLine.moneyLine.value
        break;
    }
    return price;
  }

  const cancelTicket = async (ticket) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",

      confirmButtonText: "Sí, cancelar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (ticket.ticketState === "Cancelled") {
          Swal.fire({
            title: "Error!",
            text: "El ticket ya ha sido cancelado.",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
          return;
        }
        Swal.fire({
          title: "Eliminando...",
          text: "Espere un momento por favor",
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          }
        });
        await api
          .put(`/tickets/${ticket.ticketId}/cancel/`)
          .then((res) => {
            Swal.fire({
              title: "Cancelado!",
              text: "El ticket ha sido cancelado.",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            getTickets();
            setShowModalTicket(false);
          })
      }
    });
  }

  // TicketOriginal
  const [showTicketOriginal, setShowTicketOriginal] = useState(false);

  const handleShowTicketOriginal = () => {
    setShowTicketOriginal(!showTicketOriginal);
  };


  // *---------[~]---------*
  return loading
    ? <LoadingScreen>{loadingMessage}</LoadingScreen>
    : (
      <div className="container-fluid p-0  m-0 position-relative">
        <div className="row m-0 p-0">
          {
            printTicket.print ?

              <Printer ticketId={printTicket.ticketId} printTicket={printTicket} setPrintTicket={setPrintTicket} />
              : null
          }
        </div>
        <Modal
          open={showModalTicket}
          closable={false}
          onCancel={() => setShowModalTicket(false)}
          onOk={() => setShowModalTicket(false)}
          width="80%"
          className="modal_ticket"
        >
          <div
            className="row m-0 p-4"
            style={{
              fontSize: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              fontWeight: "bold",
              border: "1px solid #131313",
            }}
          >
            <div className="col-12 m-0">
              <div className="d-flex gap-2 col mx-auto">
                <div className="d-flex col gap-2 label_and_p_container"
                  style={{
                    border: "1px solid #131313",
                  }}
                >
                  <label>Ticket #: </label>
                  <p>{ticketSelected.ticketId}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container "
                  style={{
                    border: "1px solid #131313",
                  }}
                >
                  <label>Consorcio: </label>
                  <p>{ticketSelected.createdBy?.ownedBy?.username || "Admin"}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container"
                  style={{
                    border: "1px solid #131313",
                  }}
                >
                  <label>Status: </label>
                  <p>{ticketSelected.ticketState}</p>
                </div>
              </div>
            </div>
            <div className="col-12 m-0">
              <div className="d-flex gap-2 col mx-auto">
                <div className="d-flex col gap-2 label_and_p_container "
                  style={{
                    border: "1px solid #131313",
                  }}
                >
                  <label>POS: </label>
                  <p>{ticketSelected.createdBy?.username}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container "
                  style={{
                    border: "1px solid #131313",
                  }}
                >
                  <label>Fecha/Hora de venta: </label>
                  <p>
                    {moment(ticketSelected.createdAt).format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container"
                  style={{
                    border: "1px solid #131313",
                  }}
                >
                  <label>Usuario: </label>
                  <p>{ticketSelected?.user?.username || "N/A"}</p>
                </div>
              </div>
            </div>
            <div className="col-12 m-0">
              <div className="d-flex gap-2 col mx-auto">
                <div className="d-flex col gap-2 label_and_p_container "
                  style={{
                    border: "1px solid #131313",
                  }}
                >
                  <label>Apostado: </label>
                  <p>{ticketSelected.amount}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container "
                  style={{
                    border: "1px solid #131313",
                  }}
                >
                  <label>A ganar: </label>
                  <p>{ticketSelected?.toWin || "0"}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container"
                  style={{
                    border: "1px solid #131313",
                  }}
                >
                  <label>Tipo de ticket: </label>
                  <p>
                    {getTicketType(ticketSelected?.bets?.length)}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 m-0">
              <div className="d-flex gap-2 col mx-auto">
                <div className="d-flex col gap-2 label_and_p_container "
                  style={{
                    border: "1px solid #131313",
                  }}
                >
                  <label>A pagar: </label>
                  <p>{ticketSelected?.toCollect || "0"}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container "></div>
                <div className="d-flex col gap-2 label_and_p_container"></div>
              </div>
            </div>
          </div>
          <div className="row m-0 p-0 mt-4 d-flex justify-content-end gap-1">
            <button className="btn btn-danger-2"
              style={{
                width: "fit-content",
                border: "2px solid #131313",
              }}
              onClick={() => prevTicket()}
            >
              {"<-"}
            </button>
            <button className="btn btn-danger-2"
              style={{
                width: "fit-content",
                border: "2px solid #131313",
              }}
              onClick={() => cancelTicket(ticketSelected)}
            >
              Anular
            </button>
            <button className="btn btn-danger-2"
              style={{
                width: "fit-content",
                border: "2px solid #131313",
              }}
            >
              Pagar
            </button>
            <button className="btn btn-danger-2"
              style={{
                width: "fit-content",
                border: "2px solid #131313",
              }}
              onClick={() => handleShowTicketOriginal()}
            >
              Ticket original
            </button>
            <button className="btn btn-danger-2"
              style={{
                width: "fit-content",
                border: "2px solid #131313",
              }}
              onClick={() => rePrintTicket()}
            >
              Reinprimir
            </button>
            <button className="btn btn-danger-2"
              style={{
                width: "fit-content",
                border: "2px solid #131313",
              }}
              onClick={() => nextTicket()}
            >
              {"->"} Siguiente
            </button>
          </div>
          <div className="row m-0 p-0 mt-4">
            <div className="col-12 m-0 p-0 table_container" id="table_container">
              <table
              // className="table table-striped table-hover table-sm"
              >
                <thead>
                  <tr>
                    {/* <th scope="col">Codigo</th> */}
                    <th scope="col">Juego</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Spread</th>
                    <th scope="col">Price</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {ticketSelected?.bets?.map((bet, index) => {
                    return (
                      <tr key={index}

                      >
                        {/* <td>{bet?.team.code}</td> */}
                        <td
                        >{bet.player ? `${bet.player.name}` : `${bet?.game?.localTeam?.name} vs ${bet?.game?.visitingTeam?.name}`}</td>
                        <td>{bet?.playType}</td>
                        <td
                          style={{
                            color: bet?.spread > 0 ? "#b0f6a7" : "#e30730",
                          }}
                        >{bet?.spread || "-"}</td>
                        <td
                          style={{
                            color: bet?.price > 0 ? "#e30730" : "#e30730",
                          }}
                        >
                          {
                            getPriceValue(bet?.playType, bet.player ? bet.lineMatchup : bet?.line)
                          }
                        </td>
                        <td
                          style={{
                            backgroundColor: bet?.betState == "Play" ? "#f6dda7" : bet?.betState == "Winner" ? "#b0f6a7" : "#aa0045",
                            color: bet?.betState == "Play" ? "#333" : "#202020",
                          }}
                        >{bet?.betState}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
        <Modal
          open={showTicketOriginal}
          closable={false}
          width="50%"
          onCancel={() => setShowTicketOriginal(false)}
          onOk={() => setShowTicketOriginal(false)}
        >
          <div
            className="row m-0 p-4"
            style={{
              fontSize: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              fontWeight: "bold",
              border: "1px solid #131313",
            }}
          >
            <div className="col-12 m-0">
              <div className="d-flex gap-2 col mx-auto">
                <div className="d-flex col gap-2 label_and_p_container ">
                  <label>Ticket #: </label>
                  <p>{ticketSelected.ticketId}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container ">
                  <label>Consorcio: </label>
                  <p>{ticketSelected.createdBy?.ownedBy?.username || "Admin"}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container">
                  <label>Status: </label>
                  <p>{ticketSelected.ticketState}</p>
                </div>
              </div>
            </div>
            <div className="col-12 m-0">
              <div className="d-flex gap-2 col mx-auto">
                <div className="d-flex col gap-2 label_and_p_container ">
                  <label>POS: </label>
                  <p>{ticketSelected.createdBy?.username}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container ">
                  <label>Fecha/Hora de venta: </label>
                  <p>
                    {moment(ticketSelected.createdAt).format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container">
                  <label>Usuario: </label>
                  <p>{ticketSelected?.user?.username || "N/A"}</p>
                </div>
              </div>
            </div>
            <div className="col-12 m-0">
              <div className="d-flex gap-2 col mx-auto">
                <div className="d-flex col gap-2 label_and_p_container ">
                  <label>Apostado: </label>
                  <p>{ticketSelected.amount}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container ">
                  <label>A ganar: </label>
                  <p>{ticketSelected?.toWin || "0"}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container">
                  <label>Tipo de ticket: </label>
                  <p>
                    {getTicketType(ticketSelected?.bets?.length)}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 m-0">
              <div className="d-flex gap-2 col mx-auto">
                <div className="d-flex col gap-2 label_and_p_container ">
                  <label>A pagar: </label>
                  <p>{ticketSelected?.toCollect || "0"}</p>
                </div>
                <div className="d-flex col gap-2 label_and_p_container "></div>
                <div className="d-flex col gap-2 label_and_p_container"></div>
              </div>
            </div>
          </div>
          <div className="row m-0 p-0 mt-4">
            <div className="col-12 m-0 p-0 table_container" id="table_container">
              <table
              // className="table table-striped table-hover table-sm"
              >
                <thead>
                  <tr>
                    {/* <th scope="col">Codigo</th> */}
                    <th scope="col">Juego</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Spread</th>
                    <th scope="col">Price</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {ticketSelected?.bets?.map((bet, index) => {
                    return (
                      <tr key={index}>
                        {/* <td>{bet?.team.code}</td> */}
                        <td>{`${bet?.game?.localTeam?.name} vs ${bet?.game?.visitingTeam?.name}`}</td>
                        <td>{bet?.playType}</td>
                        <td>{bet?.line?.spread?.value || "-"}</td>
                        <td>
                          {
                            getPriceValue(bet?.playType, bet.player ? bet.lineMatchup : bet?.line)
                          }
                        </td>
                        <td>{bet?.betState}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
        <div className="row m-0 p-0">
          <div className="col-12  m-0 p-0 bg-gray-800 py-4">
            <div className="row m-0 p-0 gap-3">
              <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                        <label className="m-0 p-0">POS</label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <select
                          className="form-control"
                          name="pos"
                          onChange={handleFilter}
                          value={filter.pos}
                        >
                          <option value="all">Todos</option>
                          {posTerminals.map((pos, index) => (
                            <option key={index} value={pos._id}>
                              {pos.username}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                        <label className="m-0 p-0">Consorcio</label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <select
                          className="form-control"
                          name="consorcio"
                          onChange={handleFilter}
                          value={filter.consorcio}
                        >
                          <option value="all">
                            Todos
                          </option>
                          {consorcios.map((cons, index) => (
                            <option key={index} value={cons._id}>
                              {cons.username}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                        <label className="m-0 p-0">Liga</label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <select
                          className="form-control"
                          name="liga"
                          onChange={handleFilter}
                          value={filter.liga}
                        >
                          <option value="all">Todos</option>
                          {leagues.map((lig, index) => (
                            <option key={index} value={lig._id}>
                              {lig.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                        <label className="m-0 p-0">Equipo</label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <select
                          className="form-control"
                          name="equipo"
                          onChange={handleFilter}
                          value={filter.equipo}
                        >
                          <option value="all">Todos</option>
                          {teams.map((equ, index) => (
                            <option key={index} value={equ._id}>
                              {equ.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>  
                       
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                        <label className="m-0 p-0">Grupos</label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <select
                          className="form-control"
                          name="group"
                          onChange={handleFilter}
                          value={filter.group}
                        >
                          <option value="all">Todos</option>
                          {group.map((equ, index) => (
                            <option key={index} value={equ._id}>
                              {equ.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-12 m-0 p-0 d-flex justify-content-start align-items-center">
                        <input type="checkbox" onChange={handleCheck} />
                        <label className="m-0 p-0">Rango</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                        <input
                          type="button"
                          value="Refrescar"
                          onClick={getTickets}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                        {showcheck ? (
                          <label className="m-0 p-0 text-start text-nowrap">
                            Fecha inicial
                          </label>
                        ) : (
                          <label className="m-0 p-0 text-start text-nowrap">
                            Fecha
                          </label>
                        )}
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <input
                          type="date"
                          className="form-control"
                          name="fechaInicio"
                          value={filter.fechaInicio}
                          onChange={handleFilter}
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                        <label className="m-0 p-0 text-start text-nowrap">
                          Estado
                        </label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <select
                          className="form-control"
                          name="statusJugada"
                          onChange={handleFilter}
                          value={filter.statusJugada}
                        >
                          <option value="all">
                            Todos
                          </option>
                          {aaystatusJugada.map((sta, index) => (
                            <option key={index} value={statusJugada[index]}>
                              {sta}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                        <label className="m-0 p-0 text-start text-nowrap">
                          Tipo de ticket
                        </label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <select className="form-control">
                          {tipoDeTicket.map((tip, index) => (
                            <option key={index}>{tip}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                        <label className="m-0 p-0 text-start text-nowrap">
                          Tipo de jugada
                        </label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <select className="form-control">
                          {tipoDeJugada.map((tip, index) => (
                            <option key={index}>{tip}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                        <label className="m-0 p-0 text-start text-nowrap">
                          Tipo de usuario
                        </label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <select className="form-control">
                          {tipoDeUsuario.map((tip, index) => (
                            <option key={index}>{tip}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                        <label
                          className={`m-0 p-0 text-start text-nowrap ${!showcheck ? "invisible" : ""
                            }`}
                        >
                          Fecha final
                        </label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <input
                          type="date"
                          className={`form-control ${!showcheck ? "invisible" : ""
                            }`}
                          name="fechaFinal"
                          onChange={handleFilter}
                          value={filter.fechaFinal}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          defaultChecked
                        // checked={true}
                        />
                        <label className="m-0 p-0 text-start text-nowrap">
                          Ticket #
                        </label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          onBlur={handleFilter}
                          name="ticketId"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                        <label className="m-0 p-0 text-start text-nowrap">
                          Pagado
                        </label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <select className="form-control">
                          {pagado.map((pag, index) => (
                            <option key={index}>{pag}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              {/* <div className="col-1 m-0 p-0 d-flex flex-column gap-2">

              <div className="flex">
                <select className="form-control w-100">
                  <option value="Imprimir">
                    Imprimir
                  </option>
                  <option value="Exportar">Exportar</option>
                </select>
                <input type="button" value="Buscar" className="w-100"/>
                <input type="button" value="Email" className="w-100"/>
              </div>

            </div> */}

            </div>
          </div>
          <div className="col-12 m-0 p-0 pt-4 position-relative" id="table_container">
            <div className="table-responsive" style={{ maxHeight: "55vh" }}>
              <table className="table table-striped table-hover table-bordered table-sm">
                <thead className="white-space-nowrap">
                  <tr>
                    <th>Consorcio</th>
                    <th>POS</th>
                    {/* <th>Usuario</th> */}
                    <th>Ticket #</th>
                    <th>Fecha/Hora</th>
                    <th>Tipo de Ticket</th>
                    <th>Apostado</th>
                    <th>A pagar</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="white-space-nowrap">
                  {tickets?.map((ticket, index) => (
                    <tr
                      key={index}
                      style={{
                        cursor: "pointer",
                        maxHeight: "36.19px !important",
                        backgroundColor: ticket?.ticketState === "Winner" ? "rgb(246, 221, 167)" : null,
                      }}
                      onClick={() => {
                        handleSelectTicket(ticket);
                      }}
                    >
                      <td style={{ color: ticket?.ticketState === "Winner" ? "#202020" : null, }}>
                        {ticket?.createdBy?.ownedBy?.username || "Admin"}</td>
                      <td style={{ color: ticket?.ticketState === "Winner" ? "#202020" : null, }}>
                        {ticket?.createdBy ? ticket.createdBy?.username : "N/A"}
                      </td>
                      {/* <td>{ticket.usuario ? ticket.usuario : 'N/A'}</td> */}
                      <td style={{ color: ticket?.ticketState === "Winner" ? "#202020" : null, }}>#{ticket?.ticketId ? ticket.ticketId : "N/A"}</td>
                      <td style={{ color: ticket?.ticketState === "Winner" ? "#202020" : null, }}>
                        {ticket?.createdAt
                          ? ticket.createdAt.slice(0, 10) +
                          " " +
                          ticket.createdAt.slice(11, 16)
                          : "N/A"}
                      </td>
                      <td style={{ color: ticket?.ticketState === "Winner" ? "#202020" : null, }}>
                        {ticket.ticketType || getBetType(ticket?.bets.length)}
                      </td>
                      <td
                        style={{ color: ticket?.amount > 0 ? "#7cd754" : ticket?.amount < 0 ? "#ff0000" : "#202020", }}
                      >{ticket?.amount ? ticket?.amount : "N/A"}</td>
                      <td
                        style={{ color: ticket?.toCollect > 0 ? "#ff0067" : ticket?.toCollect < 0 ? "#7cd754" : "#ffffff", }}
                      >{ticket?.toCollect ? ticket?.toCollect : "0"}</td>
                      <td style={ticket?.ticketState === "Winner" ? { color: "#202020" } : {}}>{ticket?.ticketState || "N/A"}</td>
                    </tr>
                  ))}
                  {tickets?.length == 0 && (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No hay tickets
                      </td>
                    </tr>
                  )}
                </tbody>

                <tfoot className="custom-tfoot sticky-tfoot" style={{backgroundColor:'#FFBD12'}}> 
                <tr>
                  <td>Total</td>
                  <td colSpan={4}></td>
                  <td style={{ color: ticketTotals.totalPrizes > 0 ? null : "#ff0067" }}>Cantidad : {ticketTotals.totalTickets}</td>
                  <td>Venta: ${ticketTotals.totalAmount}</td>
                  <td
                    style={{ color: ticketTotals.totalPrizes > 0 ? "#ff0067" : null }}
                  >
                     Riesgo: ${ticketTotals.totalPrizes}
                  </td>
                </tr>
              </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
}
