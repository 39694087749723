import React from 'react'

function Hockey() {
  return (
    <div className="rules-sport hockey">
      <h1>
        HOCKEY
      </h1>
      <ul>
        <li>
          All games will be valid when the second period has ended.
        </li>
        <li>
          All bets on plus (+) or minus (-) will be valid when the full three regular periods have been played.
        </li>
        <li>
          All bets per period or third will be valid when the period or third to which you have bet has ended.
        </li>
        <li>
          Bets on the first score are winners when the team scores the first goal of the match, regardless of what period.
        </li>
        <li>
          Third period bets do not include any goals scored in overtime or overtime.
        </li>
      </ul>
      <span>
        <strong>Note: </strong>
        For more (+) or less (-) full game, the extra goals are valid
      </span>
    </div>
  )
}

export default Hockey