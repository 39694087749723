import { useState, useEffect } from "react";
import { redApi as api } from "../../../../../config/Axios";
import LoadingScreen from "../../../../layout/LoadingScreen";
import moment from "moment";
import { Modal } from "antd";

let tipoDeTicket = ["Todos"];
let tipoDeJugada = ["Todas"];
let tipoDeUsuario = ["Todos"];
let pagado = ["Todos"]; // , "Si", "No"
let statusJugada = ["Play", "Winner", "Loser", "Cancelled"];

export default function TicketsWebuser({
    posTerminals,
    consorcios,
}) {
    const [showcheck, setShowcheck] = useState(false);

    const handleCheck = (e) => {
        if (e.target.checked) {
            setShowcheck(true);
        } else {
            setShowcheck(false);
        }
    };

    const [tickets, setTickets] = useState([]);
    // const [posTerminals, setPosTerminals] = useState([]);
    // const [consorcios, setConsorcios] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        consorcio: consorcios?.length > 0 ? consorcios[0]?._id : "all",
        liga: "all",
        equipo: "all",
        pos: posTerminals?.length > 0 ? posTerminals[0]?._id : "all",
        status: "all",
        tipoDeTicket: "all",
        tipoDeJugada: "all",
        tipoDeUsuario: "all",
        pagado: "all",
        statusJugada: "all",
        fechaInicio: moment().format("YYYY-MM-DD"),
        fechaFinal: "",
        ticketId: "",
    });
    const getDatos = async () => {
        setLoading(true);
        setLoadingMessage("Cargando tickets...");

        await api
            .get(`/tickets?initDate=${moment().format("YYYY-MM-DD")}${filter.consorcio !== "all" ? `&consorcio=${filter.consorcio}` : ""}${filter.pos !== "all" ? `&pos=${filter.pos}` : ""}`)
            .then((res) => {
                setTickets(res.data);
                console.log(res.data);
            });
        await api.get("/leagues").then(async (res) => {
            setLeagues(res.data);
            await api.get(`/teams?league=${res.data[0]._id}`).then((res) => {
                setTeams(res.data);
                setLoading(false);
            });
        });
        setFilter({
            ...filter,
            consorcio: '',
            pos: '',
        });
    };

    useEffect(async () => {
        await getDatos();
    }, []);

    const handleFilter = (e) => {
        if (e.target.name === "liga") {
            api
                .get(
                    `/teams?league=${e.target.value}&initDate=${moment().format(
                        "YYYY-MM-DD"
                    )}`
                )
                .then((res) => {
                    setTeams(res.data);
                });
        }
        setFilter({
            ...filter,
            [e.target.name]: e.target.value,
        });
        console.log(e.target.value, e.target.name);
    };

    // set all filters in tickets
    useEffect(async () => {
        let ticketsFiltered;
        if (filter.fechaInicio !== "") {
            if (filter.fechaFinal !== "") {
                await api
                    .get(
                        `/tickets?initDate=${filter.fechaInicio}&finalDate=${moment(
                            filter.fechaFinal
                        )
                            .endOf("day")
                            .format("YYYY-MM-DD")}`
                    )
                    .then((res) => {
                        ticketsFiltered = res.data;
                    });
            } else {
                await api
                    .get(
                        `/tickets?initDate=${filter.fechaInicio}&finalDate=${moment(
                            filter.fechaInicio
                        )
                            .endOf("day")
                            .format("YYYY-MM-DD")}
             `
                    )
                    .then((res) => {
                        ticketsFiltered = res.data;
                    });
            }
        } else {
            await api
                .get(`/tickets?initDate=${moment().format("YYYY-MM-DD")}`)
                .then((res) => {
                    ticketsFiltered = res.data;
                });
        }

        // if (filter.consorcio !== "all") {
        //     ticketsFiltered = ticketsFiltered.filter((ticket) => {
        //         if (ticket?.createdBy?.ownedBy) {
        //             if (ticket.createdBy.ownedBy._id === filter.consorcio) {
        //                 return ticket;
        //             }
        //         }
        //     });
        // }
        if (filter.liga !== "all") {
            ticketsFiltered = ticketsFiltered.filter((ticket) => {
                if (ticket.bets[0].league._id === filter.liga) {
                    return ticket;
                }
            });
        }
        if (filter.equipo !== "all") {
            ticketsFiltered = ticketsFiltered.filter((ticket) => {
                if (ticket.bets[0].team?._id === filter.equipo) {
                    return ticket;
                }
            });
        }
        // if (filter.pos !== "all") {
        //     ticketsFiltered = ticketsFiltered.filter((ticket) => {
        //         if (ticket.createdBy._id === filter.pos) {
        //             return ticket;
        //         }
        //     });
        // }
        if (filter.statusJugada !== "all") {
            ticketsFiltered = ticketsFiltered.filter((ticket) => {
                if (ticket.ticketState === filter.statusJugada) {
                    return ticket;
                }
            });
        }
        if (filter.ticketId !== "") {
            ticketsFiltered = ticketsFiltered.filter((ticket) => {
                if (ticket.ticketId == filter.ticketId) {
                    return ticket;
                }
            });
        }
        setTickets(ticketsFiltered);
    }, [filter]);

    const [showModalTicket, setShowModalTicket] = useState(false);
    const [ticketSelected, setTicketSelected] = useState({});

    const handleSelectTicket = (ticket) => {
        setShowModalTicket(true);
        setTicketSelected(ticket);
        console.log(ticket);
    };
    const getPriceValue = (playType, betLine) => {

        let price;
        switch (playType) {
            case "M":
                price = betLine.moneyLine.value
                break;
            case "R":
                price = betLine.price.value
                break;
            case "-":
                price = betLine.posMinus.value
                break;
            case "+":
                price = betLine.posPlus.value
                break;
            case "S+":
                price = betLine.singlePlus.value
                break;
            case "S-":
                price = betLine.singleMinus.value
                break;
            case "P":
                price = betLine.superRunLine.value
                break;
            case "AR":
                price = betLine.altRunLine.value
                break;
            case "H":
                price = betLine.h1Rl.value
                break;
            case "MH":
                price = betLine.h1.value
                break;
            case "Y":
                price = betLine.sia.value
                break;
            case "N":
                price = betLine.noa.value
                break;
            case "F":
                price = betLine.firstRun.value
                break;
            case "K+":
                price = betLine.strikeoutsPlus.value
                break;
            case "K-":
                price = betLine.strikeoutsMinus.value
                break;
            case "T":
                price = betLine.third.value
                break;
            case "E":
                price = betLine.draw.value
                break;
            default:
                price = betLine.moneyLine.value
                break;
        }
        return price;
    }
    if (loading) return <LoadingScreen>{loadingMessage}</LoadingScreen>;

    return (
        <div className="w-100 p-0  m-0 position-relative">
            <Modal
                open={showModalTicket}
                // hidde close button
                closable={false}
                onCancel={() => setShowModalTicket(false)}
                onOk={() => setShowModalTicket(false)}
                width="80%"
            >
                <div
                    className="row m-0 p-4"
                    style={{
                        fontSize: "15px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        fontWeight: "bold",
                        border: "1px solid #131313",
                    }}
                >
                    <div className="col-12 m-0">
                        <div className="d-flex gap-2 col mx-auto">
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>Ticket #: </label>
                                <p>{ticketSelected.ticketId}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>Consorcio: </label>
                                <p>{ticketSelected.createdBy?.ownedBy?.username || "Admin"}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container">
                                <label>Status: </label>
                                <p>{ticketSelected.ticketState}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 m-0">
                        <div className="d-flex gap-2 col mx-auto">
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>POS: </label>
                                <p>{ticketSelected.createdBy?.username}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>Fecha/Hora de venta: </label>
                                <p>
                                    {moment(ticketSelected.createdAt).format("DD/MM/YYYY HH:mm")}
                                </p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container">
                                <label>Usuario: </label>
                                <p>{ticketSelected?.user?.username || "N/A"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 m-0">
                        <div className="d-flex gap-2 col mx-auto">
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>Apostado: </label>
                                <p>{ticketSelected.amount}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>A ganar: </label>
                                <p>{ticketSelected?.toWin || "N/A"}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container">
                                <label>Tipo de ticket: </label>
                                <p>
                                    {ticketSelected?.bets?.length == 1
                                        ? "Direct"
                                        : ticketSelected?.bets?.length == 2
                                            ? "Pale"
                                            : ticketSelected?.bets?.length == 3
                                                ? "Tripleta"
                                                : ticketSelected?.bets?.length == 4
                                                    ? "Cuarteta"
                                                    : ticketSelected?.bets?.length == 5
                                                        ? "Quinteta"
                                                        : ticketSelected?.bets?.length == 6
                                                            ? "Sexteta"
                                                            : ticketSelected?.bets?.length == 7
                                                                ? "Septeta"
                                                                : ticketSelected?.bets?.length == 8
                                                                    ? "Octeta"
                                                                    : ticketSelected?.bets?.length == 9
                                                                        ? "Noneta"
                                                                        : ticketSelected?.bets?.length == 10
                                                                            ? "Decima"
                                                                            : ticketSelected?.bets?.length == 11
                                                                                ? "Decima primera"
                                                                                : ticketSelected?.bets?.length == 12
                                                                                    ? "Decima segunda"
                                                                                    : ticketSelected?.bets?.length == 13
                                                                                        ? "Decima tercera"
                                                                                        : ticketSelected?.bets?.length == 14
                                                                                            ? "Decima cuarta"
                                                                                            : ticketSelected?.bets?.length == 15
                                                                                                ? "Decima quinta"
                                                                                                : ticketSelected?.bets?.length == 16
                                                                                                    ? "Decima sexta"
                                                                                                    : "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 m-0">
                        <div className="d-flex gap-2 col mx-auto">
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>A pagar: </label>
                                <p>{ticketSelected?.toCollect || "N/A"}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container border-end"></div>
                            <div className="d-flex col gap-2 label_and_p_container"></div>
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0 mt-4">
                    <div className="col-12 m-0 p-0" id="customers">
                        <table
                        // className="table table-striped table-hover table-sm"
                        >
                            <thead>
                                <tr>
                                    {/* <th scope="col">Codigo</th> */}
                                    <th scope="col">Juego</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Spread</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ticketSelected?.bets?.map((bet, index) => {
                                    return (
                                        <tr key={index}>
                                            {/* <td>{bet?.team.code}</td> */}
                                            <td>{`${bet?.game?.localTeam?.name} vs ${bet?.game?.visitingTeam?.name}`}</td>
                                            <td>{bet?.playType}</td>
                                            <td>{bet?.line?.spread?.value || "-"}</td>
                                            <td>
                                                {
                                                    getPriceValue(bet?.playType, bet?.line)
                                                }
                                            </td>
                                            <td>{bet?.betState}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
            <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0 py-4 position-relative" id="customers">
                    <table className="table-tickets-webusers">
                        <thead>
                            <tr>
                                {/* <th scope="col">Consorcio</th>
                                <th scope="col">POS</th> */}
                                {/* <th scope="col">Usuario</th> */}
                                <th scope="col">Ticket #</th>
                                <th scope="col">Fecha/Hora</th>
                                <th scope="col">Tipo de Ticket</th>
                                <th scope="col">Apostado</th>
                                <th scope="col">A pagar</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets?.map((ticket, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        cursor: "pointer",
                                        maxHeight: "36.19px !important",
                                    }}
                                    onClick={() => {
                                        handleSelectTicket(ticket);
                                    }}
                                >
                                    {/* <td>{ticket?.createdBy?.ownedBy?.username || "Admin"}</td>
                                    <td>
                                        {ticket?.createdBy ? ticket.createdBy?.username : "N/A"}
                                    </td> */}
                                    {/* <td>{ticket.usuario ? ticket.usuario : 'N/A'}</td> */}
                                    <td>#{ticket?.ticketId ? ticket.ticketId : "N/A"}</td>
                                    <td>
                                        {ticket?.createdAt
                                            ? ticket.createdAt.slice(0, 10) +
                                            " " +
                                            ticket.createdAt.slice(11, 16)
                                            : "N/A"}
                                    </td>
                                    <td>
                                        {ticket?.bets.length == 1
                                            ? "Direct"
                                            : ticket?.bets.length == 2
                                                ? "Pale"
                                                : ticket?.bets.length == 3
                                                    ? "Tripleta"
                                                    : ticket?.bets.length == 4
                                                        ? "Cuarteta"
                                                        : ticket?.bets.length == 5
                                                            ? "Quinteta"
                                                            : ticket?.bets.length == 6
                                                                ? "Sexteta"
                                                                : ticket?.bets.length == 7
                                                                    ? "Septeta"
                                                                    : ticket?.bets.length == 8
                                                                        ? "Octeta"
                                                                        : ticket?.bets.length == 9
                                                                            ? "Noneta"
                                                                            : ticket?.bets.length == 10
                                                                                ? "Decima"
                                                                                : ticket?.bets.length == 11
                                                                                    ? "Decima primera"
                                                                                    : ticket?.bets.length == 12
                                                                                        ? "Decima segunda"
                                                                                        : ticket?.bets.length == 13
                                                                                            ? "Decima tercera"
                                                                                            : ticket?.bets.length == 14
                                                                                                ? "Decima cuarta"
                                                                                                : ticket?.bets.length == 15
                                                                                                    ? "Decima quinta"
                                                                                                    : ticket?.bets.length == 16
                                                                                                        ? "Decima sexta"
                                                                                                        : "N/A"}
                                    </td>
                                    <td>{ticket?.amount ? ticket?.amount : "N/A"}</td>
                                    <td>{ticket?.toCollect ? ticket?.toCollect : "N/A"}</td>
                                    <td>{ticket?.ticketState ? ticket?.ticketState : "N/A"}</td>
                                </tr>
                            ))}
                            {tickets?.length == 0 && (
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        No hay tickets
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
