import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment-timezone';
import { redApi } from '../../../../config/Axios';
import Cookies from "universal-cookie";
const cookie = new Cookies();


export default function TmobileLeftContainer({ handleInputSelected, setBetToAdd, handleChangeBetToAdd, betToAdd, bets, setBets, totalToWin, setTotalToWin }) {
    const betType = [
        'M', 'MH', 'MT 1', 'MT 2', '-MT 3', 'R', 'H', 'Q1', 'Q2', 'Q3', 'Q4', 'T1', 'T2', 'T3', 'HR2', '+', 'H+', 'Q1 +', 'Q2+', 'Q3+', 'Q4+', 'T1+', 'T2+', 'T3+', 'H2+', '-', 'H –', 'Q1 –', 'Q2 –', 'Q3 –', 'Q4 –', 'T1 –', 'T2 –', 'T3 –', 'H2 –', 'S+', 'H1S +', 'Q1S +', 'Q2S +', 'Q3S +', 'Q4S +', 'H2S +', 'S-', 'H1 S-', 'Q1 S-', 'Q2 S-', 'Q3 S-', 'Q4 S-', 'H2S –', 'P', 'Y', 'N', 'K+', 'K –', 'F', 'C', 'V', 'C+', 'C-', 'J1', 'J2', 'J3', 'J4', 'J5', 'G', 'G+', 'G –', 'SE', 'AR', 'T +', 'T-', 'V+', 'V-', 'E', 'HR1', 'HR1 +', 'H1 –'
    ]
    const [todayAndTime, setTodayAndTime] = useState(moment.tz('America/New_York').format('YYYY-MM-DD hh:mm A'));

    useEffect(() => {
        const interval = setInterval(() => {
            // date time Coordinated Universal Time (UTC) with .format('YYYY-MM-DD HH:mm')
            let mommentResult = moment.tz('America/New_York').format('YYYY-MM-DD hh:mm A');
            setTodayAndTime(mommentResult);
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    function getToWin(bets, amount) {
        let result = 0;
        for (let i = 0; i < bets.length; i++) {

            if (bets[i].price.toString().includes('+') && bets[i].price.toString().includes('-')) {
            }
            const multiplier = bets[i].price >= 0
                ? bets[i].price / 100
                : 1 / ((bets[i].price * -1) / 100);
            result = ((result + amount) * multiplier + result);
        }
        result = Math.round(result);
        return result
    }

    // let ifWinContainer = document.querySelector('#ifWinContainer');
    // let newInput = document.createElement('input');
    // newInput.type = 'number';
    // newInput.className = 'form-control col';
    // newInput.name = 'IfWinQuantity';
    // ifWinContainer.appendChild(newInput);
    // newInput.focus();

    const handleEnterKey = useCallback((e) => {
        if (e.key === 'Enter' && bets.bets.length > 0) {
            let ifWinInput = document.querySelector('#ifWinInput');
            let quantityInput = document.querySelector('#quantityInput');
            let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
            if (parseInt(ammountBet) > 0) {
                let betsTowin = bets.bets.map((bet) => {
                    let price = bet.Price.toString().slice(-4);
                    return {
                        price: price
                    }
                })

                ifWinInput.disabled = true;
                quantityInput.disabled = true;

                let totalToWin = getToWin(betsTowin, parseInt(ammountBet));
                setTotalToWin(totalToWin);
            }

            setBets({
                ...bets,
                ammount: parseInt(ammountBet),
            })
            setBetToAdd({
                TeamCode: '',
                PlayType: '',
                Quantity: 0,
                IfWin: 0
            })

        }
    }, [bets])

    useEffect(() => {
        let ifWinInput = document.querySelector('#ifWinInput');
        ifWinInput.addEventListener('keydown', handleEnterKey);

        return () => {
            ifWinInput.removeEventListener('keydown', handleEnterKey);
        }
    }, [bets])

    // useEffect(() => {
    //     let quantityInput = document.querySelector('#quantityInput');
    //     quantityInput.addEventListener('keydown', handleQuantityEnterKey);

    //     return () => {
    //         quantityInput.removeEventListener('keydown', handleQuantityEnterKey);
    //     }
    // }, []);

    // const handleQuantityEnterKey = (e) => {
    //     if (e.key === 'Enter') {
    //         document.querySelector('#ifWinInput').focus();
    //         document.querySelector('#ifWinInput').select();
    //     }
    // }

    const handleSpaceKey = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
            if (bets.bets.length > 0) {
                document.querySelector('#quantityInput').focus();
                document.querySelector('#quantityInput').select();
            } else {
                document.querySelector('#TeamCode').focus();
                document.querySelector('#TeamCode').select();
            }
        }
    }

    const handleLogin = async (e) => {
        await redApi.get(`/users/login/as/${e.target.value}`).then((res) => {
            if (!cookie.get("loginAs")) {
                cookie.set("loginAs", res.data.token, { path: "/" });
                window.location.href = "/client";
                return;
            }
            cookie.remove("loginAs", { path: "/" });
            cookie.set("loginAs", res.data.token, { path: "/" });
            // window.location.href = "/client";
        });
    }

    useEffect(() => {
        document.addEventListener('keydown', handleSpaceKey);

        return () => {
            document.removeEventListener('keydown', handleSpaceKey);
        }
    }, [bets])


    const getCurrentUser = async () => {
        await redApi.get(`/verify`)
            .then(res => {
                setTerminalLogged(res.data)
            })
            .catch(err => console.log(err));
    }
    const [terminalLogged, setTerminalLogged] = useState({})
    const [terminalsToLogin, setTerminalsToLogin] = useState([]);
    const getTerminals = async () => {
        if (terminalLogged && terminalLogged.userLevel < 3) {
            await redApi.get('/users/getterminalsofconsortium')
                .then(res => {
                    setTerminalsToLogin(res.data);
                }).catch(err => console.log(err));
        }
    }
    useEffect(() => {
        getCurrentUser();
    }, [])
    useEffect(() => {
        getTerminals().catch(err => console.log(err));;
    }, [terminalLogged])
    return (
        // <div className="h-100 d-flex flex-column justify-content-start align-items-center">
        <div className="bg-card">
            <div className='ticket-top position-relative'>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <input type="date" disabled value={new Date().toISOString().slice(0, 10)} className="text-center form-control" />
                    </div>
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <select disabled={terminalLogged?.userLevel < 3 ? false : true} className="form-select" onChange={handleLogin}>
                            {
                                terminalLogged?.userLevel < 3
                                    ? terminalsToLogin.map((item, index) => (
                                        <option key={index} value={item._id}>{item.username}</option>
                                    ))
                                    : (<option value={terminalLogged?._id}>{terminalLogged?.username}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <p className="text-center mb-1">Code</p>
                        <input type="number" className="form-control" name='TeamCode' id='TeamCode' onClick={handleInputSelected} value={betToAdd.TeamCode} onChange={handleChangeBetToAdd} />
                    </div>
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <p className="text-center mb-1">Play type</p>
                        {/* <input type="text" className="form-control" name='PlayType' value={betToAdd.PlayType} onChange={handleChangeBetToAdd} id="betTypeSelect" /> */}
                        <select className="form-select betType" name='PlayType' value={betToAdd.PlayType} onChange={handleChangeBetToAdd} id="betTypeSelect">
                            <option value="" disabled></option>
                            {
                                betType.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <p className="text-center mb-1">Quantity</p>
                        <input type="number" className="form-control" name='Quantity' value={betToAdd.Quantity} onClick={handleInputSelected} onChange={handleChangeBetToAdd} id="quantityInput" />
                    </div>
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <p className="text-center mb-1">IF</p>
                        <div id='ifWinContainer' className="col d-flex flex-row justify-content-center align-items-center gap-2">
                            <input type="number" className="form-control col" name='IfWin' value={betToAdd.IfWin} onClick={handleInputSelected} onChange={handleChangeBetToAdd} id="ifWinInput" />
                        </div>
                    </div>

                </div>

                {/* <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2 my-1">
                        <div className="col d-flex flex-row justify-content-center align-items-center">
                            <input type="checkbox" className="form-check-input" />
                        </div>
                        <div className="col d-flex flex-row justify-content-start align-items-center">
                            <span className="text-left">Client´s Name</span>
                        </div>
                    </div> */}
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <span className="text-center mb-1">
                            Server Date/Time:
                            {'  ' + todayAndTime}
                        </span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">Closing Time</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <input type="time" className="form-control" value={new Date().toLocaleTimeString()} disabled />
                    </div>
                </div>
                {/* <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                        <div className="col d-flex flex-row justify-content-center align-items-center">
                            <p className="text-center mb-1">User:</p>
                        </div>
                        <div className="col d-flex flex-row justify-content-start align-items-center">
                            <span className="text-left">Admin</span>
                        </div>
                    </div> */}
                <div className="border-bottom-ticket"></div>
            </div>
            <div className="ticket-bottom">
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">Ticket Total</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <span className="text-left">$ {bets.ammount > 0 ? bets.ammount : ''}</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">Ticket Total</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <span className="text-left">$ {bets.ammount > 0 ? bets.ammount : ''}</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">To win</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <span className="text-left">${totalToWin > 0 ? totalToWin : 0.00}</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">To win IF</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <span className="text-left">$0.00</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">To Collect</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <span className="text-left">$ {totalToWin > 0 ? totalToWin + bets.ammount : 0.00}</span>
                    </div>
                </div>
            </div>
        </div>
        // </div>
    )
}
