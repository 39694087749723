import { Button, Modal } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { redApi } from "../../../../config/Axios";
import { getValueForInput, parseTeamName, parseTeamOrPitcherName } from "../../../../HyLUtils/functions";
import { valuesCheck } from "../../../../utils/MonyLayDictionary";
import { totals } from "./tablas/TableDinamic";

export default function ModalSuperLiniero({
    isOpen,
    close,
    head = [],
    gameSelected: game,
    indexGame,
    isPonches,
    botSelected,
    loadGames
}) {

    if (!game) return <></>;
    const [linesChanged, setLinesChanged] = useState([]);
    const [linesChangedHashMap, setLinesChangedHashMap] = useState(new Map());
    const [forceRender, setForceRender] = useState(false)

    useEffect(() => {
        for (const line in game.localLine) {
            if (game.localLine[line]?.value) {
                console.log("LOCALLL", line)
                verifyOnChangeLines(null, game['localLine']._id, 'local', line, game, true, '0')
                verifyOnChangeLines(null, game['visitingLine']._id, 'visiting', line, game, true, '0')
            }
        }
    }, [game]);

    const saveSuperLiniero = async () => {
        const changes = Array.from(linesChangedHashMap.values());
        const { data } = await redApi.patch(`/updatemanylines`, { updatedLines: changes, matchUps: false });
        await loadGames(false)
        close();
    }

    const checkValue = (e, line, team, prop, gameChange, indexGame) => {
        let valueref = e.target.value.charAt(0) == '+' ? e.target.value.slice(1) : e.target.value;
        const valuesToChange = valuesCheck[prop] ? valuesCheck[prop][valueref] : null

        if (valuesToChange) {
            if (valuesToChange.counter) changeValues(team == 'visiting' ? 'localLine' : 'visitingLine', valuesToChange.counter, game, e, line)
            if (valuesToChange.self) changeValues(team == 'visiting' ? 'visitingLine' : 'localLine', valuesToChange.self, game, e, line)
        }
    }

    const changeValues = (lineTeam, values, game, e, line) => {
        for (const value in values) {
            const inputRef = `${game[lineTeam]._id}${value}`;
            const inputToChange = document.querySelector(`[data-superliniero-ref="${ inputRef }"]`);
            if (inputToChange) inputToChange.value = values[value];
            verifyOnChangeLines(e, game[lineTeam]._id, lineTeam == "localLine" ? 'local' : 'visiting', value, game, true, values[value])
        }
    }

    const verifyOnChangeLines = (e, id, team, prop, game, force, newValue) => {
        let valueref = e ? e.target.dataset.valueref : null;
        if (valueref && valueref[0] === "+") valueref = valueref.slice(1)
        const value = newValue || newValue == 0 ? newValue : e.target.value;
        const identifier = id + team + prop;
        if (valueref !== value || force) {
            if (!linesChanged.includes(identifier)) {
                setLinesChanged(prev => [...prev, identifier]);
            }
            setLinesChangedHashMap(prev => {
                // console.log("IDENTIFICADOR", identifier)
                prev.set(identifier, { value, id, team, prop });
                return prev;
            })
        } else {
            if (linesChanged.includes(identifier)) {
                setLinesChanged(prev => prev.filter(line => line !== identifier));
                setLinesChangedHashMap(prev => {
                    prev.delete(identifier);
                    return prev;
                })
            }
        }
        setForceRender(prev => !prev)
    }


    return (
        <Modal
            open={isOpen}
            onOk={close}
            onCancel={close}
            footer={
                <div className="w-100 d-flex justify-content-center">
                    <div className="d-flex gap-4" style={{ width: "min-content" }}>
                        <Button onClick={saveSuperLiniero} className="button_cerrar">
                            Crear
                        </Button>
                        <Button onClick={close} className="button_cerrar">
                            Cerrar
                        </Button>
                    </div>
                </div>
            }
            className="modal_lines_container"
            width="fit-content"
        >
            <div className="w-100 d-flex flex-column">
                <h2 className="text-center mb-2">Super Liniero</h2>
                <div className="table-dinamic">
                    <table className="w-100">
                        <thead>
                            <tr>
                                <th colSpan={2}>
                                    <div className='d-flex justify-content-around align-items-center'>
                                        <h4>Hora</h4>
                                        <h4>Juegos Actuales</h4>
                                    </div>
                                </th>
                                {
                                    head.map((item, index) => (
                                        <th key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>{item.name}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td colSpan={2}>
                                    <div className="d-flex gap-4">
                                        <div className="d-flex flex-column ps-2">
                                            <div
                                                style={{
                                                    fontSize: "10px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {moment(game.time).format("MM/DD")}
                                            </div>
                                            <div style={{
                                                fontSize: "12px",
                                                fontWeight: "900",
                                                whiteSpace: "nowrap"
                                            }}>
                                                {moment(game.time).format("hh:mm A")}
                                            </div>
                                        </div>
                                        <div
                                            className={`d-flex flex-column px-2 games_container ${game.status}`}
                                        >
                                            <div className="d-flex align-items-center gap-2">
                                                <img src={game.localTeam.image}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = "/logos/logodefault.png";
                                                    }}
                                                    alt="" />
                                                {" | "}
                                                <span>
                                                    {localStorage.getItem('userCodesTeams') == 'rd' ? game.localTeam.codeRD : game.localTeam.code}
                                                </span>
                                                {" - "}
                                                <span>
                                                    {parseTeamOrPitcherName(game.localTeam.name, isPonches, game.localPitcher)}
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img src={game.visitingTeam.image}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = "/logos/logodefault.png";
                                                    }}
                                                    alt="" />
                                                {" | "}
                                                <span>
                                                    {localStorage.getItem('userCodesTeams') == 'rd' ? game.visitingTeam.codeRD : game.visitingTeam.code}
                                                </span>
                                                {" - "}
                                                <span>
                                                    {parseTeamName(game.visitingTeam.name)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                {
                                    head.map((item, index) => {
                                        let botName = botSelected.split('/')[0]
                                        let sportBookName
                                        if (botSelected.split('/')[1]) {
                                            sportBookName = botSelected.split('/')[1]
                                        }
                                        return (
                                            <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}

                                            >
                                                <div className="container_inputs">
                                                    {/* <div className={getClassNameForInput(game.localLine, game.league, item.property, sportBookName, botName, lineIdSelected, linesChanged, "local")}> */}
                                                    <div>
                                                        <input
                                                            type="text"
                                                            // data-valueref={game.localLine[item.property].value}
                                                            defaultValue={0}
                                                            // value={0}
                                                            // data-refinput={index + "local"}
                                                            data-superliniero-ref={game.localLine._id + item.property}
                                                            // style={getStyleForInput(game.localLine, game.league, item.property, sportBookName, botName, head, index)}
                                                            onChange={(e) => {
                                                                // lineahandleChange( e, index = indexGame, "localLine", item.property );
                                                                if (e.target.value.length !== 1 && e.target.value.charAt(0) == 0) e.target.value = e.target.value.slice(1)
                                                                checkValue(e, game.localLine._id, "local", item.property, game, indexGame)
                                                                verifyOnChangeLines(e, game.localLine._id, "local", item.property, game)
                                                            }}
                                                        />
                                                    </div>
                                                    {/* <div className={getClassNameForInput(game.visitingLine, game.league, item.property, sportBookName, botName, lineIdSelected, linesChanged, "visiting")}> */}
                                                    <div>
                                                        <input
                                                            type="text"
                                                            // data-valueref={game.visitingLine[item.property].value}
                                                            defaultValue={0}

                                                            onChange={(e) => {
                                                                // lineahandleChange( e, index = indexGame, "localLine", item.property );
                                                                if (e.target.value.length !== 1 && e.target.value.charAt(0) == 0) e.target.value = e.target.value.slice(1)
                                                                checkValue(e, game.visitingLine._id, "visiting", item.property, game, indexGame)
                                                                verifyOnChangeLines(e, game.visitingLine._id, "visiting", item.property, game)
                                                            }}
                                                        // data-refinput={index + "visiting"}
                                                        // data-refid={game.visitingLine._id + item.property}
                                                        data-superliniero-ref={game.visitingLine._id + item.property}
                                                        // style={getStyleForInput(game.visitingLine, game.league, item.property, sportBookName, botName, head, index)}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        )
                                    })
                                }
                            </tr>












                        </tbody>
                    </table>
                </div>
            </div>
        </Modal >
    );
}
