import React, { useState } from 'react'
import Swal from 'sweetalert2'
import ModalJugadores from './jugadoresTablas/ModalJugadores'

const Jugadores = () => {
    let menuSelects = [{
        id: 1,
        label: 'Equipo',
        options: ['Todos', 'St. Louis Cardinals', 'Chicago Cubs', 'Milwaukee Brewers', 'Pittsburgh Pirates', 'Cincinnati Reds']
    },
    {
        id: 2,
        label: 'Liga',
        options: ['Major League', 'NHL', 'NBA', 'NFL', 'MLB']
    },
    {
        id: 3,
        label: 'Nombre',
        input: true
    },
    {
        id: 4,
        label: 'Posicion',
        options: ['Todas', 'Pitcher', 'No Pitcher']
    }
    ]

    let dataTable = [
        {
            id: 1,
            equipo: 'St. Louis Cardinals',
            nombre: 'J.A. Happ 1',
            posicion: 'Pitcher'
        },
        {
            id: 2,
            equipo: 'Chicago Cubs',
            nombre: 'J.A. Happ 2',
            posicion: 'Pitcher'
        },
        {
            id: 3,
            equipo: 'Milwaukee Brewers',
            nombre: 'J.A. Happ 3',
            posicion: 'Pitcher'
        },
        {
            id: 4,
            equipo: 'Pittsburgh Pirates',
            nombre: 'J.A. Happ 4',
            posicion: 'Pitcher'
        }
    ]
    let buttons = ['Crear', 'Editar', 'Eliminar', 'Refrescar']
    const [modalCrear, setModalCrear] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [jugadorSelected, setJugadorSelected] = useState(null)
    const handleClick = (e) => {
        let text = e.target.innerText
        if (text === 'Crear') {
            setModalCrear(true)
        }
        if (text === 'Editar') {
            if (jugadorSelected.consorcio == '') {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Debes seleccionar un usuario para editar!',
                })
                return
            }
            setModalEditar(true)
        }
        if (text === 'Eliminar') {

            if (jugadorSelected === null) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Debes seleccionar un usuario para eliminar!',
                })
                return
            }
            Swal.fire({
                title: 'Eliminar',
                text: '¿Estas seguro de eliminar el usuario?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar'
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire(
                        'Eliminado!',
                        'El usuario ha sido eliminado.',
                        'success'
                    )
                }
            }
            )
        }
        if (text === 'Refrescar') {
            Swal.fire({
                title: 'Refrescar',
                text: '¿Estas seguro de refrescar la tabla?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Refrescar'
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire(
                        'Refrescado!',
                        'La tabla se ha refrescado.',
                        'success'
                    )
                }
            })
        }
    }

    // group select to expand
    const handleClickSelect = (jugador) => {
        if (jugadorSelected === jugador) {
            setJugadorSelected(null)
        } else {
            setJugadorSelected(jugador)
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="row mb-2">
                        <div className="col-6">
                            <div className="row">
                                {buttons.map((item, index) => {
                                    return (
                                        <div key={index} className="col-3">
                                            <button className="btn btn-outline-primary" style={{ fontSize: '12px' }} onClick={handleClick} value={item}>{item}</button>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </div>

                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <div className="row">
                                {menuSelects.map((item, index) => {
                                    return (
                                        <div key={index} className="col-3">
                                            <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                                <label className='w-25' style={{ fontSize: '12px' }} >{item.label}</label>
                                                {item.input ?
                                                    <input type="text" className="form-control w-75" id={item.label} />
                                                    :
                                                    <select className="w-75 form-select" aria-label="Default select example">
                                                        {item.options.map((option, index) => {
                                                            return (
                                                                <option key={index} value={option}>{option}</option>
                                                            )
                                                        })}
                                                    </select>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-12">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr className="table-primary w-100">
                                <th scope="col">Equipo</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Posicion</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataTable.map((item, index) => {
                                return (
                                    <tr key={index} onClick={() => handleClickSelect(item.nombre)} className={jugadorSelected === item.nombre ? 'bg-primary text-white bg-opacity-50' : ''}>
                                        <td>{item.equipo}</td>
                                        <td>{item.nombre}</td>
                                        <td>{item.posicion}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {
                modalCrear ? <ModalJugadores setModalCrear={setModalCrear} modalCrear={modalCrear} /> : null
            }
            {
                modalEditar ? <ModalJugadores setModalEditar={setModalEditar} modalEditar={modalEditar} jugadorSelected={jugadorSelected} /> : null
            }
        </div>
    )
}

export default Jugadores