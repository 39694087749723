import React, { useCallback, useState, useEffect } from "react";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import { redApi } from "../../../../config/Axios";
import { matchupLeagues } from "../../../../utils/matchUpLeagues";


const TeamsMobile = ({
  gamesForUser,
  betToAdd,
  setBetToAdd,
  isLoading
}) => {
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([])
  useEffect(() => {
    let teams = [];
    let players = [];
    console.log({ gamesForUser })
    if (!gamesForUser?.length) return
    if (matchupLeagues.includes(gamesForUser[0].league.name)) {
      console.log("is matchups")
      // gamesForUser?.forEach((game) => {
      //   if (!players.some((player) => players?._id === game.localTeam?._id)) {
      //     teams.push(game.localTeam);
      //   }
      // });
    } else {
      gamesForUser?.forEach((game) => {
        if (!teams.some((team) => team?._id === game.localTeam?._id)) {
          teams.push(game.localTeam);
        }
        if (!teams.some((team) => team?._id === game.visitingTeam?._id)) {
          teams.push(game.visitingTeam);
        }
      });
    }
    setTeams(teams);
    setPlayers(players)
  }, [gamesForUser]);

  return (
    <header className="bg-white bg-card3" id="header">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg navbar-light bg-white d-flex justify-content-center">
              {
                teams.length > 0 && !isLoading ? (
                  teams?.map((team, index) => {
                    // !gamesForUser?.length 
                    return (
                      matchupLeagues.includes(gamesForUser[0].league.name) ? <button
                        className={`navbar-brand`}
                        key={index}
                      // onClick={() => {
                      //   setBetToAdd({
                      //     ...betToAdd,
                      //     TeamCode: team.code === 0 || team.code === '0' ? team.codeRD : team.code,
                      //   })
                      //   document.querySelector('#betTypeSelect').focus()
                      // }}
                      >
                        player {index}
                        {/* <img
                          src={team.image}
                          alt={team.name}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/logos/logodefault.png";
                          }}
                          className="img-fluid"
                          width={50}
                          height={50}
                        /> */}
                      </button>
                        :
                        <button
                          className={`navbar-brand`}
                          key={index}
                          onClick={() => {
                            setBetToAdd({
                              ...betToAdd,
                              TeamCode: team.code === 0 || team.code === '0' ? team.codeRD : team.code,
                            })
                            document.querySelector('#betTypeSelect').focus()
                          }}
                        >
                          <img
                            src={team.image}
                            alt={team.name}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/logos/logodefault.png";
                            }}
                            className="img-fluid"
                            width={50}
                            height={50}
                          />
                        </button>
                    );
                  })
                ) : (
                  isLoading ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  ) : (
                    <div>Seleccione una liga</div>
                  )
                )}
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TeamsMobile;
