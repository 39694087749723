import React, { useState } from 'react'
import { redApi } from '../../../../config/Axios'
import Swal from 'sweetalert2'
import { ManageContacts } from './ManageContacts';

export const Contacts = ({menuActive, setMenuActive, contacts, getContacts, loading}) => {
    const [selectedContact, setSelectedContact] = useState({contact: null, action: "create"});

    const openManageContact = () =>  setMenuActive("ManageContacts");
    const closeManageContact = () => setMenuActive("Contact");

    const addContact = () => {
        console.log("add contact")
        openManageContact();
    }
    const editContact = (contact) => {
        console.log({contact})
        setSelectedContact({contact, action: "edit"});
        openManageContact();
    }
    const deleteContact = (contact) => {
        Swal.fire({
            title: `Desea eliminar al contacto ${contact.name}?`,
            text: "Esta acción no se puede revertir!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '7E22CE',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'
        }).then( async (result) => {
            if (result.isConfirmed) {
                try {
                    await redApi.delete(`/whatsapp/contacts/${contact.phone}`);
                    Swal.fire({
                        icon: 'success',
                        title: 'Contacto eliminado!',
                        timer: 1500,
                        showConfirmButton: false
                    })
                    getContacts();
                } catch (error) {
                    console.log(error)
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al eliminar el contacto',
                        timer: 1500,
                        showConfirmButton: false
                    })
                }
            }
        })
    }
    return menuActive === "Contact"
    ? (
        <div className="container-fluid p-0 m-0" style={{minHeight: "50vh"}}>
            <div className="row">
                <div className="col-12 d-flex justify-content-end my-3">
                    <button onClick={addContact}>Add Contact</button>
                </div>
            </div>
            <div className="w-100" id="customers" style={{maxHeight: "60vh", overflowY: "auto"}}>
                <table className="table-sm">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Número</th>
                            <th colSpan={2}></th>
                        </tr>
                    </thead>
                    <tbody>
                        { loading
                            ? <tr><td colSpan={4}>Cargando...</td></tr>
                            : contacts.length === 0
                                ? <tr><td colSpan={4}>No hay contactos.</td></tr>
                                : contacts.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>+{item.prefix} {item.phone}</td>
                                        <td>
                                            <button onClick={() => {editContact(item)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 18 18">
                                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                            </svg>
                                            </button>
                                        </td>
                                        <td>
                                            <button onClick={() => {deleteContact(item)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 18 18">
                                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                                            </svg>
                                            </button>
                                        </td>
                                    </tr>
                                    ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
    : <ManageContacts getContacts={getContacts} close={closeManageContact} contact={selectedContact}/>
}
