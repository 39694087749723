import React, { useEffect, useState } from 'react'
import moment from 'moment'
import "./LayoutTableGames.scss"

export const TableMatchUps = ({
    games,
    head
}) => {

    const [codeToView, setCodeToView] = useState("LG")


    return (
        <div id='table-lines-responsive'>
            <table className="w-100">
                <thead>
                    <tr>
                        <th>Hora</th>
                        <th>Juego</th>
                        {
                            head?.map((item, index) => (
                                item.name !== "P MAS" && item.name !== "P MENOS" ? (
                                    <th key={index} className={`${item.property === "singleMinus" ? "" : ""}`}>{item.name}</th>
                                ) : null
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        games?.map((game, indexGame) => (
                            <tr key={indexGame}>
                                <td> {moment(game.time).format("hh:mm A")}</td>
                                <td
                                    className={` ${game.status}`}
                                    style={{
                                        position: "sticky",
                                    }}
                                >
                                    <div className="d-flex flex-column teams">
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={game.player?.image} alt="" />
                                            {" | "}
                                            <span>
                                                {
                                                    game.player?.name.length > 20
                                                        ? game.player?.name.slice(0, 20) + "..."
                                                        : game.player?.name
                                                }
                                            </span>
                                            {" - "}
                                            <span>
                                                {
                                                    codeToView === "RD" ? game.player?.codeRD : game.player?.code ? game.player?.code : game.player?.codeRD
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                {
                                    head?.map((item, index) => (
                                        item.name === "TOTAL" ? (
                                            <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>
                                                <div className="container_inputs">
                                                    <div
                                                    >
                                                        <span
                                                        >
                                                            {`${game.playerLine?.[item.property]?.value} OVER ${game.playerLine?.posPlus?.value !== "-" ? game.playerLine?.posPlus?.value : ""}` || '-'}

                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        ) : item.name && item.name !== "P MAS" && item.name !== "P MENOS" ? (

                                            <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>
                                                <div className="container_inputs">
                                                    <div>
                                                        <span>
                                                            {game.playerLine?.[item.property]?.value || '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        )
                                            : null
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
