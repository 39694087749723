import LeftContainer from '../navigation/LeftContainer'
import '../navigation/left.scss'
import { HyLright } from './HyLright'
import { HyRProvider } from '../../../HyLUtils/context/HyLContext'

export const Horarioslineas = () => {
    return (
        <HyRProvider>
            <section id="bodyAdminSideBar">
                <div className="side_wrapper">
                    <div id="left_container" className="" style={{
                        minWidth: "40px",
                    }}>
                        <LeftContainer />
                    </div>
                    <div id="right_container" className="" >
                        <HyLright />
                    </div>
                </div>
            </section>
        </HyRProvider>
    )
}