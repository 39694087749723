import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Layout from "../structures/layout";
// import './Monitor.css';

// import AccountState from './monitor/AccountState'
// import MonitorTickets from './monitor/MonitorTickets'
// import NumberList from './monitor/NumberList'
// import NumberWinners from './monitor/NumberWinners'
// import ResultadoVenta from './monitor/ResultadoVenta'
// import TotalSales from './monitor/TotalSales'

import { redApi } from "../../../config/Axios";
import { socket } from "../../../socket";
import moment from "moment";
import Swal from "sweetalert2";
import { createGlobalStyle } from "styled-components";

export default function Monitor() {
  const [terminals, setTerminals] = useState([]);
  const [terminalSelected, setTerminalSelected] = useState({});

  const getTerminals = async () => {
    const res = await redApi.get("/users?userLevel=3");
    setTerminals(res.data);
  };

  useEffect(() => {
    getTerminals();
  }, []);

  const blockUser = async (id) => {
    const res = await redApi.put(`/users/block/${id}`);
    getTerminals();
  };

  const unblockUser = async (id) => {
    const res = await redApi.put(`/users/desblock/${id}`);
    getTerminals();
  };

  const selectTerminal = (terminal) => {
    setTerminalSelected(terminal);
  };

  const disconnectTerminal = async (id) => {
    if (!id) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `No hay terminal seleccionada`,
        timer: 1000,
      });
      return;
    }
    const res = await redApi.put(`/users/logout/${id}`);
    getTerminals();
    Swal.fire({
      icon: "success",
      title: "Terminal desconectada",
      text: `Terminal ${id} desconectada`,
      timer: 1000,
    });
  };

  useEffect(() => {
    socket.on("userConnect", (userId) => {
      getTerminals();
    });
    socket.on("userDisconnect", (userId) => {
      getTerminals();
    });
  }, []);

  return (
    <Layout>
      <Container className="bg-card p-4 monitor_container">
        <form>
          <div className="row button-nav mb-4 d-flex align-items-center">
            {/* <div className="col-auto mb-2 d-flex align-items-center">
              <button type="button" onClick={getTerminals()}>Refrescar</button>
            </div> */}
          </div>
        </form>
        <div className="row button-nav mb-4 d-flex align-items-center">
          <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center"></div>
          {/* <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
            <button data-bs-toggle="modal" data-bs-target="#resultadoventaModal">Imprimir Resultado de Venta</button>
          </div>
          <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
            <button data-bs-toggle="modal" data-bs-target="#estadoModal">Estado de Cuenta</button>
          </div>
          <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
            <button data-bs-toggle="modal" data-bs-target="#totalVentas">Total de Ventas</button>
          </div>
          <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
            <button data-bs-toggle="modal" data-bs-target="#ticketsModal">Ticket Premiado</button>
          </div>
          <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
            <button data-bs-toggle="modal" data-bs-target="#listanoModal">Lista de No.</button>
          </div>
          <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
            <button data-bs-toggle="modal" data-bs-target="#ganadoresModal">No. Ganadores</button>
          </div> */}
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <div className="table-responsive-lg" id="table_container" >
              <table>
                <thead>
                  <tr>
                    <th className="text-center">User</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Desconectar</th>
                    <th className="text-center">Bloquear</th>
                    <th className="text-center">Last connection</th>
                  </tr>
                </thead>
                <tbody id="tableMonitor">
                  {terminals.map((terminal, index) => (
                    <tr
                      key={index}
                      onClick={() => selectTerminal(terminal)}
                      className={
                        terminalSelected._id === terminal._id
                          ? "table-active"
                          : ""
                      }
                    >
                      <td className="text-center">{terminal.username}</td>
                      <td className="text-center">
                        <img
                          src={`/img/admin_monitor/${terminal.isConnect
                            ? "monitor-icon-online.png"
                            : "monitor-icon-offline.png"
                            }`}
                        />
                      </td>
                      <td className="text-center">
                        <button
                          type="button"
                          onClick={() => disconnectTerminal(terminal._id)}
                          // className="button_disconnect"
                          className={terminal.isConnect ? "button_disconnect_isConnect" : "button_disconnect"}
                        >
                          Desconectar
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          type="button"
                          onClick={
                            terminal.isBlocked
                              ? () => unblockUser(terminal._id)
                              : () => blockUser(terminal._id)
                          }
                          className="button_block"
                        >
                          {terminal.isBlocked ? "unblock" : "block"}
                        </button>

                        {/* <ToggleButton
                            terminal={terminal}
                            unblockUser={unblockUser}
                            blockUser={blockUser}
                          /> */}

                      </td>
                      <td className="text-center">
                        {moment(terminal.updatedAt).format(
                          "YYYY-MM-DD h:mm:ss A"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>

      {/* POP UP RESULTADO VENTA */}
      {/* <ResultadoVenta /> */}

      {/* POP UP ESTADO DE CUENTA  */}
      {/* <AccountState /> */}

      {/* POP UP TOTAL VENTAS */}
      {/* <TotalSales /> */}

      {/* POP UP Tickets */}
      {/* <MonitorTickets /> */}

      {/* POP UP Lista de No. */}
      {/* <NumberList /> */}

      {/* POP UP No. Ganadores */}
      {/* <NumberWinners /> */}
    </Layout>
  );
}

// function ToggleButton({ terminal, unblockUser, blockUser }) {
//   const [isBlocked, setIsBlocked] = useState(terminal.isBlocked);

//   const toggleUserStatus = async () => {
//     if (isBlocked) {
//       await unblockUser(terminal._id);
//     } else {
//       await blockUser(terminal._id);
//     }
//     setIsBlocked(!isBlocked);
//   };

//   return (
   
//     <div className={`toggle-container ${isBlocked ? 'blocked' : 'unblocked'}`} onClick={toggleUserStatus}>
//       <span className="status">{isBlocked ? 'Blocked' : 'Unblocked'}</span>
//     <div className="ball"></div>
//     </div>
//   );
// }