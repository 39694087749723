import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router";
import Layout from "../../structures/theme3/legacy_layout";
import { redApi } from "../../../../config/Axios";
import Swal from "sweetalert2";
import { Printer } from "../../../printer/Print.js";
import { socket } from "../../../../socket";
import moment from "moment";
import validateBets from "../../../../utils/validateBets";
import Modal from "antd/es/modal/Modal";
import "./keyboard.scss";
import '../../structures/theme3/theme3_layout.scss';
import { matchupLeagues } from "../../../../utils/matchUpLeagues";
import useGetLeague from "../../../../hooks/configuracion/useGetLeague";
import Cookies from 'universal-cookie';

export default function GamesT3({ setUserTheme }) {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [gamesForUser, setGamesForUser] = useState(null);
  const [ticketNumber, setTicketNumber] = useState(0);
  const [selectedBet, setSelectedBet] = useState({
    teamCode: "",
    playType: "",
  });

  var isRunning = false;

  const { leagues } = useGetLeague();


  useEffect(() => {
    const fetchGames = async () => {
      setIsLoading(true);
      if (params.league !== "index") {
        try {
          await redApi
            .get(
              matchupLeagues.includes(params.league) ? `/games?leagueName=${params.league}&date=${moment().format(
                "YYYY-MM-DD"
              )}&matchUps=${true}` : `/games?leagueName=${params.league}&date=${moment().format(
                "YYYY-MM-DD"
              )}`
            )
            .then((res) => {
              // const games = res.data.filter(game => {
              //     const gameDate = moment(game.time).format('DD MMMM YYYY');
              //     let today = moment().format('DD MMMM YYYY');
              //     return gameDate === today
              // });
              setGamesForUser(res.data);

            })
            .catch((res) => {
              // navigate('/client/' + res.response.data.redirect)
            });

          const response2 = await redApi.get("/tickets?lastId=true");
       
          const newToken2 = response2.data.newToken
          // const newToken = response2.headers['newToken'] || response2.headers['newtoken'] || response2.headers['NewToken'];
          if (newToken2) {
            // Guardar en cookies
           const cookies = new Cookies();
           cookies.remove("jwt", { path: '/' });
           cookies.set("jwt", newToken2, { path: '/'});
            sessionStorage.setItem("token", newToken2);
            console.log("Token guardado!!!");
          } else {
            console.log("newToken es undefined o no está presente.");
          }
          setTicketNumber(response2.data.lastTicketId);
          setIsLoading(false);
          // const cookies = new Cookies();
          // const tokenFromCookies = cookies.get("jwt");
          // console.log("Token recuperado de cookies:", tokenFromCookies);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    }
    fetchGames();
  }, [params.league]);

  useEffect(() => {
    socket.on("lineUpdate", async () => {
      await redApi.get(`/games?leagueName=${params.league}`).then((res) => {
        setGamesForUser(res.data);
      });
    });
    socket.on("gameStart", async () => {
      if (params.league !== "index") {
        await redApi.get(`/games?leagueName=${params.league}`).then((res) => {
          setGamesForUser(res.data);
        });
      }
    });

  }, []);


  // bets
  const [bets, setBets] = useState({
    ammount: 0,
    bets: [],
    betsIf: 0,
  });
  const [betToAdd, setBetToAdd] = useState({
    TeamCode: "",
    PlayType: "",
    Quantity: "",
    IfWin: "",
    Spread: "",
    Game: ""
  });

  //*************************************************************************************** */
  //                            Function Definitions                                         *


  const isMounted = useRef(true);

  const deleteBetByTeamCode = (teamIdentifier) => {
    setBets((prevBets) => {
      let newBets = prevBets.bets.filter((bet) => bet.teamCode !== teamIdentifier);
      return {
        ...prevBets,
        ammount: 0,
        bets: newBets,
      };
    });
  };

  const targetRow = (event) => {
    try {
      let focusInput = false;
      let teamCodeInput = document.querySelector('#TeamCode');

      teamCodeInput.addEventListener('focus', () => {
        focusInput = true;
      });

      teamCodeInput.addEventListener('blur', () => {
        focusInput = false;
      });

      if (teamCodeInput.value.trim() !== '') {
        return;
      }

      if ((event.key === 'Delete') && !focusInput) {
        let filas = document.querySelectorAll('.tr_table_bets');
        let ultimaFila = filas[filas.length - 1];

        if (ultimaFila && ultimaFila.parentNode && ultimaFila.parentNode.contains(ultimaFila)) {
          // let teamIdentifier = ultimaFila.querySelector('td.text-center').textContent;
          let teamIdentifier = ultimaFila.querySelector('td.text-center').textContent;
          deleteBetByTeamCode(teamIdentifier);
          // Elimina la última fila del DOM
          // ultimaFila.remove();

          // Elimina la apuesta asociada al teamCode en el estado


          let nuevasFilas = document.querySelectorAll('.tr_table_bets');

          filas.forEach(fila => {
            // fila.classList.remove('bet-selected');
          });

          if (nuevasFilas.length > 0) {
            let nuevaUltimaFila = nuevasFilas[nuevasFilas.length - 1];
            nuevaUltimaFila.classList.add('bet-selected');
          }
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    // Cleanup: Marcar el componente como desmontado
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Agregar el evento de teclado al documento
  useEffect(() => {
    window.addEventListener('keydown', targetRow);
    return () => {
      window.removeEventListener('keydown', targetRow);
    };
  }, []);


  const [restrictionJ, setRestrictionJ] = useState([]);
  const [restrictionC, setRestrictionC] = useState([]);

  const getRestrictions = async (leagues) => {
    let leagueNames = []

    if (leagues._id) leagueNames.push(leagues._id); else for (const league of leagues) leagueNames.push(league._id);

    // await redApi.get(`/restrictions/forTerminal?leagues=${leagueNames}`).then((res) => {
    //   let newRestrictionC = [];
    //   let newRestrictionJ = [];
    //   for (const restriction of res.data) {
    //     if (restriction.type == "PC") {
    //       newRestrictionC = [...newRestrictionC, restriction];
    //     } else {
    //       newRestrictionJ = [...newRestrictionJ, restriction];
    //     }
    //   }
    //   setRestrictionC(newRestrictionC);
    //   setRestrictionJ(newRestrictionJ);
    // });
  }


  useEffect(() => {
    if (leagues)
      getRestrictions(leagues)
  }, [leagues]);


  //*************************************************************************************** */

  useEffect(() => {
    window.addEventListener("keydown", handleBetAddToTable);
    return () => {
      window.removeEventListener("keydown", handleBetAddToTable);
    };
  }, [betToAdd]);

  useEffect(() => {
    window.addEventListener("keydown", handleDeleteBet);
    return () => {
      window.removeEventListener("keydown", handleDeleteBet);
    };
  }, [selectedBet]);

  const handleDeleteBet = (e) => {
    if (e.key === "Delete" && selectedBet.teamCode !== "" && bets.bets.length > 0) {
      let newBets = bets.bets.filter(bet => {
        return bet.teamCode !== selectedBet.teamCode || bet.playType !== selectedBet.playType
      })
      setBets({
        ammount: 0,
        bets: newBets,
      });
      setSelectedBet({
        teamCode: "",
        playType: "",
      });

      document.querySelector("#ifWinInput").disabled = false;
      document.querySelector("#quantityInput").disabled = false;

      setTotalToWin(0);
    }
  };

  const handleDeleteDoubleClick = (teamCode, playType) => {
    let newBets = bets.bets.filter(bet => {
      return bet.teamCode !== teamCode || bet.playType !== playType
    })
    setBets({
      ammount: 0,
      bets: newBets,
    });
    setSelectedBet({
      teamCode: "",
      playType: "",
    });

    document.querySelector("#ifWinInput").disabled = false;
    document.querySelector("#quantityInput").disabled = false;
    setTotalToWin(0);
  }

  /******************************************************************************************** */

  //   // Agrega esto en tu componente donde manejas el input y el evento "Esc"

  // const handleKeyPress = (e) => {
  //   if (e.key === 'Escape') {
  //     console.log("escapeeeeeeeeeeeeeeeeeee")
  //     const teamCodeInput = document.getElementById('TeamCode');
  //     if (teamCodeInput) {
  //       teamCodeInput.focus();
  //     }
  //   }
  // };

  // // Luego, en tu useEffect, agrega el EventListener para manejar la tecla "Esc"

  // useEffect(() => {
  //   window.addEventListener('keydown', handleKeyPress);
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, []);

  /*********************************************************************************** */

  const handleclickTr = (playType, teamCode, mobile) => {
    if (mobile) {
      let existBet = bets.bets.find(bet => {
        return bet.teamCode === teamCode && bet.playType === playType
      })
      if (existBet) {
        let newBets = bets.bets.filter(bet => {
          return bet.teamCode !== teamCode || bet.playType !== playType
        })
        setBets({
          ammount: 0,
          bets: newBets,
        });
        setSelectedBet({
          teamCode: "",
          playType: "",
        });
        if (document.querySelector("#ifWinInput"))
          document.querySelector("#ifWinInput").disabled = false;
        document.querySelector("#quantityInput").disabled = false;
        if (document.querySelector('#IfWinQuantity2'))
          document.querySelector('#IfWinQuantity2').disabled = false
        setTotalToWin(0);
        return
      }
      return
    }
    setSelectedBet({
      teamCode,
      playType,
    });
  }

  //********************************************************************* */



  const legueNameParams = params.league

  useEffect(() => {
    document.querySelector('#TeamCode').focus()
  }, [legueNameParams])





  //********************************************************************* */

  const [modalBuyPoints, setModalBuyPoints] = useState(false);
  const [inputBuyPoints, setInputBuyPoints] = useState(document.querySelector("#inputBuyPoints") ? document.querySelector("#inputBuyPoints").value : 0);

  const handlemodalBuyPoints = () => {
    setModalBuyPoints(!modalBuyPoints);
  }

  const handleInputBuyPoints = (e) => {
    if (e.target.name === 'buyPoints') {
      setInputBuyPoints(e.target.value);
      return
    }
    switch (e.target.value) {
      case "Enter":
        handleBetAddToTable2({ target: { name: "buyPoints", value: inputBuyPoints } })
        handlemodalBuyPoints()
        setInputBuyPoints("")
        document.querySelector("#inputBuyPoints").value = ""
        break
      default:
        let inptByPoints = document.querySelector("#inputBuyPoints");
        setInputBuyPoints(inptByPoints.value += e.target.value);
    }
  }


  const handleBetAddToTable = async (e) => {

    if (
      e.target.name === "PlayType" &&
      e.key === "Enter" &&
      betToAdd.TeamCode !== ""
    ) {
      let game = gamesForUser.find(
        (game) => {
          if (matchupLeagues.includes(game.league.name)) {
            return game.player.code == betToAdd.TeamCode
          } else {
            return game.localTeam.code == betToAdd.TeamCode || game.visitingTeam.code == betToAdd.TeamCode
          }
        }
      );

      if (!game) game = gamesForUser.find(
        (game) => {
          if (matchupLeagues.includes(game.league.name)) {
            return game.player.code == betToAdd.TeamCode
          } else {
            return game.localTeam.codeRD == betToAdd.TeamCode ||
              game.visitingTeam.codeRD == betToAdd.TeamCode
          }

        }
      );

      if (game) {
        const isMatchUp = matchupLeagues.includes(game.league.name);
        const localLine = game.localLine;
        const visitingLine = game.visitingLine;
        let line;
        if (isMatchUp) {
          line = game.playerLine;
        } else {
          line = betToAdd.TeamCode == game.localTeam.codeRD || betToAdd.TeamCode == game.localTeam.code ? localLine : visitingLine;
        }

        // console.log(betToAdd.TeamCode == game.localTeam.codeRD || betToAdd.TeamCode == game.localTeam.code ? localLine : visitingLine)

        let lineToCheck;
        let price;
        let spreadChanged = false
        function returnLine(lineR) {
          if (line[lineR] === null) return null
          else if (['j1', 'j2', 'j3', 'j4', 'j5',].includes(lineR) && line[lineR] !== null) {
            lineToCheck = line[lineR].moneyLine
            return line[lineR].moneyLine.value
          }
          lineToCheck = line[lineR]
          return line[lineR]?.value
        }

        if (betToAdd.PlayType == '') {
          betToAdd.PlayType = 'R'
        }
        switch (betToAdd.PlayType) {
          case "M":
            price = returnLine('moneyLine')
            break;
          case "R":
            price = returnLine('price')
            spreadChanged = true
            betToAdd.Spread = line.gavela.value
            break;
          case "-":
            price = returnLine('posMinus')
            spreadChanged = true
            if (isMatchUp) {
              betToAdd.Spread = game.playerLine?.total?.value
            } else {
              betToAdd.Spread = visitingLine?.total.value
            }
            break;
          case "+":
            price = returnLine('posPlus')
            spreadChanged = true
            if (isMatchUp) {
              betToAdd.Spread = game.playerLine?.total?.value
            } else {
              betToAdd.Spread = localLine?.total.value
            }
            break;
          case "S+":
            price = returnLine('singlePlus')
            spreadChanged = true
            betToAdd.Spread = line.single.value
            break;
          case "S-":
            price = returnLine('singleMinus')
            spreadChanged = true
            betToAdd.Spread = line.single.value
            break;
          case "P":
            price = returnLine('superRunLine')
            spreadChanged = true
            betToAdd.Spread = line.superGavela.value
            break;
          case "AR":
            price = returnLine('altRunLine')
            spreadChanged = true
            betToAdd.Spread = line.altGavela.value
            break;
          case "H":
            price = returnLine('h1Rl')
            spreadChanged = true
            betToAdd.Spread = line.h1RlGavela.value
            break;
          case "MH":
            price = returnLine('h1')
            break;
          case "Y":
            price = returnLine('sia')
            break;
          case "N":
            price = returnLine('noa')
            break;
          case "F":
            price = returnLine('firstRun')
            break;
          case "K+":
            price = returnLine('strikeoutsPlus')
            spreadChanged = true
            betToAdd.Spread = line.strikeoutsGavela.value
            break;
          case "K-":
            price = returnLine('strikeoutsMinus')
            spreadChanged = true
            betToAdd.Spread = line.strikeoutsGavela.value
            break;
          case "T":
            price = returnLine('third')
            break;
          case "T+":
            price = returnLine('moneyLineT3')
            break;
          case "T-":
            price = returnLine('moneyLineT3')
            break;
          case "E":
            price = returnLine('draw')
            break;
          case "Q1":
            price = returnLine('RunLineQ1')
            spreadChanged = true
            betToAdd.Spread = line.RunLineQ1Gavela.value
            break;
          case "Q2":
            price = returnLine('RunLineQ2')
            spreadChanged = true
            betToAdd.Spread = line.RunLineQ2Gavela.value
            break;
          case "Q3":
            price = returnLine('RunLineQ3')
            spreadChanged = true
            betToAdd.Spread = line.RunLineQ3Gavela.value
            break;
          case "Q4":
            price = returnLine('RunLineQ4')
            spreadChanged = true
            betToAdd.Spread = line.RunLineQ4Gavela.value
            break;
          case "Q1+":
            price = returnLine('priceQ1Over')
            spreadChanged = true
            betToAdd.Spread = line.totalQ1Over.value
            break;
          case "Q2+":
            price = returnLine('priceQ2Over')
            spreadChanged = true
            betToAdd.Spread = line.totalQ2Over.value
            break;
          case "Q3+":
            price = returnLine('priceQ3Over')
            spreadChanged = true
            betToAdd.Spread = line.totalQ3Over.value
            break;
          case "Q4+":
            price = returnLine('priceQ4Over')
            spreadChanged = true
            betToAdd.Spread = line.totalQ4Over.value
            break;
          case "Q1-":
            price = returnLine('priceQ1Under')
            spreadChanged = true
            betToAdd.Spread = line.totalQ1Under.value
            break;
          case "Q2-":
            price = returnLine('priceQ2Under')
            spreadChanged = true
            betToAdd.Spread = line.totalQ2Under.value
            break;
          case "Q3-":
            price = returnLine('priceQ3Under')
            spreadChanged = true
            betToAdd.Spread = line.totalQ3Under.value
            break;
          case "Q4-":
            price = returnLine('priceQ4Under')
            spreadChanged = true
            betToAdd.Spread = line.totalQ4Under.value
            break;
          case "Q1S+":
            price = returnLine('priceQ1SOver')
            spreadChanged = true
            betToAdd.Spread = line.totalQ1S.value
            break;
          case "Q2S+":
            price = returnLine('priceQ2SOver')
            spreadChanged = true
            betToAdd.Spread = line.totalQ2S.value
            break;
          case "Q3S+":
            price = returnLine('priceQ3SOver')
            spreadChanged = true
            betToAdd.Spread = line.totalQ3S.value
            break;
          case "Q4S+":
            price = returnLine('priceQ4SOver')
            spreadChanged = true
            betToAdd.Spread = line.totalQ4S.value
            break;
          case "Q1S-":
            price = returnLine('priceQ1SUnder')
            spreadChanged = true
            betToAdd.Spread = line.totalQ1S.value
            break;
          case "Q2S-":
            price = returnLine('priceQ2SUnder')
            spreadChanged = true
            betToAdd.Spread = line.totalQ2S.value
            break;
          case "Q3S-":
            price = returnLine('priceQ3SUnder')
            spreadChanged = true
            betToAdd.Spread = line.totalQ3S.value
            break;
          case "Q4S-":
            price = returnLine('priceQ4SUnder')
            spreadChanged = true
            betToAdd.Spread = line.totalQ4S.value
            break;
          case "T1":
            price = returnLine('runLineT1')
            spreadChanged = true
            betToAdd.Spread = line.RLT1Gavela.value
            break;
          case "T2":
            price = returnLine('runLineT2')
            spreadChanged = true
            betToAdd.Spread = line.RLT2Gavela.value
            break;
          case "T3":
            price = returnLine('runLineT3')
            spreadChanged = true
            betToAdd.Spread = line.RLT3Gavela.value
            break;
          case "T1+":
            price = returnLine('priceT1Over')
            spreadChanged = true
            betToAdd.Spread = line.totalT1.value
            break;
          case "T2+":
            price = returnLine('priceT2Over')
            spreadChanged = true
            betToAdd.Spread = line.totalT2.value
            break;
          case "T3+":
            price = returnLine('priceT3Over')
            spreadChanged = true
            betToAdd.Spread = line.totalT2.value
            break;
          case "T1-":
            price = returnLine('priceT1Under')
            spreadChanged = true
            betToAdd.Spread = line.totalT1.value
            break;
          case "T2-":
            price = returnLine('priceT2Under')
            spreadChanged = true
            betToAdd.Spread = line.totalT2.value
            break;
          case "T3-":
            price = returnLine('priceT3Under')
            spreadChanged = true
            betToAdd.Spread = line.totalT2.value
            break;
          case "MT1":
            price = returnLine('moneyLineT1')
            break;
          case "MT2":
            price = returnLine('moneyLineT2')
            break;
          case "MT3":
            price = returnLine('moneyLineT3')
            break;
          case "HR2":
            price = returnLine('moneyLineT3')
            break;
          case "H+":
            spreadChanged = true
            betToAdd.Spread = line.HGavela.value
            price = returnLine('HOver')
            break;
          case "H-":
            spreadChanged = true
            betToAdd.Spread = line.HGavela.value
            price = returnLine('HUnder')
            break;
          case "H2+":
            spreadChanged = true
            betToAdd.Spread = line.H2Gavela.value
            price = returnLine('H2Over')
            break;
          case "H2-":
            spreadChanged = true
            betToAdd.Spread = line.H2Gavela.value
            price = returnLine('H2Over')
            break;
          case "H1S+":
            spreadChanged = true
            betToAdd.Spread = line.HSGavela.value
            price = returnLine('HSOver')
            break;
          case "H1S-":
            spreadChanged = true
            betToAdd.Spread = line.HSGavela.value
            price = returnLine('HSUnder')
            break;
          case "H2S+":
            spreadChanged = true
            betToAdd.Spread = line.H2SGavela.value
            price = returnLine('H2SOver')
            break;
          case "H2S-":
            spreadChanged = true
            betToAdd.Spread = line.H2SGavela.value
            price = returnLine('H2SUnder')
            break;
          case "J1":
            price = returnLine('j1')
            if (price === undefined || price === null) {
              return await Swal.fire({
                title: "Error",
                text: 'Esta línea no está disponible',
                icon: "error",
                timer: 3000,
                showConfirmButton: false,
              });
            }
            break;
          case "J2":
            price = returnLine('j2')
            if (price === undefined || price === null) {
              return await Swal.fire({
                title: "Error",
                text: 'Esta línea no está disponible',
                icon: "error",
                timer: 3000,
                showConfirmButton: false,
              });
            }
            break;
          case "J3":
            price = returnLine('j3')
            if (price === undefined || price === null) {
              return await Swal.fire({
                title: "Error",
                text: 'Esta línea no está disponible',
                icon: "error",
                timer: 3000,
                showConfirmButton: false,
              });
            }
            break;
          case "J4":
            price = returnLine('j4')
            if (price === undefined || price === null) {
              return await Swal.fire({
                title: "Error",
                text: 'Esta línea no está disponible',
                icon: "error",
                timer: 3000,
                showConfirmButton: false,
              });
            }
            break;
          case "J5":
            price = returnLine('j5')
            if (price === undefined || price === null) {
              return await Swal.fire({
                title: "Error",
                text: 'Esta línea no está disponible',
                icon: "error",
                timer: 3000,
                showConfirmButton: false,
              });
            }
            break;
          case "C":
            setModalBuyPoints(true)
            return
          case "C+":
            setModalBuyPoints(true)
            return
          case "C-":
            setModalBuyPoints(true)
            return
          case "":
            if (game?.league?.name === 'MLB') {
              price = returnLine('moneyLine')
            } else {
              price = returnLine('runLine')
            }
            break;
          default:
            Swal.fire({
              title: "Error",
              text: 'Este tipo de jugada no existe',
              icon: "error",
              timer: 3000,
              showConfirmButton: false,
            });
            return;
        }

        if (lineToCheck && lineToCheck.status !== "approved") {
          Swal.fire({
            title: "Error",
            text: 'Esta línea no está aprobada',
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
        }

        if (spreadChanged == true && betToAdd.Spread == "") {
          Swal.fire({
            title: "Error",
            text: 'Este línea requiere un spread',
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
        }

        const { valid, message } = validateBets(bets.bets, restrictionC, betToAdd, game, game.player ? true : false);

        if (!valid) {
          console.log("Errorrr")
          Swal.fire({
            title: "Error",
            text: message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
        }

        if (!price || price === "" || price === "~" || price === "0") {
          Swal.fire({
            title: "Error",
            text: `La apuesta ${betToAdd.PlayType} no tiene precio`,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
        }
        if (price === "Bet not found") {
          Swal.fire({
            title: "Error",
            text: "Por el momento no se puede apostar a este juego 1",
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
        }

        if (bets.bets.length > 0) {
          await redApi.get('/verify').then(res => {
            let multPlays = res.data.multPlays;
            if (multPlays) {
              bets.bets.forEach(bet => {
                if (bet.Price === '-110' || bet.Price === -110)
                  bet.Price = multPlays;
              });

              if (price === '-110' || Number(price) === -110) {

                price = multPlays;
              }
            }
          }).catch(err => {
            console.log(err);
          })
        }

        let TeamName;
        if (isMatchUp) {
          TeamName = game.player.name;
        } else {
          TeamName = ['+', '-', 'H+', 'H-', 'H2+', 'H2-', 'H1+', 'H1-'].includes(betToAdd.PlayType) ? `${game.localTeam.name} vs ${game.visitingTeam.name}` : betToAdd.TeamCode == game.localTeam.code || betToAdd.TeamCode == game.localTeam.codeRD ? game.localTeam.name : game.visitingTeam.name;
        }
        const newBet = {
          teamCode: betToAdd.TeamCode,
          teamCodeRD: matchupLeagues.includes(game.league.name) ? game.player.codeRD : betToAdd.TeamCode == game.localTeam.code || betToAdd.TeamCode == game.localTeam.codeRD ? game.localTeam.codeRD : game.visitingTeam.codeRD,
          playType: betToAdd.PlayType === "" ? "M" : betToAdd.PlayType,
          Spread: betToAdd.Spread,
          Price: price,
          TeamName,
          line: line._id,
          league: params.league,
          game: game._id,
          isMatchups: matchupLeagues.includes(game.league.name) ? true : false
        };
        setBets({
          ammount: parseInt(bets.ammount) + parseInt(betToAdd.Quantity),
          bets: [...bets.bets, newBet],
        });

        setBetToAdd({
          TeamCode: "",
          PlayType: "",
          Quantity: 0,
          IfWin: 0,
        });

        //******************************************************************************* */
        let filasRows = document.querySelectorAll('.tr_table_bets');
        // Elimina 'bet-selected' de todas las filas excepto de la última
        filasRows.forEach((fila, index) => {
          if (index !== filasRows.length - 1) {
            fila.classList.remove('bet-selected');
          }
        });
        //******************************************************************************** */

        let tcode_ = document.querySelector("#TeamCode");
        if (tcode_) {
          tcode_.focus();
        }
      } else {
        console.log("no game found");
        Swal.fire({
          title: "No se encontró el equipo",
          text: "revise el código",
          icon: "info",
          timer: 3000,
          showConfirmButton: false,
        });
        setBetToAdd({
          TeamCode: "",
          PlayType: "",
          Quantity: 0,
          IfWin: 0,
        });
      }
    }
  };
  const handleBetAddToTable2 = async (e) => {

    let game = gamesForUser.find(
      (game) =>
        game.localTeam.codeRD == betToAdd.TeamCode ||
        game.visitingTeam.codeRD == betToAdd.TeamCode || game.localTeam.code == betToAdd.TeamCode || game.visitingTeam.code == betToAdd.TeamCode

    );

    if (game) {
      const localLine = game.localLine;
      const visitingLine = game.visitingLine;
      const line =
        betToAdd.TeamCode == game.localTeam.codeRD || betToAdd.TeamCode == game.localTeam.code ? localLine : visitingLine;

      let error;
      let price;
      let spreadChanged = false
      let points = Math.abs(e.target.value);

      switch (betToAdd.PlayType) {
        case "C":

          await redApi.get(`/linesControlPlayType?playType=C&points=${points}&league=${game.league._id}&polarity=${line.isBully ? 'MACHO' : 'HEMBRA'}&lineId=${line._id}`).then(res => {
            price = res.data.price || res.data.price
          }).catch(err => {
            console.log(err);
            error = err.response.data.message
          })

          break;
        case "C+":
          await redApi.get(`/linesControlPlayType?playType=CPlus&points=${points}&league=${game.league._id}&polarity=${line.isBully ? 'MACHO' : 'HEMBRA'}&lineId=${line._id}`).then(res => {
            price = res.data.price || res.data.price
          }).catch(err => {
            console.log(err);
            error = err.response.data.message
          })

          break;
        case "C-":
          await redApi.get(`/linesControlPlayType?playType=CMinus&points=${points}&league=${game.league._id}&polarity=${line.isBully ? 'MACHO' : 'HEMBRA'}&lineId=${line._id}`).then(res => {
            price = res.data.price || res.data.price
          }).catch(err => {
            console.log(err);
            error = err.response.data.message
          })
          break;

        default:
          Swal.fire({
            title: "Error",
            text: 'Este tipo de jugada no está habilitada',
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
      }


      if (error) {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }

      if (!price || price === "~") {
        Swal.fire({
          title: "Error",
          text: `La apuesta ${betToAdd.PlayType} no tiene precio`,
          icon: "error",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }
      if (price === "Bet not found") {
        Swal.fire({
          title: "Error",
          text: "Por el momento no se puede apostar a este juego 1",
          icon: "error",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }

      if (bets.bets.length > 0) {
        await redApi.get('/verify').then(res => {
          let multPlays = res.data.multPlays;
          if (multPlays) {
            bets.bets.forEach(bet => {

              if (bet.Price === '-110' || bet.Price === -110)
                bet.Price = multPlays;
            });
            if (price === '-110' || Number(price) === -110) {
              price = multPlays;
            }

          }
        }).catch(err => {
          console.log(err);
        })
      }

      const TeamName = betToAdd.TeamCode == game.localTeam.codeRD || betToAdd.TeamCode == game.localTeam.code ? game.localTeam.name : game.visitingTeam.name;
      const newBet = {
        teamCode: betToAdd.TeamCode === game.localTeam.code ? game.localTeam.codeRD : betToAdd.TeamCode,
        playType: betToAdd.PlayType === "" ? "M" : betToAdd.PlayType,
        Spread: parseFloat(line.gavela.value) + parseFloat(points),
        Price: price,
        TeamName: TeamName,
        line: line._id,
        game: game._id,
      };

      setBets({
        ammount: parseInt(bets.ammount) + parseInt(betToAdd.Quantity),
        bets: [...bets.bets, newBet],
      });

      setBetToAdd({
        TeamCode: "",
        PlayType: "",
        Quantity: 0,
        IfWin: 0,
      });
      //******************************************************************************* */
      let filasRows = document.querySelectorAll('.tr_table_bets');
      // Elimina 'bet-selected' de todas las filas excepto de la última
      filasRows.forEach((fila, index) => {
        if (index !== filasRows.length - 1) {
          fila.classList.remove('bet-selected');
        }
      });
      //******************************************************************************** */

      // document.getElementById('quantityInput').focus();
      document.querySelector("#TeamCode").focus();
    } else {
      console.log("no game found");
    }

  }

  const [printTicket, setPrintTicket] = useState({
    ticketId: 0,
    print: false,
  });

  window.addEventListener("afterprint", (event) => {
  });


  function ReadPlayType(playType) {
    const dictionary = {
      M: 'EVEN',
      MH: 'EVEN H1',
      MT1: 'EVEN P1',
      MT2: 'EVEN P2',
      MT3: 'EVEN P3',
      R: 'R/L',
      H: 'R/L H1',
      Q1: 'R/L Q1',
      Q2: 'R/L Q2',
      Q3: 'R/L Q3',
      Q4: 'R/L Q4',
      T1: 'R/L P1',
      T2: 'R/L P2',
      T3: 'R/L P3',
      HR2: 'R/L H2',
      S: "Solo",
      "S+": "Solo +",
      '+': 'A Mas',
      'H+': 'A Mas H',
      'Q1+': 'A Mas Q1',
      'Q2+': 'A Mas Q2',
      'Q3+': 'A Mas Q3',
      'Q4+': 'A Mas Q4',
      'T1+': 'A Mas P1',
      'T2+': 'A Mas P2',
      'T3+': 'A Mas P3',
      'H2+': 'A Mas H2',
      '-': 'A Menos',
      'H-': 'A Menos H',
      'Q1-': 'A Menos Q1',
      'Q2-': 'A Menos Q2',
      'Q3-': 'A Menos Q3',
      'Q4-': 'A Menos Q4',
      'T1-': 'A Menos P1',
      'T2-': 'A Menos P2',
      'T3-': 'A Menos P3',
      'H2-': 'A Menos H2',
      'S+': 'Solo +',
      'H1S+': 'Solo +H',
      'Q1S+': 'Solo +Q1',
      'Q2S+': 'Solo +Q2',
      'Q3S+': 'Solo +Q3',
      'Q4S+': 'Solo +Q4',
      'H2S+': 'Solo +H2',
      'S-': 'Solo -',
      'H1S-': 'Solo -H',
      'Q1S-': 'Solo -Q1',
      'Q2S-': 'Solo -Q2',
      'Q3S-': 'Solo -Q3',
      'Q4S-': 'Solo -Q4',
      'H2S-': 'Solo -H2',
      P: 'SR/L',
      Y: 'Si',
      N: 'No',
      'K+': 'K+',
      'K-': 'K-',
      F: '1era',
      C: 'Compra',
      V: 'Venta',
      'C+': 'C A Mas',
      'C-': 'C A Menos',
      J1: 'J1',
      J2: 'J2',
      J3: 'J3',
      J4: 'J4',
      J5: 'J5',
      // G: 'GS ML',
      // 'G+': 'GS A mas',
      // 'G-': 'GSUnder',
      SE: 'Serie',
      AR: 'AR/L',
      T: 'Tercio',
      'T+': 'Tercio Mas',
      'T-': 'Tercio Menos',
      'V+': 'V A Mas',
      'V-': 'V A Menos',
      E: 'Empate',
    }

    return dictionary[playType]
  };

  const handleBetPost = async (e) => {
    if ((e.key === "*" || e.key === "Enter" || e === "Print&Send") && !isRunning) {

      if (e === "Print&Send") {
        if (bets.bets.length === 0) {
          Swal.fire({ icon: "error", title: "No hay apuestas insertadas", timer: 1500, showConfirmButton: false })
          return;
        }
        if (!bets.ammount) {
          Swal.fire({ icon: "error", title: "Monto de apuestas inválido", timer: 1500, showConfirmButton: false })
          return;
        }

      }
      if (bets.bets.length >= 1) {
        const Periods = ['Directo', 'Pale', 'Tripleta', 'Quarteta', 'Quinteta', 'Sexteta', 'Septeta', 'Octeta'];
        bets.bets.forEach(bet => {
          const typeBetAdd = ReadPlayType(bet.playType)
          restrictionJ.map(restriction => {
            if (bet.league === restriction.league) {
              const resctrictionType = restriction.lineType.includes(typeBetAdd)
              const Period = Periods[bets.bets.length - 1]
              if (resctrictionType) {
                if (restriction.period.find(element => element.includes("Todos") || restriction.period.find(element => resctrictionType.includes(element)))) {
                  if (restriction.ticketType.includes(Period)) {
                    Swal.fire({ icon: "error", title: "Hay una Restriccion con tu ticket", timer: 1500, showConfirmButton: false })
                    return;
                  }
                }
              }
            }
          });

        })
      }
      try {
        isRunning = true;
        let ticket = {
          amount: bets.ammount,
          bets: bets.bets.map((bet) => {
            return {
              teamCode: bet.teamCodeRD,
              playType: bet.playType,
              game: bet.game,
              line: bet.line,
              isMatchups: bet.isMatchups
            };
          }),
          totalToWin,
          betsIf: bets.betsIf,
          toWinIf: totalToWinIF,
          amountIf: bets.IfWinQuantity
        };
        Swal.fire({
          title: "Imprimiendo ticket",
          text: "Espere un momento por favor...",
          icon: "info",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        const { data } = await redApi.post("/tickets", ticket);
        if (data.error) {
          Swal.fire({
            title: "Error",
            text: data.error,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
        }
        setBets([]);
        setPrintTicket({
          ticketId: data.ticketId,
          print: true,
        });
        setTotalToWin(0);
        setTicketNumber(ticketNumber + 1);
        setBets({
          ammount: 0,
          bets: [],
        });
        let quantityInput = document.querySelector("#quantityInput");
        let ifWinInput = document.querySelector("#ifWinInput");
        if (ifWinInput) ifWinInput.disabled = false;
        if (quantityInput) {
          quantityInput.value = "";
          quantityInput.disabled = false;
        }
        let IfWinQuantity = document.querySelector('#IfWinQuantity2')
        if (IfWinQuantity) IfWinQuantity.disabled = false
        setBetToAdd({
          TeamCode: "",
          PlayType: "",
          Quantity: 0,
          IfWin: 0,
        });
        setTotalToWinIF(0)
        isRunning = false;
        Swal.fire({
          title: "Ticket impreso",
          text: "El ticket se ha impreso correctamente",
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: "Ok",
          confirmButtonColor: "#3085d6",
        });
        // return data.ticketId;
      } catch (error) {
        console.log({ error }, '{ error }');
        isRunning = true;
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error al realizar tu apuesta",
          html: `<p>Error: ${error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data[0]?.msg ? "Monto inválido" : "Error desconocido, por favor comunicarse con soporte técnico"}</p>`,
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: "Ok",
          confirmButtonColor: "#d33",
        });
        return false
      }
    } else {
      console.log('no se puede apostar')
    }
  };
  const handleBetPostEnter = async (e) => {
    if (e.key === 'Enter' && bets.bets.length > 0 && bets.ammount > 0 && !isRunning) {
      handleBetPost(e);
      return;
    }
  }


  useEffect(() => {
    if (bets?.bets?.length > 0)
      setSelectedBet({
        teamCode: bets.bets[bets.bets.length - 1].teamCode,
        playType: bets.bets[bets.bets.length - 1].playType
      });
    if (!bets.readyToSubmit) return;
    setTimeout(function () {
      document.addEventListener('keydown', handleBetPostEnter);
    }, 10);
    return () => {
      document.removeEventListener('keydown', handleBetPostEnter);
    };
  }, [bets])

  const [totalToWin, setTotalToWin] = useState(0);
  const [totalToWinIF, setTotalToWinIF] = useState(0);
  const [allowPrint, setAllowPrint] = useState({
    print: true,
    mobile: false,
    wsp: false
  })
  const handleChangeCheckBoxs = (e) => {
    setAllowPrint({
      ...allowPrint,
      [e.target.name]: e.target.checked
    })
  }

  const [mobile, setMobile] = useState(window.innerWidth <= 768)
  useEffect(() => {
    window.addEventListener('resize', () => {
      setMobile(window.innerWidth <= 768)
    })
  }, [])






  return (
    <Layout

      bets={bets}
      setBets={setBets}
      betToAdd={betToAdd}
      setBetToAdd={setBetToAdd}
      gamesForUser={gamesForUser}
      setGamesForUser={setGamesForUser}
      isLoading={isLoading}
      setUserTheme={setUserTheme}
      totalToWin={totalToWin}
      setTotalToWin={setTotalToWin}
      totalToWinIF={totalToWinIF}
      setTotalToWinIF={setTotalToWinIF}
      handleBetPost={handleBetPost}
      handleBetAddToTable={handleBetAddToTable}
      mobile={mobile}
      setMobile={setMobile}
      handleChangeCheckBoxs={handleChangeCheckBoxs}
      allowPrint={allowPrint}

    >

      {printTicket.print && mobile && allowPrint.print ? (
        <Printer
          ticketId={printTicket.ticketId}
          printTicket={printTicket}
          setPrintTicket={setPrintTicket}
        />

      ) : printTicket.print && !mobile ? (
        <Printer
          ticketId={printTicket.ticketId}
          printTicket={printTicket}
          setPrintTicket={setPrintTicket}
        />)
        : null}

      <Modal
        open={modalBuyPoints}
        onClose={() => handlemodalBuyPoints()}
        footer={null}
      >

        <div className="w-100 d-flex flex-column justify-content-center align-items-center py-4c gap-2 mb-4">
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            Cuantos puntos desea comprar o vender?
          </span>
          <input type="text" className="form-control text-center" id="inputBuyPoints" name="buyPoints" onChange={handleInputBuyPoints} />
        </div>
        <div className="parent">
          <div className="div1">
            {/* <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'NumLock'} disabled>NumLock</button> */}
          </div>
          <div className="div2">
            {/* <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'/'} disabled>/</button> */}
          </div>
          <div className="div3">
            {/* <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'*'} disabled>*</button> */}
          </div>
          <div className="div4">
            {/* <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'-'} disabled>-</button> */}
          </div>
          <div className="div5">
            <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={7}>7</button>
          </div>
          <div className="div6">
            <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={8}>8</button>
          </div>
          <div className="div7">
            <button className="btn btn-primary w-100 w-100" onClick={handleInputBuyPoints} value={9}>9</button>
          </div>
          <div className="div8">
            {/* <button className="btn btn-primary w-100 w-100" onClick={handleInputBuyPoints} value={'+'} disabled>+</button> */}
          </div>
          <div className="div9">
            <button className="btn btn-primary w-100 w-100" onClick={handleInputBuyPoints} value={4}>4</button>
          </div>
          <div className="div10">
            <button className="btn btn-primary w-100 w-100" onClick={handleInputBuyPoints} value={5}>5</button>
          </div>
          <div className="div11">
            <button className="btn btn-primary w-100 w-100" onClick={handleInputBuyPoints} value={6}>6</button>
          </div>
          <div className="div12">
            <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={1}>1</button>
          </div>
          <div className="div13">
            <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={2}>2</button>
          </div>
          <div className="div14">
            <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={3}>3</button>
          </div>
          <div className="div15">
            <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'Enter'}>Enter</button>
          </div>
          <div className="div16">
            <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={0}>0</button>
          </div>
          <div className="div17">
            <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'.'}>.</button>
          </div>
        </div>
      </Modal>
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center h-100 bg-card px-2">
        <div className="row w-100 d-flex flex-row justify-content-center align-items-center">
          <div className="col d-flex flex-row justify-content-center align-items-center gap-1">
            <input type="radio" name="Normal" />
            <label htmlFor="Normal">Normal</label>
          </div>
          <div className="col-6 d-flex flex-row justify-content-center align-items-center border-top border-bottom border-1 border-dark py-1">
            <span className="font-weight-bold">SportsCom</span>
          </div>
          <div className="col d-flex flex-row justify-content-center align-items-center gap-1">
            <input type="radio" name="Teaser" />
            <label htmlFor="Teaser">Teaser</label>
          </div>
        </div>
        <div className="row w-100 d-flex flex-row justify-content-center align-items-center bg-white mt-2 p-2 ">
          <span className="col font-weight-bold d-flex flex-row justify-content-center align-items-center text-dark">
            Ticket #{ticketNumber ? ticketNumber + 1 : 1}
          </span>
        </div>
        <div className="" id="table_bets_render_container">
          <table className="table table-striped table-hover table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  Code
                </th>
                <th scope="col" className="text-center">
                  Team
                </th>
                <th scope="col" className="text-center">
                  Type
                </th>
                <th scope="col" className="text-center">
                  Spread
                </th>
                <th scope="col" className="text-center">
                  Price
                </th>
                {
                  mobile && (
                    <th scope="col" className="text-center">
                      Del
                    </th>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {bets.bets?.map((bet, index) => {
                return bets.betsIf && bets.betsIf >= index + 1 ? (
                  <tr
                    style={{
                      cursor: "pointer",
                      // background: 'rgb(255, 51, 51)',
                      background: 'linear-gradient(0deg, rgba(9,9,121,1) 0%, rgba(0,180,217,1) 44%)',
                    }}
                    onClick={() => {
                      handleclickTr(bet.playType, bet.teamCode)
                    }}
                    key={index}
                    className={
                      "tr_table_bets " +
                      (selectedBet.teamCode === bet.teamCode && selectedBet.playType === bet.playType ? "bet-selected" : "")
                    }
                  >
                    <td className="text-center">{bet.teamCode}</td>
                    <td className="text-center">{bet.TeamName}</td>
                    <td className="text-center">{bet.playType}</td>
                    <td className="text-center">{bet.Spread}</td>
                    <td className="text-center">{bet.Price}</td>
                    {
                      mobile && (
                        <td className="text-center">
                          <button className="btn btn-danger btn-sm" onClick={() => handleclickTr(bet.playType, bet.teamCode, mobile)}>
                            x
                          </button>
                        </td>
                      )
                    }
                  </tr>
                ) : (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleclickTr(bet.playType, bet.teamCode)
                    }}
                    key={index}
                    className={
                      "tr_table_bets " +
                      (selectedBet.teamCode === bet.teamCode && selectedBet.playType === bet.playType ? "bet-selected" : "")
                    }
                  >
                    <td className="text-center">{bet.teamCode}</td>
                    <td className="text-center">{bet.TeamName}</td>
                    <td className="text-center">{bet.playType}</td>
                    <td className="text-center">{bet.Spread}</td>
                    <td className="text-center">{bet.Price}</td>
                    {
                      mobile && (
                        <td className="text-center">
                          <button className="btn btn-danger btn-sm" onClick={() => handleclickTr(bet.playType, bet.teamCode, mobile)}>
                            x
                          </button>
                        </td>
                      )
                    }
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}