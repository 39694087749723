import React, { useCallback, useState, useEffect } from "react";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import { redApi } from "../../../../../config/Axios";


const Theme4_teams_mobile = ({
  gamesForUser,
  betToAdd,
  setBetToAdd,
  isLoading
}) => {
  const [teams, setTeams] = useState([]);
  useEffect(() => {
    let teams = [];
    gamesForUser?.map((game) => {
      if (!teams.some((team) => team._id === game.localTeam._id)) {
        teams.push(game.localTeam);
      }
      if (!teams.some((team) => team._id === game.visitingTeam._id)) {
        teams.push(game.visitingTeam);
      }
    });
    setTeams(teams);
  }, [gamesForUser]);

  return (
    <>
      {
        teams.length > 0 && !isLoading ? (
          teams?.map((team, index) => {
            return (
              <div className="theme4_leagues_mobile" key={index}>
                <button
                  onClick={() => {
                    setBetToAdd({
                      ...betToAdd,
                      TeamCode: team.code === 0 || team.code === '0' ? team.codeRD : team.code,
                    })
                    document.querySelector('#betTypeSelect').focus()
                  }}
                >
                  <img
                    src={team.image}
                    alt={team.name}
                    width={50}
                    height={50}
                  />
                </button>
                <div>
                  {team.name.length > 15
                    ? team.name.substring(0, 15) + "..."
                    : team.name}
                </div>
              </div>

            );
          })
        ) : (
          isLoading ? (
            <div
              className="spinner-border text-primary"
              role="status"
            ></div>
          ) : (
            <div>Seleccione una liga</div>
          )
        )}
    </>
  );
};

export default Theme4_teams_mobile;
