import React, { useEffect, useState } from "react";
import CrearGrupoPrivilegios from "./CrearGrupo.Privilegios";
import CrearGrupogeneral from "./CrearGrupo.general";
import Spinner from "react-bootstrap/Spinner";
import { Modal, Button } from "antd";
import { redApi } from "../../../../../config/Axios";
import Swal from "sweetalert2";


const ModalGrupoCrear = ({ getAllGroups, groupSelect, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [privilegesPos, setPrivilegesPos] = useState([
    {
      name: "Anular tickets",
      checked: false,
      value: 1,
      permissions: [1, 2],
    },
    {
      name: "Anular tickets sin limite de tiempo",
      checked: false,
      value: 2,
      permissions: [1],
    },
    {
      name: "Reportes",
      checked: false,
      value: 3,
      permissions: [1, 2],
    },
    {
      name: "Corredor",
      checked: false,
      value: 4,
      permissions: [1],
    },
    {
      name: "Reporte Corredor",
      checked: false,
      value: 5,
      permissions: [1],
    },
    {
      name: "Pagar ticket",
      checked: false,
      value: 6,
      permissions: [1, 2],
    },
  ]);
  // administracion:
  const [administracionData, setAdministracionData] = useState([
    {
      name: "General",
      expanded: false,
      children: [
        {
          name: "Acceso a lineas",
          checked: false,
          value: 7,
          permissions: [1],
        },
        {
          name: "Acceso administrativo",
          checked: false,
          value: 8,
          permissions: [1],
        },
        {
          name: "Jugar",
          checked: false,
          value: 9,
          expanded: false,
          permissions: [1, 2],
          children: [
            {
              name: "Jugar sin restricciones",
              checked: false,
              value: 10,
              permissions: [1],
            },
          ],
        },
        {
          name: "Ingresar transacciones",
          checked: false,
          value: 11,
          permissions: [1, 2],
        },
        {
          name: "Ver transacciones",
          checked: false,
          value: 12,
          permissions: [1, 2],
        },
        {
          name: "Configurar consorcio",
          checked: false,
          value: 13,
          permissions: [1, 2],
        },
        {
          name: "Configurar restricciones",
          checked: false,
          value: 14,
          permissions: [1, 2],
        },
        {
          name: "Ver CxC",
          checked: false,
          value: 15,
          permissions: [1, 2],
        },
        {
          name: "Anular si restricciones",
          checked: false,
          value: 16,
          permissions: [1],
        },
        {
          name: "Reportes administrativos",
          checked: false,
          value: 17,
          permissions: [1, 2],
        },
      ],
    },
    {
      name: "Terminales",
      expanded: false,
      children: [
        {
          name: "Ver",
          checked: false,
          value: 18,
          permissions: [1, 2],
        },
        {
          name: "Crear",
          checked: false,
          value: 19,
          permissions: [1, 2],
        },
        {
          name: "Editar",
          checked: false,
          value: 20,
          permissions: [1, 2],
        },
        {
          name: "Eliminar",
          checked: false,
          value: 21,
          permissions: [1, 2],
        },
      ],
    },
    {
      name: "Usuarios",
      expanded: false,
      children: [
        {
          name: "Ver",
          checked: false,
          value: 22,
          permissions: [1, 2],
        },
        {
          name: "Crear",
          checked: false,
          value: 23,
          permissions: [1, 2],
        },
        {
          name: "Editar",
          checked: false,
          value: 24,
          permissions: [1, 2],
        },
        {
          name: "Eliminar",
          checked: false,
          value: 25,
          permissions: [1, 2],
        },
      ],
    },
    {
      name: "Grupos",
      expanded: false,
      children: [
        {
          name: "Ver",
          checked: false,
          value: 26,
          permissions: [1, 2],
        },
        {
          name: "Crear",
          checked: false,
          value: 27,
          permissions: [1, 2],
        },
        {
          name: "Editar",
          checked: false,
          value: 28,
          permissions: [1, 2],
        },
        {
          name: "Eliminar",
          checked: false,
          value: 29,
          permissions: [1, 2],
        },
      ],
    },
    {
      name: "Consorcio",
      expanded: false,
      children: [
        {
          name: "Ver",
          checked: false,
          value: 30,
          permissions: [1, 2],
        },
        {
          name: "Crear",
          checked: false,
          value: 31,
          permissions: [1],
        },
        {
          name: "Editar",
          checked: false,
          value: 32,
          permissions: [1, 2],
        },
        {
          name: "Eliminar",
          checked: false,
          value: 33,
          permissions: [1],
        },
      ],
    },
  ]);
console.log("props", props)
  const [grupo, setGrupo] = useState({
    name: groupSelect ? groupSelect.name : "",
    description: groupSelect ? groupSelect.description : "",
    privilegesAdmin: administracionData,
    privilegesPos: privilegesPos,
  });

  const filterAdministracionData = (data, userLevel) => {
    let newAdministracion = [];

    // Filtering the default array administracionData to get and display the correct privilege for the current user

    data.forEach((item) => {
      const childrenFiltered = item.children.filter((child) => {
        return child.permissions.includes(userLevel);
      });

      if (childrenFiltered.length > 0) {
        newAdministracion.push({ ...item, children: childrenFiltered });
      }
    });

    // The following code is for special handling of the "Jugar" privilege since its children may not be available to some users, but its parent may be.

    const generalCategoryIndex = newAdministracion.findIndex(
      (dataFormatted) => dataFormatted.name === "General"
    );

    if (generalCategoryIndex !== -1) {
      const jugarSubcategoryIndex = newAdministracion[
        generalCategoryIndex
      ].children.findIndex((child) => child.name === "Jugar");

      if (jugarSubcategoryIndex !== -1) {
        const hasPrivilege = newAdministracion[generalCategoryIndex].children[
          jugarSubcategoryIndex
        ].children.some((item) => item.permissions.includes(userLevel));

        if (!hasPrivilege) {
          delete newAdministracion[generalCategoryIndex].children[
            jugarSubcategoryIndex
          ].children;
        }
      }
    }

    return newAdministracion;
  };

  // To get and show the checked POS privileges of the selected group
  const filterGroupSeletedPosPrivileges = (
    consorcioPrivileges,
    groupPrivileges
  ) => {
    let result = [];

    for (const privilege of consorcioPrivileges) {
      const groupPosPrivilege = groupPrivileges.find(
        (posPri) => posPri.name === privilege.name
      );

      if (groupPosPrivilege) {
        result.push(groupPosPrivilege);
      }
    }

    return result;
  };

  // To get and show the checked ADMINISTRACION privileges of the selected group
  const filterGroupSeletedAdministracionPrivileges = (
    consorcioPrivileges,
    groupPrivileges
  ) => {
    let result = [];
    let n = 0;

    for (const privilege of consorcioPrivileges) {
      const childrenPrivileges = privilege.children;
      let privs = [];

      groupPrivileges[n].children.forEach((children) => {
        childrenPrivileges.forEach((element) => {
          if (children.name === element.name) {
            delete children.children;
            privs.push(children);
          }
        });
      });

      result.push({ ...privilege, children: privs });

      n++;
    }

    return result;
  };

  useEffect(async () => {
    const getUserLevel = async () => {
      try {
        const { data } = await redApi.get(`/verify`);
        console.log("data", data)
        const userLevel = data.userLevel;

        return userLevel;
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const userLevel = await getUserLevel();
    console.log("mostramee")
    console.log({ userLevel });

    if (userLevel && userLevel !== 1) {
      // Filtering to get specific privileges
      const newPosPrivileges = privilegesPos.filter((privilege) =>
        privilege.permissions.includes(userLevel)
      );

      const newAdministracionPrivileges = filterAdministracionData(
        administracionData,
        userLevel
      );

      // If a user wants to edit a group, he must obtain the previous privileges checked
      if (groupSelect) {
        console.log("Editing group");
        const groupSelectedPosPrivileges = filterGroupSeletedPosPrivileges(
          newPosPrivileges,
          groupSelect.privilegesPos
        );
        const groupSelectedAdminisPrivileges =
          filterGroupSeletedAdministracionPrivileges(
            newAdministracionPrivileges,
            groupSelect.privilegesAdmin
          );

        setPrivilegesPos(groupSelectedPosPrivileges);
        setAdministracionData(groupSelectedAdminisPrivileges);

        // Create new group
      } else {
        console.log("New group");
        setPrivilegesPos(newPosPrivileges);
        setAdministracionData(newAdministracionPrivileges);
      }
    } else {
      console.log("ADMIN");
      if (groupSelect) {
          const privAdmin = groupSelect.privilegesAdmin.map((priv) => {
            return {
              ...priv,
              checked: priv.children
                ? priv.children.some((child) => child.checked)
                : priv.checked,
            };
          });
          const privPos = groupSelect.privilegesPos.map((priv) => {
            return {
              ...priv,
              checked: priv.checked,
            };
          });

          if (groupSelect.createdBy !== 1) {
            delete privAdmin[0].children[0].children;
          }

          setPrivilegesPos(privPos);
          setAdministracionData(privAdmin);
      }
    }
  }, [groupSelect]);

  const handleOk = () => {
    if (props.modalCrearGrupo) {
      Swal.fire({
        title: `${groupSelect ? "Editando" : "Creando"} grupo`,
        html: "Espere por favor",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        showConfirmButton: false,
        showCancelButton: false,
        showCloseButton: false,
      });
      if (groupSelect) {
        redApi
          .put(`/users/groups/update`, {
            groupId: groupSelect._id,
            update: {
              privilegesAdmin: administracionData,
              privilegesPos: privilegesPos,
              name: grupo.name,
              description: grupo.description,
            },
          })
          .then((res) => {
            getAllGroups();
            Swal.fire({
              title: "Grupo editado",
              icon: "success",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              title: "Error al editar grupo",
              icon: "error",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          });
        props.setModalCrearGrupo(false);
      } else {
        redApi
          .post("/users/groups/create", grupo)
          .then((res) => {
            getAllGroups();
            Swal.fire({
              title: "Grupo creado",
              icon: "success",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              title: "Error al crear grupo",
              icon: "error",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          });
        props.setModalCrearGrupo(false);
      }
    }
  };

  const handleCancel = () => {
    if (props.modalEditar) {
      props.setModalEditar(false);
    } else if (props.modalCrearGrupo) {
      props.setModalCrearGrupo(false);
    }
  };

  const renderMenu = () => {
    let menu = ["General", "Privilegios"]; //
    return menu.map((item, index) => {
      return (
        <li key={index} className="nav-item">
          <button
            value={item}
            onClick={handleTable}
            className={`nav-buttons ${table === item ? "active" : ""}`}
          >
            {item}
          </button>
        </li>
      );
    });
  };

  const [table, setTable] = useState("General");

  const handleTable = (e) => {
    setTable(e.target.value);
  };

  const renderTableCreate = () => {
    switch (table) {
      case "General":
        return (
          <CrearGrupogeneral
            handleOk={handleOk}
            setGrupo={setGrupo}
            grupo={grupo}
          />
        );
      case "Privilegios":
        return (
          <CrearGrupoPrivilegios
            administracionData={administracionData}
            setAdministracionData={setAdministracionData}
            privilegesPos={privilegesPos}
            setPrivilegesPos={setPrivilegesPos}
          />
        );
      default:
        return <CrearGrupogeneral />;
    }
  };

  const renderTableEdit = (grupo) => {
    console.log("grupo",grupo);
    switch (table) {
      case "General":
        return <CrearGrupogeneral grupo={grupo} />;
      case "Privilegios":
        return (
          <CrearGrupoPrivilegios
            grupo={grupo}
            privilegesPos={privilegesPos}
            setPrivilegesPos={setPrivilegesPos}
            administracionData={administracionData}
            setAdministracionData={setAdministracionData}
          />
        );
      default:
        return <CrearGrupogeneral grupo={grupo} />;
    }
  };

  return (
    <Modal
      title={groupSelect ? "Editar grupo" : "Crear grupo"}
      open={props.modalEditar || props.modalCrearGrupo}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1280}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          {groupSelect ? "Editar" : "Crear"}
        </Button>,
      ]}
    >
      {loading ? (
        <div className="d-flex justify-content-center p-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <ul className="nav w-100 d-flex justify-content-start align-items-center">
              {renderMenu()}
            </ul>
          </div>
          <div className="col-12">
            {props.modalEditar
              ? renderTableEdit(data?.usuario)
              : renderTableCreate()}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalGrupoCrear;
