import moment from 'moment'
import React, { useState, useEffect, useContext, useRef } from 'react'
import Swal from 'sweetalert2'
import { redApi } from '../../../../config/Axios'
import { ReportsContext } from './context/ReportesContext'
import { AuthContext } from '../../../../auth'

export default function CxCv2() {
  const { userName, userLevel, userId } = useContext(AuthContext);

  const {
    terminals: pos,
    consortiums: consorcio,
    accounts,
    group
  } = useContext(ReportsContext)
  
  

  const [showcheck, setShowcheck] = useState(true)
  const [expandRow, setExpandRow] = useState({});
  const [firstRender, setFirtsRender] = useState(true)

  useEffect(()=> {
    setFirtsRender(false)
  }, []);

  const handleExpand = (row) => {
    if (Object.keys(expandRow).length > 0 && expandRow._id === row._id) {
      setExpandRow({})
    } else {
      setExpandRow(row)
    }
  }

  const Estado = "processed";
  const consorcioTotal = "Todos"
  const posTotal = "Todos"
  const rojoAnt = 0;
  const BalAnt = 0;
  const Result = 0;
  const Sueldo = 0;
  const Devolucion = 0;
  const PorcentajePos = 0;
  const PorcentajeBanca = 0;
  const Rojo = 0;
  const Bal = 0;

  const [data, setData] = useState([])
  const [totalData, setTotalData] = useState({
    Estado,
    consorcioTotal,
    posTotal,
    rojoAnt,
    BalAnt,
    Result,
    Sueldo,
    Devolucion,
    PorcentajePos,
    PorcentajeBanca,
    Rojo,
    Bal
  })
  // filters
  const [filters, setFilters] = useState({
    initDate: '',
    finalDate: '',
    consorcio: '',
    pos: '',
    group: ''
  });

  const getAlldata = async (e) => {
    if(accounts.length > 1 && firstRender){
      setData(accounts)
      calcularTotales(accounts)
    }else{

      redApi.get(`/accountings${filters.initDate && filters.finalDate ? `?initDate=${filters.initDate}&finalDate=${filters.finalDate}&userName=${userName}` : `?userName=${userName}`}`)
      .then(res => {
        setData(res.data)
        calcularTotales(res.data)
      })
      .catch(err => {
        console.log(err)
      })

    }

  }

  let balancesBlock = false;
  
    data.map((row)=>{
      if (!row.hasOwnProperty('prevBalance') || !row.hasOwnProperty('balance')) {
        balancesBlock = true;
      }
    })   
  

  useEffect(() => {
    let initWeek,
      finalWeek,
      initD = filters.finalDate ? moment(filters.finalDate).startOf('day').toDate() : moment().startOf('day').toDate()
    if (new Date(initD).getDay() !== 1) {
      if (new Date(initD).getDay() === 0) {
        initWeek = moment(initD).subtract(6, 'days').startOf('day').toDate()
      } else {
        initWeek = moment(initD).subtract(new Date(initD).getDay() - 1, 'days').startOf('day').toDate()
      }
    } else {
      initWeek = moment(initD).startOf('day').toDate()
    }

    finalWeek = moment(initWeek).add(6, 'days').endOf("day").toDate()

    setFilters({
      ...filters,
      initDate: moment(initWeek).format('YYYY-MM-DD'),
      finalDate: moment(finalWeek).format('YYYY-MM-DD')
    })
  }, [])

  const handeChangeDate = (e) => {
    let initWeek,
      finalWeek,
      initD = e.target.value ? moment(e.target.value).startOf('day').toDate() : moment().startOf('day').toDate()
    if (new Date(initD).getDay() !== 1) {
      if (new Date(initD).getDay() === 0) {
        initWeek = moment(initD).subtract(6, 'days').startOf('day').toDate()
      } else {
        initWeek = moment(initD).subtract(new Date(initD).getDay() - 1, 'days').startOf('day').toDate()
      }
    } else {
      initWeek = moment(initD).startOf('day').toDate()
    }

    finalWeek = moment(initWeek).add(6, 'days').endOf("day").toDate()

    setFilters({
      ...filters,
      initDate: moment(initWeek).format('YYYY-MM-DD'),
      finalDate: moment(finalWeek).format('YYYY-MM-DD')
    })
  }

  const handleSelect = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
    console.log(filters)
  }
  const handleCheck = (e) => {
    if (e.target.checked) {
      setShowcheck(true)
      setFilters({
        ...filters,
        initDate: moment(filters.finalDate).subtract(7, 'day').format('YYYY-MM-DD'),
        finalDate: moment().format('YYYY-MM-DD')
      })
    }
  }
  useEffect(() => {
    if (filters.pos === 'all') {
      setFilters({
        ...filters,
        pos: ''
      })
    }
    if (filters.consorcio === 'all') {
      setFilters({
        ...filters,
        consorcio: ''
      })
    }
    if (filters.group === 'all') {
      setFilters({
        ...filters,
        group: ''
      })
    }
    redApi.get(`/accountings${filters.initDate && filters.finalDate ? `?initDate=${filters.initDate}&finalDate=${filters.finalDate}&userName=${userName}` : `${filters.initDate ? `?initDate=${filters.initDate}&userName=${userName}` : ''}`}${filters.pos ? `&pos=${filters.pos}` : ''}${filters.consorcio ? `&consortium=${filters.consorcio}` : ''}${filters.group ? `&group=${filters.group}` : `?userName=${userName}`}`)
      .then(res => {
        setData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [filters])

  const handleRefresh = () => {
    getAlldata()
    Swal.fire({
      icon: 'success',
      title: 'Refrescado!',
      text: 'Los datos se han actualizado!',
    })
  }

  useEffect(() => {
    getAlldata()
  }, [])


  const calcularTotales = (data) => {
   
    const totales = data.reduce((acc, item) => {
      acc.rojoAnt += item.prevRed || 0;
      acc.BalAnt += item.prevBalance || 0;
      acc.Result += item.result || 0;
      acc.Sueldo += item.income || 0;
      acc.Devolucion += item.refund || 0;
      acc.PorcentajePos += item.percentagePOS || 0;
      acc.PorcentajeBanca += item.percentageBank || 0;
      acc.Rojo += item.red || 0;
      acc.Bal += item.balance || 0;
      return acc;
    }, {
      rojoAnt: 0,
      BalAnt: 0,
      Result: 0,
      Sueldo: 0,
      Devolucion: 0,
      PorcentajePos: 0,
      PorcentajeBanca: 0,
      Rojo: 0,
      Bal: 0
    });

    setTotalData(prevTotalData => ({
      ...prevTotalData,
      ...totales
    }));

  }

  


  const [updateData, setUpdateData] = useState({
    userId: '',
    Pays: {
    }
  })

  const handlechange = (e, id) => {
    setUpdateData({
      ...updateData,
      userId: id,
      Pays: {
        ...updateData.Pays,
        [e.target.id]: {
          ...updateData.Pays[e.target.id],
          [e.target.name]: e.target.value
        }
      }
    })
  }

  const handleUpdate = async () => {
    await redApi.put(`/accountings`, updateData)
      .then(res => {
        redApi.get('/accountings')
          .then(res => {
            setData(res.data)
            setExpandRow({
              ...expandRow,
              status: 'pending'
            })
          })
      })
      .catch(err => {
        console.log(err)
      })
  }


  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleUpdate()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEnter)
    return () => {
      document.removeEventListener('keydown', handleEnter)
    }
  }, [handleEnter])

  const handeprocess = async () => {
    if (expandRow._id && expandRow?.status !== 'processed') {
      await redApi.put(`/accountings/process`, {
        balanceId: expandRow._id
      })
        .then(res => {
          redApi.get('/accountings')
            .then(res => {
              setData(res.data)
              Swal.fire({
                icon: 'success',
                title: 'Procesado!',
                text: 'El balance ha sido procesado!',
              })
            })
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${expandRow?.status === 'processed' ? 'El balance ya ha sido procesado' : 'No hay balance seleccionado'}`
      })
    }
  }

  function formatNumber(num) {
    // Verificar si el número tiene decimales
    if (num % 1 !== 0) {
        // Si tiene decimales, redondear a dos decimales
        return parseFloat(num).toFixed(2);
    } else {
        // Si es un número entero, devolverlo como está
        return num.toString();
    }
}
  
  return (
    <div className="container-fluid p-0  m-0" id='cxcv2'>
      <div className="row m-0 p-0">
        <div className="col-12  m-0 p-0 bg-gray-800 py-4">
          <div className="row m-0 p-0 gap-3">
            <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
              <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                    <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                      <label className="m-0 p-0">Consorcio</label>
                    </div>
                    <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                      <select className="form-select" name='consorcio' onChange={handleSelect}>
                        <option value="all">Todos</option>
                        {consorcio.map((cons, index) => (
                          <option key={index} value={cons._id}>{cons.username}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                    <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                      <label className="m-0 p-0">POS</label>
                    </div>
                    <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                      <select className="reportes-select" name='pos' onChange={handleSelect}>
                        <option value="all">Todos</option>
                        {pos.map((pos, index) => (
                          <option key={index} value={pos._id}>{pos.username}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                        <label className="m-0 p-0">Grupos</label>
                      </div>
                      <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                        <select
                          className="form-control"
                          name="group"
                          onChange={handleSelect}
                          
                        >
                          <option value="all">Todos</option>
                          {group.map((equ, index) => (
                            <option key={index} value={equ._id}>
                              {equ.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>


            </div>
            <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
              <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                  <div className="row m-0 p-0 d-flex justify-content-center align-items-start">
                    <div className="col-12 m-0 p-0 d-flex justify-content-start align-items-center">
                      <input type="checkbox" onChange={handleCheck} checked />
                      <label className="m-0 p-0">Rango</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                  <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                    <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                      <input type="button" value="Refrescar" onClick={handleRefresh} className="btn btn-primary" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
              <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                  <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                    <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                      {
                        showcheck ? <label className="m-0 p-0 text-start text-nowrap">Fecha inicial</label> : <label className="m-0 p-0 text-start text-nowrap">Fecha</label>
                      }
                    </div>
                    <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                      <input type="date" className="form-control" value={filters.initDate} name='initDate' onChange={handeChangeDate} disabled />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                  <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                    <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                      <input type="button" value="Recalcular" />
                    </div>
                    <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                      <input type="button" value="Imprimir" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
              <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                  <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                    <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                      <label className={`m-0 p-0 text-start text-nowrap ${!showcheck ? 'invisible' : ''}`}>Fecha final</label>
                    </div>
                    <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                      <input type="date" className={`form-control ${!showcheck ? 'invisible' : ''}`} value={filters.finalDate} name='finalDate' onChange={handeChangeDate} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                  <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                    <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                    </div>
                    <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                      <input type="button" value="Procesar" onClick={handeprocess} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 m-0 p-0 py-4"
          style={{
            overflow: 'auto',
          }}
        >
          <div className="table-responsive" style={{maxHeight: "65vh"}}>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                    <th scope="col" className='text-center'>Status</th>
                    <th scope="col" className='text-center'>Consorcio</th>
                    <th scope="col" className='text-center'>POS</th>
                    <th scope="col" className='text-center'>Rojo Ant</th>
                    {
                      !balancesBlock ? <th scope="col" className='text-center'>Bal Ant</th>
                      :
                      null
                    }
                    <th scope="col" className='text-center'>resultado</th>
                    <th scope="col" className='text-center'>Sueldo</th>
                    <th scope="col" className='text-center'>Devolucion</th>
                    <th scope="col" className='text-center'>% POS</th>
                    <th scope="col" className='text-center'>% Banca</th>
                    <th scope="col" className='text-center'>Rojo</th>
                    {
                      !balancesBlock ?  <th scope="col" className='text-center'>Bal.</th>
                      :
                      null
                    }
                    
                  </tr>
              </thead>
              <tbody>
                {
                  data?.map((item, index) => {
                
                    return (
                      <React.Fragment key={index}>
                        <tr onClick={() => handleExpand(item)}>
                          <td className={`${item.status === 'processed' ? 'bg-success' : item.status === 'pending' ? 'bg-warning' : 'bg-danger'} d-flex justify-content-start gap-2 align-items-center fw-bold text-white`}>
                            {
                              expandRow._id === item._id ? <svg xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-arrow-down-right-circle"
                                width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx="12" cy="12" r="9" />
                                <line x1="15" y1="15" x2="9" y2="15" />
                                <polyline points="15 9 15 15 9 9" />
                              </svg> : <svg xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-arrow-down-right-circle"
                                style={{ transform: 'rotate(-45deg)' }}
                                width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx="12" cy="12" r="9" />
                                <line x1="15" y1="15" x2="9" y2="15" />
                                <polyline points="15 9 15 15 9 9" />
                              </svg>
                            }
                            {item.status}</td>
                          <td className='text-center'>{item.consorcio.username}</td>
                          <td className='text-center'>{item.user.username}</td>
                          <td className={`text-center ${item.prevRed > 0 ? 'text-success' : 'text-danger'}`}>{item.prevRed}</td>
                          {
                            !balancesBlock ? <td className={`text-center ${item.prevRed > 0 ? 'text-success' : 'text-danger'}`}>{formatNumber(item.prevBalance)}</td> : null
                          }
                          
                          <td className={`text-center ${item.result > 0 ? 'text-success' : 'text-danger'}`}>{item.result}</td>
                          <td className={`text-center ${item.income > 0 ? 'text-success' : 'text-danger'}`}>{item.income}</td>
                          <td className={`text-center ${item.refund > 0 ? 'text-success' : 'text-danger'}`}>{item.refund}</td>
                          <td className={`text-center ${item.percentagePOS > 0 ? 'text-success' : 'text-danger'}`}>{item.percentagePOS}</td>
                          <td className={`text-center ${item.percentageBank > 0 ? 'text-success' : 'text-danger'}`}>{item.percentageBank}</td>
                          <td className={`text-center ${item.red > 0 ? 'text-success' : 'text-danger'}`}>{item.red}</td>
                          {
                             !balancesBlock ? <td className={`text-center ${item.balance > 0 ? 'text-success' : 'text-danger'}`}>{formatNumber(item.balance)}</td> : null
                          }
                          
                        </tr>
                        {
                          expandRow._id === item._id && (
                            <tr>
                              <td colSpan={12}>
                                <div className="w-100">
                                  <table className="table table-sm table-bordered table-cxc">
                                    <thead>
                                      <tr>
                                        <th colSpan={1} className='text-center'>
                                          Lun
                                          <br />
                                          {
                                            moment(item.days.monday.date).format('DD MMM')
                                          }
                                        </th>
                                        <th colSpan={1} className='text-center'>
                                          Mar
                                          <br />
                                          {
                                            moment(item.days.tuesday.date).format('DD MMM')
                                          }
                                        </th>
                                        <th colSpan={1} className='text-center'>
                                          Mie
                                          <br />
                                          {
                                            moment(item.days.wednesday.date).format('DD MMM')
                                          }
                                        </th>
                                        <th colSpan={1} className='text-center'>
                                          Jue
                                          <br />
                                          {
                                            moment(item.days.thursday.date).format('DD MMM')
                                          }
                                        </th>
                                        <th colSpan={1} className='text-center'>
                                          Vie
                                          <br />
                                          {
                                            moment(item.days.friday.date).format('DD MMM')
                                          }
                                        </th>
                                        <th colSpan={1} className='text-center'>
                                          Sab
                                          <br />
                                          {
                                            moment(item.days.saturday.date).format('DD MMM')
                                          }
                                        </th>
                                        <th colSpan={1} className='text-center'>
                                          Dom
                                          <br />
                                          {
                                            moment(item.days.sunday.date).format('DD MMM')
                                          }
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody id='table_days'>
                                      <tr>
                                        <td colSpan={1}>
                                          <div className="d-flex justify-content-center">
                                            <table className="table-sm table-bordered">
                                              <thead>
                                                <tr>
                                                  <th className='text-center'>Neto</th>
                                                  <th className='text-center'>Pagos</th>
                                                  <th className='text-center'>Cobros</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}   type="number" name="neto" defaultValue={item.days.monday.neto} className='input_days table-cxc-input' readOnly />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}  type="number" name="ingreso" defaultValue={item.days.monday.ingreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='monday' />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}  type="number" name="egreso" defaultValue={item.days.monday.egreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='monday' />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                        <td colSpan={1}>
                                          <div className="d-flex justify-content-center">
                                            <table className="table-sm table-bordered">
                                              <thead>
                                                <tr>
                                                  <th className='text-center'>Neto</th>
                                                  <th className='text-center'>Pagos</th>
                                                  <th className='text-center'>Cobros</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}  type="number" name="neto" defaultValue={item.days.tuesday.neto} className='input_days table-cxc-input' readOnly />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}  type="number" name="ingreso" defaultValue={item.days.tuesday.ingreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='tuesday' />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}  type="number" name="egreso" defaultValue={item.days.tuesday.egreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='tuesday' />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                        <td colSpan={1}>
                                          <div className="d-flex justify-content-center">
                                            <table className="table-sm table-bordered">
                                              <thead>
                                                <tr>
                                                  <th className='text-center'>Neto</th>
                                                  <th className='text-center'>Pagos</th>
                                                  <th className='text-center'>Cobros</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}  type="number" name="neto" defaultValue={item.days.wednesday.neto} className='input_days table-cxc-input' readOnly />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}  type="number" name="ingreso" defaultValue={item.days.wednesday.ingreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='wednesday' />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}  type="number" name="egreso" defaultValue={item.days.wednesday.egreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='wednesday' />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                        <td colSpan={1}>
                                          <div className="d-flex justify-content-center">
                                            <table className="table-sm table-bordered">
                                              <thead>
                                                <tr>
                                                  <th className='text-center'>Neto</th>
                                                  <th className='text-center'>Pagos</th>
                                                  <th className='text-center'>Cobros</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}  type="number" name="neto" defaultValue={item.days.thursday.neto} className='input_days table-cxc-input' readOnly />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}  type="number" name="ingreso" defaultValue={item.days.thursday.ingreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='thursday' />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }}  type="number" name="egreso" defaultValue={item.days.thursday.egreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='thursday' />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                        <td colSpan={1}>
                                          <div className="d-flex justify-content-center">
                                            <table className="table-sm table-bordered">
                                              <thead>
                                                <tr>
                                                  <th className='text-center'>Neto</th>
                                                  <th className='text-center'>Pagos</th>
                                                  <th className='text-center'>Cobros</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }} type="number" name="neto" defaultValue={item.days.friday.neto} className='input_days table-cxc-input' readOnly />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }} type="number" name="ingreso" defaultValue={item.days.friday.ingreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='friday' />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }} type="number" name="egreso" defaultValue={item.days.friday.egreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='friday' />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                        <td colSpan={1}>
                                          <div className="d-flex justify-content-center">
                                            <table className="table-sm table-bordered">
                                              <thead>
                                                <tr>
                                                  <th className='text-center'>Neto</th>
                                                  <th className='text-center'>Pagos</th>
                                                  <th className='text-center'>Cobros</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }} type="number" name="neto" defaultValue={item.days.saturday.neto} className='input_days table-cxc-input' readOnly />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }} type="number" name="ingreso" defaultValue={item.days.saturday.ingreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='saturday' />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }} type="number" name="egreso" defaultValue={item.days.saturday.egreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='saturday' />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                        <td colSpan={1}>
                                          <div className="d-flex justify-content-center">
                                            <table className="table-sm table-bordered">
                                              <thead>
                                                <tr>
                                                  <th className='text-center'>Neto</th>
                                                  <th className='text-center'>Pagos</th>
                                                  <th className='text-center'>Cobros</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }} type="number" name="neto" defaultValue={item.days.sunday.neto} className='input_days table-cxc-input' readOnly />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }} type="number" name="ingreso" defaultValue={item.days.sunday.ingreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='sunday' />
                                                  </td>
                                                  <td className='text-center'>
                                                    <input  style={{ width: '30px !important' }} type="number" name="egreso" defaultValue={item.days.sunday.egreso} className='input_days table-cxc-input' onChange={(e) => handlechange(e, item.user._id)} id='sunday' />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          )
                        }
                      </React.Fragment>
                    )
                  })
                }
              </tbody>
              <tfoot style={{backgroundColor:'#2d2e42', color:'white'}}>
                <tr>
                  <td className='text-center'>Status</td>
                                  <td className='text-center'>Consorcio</td>
                                  <td className='text-center'>POS</td>
                                  <td className='text-center'>Rojo Ant</td>
                                  {
                                    !balancesBlock ? <td className='text-center'>Bal Ant</td> : null
                                  }
                                  
                                  <td className='text-center'>Resultado</td>
                                  <td className='text-center'>Sueldo</td>
                                  <td className='text-center'>Devolucion</td>
                                  <td className='text-center'>% POS</td>
                                  <td className='text-center'>% Banca</td>
                                  <td className='text-center'>Rojo</td>
                                  {
                                    !balancesBlock ? <td className='text-center'>Bal.</td> : null
                                  }
                                  
                </tr>
                    <tr>
                      <td className='text-center' style={{fontWeight:'bolder'}}>{totalData.Estado}</td>
                      <td className='text-center' style={{fontWeight:'bolder'}}> {totalData.consorcioTotal} </td>
                      <td className='text-center' style={{fontWeight:'bolder'}}>{totalData.posTotal}</td>
                      <td className='text-center'>{totalData.rojoAnt}</td>
                      {
                        !balancesBlock ? <td className='text-center'>{formatNumber(totalData.BalAnt)}</td> : null
                      }
                      
                      <td className='text-center'>{totalData.Result}</td>
                      <td className='text-center'>{totalData.Sueldo}</td>
                      <td className='text-center'>{totalData.Devolucion}</td>
                      <td className='text-center'>{totalData.PorcentajePos}</td>
                      <td className='text-center'>{totalData.PorcentajeBanca}</td>
                      <td className='text-center'>{totalData.Rojo}</td>
                      {
                        !balancesBlock ? <td className='text-center'>{formatNumber(totalData.Bal)}</td> : null
                      }
                      
                    </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
