import { useEffect, useState } from 'react'
import { redApi } from '../../../../config/Axios'
import { Swals } from '../../../../utils/swals'

const Precios = () => {

    const [dataTable, setDataTable] = useState([])
    const [leagues, setLeagues] = useState([])
    useEffect(() => {
        redApi.get("/leagues")
            .then(response => {
                setLeagues(response.data)
            })
    }, [])

    const [updates, setUpdates] = useState([])

    const handleUpdate = (e, _id) => {
        let { name, value } = e.target
        if (name === 'status') {
            value = e.target.checked
        } else {
            value = parseInt(value)
        }
        const update = {
            _id,
            [name]: value
        }
        const index = updates.findIndex(item => item._id === _id)
        if (index === -1) {
            setUpdates([...updates, update])
        } else {
            const newUpdates = updates
            newUpdates[index] = {
                ...newUpdates[index],
                [name]: value
            }
            setUpdates([...newUpdates])
        }
    }


    const handleSave = () => {
        redApi.put("/linesConfig/priceSolos", { updates })
            .then(response => {
                Swals.success(response.data.message || response.data)
            })
    }


    let periods = [
        {
            label: "Juego Completo",
        },
        {
            label: "Mitad",
        },
        {
            label: "Segunda Mitad",
        },
        {
            label: "Q1",
        },
        {
            label: "Q2",
        },
        {
            label: "Q3",
        },
        {
            label: "Q4",
        },
    ]
    const [filters, setFilters] = useState({
        league: 'all',
        period: 'all'
    })

    const handleFilter = (e) => {
        const { name, value } = e.target
        setFilters({
            ...filters,
            [name]: value
        })
    }

    useEffect(() => {
        redApi.get(`/linesConfig/priceSolos?league=${filters.league}&period=${filters.period}`)
            .then(response => {
                setDataTable(response.data)
            })
    }, [filters])
    return (
        <div className="row">
            <div className="col-12">
                <div className="row d-flex flex-row align-items-center justify-content-center">
                    <h2 className="text-center">Precios de solos</h2>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-6 d-flex">
                        <label className="w-50 d-flex flex-row align-items-center justify-content-start">Liga</label>
                        <select className="form-select w-50" name='league' onChange={handleFilter}>
                            <option value={"all"}>Todas</option>
                            {
                                leagues?.map((item) => (
                                    <option key={item._id} value={item._id}>{item.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-6 d-flex">
                        <label className="w-50 d-flex flex-row align-items-center justify-content-start">Periodo</label>
                        <select className="w-50 form-select" name='period' onChange={handleFilter}>
                            <option value={"all"}>Todos</option>
                            {
                                periods?.map((item) => (
                                    <option key={item.label} value={item.label}>{item.label}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col d-flex my-4">
                        <div className="w-50 d-flex flex-row align-items-center justify-content-start mx-auto">
                            <button className="btn btn-primary w-100" onClick={handleSave}>Salvar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-12" id='table_container'>
                        <table>
                            <thead>
                                <tr>
                                    <th className="text-center">#</th>
                                    <th className="text-center">Liga</th>
                                    <th className="text-center">Periodo</th>
                                    <th className="text-center">Precio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataTable.map((item) => (
                                        <tr key={item._id}>
                                            <td colSpan={0.5} className="text-center px-4">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultChecked={item.status} name='status' onChange={(e) => handleUpdate(e, item._id)} />
                                                </div>
                                            </td>
                                            <td>{item.league?.name}</td>
                                            <td>{item.period}</td>
                                            <td>
                                                <input type="number" defaultValue={item.value} className="text-center form-number" name='value' onBlur={(e) => handleUpdate(e, item._id)} />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default Precios