import React from 'react'

const MyClients = () => {
  return (
    <>
      <div className='sales-report-search'>
        <label htmlFor="search">Search:</label>
        <input type="text" name="search" id="search" />
      </div>
      <div className='sales-report-tables'>
        <div>
          <table>
            <thead>
              <tr>
                <th>
                  id
                </th>
                <th>
                  customer
                </th>
                <th>
                  username
                </th>
                <th>
                  created
                </th>
                <th>
                  debt
                </th>
                <th>
                  balance
                </th>
                <th>
                  actions
                </th>
                <th>
                  unlock
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={8}>
                  Don't have clients, create one!
                </td>
              </tr>
              {/* <tr>
                <i className="fas fa-user-edit"></i>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default MyClients