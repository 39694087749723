import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import Layout from "../structures/layout";
import { redApi } from "../../../config/Axios";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { AuthContext } from "../../../auth";
const cookie = new Cookies();

export default function VentaAdmin() {
  const { userLevel } = useContext(AuthContext);
  const [onLoading, setOnLoading] = useState(false);
  const [consorcios, setConsorcios] = useState([]);
  const [pos, setPos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setOnLoading(true);
      await redApi
        .get("/users?userLevel=2")
        .then((res) => {
          setConsorcios(res.data);
        });
      await redApi
        .get("/users?userLevel=3")
        .then((res) => {
          setPos(res.data);
          setOnLoading(false);
        });
    };
    fetchData();
  }, []);

  const handleFilter = async (e) => {
    const consorcioId = e.target.value;
    setOnLoading(true);
    if (consorcioId === "all") {
      await redApi
        .get("/users?userLevel=3")
        .then((res) => {
          setPos(res.data);
          setOnLoading(false);
        });
      return;
    }
    await redApi
      .get("/users?userLevel=3")
      .then((res) => {
        const newPos = res.data.filter((pos) => pos.ownedBy === consorcioId);
        setPos(newPos);
        setOnLoading(false);
      });
  };

  const [WebUsers, setWebUsers] = useState([]);

  useEffect(() => {
    const fetchWebUsers = async () => {
      await redApi.get("/users?userLevel=4").then((res) => {
        setWebUsers(res.data);
      });
    };
    fetchWebUsers();
  }, []);

  // admin login to pos
  const handleLogin = async (terminal) => {
    await redApi.get(`/users/login/as/${terminal._id}`).then((res) => {
      if (!cookie.get("loginAs")) {
        cookie.set("loginAs", res.data.token, { path: "/" });
        window.location.href = "/client";
        return;
      }
      cookie.remove("loginAs", { path: "/" });
      cookie.set("loginAs", res.data.token, { path: "/" });
      window.location.href = "/client";
    });
  };

  const cursorPointerStyle = { cursor: 'pointer' };

  return (
    <Layout>
      <Container className="bg-card p-4">
        <div className="row">
          <div className="col-12 mb-4">
            <h2>Venta Admin</h2>
          </div>
          <div className="col-12 mb-2">
            <div className="row mb-4">
              <div className="col-12 mb-4 d-flex align-items-center" id="selectConsorcioUsers">
                <label htmlFor="selectConsorcioUser">Consorcio: </label>
                <select
                  id="selectConsorcioUser"
                  onChange={(e) => handleFilter(e)}
                >
                  {userLevel === 1 ? (
                    <>
                      <option value="all">Todos</option>
                      {consorcios.map((consorcio, index) => (
                        <option key={index} value={consorcio._id}>
                          {consorcio.username}
                        </option>
                      ))}
                    </>
                  ) : (
                    consorcios.map((consorcio, index) => (
                      <option key={index} value={consorcio._id}>
                        {consorcio.username}
                      </option>
                    ))
                  )}
                </select>
              </div>
              <div className="col-12 mb-2">
                <div className="w-100" id="table_container">
                  <table className="">
                    <thead>
                      <tr>
                        <th>POS</th>
                      </tr>
                    </thead>
                    <tbody id="tablePOS">
                      {onLoading ? (
                        <tr>
                          <td colSpan="2">Cargando...</td>
                        </tr>
                      ) : (
                        pos?.map((terminal, index) => (
                          <tr
                            key={index}
                            onClick={() => handleLogin(terminal)}
                            style={cursorPointerStyle}
                          >
                            <td className="d-flex gap-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-box-arrow-in-right"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
                                />
                                <path
                                  fillRule="evenodd"
                                  d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                                />
                              </svg>
                              {terminal.username}
                            </td>
                          </tr>
                        ))
                      )}
                      {pos.length === 0 && !onLoading ? (
                        <tr>
                          <td colSpan="2">No hay POS</td>
                        </tr>
                      ) : null}
                    </tbody>
                    <thead>
                      <tr>
                        <th>WebUsers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {WebUsers.map((WebUser, index) => (
                        <tr key={index} style={cursorPointerStyle}>
                          <td className="d-flex gap-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-box-arrow-in-right"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                              />
                            </svg>
                            {WebUser.username}
                          </td>
                        </tr>
                      ))}
                      {WebUsers.length < 1 && (
                        <tr>
                          <td colSpan="2">No hay webusers</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
