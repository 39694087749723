import { useEffect, useState } from "react";
import { redApi } from "../../config/Axios";

/*
const heads = {
    full: [{"name":"MONEY","property":"moneyLine"},{"name":"RL","property":"price"},{"name":"TOTAL","property":"total"},{"name":"P MAS","property":"posPlus"},{"name":"P MENOS","property":"posMinus"},{"name":"SOLO","property":"single"},{"name":"SOLO (+)","property":"singlePlus"},{"name":"SOLO (-)","property":"singleMinus"},{"name":"MH","property":"h1"},{"name":"GAVELA RL/H","property":"h1RlGavela"},{"name":"PRECIO RL/H","property":"h1Rl"},{"name":"TOTAL H","property":"HGavela"},{"name":"P MAS H","property":"HOver"},{"name":"P MENOS H","property":"HUnder"},{"name":"SOLO H","property":"HSGavela"},{"name":"SOLO (+) H","property":"HSOver"},{"name":"SOLO (-) H","property":"HSUnder"}],
    firstHalf: [{"name":"MONEY","property":"moneyLine"},{"name":"RL","property":"price"},{"name":"TOTAL","property":"total"},{"name":"P MAS","property":"posPlus"},{"name":"P MENOS","property":"posMinus"},{"name":"SOLO","property":"single"},{"name":"SOLO (+)","property":"singlePlus"},{"name":"SOLO (-)","property":"singleMinus"}],
    secondHalf: [{"name":"MH","property":"h1"},{"name":"GAVELA RL/H","property":"h1RlGavela"},{"name":"PRECIO RL/H","property":"h1Rl"},{"name":"TOTAL H","property":"HGavela"},{"name":"P MAS H","property":"HOver"},{"name":"P MENOS H","property":"HUnder"},{"name":"SOLO H","property":"HSGavela"},{"name":"SOLO (+) H","property":"HSOver"},{"name":"SOLO (-) H","property":"HSUnder"}],
}
*/

export const useGameStats = (league) => {
    const [isLoading, setIsLoading] = useState(true);
    const [games, setGames] = useState([]);
    const [structure, setStructure] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [tableHeads, setTableHeads] = useState([]);

    const [filters, setFilters] = useState({filter: "", sort: ""});
    
    const reducePeriods = (per) => {
      const reduced = per.reduce((acc, curr) => {
        if (structure.includes(curr.property)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      return reduced;
    }
    const handleChangeHeaders = (e) => {
      const findPeriod = periods.find(item => item.period === e.target.value);
      if (findPeriod)
        setTableHeads(reducePeriods(findPeriod.lines));
      else 
        setTableHeads([]);
    }

    const handleChangeBySort = (e) => setFilters({...filters, sort: e.target.value});
    const handleChangeByStatus = (e) => setFilters({...filters, filter: e.target.value});
    const filterGamesBySort = (games) => {
        if ( games.length === 0 ) return [];
        let sortFn = () => {};
        switch (filters.sort) {
            case "normal":
                sortFn = (a,b) => { return a.time < b.time ? 1 : -1 };
                break;
                case "time":
                sortFn = (a,b) => { return a.time > b.time ? 1 : -1 };
                break;

            case "away":
                sortFn = (a,b) => { return a.visitingTeam.name > b.visitingTeam.name ? 1 : -1 };
                break;
            case "home":
                sortFn = (a,b) => { return a.localTeam.name > b.localTeam.name ? 1 : -1 };
                break;
            default:
                let property = "";
                switch (filters.sort) {
                    case "ML": property = "moneyLine"; break;
                    case "RL": property = "price"; break;
                    // case "TL": property = ""; break;
                    case "TL+": property = "posPlus"; break;
                    case "TL-": property = "posMinus"; break;
                    // case "s": property = ""; break;
                    case "s+": property = "singlePlus"; break;
                    case "s-": property = "singleMinus"; break;
                    default: break;
                }
                if (games[0].statistics?.localLine?.[property] === undefined) return [];
                sortFn = (a,b) => {
                    const atotal = (a.statistics?.localLine?.[property] || 0) + (a.statistics?.visitingLine?.[property] || 0);
                    const btotal = (b.statistics?.localLine?.[property] || 0) + (b.statistics?.visitingLine?.[property] || 0);
                    return atotal < btotal ? 1 : -1;
                };
                break;
        }

        const sortedGames = [...games].sort(sortFn);
        return sortedGames;
    }
    const filterGamesByStatus = (games) => {
        let newData;
        if (!filters.filter) {
            newData = games;
        } else {
            if (filters.filter === "pending") {
                newData = games.filter(item => item.status === "pending" || item.status === "approved");
            } else if (filters.filter === "canceled") {
                newData = games.filter(item => item.status === "canceled" || item.status === "cancelled");
            } else {
                newData = games.filter(item => item.status === filters.filter);
            }
        }
        return newData;
    }
    const getStats = async () => {
        if (!league) return;
        setIsLoading(true);
        try {
            const { data } = await redApi.get(`/bets${league && `?league=${league._id}`}`);
            
            let newGames = data;
            if (filters.filter) {
                newGames = filterGamesByStatus(newGames);
            }
            if (filters.sort) {
                newGames = filterGamesBySort(newGames);
            }
            
            setGames(newGames);
        } catch (e) {
            console.log(e);
            setGames([]);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (games.length > 0) {
          const structure = Object.keys(games[0].statistics?.localLine || {});
          setStructure(structure);
        }
    }, [games]);
    useEffect(() => {
        if (!league) return;
        const periods = league.lineType[0]?.periods || [];
        setPeriods(periods);
    }, [league]);
    useEffect(() => {
        if (periods.length === 0) return;
        handleChangeHeaders({target: {value: periods[0].period}})
    }, [periods, structure]);
    useEffect(() => {
        getStats();
    }, [league, filters.filter, filters.sort]);

    return {
        games,
        isLoading,
        periods,
        tableHeads,
        
        handleChangeBySort,
        handleChangeByStatus,
        handleChangeHeaders,
        loadGames: getStats,

    }
}
