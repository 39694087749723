

export const Baseball = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    // "R",
    // "H",
    "T",
    "1H",
    "2H",
    "E",
    // "half",
    // "total",
]
export const CollegeBasketball = [
    "1",
    "2",
    // "half",
    // "total",
]

export const Basketball = [
    "1",
    "2",
    "3",
    "4",
    // "half",
    // "total",
]

export const Football = [
    "1",
    "2",
    "3",
    "4",
    // "half",
    // "total",
]

export const Hockey = [
    "1",
    "2",
    "3",
    // "half",
    // "total",
]

export const Soccer = [
    "1",
    "2",
    // "half",
    // "total",
]

//YAHOO
const BaseballLeagues = ["MLB", "CPBL", "NPB", "KBO"];

const BasketballLeagues = ["NBA", "NCAAW", "WNBA"];

const CollegeBasketballLeagues = ["NCAAB"];

const HockeyLeagues = ["NHL"];

const FootballLeagues = ["NFL", "NCAAF"];

const SoccerLeagues = ["Soccer"];


//SOCCER



export const getScoreStructure = (league) => {
    let structure = [];
    if (BaseballLeagues.includes(league)) structure = Baseball;
    if (BasketballLeagues.includes(league)) structure = Basketball;
    if (CollegeBasketballLeagues.includes(league)) structure = CollegeBasketball;
    if (HockeyLeagues.includes(league)) structure = Hockey;
    if (FootballLeagues.includes(league)) structure = Football;
    if (SoccerLeagues.includes(league)) structure = Soccer;

    return structure;
}