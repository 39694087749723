import { redApi } from "../../config/Axios";
import { getScoreStructure } from "../../utils/scoreStructures";
import { halfItems } from "../constants";

export const fetchTeams = async (league) => {
    let resTeams = [];
    let leagueConfig = null;
    const structure = getScoreStructure(league.name)
    try {
        const { data } = await redApi.get(`/teams?league=${league._id}`);
        resTeams = data;
    } catch (error) {
        console.log(error);
    }
    try {
        const { data } = await redApi.get(`/linesConfig/config?league=${league._id}`)
        leagueConfig = data[0]
    } catch (error) {
        console.log(error)
    }

    return {
        resTeams,
        structure,
        leagueConfigData: leagueConfig
    }
};

export const getValueForHandleStatusGame = (status) => {
    switch (status) {
        case "pending": return "approved"
        case "approved": return "playing"
        case "playing": return "finished"
        case "finished": return "pending"
        default: return status
    }
}
export const getClassNameForInput = (line, league, property, sportBookName, botName, selectedId, linesChanged, team) => {
    const classNames = [];
    if (!line) return classNames.join(" ");
    if (line._id + property === selectedId) classNames.push("line-selected")
    else if (linesChanged.includes(line._id + team + property)) {
        classNames.push("changed")
    }
    if (line[property].isAutomatic
        || league.lineConfig[property][botName]?.isAutomatic
        || league.lineConfig[property][sportBookName]?.isAutomatic) {
        classNames.push("automatic");
    } else if (
        line[property].isManual
        || league.lineConfig[property][botName]?.isManual
        || league.lineConfig[property][sportBookName]?.isManual
    ) {
        classNames.push("manual");
    } else if (
        line[property].isNew
        || league.lineConfig[property][botName]?.isNew
        || league.lineConfig[property][sportBookName]?.isNew
    ) {
        classNames.push("isNew");
    }
     else {
        classNames.push(line[property].status);
    };

    if (halfItems.includes(property)) classNames.push("bg-opacity-50");

    return classNames.join(" ");
}

export const getStyleForInput = (line, league, property, sportBookName, botName, head, index) => {
    const style = {
        color: "black",
        textAlign: "center",
        padding: "0"
    }
    if (line[property].isManual || league.lineConfig[property][botName]?.isManual || league.lineConfig[property][sportBookName]?.isManual)
        style.color = "white";

    if (head.length < 3 && head.length > 1 && index === 0) {
        style.textAlign = "right";
        style.padding = "0 1rem 0 0";
    } else if (head.length < 3 && head.length > 0 && index === 1) {
        style.textAlign = "left";
        style.padding = "0 0 0 1rem";
    }

    return style;
}

export const parseTeamName = (name) => {
    if (name?.length > 20) return name.slice(0, 20) + "...";
    return name;
}
export const parseTeamOrPitcherName = (name, isPonches, pitcher) => {
    if (name?.length > 20) {
        return isPonches && pitcher ? `${pitcher} ${name.slice(0, 20)}... ` : name.slice(0, 20) + "..."
    }
    return isPonches && pitcher ? `${pitcher} (${name})` : name
}

export const getValueForInput = (value, identifier, linesChanged = undefined, hashMap = undefined) => {
    let parsed = value ? value : "0";
    let hasPlus = value[0] === "+"
    // parsed = Math.round(Number(parsed) * 10) / 10

    let firstNumber = parsed.split(".")[0]
    let secondNumber = parsed.split(".")[1]
    let valNeutro = "0"
    if(secondNumber && Number(secondNumber) > 0 && Number(secondNumber) < 50 || Number(secondNumber) === 50) secondNumber = "5" 
    if(secondNumber && Number(secondNumber) > 50 && Number(secondNumber) < 99 || Number(secondNumber) === 0) secondNumber = valNeutro 
    // if(!secondNumber) parsed = Math.round(parsed * 10) / 10;
    if(secondNumber) parsed = `${firstNumber}.${secondNumber}`

    // if( !secondNumber  && (Number(firstNumber) < 10 && Number(firstNumber) > -10)) parsed = `${firstNumber}.${valNeutro}`
    
    if (linesChanged && hashMap && linesChanged.includes(identifier)) {
        return hashMap.get(identifier)?.value || "0";
    };
    // if (isNaN(parsed)) return "0";
    if (isNaN(parsed)) return "";
    // if (hasPlus) return '+' + parsed
    return parsed;

}






