import Modal from "antd/es/modal/Modal"
import { useEffect, useState } from "react"
import "./reportes.scss"
import TicketsWebuser from "../webuser/reportes/Tickets"
import SalesWebuser from "../webuser/reportes/Sale"
import { redApi as api } from "../../../../config/Axios"

const ReportesModal = ({
    open,
    onCancel
}) => {

    const [menu, setMenu] = useState([
        {
            name: "Tickets",
            active: true,
            icon: "fas fa-ticket-alt"
        },
        // {
        //     name: "Sales",
        //     active: false,
        //     icon: "fas fa-dollar-sign"
        // },
    ])

    const handleMenu = (index) => {
        const newMenu = menu.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    active: true
                }
            } else {
                return {
                    ...item,
                    active: false
                }
            }
        })
        setMenu(newMenu)
    }

    const [posTerminals, setPosTerminals] = useState([]);
    const [consorcios, setConsorcios] = useState([]);
    useEffect(() => {
        api.get("/verify").then((res) => {
            setPosTerminals([res.data])
            setConsorcios([res.data.ownedBy])
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    return (
        <Modal
            title="Reportes web user"
            open={open}
            footer={null}
            width="100vw"
            style={{ top: 10, right: 10, }}
            onCancel={onCancel}
            className="reportes-modal__container"
        >
            <div className="reportes-modal">
                <div className="reportes-modal__menu">
                    {
                        menu.map((item, index) => (
                            <div
                                key={index}
                                className={`reportes-modal__menu-item ${item.active ? "active" : ""}`}
                                onClick={() => handleMenu(index)}
                            >
                                <i className={item.icon}></i>
                                <span>{item.name}</span>
                            </div>
                        ))
                    }
                </div>
                <div className="reportes-modal__content">
                    {
                        menu.map((item, index) => (
                            <div
                                key={index}
                                className={`reportes-modal__content-item ${item.active ? "active" : ""}`}
                            >
                                {
                                    item.active && item.name === "Tickets" && <TicketsWebuser posTerminals={posTerminals} consorcios={consorcios} />
                                }
                                {/* {
                                    item.active && item.name === "Sales" && <SalesWebuser posTerminals={posTerminals} consorcios={consorcios} />
                                } */}
                            </div>
                        ))
                    }
                </div>
            </div>
        </Modal >
    )
}

export default ReportesModal