import { create } from 'zustand'
import { redApi } from "../../config/Axios";
import { useState, useEffect } from 'react';

export const useHyRStore = create((set, get) => ({
  gamesRender_: [],
  getGamesRender_: async (league, date, table, estatus) => {
    if (!league) return;
    if (league?.name == 'NBA-S' || league?.name == 'WNBA-S' || league?.name == 'NFL-S' || league?.name == 'NHL-S') {
      const response = await redApi.get(`/games?matchUps=true&date=${date}&league=${league._id}`);

      set({ gamesRender_: response.data });
    } else {
      const response = await redApi.get(
        `/games?date=${date}&league=${league?._id}&table=${table}&estatus=${estatus}&futures=true`
      );

      set({ gamesRender_: response.data });
    }
  },
  teamsDB_: [],
  getTeamsDB_: (teamsDB) => {
    set({ teamsDB_: teamsDB });
  },
  statusGames_: "not inited",
  changeStatusGames_: (status) => {
    set({ statusGames_: status });
  },
}));