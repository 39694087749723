import moment from 'moment'
import { useState, useEffect } from 'react'
import { redApi } from '../../../../../config/Axios'

export default function ScoreBoxMan() {
    const [data, setData] = useState([])
    const [leagues, setLeagues] = useState([])
    const [teams, setTeams] = useState([])

    const getLeagues = async () => {
        await redApi.get('/leagues')
            .then(res => {
                setLeagues(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        getLeagues()
    }, [])

    const [filters, setFilters] = useState({
        league: 'Todos',
        team: 'Todos',
        status: 'Todos',
        date: moment().format('YYYY-MM-DD')
    })

    const handleFilters = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        })
    }

    const getDataWithFilters = async () => {
        if (filters.league !== 'Todos' || filters.team !== 'Todos' || filters.status !== 'Todos' || filters.date !== 'Todos') {
            await redApi.get(`/games${filters.date !== 'Todos' ? `?date=${filters.date}${filters.league !== 'Todos' ? `&league=${filters.league}` : ''}${filters.team !== 'Todos' ? `&team=${filters.team}` : ''}${filters.status !== 'Todos' ? `&stateGame=${filters.status}` : ''}` : ''}`)
                .then(res => {
                    setData(res.data)
                })
                .catch(err => {
                    console.log(err)
                })
            if (filters.league !== 'Todos') {
                await redApi.get(`/teams${filters.league !== 'Todos' ? `?league=${filters.league}` : ''}`)
                    .then(res => {
                        setTeams(res.data)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        } else {
            await redApi.get(`/games${filters.date !== 'Todos' ? `?date=${filters.date}` : ''}`)
                .then(res => {
                    setData(res.data)
                }
                )
                .catch(err => {
                    console.log(err)
                }
                )
        }
    }

    useEffect(() => {
        getDataWithFilters()
    }, [filters])
    const getHalfTime = (game) => {
        if (game.league.name === 'NBA') {

            let halfLocal = parseInt(game.localLine.scores.q1) + parseInt(game.localLine.scores.q2);

            let halfVisit = parseInt(game.visitingLine.scores.q1) + parseInt(game.visitingLine.scores.q2);

            return `${halfLocal} - ${halfVisit}`;
        }
    }

    let status = ["Todos", "pending", "approved", "finished", "playing"]

    return (
        <div className="container-fluid p-0  m-0">
            <div className="row m-0 p-0">
                <div className="col-12  m-0 p-0 bg-gray-800 py-4 hide_mobile">
                    <div className="row m-0 p-0 gap-3">
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Liga</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-select" name="league" onChange={handleFilters}>
                                                <option value="Todos">Todos</option>
                                                {leagues.map((league, index) => (
                                                    <option key={index} value={league._id}>{league.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Equipo</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-select" name="team" onChange={handleFilters}>
                                                <option value="Todos">Todos</option>
                                                {teams.map((team, index) => (
                                                    <option key={index}>{team.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Fecha</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="date" className="form-date" name="date" onChange={handleFilters} defaultValue={moment().format('YYYY-MM-DD')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Status</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name="status" onChange={handleFilters}>
                                                {status.map((cons, index) => (
                                                    <option key={index} value={cons}>{cons}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <input type="button" value="Imprimir" />
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <input type="button" value="Refrescar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 py-4" id="customers">
                    {/* <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Fecha</th>
                                <th scope="col">Equipo</th>
                                <th scope="col">Half</th>
                                <th scope="col">Full</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map((game, index) => (
                                    <tr key={index}>
                                        <td>{moment(game.time).format('DD/MM/YYYY')}</td>
                                        <td>
                                            {game.localTeam.name} vs {game.visitingTeam.name}
                                        </td>
                                        <td>{game.localLine.scores || game.visitingLine.scores ? getHalfTime(game) : ''}</td>
                                        <td>{game.localLine.scores || game.visitingLine.scores ? `${game.localLine.scores.total} - ${game.visitingLine.scores.total}` : ''}</td>
                                        <td>{game.status}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table> */}
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Fecha</th>
                                <th scope="col">Equipo</th>
                                <th scope="col">Half</th>
                                <th scope="col">Full</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map((game, index) => (
                                    <tr key={index}>
                                        <td>{moment(game.time).format('DD/MM/YYYY')}</td>
                                        <td>
                                            <div>
                                                <div>
                                                    {game.localTeam.name}
                                                    <span style={{ fontSize: '0.8rem', }} >
                                                        {" - "}
                                                        ({game.league.name})
                                                    </span>
                                                </div>
                                                <div>
                                                    {game.visitingTeam.name}
                                                    <span style={{ fontSize: '0.8rem', }} >
                                                        {" - "}
                                                        ({game.league.name})
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div>
                                                    {game.localLine?.scores?.half || "~"}
                                                </div>
                                                <div>
                                                    {game.visitingLine?.scores?.half || "~"}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div>
                                                    {game.localLine?.scores?.total || "~"}
                                                </div>
                                                <div>
                                                    {game.visitingLine?.scores?.total || "~"}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{game.status}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
