import React from 'react'
import "./button.css"
import Swal from "sweetalert2";
const Buttons = ({setShowCreate, setShowEditar, setShowTable,setShowDelete,selectedRow, getGroup}) => {
  const showCreateGroup = () => {
    setShowCreate(true)
    setShowTable(true)
  }
  const showEditGroup = () => {
    if(selectedRow){
      setShowEditar(true)
      setShowTable(true)
    } else {
      Swal.fire({
        title: "Debes seleccionar un grupo primero",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  }
  const showDeleteGroup = () => {
    if(selectedRow){
      setShowDelete(true)
      setShowTable(true)
    } else {
      Swal.fire({
        title: "Debes seleccionar un grupo primero",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  }

  const refresh = () => {
    try {
      getGroup()
      Swal.fire({
        title: "Refrescando datos",
        icon: "success",
        timer: 1800,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.log("error", error)
      Swal.fire({
        title: "Error al refrescar datos",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  }

  return (
    <div className='button-container'>
        <button  style={{backgroundColor:'#dc3545', color:'white', width:'22%', margin:'auto', borderRadius:'8px'}} onClick={()=>{showCreateGroup()}}>Crear</button>
        <button  style={{backgroundColor:'#dc3545', color:'white', width:'22%', margin:'auto', borderRadius:'8px'}} onClick={()=>{showEditGroup()}}>Editar</button>
        <button  style={{backgroundColor:'#dc3545', color:'white', width:'22%', margin:'auto', borderRadius:'8px'}} onClick={()=>{showDeleteGroup()}}>Eliminar</button>
        <button  style={{backgroundColor:'#dc3545', color:'white', width:'22%', margin:'auto', borderRadius:'8px'}} onClick={()=>{refresh()}}>Refrescar</button>
    </div>
  )
}

export default Buttons