import React from 'react';
import Footer from '../navigation/Footer';

import Header from '../navigation/Header';
import LeftContainer from '../navigation/LeftContainer';
import RightContainer from '../navigation/RightContainer';
import './layout-client.scss';

const Layout = ({ children, bets, setBets, betToAdd, setBetToAdd, gamesForUser, isLoading, setUserTheme, totalToWin, setTotalToWin, selectedBet, setSelectedBet, printTicket, setPrintTicket, ticketNumber, liveGames, mobile, handleBetPost }) => {
    const handleChangeBetToAdd = (e) => {
        setBetToAdd({
            ...betToAdd,
            [e.target.name]: (e.target.value).toUpperCase()
        });
        if (e.target.name === 'TeamCode' && e.target.value.length === 4) {
            document.querySelector('#betTypeSelect').focus();
        }
    }
    return (
        <>
            <section id="bodyClient" className="main-sports-wrapper">
                <Header />
                <div className="side-wrapper">
                    <div className="row g-0" style={{ height: '100%' }} id='main-row-client'>
                        <div id="left-container" className="col-lg-auto t-ful d-flex" style={{ backgroundColor: '#282838', height: '100%' }}>
                            <LeftContainer liveGames={liveGames} gamesForUser={gamesForUser} isLoading={isLoading} setUserTheme={setUserTheme} />
                        </div>
                        <div id="middle-container" className="col bg-light px-1" style={{ height: '100%' }}>
                            {children}
                        </div>
                        <div id="right-container" className="col-lg-3 right-wrapper" style={{ height: '100%' }}>
                            <RightContainer setBetToAdd={setBetToAdd} handleChangeBetToAdd={handleChangeBetToAdd} betToAdd={betToAdd} bets={bets} setBets={setBets} totalToWin={totalToWin} setTotalToWin={setTotalToWin} selectedBet={selectedBet} setSelectedBet={setSelectedBet} printTicket={printTicket} setPrintTicket={setPrintTicket} ticketNumber={ticketNumber} handleBetPost={handleBetPost} />
                        </div>
                    </div>
                </div>
                <Footer mobile={mobile} />
            </section>
        </>
    );


}

export default Layout;