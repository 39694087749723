import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';

const Navbar = () => {

    let menu = [
        {
            name: "Home",
            path: "/home/lines"
        },
        {
            name: "Play calculator",
            path: "/home/play-calculator"
        },
        {
            name: "Rules",
            path: "/home/rules"
        }
    ];

    const navigate = useNavigate();

    useEffect(() => {
        navigate('/home/lines');
    }, []);

    return (
        <div className="navbar">
            <ul>
                {menu.map((item, index) => {
                    return (
                        <li key={index}>
                            <NavLink to={item.path}>{item.name}</NavLink>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Navbar