import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { AuthContext } from '../../../auth'
import { redApi } from '../../../config/Axios'
import Layout from '../structures/layout'


export default function Messages() {
    const { userLevel } = useContext(AuthContext);
    const [consortuims, setConsortiums] = useState([])
    const [terminals, setTerminals] = useState([])
    const [webusers, setWebUsers] = useState([])

    const getAllUsers = async () => {
        try {
            await redApi.get('/users?userLevel=2').then(res => {
                setConsortiums(res.data)
            })
            await redApi.get('/users?userLevel=3').then(res => {
                setTerminals(res.data)
            })
            await redApi.get('/users?userLevel=4').then(res => {
                setWebUsers(res.data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllUsers()
    }, [])

    const [mensajeria, setMensajeria] = useState({
        consortiums: '0',
        terminals: '0',
        webusers: '0',
        message: ''
    })

    const handleMensajeria = (e) => {
        setMensajeria({
            ...mensajeria,
            [e.target.name]: e.target.value
        })
    }

    const sendMensajeria = async (e) => {
        switch (e.target.name) {
            case 'consortiums':
                console.log('consortiums')
                sendMessage(mensajeria.message, 2, mensajeria.consortiums)
                break;
            case 'terminals':
                console.log('terminals')
                sendMessage(mensajeria.message, 3, mensajeria.terminals)
                break;
            case 'webusers':
                console.log('webusers')
                sendMessage(mensajeria.message, 4, mensajeria.webusers)
                break;
            default:
                break;
        }
    }

    const sendMessage = async (message, userLevel, id) => {
        if (id) {
            try {
                Swal.fire({
                    title: 'Enviando mensaje',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                await redApi.post('/users/sendMessages', {
                    message,
                    to: id,
                    userLevel
                }).then(res => {
                    Swal.close()
                    Swal.fire({
                        icon: 'success',
                        title: 'Mensaje enviado',
                        text: `${res.data.message}`
                    })
                })
            } catch (error) {
                console.log(error)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Ha ocurrido un error`
                })
            }
        } else {
            try {
                Swal.fire({
                    title: 'Enviando mensaje',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                await redApi.post('/users/sendMessages', {
                    message,
                    userLevel,
                    to: '0'
                }).then(res => {
                    Swal.close()
                    Swal.fire({
                        icon: 'success',
                        title: 'Mensaje enviado',
                        text: `${res.data.message}`
                    })
                })
            } catch (error) {
                console.log(error)
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Ha ocurrido un error`
                })
            }
        }
    }

    return (
        <Layout>
            <Container className="container bg-card p-4">
                {/* <form>
                    <div className="row mb-4">
                        <div className='col-auto d-flex align-items-center justify-content-center'>
                            <input defaultChecked className='d-flex align-self-center' type="radio" name="radioMensajeria" id="radioPOSMens" />
                            <label htmlFor="radioPOSMens">POS</label>
                        </div>
                        <div className='col-auto d-flex align-items-center justify-content-center'>
                            <input type="radio" name="radioMensajeria" id="radioUsuariosMens" />
                            <label htmlFor="radioUsuariosMens">Usuarios</label>
                        </div>
                    </div>
                </form> */}
                <div className="mensajeria">
                    <div className="mensajeria-fields">
                        {
                            userLevel !== 1 ? null :
                                <div className="mensajeria-field">
                                    <select name="consortiums" onChange={handleMensajeria}>
                                        <option value="0">Todos los consorcios</option>
                                        {consortuims.map(consortium => (
                                            <option key={consortium._id} value={consortium._id}>{consortium.username}</option>
                                        ))}
                                    </select>
                                    <button
                                        onClick={sendMensajeria}
                                        name="consortiums"
                                    >Enviar</button>
                                </div>
                        }
                        <div className="mensajeria-field">
                            <select name="terminals" onChange={handleMensajeria}>
                                <option value="0">Todos los terminales</option>
                                {terminals.map(terminal => (
                                    <option key={terminal._id} value={terminal._id}>{terminal.username}</option>
                                ))}
                            </select>
                            <button
                                onClick={sendMensajeria}
                                name="terminals"
                            >Enviar</button>
                        </div>
                        <div className="mensajeria-field">
                            <select name="webusers" onChange={handleMensajeria}>
                                <option value="0">Todos los webusers</option>
                                {webusers.map(webuser => (
                                    <option key={webuser._id} value={webuser._id}>{webuser.username}</option>
                                ))}
                            </select>
                            <button
                                onClick={sendMensajeria}
                                name="webusers"
                            >Enviar</button>
                        </div>
                        <div className="mensajeria-field">
                            <select name="date" id="date"
                                style={
                                    {
                                        width: '90%',
                                        height: '100%',
                                    }
                                }
                            >
                                <option value="0">Enviar ahora</option>
                                <option value="1">Fecha de envio</option>
                            </select>
                            <input
                                type="date"
                                name="date"
                                id="date"
                                style={
                                    {
                                        width: '90%',
                                        height: '100%',
                                    }
                                }
                                defaultValue={moment().format('YYYY-MM-DD')}
                            />
                        </div>
                    </div>
                    <div className="mensajeria-cuerpo">
                        <textarea name="message" id="mensaje" cols="30" rows="10" onChange={handleMensajeria}
                            placeholder="Escribe tu mensaje"
                        ></textarea>
                    </div>
                </div>
            </Container>
        </Layout>
    )
}
