import React from 'react'
import './tableHeader.css'
const TableHeader = () => {
  return (

        <thead className='table-header-container'>
            <tr>
                <th>Nombre</th>
                <th >Descripcion</th>
                <th >Terminales</th>
            </tr>
        </thead>
   
  )
}

export default TableHeader