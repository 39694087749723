import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../../../../../auth';

const ChangeTheme = ({ handleChangeTheme }) => {
  const { logout, userTheme: themeofuser } = useContext(AuthContext);
  useEffect(() => {
    console.log({ themeofuser })
  }, [themeofuser])
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center py-2">
        <i className="fas fa-adjust px-1 h1"></i>
      </div>
      <div className="row d-flex flex-column justify-content-center align-items-center">
        <select
          defaultValue="Choose theme"
          className="form-select col-6"
          aria-label="Default select example"
          onChange={handleChangeTheme}
        >
          <option disabled>Choose ssstheme</option>
          <option value="1">Legacy</option>
          <option value="2">Red</option>
          <option value="3">Azul</option>
          <option value="4">Theme 4</option>
          <option value="5">F83</option>
        </select>
      </div>
    </>
  )
}

export default ChangeTheme