import React, { useCallback, useState, useEffect } from "react";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import { redApi } from "../../../config/Axios";


const Header = () => {
  const { league } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLeagueActive(league);
  }, [league]);

  const [leagueActive, setLeagueActive] = useState("");
  const [menuItems, setMenuItems] = useState([]);

  useEffect(async () => {
    await redApi.get("/leagues?leaguesWithGames=true").then((res) => {

      let leaguesWithGames = res.data;
      if (leaguesWithGames.length > 0) {
        leaguesWithGames?.map((league) => {
          league.image = `${process.env.REACT_APP_SERVER_URL}/LeagueImages/${league.name}.png`
        });
      }
      setMenuItems(leaguesWithGames);
      console.log(leaguesWithGames);
    });
  }, []);

  return (
    <header className="bg-white bg-card">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg navbar-light bg-white d-flex justify-content-center">
              {menuItems.length > 0 ? (
                menuItems?.map((item, index) => {
                  return (
                    <NavLink
                      key={index}
                      to={`/client/${item.name
                        .replaceAll(" ", "%20")
                        .replaceAll("/", "_")}`}
                      className={`navbar-brand ${leagueActive === item.name ? "active" : ""
                        }`}
                      onClick={() => setLeagueActive(item.name)}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "/logos/logodefault.png";
                        }}
                        style={{
                          width: "80px",
                          height: "80px",
                          objectFit: "contain",
                        }}
                      />
                    </NavLink>
                  );
                })
              ) : (
                <div>No hay ligas con juegos</div>
              )}
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
