import { useState, useEffect } from 'react'
import { redApi } from '../../../../../config/Axios'
import moment from 'moment'



export default function WinLoss({
    posTerminals,
    consorcios,
}) {
    const [data, setData] = useState([])
    const [onLoading, setOnLoading] = useState(false)
    const [terminals, setTerminals] = useState(posTerminals)
    // const [consorcios, setConsorcios] = useState([])

    const [filter, setFilter] = useState({
        consorcio: 'all',
        pos: 'all',
        initDate: moment().startOf('day').format('YYYY-MM-DD'),
        finalDate: ''
    })

    const handleFilter = async (e) => {
        if (e.target.name === 'refresh') {
            setOnLoading(true)
            await redApi.get(`/EarnLosses?initDate=${filter.initDate}`).then(res => {
                setData(res.data)
                setOnLoading(false)
            }).catch(err => setData(['No tickets found']))
        }
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        })
    }

    useEffect(async () => {
        setOnLoading(true)
        await redApi.get(`/EarnLosses?initDate=${filter.initDate}${posTerminals ? `&pos=${posTerminals[0].username}` : ''}`).then(res => {
            setData(res.data)
            setOnLoading(false)
        }).catch(err => setData(['No tickets found']))
    }, [])

    useEffect(async () => {
        setOnLoading(true)
        let filteredData;
        if (filter.initDate) {
            if (filter.finalDate)
                await redApi.get(`/EarnLosses?initDate=${filter.initDate}&finalDate=${filter.finalDate}${posTerminals ? `&pos=${posTerminals[0].username}` : ''}`).then(res => {
                    filteredData = res.data
                }).catch(err => filteredData = ['No tickets found'])
            else
                await redApi.get(`/EarnLosses?initDate=${filter.initDate}${posTerminals ? `&pos=${posTerminals[0].username}` : ''}`).then(res => {
                    filteredData = res.data
                }).catch(err => filteredData = ['No tickets found'])
        }

        // if (filter.pos !== 'all') {
        //     console.log(filter.pos, filteredData)
        //     filteredData.Terminales = filteredData.Terminales.filter(terminal => terminal.username === filter.pos)
        // }
        // if (filter.consorcio !== 'all') {
        //     filteredData.Terminales = filteredData.Terminales.filter(terminal => terminal.consorcio === filter.consorcio)
        // }

        if (filteredData[0] !== 'No tickets found') {
            setData(filteredData)
            setOnLoading(false)
        }
        else {
            setData(['No tickets found'])
            setOnLoading(false)
        }
    }, [filter])
    return (
        <div className="container-fluid p-0  m-0">
            <div className="row m-0 p-0">
                <div className="col-12  m-0 p-0 bg-gray-800 py-4 hide_mobile">
                    <div className="row m-0 p-0 gap-3">
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">POS</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name="pos" disabled>
                                                {
                                                    terminals && terminals?.map((termianl, index) => (
                                                        <option key={index} value={termianl?.username} selected>{termianl?.username}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Consorcio</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name="consorcio" disabled>
                                                {
                                                    consorcios && consorcios?.map((consorcio, index) => (
                                                        <option key={index} value={consorcio?.username} selected>{consorcio?.username}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-start align-items-center">
                                            {/* <input type="checkbox" onChange={handleCheck} />
                                            <label className="m-0 p-0">Rango</label> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Refrezcar" name="refresh" className="btn btn-primary"
                                                style={{
                                                    border: 'none',
                                                }}
                                                onClick={handleFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className="m-0 p-0 text-start text-nowrap">Fecha</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="date" className="form-control" name="initDate" value={filter.initDate} onChange={handleFilter} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">

                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Imprimir" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        {/* <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className={`m-0 p-0 text-start text-nowrap ${!showcheck ? 'invisible' : ''}`}>Fecha final</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="date" className={`form-control ${!showcheck ? 'invisible' : ''}`} name="endDate" onChange={handleFilter} />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 py-4" id="customers">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th className="text-center" scope="col">Consorcio</th>
                                <th className="text-center" scope="col">POS</th>
                                <th className="text-center" scope="col">Venta</th>
                                <th className="text-center" scope="col">Premios</th>
                                <th className="text-center" scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                onLoading ? (
                                    <tr>
                                        <td colSpan="5" className="text-center">Cargando...</td>
                                    </tr>
                                ) : <>
                                    {
                                        data?.Terminales?.length === 0 && (
                                            <tr>
                                                <td colSpan="5" className="text-center">No hay datos</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        data[0] === 'No tickets found' && (
                                            <tr>
                                                <td colSpan="5" className="text-center">No hay datos</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        data?.Terminales?.map((terminal, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{terminal.consorcio}</td>
                                                <td className="text-center">{terminal.username}</td>
                                                <td className="text-center">{terminal.amount}</td>
                                                <td className="text-center">{terminal.toCollect}</td>
                                                <td className="text-center">{terminal.total}</td>
                                            </tr>
                                        ))
                                    }
                                </>

                            }

                        </tbody>
                        <tfoot>
                            {
                                data?.Terminales?.length > 0 ? (
                                    <tr>

                                        <td colSpan="2" className="text-center">Total</td>
                                        <td className="text-center">{data?.amount}</td>
                                        <td className="text-center">{data?.toCollect}</td>
                                        <td className="text-center">{data?.total}</td>
                                    </tr>

                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">No hay datos</td>
                                    </tr>

                                )
                            }
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}
