
import React, { useRef, useEffect } from 'react';


import moment from 'moment';



const CanvasBarChart = ({ data, width = 1000, height = 300, labels }) => {
  let canvasRef;
  if(data){
    canvasRef = useRef(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

   

    // Configuración básica del gráfico
    const barWidth = width / (labels.length * 2); // Se multiplica por 2 porque hay dos barras por día
    const maxValue = Math.max(...data.map(d => Math.max(d.amountOfTicketsLosser, d.amountOfTicketsWin)), 0);

    ctx.clearRect(0, 0, width, height + 30); // Limpiar el canvas y dejar espacio para las etiquetas

    // **Agregar el cuadriculado en el fondo**
    const gridStep = 200; // Incremento de 200 en el eje Y
    const numberOfSteps = Math.ceil(maxValue / gridStep); // Cantidad de pasos en el eje Y
    const stepHeight = height / numberOfSteps; // Altura de cada paso

    ctx.strokeStyle = 'gray'; // Color del cuadriculado
    ctx.lineWidth = 1;

    // Líneas horizontales y etiquetas del eje Y
    for (let i = 0; i <= numberOfSteps; i++) {
      const yPos = height - i * stepHeight;

      // Dibuja la línea horizontal
      ctx.beginPath();
      ctx.moveTo(0, yPos);
      ctx.lineTo(width, yPos);
      ctx.stroke();

      // // Etiquetas del eje Y
      // ctx.fillStyle = 'black';
      // ctx.font = '12px Arial';
      // ctx.textAlign = 'right';
      // ctx.textBaseline = 'middle';
      // ctx.fillText(i * gridStep, 10, yPos); // Ajusta la posición de las etiquetas del eje Y
    }

    // Líneas verticales
    for (let i = 0; i <= labels.length; i++) {
      const xPos = i * barWidth * 2;
      ctx.beginPath();
      ctx.moveTo(xPos, 0);
      ctx.lineTo(xPos, height);
      ctx.stroke();
    }

    // Crear un mapa para asegurar que cada día tenga datos
    const dataMap = {};
    data.forEach(entry => {
      dataMap[moment(entry.date).format('YYYY-MM-DD')] = entry;
    });

    // Rellenar días faltantes con valores de 0
    const fullData = labels.map(label => {
      const date = moment().isoWeekday(labels.indexOf(label) + 1).format('YYYY-MM-DD');
      return dataMap[date] || { date, amountOfTicketsLosser: 0, amountOfTicketsWin: 0 };
    });

    // Dibujar las barras
    fullData.forEach((value, index) => {
      const xBase = index * barWidth * 2; // Posición X base para cada día

      // Barra verde (amountOfTicketsLosser)
      const barHeightLosser = (value.amountOfTicketsLosser / maxValue) * height;
      const yLosser = height - barHeightLosser;

      ctx.fillStyle = 'green';
      ctx.fillRect(xBase, yLosser, barWidth - 5, barHeightLosser);

      // Añadir borde a la barra verde
      ctx.strokeStyle = 'rgba(0, 0, 0, 1)';
      ctx.lineWidth = 2;
      ctx.strokeRect(xBase, yLosser, barWidth - 5, barHeightLosser);

      // Barra roja (amountOfTicketsWin)
      const barHeightWin = (value.amountOfTicketsWin / maxValue) * height;
      const yWin = height - barHeightWin;

      ctx.fillStyle = 'red';
      ctx.fillRect(xBase + barWidth, yWin, barWidth - 5, barHeightWin);

      // Añadir borde a la barra roja
      ctx.strokeStyle = 'rgba(0, 0, 0, 1)';
      ctx.lineWidth = 2;
      ctx.strokeRect(xBase + barWidth, yWin, barWidth - 5, barHeightWin);

      // Dibujar las etiquetas debajo de cada grupo de barras
      ctx.fillStyle = 'gray';
      ctx.font = '18px Arial';
      ctx.fillText(labels[index], xBase + barWidth / 2 + 8, height + 20); // Ajustar posición de etiquetas
    });
  }, [data, height, width, labels]);
  }

  return <canvas ref={canvasRef} width={width} height={height + 30} />; // Aumentar la altura total del canvas
};

export default CanvasBarChart;

// BarChart.js
// import React, { useEffect, useRef } from 'react';
// import Chart from 'chart.js/auto';

// const BarChart = ({ datos, labels }) => {
//   let chartRef
//   let chartInstanceRef
//   if(datos) {
//     chartRef=useRef(null);
//     chartInstanceRef = useRef(null);
//     useEffect(() => {
//       const ctx = chartRef.current.getContext('2d');
  
//        // Destruir la instancia anterior si existe
//         if (chartInstanceRef.current) {
//           chartInstanceRef.current.destroy();
//         }

//       const amountOfTicketsLosser = datos.map(d => d.amountOfTicketsLosser);
//       const amountOfTicketsWin = datos.map(d => d.amountOfTicketsWin);
  
//       new Chart(ctx, {
//         type: 'bar',
//         data: {
//           labels: labels,
//           datasets: [
//             {
//               label: 'Tickets Perdidos',
//               data: amountOfTicketsLosser,
//               backgroundColor: 'rgba(34, 139, 34, 0.2)', // Verde oscuro
//               borderColor: 'rgba(34, 139, 34, 1)',
//               borderWidth: 1,
//             },
//             {
//               label: 'Tickets Ganados',
//               data: amountOfTicketsWin,
//               backgroundColor: 'rgba(139, 0, 0, 0.2)', // Rojo oscuro
//               borderColor: 'rgba(139, 0, 0, 1)',
//               borderWidth: 1,
//             },
//           ],
//         },
//         options: {
//           responsive: true,
//           plugins: {
//             legend: {
//               position: 'top',
//             },
//             title: {
//               display: true,
//               text: 'Gráfica de Tickets',
//             },
//           },
//           scales: {
//             y: {
//               beginAtZero: true,
//             },
//           },
//         },
//       });
//     }, [datos, labels]);

//   }

//   return <canvas ref={chartRef} />;
// };
// export default BarChart;


