import React from 'react'

const PendingWithdrawals_t4_mobile = ({ setMenuActive, menuActive }) => {
  return (
    <div className='sub_menu-container'>
      <button onClick={() => setMenuActive('')}>
        <i className="fas fa-arrow-left"></i>
      </button>
      <div className='container_sub_menu'>
        <div>
          <label htmlFor="selectUser" style={{ fontSize: '1.3rem' }}>
            PENDING WITHDRAWALS
          </label>
        </div>
        <div className='bottom_container'>
          <div className='tables_container'>
            <table>
              <thead>
                <tr>
                  <th>
                    CUSTOMER
                  </th>
                  <th>
                    DATE
                  </th>
                  <th>
                    METHOD
                  </th>
                  <th>
                    AMOUNT
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <div role='alert' className='alert alert-danger d-flex justify-content-center align-items-center'>
                      No withdrawals found
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PendingWithdrawals_t4_mobile