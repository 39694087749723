
const ControlDeLineas = ({ createConsorcios, setCreateConsorcios }) => {

    return (
        <div className="row" id='ConsorcioTlmtLiga'>
            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">Liga</th>
                                    <th scope="col">Limite de RL Minimo</th>
                                    <th scope="col">Limite de RL Maximo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    createConsorcios?.LimitRl?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.league.name}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={(item.minRl)}
                                                    onChange={(e) => {
                                                        setCreateConsorcios({
                                                            ...createConsorcios,
                                                            LimitRl: createConsorcios.LimitRl.map((item2, index2) => {
                                                                if (index2 === index) {
                                                                    return {
                                                                        ...item2,
                                                                        minRl: (e.target.value)
                                                                    }
                                                                } else {
                                                                    return item2
                                                                }
                                                            })
                                                        })
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={(item.maxRl)}
                                                    onChange={(e) => {
                                                        setCreateConsorcios({
                                                            ...createConsorcios,
                                                            LimitRl: createConsorcios.LimitRl.map((item2, index2) => {
                                                                if (index2 === index) {
                                                                    return {
                                                                        ...item2,
                                                                        maxRl: (e.target.value)
                                                                    }
                                                                } else {
                                                                    return item2
                                                                }
                                                            })
                                                        })
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ControlDeLineas