import React from 'react'

const Language = () => {
  return (
    <>
      <div className='d-flex flex-column gap-2 align-items-center'>
        <label htmlFor="Language" className='text-nowrap'>Select your language:</label>
        <select name="Language" id="Language">
          <option value="0">English</option>
          <option value="1">Español</option>
        </select>
      </div>
    </>
  )
}

export default Language