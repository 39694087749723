import React from 'react'

const Results = () => {
  return (
    <>
      <div className='d-flex gap-2 align-items-end'>
        <div>
          <label htmlFor="Date">Date:</label>
          <input type="text" name="Date" id="Date" defaultValue={new Date().toLocaleDateString()} />
        </div>
        <div>
          <label htmlFor="sport">Sport:</label>
          <select name="" id="">
            <option value="0">All</option>
          </select>
        </div>
        <div>
          <label htmlFor="League">League:</label>
          <select name="" id="">
            <option value="0">All</option>
          </select>
        </div>
        <div className='d-flex gap-2 align-items-center'>
          <button
            style={{
              backgroundColor: '#337ab7',
              color: '#fff',
              border: 'none',
              padding: '7px 17px',
              borderRadius: '5px',
            }}
          >
            Search
          </button>
          <button
            style={{
              backgroundColor: '#ff5733',
              color: '#fff',
              border: 'none',
              padding: '7px 17px',
              borderRadius: '5px'
            }}
          >
            Print
          </button>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center gap-1 mt-4'>
        <i class="fa fa-spin fa-cog"></i>
        {'Loading data, please wait...'}
      </div>
    </>
  )
}

export default Results