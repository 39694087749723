import moment from "moment";
import { parseTeamName, parseTeamOrPitcherName } from "../functions";
import { useGameStats } from "../hooks/useGameStats";

export const TableStatistics = ({league}) => {
  // console.log({data})

  const { 
    games,
    isLoading,
    periods,
    tableHeads,

    handleChangeBySort,
    handleChangeByStatus,
    handleChangeHeaders,
    loadGames,
   } = useGameStats(league);
  
  return (
    <>
      <div className="body row m-0 mb-2 gap-2 px-2 px-lg-4">
        <ul className="nav_container col d-flex justify-content-between">
          <li className="nav-item">
            <label>Periodo</label>
            <select
              className="form-select"
              onChange={ handleChangeHeaders }
            >
              {
                periods.map((item, index) => (
                  <option key={index} value={item.period}>{item.period}</option>
                ))
              }
              {/* <option value="full">Juego completo</option>
              <option value="firstHalf">1ra Mitad</option>
              <option value="secondHalf">2da Mitad</option>
              <option value="1erPeriodo">1er Periodo</option>
              <option value="2doPeriodo">2do Periodo</option>
              <option value="3erPeriodo">3er Periodo</option>
              <option value="1erQuarter">1er Quarter</option>
              <option value="2doQuarter">2do Quarter</option>
              <option value="3erQuarter">3er Quarter</option>
              <option value="4toQuarter">4to Quarter</option> */}
            </select>
          </li>
          <li className="nav-item">
            <label>Tipo de linea</label>
            <select
              className="form-select"
            >
              <option value="all">Todos</option>
              <option value="ML">ML</option>
              <option value="RL">RL</option>
              <option value="TL">TL</option>
              <option value="SRL">SRL</option>
              <option value="SiAnotaran">Si anotaran</option>
              <option value="NoAnotaran">No anotaran</option>
              <option value="PrimeraCarrera">Primera carrera</option>
              <option value="Ponche">Ponche</option>
              <option value="Solo">Solo</option>
              <option value="Jugadores">Jugadores</option>
              <option value="GSML">GS ML</option>
              <option value="GSTL">GS TL</option>
              <option value="Tercios">Tercios</option>
              <option value="Serie">Serie</option>
              <option value="R/LA">R/L A</option>
              <option value="Tercios TL">Tercios TL</option>
            </select>
          </li>
          <li className="nav-item d-flex gap-2">
            <button className="button" onClick={loadGames}>Refrescar</button>
          </li>
        </ul>
        <ul className="nav_container col d-flex justify-content-between">
          <li className="nav-item d-flex gap-2">
            <label
              className="form-check-label"
            >
              Status
            </label>
            <select
              className="form-select"
              aria-label="Filter by game status"
              onChange={handleChangeByStatus}
            >
              <option value="">Todos</option>
              <option value="pending">Pendiente</option>
              <option value="playing">En progreso</option>
              <option value="finished">Finalizado</option>
              <option value="canceled">Cancelado</option>
            </select>
          </li>
          <li className="nav-item d-flex gap-2">
            <label>Ordenamiento</label>
            <select 
              className="form-select"
              onChange={handleChangeBySort}
            >
              <option value="normal">Normal</option>
              <option value="time">Hora</option>
              <option value="ML">ML</option>
              <option value="RL">RL</option>
              {/* <option value="TL">TL</option> */}
              <option value="TL+">TL +</option>
              <option value="TL-">TL -</option>
              {/* <option value="s">Solo</option> */}
              <option value="s+">Solo +</option>
              <option value="s-">Solo -</option>
              <option value="away">Equipo away</option>
              <option value="home">Equipo home</option>
            </select>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="body">
            <div id='table-dinamic'>
              <table className="table table-striped table-hover w-100">
                <thead>
                  <tr>
                    <th colSpan={2}>
                        <div className='d-flex gap-4 px-3 align-items-center'>
                            <h4>Hora</h4>
                            <h4>Juegos Actuales</h4>
                        </div>
                    </th>
                    {
                        tableHeads.length === 0
                        ? (<th> <div style={{minHeight: "40px"}}></div> </th>)
                        : tableHeads.map((item, index) => (
                            <th key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>{item.name}</th>
                          ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoading
                    ? (<tr><td colSpan={tableHeads.length ? 2+ tableHeads.length : 3} className="text-center"><p className="pt-4">Cargando...</p></td></tr>)
                    : games.length === 0 
                      ? (<tr><td colSpan={tableHeads.length ? 2+ tableHeads.length : 3} className="text-center"></td></tr>)
                      : games.map((log, index) => (
                        <tr key={index}>
                          <td colSpan={2}>
                            <div className="d-flex gap-4">
                                <div className="d-flex flex-column ps-2">
                                    <div>
                                        {moment(log.time).format("hh:mm A")}
                                    </div>
                                </div>
                                <div 
                                    className={`d-flex flex-column px-2 games_container`}
                                >
                                    <div className="d-flex align-items-center gap-2">
                                        <span>
                                            {localStorage.getItem('userCodesTeams') == 'rd' ? log.localTeam.codeRD : log.localTeam.code}
                                        </span>
                                        {" - "}
                                        <span>
                                            { parseTeamOrPitcherName(log.localTeam.name, false, log.localPitcher) }
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <span>
                                            {localStorage.getItem('userCodesTeams') == 'rd' ? log.visitingTeam.codeRD : log.visitingTeam.code}
                                        </span>
                                        {" - "}
                                        <span>
                                            { parseTeamName(log.visitingTeam.name) }
                                        </span>
                                    </div>
                                </div>
                            </div>
                          </td>
                          {
                              tableHeads.length === 0
                              ? (<td className="text-center"><div className="d-flex justify-content-center align-items-center" style={{height: 50}}>No hay estadísticas para este período</div></td>)
                              : tableHeads.map((item, index) => (
                                  <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>
                                    <div className="d-flex flex-column align-items-center justify-content-around pt-2">
                                      <div>
                                        {log.statistics.localLine[item.property] || 0}
                                      </div>
                                      <div>
                                        {log.statistics.visitingLine[item.property] || 0}
                                      </div>
                                    </div>
                                  </td>
                                ))
                          }
                        </tr>
                      ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>


  );
};
