
const CompraVentaDePuntos = ({
    setCreateConsorcios,
    createConsorcios,
    setEditConsorcio,
    editConsorcio
}) => {


    const handleChanges = (e, index) => {
        const { name, value } = e.target
        let list = [...createConsorcios.buySellControl]
        list[index][name] = value

        setEditConsorcio({
            ...editConsorcio,
            buySellControl: list
        })

        setCreateConsorcios({
            ...createConsorcios,
            buySellControl: list
        })
    }

    return (
        <div className="row" id='ConsorcioTcompraVentaPuntos'>
            <div className="col-12">
                <h6 className='text-center h2 m-2'>Compra y Venta de Puntos</h6>
            </div>
            <div className="col-12" id='limiteporligas_table'>
                <table>
                    <thead>
                        <tr>
                            <th scope="col">Liga</th>
                            <th scope="col">(C) Limite de Linea RL</th>
                            <th scope="col">(C) Maximo Puntos Permitidos RL</th>
                            <th scope="col">(C) Maximo Puntos Permitidos + / -</th>
                            <th scope="col">(C) Tipo de Ticket</th>
                            <th scope="col">IIIII</th>
                            <th scope="col">(V) Limite de Linea RL</th>
                            <th scope="col">(V) Maximo Puntos Permitidos + / -</th>
                            <th scope="col">(V) Maximo Puntos Permitidos + / -</th>
                            <th scope="col">(V) Tipo de Ticket</th>
                            {/* <th scope="col">IIIIIII</th>s */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            createConsorcios?.buySellControl.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.league.name}</td>
                                        <td>
                                            <input
                                                type="number"
                                                name='buyLimitLineRl'
                                                defaultValue={item.buyLimitLineRl}
                                                onChange={e => handleChanges(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name='buyLimitRl'
                                                defaultValue={item.buyLimitRl}
                                                onChange={e => handleChanges(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name='buyLimitOverUnder'
                                                defaultValue={item.buyLimitOverUnder}
                                                onChange={e => handleChanges(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                name='buyLimitTicket'
                                                defaultValue={item.buyLimitTicket}
                                                onChange={e => handleChanges(e, index)}
                                                className="selectpicker"
                                            >
                                                <option value="Directo">Ninguno</option>
                                                <option value="Directo">Directo</option>
                                                <option value="Pale">Pale</option>
                                                <option value="Tripleta">Tripleta</option>
                                                <option value="Quarteta">Quarteta</option>
                                                <option value="Quinteta">Quinteta</option>
                                                <option value="Sexteta">Sexteta</option>
                                                <option value="Septeta">Septeta</option>
                                                <option value="Octeta">Octeta</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name='IIIII'
                                                disabled
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name='sellLimitLineRl'
                                                defaultValue={item.sellLimitLineRl}
                                                onChange={e => handleChanges(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name='sellLimitRl'
                                                defaultValue={item.sellLimitRl}
                                                onChange={e => handleChanges(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name='sellLimitOverUnder'
                                                defaultValue={item.sellLimitOverUnder}
                                                onChange={e => handleChanges(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                name='sellLimitTicket'
                                                defaultValue={item.sellLimitTicket}
                                                onChange={e => handleChanges(e, index)}
                                                className="selectpicker"
                                            >
                                                <option value="Directo">Ninguno</option>
                                                <option value="Directo">Directo</option>
                                                <option value="Pale">Pale</option>
                                                <option value="Tripleta">Tripleta</option>
                                                <option value="Quarteta">Quarteta</option>
                                                <option value="Quinteta">Quinteta</option>
                                                <option value="Sexteta">Sexteta</option>
                                                <option value="Septeta">Septeta</option>
                                                <option value="Octeta">Octeta</option>
                                            </select>
                                            {/* <input
                                                type="text"
                                                name='sellLimitTicket'
                                                defaultValue={item.sellLimitTicket}
                                                onChange={e => handleChanges(e, index)}
                                            /> */}
                                        </td>
                                        {/* <td>
                                            <input
                                                type="text"
                                                name='IIIIIII'
                                                value={item.IIIIIII}
                                                onChange={e => handleChanges(e, index)}
                                            />
                                        </td> */}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CompraVentaDePuntos