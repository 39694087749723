

import React, { useState } from 'react';
import './TableBody.css';
import { sendNewLine } from '../servicesLimitXconsorcio';


const TableBody = ({ headerCasillas, datosCasillas, isCasillaDisabled, getLimit, periods, consorcio  }) => {
  const casillasArray = Array.isArray(datosCasillas) ? datosCasillas : [datosCasillas];
  const [inputValue, setInputValues] = useState({ limits: [] });

  // const isNumberOrOperator = (key) => {
  //   // Verifica si la tecla presionada es un número o uno de los operadores permitidos
  //   return /^[0-9+-]$/.test(key);
  // };

  // const handleInputKeyPress = (e) => {
  //   const key = e.key;

  //   // Verifica si la tecla presionada es válida
  //   if (!isNumberOrOperator(key)) {
  //     e.preventDefault();
  //   }
  // };

  return (
    <tbody>
      {casillasArray.map((row, rowi) => {
        return (
          row.limits.map((limit, i) => {
            // console.log("limit: ", limit.limits);
            return (
              <tr key={i}>
                <td className='casilla-body-limit'>{limit.league.name}</td>
                {periods.map((casilla, casillai) => {

                  const handleInputChange = (casillai, newTextContent, limit) => {
                    setInputValues((prevValues) => {
                      const prevLimits = Array.isArray(prevValues.limits) ? prevValues.limits : [];
                      const editedName = casillai.replace(/[+-]/g, '').trim()

                      const existingLeagueIndex = prevLimits.findIndex(
                        (item) => item.league === limit.league._id
                      );

                      const updatedLimits = existingLeagueIndex !== -1
                        ? prevLimits.map((item, index) => {
                          if (index === existingLeagueIndex) {
                            const existingLimitIndex = item.limits.findIndex((limitItem) => limitItem.name === editedName);

                            const updatedLimitsArray = existingLimitIndex !== -1
                              ? item.limits.map((limitItem) => {
                                if (limitItem.name === editedName) {
                                  return {
                                    ...limitItem,
                                    values: {
                                      min: casillai.includes('-') ? newTextContent : (limitItem.values?.min || ""),
                                      max: casillai.includes('-') ? (limitItem.values?.max || "") : newTextContent,
                                    },
                                  };
                                }
                                return limitItem;
                              })
                              : [
                                ...item.limits,
                                {
                                  name: editedName,
                                  values: {
                                    min: casillai.includes('-') ? newTextContent : "",
                                    max: casillai.includes('-') ? "" : newTextContent,
                                  },
                                },
                              ];

                            return {
                              ...item,
                              limits: updatedLimitsArray,
                            };
                          }
                          return item;
                        })
                        : [
                          ...prevLimits,
                          {
                            league: limit.league._id,
                            limits: [
                              {
                                name: editedName,
                                values: {
                                  min: casillai.includes('-') ? newTextContent : "",
                                  max: casillai.includes('-') ? "" : newTextContent,
                                },
                              },
                            ],
                          },
                        ];

                      return {
                        ...prevValues,
                        limits: updatedLimits,
                      };
                    });
                  };



                  const disabled = isCasillaDisabled(limit.league.name, casilla);

                  const handleSendData = async () => {
                    try {
                      await sendNewLine({ limits: inputValue.limits, consorcio  });
                      getLimit();
                    } catch (error) {
                      console.log("Error al enviar los datos: ", error);
                    }
                  };

                  const handleKeyPress = (event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleSendData();    
                      event.target.blur();             
                    }
                  };

                  let casillaValue = "0"

                  limit.limits.map((value) => {
                    let casillaName = casilla.replace(/[+|-]/g, '').trim();
                    if (casillaName == value.name) {
                      casillaValue = casilla.includes('-') ? (value.values.min.includes('+') ? value.values.min.replace('+', '-') : value.values.min || 0) : (value.values.max.includes('-') ? value.values.max.replace('-','+') : value.values.max || 0);
                    }
                  })

                  const sign = casilla.match(/[+-]/)[0]
                  return (
                    <td
                      key={casillai}
                      // className='casilla-body'
                      className={`casilla-body-limit ${disabled ? 'disabled' : ''}`}
                      contentEditable={!disabled}
                      dangerouslySetInnerHTML={{ __html: casillaValue }}
                      style={{
                        width: '36px',
                        height: '100%',
                        backgroundColor: 'red',
                        color: 'white',
                        outline: 'none',
                        fontSize: '12px',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        backgroundColor: disabled ? 'gray' : 'red',
                        disabled: true
                      }}
                      onInput={(e) => {
                        const newTextContent = e.currentTarget.textContent || '';
                        // Filtrar caracteres no deseados (dejar solo números, + y -)
                        const filteredText = newTextContent.replace(/[^0-9]/g, '');
                        let text = `${sign}${filteredText}`
                        // Actualizar el contenido solo si la entrada es válida
                        if (newTextContent !== filteredText) {
                          e.currentTarget.textContent = filteredText;
                        }
                        handleInputChange(casilla, text, limit);
                      }}
                      onClick={(event) => {
                        if (event.currentTarget.textContent === '0') {
                          event.currentTarget.textContent = ''
                        } else if (event.currentTarget.textContent.match(/[+-]/)) {
                          //Soluciona el error de al borrar por primera vez el cursor se posiciona al principio
                          // event.currentTarget.textContent = ''
                        } 

                      }}
                      onBlur={(event) => {
                        if (event.currentTarget.textContent === '') {
                          event.currentTarget.textContent = '0'
                        }   
                      }}
                      onKeyDown={(e) => {
                        // Verificar si la tecla presionada es válida (opcional)
                        const key = e.key;
                        // if (!/^[0-9+-]$/.test(key) && key !== 'Backspace') {
                        //   e.preventDefault();
                        // }
                        // Puedes agregar más lógica según sea necesario
                        handleKeyPress(e);
                      }}
                      
                    >
                      {/* {casillaValue} */}
                    </td>
                  );
                })}
              </tr>
            );
          })
        );
      })}
    </tbody>
  );
};

export default TableBody;
