import React, { useState } from 'react'
import { Modal } from 'antd'
import SalesReport from './SalesReport'
import Moneytransactions from './Moneytransactions'
import TicketStatus from './TicketStatus'
import TransactionOptions from './TransactionOptions'
import Tickets from './Tickets'
import NewAccount from './NewAccount'
import MyClients from './MyClients'
import Pendingdeposits from './Pendingdeposits'
import Pendingwithdrawals from './PendingWithdrawals'
import ShowTicket from './ShowTicket'
import WinningTickets from './WinningTickets'
import { CashOutTicket } from './CashOutTicket'
import CopyTicket from './CopyTicket'
import CanccelTicket from './CanccelTicket'
import TicketSettings from './TicketSettings'
import ParlayCalculator from './ParlayCalculator'
import QuickBet from './QuickBet'
import Results from './Results'
import Language from './Language'
import ChangePassword from './ChangePassword'
import HelpModal from './HelpModal'
import { redApi } from '../../../../../config/Axios'
import ChangeTheme from './ChangeTheme'
import Swal from 'sweetalert2'
const ModalDinamic = ({
  open,
  setOpen,
  modalToggle,
  view,
}) => {

  const [formData, setFormData] = useState({
    password: "",
    confirPassword: ""
  })
  const handleSubmit = () => {
    if (formData.password !== formData.confirPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Passwords do not match!',
      })
      return
    }
    redApi.put("/users/change/password", formData).then(res => {
      Swal.fire({
        icon: 'success',
        title: 'Password changed',
        showConfirmButton: false,
        timer: 1500
      })
      modalToggle('')

    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    })
  }
  const RenderSwitch = () => {
    switch (view) {
      case 'Sales Report':
        return <SalesReport />
      case 'Money transactions':
        return <Moneytransactions />
      case 'Ticket Status':
        return <TicketStatus />
      case 'Transaction Options':
        return <TransactionOptions />
      case 'Tickets':
        return <Tickets />
      case 'New Account':
        return <NewAccount data={data} setData={setData} />
      case 'My Clients':
        return <MyClients />
      case 'Pending deposits':
        return <Pendingdeposits />
      case 'Pending withdrawals':
        return <Pendingwithdrawals />
      case 'Show Ticket':
        return <ShowTicket />
      case 'Winning Tickets':
        return <WinningTickets />
      case 'Cash Out Ticket':
        return <CashOutTicket />
      case 'Copy Ticket':
        return <CopyTicket />
      case 'Cancel ticket':
        return <CanccelTicket />
      case 'Ticket Settings':
        return <TicketSettings />
      case 'Parlay Calculator':
        return <ParlayCalculator />
      case 'Quick Bet':
        return <QuickBet />
      case 'results':
        return <Results />
      case 'Language':
        return <Language />
      case 'Change Theme':
        return <ChangeTheme handleChangeTheme={handleChangeTheme} />
      case 'Printers':
        return <div>Printers</div>
      case 'Help':
        return <HelpModal />
      default:
        return <div>Default</div>
    }
  }

  const [userTheme, setUserTheme] = useState(1);

  const handleChangeTheme = (e) => {
    setUserTheme(e.target.value);
  };

  const handleOk = async () => {
    await redApi.get("/verify").then(async (res) => {
      console.log(res.data);
      if (res.data) {
        if (res.data._id) {
          await redApi
            .put(`/users/${res.data._id}`, { theme: userTheme })
            .then((res) => {
              Swal.fire({
                icon: "success",
                title: "Theme changed",
                showConfirmButton: false,
                timer: 1500,
              });
              setTimeout(() => {
                window.location.reload();
                modalToggle("");
              }, 1500);
            });
        }
      }
    });
  };

  const [data, setData] = useState({
    username: '',
    password: '',
    email: '',
    isGroup: null,
    ownedBy: '',
    theme: 1
  })
  const createUser = async () => {
    if (!data.username || !data.password || !data.isGroup) {
      console.log(data)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Todos los campos son obligatorios!',
      })
      return
    }
    await redApi.post(`/users/webUser`, data)
      .then(res => {
        console.log(res.data)
        Swal.fire({
          icon: 'success',
          title: 'Usuario creado',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(err => console.log(err));
  }

  return (
    <Modal
      title={view}
      open={open}
      onOk={() => modalToggle('')}
      onCancel={() => modalToggle('')}
      style={{ top: 20 }}
      width={view === 'New Account' ? 600 : 1000}
      className={`modal-dinamic-t4 ${view === 'New Account' ? 'modal-dinamic-t4-new-account' : ''}`}
      footer={view === 'New Account' ? (
        <>
          <button className='btn-footer btn-success' onClick={() => { modalToggle(''); createUser() }}>
            <i className="fas fa-save"></i>
            Create
          </button>
          <button className='btn-footer btn-danger' onClick={() => modalToggle('')}>
            <i className="fas fa-trash-alt"></i>
            Cancel
          </button>
        </>
      )
        : view === 'Change Theme' ? (
          <>
            <button className='btn-footer btn-success' onClick={handleOk}>
              <i className="fas fa-save"></i>
              Save
            </button>
            <button className='btn-footer btn-danger' onClick={() => modalToggle('')}>
              <i className="fas fa-trash-alt"></i>
              Cancel
            </button>
          </>
        )
            :
            (
              <>
                <button className='btn-footer btn-success' onClick={() => modalToggle('')}>Ok</button>
                <button className='btn-footer btn-danger' onClick={() => modalToggle('')}>Cancel</button>
              </>
            )
      }
    >
      {RenderSwitch()}
    </Modal>
  )
}

export default ModalDinamic