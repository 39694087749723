import moment from 'moment'
import React, { useEffect } from 'react'

const TransactionOptions = () => {


  return (
    <>
      <div className='d-flex pt-3' style={{ borderTop: '1px solid #e5e5e5' }}>
        <div>
          <div>
            <label htmlFor="operationType">
              Operation Type:
            </label>
            <select name="operationType" id="operationType">
              <option value="0">Deposit</option>
              <option value="1">Withdraw</option>
              <option value="2">Transfer</option>
              <option value="3">Cash Box</option>
            </select>
          </div>
          <div>
            <label htmlFor="operationType">
              Customer:
            </label>
            <select name="operationType" id="operationType">

            </select>
          </div>
        </div>
        <div className='sales-report-date' style={{ border: 'none' }}>
          <div>
            <input type="date" data-date="" data-date-format="DD MMMM YYYY" defaultValue={moment().startOf('day').format("YYYY-MM-DD")} />
            <span>
              to
            </span>
            <input type="date" data-date="" data-date-format="DD MMMM YYYY" defaultValue={moment().endOf('day').format("YYYY-MM-DD")} />
          </div>
          <div className='sales-report-tables  mt-3'>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>
                      id
                    </th>
                    <th>
                      date
                    </th>
                    <th>
                      time
                    </th>
                    <th>
                      customer
                    </th>
                    <th>
                      amount
                    </th>
                    <th>
                      description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      No data available in table
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      -
                    </td>
                    <td>
                      -
                    </td>
                    <td>
                      TOTAL
                    </td>
                    <td>
                      $0.00
                    </td>
                    <td>
                      -
                    </td>
                    <td>
                      -
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionOptions