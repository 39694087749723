import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { redApi } from '../../../config/Axios';


export default function LeftContainer({ liveGames }) {
  const { league } = useParams();
  useEffect(() => {
    setLeagueActive(league);
  }, [league]);
  const [leagueActive, setLeagueActive] = useState('');
  const [menuItems, setMenuItems] = useState([]);

  const getLeagues = async () => {
    try {
      const { data: leaguesWithGames } = await redApi.get('/leagues?leaguesWithGames=true');
      if (leaguesWithGames && leaguesWithGames.length > 0) {
        const formattedImages = leaguesWithGames.map(league => {
          switch (league.name) {
            case 'NBA':
              league.image = '/logos/NBA.png';
              break;
            case 'MLB':
              league.image = '/logos/MLB.png';
              break;
            case 'NHL':
              league.image = '/logos/NHL.png';
              break;
            case 'Dominican':
              league.image = '/logos/Dominican.png';
              break;
            case 'WNBA':
              league.image = '/logos/WNBA.png';
              break;
            case 'NCAAF':
              league.image = '/logos/NCAAF.png';
              break;
            case 'NFL':
              league.image = '/logos/NFL.png';
              break;
            case 'UFC':
              league.image = '/logos/UFC.png';
              break;
            case 'Boxing':
              league.image = '/logos/boxing.png';
              break;
            case 'NCAAB':
              league.image = '/logos/NCAAB.png';
              break;
            case 'UEFA Nations':
              league.image = '/logos/UEFA_Nations.png';
              break;
            case 'NPB':
              league.image = '/logos/NPB.png';
              break;
            case 'CONCACAF':
              league.image = '/logos/CONCACAF.png';
              break;
            case 'Libertador':
              league.image = '/logos/conmebollibertadores.png';
              break;
            case 'NBA-S':
              league.image = '/logos/NBA-S.png';
              break;
            case 'Belgium':
              league.image = '/logos/Belgium.png';
              break;
            case 'England':
              league.image = '/logos/England.png';
              break;
            case 'France':
              league.image = '/logos/France.png';
              break;
            case 'Germany':
              league.image = '/logos/Germany.png';
              break;
            case 'MLS':
              league.image = '/logos/MLS.png';
              break;
            case 'Netherland':
              league.image = '/logos/Netherland.png';
              break;
            case 'BOX/UFC':
              league.image = '/logos/BOX_UFC.png';
            default:
              if (!league.image || league.image.includes("http://")) league.image = "/logos/logodefault.png";
              break;
          }
          return league;
        })
        setMenuItems(formattedImages)
      }
      
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getLeagues();
  }, []);
  return (
    <div className="row m-0 d-flex left-wrapper" style={{ height: '100%' }}>
      {/* side nav */}
      <div className="bg-left-side col-custom-3 p-0 px-1 pb-4 d-flex">

        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
          {/* <li>
            <NavLink exact className="nav-item-side" activeClassName="active" to="/client/MLB" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <span className='side-sport-icon'>
                <img src={logoMLB} alt="MLB" />
              </span>
            </NavLink>
          </li> */}
          {
            menuItems.length > 0 ? (
              menuItems?.map((item, index) => {
                return (
                  <li key={index}>
                    <NavLink key={index} to={`/client/${item.name.replaceAll('/', '_').replaceAll(' ', '%20')}`}
                      className={`nav-item-side ${leagueActive === item.name ? 'active' : ''}`}
                      onClick={() => setLeagueActive(item.name)}>
                      <span className='side-sport-icon'>
                        <img src={item.image} alt={item.name} />
                      </span>
                    </NavLink>
                  </li>
                )
              })
            )
              :
              (
                <li>
                  No hay ligas con juegos
                </li>
              )
          }
          {/* 
          <li>
            <NavLink exact className="nav-item-side" activeClassName="active" to="/client/Dominican" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <span className='side-sport-icon'>
                <img src={logoDominican} alt="Dominican" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink exact className="nav-item-side" activeClassName="active" to="/client/NBA" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <span className='side-sport-icon'>
                <img src={logoNBA} alt="NBA" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink exact className="nav-item-side" activeClassName="active" to="/client/WNBA" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <span className='side-sport-icon'>
                <img src={logoWNBA} alt="WNBA" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink exact className="nav-item-side" activeClassName="active" to="/client/NCAAF" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <span className='side-sport-icon'>
                <img src={logoNCAAF} alt="NCAAF" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink exact className="nav-item-side" activeClassName="active" to="/client/Soccer" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <span className='side-sport-icon'>
                <img src={logoSoccer} alt="Soccer" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink exact className="nav-item-side" activeClassName="active" to="/client/NFL" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <span className='side-sport-icon'>
                <img src={logoNFL} alt="NFL" />
              </span>
            </NavLink>
          </li>

          <li>
            <NavLink exact className="nav-item-side" activeClassName="active" to="/client/NHL" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <span className='side-sport-icon'>
                <img src={logoNHL} alt="NHL" />
              </span>
            </NavLink>
          </li>

          <li>
            <NavLink exact className="nav-item-side" activeClassName="active" to="/client/UFC" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <span className='side-sport-icon'>
                <img src={logoUFC} alt="UFC" />
              </span>
            </NavLink>
          </li>

          <li>
            <NavLink exact className="nav-item-side" activeClassName="active" to="/client/Boxing" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <span className='side-sport-icon'>
                <img src={logoBoxing} alt="Boxing" />
              </span>
            </NavLink>
          </li>

          <li>
            <NavLink exact className="nav-item-side" activeClassName="active" to="/client/Polo" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
              <span className='side-sport-icon'>
                <img src={logoPolo} alt="Polo" />
              </span>
            </NavLink>
          </li> */}

        </ul>
      </div>
      {/* end icon */}
      <div id="upcomingMatches" className="live-match-wrapper live-bg col-custom-9 d-flex flex-column" >
        <div className="col-12">
          <a href="#" className="list-group-item list-grp-new text-decoration-none">
            <span className="d-block smatch-title fw-semibold">Upcoming Matches</span>
            <span className="d-block sub-upcoming">IN PLAY & LIVE STREAMING</span>
          </a>
        </div>
        {/* tabs start */}
        <div className="col-12">
          <ul className="nav match-tabs nav-tabs" role="tablist">
            <li className="nav-item">
              <button className="nav-link active" id="highlights-tab" data-bs-toggle="tab" data-bs-target="#highlights">Highlights</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" id="now-tab" data-bs-toggle="tab" data-bs-target="#now">Now</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" id="stream-tab" data-bs-toggle="tab" data-bs-target="#stream">Stream</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" id="all-tab" data-bs-toggle="tab" data-bs-target="#all">All</button>
            </li>
          </ul>
        </div>
        <div className="col-12">

          <div className="tab-content" id="matchTabContent">
            <div className="tab-pane fade show active" id="highlights" role="tabpanel" aria-labelledby="highlights-tab">
              <div className="list-group list-group-flush scrollarea">
                {
                  liveGames.map((item, index) => (
                    <div key={index} className="list-group-item list-group-item-action list-grp-new lh-tight"
                      aria-current="true">
                      <div className="row d-flex text-white align-items-center justify-content-between">
                        <div className="left-match-text col-8 p-0">
                          <strong className="smatch-name">{item.localTeam?.name} - {item.visitingTeam?.name}</strong>
                          <span className="team-name">USA - {item.league?.name}</span>
                          <span className="team-text-now">Now</span>
                        </div>
                        <div className="live-btn col-4">
                          <small className="live-text-btn">Live</small>
                        </div>
                      </div>
                    </div>
                  ))
                }

              </div>
            </div>
            <div className="tab-pane fade" id="now" role="tabpanel" aria-labelledby="now-tab">Now</div>
            <div className="tab-pane fade" id="stream" role="tabpanel" aria-labelledby="stream-tab">Stream</div>
            <div className="tab-pane fade" id="all" role="tabpanel" aria-labelledby="all-tab">

              <ul className="row">
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#ussie-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    AUSSIE RULES
                  </button>
                  <div className="collapse" id="ussie-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">AFL</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#baseball-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    BASEBALL
                  </button>
                  <div className="collapse" id="baseball-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">MLB</a></li>
                      <li><a href="#" className="link-dark rounded">DOMINICAN</a></li>
                      <li><a href="#" className="link-dark rounded">KBO</a></li>
                      <li><a href="#" className="link-dark rounded">Minor League Baseball</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#basketball-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    BASKETBALL
                  </button>
                  <div className="collapse" id="basketball-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">NBA</a></li>
                      <li><a href="#" className="link-dark rounded">WNBA</a></li>
                      <li><a href="#" className="link-dark rounded">College Basketball</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#boxing-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    BOXING
                  </button>
                  <div className="collapse" id="boxing-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">BOXING</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#cricket-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    CRICKET
                  </button>
                  <div className="collapse" id="cricket-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">The Hundred</a></li>
                      <li><a href="#" className="link-dark rounded">Test Matches</a></li>
                      <li><a href="#" className="link-dark rounded">One Day Internationals</a></li>
                      <li><a href="#" className="link-dark rounded">IPL</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#cycling-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    CYCLING
                  </button>
                  <div className="collapse" id="cycling-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">Tour de France</a></li>
                      <li><a href="#" className="link-dark rounded">Vuelta a Españna</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#darts-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    DARTS
                  </button>
                  <div className="collapse" id="darts-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">PDC World Championship</a></li>
                      <li><a href="#" className="link-dark rounded">Online Live League</a></li>
                      <li><a href="#" className="link-dark rounded">European Tour 10</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#football-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    FOOTBALL
                  </button>
                  <div className="collapse" id="football-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">College Football</a></li>
                      <li><a href="#" className="link-dark rounded">NFL</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#golf-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    GOLF
                  </button>
                  <div className="collapse" id="golf-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">Euro Tour - Himmeland</a></li>
                      <li><a href="#" className="link-dark rounded">Ryder Cup</a></li>
                      <li><a href="#" className="link-dark rounded">Presidents Cup</a></li>
                      <li><a href="#" className="link-dark rounded">The Masters</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#handball-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    HANDBALL
                  </button>
                  <div className="collapse" id="handball-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">Swedish - Elitserie</a></li>
                      <li><a href="#" className="link-dark rounded">Champions League</a></li>
                      <li><a href="#" className="link-dark rounded">France - D1</a></li>
                      <li><a href="#" className="link-dark rounded">Denmark - Handboldligaen</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#hockey-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    HOCKEY
                  </button>
                  <div className="collapse" id="hockey-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">NHL</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#lacrosse-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    LACROSSE
                  </button>
                  <div className="collapse" id="lacrosse-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">College Lacrosse</a></li>
                      <li><a href="#" className="link-dark rounded">Premier Lacrosse League</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#mma-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    MMA
                  </button>
                  <div className="collapse" id="mma-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">UFC</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#motorsports-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    MOTORSPORTS
                  </button>
                  <div className="collapse" id="motorsports-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">NASCAR Cup Series</a></li>
                      <li><a href="#" className="link-dark rounded">NASCAR Xfinity</a></li>
                      <li><a href="#" className="link-dark rounded">IndyCar Series</a></li>
                      <li><a href="#" className="link-dark rounded">F1 - Dutch GP</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#rugby-league-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    RUGBY LEAGUE
                  </button>
                  <div className="collapse" id="rugby-league-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">NRL</a></li>
                      <li><a href="#" className="link-dark rounded">Super League</a></li>
                      <li><a href="#" className="link-dark rounded">World Cup</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#rugby-union-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    RUGBY UNION
                  </button>
                  <div className="collapse" id="rugby-union-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">The Rugby Championship</a></li>
                      <li><a href="#" className="link-dark rounded">France - Top 14</a></li>
                      <li><a href="#" className="link-dark rounded">England - Premiership</a></li>
                      <li><a href="#" className="link-dark rounded">United Rugby Championship</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#snooker-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    SNOOKER
                  </button>
                  <div className="collapse" id="snooker-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">World Championship</a></li>
                      <li><a href="#" className="link-dark rounded">The Masters</a></li>
                      <li><a href="#" className="link-dark rounded">UK Championship</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#soccer-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    SOCCER
                  </button>
                  <div className="collapse" id="soccer-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">Spain - La Liga</a></li>
                      <li><a href="#" className="link-dark rounded">Germany - Bundesliga</a></li>
                      <li><a href="#" className="link-dark rounded">Mexico - Liga MX</a></li>
                      <li><a href="#" className="link-dark rounded">England - Championship</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#table-tennis-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    TABLE TENNIS
                  </button>
                  <div className="collapse" id="table-tennis-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">WTT Feeder Panagyurishte Mixed Doubles</a></li>
                    </ul>
                  </div>
                </li>
                <li className="mb-1 col-12">
                  <button className="btn btn-toggle align-items-center rounded collapsed d-flex p-0 mb-2" data-bs-toggle="collapse" data-bs-target="#tennis-collapse" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    TENNIS
                  </button>
                  <div className="collapse" id="tennis-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a href="#" className="link-dark rounded">US - Open (M)</a></li>
                      <li><a href="#" className="link-dark rounded">US - OPEN (Y)</a></li>
                      <li><a href="#" className="link-dark rounded">US Open - Doubles (M)</a></li>
                      <li><a href="#" className="link-dark rounded">US Open - Doubles (Y)</a></li>
                    </ul>
                  </div>
                </li>
              </ul>

            </div>
          </div>

        </div>
        {/* tab ends */}
      </div>
    </div>
  )
}
