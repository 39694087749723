import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Input, Select, message } from 'antd'
import General from './consorcioTablas/General'
import LimitesGral from './consorcioTablas/LimitesGral'
import LimitesLigas from './consorcioTablas/LimitePorLiga'
import CompraVenta from './consorcioTablas/CompraVentaDePuntos'
import MensajeTicket from './consorcioTablas/MsjDelTicket'
import PreciosSolos from './consorcioTablas/PreciosSolo'
import PreciosPonches from './consorcioTablas/PreciosPonches'
import LimiteHembras from './consorcioTablas/LimiteDeHembras'
import ControlLineas from './consorcioTablas/ControlDeLineas'
import { redApi } from '../../../../config/Axios';
import Licencias from './consorcioTablas/Licencias'
import Swal from 'sweetalert2'
import "./consorcioTablas/consorcio.scss"


const ModalConsorcio = ({
  setModalEditar, ponches, userLevel, setModalCrear, modalEditar, lineLimitRlDefault, modalCrear, consorcioSeleccionado, setConsorcioSeleccionado, getConsorcios, setConsorcio, leagueLimits, buySellControl }) => {

  const handleOk = () => {
    if (modalEditar) {
      setModalEditar(false)
      editarConsorcio();
    } else if (modalCrear) {
      setModalCrear(false)
      createConsorcio(createConsorcios)
    }
  }

  const handleCancel = () => {
    if (modalEditar) {
      setModalEditar(false)
    } else if (modalCrear) {
      setModalCrear(false)
    }
  }

  const createConsorcio = async (consorcio) => {
    try {
      await redApi.post('users', consorcio)
        .then(async (res) => {
          getConsorcios()
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Consorcio creado con exito',
            showConfirmButton: false,
            timer: 1500
          })
        })
    } catch (error) {
      console.log(error)
    }
  }

  const renderMenu = () => {
    let menu = ['General', 'Limites Gral', 'Limites por Ligas', 'Compra/Venta de puntos', 'Mensaje del Ticket', 'Precios (Solos)', 'Precios (Ponches)', 'Limite de Hembras', 'Control de Lineas']

    return menu.map((item, index) => {
      return (
        <li key={index} className="nav-item">
          <button value={item} onClick={handleTable} className={`nav-buttons ${table === item ? 'active' : ''}`}>{item}</button>
        </li >
      )
    })
  }
  const [table, setTable] = useState('General')

  const handleTable = (e) => {
    setTable(e.target.value)
  }
  const renderTable = () => {
    switch (table) {
      case 'General':
        return <General userLevel={userLevel} setCreateConsorcios={setCreateConsorcios} createConsorcios={createConsorcios} modalEditar={modalEditar} consorcioSeleccionado={consorcioSeleccionado} setConsorcioSeleccionado={setConsorcioSeleccionado} getConsorcios={getConsorcios} setEditConsorcio={setEditConsorcio} editConsorcio={editConsorcio} />
      case 'Limites Gral':
        return <LimitesGral setCreateConsorcios={setCreateConsorcios} createConsorcios={createConsorcios} consorcioSeleccionado={consorcioSeleccionado} />
      case 'Limites por Ligas':
        return <LimitesLigas setCreateConsorcios={setCreateConsorcios} createConsorcios={createConsorcios} />
      case 'Compra/Venta de puntos':
        return <CompraVenta setCreateConsorcios={setCreateConsorcios} createConsorcios={createConsorcios} setEditConsorcio={setEditConsorcio} editConsorcio={editConsorcio} />
      case 'Mensaje del Ticket':
        return <MensajeTicket createConsorcios={createConsorcios} setCreateConsorcios={setCreateConsorcios} />
      case 'Precios (Solos)':
        return <PreciosSolos createConsorcios={createConsorcios} setCreateConsorcios={setCreateConsorcios} />
      case 'Precios (Ponches)':
        return <PreciosPonches setCreateConsorcios={setCreateConsorcios} createConsorcios={createConsorcios} />
      case 'Limite de Hembras':
        return <LimiteHembras setCreateConsorcios={setCreateConsorcios} createConsorcios={createConsorcios} />
      case 'Control de Lineas':
        return <ControlLineas setCreateConsorcios={setCreateConsorcios} createConsorcios={createConsorcios} />
      default:
        return <General />
    }
  }

  const [priceSingle, setPriceSingle] = useState(consorcioSeleccionado?.priceSingle ? consorcioSeleccionado.priceSingle : [])
  const [createConsorcios, setCreateConsorcios] = useState({
    username: modalEditar ? consorcioSeleccionado.username : '',
    password: '',
    userLevel: 2,
    group: 'Consorcio',
    email: modalEditar ? consorcioSeleccionado.email : '',
    theme: modalEditar ? consorcioSeleccionado.theme : 1,
    description: modalEditar ? consorcioSeleccionado.description : '',
    playTime: modalEditar ? consorcioSeleccionado.playTime : '08:00',
    playTime2: modalEditar ? consorcioSeleccionado.playTime2 : '23:50',
    cancellTicket: modalEditar ? consorcioSeleccionado.cancellTicket : 5,
    tieAllowed: modalEditar ? consorcioSeleccionado.tieAllowed : true,
    multPlays: modalEditar ? consorcioSeleccionado.multPlays : -110,
    maxClients: modalEditar ? consorcioSeleccionado.maxClients : 1000,
    minSell: modalEditar ? consorcioSeleccionado.minSell : 10,
    hembraLimit: modalEditar ? consorcioSeleccionado.hembraLimit : 15,
    leagues: modalEditar ? consorcioSeleccionado.leagues : [],
    limits: {
      ticketPlays: modalEditar ? consorcioSeleccionado.limits.ticketPlays : 12,
      direct: modalEditar ? consorcioSeleccionado.limits.direct : 100000,
      pale: modalEditar ? consorcioSeleccionado.limits.pale : 100000,
      triplet: modalEditar ? consorcioSeleccionado.limits.triplet : 100000,
      quatrain: modalEditar ? consorcioSeleccionado.limits.quatrain : 100000,
      quintet: modalEditar ? consorcioSeleccionado.limits.quintet : 100000,
      sextet: modalEditar ? consorcioSeleccionado.limits.sextet : 100000,
      septet: modalEditar ? consorcioSeleccionado.limits.septet : 100000,
      octet: modalEditar ? consorcioSeleccionado.limits.octet : 100000,
      ninth: modalEditar ? consorcioSeleccionado.limits.ninth : 100000,
      tenth: modalEditar ? consorcioSeleccionado.limits.tenth : 100000,
      eleventh: modalEditar ? consorcioSeleccionado.limits.eleventh : 100000,
      twelfth: modalEditar ? consorcioSeleccionado.limits.twelfth : 100000,
      thirteenth: modalEditar ? consorcioSeleccionado.limits.thirteenth : 100000,
      fourteenth: modalEditar ? consorcioSeleccionado.limits.fourteenth : 100000,
      fifteenth: modalEditar ? consorcioSeleccionado.limits.fifteenth : 100000,
      sixteenth: modalEditar ? consorcioSeleccionado.limits.sixteenth : 100000,
      t2: modalEditar ? consorcioSeleccionado.limits.t2 : 1000,
      t3: modalEditar ? consorcioSeleccionado.limits.t3 : 2000,
      t4: modalEditar ? consorcioSeleccionado.limits.t4 : 3000,
      ifMove: modalEditar ? consorcioSeleccionado.limits.ifMove : 100000,
      ticketPlaysIF: modalEditar ? consorcioSeleccionado.limits.ticketPlaysIF : 6,
    },
    hembraLimits: modalEditar ? consorcioSeleccionado.hembraLimits : [
      {
        all: 16,
        ticketType: [
          {
            betsNumber: 1,
            value: 16
          },
          {
            betsNumber: 2,
            value: 16
          },
          {
            betsNumber: 3,
            value: 16
          },
          {
            betsNumber: 4,
            value: 16
          },
          {
            betsNumber: 5,
            value: 16
          },
          {
            betsNumber: 6,
            value: 16
          },
          {
            betsNumber: 7,
            value: 16
          },
          {
            betsNumber: 8,
            value: 16
          },
          {
            betsNumber: 9,
            value: 16
          },
          {
            betsNumber: 10,
            value: 16
          },
          {
            betsNumber: 11,
            value: 16
          },
          {
            betsNumber: 12,
            value: 16
          },
          {
            betsNumber: 13,
            value: 16
          },
          {
            betsNumber: 14,
            value: 16
          },
          {
            betsNumber: 15,
            value: 16
          },
          {
            betsNumber: 16,
            value: 16
          },
        ]
      }
    ],
    ticketMessage: modalEditar ? consorcioSeleccionado.ticketMessage : {
      mensaje1: "Revice su ticket al recibirlo",
      mensaje2: "No se paga error de linea.",
      mensaje3: "No pagamos jugadas en escalera",
      mensaje4: "No se paga sin ticket y jugada empezada"
    },
    LimitRl: modalEditar && consorcioSeleccionado.LimitRl.length > 0 ? consorcioSeleccionado.LimitRl : lineLimitRlDefault,
    leagueLimits: modalEditar && consorcioSeleccionado.leagueLimits?.length > 0 ? consorcioSeleccionado.leagueLimits : leagueLimits,
    buySellControl: modalEditar && consorcioSeleccionado.buySellControl.length > 0 ? consorcioSeleccionado.buySellControl : buySellControl,
    devolutions: modalEditar && consorcioSeleccionado.devolutions ? consorcioSeleccionado.devolutions : false,
    language: modalEditar && consorcioSeleccionado.language ? consorcioSeleccionado.language : 'es',
    priceSingle: priceSingle,
    ponches: modalEditar && consorcioSeleccionado.ponches ? consorcioSeleccionado.ponches : ponches,
    codesToUse: modalEditar && consorcioSeleccionado.codesToUse ? consorcioSeleccionado.codesToUse : "Legacy",
  })
  const [editConsorcio, setEditConsorcio] = useState({})
  useEffect(() => {
    if (!modalEditar) return;
    // setEditConsorcio(consorcioSeleccionado)
  }, [modalEditar])
  useEffect(() => {
    if (!modalCrear) return;
    if (createConsorcios.priceSingle.length === 0) {
      redApi.get(`/linesConfig/priceSolos`).then((res) => {
        setCreateConsorcios({
          ...createConsorcios,
          priceSingle: [
            {
              "_id": "64c99fcf3e80542cf3d019d3",
              "league": {
                "_id": "6447e2b729d06c3563c0f5e1",
                "name": "MLB"
              },
              "status": false,
              "period": "Juego Completo",
              "value": "-110",
              "lines": [
                "singlePlus",
                "singleMinus"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019d6",
              "league": {
                "_id": "6447e2b729d06c3563c0f5e1",
                "name": "MLB"
              },
              "status": false,
              "period": "Mitad",
              "value": "-110",
              "lines": [
                "HSOver",
                "HSUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019d9",
              "league": {
                "_id": "6447e2b729d06c3563c0f5e1",
                "name": "MLB"
              },
              "status": false,
              "period": "Segunda Mitad",
              "value": "-110",
              "lines": [
                "H2SOver",
                "H2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019dd",
              "league": {
                "_id": "647638273c099ee0baa6cbc9",
                "name": "NFL"
              },
              "status": false,
              "period": "Juego Completo",
              "value": "-110",
              "lines": [
                "singlePlus",
                "singleMinus"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019e0",
              "league": {
                "_id": "647638273c099ee0baa6cbc9",
                "name": "NFL"
              },
              "status": false,
              "period": "Mitad",
              "value": "-110",
              "lines": [
                "HSOver",
                "HSUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019e3",
              "league": {
                "_id": "647638273c099ee0baa6cbc9",
                "name": "NFL"
              },
              "status": false,
              "period": "Segunda Mitad",
              "value": "-110",
              "lines": [
                "H2SOver",
                "H2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019e6",
              "league": {
                "_id": "647638273c099ee0baa6cbc9",
                "name": "NFL"
              },
              "status": false,
              "period": "Q1",
              "value": "-110",
              "lines": [
                "priceQ1SOver",
                "priceQ1SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019e9",
              "league": {
                "_id": "647638273c099ee0baa6cbc9",
                "name": "NFL"
              },
              "status": false,
              "period": "Q2",
              "value": "-110",
              "lines": [
                "priceQ2SOver",
                "priceQ2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019ec",
              "league": {
                "_id": "647638273c099ee0baa6cbc9",
                "name": "NFL"
              },
              "status": false,
              "period": "Q3",
              "value": "-110",
              "lines": [
                "priceQ3SOver",
                "priceQ3SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019ef",
              "league": {
                "_id": "647638273c099ee0baa6cbc9",
                "name": "NFL"
              },
              "status": false,
              "period": "Q4",
              "value": "-110",
              "lines": [
                "priceQ4SOver",
                "priceQ4SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019f3",
              "league": {
                "_id": "6447e2ba29d06c3563c0f6f5",
                "name": "NHL"
              },
              "status": true,
              "period": "Juego Completo",
              "value": "-140",
              "lines": [
                "singlePlus",
                "singleMinus"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019f6",
              "league": {
                "_id": "6447e2ba29d06c3563c0f6f5",
                "name": "NHL"
              },
              "status": false,
              "period": "Mitad",
              "value": "-110",
              "lines": [
                "HSOver",
                "HSUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019f9",
              "league": {
                "_id": "6447e2ba29d06c3563c0f6f5",
                "name": "NHL"
              },
              "status": false,
              "period": "Segunda Mitad",
              "value": "-110",
              "lines": [
                "H2SOver",
                "H2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019fc",
              "league": {
                "_id": "6447e2ba29d06c3563c0f6f5",
                "name": "NHL"
              },
              "status": false,
              "period": "P1",
              "value": "-110",
              "lines": [
                "",
                ""
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d019ff",
              "league": {
                "_id": "6447e2ba29d06c3563c0f6f5",
                "name": "NHL"
              },
              "status": false,
              "period": "P2",
              "value": "-110",
              "lines": [
                "",
                ""
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a02",
              "league": {
                "_id": "6447e2ba29d06c3563c0f6f5",
                "name": "NHL"
              },
              "status": false,
              "period": "P3|  ",
              "value": "-110",
              "lines": [
                "",
                ""
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a06",
              "league": {
                "_id": "6447e2b929d06c3563c0f6c2",
                "name": "NBA"
              },
              "status": false,
              "period": "Juego Completo",
              "value": "-110",
              "lines": [
                "singlePlus",
                "singleMinus"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a09",
              "league": {
                "_id": "6447e2b929d06c3563c0f6c2",
                "name": "NBA"
              },
              "status": false,
              "period": "Mitad",
              "value": "-110",
              "lines": [
                "HSOver",
                "HSUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a0c",
              "league": {
                "_id": "6447e2b929d06c3563c0f6c2",
                "name": "NBA"
              },
              "status": false,
              "period": "Segunda Mitad",
              "value": "-110",
              "lines": [
                "H2SOver",
                "H2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a0f",
              "league": {
                "_id": "6447e2b929d06c3563c0f6c2",
                "name": "NBA"
              },
              "status": false,
              "period": "Q1",
              "value": "-110",
              "lines": [
                "priceQ1SOver",
                "priceQ1SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a12",
              "league": {
                "_id": "6447e2b929d06c3563c0f6c2",
                "name": "NBA"
              },
              "status": false,
              "period": "Q2",
              "value": "-110",
              "lines": [
                "priceQ2SOver",
                "priceQ2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a15",
              "league": {
                "_id": "6447e2b929d06c3563c0f6c2",
                "name": "NBA"
              },
              "status": false,
              "period": "Q3",
              "value": "-110",
              "lines": [
                "priceQ3SOver",
                "priceQ3SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a18",
              "league": {
                "_id": "6447e2b929d06c3563c0f6c2",
                "name": "NBA"
              },
              "status": false,
              "period": "Q4",
              "value": "-110",
              "lines": [
                "priceQ4SOver",
                "priceQ4SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a1c",
              "league": {
                "_id": "647638273c099ee0baa6cbcb",
                "name": "MNCAAB"
              },
              "status": false,
              "period": "Juego Completo",
              "value": "-110",
              "lines": [
                "singlePlus",
                "singleMinus"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a1f",
              "league": {
                "_id": "647638273c099ee0baa6cbcb",
                "name": "MNCAAB"
              },
              "status": false,
              "period": "Mitad",
              "value": "-110",
              "lines": [
                "HSOver",
                "HSUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a22",
              "league": {
                "_id": "647638273c099ee0baa6cbcb",
                "name": "MNCAAB"
              },
              "status": false,
              "period": "Segunda Mitad",
              "value": "-110",
              "lines": [
                "H2SOver",
                "H2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a26",
              "league": {
                "_id": "64599eeba67a2fa501beb30d",
                "name": "WNBA"
              },
              "status": false,
              "period": "Juego Completo",
              "value": "-110",
              "lines": [
                "singlePlus",
                "singleMinus"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a29",
              "league": {
                "_id": "64599eeba67a2fa501beb30d",
                "name": "WNBA"
              },
              "status": false,
              "period": "Mitad",
              "value": "-110",
              "lines": [
                "HSOver",
                "HSUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a2c",
              "league": {
                "_id": "64599eeba67a2fa501beb30d",
                "name": "WNBA"
              },
              "status": false,
              "period": "Segunda Mitad",
              "value": "-110",
              "lines": [
                "H2SOver",
                "H2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a2f",
              "league": {
                "_id": "64599eeba67a2fa501beb30d",
                "name": "WNBA"
              },
              "status": false,
              "period": "Q1",
              "value": "-110",
              "lines": [
                "priceQ1SOver",
                "priceQ1SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a32",
              "league": {
                "_id": "64599eeba67a2fa501beb30d",
                "name": "WNBA"
              },
              "status": false,
              "period": "Q2",
              "value": "-110",
              "lines": [
                "priceQ2SOver",
                "priceQ2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a35",
              "league": {
                "_id": "64599eeba67a2fa501beb30d",
                "name": "WNBA"
              },
              "status": false,
              "period": "Q3",
              "value": "-110",
              "lines": [
                "priceQ3SOver",
                "priceQ3SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a38",
              "league": {
                "_id": "64599eeba67a2fa501beb30d",
                "name": "WNBA"
              },
              "status": false,
              "period": "Q4",
              "value": "-110",
              "lines": [
                "priceQ4SOver",
                "priceQ4SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a3c",
              "league": {
                "_id": "6447e2b929d06c3563c0f6e0",
                "name": "Soccer"
              },
              "status": false,
              "period": "Juego Completo",
              "value": "-110",
              "lines": [
                "singlePlus",
                "singleMinus"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a3f",
              "league": {
                "_id": "6447e2b929d06c3563c0f6e0",
                "name": "Soccer"
              },
              "status": false,
              "period": "Mitad",
              "value": "-110",
              "lines": [
                "HSOver",
                "HSUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a42",
              "league": {
                "_id": "6447e2b929d06c3563c0f6e0",
                "name": "Soccer"
              },
              "status": false,
              "period": "Segunda Mitad",
              "value": "-110",
              "lines": [
                "H2SOver",
                "H2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a5c",
              "league": {
                "_id": "647638273c099ee0baa6cbca",
                "name": "Liga Invernal"
              },
              "status": false,
              "period": "Juego Completo",
              "value": "-110",
              "lines": [
                "singlePlus",
                "singleMinus"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a5f",
              "league": {
                "_id": "647638273c099ee0baa6cbca",
                "name": "Liga Invernal"
              },
              "status": false,
              "period": "Mitad",
              "value": "-110",
              "lines": [
                "HSOver",
                "HSUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a62",
              "league": {
                "_id": "647638273c099ee0baa6cbca",
                "name": "Liga Invernal"
              },
              "status": false,
              "period": "Segunda Mitad",
              "value": "-110",
              "lines": [
                "H2SOver",
                "H2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a7c",
              "league": {
                "_id": "647638273c099ee0baa6cbcc",
                "name": "CFL"
              },
              "status": false,
              "period": "Juego Completo",
              "value": "-110",
              "lines": [
                "singlePlus",
                "singleMinus"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a7f",
              "league": {
                "_id": "647638273c099ee0baa6cbcc",
                "name": "CFL"
              },
              "status": true,
              "period": "Mitad",
              "value": "-110",
              "lines": [
                "HSOver",
                "HSUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a82",
              "league": {
                "_id": "647638273c099ee0baa6cbcc",
                "name": "CFL"
              },
              "status": false,
              "period": "Segunda Mitad",
              "value": "-110",
              "lines": [
                "H2SOver",
                "H2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a85",
              "league": {
                "_id": "647638273c099ee0baa6cbcc",
                "name": "CFL"
              },
              "status": false,
              "period": "Q1",
              "value": "-110",
              "lines": [
                "priceQ1SOver",
                "priceQ1SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a88",
              "league": {
                "_id": "647638273c099ee0baa6cbcc",
                "name": "CFL"
              },
              "status": false,
              "period": "Q2",
              "value": "-110",
              "lines": [
                "priceQ2SOver",
                "priceQ2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a8b",
              "league": {
                "_id": "647638273c099ee0baa6cbcc",
                "name": "CFL"
              },
              "status": false,
              "period": "Q3",
              "value": "-110",
              "lines": [
                "priceQ3SOver",
                "priceQ3SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a8e",
              "league": {
                "_id": "647638273c099ee0baa6cbcc",
                "name": "CFL"
              },
              "status": false,
              "period": "Q4",
              "value": "-110",
              "lines": [
                "priceQ4SOver",
                "priceQ4SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a92",
              "league": {
                "_id": "6477a4c659ea9f186575e2a2",
                "name": "XFL"
              },
              "status": false,
              "period": "Juego Completo",
              "value": "-110",
              "lines": [
                "singlePlus",
                "singleMinus"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a95",
              "league": {
                "_id": "6477a4c659ea9f186575e2a2",
                "name": "XFL"
              },
              "status": false,
              "period": "Mitad",
              "value": "-110",
              "lines": [
                "HSOver",
                "HSUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a98",
              "league": {
                "_id": "6477a4c659ea9f186575e2a2",
                "name": "XFL"
              },
              "status": false,
              "period": "Segunda Mitad",
              "value": "-110",
              "lines": [
                "H2SOver",
                "H2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a9b",
              "league": {
                "_id": "6477a4c659ea9f186575e2a2",
                "name": "XFL"
              },
              "status": false,
              "period": "Q1",
              "value": "-110",
              "lines": [
                "priceQ1SOver",
                "priceQ1SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01a9e",
              "league": {
                "_id": "6477a4c659ea9f186575e2a2",
                "name": "XFL"
              },
              "status": false,
              "period": "Q2",
              "value": "-110",
              "lines": [
                "priceQ2SOver",
                "priceQ2SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01aa1",
              "league": {
                "_id": "6477a4c659ea9f186575e2a2",
                "name": "XFL"
              },
              "status": false,
              "period": "Q3",
              "value": "-110",
              "lines": [
                "priceQ3SOver",
                "priceQ3SUnder"
              ],
            },
            {
              "_id": "64c99fcf3e80542cf3d01aa4",
              "league": {
                "_id": "6477a4c659ea9f186575e2a2",
                "name": "XFL"
              },
              "status": false,
              "period": "Q4",
              "value": "-110",
              "lines": [
                "priceQ4SOver",
                "priceQ4SUnder"
              ],
            }
          ]
        })
      })
    }
  }, [createConsorcios.priceSingle])

  const editarConsorcio = async () => {
    try {
      Swal.fire({
        title: 'Guardando cambios',
        text: 'Por favor espere...',
        allowOutsideClick: false,
        showConfirmButton: false,

        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })
      // only send new changes, dont send all the object to the api
      let newchanges = {}
      newchanges = {
        ...editConsorcio,
        name: createConsorcios.name,
      }

      await redApi.put(`/users/${consorcioSeleccionado._id}`, newchanges)
        .then(async (res) => {
          setModalEditar(false)
          setConsorcioSeleccionado('')
          setCreateConsorcios({})
          await getConsorcios()
          Swal.fire({
            icon: 'success',
            title: 'Consorcio editado',
            showConfirmButton: false,
            timer: 1500
          })
        })

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salio mal!',
      })
    }
  }


  return (
    <Modal
      title="Consorcio"
      open={modalEditar || modalCrear}
      onOk={handleOk}
      onCancel={handleCancel}
      width="90%"
      className='modalConsorcio'
      style={{ top: 20 }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          {
            modalEditar ? 'Salvar' : modalCrear ? 'Crear' : ''
          }
        </Button>,
      ]}
    >
      <div className="row">
        <div className="col-12">
          <ul className="nav w-100 d-flex justify-content-start align-items-center">
            {renderMenu()}
          </ul>
        </div>
        <div className="col-12"
        >
          {renderTable()}
        </div>
      </div>
    </Modal>
  )
}

export default ModalConsorcio
