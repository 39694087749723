import moment from 'moment'
import "./TableDinamic.scss"
import { LoadingTable } from './TableDinamic'
import { useEffect } from 'react'

export const TableDinamicMatchups = ({
    head,
    title,
    TronDoubleClick,
    TrHeadOnClick,
    handleStatusGame,
    lineahandleChange,
    lineahandleClick,
    handleVisibleForGames,
    handleVisibleForLeagues,
    games,
    isLoading,
    isPonches,
    ...props
}) => {

    return (
        <div id='table-dinamic'>
            <table className="w-100"
                style={{
                    maxWidth: "1200px",
                }}
            >
                <thead>
                    <tr onClick={() => handleVisibleForLeagues()}>
                        <th colSpan={2}>
                            <div className='d-flex justify-content-around align-items-center'>
                                <h4>Hora</h4>
                                <h4>Juegos Actuales</h4>
                            </div>
                        </th>
                        <th colSpan={1}>
                            <h4>Categoría</h4>
                        </th>
                        {/* <th>Hora</th>
                        <th>Juegos Actuales</th> */}
                        {
                            head?.map((item, index) => (
                                <th key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>{item.name}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading
                            ? <LoadingTable tableLength={head.length} />
                            : games?.map((game, indexGame) => {
                                return (
                                    <tr className="tr_matchups" key={indexGame} onDoubleClick={() => handleVisibleForGames(game)}>
                                        <td colSpan={2}>
                                            <div className="d-flex gap-4">
                                                <div className="d-flex flex-column ps-2">
                                                    <div
                                                        style={{
                                                            fontSize: "10px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {moment(game.time).format("MM/DD")}
                                                    </div>
                                                    <div style={{
                                                        fontSize: "12px",
                                                        fontWeight: "900",
                                                        whiteSpace: "nowrap"
                                                    }}>
                                                        {moment(game.time).format("hh:mm A")}
                                                    </div>
                                                </div>
                                                <div
                                                    className={`d-flex flex-column px-2 games_container ${game.status}`}
                                                    onClick={() => handleStatusGame(game._id,
                                                        game.status === "pending"
                                                            ? "approved"
                                                            : game.status === "approved"
                                                                ? "playing"
                                                                : game.status === "playing"
                                                                    ? "finished"
                                                                    : game.status === "finished"
                                                                        ? "pending"
                                                                        : game.status)}
                                                >
                                                    <div className="d-flex align-items-center gap-2">
                                                        <img src={game?.player?.image} alt={game?.player?.name} />
                                                        {" | "}
                                                        <span>
                                                            {localStorage.getItem('userCodesTeams') == 'rd' ? game?.player?.codeRD : game?.player?.code}
                                                        </span>
                                                        {" - "}
                                                        <span>
                                                            {`${game?.player?.name}`}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td key={indexGame} className={`tr_matchups ${game.status}`}>
                                            <div className="d-flex align-items-center gap-2 container_inputs container_inputs_matchups">
                                                <span>
                                                    {`${game.matchType}`}
                                                </span>
                                            </div>
                                        </td>

                                        {
                                            head && game.playerLine && head?.map((item, index) => {

                                                return (
                                                    <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>
                                                        <div className="container_inputs container_inputs_matchups">
                                                            <div className={`${(game.playerLine[item?.property]?.isManual) ? "manual" : game.playerLine[item?.property]?.status} `}

                                                            >
                                                                <input type="text" value={game.playerLine[item.property]?.value} onChange={(e) =>
                                                                    lineahandleChange(
                                                                        e,
                                                                        index = indexGame,
                                                                        "playerLine",
                                                                        item.property
                                                                    )}
                                                                    onClick={() => lineahandleClick(game.playerLine._id,
                                                                        item.property,
                                                                        game.playerLine[item.property]?.value,
                                                                        "playerLine"
                                                                    )}
                                                                    style={{
                                                                        color: (game.playerLine[item.property]?.isManual) ? "white" : "black"
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                    }
                </tbody>
            </table>
        </div>
    )
}