import React, { useState } from 'react'
import { useContext } from 'react'
import { Container, Row } from 'react-bootstrap'

import Layout from '../structures/layout'

import { redApi } from '../../../config/Axios';
import Cookies from 'universal-cookie';
import { AuthContext } from '../../../auth/context/AuthContext'

const cookie = new Cookies();

async function createUser(data) {

  const id = sessionStorage.getItem("userid");

  const bodydata = JSON.stringify({
    username: data.username,
    password: data.password,
    theme: data.theme,
    userLevel: data.userlevel,
    group: data.group,
    email: data.email,
    ownerOf: data.ownerof,
    posAmount: data.posamount
  })

  console.log(id)
  console.log("este es el user devuelto ::: ", bodydata)

  return fetch(`http://localhost:5000/admin/createuser?userId=${id}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: bodydata
  })
    .then(data => console.log(data.json()))
    .catch(error => console.error(error));
}

export default function Users() {

  //console.log(useContext( AuthContext ))
  // console.log(cookie.get('jwt'))

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [theme, setTheme] = useState('1');
  const [userlevel, setUserlevel] = useState('1');
  const [group, setGroup] = useState();
  const [email, setEmail] = useState();
  const [posamount, setPosamount] = useState();
  const [ownerof, setOwnerof] = useState();


  const handleCreateUser = async (e, data) => {
    e.preventDefault();
    console.log(username, password, theme, userlevel, email, posamount, ownerof, group);
    const user = await createUser({ username, password, theme, userlevel, email, group, posamount, ownerof });
    console.log(user)
  }

  return (
    <Layout>
      <Container className="bg-card p-4">
        <Row className="button-nav mb-4 d-flex align-items-center">
          <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
            <div className="dropdown ps-1" style={{ width: '100%' }}>
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButtong"
                data-bs-toggle="dropdown" aria-expanded="false">
                Crearjjh
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtong">
                <li><a href="#" className="dropdown-item" data-bs-toggle="modal" data-bs-target="#createUsuarioModal">Usuario</a></li>
                <li><a href="#" className="dropdown-item" data-bs-toggle="modal" data-bs-target="#createGroupModal">Grupo</a></li>
              </ul>
            </div>
          </div>



          <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
            <button data-bs-toggle="modal" data-bs-target="#editUser">Editar</button>

            {/* EDITAR USER */}
            <div className="modal fade" id="editUser" tabIndex="-1" aria-labelledby="editUserLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="editUserLabel">Editar</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className="container-fluid">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="edit-user-tab" data-bs-toggle="tab" data-bs-target="#edit-user" type="button" role="tab" aria-controls="edit-user" aria-selected="true">Usuario</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="edit-group-tab" data-bs-toggle="tab" data-bs-target="#edit-group" type="button" role="tab" aria-controls="edit-group" aria-selected="false">Grupo</button>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="edit-user" role="tabpanel" aria-labelledby="edit-user-tab">
                          <div className="row my-3 d-flex justify-content-center">
                            <div className="col-12 col-lg-6 mb-2">
                              <label htmlFor="editUsername">Usuario:</label>
                              <select name="editUsername" id="editUsername">
                                <option>Lorem Ipsum</option>
                              </select>
                            </div>
                            <div className="col-12 mb-2 col-lg-6">
                              <label htmlFor="editUserEmail">Email:</label>
                              <input type="email" id="editUserEmail" />
                            </div>

                            <div className="col-12 col-lg-6 mb-2">
                              <label htmlFor="editPass">Contraseña:</label>
                              <input type="password" id="editPass" />
                            </div>

                            <div className="col-12 col-lg-6 mb-2">
                              <label htmlFor="editGroupName">Grupo</label>
                              <select id="editGroupName">
                                <option>Lorem Ipsum</option>
                              </select>
                            </div>
                            <div className="col-8 mb-2">
                              <button type="button" className="btn btn-ok mb-2" data-bs-dismiss="modal">Editar</button>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="edit-group" role="tabpanel" aria-labelledby="edit-group-tab">
                          <div className="row my-3 d-flex justify-content-center">
                            <div className="col-12 mb-2">
                              <div className="row">
                                <div className="col-12 mb-1">
                                  <label htmlFor="editGroupName">Nombre: </label>
                                  <select id="editGroupName">
                                    <option>Lorem Ipsum</option>
                                  </select>
                                </div>
                                <div className="col-12 mb-1">
                                  <label htmlFor="editGroupDescription">Descripción: </label>
                                  <input type="text" id="editGroupDescription" required />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-auto mb-2 border-end">
                                  <ul className="nav nav-pills flex-column" id="editPrivilegesTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                      <button className="nav-link active" id="editGroupPOS-tab" data-bs-toggle="tab" data-bs-target="#editGroupPOS" type="button" role="tab" aria-controls="editGroupPOS" aria-selected="true">POS</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                      <button className="nav-link" id="editGroupAdmin-tab" data-bs-toggle="tab" data-bs-target="#editGroupAdmin" type="button" role="tab" aria-controls="editGroupAdmin" aria-selected="false">Administración</button>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col mb-2">
                                  <div className="tab-content" id="editPrivilegesTabContent">
                                    <div className="tab-pane fade show active" id="editGroupPOS" role="tabpanel" aria-labelledby="editGroupPOS-tab">
                                      <div className="row my-3">
                                        <div className="col-12 mb-2">
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="ligarNumerosEdit">Ligar Números</label>
                                            <input className="form-check-input" type="checkbox" id="ligarNumerosEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="duplicateNumbersEdit">Duplicar Tickets</label>
                                            <input className="form-check-input" type="checkbox" id="duplicateNumbersEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="cancelTicketEdit">Anular Tickets</label>
                                            <input className="form-check-input" type="checkbox" id="cancelTicketEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="payTicketsEdit">Pagar Tickets</label>
                                            <input className="form-check-input" type="checkbox" id="payTicketsEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="winnerNumbersEdit">Reporte de No. Ganadores</label>
                                            <input className="form-check-input" type="checkbox" id="winnerNumbersEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="listOfNumbersEdit">Reporte de Lista de Números</label>
                                            <input className="form-check-input" type="checkbox" id="listOfNumbersEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="awardedTicketsEdit">Reporte de Tickets Premiados</label>
                                            <input className="form-check-input" type="checkbox" id="awardedTicketsEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="totalSalesEdit">Reporte de Total de Ventas</label>
                                            <input className="form-check-input" type="checkbox" id="totalSalesEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="cancelTicketWithNoTimeEdit">Anular Tickets sin límite de tiempo</label>
                                            <input className="form-check-input" type="checkbox" id="cancelTicketWithNoTimeEdit" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tab-pane fade" id="editGroupAdmin" role="tabpanel" aria-labelledby="editGroupAdmin-tab">
                                      <div className="row my-3">
                                        <div className="col-12 mb-2">
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="accountsToCollectEdit">Accounts to Collect</label>
                                            <input className="form-check-input" type="checkbox" id="accountsToCollectEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="createAccountingEdit">Crear Accounting</label>
                                            <input className="form-check-input" type="checkbox" id="createAccountingEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="readAccountingEdit">Ver Accounting</label>
                                            <input className="form-check-input" type="checkbox" id="readAccountingEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="enterWinnerNumbersEdit">Ingresar Números Ganadores</label>
                                            <input className="form-check-input" type="checkbox" id="enterWinnerNumbersEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="readWinnerNumbersEdit">Ver Números Ganadores</label>
                                            <input className="form-check-input" type="checkbox" id="readWinnerNumbersEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="readBotesEdit">Ver Botes</label>
                                            <input className="form-check-input" type="checkbox" id="readBotesEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="editBotesEdit">Editar Botes</label>
                                            <input className="form-check-input" type="checkbox" id="editBotesEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="readMonitorEdit">Ver Monitor</label>
                                            <input className="form-check-input" type="checkbox" id="readMonitorEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="readReportsEdit">Ver Reportes</label>
                                            <input className="form-check-input" type="checkbox" id="readReportsEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="readLottosEdit">Ver Ligas</label>
                                            <input className="form-check-input" type="checkbox" id="readLottosEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="createLottosEdit">Crear Ligas</label>
                                            <input className="form-check-input" type="checkbox" id="createLottosEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="editLottosEdit">Editar Ligas</label>
                                            <input className="form-check-input" type="checkbox" id="editLottosEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="deleteLottosEdit">Borrar Ligas</label>
                                            <input className="form-check-input" type="checkbox" id="deleteLottosEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="readTerminalesEdit">Ver Terminales</label>
                                            <input className="form-check-input" type="checkbox" id="readTerminalesEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="createTerminalesEdit">Crear Terminales</label>
                                            <input className="form-check-input" type="checkbox" id="createTerminalesEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="editTerminalesEdit">Editar Terminales</label>
                                            <input className="form-check-input" type="checkbox" id="editTerminalesEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="deleteTerminalesEdit">Borrar Terminales</label>
                                            <input className="form-check-input" type="checkbox" id="deleteTerminalesEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="readUsersEdit">Ver Usuarios</label>
                                            <input className="form-check-input" type="checkbox" id="readUsersEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="createUsersEdit">Crear Usuarios</label>
                                            <input className="form-check-input" type="checkbox" id="createUsersEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="editUsersEdit">Editar Usuarios</label>
                                            <input className="form-check-input" type="checkbox" id="editUsersEdit" />
                                          </div>
                                          <div className="form-check">
                                            <label className="form-check-label" htmlFor="deleteUsersEdit">Borrar Usuarios</label>
                                            <input className="form-check-input" type="checkbox" id="deleteUsersEdit" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-8 mb-2">
                              <button type="button" className="btn btn-ok mb-2">Editar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer d-flex justify-content-center">
                    <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ELIMINAR USER */}
          <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
            <button data-bs-toggle="modal" data-bs-target="#deleteUser">Eliminar</button>
            <div className="modal fade" id="deleteUser" tabIndex="-1" aria-labelledby="deleteUserLabel"
              aria-hidden="true">
              <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="deleteUserLabel">Eliminar</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className="container-fluid">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="delete-user-tab" data-bs-toggle="tab" data-bs-target="#delete-user" type="button" role="tab" aria-controls="delete-user" aria-selected="true">Usuario</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="delete-group-tab" data-bs-toggle="tab" data-bs-target="#delete-group" type="button" role="tab" aria-controls="delete-group" aria-selected="false">Grupo</button>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="delete-user" role="tabpanel" aria-labelledby="delete-user-tab">
                          <div className="row my-3 d-flex justify-content-center">
                            <div className="col-12 mb-2 d-flex">
                              <label htmlFor="deleteUsername">Username:</label>
                              <select name="deleteUsername" id="deleteUsername"></select>
                            </div>
                            <div className="col-8 mb-2">
                              <button type="button" className="btn btn-ok mb-2">Eliminar</button>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="delete-group" role="tabpanel" aria-labelledby="delete-group-tab">
                          <div className="row my-3 d-flex justify-content-center">
                            <div className="col-12 mb-2 d-flex">
                              <label htmlFor="deleteGroup">Grupo:</label>
                              <select name="deleteGroup" id="deleteGroup"></select>
                            </div>
                            <div className="col-8 mb-2">
                              <button type="button" className="btn btn-ok mb-2">Eliminar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer d-flex justify-content-center">
                    <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
            <button>Reset</button>
          </div>
        </Row>

        <form>
          <Row className="button-nav mb-2 d-flex align-items-center">
            <div className="col-12 mb-2 col-md-2">
              <label htmlFor="selectConsorcioUsers">Consorcio</label>
            </div>
            <div className="col-12 mb-2 col-md-10">
              <select id="selectConsorcioUsers">
                <option value="todos">Todos</option>
              </select>
            </div>
          </Row>
        </form>

        <Row className="mb-4">
          <div className="col-12">
            <div className="table-responsive-lg">
              <table id="tableUsers" className="table table-hover table-sm table-bordered">
                <thead className="table-title">
                  <tr>
                    <th>Usuario</th>
                    <th>Theme</th>
                    <th>Nivel del usuario</th>
                    <th>Activo?</th>
                    <th>Consorcio</th>
                  </tr>
                </thead>
                <thead name="tablesNotSubadmin">
                  <tr>
                    <th colSpan="5" className="text-center">Administradores</th>
                  </tr>
                </thead>
                <tbody name="tablesNotSubadmin" id="adminsTable">
                </tbody>
                <thead name="tablesNotSubadmin">
                  <tr>
                    <th colSpan="5" className="text-center">Consorcios</th>
                  </tr>
                </thead>
                <tbody name="tablesNotSubadmin" id="subadminsTable">
                </tbody>
                <thead style={{ height: '30px' }}>
                  <tr>
                    <th colSpan="5" className="text-center">Contadores</th>
                  </tr>
                </thead>
                <tbody id="subaccTable">
                </tbody>
                <thead style={{ height: '30px' }}>
                  <tr>
                    <th colSpan="5" className="text-center">POS</th>
                  </tr>
                </thead>
                <tbody id="usersTable">
                </tbody>
                <thead name="tablesNotSubadmin">
                  <tr>
                    <th colSpan="5" className="text-center">Digitadores</th>
                  </tr>
                </thead>
                <tbody name="tablesNotSubadmin" id="digitatorsTable">
                </tbody>
              </table>
            </div>
          </div>
        </Row>
      </Container>
      {/* MODAL CREAR GRUPO */}
      <form>
        <div className="modal fade" id="createGroupModal" tabIndex="-1" aria-labelledby="createGroupModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="CreateGroupModalLabel">Crear Grupo</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="groupGeneral-tab" data-bs-toggle="tab" data-bs-target="#groupGeneral" type="button" role="tab" aria-controls="groupGeneral" aria-selected="true">General</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="privileges-tab" data-bs-toggle="tab" data-bs-target="#privileges" type="button" role="tab" aria-controls="privileges" aria-selected="false">Privilegios</button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="groupGeneral" role="tabpanel" aria-labelledby="groupGeneral-tab">
                      <Row className="my-3">
                        <div className="col-12 mb-2">
                          <label htmlFor="groupName">Nombre: </label>
                          <input type="text" id="groupName" required />
                        </div>
                        <div className="col-12 mb-2">
                          <label htmlFor="groupDescription">Descripción: </label>
                          <input type="text" id="groupDescription" required />
                        </div>
                      </Row>
                    </div>

                    <div className="tab-pane fade" id="privileges" role="tabpanel" aria-labelledby="privileges-tab">
                      <Row className="my-3">
                        <div className="col-auto mb-2 border-end">
                          <ul className="nav nav-pills flex-column" id="privilegesTab" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="groupPOS-tab" data-bs-toggle="tab" data-bs-target="#groupPOS" type="button" role="tab" aria-controls="groupPOS" aria-selected="true">POS</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="groupAdmin-tab" data-bs-toggle="tab" data-bs-target="#groupAdmin" type="button" role="tab" aria-controls="groupAdmin" aria-selected="false">Administración</button>
                            </li>
                          </ul>
                        </div>
                        <div className="col mb-2">
                          <div className="tab-content" id="privilegesTabContent">
                            <div className="tab-pane fade show active" id="groupPOS" role="tabpanel" aria-labelledby="groupPOS-tab">
                              <div className="row my-3">
                                <div className="col-12 mb-2">
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="ligarNumeros">Ligar Números</label>
                                    <input className="form-check-input" type="checkbox" id="ligarNumeros" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="duplicateNumbers">Duplicar Tickets</label>
                                    <input className="form-check-input" type="checkbox" id="duplicateNumbers" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="cancelTicket">Anular Tickets</label>
                                    <input className="form-check-input" type="checkbox" id="cancelTicket" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="payTickets">Pagar Tickets</label>
                                    <input className="form-check-input" type="checkbox" id="payTickets" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="winnerNumbers">Reporte de No. Ganadores</label>
                                    <input className="form-check-input" type="checkbox" id="winnerNumbers" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="listOfNumbers">Reporte de Lista de Números</label>
                                    <input className="form-check-input" type="checkbox" id="listOfNumbers" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="awardedTickets">Reporte de Tickets Premiados</label>
                                    <input className="form-check-input" type="checkbox" id="awardedTickets" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="totalSales">Reporte de Total de Ventas</label>
                                    <input className="form-check-input" type="checkbox" id="totalSales" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="cancelTicketWithNoTime">Anular Tickets sin límite de tiempo</label>
                                    <input className="form-check-input" type="checkbox" id="cancelTicketWithNoTime" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="tab-pane fade" id="groupAdmin" role="tabpanel" aria-labelledby="groupAdmin-tab">
                              <div className="row my-3">
                                <div className="col-12 mb-2">
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="accountsToCollect">Accounts to Collect</label>
                                    <input className="form-check-input" type="checkbox" id="accountsToCollect" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="createAccounting">Crear Accounting</label>
                                    <input className="form-check-input" type="checkbox" id="createAccounting" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="readAccounting">Ver Accounting</label>
                                    <input className="form-check-input" type="checkbox" id="readAccounting" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="enterWinnerNumbers">Ingresar Números Ganadores</label>
                                    <input className="form-check-input" type="checkbox" id="enterWinnerNumbers" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="readWinnerNumbers">Ver Números Ganadores</label>
                                    <input className="form-check-input" type="checkbox" id="readWinnerNumbers" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="readBotes">Ver Botes</label>
                                    <input className="form-check-input" type="checkbox" id="readBotes" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="editBotes">Editar Botes</label>
                                    <input className="form-check-input" type="checkbox" id="editBotes" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="readMonitor">Ver Monitor</label>
                                    <input className="form-check-input" type="checkbox" id="readMonitor" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="readReports">Ver Reportes</label>
                                    <input className="form-check-input" type="checkbox" id="readReports" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="readLottos">Ver Ligas</label>
                                    <input className="form-check-input" type="checkbox" id="readLottos" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="createLottos">Crear Ligas</label>
                                    <input className="form-check-input" type="checkbox" id="createLottos" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="editLottos">Editar Ligas</label>
                                    <input className="form-check-input" type="checkbox" id="editLottos" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="deleteLottos">Borrar Ligas</label>
                                    <input className="form-check-input" type="checkbox" id="deleteLottos" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="readTerminales">Ver Terminales</label>
                                    <input className="form-check-input" type="checkbox" id="readTerminales" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="createTerminales">Crear Terminales</label>
                                    <input className="form-check-input" type="checkbox" id="createTerminales" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="editTerminales">Editar Terminales</label>
                                    <input className="form-check-input" type="checkbox" id="editTerminales" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="deleteTerminales">Borrar Terminales</label>
                                    <input className="form-check-input" type="checkbox" id="deleteTerminales" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="readUsers">Ver Usuarios</label>
                                    <input className="form-check-input" type="checkbox" id="readUsers" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="createUsers">Crear Usuarios</label>
                                    <input className="form-check-input" type="checkbox" id="createUsers" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="editUsers">Editar Usuarios</label>
                                    <input className="form-check-input" type="checkbox" id="editUsers" />
                                  </div>
                                  <div className="form-check">
                                    <label className="form-check-label" htmlFor="deleteUsers">Borrar Usuarios</label>
                                    <input className="form-check-input" type="checkbox" id="deleteUsers" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>

                </div>
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button type="submit" className="btn btn-ok mb-2">Crear</button>
                <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* MODAL CREAR USUARIOS NUEVO */}
      <form onSubmit={handleCreateUser}>
        <div className="modal fade" id="createUsuarioModal" tabIndex="-1" aria-labelledby="createUsuarioModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="CreateUsuarioModalLabel">Crear Usuario</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-4 mb-2">
                          <label htmlFor="usuarioUsername">Tipo:</label>
                        </div>
                        <div className="col-8 mb-2">
                          <select id="usuarioUserType" onChange={e => setUserlevel(e.target.value)}>
                            <option value="1" selected >Administrador</option>
                            <option value="2">Consorcio</option>
                            <option value="3">Cliente</option>
                            <option value="4">WebUser</option>
                            <option value="5">Digitador</option>
                            <option value="6">Supervisor</option>s
                          </select>
                        </div>

                        <div className="col-4 mb-2">
                          <label htmlFor="usuarioUsername">Usuario:</label>
                        </div>
                        <div className="col-8 mb-2">
                          <input required type="text" id="usuarioUsername" onChange={e => setUsername(e.target.value)} />
                        </div>

                        <div className="col-4 mb-2">
                          <label htmlFor="usuarioPassword">Contraseña:</label>
                        </div>
                        <div className="col-8 mb-2">
                          <input required type="password" id="usuarioPassword" onChange={e => setPassword(e.target.value)} />
                        </div>

                        <div className="col-4 mb-2">
                          <label htmlFor="usuarioGroup">Grupo:</label>
                        </div>
                        <div className="col-8 mb-2">
                          <select id="usuarioGroup"></select>
                        </div>

                        <div id="divPosToSubadminLabel" className="col-4 mb-2">
                          <label htmlFor="posAmountToSubadmin">Bancas:</label>
                        </div>
                        <div id="divPosToSubadminInput" className="col-8 mb-2">
                          <input required type="number" min="1" id="posAmountToSubadmin" onChange={e => setPosamount(e.target.value)} />
                        </div>

                        <div className="col-4 mb-2">
                          <label htmlFor="usuarioEmail">Email:</label>
                        </div>
                        <div className="col-8 mb-2">
                          <input required type="email" id="usuarioEmail" onChange={e => setEmail(e.target.value)} />
                        </div>

                        <div className="col-4 mb-2">
                          <label htmlFor="usuarioTheme">Theme:</label>
                        </div>
                        <div className="col-8 mb-2">
                          <select id="usuarioTheme" onChange={e => setTheme(e.target.value)}>
                            <option value="1" selected >Opcion 1</option>
                            <option value="2">Opcion 2</option>
                          </select>

                        </div>
                      </div>

                    </div>
                    <div id="divTableAssignPOS" className="col-6">
                      <table className="table table-sm table-bordered table-hover">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Select POS</th>
                          </tr>
                        </thead>
                        <tbody id="tableAssignPOS"></tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button type="submit" className="btn btn-ok mb-2">Crear</button>
                <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Layout>
  )
}
