import { redApi } from "../../../../config/Axios"


export const sendDataTickets = async () => {
    try {
        const result = await redApi.get('/chatbot/admin/tickets')
        console.log("results: ", result)
        return result
    } catch (error) {
        console.log("Error: ", error)
        throw error
    }
}


export const editTickets = async (ticketCode, newStatus) => {
    try {
        // Realizar la solicitud PUT al backend para actualizar el estado del ticket
        const response = await redApi.put(`/chatbot/tickets/${ticketCode}`, {
            status: newStatus
        })
        // Verificar si la actualización fue exitosa
        if (response.status === 200) {
            console.log('Ticket actualizado con éxito');
        } else {
            console.log('Error al actualizar el ticket');
        }
    } catch (error) {
        console.error('Error en la solicitud de actualización: ', error);
        throw error;
    }
}

export const getTicketCount = async (status) => {
    try {
        const response = await redApi.get(`/chatbot/ticketsCount?status=${status}`)
        return response
    } catch (error) {
        console.error('Error en la solicitud de actualización: ', error);
        throw error;
    }
};

export const sendAssigned = async (ticketCode, newAssigned) => {
    try {

        const data = await redApi.put(`/chatbot/tickets/assigned/${ticketCode}`, {
            assigned: newAssigned
        })

        if (data.status === 200) {
            console.log('Assignes succes!!!');
        } else {
            console.log('Assigned Error!!!');
        }
    } catch (error) {
        console.error('Error en la funcion: ', error);
        throw error;
    }
}