import axios from "axios";
import Cookies from "universal-cookie";

export const redApi = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

redApi.interceptors.request.use(async (config) => {
  const cookies = new Cookies();
  const token = await cookies.get("jwt");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    redApi.defaults.headers.common["Authorization"] = `Bearer ${cookies.get(
      "jwt"
    )}`;
  }
  if (cookies.get("loginAs")) {
    config.headers.LoginAs = `Bearer ${cookies.get("loginAs")}`;
    redApi.defaults.headers.common["LoginAs"] = `Bearer ${cookies.get(
      "loginAs"
    )}`;
  }
  return config;
});
