import React from 'react'
import KeyboardVrtual from '../KeyboardVrtual'

const Transfer_t4_mobile = ({ setMenuActive, menuActive }) => {
  return (
    <div className='sub_menu-container'>
      <button onClick={() => setMenuActive('')}>
        <i className="fas fa-arrow-left"></i>
      </button>
      <div className='container_sub_menu'>
        <div>
          <label htmlFor="selectUser">
            PRINCIPAL ACCOUNT
          </label>
          <select name="selectUser" id="selectUser">
            <option value="Select">Select</option>
          </select>
        </div>
        <div>
          <label htmlFor="selectUser">
            DESTINATION ACCOUNT
          </label>
          <select name="selectUser" id="selectUser">
            <option value="Select">Select</option>
          </select>
        </div>
        <div>
          <label>
            Actual Balance:
          </label>
          <div>
            $ 0.00
          </div>
        </div>
        <div className='bottom_container'>
          <div>
            <label htmlFor="">
              TRANSFER AMOUNT
            </label>
            <input type="text" placeholder='AMOUNT $' inputMode='none' id='inputAmount' />
          </div>
        </div>
        <KeyboardVrtual menuActive={menuActive} setMenuActive={setMenuActive} />
      </div>
    </div>
  )
}

export default Transfer_t4_mobile