import { useConfiguration } from "../../../../hooks/configuracion/useConfiguration"
export const Config = () => {

    const {
        dataTable,
        leagues,

        handleFilter,
        refreshData,
        handleUpdate,
        handleSubmit,
    } = useConfiguration();
    return (
        <div className="container">
            <div className="row gap-4">
                <div className="col-12 d-flex justify-content-between">
                    <div className="d-flex justify-content-center align-items-center gap-1 text-nowrap">
                        <label htmlFor="leagues">
                            Ligas:
                        </label>
                        <select name="league" id="leagues" onChange={handleFilter}>
                            <option value="">Todas</option>
                            {
                                leagues.map((item, index) => {
                                    return (
                                        <option key={index} value={item._id}>
                                            {item.name}
                                        </option>
                                    );
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-start">
                    <button className="btn btn-outline-primary"
                        style={{
                            fontSize: "12px",
                            maxWidth: "fit-content",
                        }}
                        onClick={refreshData}
                    >
                        Refrescar
                    </button>
                    <button className="btn btn-outline-primary"
                        style={{
                            fontSize: "12px",
                            maxWidth: "fit-content",
                        }}
                        onClick={handleSubmit}
                    >
                        Salvar
                    </button>
                    <button className="btn btn-outline-primary"
                        style={{
                            fontSize: "12px",
                            maxWidth: "fit-content",
                        }}
                        disabled
                    >
                        Modificar
                    </button>
                </div>
                <div className="col-12" id="table_container">
                    <table>
                        <thead>
                            <tr>
                                <th>Liga</th>
                                <th>Fuente de informacion</th>
                                <th>Autorizacion</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataTable?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.league.name}</td>
                                        <td>
                                            <select
                                                name="infoSource"
                                                id={item._id}
                                                onChange={(e) => handleUpdate(e, item)}
                                                defaultValue={getDefaultValue(item)}
                                            >
                                                <option value="Default">DEFAULT</option>
                                                {item?.league?.bots?.map((bot, botIndex) => {
                                                    if (bot?.sportBooks?.length == 0) {
                                                        return (
                                                            <option key={bot.name + botIndex} value={bot.name} >{bot.name.toUpperCase()}</option>
                                                        )
                                                    } else {
                                                        return bot?.sportBooks?.map((sportBook, sportBookIndex) => (
                                                            <option key={bot.name + sportBookIndex} value={bot.name + '/' + sportBook.name} >{sportBook.name.toUpperCase()}</option>
                                                        ))
                                                    }

                                                })}
                                            </select>
                                        </td>
                                        <td>{item.authType}</td>
                                        <td>
                                            <input type="checkbox" defaultChecked={item.status} className="form-check-input"
                                                style={{
                                                    marginRight: "5px"
                                                }}
                                                name="status"
                                                id={item._id}
                                                onChange={(e) => handleUpdate(e)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}


const getDefaultValue = (item) => {
    try {
        if (item.sportBook) return `donbestapi/${item.sportBook.name}`;
        if (typeof item?.infoSource === 'string') {
            if (item?.infoSource.toUpperCase() === 'DEFAULT') return "Default";
            else return item?.infoSource;
        }
        return item.infoSource.name;
    } catch (error) {
        console.log(error);
        return "Default";
    }
}