import { redApi } from "../../config/Axios";
import { useState, useEffect } from 'react';


const useGetLeague = () => {
  const [leagues, setLeagues] = useState([]);

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const response = await redApi.get("/leagues?leaguesWithGames=true");

        let leaguesWithGames = response.data;

        if (leaguesWithGames.length > 0) {
          leaguesWithGames = leaguesWithGames.map((league) => ({
            ...league,
            image: `${process.env.REACT_APP_SERVER_URL}/LeagueImages/${league.name}.png`
          }));
        }

        setLeagues(leaguesWithGames);
      } catch (error) {
        // Manejar errores según tus necesidades
        console.error("Error al obtener las ligas", error);
      }
    };

    fetchLeagues();
  }, []);

  return { leagues };
};
export default useGetLeague;