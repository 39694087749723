import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { redApi } from '../../../../config/Axios';
import Swal from 'sweetalert2';

export const WhatsAppSession = ({setSession}) => {

    const [query, setQuery] = useState({date: "", endDate: "", status: ""});
    const [loading, setLoading] = useState(true);
    const [msgHistorial, setMsgHistorial] = useState([]);
    const logoutWs = async () => {
        Swal.fire({
            title: 'Desconectando...',
            didOpen: () => {
              Swal.showLoading()
            }
        })
        try {
        await redApi.delete("/whatsapp/endsession");
        setSession({loading: false, session: false});
        Swal.fire({
            icon: 'success',
            title: 'Sesión terminada',
            timer: 1500,
            showConfirmButton: false,
        })
        } catch (error) {
        console.log(error);
        Swal.fire({
            icon: 'error',
            title: 'Error cerrando la sesión.',
            timer: 1500,
            showConfirmButton: false,
        })
        }
    }
    const handlerLogout = () => {
        Swal.fire({
            title: 'Desea terminar la sesión de WhatsApp?',
            text: "El servicio quedará inactivo hasta que inicie otra sesión.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "7E22CE",
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'
            }).then((result) => {
            if (result.isConfirmed) {
                logoutWs();
            }
            })
    }
    const fetchHistorialMsg = async () => {
        setLoading(true);
        const { date, endDate, status } = query; 
        try {
        const { data } = await redApi.get(`/whatsapp/historial`, {
            params: {
                date, endDate, status
            }
        });
        setMsgHistorial(data);
        } catch (error) {
        setMsgHistorial([]);
        } finally {
        setLoading(false);
        }
    };
    useEffect(() => {
        setQuery({...query, date: moment().format("YYYY-MM-DD"), endDate: moment().add(1, "day").format("YYYY-MM-DD")})
    }, []);
    useEffect(() => {
        if (query.date) {
        fetchHistorialMsg();
        }
    }, [query])



  return (
    <div className="row mb-4 justify-content-center">
        <div className="col-6 mb-2 d-flex flex-column align-items-center">
            <p style={{fontSize: "1.3rem"}}>Sesión iniciada.</p>
            <button onClick={handlerLogout}>Cerrar Sesión</button>
        </div>
        <div className="col-12 my-2">
            <span style={{fontSize: "1.3rem", fontWeight: "bold"}}>Cola de Mensajes</span>
            <div className="d-flex justify-content-between mx-auto align-items-end mb-2">
                <div>
                    <span>Fecha:</span>
                    <input value={query.date} onChange={(e) => {setQuery({...query, date: e.target.value})}} type="date" name="date"/>
                </div>
                <div>
                    <span>Fin:</span>
                    <input value={query.endDate} onChange={(e) => {setQuery({...query, endDate: e.target.value})}} type="date" name="endDate"/>
                </div>
                <div>
                    <span>Status:</span>
                    <select value={query.status} onChange={(e) => {setQuery({...query, status: e.target.value})}} name="status">
                        <option value="">Todos</option>
                        <option value="pending">Pendiente</option>
                        <option value="sent">Enviado</option>
                        <option value="error">Error</option>
                    </select>
                </div>
                <div>
                    <button onClick={fetchHistorialMsg}>
                        Refresh
                    </button>
                </div>
            </div>
            <div className="w-100" id="table_container" style={{maxHeight: "60vh", overflowY: "auto"}}>
                <table className="">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Ticket</th>
                            <th>Usuario</th>
                            <th>Contacto</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading
                                ? <tr><td colSpan={5}>Loading...</td></tr>
                                : msgHistorial.length === 0
                                ? <tr><td colSpan={5}>No tickets sent.</td></tr>
                                : msgHistorial.map((item, index) => (
                                    <tr key={index}>
                                        <td>{moment(item.createdAt).format("YYYY-MM-DD hh:mm:ss A")}</td>
                                        <td>#{item.ticketNumber}</td>
                                        <td>{item.userId.username}</td>
                                        <td>+{item.contact}</td>
                                        <td 
                                            style={typeStylesMsg(item.status)}
                                        >{item.status}</td>
                                    </tr>
                                    ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

const typeStylesMsg= (type) => {
    switch (type) {
        case "sent": return {background:"#22C55E", color: "white"};
        case "error": return {background:"#B91C1C", color: "white"};
        default: return {};
    }
}