import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../structures/layout'

export default function BoatBankers() {
  return (
    <Layout>
        <Container className="bg-card p-4">
            <div className="row button-nav mb-4 d-flex align-items-center">
                <div className="col-12 col-md-6 col-lg-auto d-flex justify-content-center">
                    <a className="navlink-btn" href="/admin/boat">Bote</a>
                </div>
                <div className="col-12 col-md-6 col-lg-auto d-flex justify-content-center">
                    <h3 className="mr-2">Banqueros</h3>
                </div>
            </div>

            <div className="row button-nav mb-4 d-flex align-items-center">
                <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
                    <button data-bs-toggle="modal" data-bs-target="#createBanker">Crear</button>
                    <div className="modal fade" id="createBanker" tabIndex="-1" aria-labelledby="createBankerLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                            <h5 className="modal-title" id="CreateBankerLabel">Crear</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <div className="container-fluid">
                                <form>
                                    <div className="row mb-4 d-flex align-items-center">
                                        <div className="col-12 mb-2">
                                            <label htmlFor="nameCreateBanker">Nombre:</label>
                                            <input type="text" id="nameCreateBanker"/>
                                        </div>
                                    </div>
                                    <div className="row mb-4 d-flex justify-content-around">
                                        <div className="col-12 mb-2 d-flex justify-content-center">
                                            <span>Límite de Bote</span>
                                        </div>
                                        <div className="col-12 mb-2 col-lg-3">
                                            <label htmlFor="createDirect">Directo</label>
                                            <input type="number" id="createDirect" />
                                        </div>
                                        <div className="col-12 mb-2 col-lg-3">
                                            <label htmlFor="createPale">Pale</label>
                                            <input type="number" id="createPale" />
                                        </div>
                                        <div className="col-12 mb-2 col-lg-3">
                                            <label htmlFor="createTriplet">Tripleta</label>
                                            <input type="number" id="createTriplet"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-center">
                            <button type="button" className="btn btn-ok mb-2">Crear Banquero</button>
                            <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cancelar</button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
                    <button data-bs-toggle="modal" data-bs-target="#editBanker">Editar</button>
                    <div className="modal fade" id="editBanker" tabIndex="-1" aria-labelledby="editBankerLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                            <h5 className="modal-title" id="EditBankerLabel">Editar</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <div className="container-fluid">
                                <form>
                                    <div className="row mb-4 d-flex align-items-center">
                                        <div className="col-12 mb-2">
                                            <label htmlFor="editbankername">Nombre:</label>
                                            <select id="editbankername">
                                                <option value="lorem">lorem</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-4 d-flex justify-content-around">
                                        <div className="col-12 mb-2 d-flex justify-content-center">
                                            <span>Límite de Bote</span>
                                        </div>
                                        <div className="col-12 mb-2 col-lg-3">
                                            <label htmlFor="editDirect">Directo</label>
                                            <input type="number" id="editDirect" />
                                        </div>
                                        <div className="col-12 mb-2 col-lg-3">
                                            <label htmlFor="editPale">Pale</label>
                                            <input type="number" id="editPale" />
                                        </div>
                                        <div className="col-12 mb-2 col-lg-3">
                                            <label htmlFor="editTriplet">Tripleta</label>
                                            <input type="number" id="editTriplet"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-center">
                            <button type="button" className="btn btn-ok mb-2">Crear Banquero</button>
                            <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cancelar</button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
                    <button data-bs-toggle="modal" data-bs-target="#deleteBanker">Eliminar</button>
                    <div className="modal fade" id="deleteBanker" tabIndex="-1" aria-labelledby="deleteBankerLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                            <h5 className="modal-title" id="DeleteBankerLabel">Eliminar</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <div className="container-fluid">
                                <form>
                                    <div className="row mb-4 d-flex align-items-center">
                                        <div className="col-12 mb-2">
                                            <label htmlFor="deletebankername">Nombre:</label>
                                            <select id="deletebankername">
                                                <option value="lorem">lorem</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-center">
                            <button type="button" className="btn btn-ok mb-2">Crear Banquero</button>
                            <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cancelar</button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
                    <button>Activar/Desactivar</button>
                </div>
            </div>
            <div className="row mb-3">
                <div className="table-responsive-lg">
                    <table className="table table-sm table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Directo</th>
                        <th>Pale</th>
                        <th>Tripleta</th>
                        <th>Estatus</th>
                    </tr>
                    </thead>
                    <tbody id="tableBankers">
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </Container>
    </Layout>
  )
}
