import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Input, Select, message } from 'antd'

const ModalJugadores = (props) => {
    const handleOk = () => {
        if (props.modalEditar) {
            props.setModalEditar(false)
        } else if (props.modalCrear) {
            props.setModalCrear(false)
        }
    }

    const handleCancel = () => {
        if (props.modalEditar) {
            props.setModalEditar(false)
        } else if (props.modalCrear) {
            props.setModalCrear(false)
        }
    }

    let jugador = props.jugadorSelected || ""



    return (
        <Modal
            title={props.modalEditar ? 'Editar juagdor' : 'Crear jugador'}
            open={props.modalEditar || props.modalCrear}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1280}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    {props.modalEditar ? 'Editar jugador' : 'Crear jugador'}
                </Button>,
            ]}
        >
            <div className="row d-flex flex-column">
                <div className="col-12">
                    <button className="btn btn-secondary col-2">General</button>
                </div>
                <div className="col-12 py-4">
                    <div className="row py-3">
                        <div className="col-3">
                            <label> Nombre: </label>
                        </div>
                        <div className="col-9">
                            <input type="text" className="form-control" value={jugador ? jugador : ''} />
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-3">
                            <label> Liga: </label>
                        </div>
                        <div className="col-9">
                            <select className="form-select">
                                <option value="seleccionar">Seleccionar</option>
                                <option value="major-league-baseball">Major League Baseball</option>
                                <option value="nhl">NHL</option>
                                <option value="nba">NBA</option>
                                <option value="mlb">MLB</option>
                                <option value="nfl">NFL</option>
                            </select>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-3">
                            <label> Equipo: </label>
                        </div>
                        <div className="col-9">
                            <select className="form-select">
                                <option value="seleccionar">Seleccionar</option>
                                <option value="Los Angeles Dodgers">Los Angeles Dodgers</option>
                                <option value="New York Yankees">New York Yankees</option>
                                <option value="Chicago Cubs">Chicago Cubs</option>
                                <option value="Boston Red Sox">Boston Red Sox</option>
                                <option value="San Francisco Giants">San Francisco Giants</option>
                            </select>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-3 d-flex gap-2">
                            <input type="checkbox" className="form-check-input" />
                            <label>Pitcher</label>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalJugadores