import { useEffect, useState } from 'react'
import { redApi } from '../../../../config/Axios'

export const Ctrldelineas = () => {
    const [dataTable, setDataTable] = useState([])
    useEffect(() => {
        redApi.get('/linesConfig/lineLimitRl')
            .then(res => {
                setDataTable(res.data)
            })
    }, [])

    const [newData, setNewData] = useState([])
    const handleChange = (e, index) => {
        // setNewData
        let { name, value } = e.target;
        let newD = dataTable[index]
        newD[name] = value;

        // if exist in newData update
        if (newData.length > 0) {
            let exist = newData.find(item => item._id === newD._id)
            if (exist) {
                let newData2 = newData.filter(item => item._id !== newD._id)
                setNewData([...newData2, newD])
                console.log({ newD, newData2 });
            } else {
                setNewData([...newData, newD])
                console.log({ newD, newData });
            }
        } else {
            setNewData([...newData, newD])
            console.log({ newD, newData });
        }
    }

    const handleSave = () => {
        console.log('save');
        if (newData.length === 0) return;
        redApi.put(`/linesConfig/lineLimitRl`, {
            updates: newData
        })
            .then(res => {
                console.log({ res });
                setNewData([])
            })
            .catch(err => {
                console.log({ err });
            }
            )
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <button className="btn btn-primary"
                        style={{
                            maxWidth: '200px',
                            margin: '10px auto'
                        }}
                        onClick={handleSave}
                    >
                        Salvar
                    </button>
                </div>
                <div className="col-12" id="table_container">
                    <table>
                        <thead>
                            <tr>
                                <th>Liga</th>
                                <th>Limite del RL Minimo</th>
                                <th>Limite del RL Maximo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataTable?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.league?.name}</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={item.minRl}
                                                onChange={(e) => {
                                                    handleChange(e, index)
                                                }}
                                                name="minRl"
                                            />

                                        </td>
                                        <td>
                                            <input
                                                type="text" value={item.maxRl}
                                                onChange={(e) => {
                                                    handleChange(e, index)
                                                }}
                                                name="maxRl"
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
