import React, { useState } from 'react'

const CrearGrupoPrivilegios = ({ administracionData, setAdministracionData, privilegesPos, setPrivilegesPos }) => {
    const [show, setShow] = useState('POS')

    const handleShow = (e) => {
        setShow(e.target.value)
    }



    const handleExpand = (name) => {
        const newData = administracionData.map((item) => {
            if (item.name === name) {
                item.expanded = !item.expanded
            }
            return item
        })
        setAdministracionData(newData)
    }

    const handleExpandChild = (name, childName) => {
        const newData = administracionData.map((item) => {
            if (item.name === name) {
                item.children.map((child) => {
                    if (child.name === childName) {
                        child.expanded = !child.expanded
                    }
                    return child
                })
            }
            return item
        })
        setAdministracionData(newData)
    }

    const handleCheck = (name, childName) => {
        const newData = administracionData.map((item) => {
            if (item.name === name) {
                item.children.map((child) => {
                    if (child.name === childName) {
                        child.checked = !child.checked
                    }
                    return child
                })
            }
            return item
        })
        setAdministracionData(newData)
    }

    const handleCheckChild = (name, childName, childChildName) => {
        const newData = administracionData.map((item) => {
            if (item.name === name) {
                item.children.map((child) => {
                    if (child.name === childName) {
                        child.children.map((childChild) => {
                            if (childChild.name === childChildName) {
                                childChild.checked = !childChild.checked
                            }
                            return childChild
                        })
                    }
                    return child
                })
            }
            return item
        })
        setAdministracionData(newData)
        console.log(administracionData)
    }

    // pos privileges

    const handleCheckPos = (name) => {
        const newData = privilegesPos.map((item) => {
            if (item.name === name) {
                item.checked = !item.checked
            }
            return item
        })
        setPrivilegesPos(newData)
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="row">
                    <div className="col-3">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <button value="POS" onClick={handleShow} className={`nav-buttons ${show === 'POS' ? 'active' : ''}`}>POS</button>
                            </li>
                            <li className="nav-item">
                                <button value="Administración" onClick={handleShow} className={`nav-buttons ${show === 'Administración' ? 'active' : ''}`}>Administración</button>
                            </li>
                        </ul>
                    </div>
                    <div className="col-9">
                        {show === 'POS' && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="row d-flex flex-column gap-4">
                                        {
                                            privilegesPos?.map((item, index) => (
                                                <div key={index} className="col-6 d-flex gap-2">
                                                    <input type="checkbox" value={item.value} onChange={() => handleCheckPos(item.name)} checked={item.checked} />
                                                    <label>{item.name}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                        }
                        {
                            show === 'Administración' &&
                            (
                                <div className="row">
                                    <div className="col-12 d-flex flex-column gap-4">
                                        {
                                            administracionData?.map((item, index) => (
                                                <div key={index} className="row d-flex flex-column gap-4">
                                                    <div className="col-6 d-flex gap-2">
                                                        <button
                                                            onClick={() => handleExpand(item.name)}
                                                            className="btn btn-sm btn-outline-primary"
                                                        >
                                                            {item.expanded ? '-' : '+'}
                                                        </button>
                                                        <label>{item.name}</label>
                                                    </div>
                                                    {
                                                        item.expanded && item.children?.map((child, index) => (
                                                            <div key={index} className="d-flex gap-2 flex-column">
                                                                <div className='col d-flex gap-2' style={{
                                                                    marginLeft: '2rem'
                                                                }}>
                                                                    {
                                                                        child.children && child.name === 'Jugar' && (
                                                                            <button
                                                                                onClick={() => handleExpandChild(item.name, child.name)}
                                                                                className="btn btn-sm btn-outline-primary"
                                                                            >
                                                                                {child.expanded ? '-' : '+'}
                                                                            </button>
                                                                        )
                                                                    }
                                                                    <input type="checkbox" value={child.value} onChange={() => handleCheck(item.name, child.name)} checked={child.checked} />
                                                                    <label>{child.name}</label>
                                                                </div>
                                                                {
                                                                    child.expanded && child.children?.map((child2, index) => (
                                                                        <div key={index} className="col-6 d-flex gap-2"
                                                                            style={{
                                                                                marginLeft: '4rem'
                                                                            }}
                                                                        >
                                                                            <input type="checkbox" value={child2.value} onChange={() => handleCheckChild(item.name, child.name, child2.name)} checked={child2.checked} />
                                                                            <label>{child2.name}</label>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrearGrupoPrivilegios