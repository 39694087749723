import React, { useState, useEffect, useContext } from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../structures/layout'
import TicketsReports from './reportes/TicketsReports'
import Monitor from './reportes/Monitor'
import GananciaPerdida from './reportes/Ganancia_Perdida'
import CxCv2 from './reportes/CxCv2'
import IngEgr from './reportes/IngEgr'
import VentaGral from './reportes/VentaGral'
import Resumen from './reportes/Resumen'
import ScoreBox from './reportes/ScoreBox'
import WebUsers from './reportes/WebUsers'
import './reportes/reportes.scss'
import { UserServices } from '../../../services/userServices'
import validPrivileges from '../../../utils/validatePriv'
// get current url  
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../../auth'
import { ReportsProvider } from "./reportes/context/ReportesContext";

let privilegesRequired = {
  cxcv2: 15,
  ingegr: 12,
}

export default function Reports({ currentTable }) {
  const params = useParams()
  const [table, setTable] = useState('Tickets')
  const { userLevel } = useContext(AuthContext);

  useEffect(() => {
    let value = params['*']
    if (value !== undefined && currentTable === 'Web users') {
      setTable(currentTable)
    } else {
      setTable('Tickets')
    }
  }, [params])
  const [menu, setMenu] = useState([])
  const getUserPrivileges = async () => {
    let allmenu = ['Tickets', 'Monitor', 'Gan/Pérd', 'CxC v2', 'Ing/Egr', 'Venta Gral', 'Resumen', 'Score box', 'Web users']
    if (userLevel === 10){

      let userPriv = await UserServices.getUserPrivileges('General')
      let valid = validPrivileges(userPriv.values, [privilegesRequired.cxcv2, privilegesRequired.ingegr])
      if (valid !== true) {
        let missingPrivileges = valid.split(': ')[1].split(', ')
        if (missingPrivileges.includes('Ver CxC')) {
          allmenu = allmenu.filter(item => item !== 'CxC v2')
        }
        if (missingPrivileges.includes('Ver transacciones')) {
          allmenu = allmenu.filter(item => item !== 'Ing/Egr')
        }
      }
      setMenu(allmenu)
    } else {
      setMenu(allmenu)
    }
  }

  useEffect(() => {
    getUserPrivileges()
  }, [UserServices])

  const handleMenu = (e) => {
    setTable(e.target.value)
  }

  const renderTable = () => {
    switch (table) {
      case 'Tickets':
        return <TicketsReports />
      case 'Monitor':
        return <Monitor />
      case 'Gan/Pérd':
        return <GananciaPerdida />
      case 'CxC v2':
        return <CxCv2 />
      case 'Ing/Egr':
        return <IngEgr />
      case 'Venta Gral':
        return <VentaGral />
      case 'Resumen':
        return <Resumen />
      case 'Score box':
        return <ScoreBox />
      case 'Web users':
        return <WebUsers />
      default:
        return <TicketsReports />
    }
  }



  return (
    <Layout>
      <Container fluid className="py-4 px-2 px-lg-5" id='reportsContainer'>
       <ReportsProvider>
          <div >
            <div className="row mb-1 d-flex align-items-center">
              <div className='col d-flex align-items-center justify-content-center'>
                {
                  menu.map((item, index) => {
                    return (
                      <button key={index} value={item} onClick={handleMenu} className={`text-nowrap  ${table === item ? 'active' : ''}`}>{item}</button>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className='rendertable'>
            {
              renderTable()
            }
          </div>
        </ReportsProvider>
      </Container>
    </Layout>
  )
}
