import React, {useState} from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { LoadingSpinner } from '../../../../utils/LoadingSpinner';
import { redApi } from '../../../../config/Axios';

export const WhatsAppNoSession = ({setSession}) => {
    const [open, setOpen] = useState(false);
    const [qrCode, setQrcode] = useState(<LoadingSpinner color="#a0a0a0"/>)
    const loginWs = async () => {
        setOpen(true);
        try {
            const { data } = await redApi.post("/whatsapp/newsession");
            console.log({ data })
            setQrcode(
                <img 
                    src={"data: image/png; base64, "+ data.qrbuffer} 
                    alt="QR"
                    width={300}
                    className="mx-auto"
                />
            )
            try {
                await redApi.get("/whatsapp/waitlogin");
                // Sweet alert login successfull
                Swal.fire({
                    icon: 'success',
                    title: 'Login successfull',
                    timer: 1500,
                    showConfirmButton: false
                })
                setSession({loading: false, session: true});
            } catch (error) {
                // Sweet alert login error
                Swal.fire({
                    icon: 'error',
                    title: 'Login error',
                    text: "Try again later.",
                    timer: 1500,
                    showConfirmButton: false
                })
                console.log(error)
            } finally {
                setOpen(false)
                setQrcode(<LoadingSpinner color="#a0a0a0"/>)
            }
        } catch (error) {
            console.log({error})
        }
    };

    const handlerLogin = () => {
        Swal.fire({
            title: '¿Desea iniciar una sesión de WhatsApp?',
            text: "Prepárese a escanear el código QR",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '7E22CE',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'
        }).then((result) => {
            if (result.isConfirmed) {
                loginWs();
            }
        })
    }
    return (
        <>
            <div className="row mb-4">
                <div className="col-12 mb-2">
                    <p style={{fontSize: "1.3rem"}}>No hay sesión iniciada.</p>
                    <button onClick={handlerLogin}>Iniciar Sesión</button>
                </div>
            </div>
            <WhatsappQRModal open={open} qrCode={qrCode}  />
        </>
    )
}

const WhatsappQRModal = ({open, qrCode}) => {

    return (
        <Modal show={open} onHide={() => {}} backdrop="static" keyboard={false}>
            <Modal.Header className="justify-content-center">
                <Modal.Title>Escanea el Código QR</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="d-flex justify-content-center">
                    <Col xs={12} className="d-flex justify-content-center">
                        {qrCode}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}