import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Select, message } from "antd";
import General from "./General.js";
import UsuariosPermitidos from "./UsuariosPermitidos.js";
import Horarios from "./Horarios.js";
import { redApi } from "../../../../../config/Axios";
import Swal from "sweetalert2";

const ModalUsuarios = ({ getusuarios, ...props }) => {
  const [terminalsSelected, setTerminalsSelected] = useState([]);
  const [isWebUser, setIsWebUser] = useState(false);
  // useEffect(() => { console.log({ terminalsSelected }) }, [terminalsSelected])
  useEffect(() => {
    if (props?.usuario?.ownerOf?.length > 0) {
      setTerminalsSelected(props.usuario.ownerOf);
    }
  }, [props.modalEditar]);

  const createUser = async () => {
    let adminBy = [];
    if (terminalsSelected.length > 0) {
      terminalsSelected.map((terminal) => {
        adminBy.push(terminal._id);
      });
    }
    Swal.fire({
      title: "Creando usuario",
      html: "Espere por favor",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
    });

    if (isWebUser) {
      data["ownedBy"] = adminBy[0];
      delete data.ownerOf;

      await redApi
        .post(`/users/webUser`, {
          ...data,
          ownerOf: adminBy,
        })
        .then((res) => {
          getusuarios();
          Swal.fire({
            icon: "success",
            title: `Usuario creado!`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => console.log(err));
    } else {
      await redApi
        .post(`/users`, {
          ...data,
          ownerOf: adminBy,
        })
        .then((res) => {
          getusuarios();
          Swal.fire({
            icon: "success",
            title: `Usuario creado!`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Algo salio mal!",
          });
        });
    }
  };
  const edituser = async (id) => {
    let adminBy = [];
    if (terminalsSelected.length > 0) {
      terminalsSelected.map((terminal) => {
        adminBy.push(terminal._id);
      });
    }
    Swal.fire({
      title: "Editando usuario",
      html: "Espere por favor",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
    });
    await redApi
      .put(`/users/${id}`, {
        ...data,
        ownerOf: adminBy,
      })
      .then((res) => {
        getusuarios();
        Swal.fire({
          icon: "success",
          title: `Usuario editado!`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Algo salio mal!",
        });
      });
  };

  const [data, setData] = useState({
    userLevel: 10,
    theme: 1,
    ownerOf: [],
  });

  const disabledBtn = () => {
    if (props?.usuario?.userLevel == 1) return

    if (!props.modalEditar) {
      if (
        !data.username ||
        !data.password ||
        data.ownedBy === "" ||
        data.isGroup === ""
      ) {
        return true;
      }
    }

    if (props.modalEditar) {

      if (
        data.ownedBy === "" ||
        data.isGroup === ""
      ) {
        return true;
      }
    }

    return false;
  };

  const handleOk = () => {
    if (props.modalEditar) {
      props.setModalEditar(false);
      edituser(props?.usuario?._id);
      // setData({
      //     ...data,
      //     username: data.username === "" ? props.usuario.username : data.username,
      //     password: data.password === "" ? props.usuario.password : data.password,
      //     group: data.isGroup === "" ? props.usuario.isGroup : data.isGroup,
      //     email: data.email === "" ? props.usuario.email : data.email,
      // })
      // props.edituser(data)
    } else if (props.modalCrear) {
      console.log(props, "HEREEEE");
      props.setModalCrear(false);
      createUser();
    }
  };

  const handleCancel = () => {
    if (props.modalEditar) {
      props.setModalEditar(false);
    } else if (props.modalCrear) {
      props.setModalCrear(false);
    }
  };

  const renderMenu = () => {
    let menu = ["General", "Usuarios Permitidos", "Horario"];
    return menu.map((item, index) => {
      return (
        <li key={index} className="nav-item">
          <button
            value={item}
            onClick={handleTable}
            className={`nav-buttons ${table === item ? "active" : ""}`}
          >
            {item}
          </button>
        </li>
      );
    });
  };

  const [table, setTable] = useState("General");

  const handleTable = (e) => {
    setTable(e.target.value);
  };

  const renderTableCreate = () => {
    switch (table) {
      case "General":
        return (
          <General
            setData={setData}
            data={data}
            allgroups={props.allgroups}
            allConsorcios={props.allConsorcios}
            terminalsSelected={terminalsSelected}
            setTerminalsSelected={setTerminalsSelected}
            setIsWebUser={setIsWebUser}
            isWebUser={isWebUser}
          />
        );
      case "Usuarios Permitidos":
        return <UsuariosPermitidos />;
      case "Horario":
        return <Horarios />;
      default:
        return <General />;
    }
  };

  const renderTableEdit = (usuario) => {
    switch (table) {
      case "General":
        return (
          <General
            usuario={usuario}
            setData={setData}
            data={data}
            modalEditar={props.modalEditar}
            allgroups={props.allgroups}
            allConsorcios={props.allConsorcios}
            terminalsSelected={terminalsSelected}
            setTerminalsSelected={setTerminalsSelected}
          />
        );
      case "Usuarios Permitidos":
        return <UsuariosPermitidos usuario={usuario} />;
      case "Horario":
        return <Horarios usuario={usuario} />;
      default:
        return <General usuario={usuario} />;
    }
  };

  return (
    <Modal
      title={props.modalEditar ? "Editar usuario" : "Crear usuario"}
      open={props.modalEditar || props.modalCrear}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1280}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          disabled={disabledBtn()}
        >
          {props.modalEditar ? "Editar usuario" : "Crear usuario"}
        </Button>,
      ]}
    >
      <div className="row">
        <div className="col-12">
          <ul className="nav w-100 d-flex justify-content-start align-items-center">
            {renderMenu()}
          </ul>
        </div>
        <div className="col-12">
          {props.modalEditar
            ? renderTableEdit(props?.usuario)
            : renderTableCreate()}
        </div>
      </div>
    </Modal>
  );
};

export default ModalUsuarios;
