import React from 'react';
import Footer from '../../navigation/webuser/Webuser_footer';
import Header from '../../navigation/webuser/Webuser_header';
import LeftContainer from '../../navigation/webuser/Webuser_left';
import RightContainer from '../../navigation/webuser/Webuser_right';
import './WebuserLayout.scss';

const WebuserLayout = ({ children, bets, setBets, betToAdd, setBetToAdd, gamesForUser, isLoading, setUserTheme, totalToWin, setTotalToWin }) => {



    const handleChangeBetToAdd = (e) => {
        setBetToAdd({
            ...betToAdd,
            [e.target.name]: (e.target.value).toUpperCase()
        });
        if (e.target.name === 'TeamCode' && e.target.value.length === 4) {
            document.querySelector('#betTypeSelect').focus();
        }
    }

    return (
        <>
            <section id="LayoutClientWeb" className="main-sports-wrapper">
                <div className='p-2'>
                    <Header />
                </div>
                <div style={{ height: '100%' }} className="row g-0 px-xl-5 px-2 gap-2">
                    <div id="left-container" style={{ height: '100%' }} className="col-12 h-100">
                        <LeftContainer setBetToAdd={setBetToAdd} handleChangeBetToAdd={handleChangeBetToAdd} betToAdd={betToAdd} bets={bets} setBets={setBets} totalToWin={totalToWin} setTotalToWin={setTotalToWin} />
                    </div>

                    <div id="middle-container" className="col-12">
                        {children}
                    </div>
                    <div id="right-container" className="col-12">
                        <RightContainer gamesForUser={gamesForUser} isLoading={isLoading} setUserTheme={setUserTheme} />
                    </div>
                </div>
                <div className='p-2'>
                    <Footer />
                </div>
            </section>
        </>
    );


}

export default WebuserLayout;
