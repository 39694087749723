import React, { useState, useEffect } from "react";
import { redApi } from "../../../../config/Axios";
import { Modal } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function RightContainer({
  isLoading,
  setIsLoading,
  gamesForUser,
}) {
  const navigate = useNavigate();
  // change theme
  const [userTheme, setUserTheme] = useState(1);
  const handleChangeTheme = (e) => {
    setUserTheme(e.target.value);
  };

  // modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    setIsModalVisible(false);
    await redApi.get("/verify").then(async (res) => {
      console.log(res.data);
      if (res.data) {
        if (res.data._id) {
          await redApi
            .put(`/users/${res.data._id}`, { theme: userTheme })
            .then((res) => {
              window.location.reload();
              Swal.fire({
                icon: "success",
                title: "Theme changed",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      }
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // click to expand and show table 
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`container-fluid d-flex flex-column justify-content-center align-items-center ${isExpanded ? 'bg-card' : ''}`}>
      {/* <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 p-2 mb-2">
        <div className="col d-flex flex-column justify-content-center align-items-center">
          <button className="right_button w-100">
            <i className="fas fa-check"></i>
            Linea <br />
            (Todas)
          </button>
        </div>
        <div className="col d-flex flex-column justify-content-center align-items-center h-100">
          <button
            className="right_button w-100 h-100"
            onClick={() => navigate("/client/scores/top")}
          >
            Score
          </button>
        </div>
      </div> */}
      {
        isExpanded ? (
          <div className="w-100 d-flex flex-column justify-content-start align-items-center gap-2 px-2 h-100 py-2">
            {/* <div className="w-100 d-flex flex-row justify-content-start align-items-center gap-2">
          <div className="col-3 d-flex flex-column justify-content-center align-items-center">
            <button className="right_button w-100">
              <i className="fas fa-print px-1"></i>
              Print
            </button>
          </div>
          <div className="col-3 d-flex flex-column justify-content-center align-items-center">
            <button className="right_button w-100">
              <i className="fas fa-print px-1"></i>
              Todas
            </button>
          </div>
          <div className="col d-flex flex-column justify-content-center align-items-center">
            <button className="right_button w-100" onClick={showModal}>
              <i className="fas fa-adjust px-1"></i>
              change theme
            </button>
            <Modal
              title="Change Theme"
              open={isModalVisible}
              onCancel={handleCancel}
              width={"30%"}
              onOk={handleOk}
            >
              <div className="d-flex flex-column justify-content-center align-items-center py-2">
                <i className="fas fa-adjust px-1 h1"></i>
              </div>
              <div className="row d-flex flex-column justify-content-center align-items-center">
                <select
                  defaultValue="Choose theme"
                  className="form-select col-6"
                  aria-label="Default select example"
                  onChange={handleChangeTheme}
                >
                  <option disabled>Choose theme</option>
                  <option value="1">Legacy</option>
                  <option value="2">Red</option>
                </select>
              </div>
            </Modal>
          </div>
        </div> */}
            <div className="Tables_right_container">
              {/* close show games */}
              <div className="d-flex flex-row justify-content-end align-items-center gap-2">
                <button className="expand_button w-100" onClick={handleExpand}>
                  <i className="fas fa-times"></i> Close
                </button>
              </div>
              {isLoading ? (
                <div
                  className="spinner-border text-danger z-index-999 position-absolute top-50 start-50"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <div className="table-responsive">
                  <div className="d-flex flex-row justify-content-center align-items-center text-dark">
                    Full Game/Half
                  </div>
                  <table className="table table-sm table-light table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Hora</th>
                        <th scope="col">Cod</th>
                        <th scope="col">Team</th>
                        <th scope="col">ML</th>
                        <th scope="col">RL/T</th>
                        <th scope="col">H1 RL/T</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gamesForUser?.map((game, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              borderBottom: "1px solid #131313",
                            }}
                          >
                            <th
                              scope="row"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {moment(game.time)
                                .tz("America/New_York")
                                .format("hh:mm A")}
                            </th>
                            <th>
                              {game.localTeam.codeRD? game.localTeam.codeRD: game.localTeam.code}
                              <br />
                              {game.visitingTeam.codeRD? game.visitingTeam.codeRD: game.visitingTeam.code}
                            </th>
                            <td>
                              {game.localTeam.name}
                              <br />
                              {game.visitingTeam.name}
                            </td>
                            <td>
                              {game.localLine?.moneyLine?.value}
                              <br />
                              {game.visitingLine?.moneyLine?.value}
                            </td>
                            <td>
                              {game.localLine?.runLine?.value}
                              <br />
                              {game.visitingLine?.runLine?.value}
                            </td>
                            <td>
                              {game.localTeam?.halfRunLine}
                              <br />
                              {game.visitingTeam?.halfRunLine}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        ) : (
          // EXPAND BUTTON
          <div className="w-100 mb-2 d-flex flex-column justify-content-start align-items-center gap-2 px-2 h-100 py-2">
            <div className="">
              <button
                className="expand_button w-100"
                onClick={handleExpand}
              >
                {/* arrow down */}
                <i className="fas fa-angle-double-down"></i> Show Games
              </button>
            </div>
          </div>
        )
      }

    </div>
  );
}
