import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './scores.scss';
import sportsLogo from '../../../assets/img/sports-logo-.png';
import { redApi } from '../../../config/Axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';

export default function Score() {

  const { league } = useParams();
  const [leagues, setLeagues] = useState([]);
  const [games, setGames] = useState([]);

  useEffect(() => {
    redApi.get('/leagues')
      .then(res => {
        setLeagues(res.data);
      })
  }, [])

  useEffect(() => {
    if (league.toLowerCase() === 'top') {
      redApi.get(`/games?date=${moment().format('YYYY-MM-DD')}`)
        .then(res => {
          setGames(res.data);
        })
      return;
    }
    redApi.get(`/games?league=${league}&date=${moment().format('YYYY-MM-DD')}`)
      .then(res => {
        setGames(res.data);
      })
  }, [league])



  return (
    <section id="pageScores" className="main-sports-wrapper container-fluid">
      <div className="row">
        <header className="col-12 col-lg-2 p-3 sbg-header-score text-white d-flex align-items-start">
          <nav className="navbar col-12 navbar-dark p-0 navbar-expand-lg">
            <div id="navbarScores" className="container-fluid d-flex flex-lg-column flex-row">
              <div className="col-12 d-flex justify-content-between">
                <NavLink className="navbar-brand scorelogo-header" to="/"><img src={sportsLogo} /></NavLink>
                <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navSidebarScores" aria-controls="navSidebarScores"
                  aria-expanded="false" aria-label="Toggle navigation">
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="#000" className="bi bi-list" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </button>
              </div>
              <div className="col-12">
                <div className="match-nav-main collapse navbar-collapse" id="navSidebarScores">
                  <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                    <li className="nav-item"><NavLink exact className="nav-link px-2" to="/" >Sports</NavLink></li>
                    <li className="nav-item"><NavLink exact className="nav-link active px-2" to="/client/score" >Scores</NavLink></li>
                    <li className="nav-item"><a href="#" className="nav-link px-2">Reports</a></li>
                    <li className="nav-item"><a href="#" className="nav-link px-2">Lottery</a></li>
                    <li className="nav-item"><a href="#" className="nav-link px-2">Roulette</a></li>
                    <li className="nav-item"><a href="#" className="nav-link px-2">Horses</a></li>
                    {/* <div className="dropdown drop-pok-btn sidebar">
                      <button className="btn dropdown-toggle" type="button" id="dropdownMenuButtong" data-bs-toggle="dropdown" aria-expanded="false">
                        MLB
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtong">
                        <li><a className="dropdown-item" href="ncaaf.html">NCAAF</a></li>
                        <li><a className="dropdown-item" href="nba.html">NBA</a></li>
                        <li><a className="dropdown-item" href="nhl.html">NHL</a></li>
                        <li><a className="dropdown-item" href="nfl.html">NFL</a></li>
                        <li><a className="dropdown-item" href="ncaab.html">NCAAB</a></li>
                      </ul>
                    </div> */}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-10 col-lg-12">
                <div id="menuIcons" className="row d-flex flex-row mb-3 mb-lg-0 justify-content-center">
                  <div className="col-auto">
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg>
                    </a>
                  </div>
                  <div className="col-auto">
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-files" viewBox="0 0 16 16">
                        <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                      </svg>
                    </a>
                  </div>
                  <div className="col-auto">
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                      </svg>
                    </a>
                  </div>
                  <div className="col-auto">
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart-line-fill" viewBox="0 0 16 16">
                        <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z" />
                      </svg>
                    </a>
                  </div>
                  <div className="col-auto">
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                    </a>
                  </div>
                  <div className="col-auto">
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
                      </svg>
                    </a>
                  </div>
                  <div className="col-auto">
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                        <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                      </svg>
                    </a>
                  </div>
                  {/* <!-- <a href="#"></a> --> */}
                </div>
              </div>
              <div className="col-12 col-sm-2 col-lg-12 d-flex justify-content-center">
                <div className="dropdown drop-pok-btn">
                  <button className="btn dropdown-toggle" type="button" id="dropdownLanguage" data-bs-toggle="dropdown" aria-expanded="false">
                    EN
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownLanguage">
                    <li><a className="dropdown-item" href="./">EN</a></li>
                    <li><a className="dropdown-item" href="./es/">ES</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <main className="col-12 col-lg-10 mainStyles">
          <div className="side-wrapper px-5">
            <div className="row g-0">
              <div className="col-12">
                <nav id="navbarSportsScores" className="navbar navbar-dark p-0 navbar-expand-lg">
                  <div className="container">
                    <span className="navbar-brand sbrand-header">SCORES</span>
                    <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse"
                      data-bs-target="#navSportScore" aria-controls="navSportScore"
                      aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                      </span>
                    </button>
                    <div className="match-nav-main collapse navbar-collapse" id="navSportScore">
                      <ul className="navbar-nav mx-auto mb-2 pt-4 mb-lg-0">
                        <li className="nav-item">
                          <NavLink to="/client/scores/top" className="nav-link px-2">TOP</NavLink>
                        </li>
                        {
                          leagues.slice(0, 5).map((league, index) => {
                            return (
                              <li className="nav-item" key={index}>
                                <NavLink to={`/client/scores/${league._id}`} className="nav-link px-2">{league.name}</NavLink>
                              </li>
                            )
                          })
                        }
                        <div className="dropdown drop-pok-btn">
                          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButtong" data-bs-toggle="dropdown" aria-expanded="false">
                            MORE
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtong">
                            {
                              leagues.slice(5, leagues.length).map((league, index) => {
                                return (
                                  <li key={index}>
                                    <NavLink to={`/client/scores/${league._id}`} className="dropdown-item">{league.name}</NavLink>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </ul>

                    </div>
                  </div>
                </nav>
              </div>
              <div id="scores-content" className="col-12">
                <div className="row">
                  <div className="col-12 scores-title">
                    <h4>TOP SCORES</h4>
                  </div>
                  <div className="col-12">
                    <h4>TODAY</h4>
                  </div>
                  <div className="col-3 col-space-blank"></div>
                  <div className="col-12 col-md-9">
                    {/* <div className="col-12 col-md-6 d-flex flex-column team">
                      <h5>MLB</h5>
                      <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                        <div className="col-auto d-flex align-items-center">
                          <img src="./assets/img/dodgers.png" alt="" />
                          <span>DODGERS</span>
                          <span className="small align-self-start">1-0</span>
                        </div>
                        <div className="col-auto"><span>35</span></div>
                      </div>
                      <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                        <div className="col-auto d-flex align-items-center">
                          <img src="./assets/img/mets.png" alt="" />
                          <span className="second align-items-start">METS</span>
                          <span className="second small align-self-start">1-0</span>
                        </div>
                        <div className="col-auto"><span className="second">35</span></div>
                      </div>
                      <div className="col-12"><span className="final">FINAL</span></div>
                    </div> */}
                    <div className="row">

                      {
                        games.length > 0 ? games.map((game, index) => {

                          return (
                            <div className="col-12 col-md-6 d-flex flex-column team">
                              <h5>{game.league.name}</h5>
                              <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                <div className="col-auto d-flex align-items-center gap-4">
                                  <img src={game.localTeam.image} alt="" />
                                  <span>{game.localTeam.name}</span>
                                  {/* <span className="small align-self-start">1-0</span> */}
                                </div>
                                <div className="col-auto"><span>{
                                  game.localLine?.scores?.total ? game.localLine?.scores?.total : 0
                                }</span></div>
                              </div>
                              <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                <div className="col-auto d-flex align-items-center gap-4">
                                  <img src="./assets/img/mets.png" alt="" />
                                  <span className="second align-items-start">
                                    {game.visitingTeam.name}
                                  </span>
                                  {/* <span className="second small align-self-start">1-0</span> */}
                                </div>
                                <div className="col-auto"><span className="second">
                                  {game.visitingLine?.scores?.total ? game.visitingLine?.scores?.total : 0}
                                </span></div>
                              </div>
                              <div className="col-12"><span className="final">FINAL</span></div>
                            </div>
                          )
                        }) : <div className="col-12 col-md-6 d-flex flex-column team">
                          no games
                        </div>
                      }
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                                <div className="col-12">
                                    <h4>TOMORROW</h4>
                                </div>
                                <div className="col-2 col-space-blank"></div>
                                <div className="col-12 col-md-10">
                                    <div className="row">
                                        <div className="col-12 col-md-6 d-flex flex-column team">
                                            <h5>MLB</h5>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/dodgers.png" alt=""/>
                                                    <span>DODGERS</span>
                                                    <span className="small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span>12:30PM</span></div>
                                            </div>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/mets.png" alt=""/>
                                                    <span className="second align-items-start">METS</span>
                                                    <span className="second small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span className="second">FOX</span></div>
                                            </div>
                                            <div className="col-12"><span className="final">FINAL</span></div>
                                        </div>
                                        <div className="col-12 col-md-6 d-flex flex-column team">
                                            <h5>MLB</h5>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/dodgers.png" alt=""/>
                                                    <span>DODGERS</span>
                                                    <span className="small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span>12:30PM</span></div>
                                            </div>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/mets.png" alt=""/>
                                                    <span className="second align-items-start">METS</span>
                                                    <span className="second small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span className="second">FOX</span></div>
                                            </div>
                                            <div className="col-12"><span className="final">FINAL</span></div>
                                        </div>
                                        <div className="col-12 col-md-6 d-flex flex-column team">
                                            <h5>MLB</h5>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/dodgers.png" alt=""/>
                                                    <span>DODGERS</span>
                                                    <span className="small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span>12:30PM</span></div>
                                            </div>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/mets.png" alt=""/>
                                                    <span className="second align-items-start">METS</span>
                                                    <span className="second small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span className="second">FOX</span></div>
                                            </div>
                                            <div className="col-12"><span className="final">FINAL</span></div>
                                        </div>
                                        <div className="col-12 col-md-6 d-flex flex-column team">
                                            <h5>MLB</h5>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/dodgers.png" alt=""/>
                                                    <span>DODGERS</span>
                                                    <span className="small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span>12:30PM</span></div>
                                            </div>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/mets.png" alt=""/>
                                                    <span className="second align-items-start">METS</span>
                                                    <span className="second small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span className="second">FOX</span></div>
                                            </div>
                                            <div className="col-12"><span className="final">FINAL</span></div>
                                        </div>
                                        <div className="col-12 col-md-6 d-flex flex-column team">
                                            <h5>MLB</h5>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/dodgers.png" alt=""/>
                                                    <span>DODGERS</span>
                                                    <span className="small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span>12:30PM</span></div>
                                            </div>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/mets.png" alt=""/>
                                                    <span className="second align-items-start">METS</span>
                                                    <span className="second small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span className="second">FOX</span></div>
                                            </div>
                                            <div className="col-12"><span className="final">FINAL</span></div>
                                        </div>
                                        <div className="col-12 col-md-6 d-flex flex-column team">
                                            <h5>MLB</h5>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/dodgers.png" alt=""/>
                                                    <span>DODGERS</span>
                                                    <span className="small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span>12:30PM</span></div>
                                            </div>
                                            <div className="col-12 d-flex team-name flex-row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center">
                                                    <img src="./assets/img/mets.png" alt=""/>
                                                    <span className="second align-items-start">METS</span>
                                                    <span className="second small align-self-start">0-0</span>
                                                </div>
                                                <div className="col-auto"><span className="second">FOX</span></div>
                                            </div>
                                            <div className="col-12"><span className="final">FINAL</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
        </main>
      </div>
    </section>
  )
}
