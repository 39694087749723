import "./styles/chat.scss"
import SendIcon from "../../../../assets/SendIcon.svg"
import greyBot from "../../../../assets/GreyBot.png"
import FormReport from "./FormReport"
import { useEffect, useRef, useState } from "react"
import { socket } from "../../../../socket"
import { redApi } from "../../../../config/Axios"

//Component with Bot message style
function BotMessage({ text, icon }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>

      {icon && <div id="bot-icon">
        <img style={{ height: '25px', width: '25px' }} src={greyBot} alt="" />

      </div>}
      {
        icon ?
          <div id="bot-message">
            <p style={{ color: '#282828', margin: 0 }}>{text}</p>
          </div>
          :
          <div id="bot-message-without-icon">
            <p style={{ color: '#282828', margin: 0 }}>{text}</p>
          </div>
      }
    </div>
  )
}

//Component with User message style

function UserMessage({ text }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div id="user-message">
        <p style={{ color: '#fff', margin: 0 }}>{text}</p>

      </div>
    </div>
  )
}

//Component that init when the chat is open
function FirstStep({ setAction }) {
  return (
    <div>
      <BotMessage text="How can we help you?" icon={true} />
      <div id="selectOption">
        <input id="optionBtn" type="button" value="Submit new ticket" onClick={() => setAction('simpleReport')} />
        <input id="optionBtn" type="button" value="Problem with printer" onClick={() => setAction('printerReport')} />
        <input id="optionBtn" type="button" value="See open ticket" onClick={() => setAction('listReports')} />
      </div>
    </div>
  )
}



export default function Chat({ botImg }) {

  const [messages, setMessage] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [action, setAction] = useState()
  const [ticketCode, setTicketCode] = useState();
  const [SlackTimestamp, setSlackTimestamp] = useState('');
  const [inputValue, setInputValue] = useState('')
  const [submitted, setSubmitted] = useState(true);
  const scrollRef = useRef(null);
 
  const inputRef = useRef(null);

  async function sendNewMessage() {
    setSubmitted(true)

    await redApi.post(`/chatbot/tickets/chat/${ticketCode}`, {
      "message": inputValue,
      "SlackTimestamp": SlackTimestamp,
    }).then((res) => {
      setMessage((prevMessage) => [...prevMessage, {
        isUser: true,
        text: `${inputValue}`
      }])
      //Reset the data of the input and disable button and the input
      setSubmitted(false)
      setInputValue("")
    }).catch((e) => {
      console.log(e, 'ERROR ON CHAT');
    });
  }

  // Manejar el cambio de los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue(value);
  };

  //Send the message when press Enter
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendNewMessage()
    }
  }

  useEffect(() => {
    if (action == 'simpleReport') {
      setMessage((prevMessage) => [...prevMessage, {
        isUser: false,
        text: `Your report has been submitted with the ID ${ticketCode}. You will receive a response from the support team soon.`,
        icon: true
      }])
      setShowMessage(true)
      setSubmitted(false)

    } else if (action == 'printerReport') {
      setMessage((prevMessage) => [...prevMessage, {
        isUser: false,
        text: `Your report has been submitted with the ID ${ticketCode}. Allow up to 30 minutes for this installation to happen.`,
        icon: true
      }])
      setShowMessage(true)
      setSubmitted(false)

    }
  }, [ticketCode])

  useEffect(() => {

    socket.on('messageSlack', async ({ message, status, whoWasTheLast }) => {
      setMessage((prevMessage) => [...prevMessage, {
        isUser: false,
        text: `${message}`,
        icon: !whoWasTheLast
      }])

      //If the status of the ticket is completed, the ticket is closed and the input is disable
      if (status == 'Completed') {
        setSubmitted(true)
      }
    });

    // Asegúrate de limpiar el manejador de eventos cuando el componente se desmonte
    return () => {
      socket.off('messageSlack');
    };
  }, [])

  //Position the chat at the height of the component that is active
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [action]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [inputRef]);

  return (
    <div id="container" >

      <div id="topBar">
        <div id="imgColumn">
          <img src={botImg} alt="" />
        </div>
        <div id="textColumn" >
          <span>ChatBot</span>
          <div id="onlineRow">
            <div id="greenCircle"></div>
            <span style={{ fontSize: '12px' }}>Online</span>
          </div>
        </div>
        <div id="textColumn2">
          {/* <input id="logoutBtn" type="button" value="Logout" /> */}


        </div>
      </div>
      <div id="messageBoard">
        <div style={{ margin: '9px' }}>
          <FirstStep setAction={setAction} />
        </div>

        {
          action && <div ref={scrollRef} style={{ margin: '12px' }}>
            <UserMessage text={action} />
          </div>
        }
        {
          action && <div ref={scrollRef} style={{ marginLeft: '15px', marginRight: '15px' }}>
            <FormReport type={action} setTicketCode={setTicketCode} setSlackTimestamp={setSlackTimestamp} inputRef={inputRef}/>
          </div>
        }
        {showMessage && <div >
          {messages.map((message, index) => (
            <div ref={scrollRef} key={index} style={{ margin: '9px' }} >
              {message.isUser ? (
                <UserMessage text={message.text} />
              ) : (
                <BotMessage text={message.text} icon={message.icon} />
              )}
            </div>
          ))}
        </div>}
      </div>
      <hr />

      <div id="bottomBar" >
        <input id="input" type="text" placeholder="Ask your question..."
          name="msg"
          value={inputValue}
          onChange={handleChange}
          disabled={submitted}
          onKeyPress={handleKeyPress} 
        
          />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <input type="image" src={SendIcon} style={{ border: '0' }} onClick={sendNewMessage} disabled={submitted} />
        </div>
      </div>

    </div>
  )
}