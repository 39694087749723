import AppRouter from './router/AppRouter';
import { redApi } from './config/Axios';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import { socket } from './socket';
const App = () => {

  const cookies = new Cookies();
  useEffect(() => {
    if (cookies.get('jwt')) {
      redApi.defaults.headers.common['Authorization'] = `Bearer ${cookies.get('jwt')}`;
    }
  }, [cookies]);

  socket.on('userBlock', () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: `Has sido bloqueado`
    }).then((result) => {
      window.location.href = '/';
    })
    cookies.remove('jwt', { path: "/" });
  })

  socket.on('userLogout', () => {
    console.log("DEBERIA DESCONECTARSE AQUIII")
    console.log("LLEGANDO LA DESCONEXIÓN")
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: `Has sido desconectado`,
      timer: 5000
    }).then((result) => {
      cookies.remove('jwt', { path: "/" });
      cookies.remove('userId', { path: "/" });
      sessionStorage.clear()
      window.location.href = '/';
    })
  });

  socket.on('warnSession', () => {
    Swal.fire({
      title: "¿Deseas extender la sesión?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Si`,
      denyButtonText: `No`,
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          console.log("REFRES TOKEN")
          const res = await redApi.post(`/refresh`)

        if (res.data.token) {
            // const jwt = res.data.token;
            // axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
            // axios.defaults.headers.common['xsrfCookieName'] = 'jwt';
            // axios.defaults.headers.common['xsrfHeaderName'] = 'jwt';
            // document.cookie = `jwt=${jwt}`;
            sessionStorage.setItem("token", res.data.token);
            // console.log(axios.defaults.headers.common, 'headers');
        }
        } else if (result.isDenied) {
          await Swal.fire("Se cerrará la sesión en menos de un minuto");
        }
      })
  });

  socket.on('message', (data) => {
    console.log({ data }, 'here');
    Swal.fire({
      icon: 'info',
      title: 'Mensaje',
      text: `${data.message}`
    })
  });



  socket.on('connect', async () => {
    if (!!sessionStorage.userId) {
      socket.on(process.env.ws_namespace || "").emit('reconnectingUser', sessionStorage.userId)
    }
  })

  return (
    <AppRouter />
  );
}

export default App;
