import moment from 'moment'
import "./TableDinamic.scss"
import {
    getClassNameForInput,
    getStyleForInput,
    getValueForHandleStatusGame,
    getValueForInput,
    parseTeamName,
    parseTeamOrPitcherName,
} from '../../../../../HyLUtils/functions';
import { valuesCheck } from '../../../../../utils/MonyLayDictionary';
import { useCallback } from 'react';
import NewTableSticky from './NewTableSticky';
const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
export const totals = ["total", "posPlus", "posMinus", "HGavela", "HOver", "HUnder", "totalQ1", "totalQ2", "totalQ3", "totalQ4", "priceQ1Over", "priceQ2Over", "priceQ3Over", "priceQ4Over", "priceQ1Under", "priceQ2Under", "priceQ3Under", "priceQ4Under", "totalT1", "totalT2", "totalT3", "priceT1Over", "priceT2Over", "priceT3Over", "priceT1Under", "priceT2Under", "priceT3Under"];
export const TableDinamic = ({
    // TrHeadOnClick,
    // TronDoubleClick,
    botSelected,
    games,
    isLoading,
    handleStatusGame,
    handleVisibleForGames,
    handleVisibleForLeagues,
    head = [],
    isPonches,
    lineahandleChange,
    lineahandleClick,
    lineIdSelected,
    selectLineId,
    linesChanged: { verifyOnChangeLines, linesChanged, linesChangedHashMap },
    onDblClickByLineMode,
    putAuthLine
}) => {

    const handleMovingWithKeys = useCallback((e, reftr, refinput, team) => {
        if (arrowKeys.includes(e.key)) {
            e.preventDefault();
            let htmlElement;
            switch (e.key) {
                case "ArrowUp":
                    if (team === "local") {
                        htmlElement = document.querySelector(`[data-reftr="${reftr - 1}"] [data-refinput="${refinput}visiting"]`);
                    } else {
                        htmlElement = document.querySelector(`[data-reftr="${reftr}"] [data-refinput="${refinput}local"]`);
                    }
                    break;
                case "ArrowDown":
                    if (team === "local") {
                        htmlElement = document.querySelector(`[data-reftr="${reftr}"] [data-refinput="${refinput}visiting"]`);
                    } else {
                        htmlElement = document.querySelector(`[data-reftr="${reftr + 1}"] [data-refinput="${refinput}local"]`);
                    }
                    break;
                case "ArrowLeft":
                    htmlElement = document.querySelector(`[data-reftr="${reftr}"] [data-refinput="${refinput - 1}${team}"]`);
                    break;
                case "ArrowRight":
                    htmlElement = document.querySelector(`[data-reftr="${reftr}"] [data-refinput="${refinput + 1}${team}"]`);
                    break;
                default: break;
            }
            // console.log({htmlElement})
            if (!htmlElement) return;
            htmlElement.selectionStart = htmlElement.selectionEnd = htmlElement.value.length;
            htmlElement.click();
            htmlElement.focus();
        }
    }, [])


    const getRangeKey = (value, ranges) => {
        const number = Math.abs(Number(value)); 
       
    
        for (const range in ranges) {
            const trimmedRange = range.trim(); 
        
    
            const [minStr, maxStr] = trimmedRange.split('_to_');
            const min = Math.abs(Number(minStr)); 
            const max = Math.abs(Number(maxStr)); 
           
            if (isNaN(min) || isNaN(max)) {
                continue;
            }
    
            const isGreaterOrEqual = number >= min;

            const isLessOrEqual = number <= max;

    
            if (isGreaterOrEqual && isLessOrEqual) {
                return range;
            }
        }
        return null;
    };
    

    const checkValue = (e, line, team, prop, gameChange, indexGame) => {
        
        const moneyValue = Number(gameChange.localLine.moneyLine.value) < 0 ? gameChange.localLine.moneyLine.value : gameChange.visitingLine.moneyLine.value
        let valueref = e.target.value.charAt(0) == '+' ? e.target.value.slice(1) : e.target.value;
        if (prop === 'strikeoutsGavela') valueref = 'any'
        // if (prop === 'superGavela') valueref = 'any'
        // const firstValue = prop == 'superGavela' ? valuesCheck[prop][moneyValue] : valuesCheck[prop]
        // if (prop == 'sia') valueref = 'any'
        let firstValue;
        if (prop === 'superGavela') {
        
            const rangeKey = getRangeKey(Number(moneyValue), valuesCheck[prop]);
            firstValue = rangeKey ? valuesCheck[prop][rangeKey] : null;
        } else {
            firstValue = valuesCheck[prop]
        }
        const valuesToChange = firstValue ? firstValue[valueref] : null
        if (valuesToChange) {
            if (valuesToChange.counter) changeValues(team == 'visiting' ? 'localLine' : 'visitingLine', valuesToChange.counter, indexGame, e, line)
            if (valuesToChange.self) changeValues(team == 'visiting' ? 'visitingLine' : 'localLine', valuesToChange.self, indexGame, e, line)
        }

        // games[indexGame].localLine.moneyLine.value = 222

    }

    const changeValues = (lineTeam, values, game, e, line) => {
        for (const value in values) {
            games[game][lineTeam][value].value = values[value]
            verifyOnChangeLines(e, games[game][lineTeam]._id, lineTeam == "localLine" ? 'local' : 'visiting', value, game, true, values[value])
        }
    }
    return (
        <div id='table-dinamic'>
            {/* <NewTableSticky handleVisibleForLeagues={handleVisibleForLeagues} head={head} isLoading={isLoading} games={games} handleStatusGame={handleStatusGame} getValueForHandleStatusGame={getValueForHandleStatusGame} parseTeamOrPitcherName={parseTeamOrPitcherName} isPonches={isPonches} parseTeamName={parseTeamName} botSelected={botSelected} getClassNameForInput={getClassNameForInput} lineIdSelected={lineIdSelected} linesChanged={linesChanged} getValueForInput={getValueForInput} linesChangedHashMap={linesChangedHashMap} getStyleForInput={getStyleForInput} totals={totals} /> */}
            <table
                className={`${head?.length > 0 ? 'table-simple' : ''}`}
            >
                <thead>
                    <tr onClick={() => handleVisibleForLeagues()}>
                        <th>
                            Hora
                        </th>
                        <th style={{
                            minWidth: "250px"
                        }}>
                            Juegos Actuales
                        </th>
                        {
                            head.map((item, index) => (
                                <th key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>{item.name}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {

                        isLoading
                            ? <LoadingTable tableLength={head.length} />
                            : games[0] && games.map((game, indexGame) => (
                                <tr data-reftr={indexGame} key={indexGame} onDoubleClick={() => onDblClickByLineMode(game, indexGame)}
                                >
                                    <td className='default'>
                                        <div className="d-flex gap-4">
                                            <div className="d-flex flex-column px-1">
                                                <div
                                                    style={{
                                                        fontSize: "10px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {moment(game.time).format("MM/DD")}
                                                </div>
                                                <div style={{
                                                    fontSize: "12px",
                                                    fontWeight: "900",
                                                    whiteSpace: "nowrap"
                                                }}>
                                                    {moment(game.time).format("hh:mm A")}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={`default ${game.status}`}
                                        style={{
                                            minWidth: "250px"
                                        }}
                                    >
                                        <div
                                            className='d-flex justify-content-end align-items-center'
                                        >
                                            <div
                                                onClick={() => handleStatusGame(game._id, getValueForHandleStatusGame(game.status))}
                                                className={`d-flex flex-column  games_container `}
                                            >
                                                <div className="d-flex align-items-center gap-1">
                                                    <img src={game?.localTeam?.image}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "/logos/logodefault.png";
                                                        }}
                                                        alt="" />
                                                    {" | "}
                                                    <span>
                                                        {localStorage.getItem('userCodesTeams') == 'rd' ? game?.localTeam?.codeRD : game?.localTeam?.code}
                                                    </span>
                                                    {" - "}
                                                    <span>
                                                        {game.manual ? `${parseTeamOrPitcherName(game?.localTeam?.name, isPonches, game.localPitcher)}(M)` : parseTeamOrPitcherName(game?.localTeam?.name, isPonches, game.localPitcher)}
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center gap-1">
                                                    <img src={game.visitingTeam?.image}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "/logos/logodefault.png";
                                                        }}
                                                        alt="" />
                                                    {" | "}
                                                    <span>
                                                        {localStorage.getItem('userCodesTeams') == 'rd' ? game.visitingTeam?.codeRD : game.visitingTeam?.code}
                                                    </span>
                                                    {" - "}
                                                    <span>
                                                        {game.manual ? `${parseTeamName(game.visitingTeam?.name)}(M)` :parseTeamName(game.visitingTeam?.name)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    {
                                        head && game.localLine && head?.map((item, index) => {
                                            let botName = botSelected.split('/')[0]
                                            let sportBookName
                                            if (botSelected.split('/')[1]) {
                                                sportBookName = botSelected.split('/')[1]
                                            }
                                            return (
                                                <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}
                                                >
                                                    <div className="container_inputs">
                                                        <div className={getClassNameForInput(game.localLine, game.league, item.property, sportBookName, botName, lineIdSelected, linesChanged, "local")}
                                                        >
                                                            <input
                                                                type="text"
                                                                data-valueref={game.localLine[item.property].value}
                                                                value={getValueForInput(
                                                                    game.localLine[item.property].value,
                                                                    game.localLine._id + "local" + item.property,
                                                                    linesChanged,
                                                                    linesChangedHashMap,
                                                                )}
                                                                onChange={async (e) => {

                                                                    let maxLength;
                                                                    switch (item.name) {
                                                                    
                                                                        case "TOTAL":
                                                                            maxLength = 4
                                                                            break;    
                                                                        case "SOLO":
                                                                            maxLength = 4
                                                                            break; 
                                                                        case "TOTAL H":
                                                                            maxLength = 4
                                                                            break; 
                                                                        case "SOLO H":
                                                                            maxLength = 4
                                                                            break; 
                                                                        default:
                                                                            maxLength = 4
                                                                            break; 
                                                                    }
                                                                    if (e.target.value.length > maxLength) {
                                                                        e.target.value = e.target.value.slice(0, maxLength);
                                                                        return;
                                                                    }
                                                                    // lineahandleChange( e, index = indexGame, "localLine", item.property );
                                                                    if (e.target.value.toLowerCase().includes('n')) e.target.value = 0
                                                                    if (e.target.value.toLowerCase().includes('a')) {
                                                                        await putAuthLine()
                                                                        return
                                                                    }
                                                                    if (e.target.value.length !== 1 && e.target.value.charAt(0) == 0) e.target.value = e.target.value.slice(1)
                                                                    if (!totals.includes(item.property) && Number(e.target.value) > 0 && !e.target.value.includes('+')) {
                                                                        e.target.value = item.name === "PONCHE" ? + e.target.value : item.name === "SOLO" ? e.target.value :  '+' + e.target.value
                                                                    }
                                                                    // if(e.target.value.includes('+')) e.target.value =  e.target.value.replace("+", "")
                                                                    checkValue(e, game.localLine._id, "local", item.property, game, indexGame)
                                                                    verifyOnChangeLines(e, game.localLine._id, "local", item.property, game)
                                                                }}
                                                                onClick={(el) => {
                                                                    el.target.selectionStart = el.target.value.length
                                                                    el.target.selectionEnd = el.target.value.length
                                                                    lineahandleClick(game.localLine._id,
                                                                        item.property,
                                                                        game.localLine[item.property]?.value,
                                                                        "localLine"
                                                                    );
                                                                    selectLineId(game.localLine._id + item.property)
                                                                }}
                                                                onKeyDown={(e) => handleMovingWithKeys(e, indexGame, index, "local")}
                                                                data-refinput={index + "local"}
                                                                data-refid={game.localLine._id + item.property}
                                                                style={getStyleForInput(game.localLine, game.league, item.property, sportBookName, botName, head, index)}
                                                            />
                                                        </div>
                                                        <div className={getClassNameForInput(game.visitingLine, game.league, item.property, sportBookName, botName, lineIdSelected, linesChanged, "visiting")}>
                                                            <input
                                                                type="text"
                                                                data-valueref={game.visitingLine[item.property].value}
                                                                value={totals.includes(item.property) ? '~' : getValueForInput(
                                                                    game.visitingLine[item.property].value,
                                                                    game.visitingLine._id + "visiting" + item.property,
                                                                    linesChanged,
                                                                    linesChangedHashMap,
                                                                )}
                                                                onChange={async (e) => {

                                                                    let maxLength;
                                                                    switch (item.name) {
                                                                    
                                                                        case "TOTAL":
                                                                            maxLength = 4
                                                                            break;    
                                                                        case "SOLO":
                                                                            maxLength = 4
                                                                            break; 
                                                                        case "TOTAL H":
                                                                            maxLength = 4
                                                                            break; 
                                                                        case "SOLO H":
                                                                            maxLength = 4
                                                                            break; 
                                                                        default:
                                                                            maxLength = 4
                                                                            break; 
                                                                    }
                                                                    if (e.target.value.length > maxLength) {
                                                                        e.target.value = e.target.value.slice(0, maxLength);
                                                                        return;
                                                                    }

                                                                    if (e.target.value.toLowerCase().includes('n')) e.target.value = 0
                                                                    if (e.target.value.toLowerCase().includes('a')) {
                                                                        await putAuthLine()
                                                                        return
                                                                    }



                                                                    if (e.target.value.length !== 1 && e.target.value.charAt(0) == 0) e.target.value = e.target.value.slice(1)
                                                                    if (!totals.includes(item.property) && Number(e.target.value) > 0 && !e.target.value.includes('+')) {
                                                                        e.target.value = item.name === "PONCHE" ? + e.target.value : item.name === "SOLO" ? e.target.value  :  '+' + e.target.value
                                                                    }
                                                                    // if(e.target.value.includes('+')) e.target.value =  e.target.value.replace("+", "")

                                                                    checkValue(e, game.visitingLine._id, "visiting", item.property, game, indexGame)
                                                                    verifyOnChangeLines(e, game.visitingLine._id, "visiting", item.property, game)
                                                                }}
                                                                onClick={(el) => {
                                                                    el.target.selectionStart = el.target.value.length
                                                                    el.target.selectionEnd = el.target.value.length
                                                                    lineahandleClick(game.visitingLine._id,
                                                                        item.property,
                                                                        game.visitingLine[item.property]?.value,
                                                                        "visitingLine"
                                                                    );
                                                                    selectLineId(game.visitingLine._id + item.property)
                                                                }}
                                                                onKeyDown={(e) => handleMovingWithKeys(e, indexGame, index, "visiting")}
                                                                data-refinput={index + "visiting"}
                                                                data-refid={game.visitingLine._id + item.property}
                                                                style={getStyleForInput(game.visitingLine, game.league, item.property, sportBookName, botName, head, index)}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            ))
                    }
                </tbody>
            </table>
        </div>
    )
}



export const LoadingTable = ({ tableLength = 0 }) => {
    return (
        <tr>
            <td className='text-center' colSpan={2 + tableLength}>Cargando...</td>
        </tr>
    )
}