import React from 'react'

const MyCustomers_t4_mobile = ({ setMenuActive, menuActive }) => {
  return (
    <div className='sub_menu-container'>
      <button onClick={() => setMenuActive('')}>
        <i className="fas fa-arrow-left"></i>
      </button>
      <div className='container_sub_menu'>
        <div>
          <label htmlFor="selectUser" style={{ fontSize: '1.3rem' }}>
            <i className="fas fa-users" style={{ marginRight: '10px' }}></i>
            MY CUSTOMERS
          </label>
        </div>
        <div>
          <button className='d-flex align-items-center gap-1 justify-content-center'>
            <i className="fas fa-user-plus"></i>
            NEW ACCOUNT
          </button>
        </div>
        <div className='bottom_container'>
          <div className='tables_container'>
            <table>
              <thead>
                <tr>
                  <th>
                    OWNER
                  </th>
                  <th>
                    USERNAME
                  </th>
                  <th>
                    CREATED
                  </th>
                  <th>
                    BALANCE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <div role='alert' className='alert alert-danger d-flex justify-content-center align-items-center'>
                      No accounts found
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyCustomers_t4_mobile