import { redApi } from "../config/Axios";

export class UserServices {

  static async getUsers({ userLevel = undefined, ownedBy = undefined }) {
    const params = {
      populates: "limits,income,leagues,ownedBy",
    };
    if (userLevel) params.userLevel = userLevel
    if (ownedBy) params.ownedBy = ownedBy
    const { data: users } = await redApi.get("/users", { params });
    return users
  }

  static async createUser(payload) {
    const { data } = await redApi.post(`users`, payload);
    return data;
  }

  static async updateUser(id, payload) {
    const { data } = await redApi.put(`users/${id}`, payload);
    return data;
  }

  static async getUserPrivileges(section) {
    const { data } = await redApi.get('/verify');
    // console.log({ data })
    if (data.userLevel !== 10) return;

    const allPrivileges = [];
    const privileges = data.isGroup;
    if (privileges) {
      const { privilegesAdmin, privilegesPos } = privileges;
      if (privilegesAdmin) {
        privilegesAdmin.forEach((privilege) => {
          allPrivileges.push({
            name: privilege.name,
            // if (child.checked) return child.value
            values: privilege.children.filter((child) => child.checked).map((child) => child.value)
          })
        })
      }

      if (privilegesPos) {
        const values = []
        privilegesPos.forEach(privilege => {
          if (privilege.checked == true)
            values.push(privilege.value)
        });
        allPrivileges.push({
          name: 'privilegesPos',
          values,
          isPos: true
        })
      }


      if (section === 'Configuracion') {
        // get all privileges from Terminales, Consorcio & Usuarios.
        const terminales = allPrivileges.find((privilege) => privilege.name === 'Terminales')
        const consorcio = allPrivileges.find((privilege) => privilege.name === 'Consorcio')
        const usuarios = allPrivileges.find((privilege) => privilege.name === 'Usuarios')

        allPrivileges.push({
          name: 'Configuracion',
          values: [...terminales.values, ...consorcio.values, ...usuarios.values]
        })
      }
      const findPrivilege = { values: [] }
      allPrivileges.forEach((privilege) => {
        findPrivilege.values = [...findPrivilege.values, ...privilege.values]
      })

      return findPrivilege;
    }
  }

}