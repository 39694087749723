import React from 'react'

function Boxing() {
  return (
    <div className="rules-sport">
      <h1>
        Boxing
      </h1>
      <div>
        <ul>
          <li>
            Boxing can be played in various ways.
          </li>
          <li>
            To the Winner (participant who wins the fight).
          </li>
          <li>
            To Tie (when the result is considered a tie).
          </li>
          <li>
            Round Total: It is played that the fight will last more or less than certain Round numbers.
          </li>
        </ul>
      </div>
      <div>
        <h2>
          General Rules
        </h2>
        <ul>
          <li>
            Plays are accepted until the beginning of the hour of said fight.
          </li>
          <li>
            For total round plays, for example 9 1/2 means that nine round must be completed and half of the tenth round covered.
          </li>
          <li>
            The decision of the regulatory body is made on the official day of the fight.
          </li>
          <li>
            If a tie play option is offered, all Winner plays for both entrants are considered losers. (In case the fight is a tie).
          </li>
          <li>
            If the number of scheduled rounds is varied, the plays in this modality are canceled.
          </li>
          <li>
            All plays to the Winner will be decided at the discretion of the judges of the fight. This decision includes scoring, Technical Knock Out, Knock Out or disqualification.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Boxing