



export const getRedValueClassName = (isTrue, aditionalClassName = "", oppositeClassName = "") => {
    if (isTrue) {
        return ('text-danger ' + aditionalClassName).trim()
    } else {
        return `${oppositeClassName} ${aditionalClassName}`.trim();
    }
}