import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Input, Select, message } from 'antd'
import { redApi } from '../../../../../config/Axios'
import Swal from 'sweetalert2'

const General = (props) => {
    let user = props?.usuario

    const handleInputChange = (e) => {
        props.setData({
            ...props.data,
            [e.target.name]: e.target.value
        })
    }

    const [allgroups, setAllgroups] = useState([])

    useEffect(() => {
        redApi.get(`/users/groups/get/name?name=WebUsers`)
            .then(res => {
                setAllgroups(res.data)
            })
            .catch(err => console.log(err));
    }, [])

    useEffect(() => {
        props.setData({
            ...props.data,
            isGroup: allgroups._id
        })
    }, [allgroups])

    const [showPassword, setShowPassword] = useState(false);
    return (
        <div className="row">
            {/* <div className="col-12">
                  <label>Nombres</label>
                  <input type="text" className="form-control" defaultValue={user ? user : null} />
              </div>
              <div className="col-12">
                  <label>Apellidos</label>
                  <input type="text" className="form-control" defaultValue={user ? user : null} />
              </div> */}
            <div className="col-12">
                <label>username</label>
                <input type="text" className="form-control" defaultValue={user?.username || ''} name="username" onChange={handleInputChange} disabled={props.modalEditar ? true : false} />
            </div>
            <div className="col-12">
                <label>password</label>
                <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder={props.modalEditar ? "********" : ""}
                    name="password"
                    onChange={handleInputChange}
                    value={props.modalEditar ? '' : props.data.password}
                    disabled={props.modalEditar ? true : false}
                />
                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={showPassword}
                        onChange={() => setShowPassword(!showPassword)}
                    />
                    <label className="form-check-label">Show Password</label>
                </div>
            </div>
            <div className="col-12">
                <label>Group</label>
                <select className="form-control" name='isGroup' onChange={handleInputChange} value={allgroups._id || ''} disabled>
                    <option value={allgroups._id} selected>{allgroups.name}</option>
                </select>
            </div>
            <div className="col-12">
                <label>Email</label>
                <input type="email" className="form-control" defaultValue={user ? user.email : null} name="email" onChange={handleInputChange} />
            </div>
            <div className="col-12">
                <label>Terminal</label>
                <select className="form-control" name="ownedBy" onChange={handleInputChange} disabled>
                    <option>{props?.terminalLogged?.username}</option>
                    {/* {
                        props.allConsorcios?.map((consorcio, index) => {
                            return (
                                <option key={index} value={consorcio._id}>{consorcio.username}</option>
                            )
                        })
                    } */}
                </select>
            </div>
            <div className="col-12 d-flex gap-2">
                {/* <input type="checkbox" className="form-control" style={{ width: '20px' }} />
          <label>Idioma</label> */}
            </div>
        </div>
    )
}
const Horarios = (props) => {
    return (
        <div className="row">
            <div className="col-12 d-flex gap-4 justify-content-start align-items-center">
                <input type="checkbox" className="form-control" style={{ width: '20px', height: '20px' }} />
                <label className="form-label d-flex justify-content-center align-items-center">Activar</label>
            </div>
        </div>
    )
}

const WebuserModal = (props) => {

    const createUser = async () => {
        if (!props.data.username || !props.data.password || !props.data.email || !props.data.isGroup || !props.data.ownedBy) {
            console.log(props.data)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Todos los campos son obligatorios!',
            })
            return
        }
        await redApi.post(`/users/webUser`, props.data)
            .then(res => {
                console.log(res.data)
                Swal.fire({
                    icon: 'success',
                    title: 'Usuario creado',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(err => console.log(err));
    }

    const edituser = async (id) => {
        await redApi.put(`/users/${id}`, props.data)
            .then(res => {
                console.log(res.data)
                Swal.fire({
                    icon: 'success',
                    title: `Usuario ${props.data.username} editado!`,
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo salio mal!',
                })
            });
    }


    const getCurrentUser = async () => {
        await redApi.get(`/verify`)
            .then(res => {
                setTerminalLogged(res.data)
            })
            .catch(err => console.log(err));
    }
    const [terminalLogged, setTerminalLogged] = useState({})

    useEffect(() => {
        getCurrentUser()
    }, [])


    const handleOk = () => {
        if (props.modalEditar) {
            props.setModalEditar(false)
            edituser(props?.usuario?._id)
            // setData({
            //     ...data,
            //     username: data.username === "" ? props.usuario.username : data.username,
            //     password: data.password === "" ? props.usuario.password : data.password,
            //     group: data.isGroup === "" ? props.usuario.isGroup : data.isGroup,
            //     email: data.email === "" ? props.usuario.email : data.email,
            // })
            // props.edituser(data)
        } else if (props.modalCrear) {
            props.setModalCrear(false)
            createUser()
        }
    }

    const handleCancel = () => {
        if (props.modalEditar) {
            props.setModalEditar(false)
        } else if (props.modalCrear) {
            props.setModalCrear(false)
        }
    }

    const renderMenu = () => {
        let menu = ["General"] //, "Horario"
        return menu.map((item, index) => {
            return (
                <li key={index} className="nav-item">
                    <button value={item} onClick={handleTable} className={`nav-buttons ${table === item ? 'active' : ''}`}>{item}</button>
                </li >
            )
        })
    }

    const [table, setTable] = useState('General')

    const handleTable = (e) => {
        setTable(e.target.value)
    }

    const renderTableCreate = () => {
        switch (table) {
            case 'General':
                return <General terminalLogged={terminalLogged} setData={props.setData} data={props.data} modalEditar={props.modalEditar} allgroups={props.allgroups} allConsorcios={props.allConsorcios} />

            case 'Horario':
                return <Horarios />
            default:
                return <General />
        }
    }

    const renderTableEdit = (usuario) => {
        switch (table) {
            case 'General':
                return <General terminalLogged={terminalLogged} usuario={usuario} setData={props.setData} data={props.data} modalEditar={props.modalEditar} allgroups={props.allgroups} allConsorcios={props.allConsorcios} />
            case 'Horario':
                return <Horarios usuario={usuario} />
            default:
                return <General usuario={usuario} />
        }
    }


    return (
        <Modal
            title={props.modalEditar ? 'Editar usuario' : 'Crear usuario'}
            open={props.modalEditar || props.modalCrear}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1280}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    {props.modalEditar ? 'Editar usuario' : 'Crear usuario'}
                </Button>,
            ]}
        >
            <div className="row">
                <div className="col-12">
                    <ul className="nav w-100 d-flex justify-content-start align-items-center">
                        {renderMenu()}
                    </ul>
                </div>
                <div className="col-12">
                    {props.modalEditar ? renderTableEdit(props?.usuario) : renderTableCreate()}
                </div>
            </div>
        </Modal>
    )
}

export default WebuserModal