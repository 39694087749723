import { useState } from "react";
import { redApi } from "../config/Axios";
import moment from "moment";

export const useLines = () => {
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLines = async (league) => {
    try {
      const { data } = await redApi.get(`/games/public?date=${moment().format('YYYY-MM-DD')}&league=${league}`);
      setLines(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getLinesByDate = async (league) => {
    if (!league) return;
    try {
      const { data } = await redApi.get(`/games/public?date=${moment().format('YYYY-MM-DD')}&league=${league}`);
      setLoading(false);
      setLines(data);
    } catch (error) {
      console.log(error);
    }
  };

  return { lines, loading, getLines, getLinesByDate };
};

export const useLeagues = () => {
  const [leagues, setLeagues] = useState([]);
  const [loadingLeagues, setLoadingLeagues] = useState(true);

  const getLeagues = async () => {
    try {
      const { data } = await redApi.get('/leagues/view');
      setLeagues(data);
      setLoadingLeagues(false);
    } catch (error) {
      console.log(error);
    }
  };

  return { leagues, loadingLeagues, getLeagues };
}

export const useTerminals = () => {
  const [terminals, setTerminals] = useState([]);
  const [loadingTerminals, setLoadingTerminals] = useState(true);

  const getTerminals = async () => {
    try {
      const { data } = await redApi.get('/users?userLevel=3');
      setTerminals(data);
      setLoadingTerminals(false);
    } catch (error) {
      console.log(error);
    }
  };

  return [terminals, loadingTerminals, getTerminals];
};
