
import './TableHeader.css';

const TableHeader = ({ headerCasillas, isCasillaDisabled, periods }) => {

  return (
    <thead style={{position:'sticky', top:0, backgroundColor:"transparent", zIndex:1}}>
      <tr>
        <th className={`casilla-header-limit `}>
          Liga
        </th>
        {periods.map((casilla, i) => (
          
          <th
            key={i}
            className={`casilla-header-limit ${i === 0 ? 'first-column' : ''}`}
            style={{
              position: i === 0 ? 'sticky' : 'static',
              left: i === 0 ? 0 : 'auto',
            }}
          >
            {casilla}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
