import React, { useEffect, useState } from 'react'
import { NavLines } from '../layout/NavLines'
import { TableLines } from '../layout/TableLines'
import moment from 'moment'

const LinesGamesVertical = ({ games, leagues }) => {
    const [head, setHead] = useState([]);
    const [head2, setHead2] = useState([]);
    const [currentleague, setCurrentLeague] = useState(null);//new
    useEffect(() => {
        let current = leagues?.find(league => league._id === games?.[0]?.league._id);
        setCurrentLeague(current);//new
        setHead(current?.lineType[0]?.periods[1]?.lines);
        setHead2(current?.lineType[0]?.periods[2]?.lines);
    }, [games, leagues])
    const [tables, setTables] = useState(currentleague?.lineType || []);
    const [tablesToView, setTablesToView] = useState([]);
    useEffect(() => {
        setTables(currentleague?.lineType || []);
    }, [currentleague]);

    function getTablesToView() {
        try {
            tables?.forEach(lineType => {
                lineType?.periods?.forEach(period => {
                    if (games?.length > 5) {
                        period.lines = period.lines.slice(0, 5);
                    }
                });
            });
        } catch (error) {
            console.log({ error });
        }
    }

    useEffect(() => {

        setInterval(() => {
            getTablesToView();
        }, 10000);

        return () => {
            clearInterval();
        }

    }, [tablesToView, currentleague]);


    return (
        <div className="lines-games-vertical__container">
            <NavLines />
            <div className="lines-games-vertical">
                <div className="lines-games-vertical__table_league">

                    <label htmlFor="league">
                        liga:
                    </label>
                    <span>
                        {currentleague?.name}
                    </span>
                    <img src={currentleague?.image} alt="" />
                </div>
                {
                    tables?.map((lineType, indexLineType) => (
                        <div key={indexLineType} className="lines-games-vertical__table mb-4">
                            {
                                lineType?.periods?.map((period, indexPeriod) => (
                                    period.period !== "Completo / 1ra Mitad " && (
                                        <div key={indexPeriod} className="lines-games-vertical__table__period mb-4">
                                            <div className="lines-games-vertical__table__period__title">
                                                <span>{period.period}</span>
                                            </div>
                                            <TableLines head={period.lines} games={games} />
                                        </div>
                                    )
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default LinesGamesVertical