import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../structures/layout'
import moment from 'moment'

export default function Draw() {
    return (
        <Layout>
            <Container className="bg-card p-4">
                <form>
                    <div className="row button-nav mb-4 d-flex align-items-center">
                        <div className="col-12 col-lg-4 mb-2">
                            <label htmlFor="selectLotType">Tipo</label>
                            <select id="selectLotType">
                                <option value="RD">Normal</option>
                                <option value="US">Bolita</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-4 mb-2">
                            <label htmlFor="selectLoteriasSorteos">Loterías</label>
                            <select id="selectLoteriasSorteos">
                                <option value="todas">Todas</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-4 mb-2">
                            <label htmlFor="dateSorteos">Fecha</label>
                            <input type="date" id="dateSorteos" defaultValue={moment().format("YYYY-MM-DD")} />
                        </div>
                        <div className="col-12 col-lg-4 mb-2">
                            <button type="button">Refrescar</button>
                        </div>
                    </div>
                </form>

                <div className="row mb-4">
                    <div className="col-12">
                        <div className="table-responsive-lg">
                            <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Lotería</th>
                                        <th>Fecha</th>
                                        <th>Inicio</th>
                                        <th>Final</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody id="tableLot">
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Container>
        </Layout>
    )
}
