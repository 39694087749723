import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../structures/layout'

export default function Boat() {
  return (
    <Layout>
        <Container className="bg-card p-4">
            <div className="row button-nav mb-4 d-flex align-items-center">
                <div className="col-12 col-md-6 col-lg-auto d-flex justify-content-center">
                    <h3 className="mr-2">Bote</h3>
                </div>
                <div className="col-12 col-md-6 col-lg-auto d-flex justify-content-center">
                    <button data-bs-toggle="modal" data-bs-target="#configuracionBote">Configuración del Bote</button>
                    <div className="modal fade" id="configuracionBote" tabIndex="-1" aria-labelledby="configuracionBoteLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="ConfiguracionBoteLabel">Configuración del Bote</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                <div className="row mb-3">
                                    <div className="table-responsive-lg">
                                        <table className="table table-sm table-bordered table-striped">
                                        <thead id="theadConfigBoat">
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Directo</th>
                                                <th>Pale</th>
                                                <th>Tripleta</th>
                                                <th>Estatus</th>
                                            </tr>
                                        </thead>
                                        <tbody id="tableConfigBoat">
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-center">
                                <button type="button" className="btn btn-ok mb-2">Salvar</button>
                                <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cerrar</button>
                            </div>
                            </div>
                        </div>
                        </div>
                </div>
                <div className="col-12 col-md-6 col-lg-auto d-flex justify-content-center">
                    <a className="navlink-btn" href="/admin/boatbankers">Banqueros</a>
                </div>
            </div>

            <div className="row mb-4">
                <div className="mb-2 col-12 col-md-6">
                    <label htmlFor="lotTypeBote">Tipo</label>
                    <select id="lotTypeBote">
                        <option value="RD">Normal</option>
                        <option value="US">Bolita</option>
                    </select>
                </div>
                <div className="mb-2 col-12 col-md-6">
                    <label htmlFor="selectLoteriaBote">Lotería</label>
                    <select id="selectLoteriaBote">
                        <option value="todas">Todas</option>
                    </select>
                </div>
                <div className="mb-2 col-12 col-sm-6 col-md-auto">
                    <button type="button">Refrescar</button>
                </div>
                <div className="mb-2 col-12 col-sm-6 col-md-auto">
                    <button type="button">Procesar/ Imprimir</button>
                </div>
                <div className="mb-2 col-12 col-sm-6 col-md-auto">
                    <button type="button">Limpiar Botados</button>
                </div>
                <div className="mb-2 col-12 col-sm-6 col-md-auto">
                    <button type="button">Imprimir</button>
                </div>
                
            </div>
            <div id="tableBoatRD" className="row mb-4">
                <div className="col-12 col-lg-4 mb-2">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Directo</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>Vendido</th>
                                        <th>A Botar</th>
                                    </tr>
                                </thead>
                                <tbody id="tableLotDirect">
                                </tbody>
                                <tbody id="totalLotDirect" className="tbodyTotal">
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-2">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Pale</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>Vendido</th>
                                        <th>A Botar</th>
                                    </tr>
                                </thead>
                                <tbody id="tableLotPale">
                                </tbody>
                                <tbody id="totalLotPale" className="tbodyTotal">
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-2">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Tripleta</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>Vendido</th>
                                        <th>A Botar</th>
                                    </tr>
                                </thead>
                                <tbody id="tableLotTriplet">
                                </tbody>
                                <tbody id="totalLotTriplet" className="tbodyTotal">
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="tableBoatUS" className="row mb-4 d-flex justify-content-center d-none">
                <div className="col-12 col-lg-6 mb-2">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Pick 3</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>Vendido</th>
                                        <th>A Botar</th>
                                    </tr>
                                </thead>
                                <tbody id="tableLotDirectUS">
                                </tbody>
                                <tbody id="totalLotDirectUS" className="tbodyTotal">
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mb-2">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Pick 4</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>Vendido</th>
                                        <th>A Botar</th>
                                    </tr>
                                </thead>
                                <tbody id="tableLotPaleUS">
                                </tbody>
                                <tbody id="totalLotPaleUS" className="tbodyTotal">
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-2 d-none">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Venezuela</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>Vendido</th>
                                        <th>A Botar</th>
                                    </tr>
                                </thead>
                                <tbody id="tableLotTripletUS">
                                </tbody>
                                <tbody id="totalLotTripletUS" className="tbodyTotal">
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="mb-2 col-12 col-md-2">
                    <label htmlFor="selectBanqueroBote">Banquero</label>
                </div>
                <div className="mb-2 col-12 col-md-10">
                    <select id="selectBanqueroBote">
                        <option value="todos">Todos</option>
                    </select>
                </div>
                <div className="mb-2 col-12 col-sm-6 col-md-auto">
                    <button type="button">Procesar</button>
                </div>
                <div className="mb-2 col-12 col-sm-6 col-md-auto">
                    <button type="button">Imprimir</button>
                </div>
                <div className="mb-2 col-12 col-sm-6 col-md-auto">
                    <button type="button">Limpiar Botados</button>
                </div>
                
            </div>
            <div id="tableBankRD"  className="row mb-4">
                <div className="col-12 col-lg-4 mb-2">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Directo</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>A Botar</th>
                                        <th>Botados</th>
                                    </tr>
                                </thead>
                                <tbody id="tableBankDirect">
                                </tbody>
                                <tbody id="totalBankDirect" className="tbodyTotal">                                    
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-2">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Pale</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>A Botar</th>
                                        <th>Botados</th>
                                    </tr>
                                </thead>
                                <tbody id="tableBankPale">
                                </tbody>
                                <tbody id="totalBankPale" className="tbodyTotal">
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-2">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Tripleta</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>A Botar</th>
                                        <th>Botados</th>
                                    </tr>
                                </thead>
                                <tbody id="tableBankTriplet">
                                </tbody>
                                <tbody id="totalBankTriplet" className="tbodyTotal">
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="tableBankUS" className="row mb-4 d-flex justify-content-center d-none">
                <div className="col-12 col-lg-6 mb-2">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Pick 3</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>A Botar</th>
                                        <th>Botados</th>
                                    </tr>
                                </thead>
                                <tbody id="tableBankDirectUS">
                                </tbody>
                                <tbody id="totalBankDirectUS" className="tbodyTotal">
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mb-2">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Pick 4</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>A Botar</th>
                                        <th>Botados</th>
                                    </tr>
                                </thead>
                                <tbody id="tableBankPaleUS">
                                </tbody>
                                <tbody id="totalBankPaleUS" className="tbodyTotal">
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-2 d-none">
                    <div className="row">
                        <div className='col-12'>
                            <h5>Venezuela</h5>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Números</th>
                                        <th>A Botar</th>
                                        <th>Botados</th>
                                    </tr>
                                </thead>
                                <tbody id="tableBankTripletUS">
                                </tbody>
                                <tbody id="totalBankTripletUS" className="tbodyTotal">
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="configBoatForLotto" tabIndex="-1" aria-labelledby="configBoatForLottoLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="ConfigBoatForLottoLabel">Configuración del Bote</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div id="setBoatRD" className="row mb-3">
                                <div className="col-12 col-lg-4">
                                    <label htmlFor="directSetBoat">Directo</label>
                                    <input type="number" min="0" id="directSetBoat"/>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <label htmlFor="paleSetBoat">Pale</label>
                                    <input type="number" min="0" id="paleSetBoat"/>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <label htmlFor="tripletSetBoat">Tripleta</label>
                                    <input type="number" min="0" id="tripletSetBoat"/>
                                </div>
                            </div>
                            <div id="setBoatUS" className="row mb-3 d-flex justify-content-center">
                                <div className="col-12 col-lg-4">
                                    <label htmlFor="pick3SetBoat">Pick 3</label>
                                    <input type="number" min="0" id="pick3SetBoat"/>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <label htmlFor="pick4SetBoat">Pick 4</label>
                                    <input type="number" min="0" id="pick4SetBoat"/>
                                </div>
                                <div className="col-12 col-lg-4 d-none">
                                    <label htmlFor="venezuelaSetBoat">Venezuela</label>
                                    <input type="number" min="0" id="venezuelaSetBoat"/>
                                </div>
                            </div>
                            <div className="row mb-3 d-flex justify-content-center">
                                <div className="col-12 col-lg-auto">
                                    <button type="button">Procesar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-center">
                        <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal"  data-bs-toggle="modal" data-bs-target="#configuracionBote">Volver</button>
                    </div>
                    </div>
                </div>
            </div>
        </Container>
    </Layout>
  )
}
