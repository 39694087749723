import React from 'react'

const Moneytransactions = () => {
  return (
    <>
      <div className='sales-report'>
        <div className='balance'>
          Balance: $0.00
        </div>
        <div className='sales-report-inputs'>
          <label>
            Monto
          </label>
          <input type="number" placeholder='0.00' />
        </div>
        <div className='d-flex gap-4'>
          <div className='sales-report-radio'>
            <input type="radio" id='report' />
            <label htmlFor="report">
              Report
            </label>
          </div>
          <div className='sales-report-radio'>
            <input type="radio" id='prize' />
            <label htmlFor="prize">
              Prize
            </label>
          </div>
        </div>
        <button className='send'>
          Send
        </button>
      </div>
      <div className='d-flex justify-content-center align-items-center mt-4 gap-2'>
        Confirmation history <i className="fas fa-sync-alt" onclick="refreshLista();"></i>
      </div>
      <div className='sales-report-tables mt-4'>
        <div>
          <table>
            <thead>
              <tr>
                <th>
                  Id
                </th>
                <th>
                  Date
                </th>
                <th>
                  Time
                </th>
                <th>
                  Amount
                </th>
                <th>
                  Type
                </th>
                <th>
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={6}>
                  No se encontraron confirmaiones de entrega
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Moneytransactions