import React, { useState, useEffect } from 'react';
import "./Index.css";
import Title from "../title/Title";
import Table from "../table/Table";
import Layout from "../../../structures/layout";
import { redApi } from '../../../../../config/Axios';

const Index = () => {
  const objectPeriods = {
    Completo: [
      'MONEY +', 'MONEY -', 'PRECIO R/L +', 'PRECIO R/L -', 'P MAS +', 'P MENOS -', 'TOTAL +', 'TOTAL -', 'SOLO +', 'SOLO -'
    ],
    PrimeraMitad: [
      'MONEY H +', 'MONEY H -', 'PRECIO RL/H +', 'PRECIO RL/H -', 'P MAS H +', 'P MENOS H -', 'TOTAL H +', 'TOTAL H -', 'SOLO H +', 'SOLO H -'
    ],
    SegundaMitad: [
      'GAVELA R/L H2 +', 'GAVELA R/L H2 -', 'PRECIO R/L H2 +', 'PRECIO R/L H2 -', 'P MAS H2 +', 'P MENOS H2 -', 'TOTAL H2 +', 'TOTAL H2 -', 'SOLO H2 +', 'SOLO H2 -'
    ],
    PrimerCuarto: [
      'GAVELA R/L 1Q +', 'GAVELA R/L 1Q -', 'PRECIO R/L 1Q +', 'PRECIO R/L 1Q -', 'P MAS 1Q +', 'P MENOS 1Q -', 'TOTAL 1Q +', 'TOTAL 1Q -', 'SOLO 1Q +', 'SOLO 1Q -'
    ],
    SegundoCuarto: [
      'GAVELA R/L 2Q +', 'GAVELA R/L 2Q -', 'PRECIO R/L 2Q +', 'PRECIO R/L 2Q -', 'P MAS 2Q +', 'P MENOS 2Q -', 'TOTAL 2Q +', 'TOTAL 2Q -', 'SOLO 2Q +', 'SOLO 2Q -'
    ],
    TercerCuarto: [
      'GAVELA R/L 3Q +', 'GAVELA R/L 3Q -', 'PRECIO R/L 3Q +', 'PRECIO R/L 3Q -', 'P MAS 3Q +', 'P MENOS 3Q -', 'TOTAL 3Q +', 'TOTAL 3Q -', 'SOLO 3Q +', 'SOLO 3Q -'
    ],
    CuartoCuarto: [
      'GAVELA R/L 4Q +', 'GAVELA R/L 4Q -', 'PRECIO R/L 4Q +', 'PRECIO R/L 4Q -', 'P MAS 4Q +', 'P MENOS 4Q -', 'TOTAL 4Q +', 'TOTAL 4Q -', 'SOLO 4Q +', 'SOLO 4Q -'
    ],
    PrimerTercio: [
      'MONEY 1T +', 'MONEY 1T -', 'GAVELA R/L 1T +', 'GAVELA R/L 1T -', 'P MAS 1T +', 'P MENOS 1T -', 'TOTAL +', 'TOTAL -'
    ],
    SegundoTercio: [
      'MONEY 2T +', 'MONEY 2T -', 'GAVELA R/L 2T +', 'GAVELA R/L 2T -', 'P MAS 2T +', 'P MENOS 2T -', 'TOTAL 2T +', 'TOTAL 2T -'
    ],
    TercerTercio: [
      'MONEY 3T +', 'MONEY 3T -', 'GAVELA R/L 3T +', 'GAVELA R/L 3T -', 'P MAS 3T +', 'P MENOS 3T -', 'TOTAL 3T +', 'TOTAL 3T -'
    ],
  };

  const [periods, setPeriods] = useState(objectPeriods.Completo);
  const [objectConsorcio, setConsorts] = useState([{ name: "Admin" },]);
  const [selectedPeriodKey, setSelectedPeriodKey] = useState('Completo');
  const [selectedConsorcio, setSelectedConsorcio] = useState();

  const handlePeriodChange = (event) => {
    const selectedKey = event.target.value;
    setSelectedPeriodKey(selectedKey);
    setPeriods(objectPeriods[selectedKey]);
  };

  const handleConsorcioChange = (event) => {
    setSelectedConsorcio(event.target.value == 'Admin' ? null : event.target.value);
  };

  const getConsorts = async () => {
    const listConsorts = await redApi.get('/users?userLevel=2')
    setConsorts([...objectConsorcio, ...listConsorts.data.map(e => { return { name: e.username, id: e._id } })])
  }

  useEffect(() => {
    getConsorts()
  }, [])

  useEffect(() => {
    console.log('CAMBIANDO LOS CONSORTS', objectConsorcio);
  }, [objectConsorcio])


  return (
    <Layout>
      <Title />
      <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginLeft: '50px' }}>
        <select
          value={selectedPeriodKey}
          onChange={handlePeriodChange}
          style={{
            width: '250px',
            height: '35px',
            textAlign: 'center',
            backgroundColor: '#dc3545',
            color: 'white',
            fontWeight: 'bolder',
          }}
        >
          {Object.keys(objectPeriods).map((key) => (
            <option
              key={key}
              value={key}
              style={{
                color: 'white',
                fontWeight: 'bolder',
                border: '3px solid black'
              }}
            >
              {key}
            </option>
          ))}
        </select>
        <select
          value={selectedConsorcio}
          onChange={handleConsorcioChange}
          style={{
            width: '250px',
            height: '35px',
            textAlign: 'center',
            backgroundColor: '#dc3545',
            color: 'white',
            fontWeight: 'bolder',
          }}
        >
          {objectConsorcio.map((consort, index) => (
            <option
              key={index}
              value={consort.id ? consort.id : ''}
              style={{
                color: 'white',
                fontWeight: 'bolder',
                border: '3px solid black'
              }}
            >
              {consort.name}
            </option>
          ))}
        </select>
      </div>
      <Table periods={periods} consorcio={selectedConsorcio} />
    </Layout>
  );
};

export default Index;