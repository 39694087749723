import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import sportsLogo from '../../../assets/img/sports-logo-.png';
import { AuthContext } from '../../../auth/context/AuthContext';
import moment from 'moment';
import { redApi as api } from '../../../config/Axios';

const Header = () => {

    const { logout } = useContext(AuthContext);

    const navigate = useNavigate();

    const onLogout = () => {
        logout();
        navigate('/', {
            replace: true
        });
    }
    const [showHelp, setShowHelp] = useState(false)
    const hadnleshowHelp = () => {
        setShowHelp(!showHelp)
    }
    const handleGetTickets = async () => {
        let { fromDate, toDate } = filters
        // if toDate is today, add 24 hours to get all tickets
        if (toDate === moment().format('YYYY-MM-DD')) {
            toDate = moment().add(1, 'days').format('YYYY-MM-DD')
        }

        setInLoading(true)
        await api.get(`/tickets?initDate=${fromDate}&finalDate=${toDate}`)
            .then(res => {
                let tickets = res.data.filter(ticket => {
                    if (reportsType === 'reports') return ticket
                    if (reportsType === 'cancelticket') return ticket.ticketState === 'Play'
                    if (reportsType === 'reprint') return ticket.ticketState !== 'Cancelled'
                    if (reportsType === 'salesResults') return ticket.ticketState === 'Winner' || ticket.ticketState === 'Loser'
                })
                setTickets(tickets)
                setInLoading(false)
            })
            .catch(err => {
                console.log(err)
            }
            )
    }
    let helpTexts = [
        { key: 'm', text: 'Money Line' },
        { key: 'mh', text: 'Money Line H1' },
        { key: 'mt1', text: 'Money Line P1' },
        { key: 'mt2', text: 'Money Line P2' },
        { key: 'mt3', text: 'Money Line P3' },
        { key: 'r', text: 'Run Line' },
        { key: 'h', text: 'Run Line H' },
        { key: 'q1', text: 'Run Line Q1' },
        { key: 'q2', text: 'Run Line Q2' },
        { key: 'q3', text: 'Run Line Q3' },
        { key: 'q4', text: 'Run Line Q4' },
        { key: 't1', text: 'Run Line P1' },
        { key: 't2', text: 'Run Line P2' },
        { key: 't3', text: 'Run Line P3' },
        { key: 'hr2', text: 'Run Line H2' },
        { key: '+', text: 'Over' },
        { key: 'h+', text: 'Over H' },
        { key: 'q1+', text: 'Over Q1' },
        { key: 'q2+', text: 'Over Q2' },
        { key: 'q3+', text: 'Over Q3' },
        { key: 'q4+', text: 'Over Q4' },
        { key: 't1+', text: 'Over P1' },
        { key: 't2+', text: 'Over P2' },
        { key: 't3+', text: 'Over P3' },
        { key: 'h2+', text: 'Over H2' },
        { key: '-', text: 'Under' },
        { key: 'h-', text: 'Under H' },
        { key: 'q1-', text: 'Under Q1' },
        { key: 'q2-', text: 'Under Q2' },
        { key: 'q3-', text: 'Under Q3' },
        { key: 'q4-', text: 'Under Q4' },
        { key: 't1-', text: 'Under P1' },
        { key: 't2-', text: 'Under P2' },
        { key: 't3-', text: 'Under P3' },
        { key: 'h2-', text: 'Under H2' },
        { key: 's+', text: 'Solo +' },
        { key: 'h1s+', text: 'Solo +H' },
        { key: 'q1s+', text: 'Solo +Q1' },
        { key: 'q2s+', text: 'Solo +Q2' },
        { key: 'q3s+', text: 'Solo +Q3' },
        { key: 'q4s+', text: 'Solo +Q4' },
        { key: 'h2s+', text: 'Solo +H' },
        { key: 's-', text: 'Solo -' },
        { key: 'h1s-', text: 'Solo -H' },
        { key: 'q1s-', text: 'Solo -Q1' },
        { key: 'q2s-', text: 'Solo -Q2' },
        { key: 'q3s-', text: 'Solo -Q3' },
        { key: 'q4s-', text: 'Solo -Q4' },
        { key: 'h2s-', text: 'Solo -H' },
        { key: 'p', text: 'Super Run Line' },
        { key: 'y', text: 'Yes anotaran' },
        { key: 'n', text: 'No anotaran' },
        { key: 'k+', text: 'Strikeout +' },
        { key: 'k-', text: 'Strikeout -' },
        { key: 'f', text: 'First run' },
        { key: 'c', text: 'Buying' },
        { key: 'v', text: 'Senta' },
        { key: 'c+', text: 'B Over' },
        { key: 'c-', text: 'B Under' },
        { key: 'j1', text: 'Players 1' },
        { key: 'j2', text: 'Players 2' },
        { key: 'j3', text: 'Players 3' },
        { key: 'j4', text: 'Players 4' },
        { key: 'j5', text: 'Players 5' },
        { key: 'g', text: 'GS ML' },
        { key: 'g+', text: 'GS Over' },
        { key: 'g-', text: 'GS Under' },
        { key: 'se', text: 'Serie' },
        { key: 'ar', text: 'Run Line Alternativo' },
        { key: 't', text: 'Tercio' },
        { key: 't+', text: 'Tercio Mas' },
        { key: 't-', text: 'Tercio Menos' },
        { key: 'v+', text: 'S a mas' },
        { key: 'v-', text: 'S a menos' },
        { key: 'e', text: 'Draw' },
        { key: 'hr1', text: 'Run Line H' },
        { key: 'h1+', text: 'Over H' },
        { key: 'h1-', text: 'Under H' }
    ]
    const HelpModal = () => {
        return (
            <Modal
                open={showHelp}
                onCancel={hadnleshowHelp}
                footer={null}
            >
                <div className="row">
                    <div className="col">
                        <h3>How to use the system</h3>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Enter</th>
                                    <th scope="col">A Juego</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    helpTexts.map((helpText, index) => (
                                        <tr key={index}>
                                            <td>{helpText.key}</td>
                                            <td>{helpText.text}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        )
    }
    const [showReports, setShowReports] = useState(false)
    const [tickets, setTickets] = useState([])
    const [reportsType, setReportsType] = useState('')
    const [inLoading, setInLoading] = useState(false)
    const handleReports = (show) => {
        setShowReports(!showReports)
        setReportsType(show)
    }
    const handleFilters = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        })
    }
    useEffect(() => {
        if (showReports) {
            setInLoading(true)
            let initDate = moment().format('YYYY-MM-DD')
            let finalDate = moment().format('YYYY-MM-DD') + 'T23:59:59'
            api.get(`/tickets?initDate=${initDate}&finalDate=${finalDate}`) //today tickets
                .then(res => {
                    let tickets = res.data.filter(ticket => {
                        if (reportsType === 'reports') return ticket
                        if (reportsType === 'cancelticket') return ticket.ticketState === 'Play'
                        if (reportsType === 'reprint') return ticket.ticketState !== 'Cancelled'
                        if (reportsType === 'salesResults') return ticket.ticketState === 'Winner' || ticket.ticketState === 'Loser'
                    })
                    setTickets(tickets)
                    setInLoading(false)
                })
                .catch(err => console.log(err))
        }
    }, [showReports])
    return (
        <header className="sbg-header text-white">
            <HelpModal />
            <Modal
                open={showReports}
                onCancel={handleReports}
                footer={null}
                width='80%'
            >
                <div className="row">
                    <div className="col-12 d-flex justify-content-center flex-column align-items-center gap-4">
                        {/* date filters */}
                        <div className="d-flex gap-4 align-items-end justify-content-center">
                            <div className="col">
                                <label htmlFor="fromDate">From Date</label>
                                <input type="date" className="form-control" name="fromDate" onChange={handleFilters} defaultValue={moment().format('YYYY-MM-DD')} />
                            </div>
                            <div className="col">
                                <label htmlFor="toDate">To Date</label>
                                <input type="date" className="form-control" name="toDate" onChange={handleFilters} defaultValue={moment().format('YYYY-MM-DD')} />
                            </div>
                            <div className="col">
                                <button className="btn btn-primary" onClick={handleGetTickets}>Get Tickets</button>
                            </div>
                        </div>
                        <table className="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    {/* <th scope="col">Consorcio</th> */}
                                    <th scope="col" className='text-center'>Ticket #</th>
                                    <th scope="col" className='text-center'>Fecha/Hora</th>
                                    {
                                        reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>POS</th>
                                    }
                                    {/* <th scope="col" className='text-center'>Usuario</th> */}
                                    {
                                        reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>Tipo de Ticket</th>
                                    }

                                    <th scope="col" className='text-center'>Apostado</th>
                                    {
                                        reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>A pagar</th>
                                    }
                                    <th scope="col" className='text-center'>Status</th>
                                </tr>
                            </thead>
                            <tbody className='position-relative'>
                                {
                                    inLoading ? (
                                        <tr className="spinner-border text-primary position-absolute top-50 start-50" role="status">
                                            <td colSpan={7} className="visually-hidden">Loading...</td>
                                        </tr>
                                    ) : (
                                        tickets.length === 0 ? (
                                            <tr>
                                                <td colSpan={7} className='text-center'>No hay tickets</td>
                                            </tr>
                                        ) : (
                                            tickets.map((ticket, index) => (

                                                <tr key={index} onClick={reportsType === 'reprint' ? () => handleRePrint(ticket.ticketId) : reportsType === 'cancelticket' ? () => handleCancelTicket(ticket.ticketId) : null}>
                                                    {/* <td>{ticket.consortium || 'N/A'}</td> */}
                                                    <td className='text-center'>#{ticket.ticketId || 'N/A'}</td>
                                                    <td className='text-center'>{moment(ticket.createdAt).format('DD/MM/YYYY hh:mm A')}</td>
                                                    {
                                                        reportsType === 'salesResults' ? null : <td className='text-center'>{ticket.createdBy.username || 'N/A'}</td>
                                                    }

                                                    {/* <td className='text-center'>{ticket.user || 'N/A'}</td> */}
                                                    {
                                                        reportsType === 'salesResults' ? null : <td className='text-center'>{ticket.bets.length === 1 ? 'Direct' : ticket.bets.length === 2 ? 'Pale' : ticket.bets.length === 3 ? 'Tripleta' : ticket.bets.length === 4 ? 'Cuadruple' : ticket.bets.length === 5 ? 'Quintuple' : ticket.bets.length === 6 ? 'Sextuple' : ticket.bets.length === 7 ? 'Septuple' : ticket.bets.length === 8 ? 'Octuple' : ticket.bets.length === 9 ? 'Nonuple' : ticket.bets.length === 10 ? 'Decuple' : 'N/A'}</td>
                                                    }
                                                    <td className='text-center'>${ticket.amount || 'N/A'}</td>
                                                    {
                                                        reportsType === 'salesResults' ? null : <td className='text-center'>${ticket.toCollect || 'N/A'}</td>
                                                    }
                                                    <td className={`text-center ${ticket.ticketState === 'Play' ? 'text-warning' : ticket.ticketState === 'Winner' ? 'text-success' : ticket.ticketState === 'Loser' ? 'text-danger' : 'text-secondary'}`}
                                                    >{ticket.ticketState || 'N/A'}</td>
                                                </tr>
                                            ))
                                        )
                                    )
                                }
                            </tbody>
                            {
                                reportsType !== 'reports' ? null : (
                                    <tfoot>
                                        <tr>
                                            <td colSpan={4} className='text-center'>Total</td>
                                            <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.amount, 0)}</td>
                                            <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.toCollect, 0)}</td>
                                            <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.toCollect, 0) - tickets.reduce((acc, ticket) => acc + ticket.amount, 0)}</td>
                                        </tr>
                                    </tfoot>
                                )
                            }

                        </table>
                    </div>
                </div>
            </Modal>
            {/* <div> */}
            <nav id="headerClient" className="navbar navbarclient navbar-dark p-0 navbar-expand-lg">
                {/* <div className="container-fluid"> */}
                <NavLink className="navbar-brand slogo-header" to="/client"><img src={sportsLogo} alt="Logo Sports" /></NavLink>
                <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="match-nav-main collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item"><NavLink className="nav-link active px-2" to="/client/index" >Sports</NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link px-2" to="/client/scores/top" >Scores</NavLink></li>
                        {/* <li className="nav-item"><a href="#" className="nav-link px-2">Reports</a></li> */}
                        {
                            <>
                                {/* <li className="nav-item"><a href="#" className="nav-link px-2">Lottery</a></li>
                                <li className="nav-item"><a href="#" className="nav-link px-2">Roulette</a></li>
                                <li className="nav-item"><a href="#" className="nav-link px-2">Horses</a></li> */}
                            </>
                        }
                        {/* <div className="dropdown drop-pok-btn">
                            <NavLink to="/client/MLB" className="btn btn-secondary dropdown-toggle" id="dropdownMenuButtonggg" data-bs-toggle="dropdown" aria-expanded="false">
                                MLB
                            </NavLink>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonggg">
                                <li><a className="dropdown-item" href="ncaaf.html">NCAAF</a></li>
                                <li><a className="dropdown-item" href="nba.html">NBA</a></li>
                                <li><a className="dropdown-item" href="nhl.html">NHL</a></li>
                                <li><a className="dropdown-item" href="nfl.html">NFL</a></li>
                                <li><a className="dropdown-item" href="ncaab.html">NCAAB</a></li>
                            </ul>
                        </div> */}
                    </ul>
                    <div id="menuIcons" className="col-6 d-flex col-lg-auto mb-3 mb-lg-0 me-lg-3 gap-2">
                        {/* <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                        </a> */}
                        {/* <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-files" viewBox="0 0 16 16">
                                <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                            </svg>
                        </a> */}
                        <a onClick={hadnleshowHelp} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                            </svg>
                        </a>
                        <a href="#" onClick={() => handleReports('reports')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart-line-fill" viewBox="0 0 16 16">
                                <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z" />
                            </svg>
                        </a>
                        {/* <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                                <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                            </svg>
                        </a> */}
                        {/* <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
                                <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
                            </svg>
                        </a> */}
                        {/* <button onClick={onLogout}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                                <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                            </svg>
                        </button> */}
                    </div>
                    <div className="dropdown drop-pok-btn">
                        <button className="btn btn-secondary dropdown-toggle" disabled type="button" id="dropdownLanguage" data-bs-toggle="dropdown" aria-expanded="false">
                            EN
                        </button>
                        <ul className="dropdown-menu"
                            aria-labelledby="dropdownLanguage"
                        >
                            <li><NavLink className="dropdown-item" to="/client/" >EN</NavLink></li>
                            <li><NavLink className="dropdown-item" to="/client/es" >ES</NavLink></li>
                        </ul>
                    </div>


                    {/* <div className="text-end">
                            
                                <button type="button" className="btn btn-login me-2">
                                    <span className="icon-lock">
                                        <i className="fa-solid fa-lock"></i>
                                    </span>
                                    Login
                                    </button>
                            </div> */}
                </div>
                {/* </div> */}
            </nav>
            {/* </div> */}
        </header>

    );
}

export default Header;