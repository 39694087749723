import { useEffect, useState } from "react"
import { redApi } from "../../config/Axios"
import Swal from "sweetalert2"

export const useConfiguration = () => {

    const [dataTable, setDataTable] = useState([])
    const [filters, setFilters] = useState({
        league: "",
        lineType: "",
        period: "",
    })
    const [leagues, setLeagues] = useState([])
    const [updates, setUpdates] = useState([]);

    const handleFilter = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        })
    }
    const refreshData = () => {
        Swal.fire({
            title: "Refrescando...",
            text: "Espere un momento por favor",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        })
        redApi.get(`/linesConfig/config?league=${filters.league}`)
            .then(res => {
                setDataTable(res.data)
                Swal.fire({
                    icon: "success",
                    title: "Refrescado",
                    text: "Se han actualizado los datos",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(err => console.log(err))
    }
    const handleUpdate = (e, itemTable = undefined) => {
        let bot;
        let sportBook = null;
        if (e.target.name == 'infoSource') {
            bot = itemTable.league.bots.find((bot) => bot.name == e.target.value.split('/')[0]);
            const findSportBook = bot?.sportBooks.find((sportB) => sportB.name == e.target.value.split('/')[1]);
            if ( findSportBook ) sportBook = findSportBook;
        }
        const exist = updates.find(item => item._id === e.target.id)
        if (exist) {
            let newUpdates = updates.map(item => {
                if (item._id === e.target.id) {
                    const changedUpdate = { ...item }
                    if (e.target.name === "status") changedUpdate.status = e.target.checked
                    if (bot) {
                        changedUpdate[e.target.name] = bot
                        changedUpdate.sportBook = sportBook
                    } else {
                        changedUpdate[e.target.name] = e.target.value
                        changedUpdate.sportBook = sportBook
                    }
                    return changedUpdate
                }
                return item
            })
            setUpdates(newUpdates)
        } else {
            const update = {
                _id: e.target.id
            }
            if (e.target.name === "status") {
                update.status = e.target.checked
            }
            if (bot) {
                update[e.target.name] = bot
                update.sportBook = sportBook
            } else {
                update[e.target.name] = e.target.value
                update.sportBook = sportBook
            }
            setUpdates([...updates, update]);
        }
    }
    const handleSubmit = () => {
        if (updates.length === 0) return
        Swal.fire({
            title: "Actualizando...",
            text: "Espere un momento por favor",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        })
        redApi.put("/linesConfig/config", {
            updates
        })
            .then(res => {

                redApi.get(`/linesConfig/config?league=${filters.league}`)
                    .then(res => {
                        setDataTable(res.data)
                        Swal.fire({
                            icon: "success",
                            title: "Actualizado",
                            text: "Se han actualizado los datos",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                    .catch(err => console.log(err))
            })
    }

    useEffect(() => {
        redApi.get("/leagues").then(res => setLeagues(res.data))
    }, [])
    useEffect(() => {
        redApi.get(`/linesConfig/config?league=${filters.league}`)
            .then(res => {
                setDataTable(res.data)
            })
            .catch(err => console.log(err))
    }, [filters])
    // useEffect(() => {console.log({updates})}, [updates])

    return {
        dataTable,
        leagues,

        handleFilter,
        refreshData,
        handleUpdate,
        handleSubmit,
    }
  
}
