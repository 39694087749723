import React, { useCallback, useState, useEffect } from "react";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import { redApi } from "../../../../../config/Axios";


const Theme4_leagues = () => {
  const { league } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLeagueActive(league);
  }, [league]);

  const [leagueActive, setLeagueActive] = useState("");
  const [menuItems, setMenuItems] = useState([]);

  useEffect(async () => {
    await redApi.get("/leagues?leaguesWithGames=true").then((res) => {

      let leaguesWithGames = res.data;
      if (leaguesWithGames.length > 0) {
        leaguesWithGames?.map((league) => {
          league.image = `${process.env.REACT_APP_SERVER_URL}/LeagueImages/${league.name}.png`;
        });
      }
      setMenuItems(leaguesWithGames);
      console.log(leaguesWithGames);
    });
  }, []);

  // useEffect(() => {
  //     menuItems?.map(item => {
  //         if (league !== item.name) {
  //             navigate(`/client/${item.name.replaceAll(' ', '%20').replaceAll('/', '_')}`)
  //         }
  //     })
  // }, [menuItems]);

  return (
    <header className="header">
      <nav className="leagues-nav">
        {menuItems.length > 0 ? (
          menuItems?.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={`/client/${item.name
                  .replaceAll(" ", "%20")
                  .replaceAll("/", "_")}`}
                onClick={() => setLeagueActive(item.name)}
              >
                <img
                  src={item.image}
                  alt={item.name}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/logos/logodefault.png";
                  }}
                />
                <span>
                  {item.name.length > 15
                    ? item.name.substring(0, 15) + "..."
                    : item.name}
                </span>
              </NavLink>
            );
          })
        ) : (
          <div>No hay ligas con juegos</div>
        )}
      </nav>
    </header>
  );
};

export default Theme4_leagues;
