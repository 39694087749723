import React, { useState, useEffect, useContext, useRef } from 'react'
import moment from 'moment';
import { redApi } from '../../../../config/Axios';
import { getRedValueClassName } from '../../../../utils/returnClassNames';
import { ReportsContext } from './context/ReportesContext';

export default function IngEgr() {
    const firstRender = useRef(true)

    const {
        terminals: users,
        consortiums: consorcios,
         getTerminalsByOwner,
         IngEgr,
         loading
    } = useContext(ReportsContext)
    
    const [dataTable, setDataTable] = useState([])
    const [totaData, setTotalData] = useState({})
    const [Render, setRender] = useState(true)

    const [showcheck, setShowcheck] = useState(false);
    const [fetchQuery, setFetchQuery] = useState({ pos: '', consortium: '', initDate: moment().format('YYYY-MM-DD'), endDate: '' });

    useEffect(() => {
        if ( firstRender.current ) return;
        
        getTerminalsByOwner(fetchQuery.consortium).catch(e => console.log(e));
    }, [fetchQuery.consortium])
    useEffect(() => {
        setRender(false);
        firstRender.current = false
    }, [])


    const handleCheck = (e) => {
        if (e.target.checked) {
            setShowcheck(true)
            setFetchQuery({ ...fetchQuery, endDate: moment(fetchQuery.initDate).add(1, 'd').format('YYYY-MM-DD') });
        } else {
            setShowcheck(false);
            setFetchQuery({ ...fetchQuery, endDate: '' });
        }
    }

    const handlerOnChange = (e) => {
        setFetchQuery({ ...fetchQuery, [e.target.name]: e.target.value });
    }

    const refreshTable = () => {
        if(Render && !loading){
            const {balances, total } = IngEgr;
            setDataTable(balances)
            setTotalData(total)
        }else{
            redApi.get(`/accountings/transHist${fetchQuery.initDate && fetchQuery.endDate ? `?initDate=${fetchQuery.initDate}&finalDate=${fetchQuery.endDate}` : `${fetchQuery.initDate ? `?initDate=${fetchQuery.initDate}` : ''}${fetchQuery.consortium ? `&consortium=${fetchQuery.consortium}` : ''}${fetchQuery.pos ? `&pos=${fetchQuery.pos}` : ''}`}`)
                .then(res => {
                    setDataTable(res.data.balances)
                    setTotalData(res.data.total)
                })
                .catch(err => {
                    console.log(err)
                })       
        }

    }
    useEffect(() => {
        refreshTable();
    }, [fetchQuery]);

    return (

        <div className="container-fluid p-0  m-0">
            <div className="row m-0 p-0">
                <div className="col-12  m-0 p-0 bg-gray-800 py-4">
                    <div className="row m-0 p-0 gap-3">
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">POS</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name='pos' onChange={(e) => { handlerOnChange(e) }}>
                                                <option value="">Todos</option>
                                                {users.map((pos, index) => (
                                                    <option key={index} value={pos._id}>{pos.username}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Consorcio</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name='consortium' onChange={(e) => { handlerOnChange(e) }} >
                                                <option value="">Todos</option>
                                                {consorcios.map((cons, index) => (
                                                    <option key={index} value={cons._id}>{cons.username}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-start">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <input type="checkbox" onChange={handleCheck} />
                                            <label className="m-0 p-0">Rango</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Refrescar" onClick={refreshTable} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            {
                                                showcheck ? <label className="m-0 p-0 text-start text-nowrap">Fecha inicial</label> : <label className="m-0 p-0 text-start text-nowrap">Fecha</label>
                                            }
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="date" value={fetchQuery.initDate} className="form-control" id="ingEgrDate" name="initDate" onChange={(e) => { handlerOnChange(e) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className={`m-0 p-0 text-start text-nowrap ${!showcheck ? 'invisible' : ''}`}>Fecha final</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="date" value={fetchQuery.endDate} className={`form-control ${!showcheck ? 'invisible' : ''}`} name="endDate" onChange={(e) => { handlerOnChange(e) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">


                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 py-4" id="table_container">
                    <div className="table-responsive" style={{maxHeight: "65vh"}}>
                        <table className="table table-striped table-hover" style={{ minHeight: 30 }}>
                            <thead>
                                <tr>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">POS</th>
                                    <th scope="col">Ingreso</th>
                                    <th scope="col">Egreso</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataTable.length === 0
                                    ? (<tr><td colSpan={4}>No hay Transacciones hoy.</td></tr>)
                                    : dataTable.map((data, index) => (
                                        <tr key={index}>
                                            <td>{moment(data.date).format('MM/DD/YY (ddd)')}</td>
                                            <td>{data.user}</td>
                                            <td className="text-success">{data.ingreso}</td>
                                            <td className={getRedValueClassName(data.egreso > 0)}>{-data.egreso}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="1">Total</td>
                                    <td className={getRedValueClassName(totaData.total < 0, "", "text-success")}>Total: {totaData.total}</td>
                                    <td className="text-success">Ingreso: {totaData.ingreso}</td>
                                    <td className={getRedValueClassName(totaData.egreso > 0)}>Egreso: {totaData.egreso}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
