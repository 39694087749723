import React, { useEffect, useState } from 'react'
import { redApi } from '../../../../../config/Axios'

const PreciosSolo = ({ createConsorcios, setCreateConsorcios }) => {

    const [leagues, setLeagues] = useState([])
    useEffect(() => {
        redApi.get("/leagues")
            .then(response => {
                setLeagues(response.data)
            })
        console.log(createConsorcios)
    }, [])



    const handleUpdate = (e, _id) => {
        let { name, value } = e.target
        if (name === 'status') {
            value = e.target.checked
        } else {
            value = parseInt(value)
        }
        setCreateConsorcios({
            ...createConsorcios,
            priceSingle: [
                ...createConsorcios.priceSingle.map((item) => {
                    if (item._id === _id) {
                        return {
                            ...item,
                            [name]: value
                        }
                    } else {
                        return item
                    }
                })
            ]
        })
    }

    let periods = [
        {
            label: "Juego Completo",
        },
        {
            label: "Mitad",
        },
        {
            label: "Segunda Mitad",
        },
        {
            label: "Q1",
        },
        {
            label: "Q2",
        },
        {
            label: "Q3",
        },
        {
            label: "Q4",
        },
    ]

    const [filters, setFilters] = useState({
        league: 'all',
        period: 'all'
    })

    const handleFilter = (e) => {
        const { name, value } = e.target
        setFilters({
            ...filters,
            [name]: value
        })
    }



    return (
        <div className="row" id='ConsorcioTlmtLiga'>
            <div className="col-12">
                <div className="row mb-2">
                    <div className="col-12">
                        <h6 className='text-center h2 m-2'>Precios de los Solos</h6>
                    </div>
                    {/* <div className="col-12">
                        <div className="row">
                            <div className="col-5 d-flex justify-content-center align-items-center gap-4">
                                <label className='labelGeneral'>Ligas</label>
                                <select className="form-select w-50" name='league' onChange={handleFilter}>
                                    <option value={"all"}>Todas</option>
                                    {
                                        leagues?.map((item) => (
                                            <option key={item._id} value={item._id}>{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-5 d-flex justify-content-center align-items-center gap-4">
                                <label className='labelGeneral'>Periodos</label>
                                <select className="w-50 form-select" name='period' onChange={handleFilter}>
                                    <option value={"all"}>Todos</option>
                                    {
                                        periods?.map((item) => (
                                            <option key={item.label} value={item.label}>{item.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-12">
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col" colSpan={1}></th>
                                    <th scope="col" colSpan={4}>Liga</th>
                                    <th scope="col" colSpan={4}>Periodo</th>
                                    <th scope="col" colSpan={4}>1</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    createConsorcios.priceSingle.map((item) => {
                                        console.log("ITEMMM", item)
                                        return (
                                            <tr key={item._id}>
                                                <td colSpan={1}>
                                                    <input
                                                        type="checkbox"
                                                        name='status'
                                                        defaultChecked={item.status}
                                                        onChange={(e) => handleUpdate(e, item._id)}
                                                    />
                                                </td>
                                                <td colSpan={4}>{item.league.name}</td>
                                                <td colSpan={4}>{item.period}</td>
                                                <td colSpan={4}>
                                                    <input type="number" defaultValue={item.value} className="text-center form-number" name='value' onBlur={(e) => handleUpdate(e, item._id)} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreciosSolo