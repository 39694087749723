import React, { useEffect, useState } from 'react'
import Deposit_t4_mobile from './online acc menu/Deposit_t4_mobile'
import Withdrawals_t4_mobile from './online acc menu/Withdrawals_t4_mobile'
import Transfer_t4_mobile from './online acc menu/Transfer_t4_mobile'
import NewAccount_t4_mobile from './online acc menu/NewAccount_t4_mobile'
import WebTickets_t4_mobile from './online acc menu/WebTickets_t4_mobile'
import MyCustomers_t4_mobile from './online acc menu/MyCustomers_t4_mobile'
import PendingWithdrawals_t4_mobile from './online acc menu/PendingWithdrawals_t4_mobile'
const OnlineAccounts_t4_mobile = () => {

  let menuList = [
    {
      name: 'Deposit',
      icon: 'fas fa-money-bill-wave',
    },
    {
      name: 'Withdrawals',
      icon: 'fa fa-hand-o-left',
    },
    {
      name: 'Transfer',
      icon: 'fas fa-exchange-alt',
    },
    {
      name: 'New Account',
      icon: 'fas fa-user-plus',
    },
    {
      name: 'Web Tickets',
      icon: 'fas fa-ticket-alt',
    },
    {
      name: 'My customers',
      icon: 'fas fa-users',
    },
    {
      name: 'Pending Withdrawals',
      icon: 'fas fa-hand-holding-usd',
    }
  ]


  const [menuActive, setMenuActive] = useState('')


  const renderMenu = () => {
    switch (menuActive) {
      case 'Deposit':
        return (<Deposit_t4_mobile setMenuActive={setMenuActive} menuActive={menuActive} />)
      case 'Withdrawals':
        return (<Withdrawals_t4_mobile setMenuActive={setMenuActive} menuActive={menuActive} />)
      case 'Transfer':
        return (<Transfer_t4_mobile setMenuActive={setMenuActive} menuActive={menuActive} />)
      case 'New Account':
        return (<NewAccount_t4_mobile setMenuActive={setMenuActive} menuActive={menuActive} />)
      case 'Web Tickets':
        return (<WebTickets_t4_mobile setMenuActive={setMenuActive} menuActive={menuActive} />)
      case 'My customers':
        return (<MyCustomers_t4_mobile setMenuActive={setMenuActive} menuActive={menuActive} />)
      case 'Pending Withdrawals':
        return (<PendingWithdrawals_t4_mobile setMenuActive={setMenuActive} menuActive={menuActive} />)
      default:
        return (<Deposit_t4_mobile setMenuActive={setMenuActive} menuActive={menuActive} />)
    }
  }


  useEffect(() => {
    console.log({ menuActive })
  }, [menuActive])
  return (
    <div className='menu_container'>
      {
        menuActive !== '' ? (
          renderMenu()
        ) : (
          <>
            <div className='top_container'>
              <div>
                <span>
                  <i className="fa fa-globe" aria-hidden="true" style={{ marginRight: '5px' }}></i>
                  Online Accounts
                </span>
              </div>
            </div>
            <div className='d-flex flex-column px-4 gap-2'>
              {
                menuList.map((item, index) => {
                  return (
                    <div className='d-flex align-items-center px-3 py-2 gap-2'
                      key={index}
                      style={{
                        background: 'rgb(40,55,59)',
                        background: 'linear-gradient(90deg, rgba(40,55,59,1) 0%, rgba(180,189,199,1) 100%)',
                        borderRadius: '5px',
                        textTransform: 'uppercase',
                        color: '#fff',
                      }}
                      onClick={() => {
                        setMenuActive(item.name)
                      }}
                    >
                      <i className={item.icon}></i>
                      <span>
                        {item.name}
                      </span>
                    </div>
                  )
                })
              }
            </div>
          </>
        )
      }

    </div>
  )
}

export default OnlineAccounts_t4_mobile