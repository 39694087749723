import React, { useState } from 'react'

const MsjDelTicket = ({ createConsorcios, setCreateConsorcios }) => {




    const handleMsj = (e) => {
        setCreateConsorcios({
            ...createConsorcios,
            ticketMessage: {
                ...createConsorcios.ticketMessage,
                [e.target.name]: e.target.value
            }
        })
    }

    return (
        <div className="row" id='ConsorcioTmjsTicket'>
            <div className="col-12 d-flex flex-row justify-content-center align-items-start gap-4">
                <h6 className='text-center h6'>Mensajes</h6>
                <div className='text-start h5 w-100 d-flex flex-column gap-1'>
                    <input type="text" name="mensaje1" id="mensaje1" className="form-control" onChange={handleMsj} value={createConsorcios.ticketMessage.mensaje1} />
                    <input type="text" name="mensaje2" id="mensaje2" className="form-control" onChange={handleMsj} value={createConsorcios.ticketMessage.mensaje2} />
                    <input type="text" name="mensaje3" id="mensaje3" className="form-control" onChange={handleMsj} value={createConsorcios.ticketMessage.mensaje3} />
                    <input type="text" name="mensaje4" id="mensaje4" className="form-control" onChange={handleMsj} value={createConsorcios.ticketMessage.mensaje4} />
                </div>
            </div>
        </div>
    )
}

export default MsjDelTicket