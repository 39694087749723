import React, { useEffect, useState } from "react";
import { UserServices } from "../../services/userServices";
import { redApi } from "../../config/Axios";
import { Modal, Button } from "antd";
import Swal from "sweetalert2";
import { Swals } from "../../utils/swals";
import "./userForm.scss";

const menu = [
  "General",
  "Porciento",
  "Rojo",
  "Limite",
  "Restricciones",
  "Mensajes",
  "IEAutomatico",
];

const UserForm = ({
  setShow,
  show,
  loadUsers,
  formMode = "create",
  toUpdate,
  userLevel,
  consorcios,
  setTerminalSelected,
  terminalSelected,
}) => {
  const [form, setForm] = useState({
    name: toUpdate?.name || "",
    username: toUpdate?.username || "",
    // password: "",
    theme: toUpdate?.theme || 1,
    userLevel: 3,
    group: "Terminal",
    leagues: toUpdate?.leagues.map((i) => i._id) || [],
    percent: {
      frequency: toUpdate?.percent?.frequency || "weekly",
      day: toUpdate?.percent?.day || 0,
      percent: toUpdate?.percent?.percent || 50,
      wipeRed: toUpdate?.percent?.wipeRed || false,
    },
    consortium: toUpdate?.createdBy || null,
    limits: {
      ticketPlays: toUpdate?.limits?.ticketPlays || 6,
      direct: toUpdate?.limits?.direct || 1000,
      pale: toUpdate?.limits?.pale || 2000,
      triplet: toUpdate?.limits?.triplet || 3000,
      quatrain: toUpdate?.limits?.quatrain || 4000,
      quintet: toUpdate?.limits?.quintet || 5000,
      sextet: toUpdate?.limits?.sextet || 9500,
      septet: toUpdate?.limits?.septet || 11500,
      octet: toUpdate?.limits?.octet || 13000,
      t2: toUpdate?.limits?.t2 || 0,
      t3: toUpdate?.limits?.t3 || 0,
      t4: toUpdate?.limits?.t4 || 0,
      ticketPlaysIF: toUpdate?.limits?.ticketPlaysIF || 6,
      ninth: toUpdate?.limits?.ninth || 20000,
      tenth: toUpdate?.limits?.tenth || 21000,
      eleventh: toUpdate?.limits?.eleventh || 22000,
      twelfth: toUpdate?.limits?.twelfth || 23000,
      thirteenth: toUpdate?.limits?.thirteenth || 24000,
      fourteenth: toUpdate?.limits?.fourteenth || 25000,
      fifteenth: toUpdate?.limits?.fifteenth || 26000,
      sixteenth: toUpdate?.limits?.sixteenth || 27000,
      ifMove: toUpdate?.limits?.ifMove || 26000,
    },
    minSell: toUpdate?.minSell || 10,
    multPlays: toUpdate?.multPlays || "-110",
    dailyMessage: toUpdate?.dailyMessage || "",
    income: toUpdate?.income || [],
    restrictions: toUpdate?.restrictions || [],
    ticketMessage: toUpdate?.ticketMessage || {
      mensaje1: "Revice su ticket al recibirlo",
      mensaje2: "No se paga error de linea y juego iniciado.",
      mensaje3: "No pagamos jugadas en escalera",
      mensaje4: "Polarsports.ddns.net",
    },
  });

  useEffect(() => {
    if (form?.consortium && consorcios) {
      const consorcio = consorcios.find((c) => c._id === form?.consortium);
      setForm((pv) => {
        return { ...pv, limits: consorcio?.limits };
      });
      // setTerminalSelected((pv) => {
      //   return ({ ...pv, limits: consorcio?.limits })
      // })
    }
  }, [form.consortium]);

  useEffect(() => {
    if (toUpdate) {
      setForm({
        ...toUpdate,
        leagues: toUpdate.leagues.map((i) => i._id),
        consortium: toUpdate.ownedBy?._id,
        _id: toUpdate._id,
      });
    }
  }, [toUpdate]);
  const [activeTab, setActiveTab] = useState("General");

  const changeValue = (name) => (e) => {
    if (name == "consortium") {
      if (e.target.value) {
        setForm((pv) => {
          return {
            ...pv,
            minSell: e.target.selectedOptions[0].attributes.minsell.value,
            multPlays: e.target.selectedOptions[0].attributes.multplays.value,
          };
        });
      } else {
        setForm((pv) => {
          return { ...pv, minSell: 10, multPlays: -110 };
        });
      }
    }
    setForm((pv) => {
      return { ...pv, [name]: e.target.value };
    });
  };
  const handleSubmit = async () => {
    try {
      if (
        formMode === "create" &&
        (!form.username?.trim() || !form.password?.trim())
      )
        return Swals.error(
          "Error",
          "Por favor ingresa por lo menos un nombre de usuario y contraseña."
        );
      else if (!form.username.trim())
        return Swals.error(
          "Error",
          "Por favor ingresa por lo menos un nombre de usuario y contraseña."
        );

      if (formMode === "create") {
        await UserServices.createUser({
          ...form,
          ownedBy: form.consortium || null,
        });
        await Swals.success(
          "Terminal creada",
          "Se ha registrado la terminal, ahora puedes iniciar sesion con ella."
        );
      } else {
        // console.log(form._id, {
        //   ...form,
        //   ownedBy: form.consortium,
        // }, "form");
        if (!toUpdate._id) {
          await Swals.error(
            "Error",
            "No se ha podido actualizar la terminal, intentalo de nuevo."
          );
          return;
        }
        await UserServices.updateUser(form._id || toUpdate._id, {
          ...form,
          ownedBy: form.consortium,
        });
        changeRed(form?._id);
        if (transacciones.length > 0) {
          await updateIncomes().then(async (res) => {
            await Swals.success(
              "Terminal actualizada",
              "Se ha actualizado la terminal, ya puedes observar los cambios realizados."
            );
            await loadUsers();
          });
          setShow(false);
          return;
        }
        await Swals.success(
          "Terminal actualizada",
          "Se ha actualizado la terminal, ya puedes observar los cambios realizados."
        );
      }
      await loadUsers();
      setShow(false);
    } catch (e) {
      console.log(e);
      await Swals.error(
        "Error",
        e.response?.data?.message ||
        "Desconocido, si persiste contacta a soporte"
      );
    }
  };
  const updateIncomes = async () => {
    try {
      await redApi
        .put(`/accountings/income`, {
          userId: form._id,
          incomes: transacciones || [],
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleCancel = () => {
    setShow(false);
  };

  const [transacciones, setTransacciones] = useState([]);

  const [newRed, setNewRed] = useState(null);
  const changeRed = async (userId, red) => {
    if (userId === undefined || null)
      return Swals.error("No se encontro el usuario");
    if (newRed === undefined || null)
      return Swals.error("No se encontro el nuevo rojo");
    if (red) {
      await redApi
        .put("/accountings/red", { userId, newRed: red })
        .then((res) => {
          Swals.success(
            "Rojo actualizado",
            "Se ha actualizado el rojo del usuario"
          );
        });
    }
    await redApi.put("/accountings/red", { userId, newRed }).then((res) => {
      setNewRed("");
    });
  };

  return (
    <>
      <Modal
        title={
          formMode === "create" ? "Crear terminal" : "Actualiza la terminal"
        }
        open={show}
        onOk={handleSubmit}
        onCancel={handleCancel}
        width="70%"
        footer={[
          <button
            key="back"
            onClick={handleCancel}
            className="btn btn-outline-secondary mx-3 px-4"
          >
            Cancelar
          </button>,
          <button
            key="submit"
            className="btn btn-success px-4"
            type="primary"
            onClick={handleSubmit}
          >
            {formMode === "create" ? "Registrar" : "Actualizar"}
          </button>,
        ]}
      >
        <div className="row">
          <div className="col-12">
            <ul className="nav w-100 d-flex justify-content-start align-items-center">
              {menu.map((item, index) => (
                <li key={index} className="nav-item mx-2">
                  <button
                    value={item}
                    onClick={() => setActiveTab(item)}
                    className={`nav-buttons ${activeTab === item ? "active" : ""
                      }`}
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-12">
            {activeTab === "General" && (
              <GeneralForm
                changeValue={changeValue}
                form={form}
                setForm={setForm}
                userLevel={userLevel}
                formMode={formMode}
                toUpdate={toUpdate}
              />
            )}
            {activeTab === "Porciento" && (
              <PercentForm
                changeValue={changeValue}
                form={form}
                setForm={setForm}
              />
            )}
            {activeTab === "Rojo" && (
              <RedForm
                changeValue={changeValue}
                form={form}
                setForm={setForm}
                changeRed={changeRed}
                newRed={newRed}
                setNewRed={setNewRed}
                toUpdate={toUpdate}
              />
            )}
            {activeTab === "Limite" && (
              <LimitsForm
                changeValue={changeValue}
                form={form}
                setForm={setForm}
              />
            )}
            {activeTab === "Restricciones" && (
              <RestriccionesForm
                changeValue={changeValue}
                form={form}
                setForm={setForm}
                toUpdate={toUpdate}
              />
            )}
            {activeTab === "Mensajes" && (
              <MensajesForm
                changeValue={changeValue}
                form={form}
                setForm={setForm}
              />
            )}
            {activeTab === "IEAutomatico" && (
              <IEAutomaticoForm
                changeValue={changeValue}
                form={form}
                setForm={setForm}
                transacciones={transacciones}
                setTransacciones={setTransacciones}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

const GeneralForm = ({ form, setForm, changeValue, userLevel, toUpdate }) => {
  const [consortiums, setConsortiums] = useState([]);

  const [multPlaysValue, setMultPlaysValue] = useState("");

  useEffect(() => {
    const loadConsortiums = async () => {
      const res = await UserServices.getUsers({ userLevel: 2 });
      if (userLevel === 2) {
        setForm({
          ...form,
          consortium: res[0]?._id,
          multPlays: res[0]?.multPlays,
          minSell: res[0]?.minSell,
        });
        changeValue(res[0]?.multPlays);
      }
      setConsortiums(res);
    };
    loadConsortiums();
  }, []);

  const [inputsChecks, setInputsChecks] = useState({
    showMinSell: true,
    showPlayMult: true,
  });

  const changeInputChecks = (name) => (e) =>
    setInputsChecks((pv) => ({ ...pv, [name]: e.target.checked }));

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h3>Informacion general</h3>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label
                htmlFor="name"
                className="form-label font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Nombre
              </label>
              <input
                value={form.name}
                onChange={changeValue("name")}
                type="text"
                className="form-control"
                id="name"
                placeholder="Francisco"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label
                htmlFor="userName"
                className="form-label font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Nombre de usuario
              </label>
              <input
                value={form.username}
                onChange={changeValue("username")}
                type="text"
                className="form-control"
                id="userName"
                placeholder="Francisco2023"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label
                htmlFor="password"
                className="form-label font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Constraseña
              </label>
              <input
                value={form.password}
                onChange={changeValue("password")}
                type="password"
                className="form-control"
                id="password"
                placeholder="******"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label
                htmlFor="description"
                className="form-label font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Descripcion
              </label>
              <input
                value={form.description}
                onChange={changeValue("description")}
                type="text"
                className="form-control"
                id="description"
                placeholder="Descripcion..."
              />
            </div>
            <div className="col-md-4 mb-3">
              <label
                htmlFor="ticketTitle"
                className="form-label font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Titulo del recibo
              </label>
              <input
                value={form.ticketTitle}
                onChange={changeValue("ticketTitle")}
                type="text"
                className="form-control"
                id="ticketTitle"
                placeholder="Bienvenido :))"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label
                htmlFor="consortium"
                className="form-label font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Consorcio:
              </label>
              <select
                className="form-select"
                id="consortium"
                onChange={changeValue("consortium")}
                disabled={userLevel === 2}
              >
                {userLevel !== 2 && (
                  <>
                    <option selected disabled>
                      Selecciona un consorcio
                    </option>
                    <option value="">Admin</option>
                  </>
                )}

                {consortiums.map((c) => (
                  <option
                    key={c._id}
                    value={c._id}
                    multPlays={c.multPlays}
                    minSell={c.minSell}
                    selected={
                      c._id === form.consortium ? true : userLevel === 2
                    }
                  >
                    {c.username}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <input
                type="checkbox"
                name="showMinSell"
                onChange={changeInputChecks("showMinSell")}
                className="m-2"
                checked={inputsChecks.showMinSell}
              />
              <label htmlFor="showMinSell">Minimo de venta</label>
              {inputsChecks.showMinSell && (
                <input
                  value={form.minSell}
                  onChange={changeValue("minSell")}
                  type="number"
                  className="form-control"
                  id="minSell"
                  placeholder="Minimo de venta"
                />
              )}
            </div>
            <div className="col">
              <input
                type="checkbox"
                name="showPlaysMult"
                onChange={changeInputChecks("showPlayMult")}
                checked={inputsChecks.showPlayMult}
                className="m-2"
              />
              <label htmlFor="showPlaysMult">Multiple jugadas</label>
              {inputsChecks.showPlayMult && (
                <input
                  value={form.multPlays}
                  onChange={changeValue("multPlays")}
                  type="number"
                  className="form-control"
                  id="playsMult"
                  placeholder="Multiple jugadas"
                />
              )}
            </div>
          </div>
          <div className="row">
            <LeaguesSelect form={form} setForm={setForm} />
          </div>
        </div>
      </div>
    </>
  );
};

const LeaguesSelect = ({ form, setForm }) => {
  const [leagues, setLeagues] = useState([]);

  useEffect(() => {
    const loadLeagues = async () => {
      const res = await redApi.get("leagues").then((r) => r.data);
      setLeagues(res);
    };
    loadLeagues();
  }, []);

  const addLeague = (id) => {
    setForm((pv) => ({ ...pv, leagues: [...pv.leagues, id] }));
  };

  const removeLeague = (id) => {
    const newLeagues = [...form.leagues].filter((i) => i !== id);
    // console.log(newLeagues., id);
    setForm((pv) => ({ ...pv, leagues: [...newLeagues] }));
  };

  const setLeague = (id) => {
    if (form.leagues?.includes(id)) removeLeague(id);
    else addLeague(id);
  };

  // default includes all leagues
  useEffect(() => {
    if (form.leagues?.length === 0) {
      setForm((pv) => ({ ...pv, leagues: leagues.map((l) => l._id) }));
    }
  }, [form.leagues]);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h3>Selecciona las ligas</h3>
          {leagues.map((l) => (
            <>
              <div className="col-md-6 my-2" key={l._id}>
                <div
                  className={`${form.leagues?.includes(l._id)
                    ? "bg-primary"
                    : "bg-secondary"
                    } align-items-center justify-content-center py-2 rounded text-center`}
                  onClick={() => setLeague(l._id)}
                >
                  <p className="text-white fw-bold my-2">
                    {form.leagues?.includes(l._id) && (
                      <i className="fa-solid fa-check"></i>
                    )}{" "}
                    {l.name}
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

const PercentForm = ({ form, setForm, changeValue }) => {
  const handleChangeDay = (e) => {
    setForm((pv) => ({
      ...pv,
      percent: {
        ...pv.percent,
        day: e.target.value,
      },
    }));
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h3>Porciento</h3>
            <div className="col-12 my-auto mb-3">
              <div className="form-check d-flex justify-content-center gap-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="wipeRed"
                  checked={form.percent.wipeRed}
                  onChange={(e) =>
                    setForm((pv) => ({
                      ...pv,
                      percent: {
                        ...pv.percent,
                        wipeRed: !pv.percent.wipeRed,
                      },
                    }))
                  }
                />
                <label className="form-check-label fw-bold" htmlFor="wipeRed">
                  Limpiar rojo
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-3">
                <label
                  htmlFor="percentFrequency"
                  className="form-label font-weight-bold"
                  style={{ fontWeight: "bold" }}
                >
                  Frecuencia
                </label>
                <select
                  className="form-select"
                  id="percentFrequency"
                  value={form.percent.frequency}
                  onChange={(e) =>
                    setForm((pv) => ({
                      ...pv,
                      percent: {
                        ...pv.percent,
                        frequency: e.target.value,
                      },
                    }))
                  }
                >
                  <option value="daily">Diario</option>
                  <option value="weekly" selected>
                    Semanal
                  </option>
                  <option value="biweekly">Quincenal</option>
                  <option value="monthly">Mensual</option>
                </select>
              </div>
              <div className="col-md-4 mb-3">
                <label
                  htmlFor="day"
                  className="form-label font-weight-bold"
                  style={{ fontWeight: "bold" }}
                >
                  Dia
                </label>
                {form.percent.frequency === "weekly" ? (
                  <select
                    className="form-select"
                    id="day"
                    value={form.percent.day}
                    onChange={(e) => handleChangeDay(e)}
                  >
                    <option value="0">Domingo</option>
                    <option value="1">Lunes</option>
                    <option value="2">Martes</option>
                    <option value="3">Miercoles</option>
                    <option value="4">Jueves</option>
                    <option value="5">Viernes</option>
                    <option value="6">Sabado</option>
                  </select>
                ) : form.percent.frequency === "monthly" ? (
                  <input
                    value={form.percent.day}
                    onChange={(e) =>
                      setForm((pv) => ({
                        ...pv,
                        percent: {
                          ...pv.percent,
                          day: e.target.value,
                        },
                      }))
                    }
                    type="number"
                    className="form-control"
                    id="day"
                    placeholder=""
                    min="1"
                    max="31"
                  />
                ) : null}
              </div>
              <div className="col-md-4 mb-3">
                <label
                  htmlFor="percent"
                  className="form-label font-weight-bold"
                  style={{ fontWeight: "bold" }}
                >
                  Porciento
                </label>
                <input
                  value={form.percent.percent}
                  onChange={(e) =>
                    setForm((pv) => ({
                      ...pv,
                      percent: {
                        ...pv.percent,
                        percent: e.target.value,
                      },
                    }))
                  }
                  type="number"
                  className="form-control"
                  id="percent"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-md-4 mb-3">
                <label
                  htmlFor="red"
                  className="form-label font-weight-bold"
                  style={{ fontWeight: "bold" }}
                >
                  Configuracion de porcentaje rojo
                </label>
                <input
                  value={form.percent.red}
                  onChange={(e) =>
                    setForm((pv) => ({
                      ...pv,
                      percent: {
                        ...pv.percent,
                        red: e.target.value,
                      },
                    }))
                  }
                  type="number"
                  className="form-control"
                  id="red"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const formatIncomeDay = (time, day) => {
  if (time === "Diario" || time === "Quincenal") {
    return "-"
  }

  if (time === "Semanal") {
    const weekDays = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"]
    return weekDays[day]
  }

  return day;
};

const RedForm = ({
  form,
  setForm,
  changeValue,
  newRed,
  setNewRed,
  toUpdate,
}) => {
  const handleChangeInput = (e) => {
    let red = e.target.value;
    setNewRed(red);
  };

  const handleSubmit = async () => {
    Swal.fire({
      title: "Limpiar rojo",
      text: "¿Estas seguro de limpiar el rojo?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (form?._id || toUpdate._id) {
          await redApi.put("/accountings/red", {
            userId: form?._id || toUpdate._id,
            newRed: 0,
          });
          Swals.success("Rojo limpiado");
        }
      }
    });
  };

  return (
    <>
      <div className="container-fluid">
        <h3>Configuracion de rojo</h3>
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="mb-3">
              <label
                htmlFor="red"
                className="form-label font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Nuevo rojo
              </label>
              <input
                value={newRed}
                onChange={(e) => handleChangeInput(e)}
                type="text"
                className="form-control"
                id="newRed_input"
                placeholder="100"
              />
            </div>
            <button
              className="btn btn-danger w-100 fw-bold"
              onClick={handleSubmit}
            >
              Limpiar rojo
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const LimitsForm = ({ form, setForm }) => {
  const changeValue = (key) => (e) =>
    setForm((pv) => ({
      ...pv,
      limits: {
        ...pv.limits,
        [key]: e.target.value,
      },
    }));
  return (
    <>
      <div className="container-fluid">
        <h3>Configuracion de limites</h3>
        <div className="row justify-content-center">
          <div className="col-5 mb-1">
            <label
              htmlFor="ticketPlays"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Limite de jugadas por ticket
            </label>
            <input
              value={form?.limits?.ticketPlays}
              onChange={changeValue("ticketPlays")}
              type="number"
              className="form-control"
              id="ticketPlays"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-1">
            <label
              htmlFor="ticketPlaysIF"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Limite de jugadas por ticket IF
            </label>
            <input
              value={form?.limits?.ticketPlaysIF}
              onChange={changeValue("ticketPlaysIF")}
              type="number"
              className="form-control"
              id="ticketPlaysIF"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="direct"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Directo
            </label>
            <input
              value={form?.limits?.direct}
              onChange={changeValue("direct")}
              type="number"
              className="form-control"
              id="direct"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="ninth"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Noneta
            </label>
            <input
              value={form?.limits?.ninth}
              onChange={changeValue("ninth")}
              type="number"
              className="form-control"
              id="ninth"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="pale"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Pale
            </label>
            <input
              value={form?.limits?.pale}
              onChange={changeValue("pale")}
              type="number"
              className="form-control"
              id="pale"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="tenth"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Decima
            </label>
            <input
              value={form?.limits?.tenth}
              onChange={changeValue("tenth")}
              type="number"
              className="form-control"
              id="tenth"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>

          <div className="col-5 mb-3">
            <label
              htmlFor="triplet"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Tripleta
            </label>
            <input
              value={form?.limits?.triplet}
              onChange={changeValue("triplet")}
              type="number"
              className="form-control"
              id="triplet"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="eleventh"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Decima primera
            </label>
            <input
              value={form?.limits?.eleventh}
              onChange={changeValue("eleventh")}
              type="number"
              className="form-control"
              id="eleventh"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="quatrain"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Quarteta
            </label>
            <input
              value={form?.limits?.quatrain}
              onChange={changeValue("quatrain")}
              type="number"
              className="form-control"
              id="quatrain"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="twelfth"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Decima segunda
            </label>
            <input
              value={form?.limits?.twelfth}
              onChange={changeValue("twelfth")}
              type="number"
              className="form-control"
              id="twelfth"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="quintet"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Quinteta
            </label>
            <input
              value={form?.limits?.quintet}
              onChange={changeValue("quintet")}
              type="number"
              className="form-control"
              id="quintet"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="thirteenth"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Decima tercera
            </label>
            <input
              value={form?.limits?.thirteenth}
              onChange={changeValue("thirteenth")}
              type="number"
              className="form-control"
              id="thirteenth"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="sextet"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Sexteta
            </label>
            <input
              value={form?.limits?.sextet}
              onChange={changeValue("sextet")}
              type="number"
              className="form-control"
              id="sextet"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="fourteenth"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Decima cuarta
            </label>
            <input
              value={form?.limits?.fourteenth}
              onChange={changeValue("fourteenth")}
              type="number"
              className="form-control"
              id="fourteenth"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="septet"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Septeta
            </label>
            <input
              value={form?.limits?.septet}
              onChange={changeValue("septet")}
              type="number"
              className="form-control"
              id="septet"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="fifteenth"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Decima quinta
            </label>
            <input
              value={form?.limits?.fifteenth}
              onChange={changeValue("fifteenth")}
              type="number"
              className="form-control"
              id="fifteenth"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="octet"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Octeta
            </label>
            <input
              value={form?.limits?.octet}
              onChange={changeValue("octet")}
              type="number"
              className="form-control"
              id="octet"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="sixteenth"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Decima sexta
            </label>
            <input
              value={form?.limits?.sixteenth}
              onChange={changeValue("sixteenth")}
              type="number"
              className="form-control"
              id="sixteenth"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>

          <div className="col-5 mb-3">
            <label
              htmlFor="t2"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              T2
            </label>
            <input
              value={form?.limits?.t2}
              onChange={changeValue("t2")}
              type="number"
              className="form-control"
              id="t2"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="ifMove"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              Jugada IF
            </label>
            <input
              value={form?.limits?.ifMove}
              onChange={changeValue("ifMove")}
              type="number"
              className="form-control"
              id="ifMove"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="t3"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              T3
            </label>
            <input
              value={form?.limits?.t3}
              onChange={changeValue("t3")}
              type="number"
              className="form-control"
              id="octet"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
          <div className="col-5 mb-3">
            <label
              htmlFor="t4"
              className="form-label font-weight-bold"
              style={{ fontWeight: "bold" }}
            >
              T4
            </label>
            <input
              value={form?.limits?.t4}
              onChange={changeValue("t4")}
              type="number"
              className="form-control"
              id="t4"
              placeholder="100"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                border: "2px solid #202020",
                color: "#202020",
                textAlign: "center",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const MensajesForm = ({ form, setForm }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-3">
              <h6 className="text-center h3">Mensajes</h6>
            </div>
            <div className="col-9">
              <input
                type="text"
                className="form-control"
                style={{ height: "200px" }}
                value={form.dailyMessage}
                onChange={(e) =>
                  setForm({ ...form, dailyMessage: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const IEAutomaticoForm = ({ form, transacciones, setTransacciones }) => {
  const generateID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };
  const [incomeUser, setIncomeUser] = useState([]);

  const [errors, setErrors] = useState({
    type: "",
    description: "",
    amount: "",
  });
  const [data, setData] = useState({
    type: "",
    description: "",
    amount: "",
    idgen: generateID(),
    time: "Diario",
    day: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Actualizar el estado principal
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "time") {
      setData((prevData) => ({
        ...prevData,
        day: getInitialDay(value),
      }));
    }
  };

  const getInitialDay = (time) => {
    switch (time) {
      case "Semanal":
        return "1";
      case "Mensual":
        return "1";
      default:
        return "";
    }
  };

  const addTransaccion = () => {
    if (data.type === "" || data.description === "" || data.amount === "") {
      setErrors({
        type: data.type === "" ? "El tipo es requerido" : "",
        description:
          data.description === "" ? "La descripción es requerida" : "",
        amount: data.amount === "" ? "El monto es requerido" : "",
      });
      return;
    }

    setTransacciones([
      ...transacciones,
      {
        type: data.type,
        description: data.description,
        amount: data.amount,
        time: data.time,
        idgen: data.idgen,
        day: data.day,
      },
    ]);
    setIncomeUser([...incomeUser, data]);
    setData({
      type: "",
      description: "",
      amount: "",
      _id: generateID(),
      day: "",
      time: "Diario",
    });
    setErrors({
      type: "",
      description: "",
      amount: "",
    });
  };

  const deleteTransaccion = async (id) => {
    if (form?.income?.find((item) => item._id === id)) {
      await redApi
        .delete(`/accountings/income/${id}`)
        .then((res) => { })
        .catch((err) => {
          console.log(err);
        });
    }
    setTransacciones(transacciones.filter((item) => item.idgen !== id));
    setIncomeUser(incomeUser.filter((item) => item._id !== id));
    Swals.success("Transacción eliminada correctamente");
  };

  useEffect(() => {
    if (form.income) {
      setIncomeUser(form.income);
    }
  }, [form.income]);

  return (
    <div className="container" id="ieautomtico_container">
      <div className="row d-flex align-items-center justify-content-center gap-3 py-4 my-2">
        <div className="col">
          <div className="row d-flex flex-column align-items-center justify-content-center">
            <div className="col text-center">
              <label
                htmlFor="Tipo-de-transaccion"
                className="form-label font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Tipo de transacción:
              </label>
            </div>
            <div className="col">
              <select
                className="form-select"
                id="Tipo-de-transaccion"
                name="type"
                onChange={handleInputChange}
                value={data.type}
                style={{
                  border: errors.type ? "1px solid red" : "none",
                }}
              >
                <option selected>Seleccione</option>
                <option value="Ingreso">Ingreso</option>
                <option value="Egreso">Egreso</option>
              </select>
              <div className="text-danger">{errors.type}</div>
            </div>
          </div>
        </div>
        <div className="col ">
          <div className="row d-flex flex-column align-items-center justify-content-center">
            <div className="col text-center">
              <label
                htmlFor="descripcion"
                className="form-label font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Descripción:
              </label>
            </div>
            <div className="col">
              <input
                className="form-control"
                type="text"
                name="description"
                onChange={handleInputChange}
                value={data.description}
                style={{
                  border: errors.description ? "1px solid red" : "none",
                }}
              />
              <div className="text-danger">{errors.description}</div>
            </div>
          </div>
        </div>
        <div className="col ">
          <div className="row d-flex flex-column align-items-center justify-content-center">
            <div className="col text-center">
              <label
                htmlFor="cantidad"
                className="form-label font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Cantidad:
              </label>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                name="amount"
                onChange={handleInputChange}
                value={data.amount}
                style={{
                  border: errors.amount ? "1px solid red" : "none",
                }}
              />
              <div className="text-danger">{errors.amount}</div>
            </div>
          </div>
        </div>
        <div className="col ">
          <div className="row d-flex flex-column align-items-center justify-content-center">
            <div className="col text-center">
              <label
                htmlFor="cantidad"
                className="form-label font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Frecuencia:
              </label>
            </div>
            <div className="col">
              <select
                name="time"
                id="time"
                className="form-select"
                onChange={handleInputChange}
                value={data.time}
              >
                <option value="Diario">Diario</option>
                <option value="Semanal">Semanal</option>
                <option value="Quincenal">Quincenal</option>
                <option value="Mensual">Mensual</option>
              </select>
            </div>
          </div>
        </div>
        {data.time === "Semanal" && (
          <div className="col ">
            <div className="row d-flex flex-column align-items-center justify-content-center">
              <div className="col text-center">
                <label
                  htmlFor="cantidad"
                  className="form-label font-weight-bold"
                  style={{ fontWeight: "bold" }}
                >
                  Dia:
                </label>
              </div>
              <div className="col">
                <select
                  name="day"
                  id="day"
                  className="form-select"
                  onChange={handleInputChange}
                  value={data.day}
                >
                  <option value="1">Lunes</option>
                  <option value="2">Martes</option>
                  <option value="3">Miercoles</option>
                  <option value="4">Jueves</option>
                  <option value="5">Viernes</option>
                  <option value="6">Sabado</option>
                  <option value="0">Domingo</option>
                </select>
              </div>
            </div>
          </div>
        )}
        {data.time === "Mensual" && (
          <div className="col ">
            <div className="row d-flex flex-column align-items-center justify-content-center">
              <div className="col text-center">
                <label
                  htmlFor="cantidad"
                  className="form-label font-weight-bold"
                  style={{ fontWeight: "bold" }}
                >
                  Dia:
                </label>
              </div>
              <div className="col">
                <select
                  name="day"
                  id="day"
                  className="form-select"
                  onChange={handleInputChange}
                  value={data.day}
                >
                  {Array.from({ length: 31 }, (_, index) => index + 1).map(
                    (day) => (
                      <option key={day} value={day.toString()}>
                        {day}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
          </div>
        )}
        <div className="col ">
          <div className="row d-flex flex-column align-items-center justify-content-center">
            <div className="col text-center">
              <label
                htmlFor="cantidad"
                className="form-label font-weight-bold "
                style={{
                  fontWeight: "bold",
                  visibility: "hidden",
                }}
              >
                Transacción:
              </label>
            </div>
            <div className="col">
              <button
                type="button"
                onClick={addTransaccion}
                style={{
                  fontSize: "0.7rem",
                }}
              >
                <i className="fas fa-plus"></i> Agregar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row d-flex align-items-center justify-content-center gap-3 py-4 my-2">
        <div className="col-12">
          <table className="table-striped">
            <thead>
              <tr>
                <th scope="col">Tipo de transacción</th>
                <th scope="col">Descripción</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Frecuencia</th>
                <th scope="col">Dia</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {incomeUser &&
                incomeUser?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.type}</td>
                      <td>{item.description}</td>
                      <td>{item.amount}</td>
                      <td>{item?.time}</td>
                      <td>{formatIncomeDay(item?.time, item?.day)}</td>
                      <td>
                        <button
                          onClick={() =>
                            deleteTransaccion(item._id || item.idgen)
                          }
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const ModalRestricciones = ({ form, setForm, toUpdate, ...props }) => {
  const handleOk = () => {
    if (props.modalEditar) {
      props.setModalEditar(false);
      addRestriccion();
    } else if (props.modalCrear) {
      props.setModalCrear(false);
      addRestriccion();
    }
  };
  const [leagues, setLeagues] = useState([]);
  useEffect(() => {
    redApi
      .get("/leagues")
      .then((res) => {
        setLeagues(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleCancel = () => {
    if (props.modalEditar) {
      props.setModalEditar(false);
    } else if (props.modalCrear) {
      props.setModalCrear(false);
    }
  };

  let tipodetikcet = [
    "Todos",
    "Directo",
    "Pale",
    "Tripleta",
    "Cuarteta",
    "Quinteta",
    "Sexteta",
    "Septeta",
    "Octeta",
    "Noneta",
    "Decima",
    "Decima primera",
    "Decima segunda",
    "Decima tercera",
    "Decima cuarta",
    "Decima quinta",
    "Decima sexta",
  ];
  let tipodelinea = [
    "Todos",
    "ML",
    "TL",
    "RL",
    "SRL",
    "Si anotaran",
    "No anotaran",
    "Primera carrera",
    "Ponche",
    "Solo",
    "Jugadores",
    "GS ML",
    "GS TL",
    "Tercios",
    "Serie",
    "R/L A",
    "Tercios TL",
  ];
  let periodo = [
    "Todos",
    "Juego comppleto",
    "Mitad",
    "Segunda mitad",
    "Q1",
    "Q2",
    "Q3",
    "Q4",
    "P1",
    "P2",
    "P3",
  ];

  const [restriccion, setRestriccion] = useState({
    league: props?.RestriccionSelected?.league?._id
      ? props?.RestriccionSelected?.league?._id
      : "",
    ticketType: props?.RestriccionSelected?.ticketType
      ? props?.RestriccionSelected?.ticketType
      : [],
    lineType: props?.RestriccionSelected?.lineType
      ? props?.RestriccionSelected?.lineType
      : [],
    period: props?.RestriccionSelected?.period
      ? props?.RestriccionSelected?.period
      : [],
    type: props?.RestriccionSelected?.type
      ? props?.RestriccionSelected?.type
      : props?.resType == "Por Jugada"
        ? "PJ"
        : props?.resType == "Por Combinacion"
          ? "PC"
          : "PT",
    playType1: props?.RestriccionSelected?.playType1
      ? props?.RestriccionSelected?.playType1
      : [],
    playType2: props?.RestriccionSelected?.playType2
      ? props?.RestriccionSelected?.playType2
      : [],
  });

  const handlechange = (e) => {
    if (e.target.name === "ticketType") {
      if (e.target.checked) {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            ticketType: tipodetikcet,
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          ticketType: [...restriccion.ticketType, e.target.value],
        });
      } else {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            ticketType: [],
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          ticketType: restriccion.ticketType.filter(
            (item) => item !== e.target.value
          ),
        });
      }
    } else if (e.target.name === "lineType") {
      if (e.target.checked) {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            lineType: tipodelinea,
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          lineType: [...restriccion.lineType, e.target.value],
        });
      } else {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            lineType: [],
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          lineType: restriccion.lineType.filter(
            (item) => item !== e.target.value
          ),
        });
      }
    } else if (e.target.name === "period") {
      if (e.target.checked) {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            period: periodo,
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          period: [...restriccion.period, e.target.value],
        });
      } else {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            period: [],
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          period: restriccion.period.filter((item) => item !== e.target.value),
        });
      }
    } else if (e.target.name === "playType1") {
      if (e.target.checked) {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            playType1: PlaysTypes,
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          playType1: [...restriccion.playType1, e.target.value],
        });
      } else {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            playType1: [],
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          playType1: restriccion.playType1.filter(
            (item) => item !== e.target.value
          ),
        });
      }
    } else if (e.target.name === "playType2") {
      if (e.target.checked) {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            playType2: PlaysTypes,
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          playType2: [...restriccion.playType2, e.target.value],
        });
      } else {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            playType2: [],
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          playType2: restriccion.playType2.filter(
            (item) => item !== e.target.value
          ),
        });
      }
    } else {
      setRestriccion({
        ...restriccion,
        [e.target.name]: e.target.value,
      });
    }
  };

  const addRestriccion = async () => {
    if (props.modalEditar) {
      if (restriccion.type === "PJ") {
        await redApi
          .put(`/restrictions`, {
            ...restriccion,
            id: props.RestriccionSelected._id,
          })
          .then((res) => {
            props.setModalEditar(false);
            props.setRestriccionSelected({});
            Swal.fire({
              icon: "success",
              title: "Restricción editada",
              showConfirmButton: false,
              timer: 1500,
            });
            props.getRestrictions();
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      } else if (restriccion.type === "PC" || restriccion.type === "PT") {
        await redApi
          .put(`/restrictions`, {
            id: props.RestriccionSelected._id,
            type: restriccion.type,
            league: restriccion.league,
            playType1: restriccion.playType1,
            playType2: restriccion.playType2,
          })
          .then((res) => {
            props.setModalEditar(false);
            props.setRestriccionSelected({});
            Swal.fire({
              icon: "success",
              title: "Restricción editada",
              showConfirmButton: false,
              timer: 1500,
            });
            props.getRestrictions();
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      }
    } else if (props.modalCrear) {
      if (toUpdate) {
        // si es true, es porque se esta editando un usuario
        if (restriccion.type === "PJ") {
          await redApi
            .post(`/restrictions`, {
              user: props.consorcioSelected,
              ...restriccion,
            })
            .then((res) => {
              props.setModalCrear(false);
              Swal.fire({
                icon: "success",
                title: "Restricción creada",
                showConfirmButton: false,
                timer: 1500,
              });
              props.getRestrictions();
            })
            .catch((err) => {
              console.log(err);
            });
          return;
        } else if (restriccion.type === "PC" || restriccion.type === "PT") {
          // console.log({
          //   type: restriccion.type,
          //   league: restriccion.league,
          //   playType: restriccion.playType,
          //   user: props.consorcioSelected
          // })
          console.log({
            type: restriccion.type,
            league: restriccion.league,
            playType1: restriccion.playType1,
            playType2: restriccion.playType2,
            user: props.consorcioSelected,
          });
          await redApi
            .post(`/restrictions`, {
              type: restriccion.type,
              league: restriccion.league,
              playType1: restriccion.playType1,
              playType2: restriccion.playType2,
              user: props.consorcioSelected,
            })
            .then((res) => {
              props.setModalCrear(false);
              Swal.fire({
                icon: "success",
                title: "Restricción creada",
                showConfirmButton: false,
                timer: 1500,
              });
              props.getRestrictions();
            })
            .catch((err) => {
              console.log(err);
            });
          return;
        }
      } else {
        if (restriccion.type === "PJ") {
          setForm({
            ...form,
            restrictions: [
              ...form.restrictions,
              {
                ...restriccion,
              },
            ],
          });
          props.setModalCrear(false);
          Swal.fire({
            icon: "success",
            title: "Restricción creada",
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        } else if (restriccion.type === "PC" || restriccion.type === "PT") {
          setForm({
            ...form,
            restrictions: [
              ...form.restrictions,
              {
                type: restriccion.type,
                league: restriccion.league,
                playType1: restriccion.playType1,
                playType2: restriccion.playType2,
              },
            ],
          });
          props.setModalCrear(false);
          Swal.fire({
            icon: "success",
            title: "Restricción creada",
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        }
      }
    }
  };

  let PlaysTypes = [
    "Todos",
    "EVEN",
    "EVENT H1",
    "EVENT P1",
    "EVENT P2",
    "EVENT P3",
    "R/L",
    "R/L H1",
    "R/L Q1",
    "R/L Q2",
    "R/L Q3",
    "R/L Q4",
    "R/L P1",
    "R/L P2",
    "R/L P3",
    "R/L H2",
    "A Mas",
    "A Mas H",
    "A Mas Q1",
    "A Mas Q2",
    "A Mas Q3",
    "A Mas Q4",
    "A Mas P1",
    "A Mas P2",
    "A Mas P3",
    "A Mas H2",
    "Solo +",
    "Solo +H",
    "Solo +Q1",
    "Solo +Q2",
    "Solo +Q3",
    "Solo +Q4",
    "Solo +H2",
    "Solo -",
    "Solo -H",
    "Solo -Q1",
    "Solo -Q2",
    "Solo -Q3",
    "Solo -Q4",
    "Solo -H2",
    "SR/L",
    "Yes",
    "K +",
    "K -",
    "1 era",
    "Compra",
    "Venta",
    "C a mas",
    "C a menos -",
    "J1",
    "J2",
    "J3",
    "J4",
    "J5",
    "GS ML",
    "GS a mas",
    "GS a menos",
    "Serie",
    "AR/L",
    "Tercio",
    "Tercio a mas",
    "Tercio a menos",
    "V a mas",
    "V a menos",
    "Empate",
    "R/L H1",
    "A Mas H",
    "A Menos H",
  ];

  return (
    <Modal
      title={props.modalEditar ? "Editar restricción" : "Crear restricción"}
      open={props.modalEditar || props.modalCrear}
      onOk={handleOk}
      onCancel={handleCancel}
      width="90%"
      style={{ top: 20 }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={addRestriccion}>
          {props.modalEditar ? "Editar restricción" : "Crear restricción"}
        </Button>,
      ]}
    >
      <div className="row d-flex flex-column">
        <div className="row d-flex flex-column my-4">
          <div className="col-12">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-3 text-end">
                <label
                  htmlFor="select-league"
                  className="form-label font-weight-bold"
                  style={{ fontWeight: "bold" }}
                >
                  Liga
                </label>
                <select
                  name="league"
                  id="select-league"
                  className="form-select"
                  onChange={handlechange}
                >
                  <option disabled selected>
                    {" "}
                    -- Seleccione una liga --{" "}
                  </option>
                  {leagues.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            {restriccion.type === "PJ" ? (
              <>
                <div className="col-4">
                  <h6 className="text-center">Tipo De Ticket</h6>
                </div>
                <div className="col-4">
                  <h6 className="text-center">Tipo De Linea</h6>
                </div>
                <div className="col-4">
                  <h6 className="text-center">Periodo</h6>
                </div>
              </>
            ) : (
              <>
                <div className="col-4">
                  <h6 className="text-center">Tipo De Jugada</h6>
                </div>
                {/* <div className="col-4">
                      <h6 className="text-center">Tipo De Jugada</h6>
                    </div> */}
              </>
            )}
          </div>
        </div>
        <div className="col-12">
          {restriccion.type === "PJ" ? (
            <div className="row d-flex justify-content-center">
              <div className="col-4 d-flex justify-content-start align-items-center flex-column">
                {tipodetikcet.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="row d-flex justify-content-center align-items-center w-100"
                    >
                      <div className="col-1">
                        <input
                          type="checkbox"
                          name="ticketType"
                          value={item}
                          onChange={handlechange}
                          checked={restriccion.ticketType.includes(item)}
                        />
                      </div>
                      <div className="col-11">
                        <label>{item}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-4 d-flex justify-content-startr align-items-center flex-column">
                {tipodelinea.map((item, index) => {
                  return (
                    <div
                      className="row d-flex justify-content-center align-items-center w-100"
                      key={index}
                    >
                      <div className="col-1">
                        <input
                          type="checkbox"
                          name="lineType"
                          value={item}
                          onChange={handlechange}
                          checked={restriccion.lineType.includes(item)}
                        />
                      </div>
                      <div className="col-11">
                        <label>{item}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-4 d-flex justify-content-start align-items-center flex-column">
                {periodo.map((item, index) => {
                  return (
                    <div
                      className="row d-flex justify-content-center align-items-center w-100"
                      key={index}
                    >
                      <div className="col-1">
                        <input
                          type="checkbox"
                          name="period"
                          value={item}
                          onChange={handlechange}
                          checked={restriccion.period.includes(item)}
                        />
                      </div>
                      <div className="col-11">
                        <label>{item}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="row d-flex justify-content-center">
              <div
                className="col-4 d-flex justify-content-start align-items-center flex-column"
                style={{
                  maxHeight: "60vh",
                  overflowY: "auto",
                }}
              >
                {PlaysTypes.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="row d-flex justify-content-center align-items-center w-100"
                    >
                      <div className="col-1">
                        <input
                          type="checkbox"
                          name="playType1"
                          value={item}
                          onChange={handlechange}
                          checked={restriccion?.playType1?.includes(item)}
                        />
                      </div>
                      <div className="col-11">
                        <label>{item}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="col-4 d-flex justify-content-start align-items-center flex-column"
                style={{
                  maxHeight: "60vh",
                  overflowY: "auto",
                }}
              >
                {PlaysTypes.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="row d-flex justify-content-center align-items-center w-100"
                    >
                      <div className="col-1">
                        <input
                          type="checkbox"
                          name="playType2"
                          value={item}
                          onChange={handlechange}
                          checked={restriccion?.playType2?.includes(item)}
                        />
                      </div>
                      <div className="col-11">
                        <label>{item}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
const RestriccionesForm = ({ toUpdate, setForm, form }) => {
  let buttonsNav = ["Por Jugada", "Por Combinacion", "Por Ticket"];

  const [active, setActive] = useState("Por Jugada");
  const handleActive = (e) => {
    let value = e.target.value;
    setActive(value);
  };

  let buttons = ["Crear", "Editar", "Eliminar", "Refrescar"];
  const [modalCrear, setModalCrear] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [RestriccionSelected, setRestriccionSelected] = useState({});
  const handleClick = (e) => {
    let text = e.target.innerText;
    if (text === "Crear") {
      setModalCrear(true);
    }
    if (text === "Editar") {
      if (!RestriccionSelected.user) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes seleccionar un usuario para editar!",
        });
        return;
      }
      setModalEditar(true);
    }
    if (text === "Eliminar") {
      if (!RestriccionSelected?._id) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes seleccionar un usuario para eliminar!",
        });
        return;
      }
      Swal.fire({
        title: "Eliminar",
        text: "¿Estas seguro de eliminar el usuario?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteRestriction(RestriccionSelected._id);
        }
      });
    }
    if (text === "Refrescar") {
      Swal.fire({
        title: "Refrescar",
        text: "¿Estas seguro de refrescar la tabla?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Refrescar",
      }).then((result) => {
        if (result.isConfirmed) {
          getRestrictions();
          Swal.fire("Refrescado!", "La tabla se ha refrescado.", "success");
        }
      });
    }
  };

  const handleSelect = (item) => {
    setRestriccionSelected(item);
  };

  const [restricciones, setRestricciones] = useState(form.restrictions || []);
  const [consorcios, setConsorcios] = useState([]);
  const [consorcioSelected, setConsorcioSelected] = useState(toUpdate?._id);

  useEffect(() => {
    if (!form.restrictions) return;
    setRestricciones(form.restrictions);
  }, [form.restrictions]);
  const getRestrictions = async () => {
    try {
      await redApi
        .get(
          `/restrictions?type=${active === "Por Jugada"
            ? "PJ"
            : active === "Por Combinacion"
              ? "PC"
              : "PT"
          }&user=${toUpdate?._id}`
        )
        .then((res) => {
          setRestricciones(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (!toUpdate?._id) return;
  //   getRestrictions();
  // }, []);

  const deleteRestriction = async (id) => {
    try {
      await redApi
        .delete(`/restrictions/${id}`)
        .then((res) => {
          getRestrictions();
          Swal.fire({
            icon: "success",
            title: "Eliminado!",
            text: "La restriccion ha sido eliminada.",
            timer: 2000,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const [leagues, setLeagues] = useState([]);
  useEffect(() => {
    redApi
      .get("/leagues")
      .then((res) => {
        setLeagues(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [filters, setFilters] = useState({
    pj: {
      user: "",
      league: "",
      lineType: "",
      period: "",
      ticketType: "",
    },
    pc: {
      user: "",
      league: "",
      playType: "",
    },
    pt: {
      user: "",
      league: "",
      playType: "",
    },
  });

  const handleFilter = (e) => {
    let { id, name, value } = e.target;
    setFilters({
      ...filters,
      [id]: {
        ...filters[id],
        [name]: value,
      },
    });
  };

  useEffect(() => {
    if (!toUpdate?._id) return;
    switch (active) {
      case "Por Jugada":
        redApi
          .get(
            `/restrictions?type=PJ&user=${toUpdate?._id}${filters.pj.league && `&league=${filters.pj.league}`
            }${filters.pj.lineType && `&lineType=${filters.pj.lineType}`}${filters.pj.period && `&period=${filters.pj.period}`
            }${filters.pj.ticketType && `&ticketType=${filters.pj.ticketType}`
            }${filters.pc.playType && `&playType=${filters.pc.playType}`}${filters.pt.playType && `&playType=${filters.pt.playType}`
            }`
          )
          .then((res) => {
            setRestricciones(res.data);
          });
        break;
      case "Por Combinacion":
        redApi
          .get(
            `/restrictions?type=PC&user=${toUpdate?._id}${filters.pc.league && `&league=${filters.pc.league}`
            }${filters.pc.playType && `&playType=${filters.pc.playType}`}`
          )
          .then((res) => {
            setRestricciones(res.data);
          });
        break;
      case "Por Ticket":
        redApi
          .get(
            `/restrictions?type=PT&user=${toUpdate?._id}${filters.pt.league && `&league=${filters.pt.league}`
            }${filters.pt.playType && `&playType=${filters.pt.playType}`}`
          )
          .then((res) => {
            setRestricciones(res.data);
          });
        break;
      default:
        getRestrictions();
        break;
    }
  }, [filters, active]);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="row mb-3">
            <div className="col-6">
              <div className="row px-0">
                {buttonsNav.map((item, index) => {
                  return (
                    <div key={index} className="col-3 px-0">
                      <button
                        style={{ fontSize: "12px" }}
                        value={item}
                        onClick={handleActive}
                        className={`btn w-100 text-white outline-none border-0 rounded-0 text-nowrap ${active === item ? "bg-primary" : "bg-secondary"
                          }`}
                      >
                        {item}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {active === "Por Jugada" ? (
          <>
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-6">
                  <div className="row">
                    {buttons.map((item, index) => {
                      return (
                        <div key={index} className="col-3 px-0">
                          <button
                            className="btn btn-outline-primary w-100"
                            style={{ fontSize: "12px" }}
                            onClick={handleClick}
                          >
                            {item}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 ">
                  <div className="row d-flex justify-content-center">
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Liga
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        name="league"
                        id="pj"
                        onChange={(e) => handleFilter(e)}
                      >
                        <option value="todos">Todos</option>
                        {leagues?.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Linea
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="lineType"
                        id="pj"
                        value={filters.pj.lineType}
                      >
                        <option value="">Todos</option>
                        {[
                          "ML",
                          "TL",
                          "RL",
                          "SRL",
                          "Si anotaran",
                          "No anotaran",
                          "Primera carrera",
                          "Ponche",
                          "Solo",
                          "Jugadores",
                          "GS ML",
                          "GS TL",
                          "Tercios",
                          "Serie",
                          "R/L A",
                          "Tercios TL",
                        ].map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        {" "}
                        Periodo
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="period"
                        id="pj"
                        value={filters.pj.period}
                      >
                        <option value="">Todos</option>
                        {[
                          "Juego completo",
                          "Mitad",
                          "Segunda mitad",
                          "Q1",
                          "Q2",
                          "Q3",
                          "Q4",
                          "P1",
                          "P2",
                          "P3",
                        ].map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Ticket
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="ticketType"
                        id="pj"
                        value={filters.pj.ticketType}
                      >
                        <option value="">Todos</option>
                        {[
                          "Directo",
                          "Pale",
                          "Tripleta",
                          "Cuarteta",
                          "Quinteta",
                          "Sexteta",
                          "Septeta",
                          "Octeta",
                          "Noneta",
                          "Decima",
                          "Decima primera",
                          "Decima segunda",
                          "Decima tercera",
                          "Decima cuarta",
                          "Decima quinta",
                          "Decima sexta",
                        ].map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr className="table-primary w-100">
                    <th scope="col">#</th>
                    <th scope="col">Consorcio</th>
                    <th scope="col">Liga</th>
                    <th scope="col">Linea</th>
                    <th scope="col">Periodo</th>
                    <th scope="col">Ticket</th>
                  </tr>
                </thead>
                <tbody>
                  {restricciones?.map((item, index) => {
                    return (
                      item.type === "PJ" && (
                        <tr
                          key={index}
                          onClick={() => handleSelect(item)}
                          className={
                            RestriccionSelected._id === item._id
                              ? "table-primary"
                              : ""
                          }
                        >
                          <th scope="row">{item?.NDI}</th>
                          <td>{item?.user?.username || "~"}</td>
                          <td>
                            {item?.league?.name ||
                              leagues.find(
                                (league) => league._id === item.league
                              )?.name}
                          </td>
                          <td>
                            {item.lineType?.length > 2
                              ? item.lineType.slice(-2).map((line, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${line}${index === 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.lineType.map((line, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${line}${index === 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                          <td>
                            {item.period?.length > 2
                              ? item.period?.slice(-2).map((period, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${period}${index === 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.period?.map((period, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${period}${index === 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                          <td>
                            {item.ticketType?.length > 2
                              ? item.ticketType
                                ?.slice(-2)
                                ?.map((ticket, index) => {
                                  return (
                                    <div key={index}>
                                      <span>{`${ticket}${index === 1 ? "..." : ""
                                        }`}</span>
                                    </div>
                                  );
                                })
                              : item.ticketType?.map((ticket, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${ticket}${index === 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : active === "Por Combinacion" ? (
          <>
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-6">
                  <div className="row">
                    {buttons.map((item, index) => {
                      return (
                        <div key={index} className="col-3 px-0">
                          <button
                            className="btn btn-outline-primary w-100"
                            style={{ fontSize: "12px" }}
                            onClick={handleClick}
                          >
                            {item}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 ">
                  <div className="row d-flex justify-content-center">
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Liga
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="league"
                        id="pc"
                        value={filters.pc.league}
                      >
                        <option value="todos">Todos</option>
                        {leagues?.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Tipo de jugada
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="playType"
                        id="pc"
                        value={filters.pc.playType}
                      >
                        <option value="">Todos</option>
                        {[
                          "EVEN",
                          "EVENT H1",
                          "EVENT P1",
                          "EVENT P2",
                          "EVENT P3",
                          "R/L",
                          "R/L H1",
                          "R/L Q1",
                          "R/L Q2",
                          "R/L Q3",
                          "R/L Q4",
                          "R/L P1",
                          "R/L P2",
                          "R/L P3",
                          "R/L H2",
                          "A Mas",
                          "A Mas H",
                          "A Mas Q1",
                          "A Mas Q2",
                          "A Mas Q3",
                          "A Mas Q4",
                          "A Mas P1",
                          "A Mas P2",
                          "A Mas P3",
                          "A Mas H2",
                          "Solo +",
                          "Solo +H",
                          "Solo +Q1",
                          "Solo +Q2",
                          "Solo +Q3",
                          "Solo +Q4",
                          "Solo +H2",
                          "Solo -",
                          "Solo -H",
                          "Solo -Q1",
                          "Solo -Q2",
                          "Solo -Q3",
                          "Solo -Q4",
                          "Solo -H2",
                          "SR/L",
                          "Yes",
                          "K +",
                          "K -",
                          "1 era",
                          "Compra",
                          "Venta",
                          "C a mas",
                          "C a menos -",
                          "J1",
                          "J2",
                          "J3",
                          "J4",
                          "J5",
                          "GS ML",
                          "GS a mas",
                          "GS a menos",
                          "Serie",
                          "AR/L",
                          "Tercio",
                          "Tercio a mas",
                          "Tercio a menos",
                          "V a mas",
                          "V a menos",
                          "Empate",
                          "R/L H1",
                          "A Mas H",
                          "A Menos H",
                        ].map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr className="table-primary w-100">
                    <th scope="col">#</th>
                    <th scope="col">Consorcio</th>
                    <th scope="col">Liga</th>
                    <th scope="col">Tipo de Jugada</th>
                    <th scope="col">Tipo de Jugada</th>
                  </tr>
                </thead>
                <tbody>
                  {restricciones?.map((item, index) => {
                    return (
                      item.type === "PC" && (
                        <tr
                          key={index}
                          onClick={() => handleSelect(item)}
                          className={
                            RestriccionSelected._id === item._id
                              ? "table-primary"
                              : ""
                          }
                        >
                          <th scope="row">{item?.NDI}</th>
                          <td>{item?.user?.username || "Todos"}</td>
                          <td>{item.league.name || "Todas"}</td>
                          <td>
                            {item.playType1?.length > 2
                              ? item.playType1.slice(-2).map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.playType1.map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                          <td>
                            {item.playType2?.length > 2
                              ? item.playType2.slice(-2).map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.playType2.map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-6">
                  <div className="row">
                    {buttons.map((item, index) => {
                      return (
                        <div key={index} className="col-3 px-0">
                          <button
                            className="btn btn-outline-primary w-100"
                            style={{ fontSize: "12px" }}
                            onClick={handleClick}
                          >
                            {item}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 ">
                  <div className="row d-flex justify-content-center">
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Liga
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="league"
                        id="pt"
                        value={filters.pt.league}
                      >
                        <option value="todos">Todos</option>
                        {leagues?.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Tipo de jugada
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="playType"
                        id="pt"
                        value={filters.pt.playType}
                      >
                        <option value="todos">Todos</option>
                        {[
                          "EVEN",
                          "EVENT H1",
                          "EVENT P1",
                          "EVENT P2",
                          "EVENT P3",
                          "R/L",
                          "R/L H1",
                          "R/L Q1",
                          "R/L Q2",
                          "R/L Q3",
                          "R/L Q4",
                          "R/L P1",
                          "R/L P2",
                          "R/L P3",
                          "R/L H2",
                          "A Mas",
                          "A Mas H",
                          "A Mas Q1",
                          "A Mas Q2",
                          "A Mas Q3",
                          "A Mas Q4",
                          "A Mas P1",
                          "A Mas P2",
                          "A Mas P3",
                          "A Mas H2",
                          "Solo +",
                          "Solo +H",
                          "Solo +Q1",
                          "Solo +Q2",
                          "Solo +Q3",
                          "Solo +Q4",
                          "Solo +H2",
                          "Solo -",
                          "Solo -H",
                          "Solo -Q1",
                          "Solo -Q2",
                          "Solo -Q3",
                          "Solo -Q4",
                          "Solo -H2",
                          "SR/L",
                          "Yes",
                          "K +",
                          "K -",
                          "1 era",
                          "Compra",
                          "Venta",
                          "C a mas",
                          "C a menos -",
                          "J1",
                          "J2",
                          "J3",
                          "J4",
                          "J5",
                          "GS ML",
                          "GS a mas",
                          "GS a menos",
                          "Serie",
                          "AR/L",
                          "Tercio",
                          "Tercio a mas",
                          "Tercio a menos",
                          "V a mas",
                          "V a menos",
                          "Empate",
                          "R/L H1",
                          "A Mas H",
                          "A Menos H",
                        ].map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr className="table-primary w-100">
                    <th scope="col">#</th>
                    <th scope="col">Consorcio</th>
                    <th scope="col">Liga</th>
                    <th scope="col">Tipo de Jugada</th>
                    <th scope="col">Tipo de Jugada</th>
                  </tr>
                </thead>
                <tbody>
                  {restricciones?.map((item, index) => {
                    return (
                      item.type === "PT" && (
                        <tr
                          key={index}
                          onClick={() => handleSelect(item)}
                          className={
                            RestriccionSelected._id === item._id
                              ? "table-primary"
                              : ""
                          }
                        >
                          <th scope="row">{item?.NDI}</th>
                          <td>{item.user.username}</td>
                          <td>{item.league.name}</td>
                          <td>
                            {item.playType1?.length > 2
                              ? item.playType1.slice(-2).map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.playType1.map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                          <td>
                            {item.playType2?.length > 2
                              ? item.playType2.slice(-2).map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.playType2.map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
        {modalCrear ? (
          <ModalRestricciones
            setConsorcioSelected={setConsorcioSelected}
            consorcioSelected={consorcioSelected}
            consorcios={consorcios}
            setRestricciones={setRestricciones}
            restricciones={restricciones}
            setModalCrear={setModalCrear}
            modalCrear={modalCrear}
            toUpdate={toUpdate}
            resType={active}
            getRestrictions={getRestrictions}
            form={form}
            setForm={setForm}
          />
        ) : null}
        {modalEditar ? (
          <ModalRestricciones
            setConsorcioSelected={setConsorcioSelected}
            consorcioSelected={consorcioSelected}
            consorcios={consorcios}
            setRestricciones={setRestricciones}
            restricciones={restricciones}
            setModalEditar={setModalEditar}
            modalEditar={modalEditar}
            RestriccionSelected={RestriccionSelected}
            setRestriccionSelected={setRestriccionSelected}
            toUpdate={toUpdate}
            resType={active}
            getRestrictions={getRestrictions}
          />
        ) : null}
      </div>
    </div>
  );
};

export default UserForm;
