import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';


function getToWin(bets, amount) {
    let result = 0;
    for (let i = 0; i < bets.length; i++) {

        if (bets[i].price.toString().includes('+') && bets[i].price.toString().includes('-')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a valid number',
            })
            return
        }
        const multiplier = bets[i].price >= 0
            ? bets[i].price / 100
            : 1 / ((bets[i].price * -1) / 100);
        result = ((result + amount) * multiplier + result);
    }
    result = Math.round(result);
    return result
}

const PLayCalculator = () => {

    const [form, setForm] = useState({
        betAmount: "",
        betOdds: [
            // {
            //     index: 0,
            //     price: ""
            // }
        ]
    })

    const [betType, setBetType] = useState("parlay")

    const handleBetType = (e) => {
        setBetType(e.target.value)
        setForm({
            betAmount: "",
            betOdds: []
        })
        setIndexOdds(1)
    }

    const handleForm = (e) => {
        if (e.target.name.includes("betOdds")) {
            const index = e.target.id
            // betOdds: [
            //     // {
            //     //     index: 0,
            //     //     price: ""
            //     // }
            // ]
            const copyForm = { ...form }
            let objetOdds = copyForm.betOdds.find((item) => item.index === index)
            if (objetOdds) {
                objetOdds.price = e.target.value.toString().slice(-4)
            }
            else {
                copyForm.betOdds.push({ index, price: e.target.value.toString().slice(-4) })
            }
            setForm(copyForm)
        } else {
            setForm({ ...form, [e.target.name]: e.target.value })
        }
    }

    const [indexOdds, setIndexOdds] = useState(4)
    const addnewBet = () => {
        setIndexOdds(indexOdds + 1)
    }
    useEffect(() => {
        let input = document.getElementById(`${indexOdds - 1}`)
        if (input) {
            input.focus()
        }

    }, [indexOdds])

    const [toWin, setToWin] = useState(null)


    const calculateToWin = () => {
        if (form.betAmount === "" || form.betOdds.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter a bet amount',
            })
            return
        }
        const toWin = getToWin(form.betOdds, parseInt(form.betAmount))
        setToWin(toWin)
        setForm({
            betAmount: "",
            betOdds: []
        })



    }

    return (
        <div className="play-calculator-container">
            <h1
                className="text-center mb-3 text-white text-uppercase font-weight-bold"
            >
                PLAY CALCULATOR
            </h1>
            <div className="d-flex align-items-center justify-content-center mb-3">
                <select name="" id="" onChange={handleBetType} value={betType}>
                    <option value="parlay">Parlay</option>
                    <option value="singlebet">Single Bet</option>
                </select>
            </div>
            <div>
                {
                    betType === "singlebet" ?
                        <div id='typesinglebet'>
                            <div className="d-flex align-items-center justify-content-center mb-3 flex-column">
                                <label htmlFor=""
                                    style={{
                                        color: "#202020",
                                        fontSize: "0.8rem",
                                        fontWeight: "bold"
                                    }}
                                >Bet Amount: </label>
                                <input type="text" name='betAmount' onChange={handleForm} value={form.betAmount}
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-center mb-3 flex-column">
                                <label htmlFor=""
                                    style={{
                                        color: "#202020",
                                        fontSize: "0.8rem",
                                        fontWeight: "bold"
                                    }}
                                >Bet Odds:</label>
                                <input type="text" name='betOdds1' onChange={handleForm} value={form.betOdds[0] ? form.betOdds[0].price : ""} />
                            </div>
                            <div className="d-flex align-items-center justify-content-center mb-3 flex-column">
                                <button onClick={calculateToWin}
                                >
                                    Calculate To Win
                                </button>
                            </div>
                            <div>
                                <h3
                                    className="text-center mb-3 text-white text-uppercase font-weight-bold"
                                >To Win: {toWin ? `$${toWin}` : ""}</h3>
                            </div>
                        </div>
                        :
                        <>
                            <div id='typeparlay'>
                                <div className="d-flex align-items-center justify-content-center mb-3 flex-column">
                                    <label htmlFor="">Bet Amount</label>
                                    <input type="text" name='betAmount' onChange={handleForm} value={form.betAmount} />
                                </div>
                                {
                                    [...Array(indexOdds)].map((item, index) => (
                                        <div className="d-flex align-items-center justify-content-center mb-3 flex-column">
                                            <label htmlFor="">Bet Odds</label>
                                            <input type="text" name={`betOdds${index + 1}`} id={index} onChange={handleForm} value={form.betOdds[index] ? form.betOdds[index].price : ""} />
                                        </div>
                                    ))
                                }

                            </div>
                            <div className="d-flex align-items-center justify-content-center mb-3 flex-column">
                                <button onClick={addnewBet}
                                >
                                    Add Bet
                                </button>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mb-3 flex-column">
                                <button onClick={calculateToWin}
                                >
                                    Calculate To Win
                                </button>
                            </div>
                            <div>
                                <h3
                                    className="text-center mb-3 text-white text-uppercase font-weight-bold"
                                >To Win: {toWin ? `$${toWin}` : ""}</h3>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default PLayCalculator