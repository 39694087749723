import Swal from "sweetalert2";
import { create } from "zustand";
import { playTypesHeadDictionary } from "../../utils/playTypes";
import { useGames } from "./useGames";
import { matchupLeagues } from "../../utils/matchUpLeagues";
import { redApi } from "../../config/Axios";
// {
//     "amount": 20,
//     "bets": [
//         {
//             "teamCode": 0,
//             "playType": "M",
//             "game": "65ef2215e9908cc1dd6e1656",
//             "line": "65ef2255e9908cc1dd6e19c1",
//             "isMatchups": false
//         },
//         {
//             "teamCode": 0,
//             "playType": "M",
//             "game": "65ef2214e9908cc1dd6e1617",
//             "line": "65ef2255e9908cc1dd6e197a",
//             "isMatchups": false
//         }
//     ],
//     "totalToWin": 104,
//     "betsIf": 0,
//     "toWinIf": 0,
//     "amountIf": 0
// }
function ReadPlayType(playType) {
  const dictionary = {
    M: 'EVEN',
    MH: 'EVEN H1',
    MT1: 'EVEN P1',
    MT2: 'EVEN P2',
    MT3: 'EVEN P3',
    R: 'R/L',
    H: 'R/L H1',
    Q1: 'R/L Q1',
    Q2: 'R/L Q2',
    Q3: 'R/L Q3',
    Q4: 'R/L Q4',
    T1: 'R/L P1',
    T2: 'R/L P2',
    T3: 'R/L P3',
    HR2: 'R/L H2',
    S: "Solo",
    "S+": "Solo +",
    '+': 'A Mas',
    'H+': 'A Mas H',
    'Q1+': 'A Mas Q1',
    'Q2+': 'A Mas Q2',
    'Q3+': 'A Mas Q3',
    'Q4+': 'A Mas Q4',
    'T1+': 'A Mas P1',
    'T2+': 'A Mas P2',
    'T3+': 'A Mas P3',
    'H2+': 'A Mas H2',
    '-': 'A Menos',
    'H-': 'A Menos H',
    'Q1-': 'A Menos Q1',
    'Q2-': 'A Menos Q2',
    'Q3-': 'A Menos Q3',
    'Q4-': 'A Menos Q4',
    'T1-': 'A Menos P1',
    'T2-': 'A Menos P2',
    'T3-': 'A Menos P3',
    'H2-': 'A Menos H2',
    'S+': 'Solo +',
    'H1S+': 'Solo +H',
    'Q1S+': 'Solo +Q1',
    'Q2S+': 'Solo +Q2',
    'Q3S+': 'Solo +Q3',
    'Q4S+': 'Solo +Q4',
    'H2S+': 'Solo +H2',
    'S-': 'Solo -',
    'H1S-': 'Solo -H',
    'Q1S-': 'Solo -Q1',
    'Q2S-': 'Solo -Q2',
    'Q3S-': 'Solo -Q3',
    'Q4S-': 'Solo -Q4',
    'H2S-': 'Solo -H2',
    P: 'SR/L',
    Y: 'Si',
    N: 'No',
    'K+': 'K+',
    'K-': 'K-',
    F: '1era',
    C: 'Compra',
    V: 'Venta',
    'C+': 'C A Mas',
    'C-': 'C A Menos',
    J1: 'J1',
    J2: 'J2',
    J3: 'J3',
    J4: 'J4',
    J5: 'J5',
    SE: 'Serie',
    AR: 'AR/L',
    T: 'Tercio',
    'T+': 'Tercio Mas',
    'T-': 'Tercio Menos',
    'V+': 'V A Mas',
    'V-': 'V A Menos',
    E: 'Empate',
  }
  return dictionary[playType]
}
const playTypes = [
  {
    type: 'R', //== RunLine: Al equipo que gane por diferencia de puntos
    disabled: ['M', 'S+', 'S-', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T', 'MH'],
  },
  {
    type: 'M', //== MoneyLine: Al equipo que gane
    disabled: ['R', 'S+', 'S-', 'M', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T', 'MH'],
  },
  {
    type: '+', //== P+: Si el puntaje de ambos equipos es más que el spread
    disabled: ['S+', 'S-', '+', '-'],
  },
  {
    type: '-', //== P-: Si el puntaje de ambos equipos es menos que el spread
    disabled: ['S+', 'S-', '-', '+'],
  },
  {
    type: 'S+', //== Solo+: Si el puntaje del equipo es mas que el spread
    disabled: ['M', 'R', '+', '-', 'S+'],
  },
  {
    type: 'S-', //== Solo-: Si el puntaje del equipo es menos que el spread
    disabled: ['M', 'R', '+', '-', 'S-'],
  },
  {
    type: 'MH', //== MoneyLineHalf: Al equipo lleve la delantera en el half
    disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T', 'S+', 'S-', 'MH'],
  },
  {
    type: 'H', //== RunLine Half
    disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
  },
  {
    type: 'Y', //== Si anotan en el primer inning
    disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
  },
  {
    type: 'N', //== Si no anota en el primer inning
    disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
  },
  {
    type: 'AR', //== Alternative RunLine
    disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
  },
  {
    type: 'P', //== Super RunLine
    disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
  },
  {
    type: 'F', // El equipo que anote primero
    disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
  },
  {
    type: 'K+', // Al equipo que haga mas ponches
    disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
  },
  {
    type: 'K-', // Al equipo que haga menos ponches
    disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
  },
  {
    type: 'T', //== Tercio: Al equipo que lleve la delantera en el primer tercio
    disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T'],
  },
  {
    type: 'E', //== Draw: Si los equipos empatan
    disabled: ['M', 'R', 'Y', 'H', 'N', 'AR', 'P', 'F', 'K+', 'K-', 'T', 'MH'],
  },
  {
    type: 'Q1', //== Q1: Al equipo que lleve la delantera en el primer cuarto
    disabled: [],
  },
  {
    type: 'Q2',
    disabled: [],
  },
  {
    type: 'Q3',
    disabled: [],
  },
  {
    type: 'Q4',
    disabled: [],
  },
  {
    type: 'Q1+',
    disabled: [],
  },
  {
    type: 'Q2+',
    disabled: [],
  },
  {
    type: 'Q3+',
    disabled: [],
  },
  {
    type: 'Q4+',
    disabled: [],
  },
  {
    type: 'Q1-',
    disabled: [],
  },
  {
    type: 'Q2-',
    disabled: [],
  },
  {
    type: 'Q3-',
    disabled: [],
  },
  {
    type: 'Q4-',
    disabled: [],
  },
  {
    type: 'QS1+',
    disabled: [],
  },
  {
    type: 'QS2+',
    disabled: [],
  },
  {
    type: 'QS3+',
    disabled: [],
  },
  {
    type: 'QS4+',
    disabled: [],
  },
  {
    type: 'QS1-',
    disabled: [],
  },
  {
    type: 'QS2-',
    disabled: [],
  },
  {
    type: 'QS3-',
    disabled: [],
  },
  {
    type: 'QS4-',
    disabled: [],
  },
  {
    type: 'T1',
    disabled: [],
  },
  {
    type: 'T2',
    disabled: [],
  },
  {
    type: 'T3',
    disabled: [],
  },
  {
    type: 'T1+',
    disabled: [],
  },
  {
    type: 'T2+',
    disabled: [],
  },
  {
    type: 'T3+',
    disabled: [],
  },
  {
    type: 'T1-',
    disabled: [],
  },
  {
    type: 'T2-',
    disabled: [],
  },
  {
    type: 'T3-',
    disabled: [],
  },
  {
    type: 'MT1',
    disabled: [],
  },
  {
    type: 'MT2',
    disabled: [],
  },
  {
    type: 'MT3',
    disabled: [],
  },
  {
    type: 'J2',
    disabled: [],
  },
  {
    type: 'J3',
    disabled: [],
  },
  {
    type: 'J4',
    disabled: [],
  },
  {
    type: 'J5',
    disabled: [],
  },
]
const validateBets = (
  bets,
  // restrictions,
  betToAdd,
  game,
  isMatchup) => {
  let valid = true;
  let message = '';
  let localTeam = isMatchup ? game.player?.codeRD : game.localTeam?.codeRD == 0 ? game.localTeam.code : game.localTeam?.codeRD;
  let visitingTeam = isMatchup ? null : game.visitingTeam?.codeRD == 0 ? game.visitingTeam.code : game.visitingTeam?.codeRD;
  let betType = betToAdd.playType;
  let betTeam = Number(betToAdd.teamCode);
  let playType = playTypes.find(playType => playType.type === betType);

  if (isMatchup) {
    if (localTeam == betTeam) {
      bets.forEach(bet => {
        if (bet.teamCode == visitingTeam) {
          if (playType.disabled.includes(bet.playType)) {
            valid = false;
            message = `No se puede jugar ${betType} al equipo local porque ya se jugó ${bet.playType} al equipo visitante`;
          }
        } else if (bet.teamCode == localTeam) {
          if (playType.disabled.includes(bet.playType)) {
            valid = false;
            message = `No se puede jugar ${betType} al equipo local porque ya se jugó ${bet.playType} al equipo local`;
          }
        }
      });
    }
    return { valid, message };

  }
  ///Combination Restrictions
  if (bets.length > 0) {
    const typeBetAdd = ReadPlayType(betToAdd.playType)
    // restrictions.map(rectriction => {
    //   if (rectriction.league.name === game.league.name) {
    //     const resctriction1 = rectriction.playType1.includes(typeBetAdd)
    //     const resctriction2 = rectriction.playType2.includes(typeBetAdd)
    //     if (resctriction1 || resctriction2) {
    //       bets.map(bet => {
    //         if (game._id == bet.game) {
    //           let typePlayBet = ReadPlayType(bet.playType)
    //           const SearchPlayType = resctriction1 ? rectriction.playType2 : rectriction.playType1
    //           if (SearchPlayType.includes(typePlayBet)) {
    //             valid = false
    //             message = `El tipo de jugada ${betToAdd.playType} no puede combinarse con el tipo de jugada ${bet.playType}`;
    //           }
    //         }
    //       })
    //     }
    //   }
    // })
  }

  if (localTeam == betTeam) {
    bets.forEach(bet => {
      if (bet.teamCode == visitingTeam) {
        if (betType == bet.playType) {
          valid = false;
          message = `No se puede jugar ${betType} al equipo local porque ya se jugó ${bet.playType} al equipo visitante`;
        }
      } else if (bet.teamCode == localTeam) {
        if (betType == bet.playType) {
          valid = false;
          message = `No puedes repetir jugadas al mismo equipo`;
        }
      } if ((betType == '-' && bet.playType == '+') || (betType == '+' && bet.playType == '-')) {
        valid = false;
        message = `No puedes jugar + y - en el mismo juego`;
      }
    });
  } else if (visitingTeam == betTeam) {
    bets.forEach(bet => {
      if (bet.teamCode == localTeam) {
        if (betType == bet.playType) {
          valid = false;
          message = `No se puede jugar ${betType} al equipo visitante porque ya se jugó ${bet.playType} al equipo local`;
        }
      } else if (bet.teamCode == visitingTeam) {
        if (betType == bet.playType) {
          valid = false;
          message = `No puedes repetir jugadas al mismo equipo`;
        }
      } if ((betType == '-' && bet.playType == '+') || (betType == '+' && bet.playType == '-')) {
        valid = false;
        message = `No puedes jugar + y - en el mismo juego`;
      }
    });
  }
  return { valid, message };
}
const returnplayType = (head, isLocal) => {
  let playType;
  if (head.property === "altGavela") {
    playType = playTypesHeadDictionary[head.property];
  } else {
    playType = playTypesHeadDictionary[head.name];
  }
  if (playType) {
    if (playType === "P") {
      return isLocal ? "+" : "-"
    }
    if (playType === "H2") {
      return isLocal ? "H+" : "H-"
    }
    return playType;
  };
  return name;
}

// const handleBetAddToTable = async (e) => {
//   if (
//     e.target.name === "playType" &&
//     e.key === "Enter" &&
//     betToAdd.teamCode !== ""
//   ) {
//     let game = gamesForUser.find(
//       (game) => {
//         if (matchupLeagues.includes(game.league.name)) {
//           return game.player.code == betToAdd.teamCode
//         } else {
//           return game.localTeam.code == betToAdd.teamCode || game.visitingTeam.code == betToAdd.teamCode
//         }
//       }
//     );

//     if (!game) game = gamesForUser.find(
//       (game) => {
//         if (matchupLeagues.includes(game.league.name)) {
//           return game.player.code == betToAdd.teamCode
//         } else {
//           return game.localTeam.code == betToAdd.teamCode || game.visitingTeam.code == betToAdd.teamCode
//         }
//       }
//     );

//     if (game) {
//       const isMatchUp = matchupLeagues.includes(game.league.name);
//       const localLine = game.localLine;
//       const visitingLine = game.visitingLine;
//       let line;
//       if (isMatchUp) {
//         line = game.playerLine;
//       } else {
//         line = betToAdd.teamCode == game.localTeam.codeRD || betToAdd.teamCode == game.localTeam.code ? localLine : visitingLine;
//       }
//       // console.log(betToAdd.teamCode == game.localTeam.codeRD || betToAdd.teamCode == game.localTeam.code ? localLine : visitingLine)

//       let lineToCheck;
//       let price;
//       let spreadChanged = false
//       function returnLine(lineR) {
//         if (line[lineR] === null) return null
//         else if (['j1', 'j2', 'j3', 'j4', 'j5',].includes(lineR) && line[lineR] !== null) {
//           lineToCheck = line[lineR].moneyLine
//           return line[lineR].moneyLine.value
//         }
//         lineToCheck = line[lineR]
//         return line[lineR]?.value
//       }
//       if (betToAdd.playType == '') {
//         betToAdd.playType = 'R'
//       }
//       switch (betToAdd.playType) {
//         case "M":
//           price = returnLine('moneyLine')
//           break;
//         case "R":
//           price = returnLine('price')
//           spreadChanged = true
//           betToAdd.Spread = line.gavela.value
//           break;
//         case "-":
//           price = returnLine('posMinus')
//           spreadChanged = true
//           if (isMatchUp) {
//             betToAdd.Spread = game.playerLine?.total?.value
//           } else {
//             betToAdd.Spread = visitingLine?.total.value
//           }
//           break;
//         case "+":
//           price = returnLine('posPlus')
//           spreadChanged = true
//           if (isMatchUp) {
//             betToAdd.Spread = game.playerLine?.total?.value
//           } else {
//             betToAdd.Spread = localLine?.total.value
//           }
//           break;
//         case "S+":
//           price = returnLine('singlePlus')
//           spreadChanged = true
//           betToAdd.Spread = line.single.value
//           break;
//         case "S-":
//           price = returnLine('singleMinus')
//           spreadChanged = true
//           betToAdd.Spread = line.single.value
//           break;
//         case "P":
//           price = returnLine('superRunLine')
//           spreadChanged = true
//           betToAdd.Spread = line.superGavela.value
//           break;
//         case "AR":
//           price = returnLine('altRunLine')
//           spreadChanged = true
//           betToAdd.Spread = line.altGavela.value
//           break;
//         case "H":
//           price = returnLine('h1Rl')
//           spreadChanged = true
//           betToAdd.Spread = line.h1RlGavela.value
//           break;
//         case "MH":
//           price = returnLine('h1')
//           break;
//         case "Y":
//           price = returnLine('sia')
//           break;
//         case "N":
//           price = returnLine('noa')
//           break;
//         case "F":
//           price = returnLine('firstRun')
//           break;
//         case "K+":
//           price = returnLine('strikeoutsPlus')
//           spreadChanged = true
//           betToAdd.Spread = line.strikeoutsGavela.value
//           break;
//         case "K-":
//           price = returnLine('strikeoutsMinus')
//           spreadChanged = true
//           betToAdd.Spread = line.strikeoutsGavela.value
//           break;
//         case "T":
//           price = returnLine('third')
//           break;
//         case "T+":
//           price = returnLine('moneyLineT3')
//           break;
//         case "T-":
//           price = returnLine('moneyLineT3')
//           break;
//         case "E":
//           price = returnLine('draw')
//           break;
//         case "Q1":
//           price = returnLine('RunLineQ1')
//           spreadChanged = true
//           betToAdd.Spread = line.RunLineQ1Gavela.value
//           break;
//         case "Q2":
//           price = returnLine('RunLineQ2')
//           spreadChanged = true
//           betToAdd.Spread = line.RunLineQ2Gavela.value
//           break;
//         case "Q3":
//           price = returnLine('RunLineQ3')
//           spreadChanged = true
//           betToAdd.Spread = line.RunLineQ3Gavela.value
//           break;
//         case "Q4":
//           price = returnLine('RunLineQ4')
//           spreadChanged = true
//           betToAdd.Spread = line.RunLineQ4Gavela.value
//           break;
//         case "Q1+":
//           price = returnLine('priceQ1Over')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ1Over.value
//           break;
//         case "Q2+":
//           price = returnLine('priceQ2Over')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ2Over.value
//           break;
//         case "Q3+":
//           price = returnLine('priceQ3Over')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ3Over.value
//           break;
//         case "Q4+":
//           price = returnLine('priceQ4Over')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ4Over.value
//           break;
//         case "Q1-":
//           price = returnLine('priceQ1Under')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ1Under.value
//           break;
//         case "Q2-":
//           price = returnLine('priceQ2Under')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ2Under.value
//           break;
//         case "Q3-":
//           price = returnLine('priceQ3Under')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ3Under.value
//           break;
//         case "Q4-":
//           price = returnLine('priceQ4Under')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ4Under.value
//           break;
//         case "Q1S+":
//           price = returnLine('priceQ1SOver')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ1S.value
//           break;
//         case "Q2S+":
//           price = returnLine('priceQ2SOver')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ2S.value
//           break;
//         case "Q3S+":
//           price = returnLine('priceQ3SOver')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ3S.value
//           break;
//         case "Q4S+":
//           price = returnLine('priceQ4SOver')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ4S.value
//           break;
//         case "Q1S-":
//           price = returnLine('priceQ1SUnder')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ1S.value
//           break;
//         case "Q2S-":
//           price = returnLine('priceQ2SUnder')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ2S.value
//           break;
//         case "Q3S-":
//           price = returnLine('priceQ3SUnder')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ3S.value
//           break;
//         case "Q4S-":
//           price = returnLine('priceQ4SUnder')
//           spreadChanged = true
//           betToAdd.Spread = line.totalQ4S.value
//           break;
//         case "T1":
//           price = returnLine('runLineT1')
//           spreadChanged = true
//           betToAdd.Spread = line.RLT1Gavela.value
//           break;
//         case "T2":
//           price = returnLine('runLineT2')
//           spreadChanged = true
//           betToAdd.Spread = line.RLT2Gavela.value
//           break;
//         case "T3":
//           price = returnLine('runLineT3')
//           spreadChanged = true
//           betToAdd.Spread = line.RLT3Gavela.value
//           break;
//         case "T1+":
//           price = returnLine('priceT1Over')
//           spreadChanged = true
//           betToAdd.Spread = line.totalT1.value
//           break;
//         case "T2+":
//           price = returnLine('priceT2Over')
//           spreadChanged = true
//           betToAdd.Spread = line.totalT2.value
//           break;
//         case "T3+":
//           price = returnLine('priceT3Over')
//           spreadChanged = true
//           betToAdd.Spread = line.totalT2.value
//           break;
//         case "T1-":
//           price = returnLine('priceT1Under')
//           spreadChanged = true
//           betToAdd.Spread = line.totalT1.value
//           break;
//         case "T2-":
//           price = returnLine('priceT2Under')
//           spreadChanged = true
//           betToAdd.Spread = line.totalT2.value
//           break;
//         case "T3-":
//           price = returnLine('priceT3Under')
//           spreadChanged = true
//           betToAdd.Spread = line.totalT2.value
//           break;
//         case "MT1":
//           price = returnLine('moneyLineT1')
//           break;
//         case "MT2":
//           price = returnLine('moneyLineT2')
//           break;
//         case "MT3":
//           price = returnLine('moneyLineT3')
//           break;
//         case "HR2":
//           price = returnLine('moneyLineT3')
//           break;
//         case "H+":
//           spreadChanged = true
//           betToAdd.Spread = line.HGavela.value
//           price = returnLine('HOver')
//           break;
//         case "H-":
//           spreadChanged = true
//           betToAdd.Spread = line.HGavela.value
//           price = returnLine('HUnder')
//           break;
//         case "H2+":
//           spreadChanged = true
//           betToAdd.Spread = line.H2Gavela.value
//           price = returnLine('H2Over')
//           break;
//         case "H2-":
//           spreadChanged = true
//           betToAdd.Spread = line.H2Gavela.value
//           price = returnLine('H2Over')
//           break;
//         case "H1S+":
//           spreadChanged = true
//           betToAdd.Spread = line.HSGavela.value
//           price = returnLine('HSOver')
//           break;
//         case "H1S-":
//           spreadChanged = true
//           betToAdd.Spread = line.HSGavela.value
//           price = returnLine('HSUnder')
//           break;
//         case "H2S+":
//           spreadChanged = true
//           betToAdd.Spread = line.H2SGavela.value
//           price = returnLine('H2SOver')
//           break;
//         case "H2S-":
//           spreadChanged = true
//           betToAdd.Spread = line.H2SGavela.value
//           price = returnLine('H2SUnder')
//           break;
//         case "J1":
//           price = returnLine('j1')
//           if (price === undefined || price === null) {
//             return await Swal.fire({
//               title: "Error",
//               text: 'Esta línea no está disponible',
//               icon: "error",
//               timer: 3000,
//               showConfirmButton: false,
//             });
//           }
//           break;
//         case "J2":
//           price = returnLine('j2')
//           if (price === undefined || price === null) {
//             return await Swal.fire({
//               title: "Error",
//               text: 'Esta línea no está disponible',
//               icon: "error",
//               timer: 3000,
//               showConfirmButton: false,
//             });
//           }
//           break;
//         case "J3":
//           price = returnLine('j3')
//           if (price === undefined || price === null) {
//             return await Swal.fire({
//               title: "Error",
//               text: 'Esta línea no está disponible',
//               icon: "error",
//               timer: 3000,
//               showConfirmButton: false,
//             });
//           }
//           break;
//         case "J4":
//           price = returnLine('j4')
//           if (price === undefined || price === null) {
//             return await Swal.fire({
//               title: "Error",
//               text: 'Esta línea no está disponible',
//               icon: "error",
//               timer: 3000,
//               showConfirmButton: false,
//             });
//           }
//           break;
//         case "J5":
//           price = returnLine('j5')
//           if (price === undefined || price === null) {
//             return await Swal.fire({
//               title: "Error",
//               text: 'Esta línea no está disponible',
//               icon: "error",
//               timer: 3000,
//               showConfirmButton: false,
//             });
//           }
//           break;
//         case "C":
//           setModalBuyPoints(true)
//           return
//         case "C+":
//           setModalBuyPoints(true)
//           return
//         case "C-":
//           setModalBuyPoints(true)
//           return
//         case "":
//           if (game?.league?.name === 'MLB') {
//             price = returnLine('moneyLine')
//           } else {
//             price = returnLine('runLine')
//           }
//           break;
//         default:
//           Swal.fire({
//             title: "Error",
//             text: 'Este tipo de jugada no existe',
//             icon: "error",
//             timer: 3000,
//             showConfirmButton: false,
//           });
//           return;
//       }

//       if (lineToCheck && lineToCheck.status !== "approved") {
//         Swal.fire({
//           title: "Error",
//           text: 'Esta línea no está aprobada',
//           icon: "error",
//           timer: 3000,
//           showConfirmButton: false,
//         });
//         return;
//       }

//       if (spreadChanged == true && betToAdd.Spread == "") {
//         Swal.fire({
//           title: "Error",
//           text: 'Este línea requiere un spread',
//           icon: "error",
//           timer: 3000,
//           showConfirmButton: false,
//         });
//         return;
//       }

//       const { valid, message } = validateBets(bets.bets, betToAdd, game, game.player ? true : false);



//       if (!valid) {
//         Swal.fire({
//           title: "Error",
//           text: message,
//           icon: "error",
//           timer: 3000,
//           showConfirmButton: false,
//         });
//         return;
//       }

//       console.log("PRICEEEEE", price)
//       if (!price || price === "" || price === "~" || price === "0") {
//         Swal.fire({
//           title: "Error",
//           text: `La apuesta ${betToAdd.playType} no tiene precio`,
//           icon: "error",
//           timer: 3000,
//           showConfirmButton: false,
//         });
//         return;
//       }
//       if (price === "Bet not found") {
//         Swal.fire({
//           title: "Error",
//           text: "Por el momento no se puede apostar a este juego 1",
//           icon: "error",
//           timer: 3000,
//           showConfirmButton: false,
//         });
//         return;
//       }

//       if (bets.bets.length > 0) {
//         await redApi.get('/verify').then(res => {
//           let multPlays = res.data.multPlays;
//           if (multPlays) {
//             bets.bets.forEach(bet => {
//               if (bet.Price === '-110' || bet.Price === -110)
//                 bet.Price = multPlays;
//             });
//             if (price === '-110' || Number(price) === -110) {
//               price = multPlays;
//             }
//           }
//         }).catch(err => {
//           console.log(err);
//         })
//       }

//       let TeamName;
//       if (isMatchUp) {
//         TeamName = game.player.name;
//       } else {
//         TeamName = ['+', '-', 'H+', 'H-', 'H2+', 'H2-', 'H1+', 'H1-'].includes(betToAdd.playType) ? `${game.localTeam.name} vs ${game.visitingTeam.name}` : betToAdd.teamCode == game.localTeam.code || betToAdd.teamCode == game.localTeam.codeRD ? game.localTeam.name : game.visitingTeam.name;
//       }
//       console.log(game.league.name)
//       const newBet = {
//         teamCode: betToAdd.teamCode,
//         teamCodeRD: matchupLeagues.includes(game.league.name) ? game.player.codeRD : betToAdd.teamCode == game.localTeam.code || betToAdd.teamCode == game.localTeam.codeRD ? game.localTeam.codeRD : game.visitingTeam.codeRD,
//         playType: betToAdd.playType === "" ? "M" : betToAdd.playType,
//         Spread: betToAdd.Spread,
//         Price: price,
//         TeamName,
//         line: line._id,
//         game: game._id,
//       };
//       console.log("NEW BET", newBet);
//       setBets({
//         ammount: parseInt(bets.ammount) + parseInt(betToAdd.Quantity),
//         bets: [...bets.bets, newBet],
//       });

//       setBetToAdd({
//         teamCode: "",
//         playType: "",
//         Quantity: 0,
//         IfWin: 0,
//       });
//       //******************************************************************************* */
//       let filasRows = document.querySelectorAll('.tr_table_bets');
//       // Elimina 'bet-selected' de todas las filas excepto de la última
//       filasRows.forEach((fila, index) => {
//         if (index !== filasRows.length - 1) {
//           fila.classList.remove('bet-selected');
//         }
//       });
//       //******************************************************************************** */
//       // document.getElementById('quantityInput').focus();
//       document.querySelector("#teamCode").focus();
//     } else {
//       console.log("no game found");
//     }
//   }
// };
export const useTickets = create((set, get) => ({
  // bets store:
  ticket: {
    amount: 0,
    totalToWin: 0,
    betsIf: 0,
    toWinIf: 0,
    amountIf: 0,
    bets: [],
  },
  betToAdd: {
    teamCode: "",
    playType: "",
    game: "",
    line: "",
    isMatchups: false
  },
  leagueSelected: null,
  handleLeagueSelected: (league) => set({ leagueSelected: league }),
  handleChangeBetToAdd: (field, value) => set((state) => ({ betToAdd: { ...state.betToAdd, [field]: value } })),
  
  handleAddBet: async () => {
    try {
      const { gamesToday } = useGames.getState()
      let bets = get().ticket
      let betToAdd = get().betToAdd
      let leagueSelected = get().leagueSelected
      const isMatchUp = matchupLeagues.includes(leagueSelected.name);
      set({ betToAdd: { ...betToAdd, isMatchups: isMatchUp } })
      
      let game = gamesToday.find((game) => {
        if (isMatchUp) {
          return game.player.code == betToAdd.teamCode
        } else {
          // return game.localTeam.code == betToAdd.teamCode || game.visitingTeam.code == betToAdd.teamCode

      return game.localTeam.codeRD == parseInt( betToAdd.teamCode)
      || game.visitingTeam.codeRD == parseInt(betToAdd.teamCode)
      || game.localTeam.code == parseInt(betToAdd.teamCode)
      || game.visitingTeam.code == parseInt(betToAdd.teamCode)
        }
      });

      if (!game) game = gamesToday.find((game) => {
        if (isMatchUp) {
          return game.player.code == betToAdd.teamCode
        } else {
      

          // return game.localTeam.code == betToAdd.teamCode || game.visitingTeam.code == betToAdd.teamCode
          return game.localTeam.codeRD == parseInt(betToAdd.teamCode)
              || game.visitingTeam.codeRD == parseInt(betToAdd.teamCode)
              || game.localTeam.code == parseInt(betToAdd.teamCode)
              || game.visitingTeam.code == parseInt(betToAdd.teamCode)
        }
      });

      if (game) {
        const localLine = game.localLine;
        const visitingLine = game.visitingLine;
        let line;
        if (isMatchUp) {
          line = game.playerLine;
        } else {
          line = betToAdd.teamCode == game.localTeam.codeRD || betToAdd.teamCode == game.localTeam.code ? localLine : visitingLine;
        }
        let lineToCheck;
        let price;
        let spreadChanged = false
        function returnLine(lineR) {
          if (line[lineR] === null) return null
          else if (['j1', 'j2', 'j3', 'j4', 'j5',].includes(lineR) && line[lineR] !== null) {
            lineToCheck = line[lineR].moneyLine
            return line[lineR].moneyLine.value
          }
          lineToCheck = line[lineR]
          return line[lineR]?.value
        }
        // if (betToAdd.playType == '') {
        //   betToAdd.playType = 'R'
        // }
        switch (betToAdd.playType) {
          case "M":
            price = returnLine('moneyLine')
            break;
          case "R":
            price = returnLine('price')
            spreadChanged = true
            betToAdd.Spread = line.gavela.value
            break;
          case "-":
            price = returnLine('posMinus')
            spreadChanged = true
            if (isMatchUp) {
              betToAdd.Spread = game.playerLine?.total?.value
            } else {
              betToAdd.Spread = visitingLine?.total.value
            }
            break;
          case "+":
            price = returnLine('posPlus')
            spreadChanged = true
            if (isMatchUp) {
              betToAdd.Spread = game.playerLine?.total?.value
            } else {
              betToAdd.Spread = localLine?.total.value
            }
            break;
          case "S+":
            price = returnLine('singlePlus')
            spreadChanged = true
            betToAdd.Spread = line.single.value
            break;
          case "S-":
            price = returnLine('singleMinus')
            spreadChanged = true
            betToAdd.Spread = line.single.value
            break;
          case "P":
            price = returnLine('superRunLine')
            spreadChanged = true
            betToAdd.Spread = line.superGavela.value
            break;
          case "AR":
            price = returnLine('altRunLine')
            spreadChanged = true
            betToAdd.Spread = line.altGavela.value
            break;
          case "H":
            price = returnLine('h1Rl')
            spreadChanged = true
            betToAdd.Spread = line.h1RlGavela.value
            break;
          case "MH":
            price = returnLine('h1')
            break;
          case "Y":
            price = returnLine('sia')
            break;
          case "N":
            price = returnLine('noa')
            break;
          case "F":
            price = returnLine('firstRun')
            break;
          case "K+":
            price = returnLine('strikeoutsPlus')
            spreadChanged = true
            betToAdd.Spread = line.strikeoutsGavela.value
            break;
          case "K-":
            price = returnLine('strikeoutsMinus')
            spreadChanged = true
            betToAdd.Spread = line.strikeoutsGavela.value
            break;
          case "T":
            price = returnLine('third')
            break;
          case "T+":
            price = returnLine('moneyLineT3')
            break;
          case "T-":
            price = returnLine('moneyLineT3')
            break;
          case "E":
            price = returnLine('draw')
            break;
          case "Q1":
            price = returnLine('RunLineQ1')
            spreadChanged = true
            betToAdd.Spread = line.RunLineQ1Gavela.value
            break;
          case "Q2":
            price = returnLine('RunLineQ2')
            spreadChanged = true
            betToAdd.Spread = line.RunLineQ2Gavela.value
            break;
          case "Q3":
            price = returnLine('RunLineQ3')
            spreadChanged = true
            betToAdd.Spread = line.RunLineQ3Gavela.value
            break;
          case "Q4":
            price = returnLine('RunLineQ4')
            spreadChanged = true
            betToAdd.Spread = line.RunLineQ4Gavela.value
            break;
          case "Q1+":
            price = returnLine('priceQ1Over')
            spreadChanged = true
            betToAdd.Spread = line.totalQ1Over.value
            break;
          case "Q2+":
            price = returnLine('priceQ2Over')
            spreadChanged = true
            betToAdd.Spread = line.totalQ2Over.value
            break;
          case "Q3+":
            price = returnLine('priceQ3Over')
            spreadChanged = true
            betToAdd.Spread = line.totalQ3Over.value
            break;
          case "Q4+":
            price = returnLine('priceQ4Over')
            spreadChanged = true
            betToAdd.Spread = line.totalQ4Over.value
            break;
          case "Q1-":
            price = returnLine('priceQ1Under')
            spreadChanged = true
            betToAdd.Spread = line.totalQ1Under.value
            break;
          case "Q2-":
            price = returnLine('priceQ2Under')
            spreadChanged = true
            betToAdd.Spread = line.totalQ2Under.value
            break;
          case "Q3-":
            price = returnLine('priceQ3Under')
            spreadChanged = true
            betToAdd.Spread = line.totalQ3Under.value
            break;
          case "Q4-":
            price = returnLine('priceQ4Under')
            spreadChanged = true
            betToAdd.Spread = line.totalQ4Under.value
            break;
          case "Q1S+":
            price = returnLine('priceQ1SOver')
            spreadChanged = true
            betToAdd.Spread = line.totalQ1S.value
            break;
          case "Q2S+":
            price = returnLine('priceQ2SOver')
            spreadChanged = true
            betToAdd.Spread = line.totalQ2S.value
            break;
          case "Q3S+":
            price = returnLine('priceQ3SOver')
            spreadChanged = true
            betToAdd.Spread = line.totalQ3S.value
            break;
          case "Q4S+":
            price = returnLine('priceQ4SOver')
            spreadChanged = true
            betToAdd.Spread = line.totalQ4S.value
            break;
          case "Q1S-":
            price = returnLine('priceQ1SUnder')
            spreadChanged = true
            betToAdd.Spread = line.totalQ1S.value
            break;
          case "Q2S-":
            price = returnLine('priceQ2SUnder')
            spreadChanged = true
            betToAdd.Spread = line.totalQ2S.value
            break;
          case "Q3S-":
            price = returnLine('priceQ3SUnder')
            spreadChanged = true
            betToAdd.Spread = line.totalQ3S.value
            break;
          case "Q4S-":
            price = returnLine('priceQ4SUnder')
            spreadChanged = true
            betToAdd.Spread = line.totalQ4S.value
            break;
          case "T1":
            price = returnLine('runLineT1')
            spreadChanged = true
            betToAdd.Spread = line.RLT1Gavela.value
            break;
          case "T2":
            price = returnLine('runLineT2')
            spreadChanged = true
            betToAdd.Spread = line.RLT2Gavela.value
            break;
          case "T3":
            price = returnLine('runLineT3')
            spreadChanged = true
            betToAdd.Spread = line.RLT3Gavela.value
            break;
          case "T1+":
            price = returnLine('priceT1Over')
            spreadChanged = true
            betToAdd.Spread = line.totalT1.value
            break;
          case "T2+":
            price = returnLine('priceT2Over')
            spreadChanged = true
            betToAdd.Spread = line.totalT2.value
            break;
          case "T3+":
            price = returnLine('priceT3Over')
            spreadChanged = true
            betToAdd.Spread = line.totalT2.value
            break;
          case "T1-":
            price = returnLine('priceT1Under')
            spreadChanged = true
            betToAdd.Spread = line.totalT1.value
            break;
          case "T2-":
            price = returnLine('priceT2Under')
            spreadChanged = true
            betToAdd.Spread = line.totalT2.value
            break;
          case "T3-":
            price = returnLine('priceT3Under')
            spreadChanged = true
            betToAdd.Spread = line.totalT2.value
            break;
          case "MT1":
            price = returnLine('moneyLineT1')
            break;
          case "MT2":
            price = returnLine('moneyLineT2')
            break;
          case "MT3":
            price = returnLine('moneyLineT3')
            break;
          case "HR2":
            price = returnLine('moneyLineT3')
            break;
          case "H+":
            spreadChanged = true
            betToAdd.Spread = line.HGavela.value
            price = returnLine('HOver')
            break;
          case "H-":
            spreadChanged = true
            betToAdd.Spread = line.HGavela.value
            price = returnLine('HUnder')
            break;
          case "H2+":
            spreadChanged = true
            betToAdd.Spread = line.H2Gavela.value
            price = returnLine('H2Over')
            break;
          case "H2-":
            spreadChanged = true
            betToAdd.Spread = line.H2Gavela.value
            price = returnLine('H2Over')
            break;
          case "H1S+":
            spreadChanged = true
            betToAdd.Spread = line.HSGavela.value
            price = returnLine('HSOver')
            break;
          case "H1S-":
            spreadChanged = true
            betToAdd.Spread = line.HSGavela.value
            price = returnLine('HSUnder')
            break;
          case "H2S+":
            spreadChanged = true
            betToAdd.Spread = line.H2SGavela.value
            price = returnLine('H2SOver')
            break;
          case "H2S-":
            spreadChanged = true
            betToAdd.Spread = line.H2SGavela.value
            price = returnLine('H2SUnder')
            break;
          case "J1":
            price = returnLine('j1')
            if (price === undefined || price === null) {
              return await Swal.fire({
                title: "Error",
                text: 'Esta línea no está disponible',
                icon: "error",
                timer: 3000,
                showConfirmButton: false,
              });
            }
            break;
          case "J2":
            price = returnLine('j2')
            if (price === undefined || price === null) {
              return await Swal.fire({
                title: "Error",
                text: 'Esta línea no está disponible',
                icon: "error",
                timer: 3000,
                showConfirmButton: false,
              });
            }
            break;
          case "J3":
            price = returnLine('j3')
            if (price === undefined || price === null) {
              return await Swal.fire({
                title: "Error",
                text: 'Esta línea no está disponible',
                icon: "error",
                timer: 3000,
                showConfirmButton: false,
              });
            }
            break;
          case "J4":
            price = returnLine('j4')
            if (price === undefined || price === null) {
              return await Swal.fire({
                title: "Error",
                text: 'Esta línea no está disponible',
                icon: "error",
                timer: 3000,
                showConfirmButton: false,
              });
            }
            break;
          case "J5":
            price = returnLine('j5')
            if (price === undefined || price === null) {
              return await Swal.fire({
                title: "Error",
                text: 'Esta línea no está disponible',
                icon: "error",
                timer: 3000,
                showConfirmButton: false,
              });
            }
            break;
          case "C":
            setModalBuyPoints(true)
            return
          case "C+":
            setModalBuyPoints(true)
            return
          case "C-":
            setModalBuyPoints(true)
            return
          case "":
            if (game?.league?.name === 'MLB') {
              price = returnLine('moneyLine')
            } else {
              price = returnLine('runLine')
            }
            break;
          default:
            Swal.fire({
              title: "Error",
              text: 'Este tipo de jugada no existe',
              icon: "error",
              timer: 3000,
              showConfirmButton: false,
            });
            return;
        }


        if (lineToCheck && lineToCheck.status !== "approved") {
          Swal.fire({
            title: "Error",
            text: 'Esta línea no está aprobada',
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
        }

        if (spreadChanged == true && betToAdd.Spread == "") {
          Swal.fire({
            title: "Error",
            text: 'Este línea requiere un spread',
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
        }
        const { valid, message } = validateBets(bets.bets, betToAdd, game, game.player ? true : false);
        if (!valid) {
          Swal.fire({
            title: "Error",
            text: message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
        }
        if (!price || price === "" || price === "~" || price === "0") {
          Swal.fire({
            title: "Error",
            text: `La apuesta ${betToAdd.playType} no tiene precio`,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
        }
        if (price === "Bet not found") {
          Swal.fire({
            title: "Error",
            text: "Por el momento no se puede apostar a este juego 1",
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
          return;
        }
        if (bets.bets.length > 0) {
          await redApi.get('/verify').then(res => {
            let multPlays = res.data.multPlays;
            if (multPlays) {
              bets.bets.forEach(bet => {
                if (bet.price === '-110' || bet.price === -110)
                  bet.price = multPlays;
              });
              if (price === '-110' || Number(price) === -110) {
                price = multPlays;
              }
            }
          }).catch(err => {
            console.log(err);
          })
        }
        let TeamName;
        if (isMatchUp) {
          TeamName = game.player.name;
        } else {
          TeamName = ['+', '-', 'H+', 'H-', 'H2+', 'H2-', 'H1+', 'H1-'].includes(betToAdd.playType) ? `${game.localTeam.name} vs ${game.visitingTeam.name}` : betToAdd.teamCode == game.localTeam.code || betToAdd.teamCode == game.localTeam.codeRD ? game.localTeam.name : game.visitingTeam.name;
        }
        const newBet = {
          teamCode: betToAdd.teamCode,
          teamCodeRD: matchupLeagues.includes(game.league.name) ? game.player.codeRD : betToAdd.teamCode == game.localTeam.code || betToAdd.teamCode == game.localTeam.codeRD ? game.localTeam.codeRD : game.visitingTeam.codeRD,
          playType: betToAdd.playType === "" ? "M" : betToAdd.playType,
          spread: betToAdd.Spread,
          price: price,
          teamName: TeamName,
          line: line._id,
          game: game._id,
          isMatchups: isMatchUp
        };
        console.log("newBet: ", newBet)
        set({ ticket: { ...bets, bets: [...bets.bets, newBet] } })
        set({ betToAdd: { teamCode: "", playType: "", game: "", line: "", isMatchups: false } })
      }
    } catch (error) {
      console.error({ error })
    }
  },
  handleRemoveBet: (index) => set((state) => ({ ticket: { ...state.ticket, bets: state.ticket.bets.filter((_, i) => i !== index) } })),
  handleClearTicket: () => set((state) => ({ ticket: { ...state.ticket, bets: [] } })),
  handleChangeTicket: (field, value) => set((state) => ({ ticket: { ...state.ticket, [field]: value } })),
  leagues: [],
  loadingLeagues: false,
  getLeagues: async () => {
    try {
      set({ loadingLeagues: true })
      const res = await redApi.get("/leagues?leaguesWithGames=true")
      const { data } = res
      if (data.length > 0) {
        data?.map((league) => {
          league.image = `${process.env.REACT_APP_SERVER_URL}/LeagueImages/${league.name}.png`
        });
      }
      set({ leagues: typeof data !== 'array' ? data : [], loadingLeagues: false })
    } catch (error) {
      console.error({ error })
      set({ loadingLeagues: false })
    }
  },
  printTicket: {
    ticketId: 0,
    print: false,
  },
  setPrintTicket: (ticket) => set({ printTicket: ticket }),
  handlePostTicket: async () => {
    try {
      const { ticket } = get()
      //  {
      //   "amount": 25,
      //   "bets": [
      //       {
      //           "teamCode": 0,
      //           "playType": "M",
      //           "game": "65f2af2e9477dddbae234c3f",
      //           "line": "65f2f26f9477dddbae24fae5",
      //           "isMatchups": false
      //       },
      //       {
      //           "teamCode": 0,
      //           "playType": "M",
      //           "game": "65f2af2e9477dddbae234c54",
      //           "line": "65f2f26f9477dddbae24fafd",
      //           "isMatchups": false
      //       },
      //       {
      //           "teamCode": 0,
      //           "playType": "M",
      //           "game": "65f2af2e9477dddbae234c15",
      //           "line": "65f2f26f9477dddbae24fab5",
      //           "isMatchups": false
      //       }
      //   ],
      //   "totalToWin": 208,
      //   "betsIf": 0,
      //   "toWinIf": 0,
      //   "amountIf": 0
      // }
      // {
      //   "amount": "23",
      //   "totalToWin": 90,
      //   "betsIf": 0,
      //   "toWinIf": 0,
      //   "amountIf": 0,
      //   "bets": [
      //       {
      //           "teamCode": "5945",
      //           "teamCodeRD": 0,
      //           "playType": "M",
      //           "price": "+240",
      //           "teamName": "SJ Sharks",
      //           "line": "65f2f2749477dddbae24fbb9",
      //           "game": "65f2af2e9477dddbae234beb"
      //       },
      //       {
      //           "teamCode": "3017",
      //           "teamCodeRD": 0,
      //           "playType": "M",
      //           "price": "-225",
      //           "teamName": "Boston",
      //           "line": "65f2f2749477dddbae24fbeb",
      //           "game": "65f2af2e9477dddbae234c15"
      //       }
      //   ]
      // }
      console.log("tickets", ticket);
      const res = await redApi.post("/tickets", ticket)
      if (res?.data?.error) {
        Swal.fire({
          title: "Error",
          text: data.error,
          icon: "error",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }
      if (!res?.status === 200) return Swal.fire({
        title: "Error",
        text: 'Error al guardar el ticket',
        icon: "error",
        timer: 3000,
        showConfirmButton: false,
      });
      set({ ticket: { amount: 0, totalToWin: 0, betsIf: 0, toWinIf: 0, amountIf: 0, bets: [] } })
      Swal.fire({
        title: "Ticket",
        text: 'Ticket guardado con éxito',
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      set({ printTicket: { ticketId: res.data.ticketId, print: true } })
    } catch (error) {
      console.error({ error })
    }
  },
}));