import React, { useState, useEffect, useRef } from "react";
import { redApi } from "../../../../config/Axios";
import { socket } from "../../../../socket";

function FormReport(props) {

    const { type, setTicketCode, setSlackTimestamp, inputRef } = props

    const buttonRef = useRef(null);

    // Definir el estado inicial de los campos
    const [subject, setSubject] = useState("");
    const [consorcio, setConsorcio] = useState("");
    const [terminal, setTerminal] = useState("");
    const [printerModel, setPrinterModel] = useState("");
    const [ultraViewer, setUltraViewer] = useState("");
    const [listReports, setListReports] = useState([]);
    const [note, setNote] = useState("");
    const [submitted, setSubmitted] = useState(false);

   

    useEffect(async () => {
        if (type === 'printerReport') {
            setSubject('PRINTER REPORT');
        }
        if (type === 'listReports') {
            await redApi.get(`/chatbot/tickets`).then((res) => {
                setListReports(res.data)
            }).catch((e) => {
            });
        }
    }, [type]);

    // Manejar el cambio de los inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "subject":
                setSubject(value);
                break;
            case "consorcio":
                setConsorcio(value);
                break;
            case "terminal":
                setTerminal(value);
                break;
            case "note":
                setNote(value);
                break;
            case "printer":
                setPrinterModel(value);
                break;
            case "ultraViewer":
                setUltraViewer(value);
                break;
            default:
                break;
        }
    };

    // Manejar el envío del formulario
    const handleSubmitSimpleReport = async (e) => {
        e.preventDefault();
        setSubmitted(true)
        await redApi.post(`/chatbot/tickets`, {
            "subject": subject,
            "consortium": consorcio,
            "terminal": terminal,
            "note": note
        }).then((res) => {
            setTicketCode(res.data.ticketCode)
            setSlackTimestamp(res.data.Slacktimestamp)
            socket.emit('createSlackRoom', {ticket: res.data.ticketCode, timestamp: res.data.Slacktimestamp})

        }).catch((e) => {
        });
        // Aquí puedes hacer lo que quieras con los datos del formulario
        // alert(`Subject: ${subject}\nConsorcio: ${consorcio}\nTerminal: ${terminal}\nNote: ${note}`);
    };

    const handleSubmitPrinterReport = async (e) => {
        e.preventDefault();
        setSubmitted(true)
        await redApi.post(`/chatbot/tickets`, {
            "subject": subject,
            "consortium": consorcio,
            "terminal": terminal,
            "printerModel": printerModel,
            "ultraViewer": ultraViewer,
            "note": note
        }).then((res) => {
            setTicketCode(res.data.ticketCode)
        }).catch((e) => {
        });
        // Aquí puedes hacer lo que quieras con los datos del formulario
        // alert(`Subject: ${subject}\nConsorcio: ${consorcio}\nTerminal: ${terminal}\nNote: ${note}`);
    };

    // Definir los estilos inline
    const styles = {
        label: {
            display: "block",
            margin: "1px 0",
        },
        input: {
            width: "100%",
            padding: "10px",
            marginTop: "2px",
            border: "1px solid #ccc",
            borderRadius: "5px",
        },
        textarea: {
            width: "100%",
            height: "100px",
            // height: "70px",
            padding: "10px",
            marginTop: "5px",
            border: "1px solid #ccc",
            borderRadius: "5px",
        },
        button: {
            display: "block",
            margin: "10px 0",
            padding: "10px 20px",
            backgroundColor: "#CE5151",
            color: "white",
            border: "none",
            cursor: "pointer",
            border: "1px solid #ccc",
            borderRadius: "10px",
        },

        buttonStyle: {
            width: '100%',
            margin: "5px 0",
            padding: "12px 24px",
            backgroundColor: "#3498db",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "background-color 0.3s ease",
        }
    };

   
    // useEffect(() => {
    //     const handleFocus = () => {
    //       if (inputRef.current) {
    //         inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    //       }
    //     };
    
    //     if (inputRef.current) {
    //       inputRef.current.addEventListener("focus", handleFocus);
         
    //       inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    //     }
    
    //     return () => {
    //       if (inputRef.current) {
    //         inputRef.current.removeEventListener("focus", handleFocus);
    //       }
    //     };
    //   }, [inputRef]);

    useEffect(() => {
        const handleFocus = () => {
          if (buttonRef.current) {
            buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
          }
        };
    
        if (inputRef.current) {
          inputRef.current.addEventListener("focus", handleFocus);
        }
    
        return () => {
          if (inputRef.current) {
            inputRef.current.removeEventListener("focus", handleFocus);
          }
        };
      }, [inputRef]);


    if (type == 'simpleReport') // Renderizar el formulario
        return (
            <form onSubmit={handleSubmitSimpleReport}>
                <label style={styles.label}>
                    Subject:
                    <input
                        type="text"
                        name="subject"
                        value={subject}
                        onChange={handleChange}
                        style={styles.input}
                        disabled={submitted}
                    />
                </label>
                <label style={styles.label}>
                    Consorcio:
                    <input
                        type="text"
                        name="consorcio"
                        value={consorcio}
                        onChange={handleChange}
                        style={styles.input}
                        disabled={submitted}
                    />
                </label>
                <label style={styles.label}>
                    Terminal:
                    <input
                        type="text"
                        name="terminal"
                        value={terminal}
                        onChange={handleChange}
                        style={styles.input}
                        disabled={submitted}
                    />
                </label>
                <label style={styles.label}>
                    Note:
                    <textarea
                        name="note"
                        value={note}
                        onChange={handleChange}
                        style={styles.textarea}
                        disabled={submitted}
                        ref={inputRef}
                    />
                </label>
                <button ref={buttonRef} type="submit" style={styles.button} disabled={submitted}>
                    Submit
                </button>
            </form>
        );

    if (type == 'printerReport')
        return (
            <form onSubmit={handleSubmitPrinterReport}>
                <label style={styles.label}>
                    Subject:
                    <input
                        type="text"
                        name="subject"
                        value={subject}
                        onChange={handleChange}
                        style={styles.input}
                        disabled={true}
                    />
                </label>
                <label style={styles.label}>
                    Consorcio:
                    <input
                        type="text"
                        name="consorcio"
                        value={consorcio}
                        onChange={handleChange}
                        style={styles.input}
                        disabled={submitted}
                    />
                </label>
                <label style={styles.label}>
                    Terminal:
                    <input
                        type="text"
                        name="terminal"
                        value={terminal}
                        onChange={handleChange}
                        style={styles.input}
                        disabled={submitted}
                    />
                </label>
                <label style={styles.label}>
                    Printer Model:
                    <input
                        type="text"
                        name="printer"
                        value={printerModel}
                        onChange={handleChange}
                        style={styles.input}
                        disabled={submitted}
                    />
                </label>
                <label style={styles.label}>
                    Ultraviewer #:
                    <input
                        type="text"
                        name="ultraViewer"
                        value={ultraViewer}
                        onChange={handleChange}
                        style={styles.input}
                        disabled={submitted}
                    />
                </label>
                <label style={styles.label}>
                    Note:
                    <input
                        type="note"
                        name="note"
                        value={note}
                        onChange={handleChange}
                        style={styles.input}
                        disabled={submitted}
                        ref={inputRef}
                    />
                </label>
                <button ref={buttonRef} type="submit" style={styles.button} disabled={submitted}>
                    Submit
                </button>
            </form>
        );

    if (type == 'listReports')
        return (
            <div>
                {listReports.map((report) => (
                    <button key={report._id} style={styles.buttonStyle} onClick={() => handleButtonClick(report.ticketCode)}>
                        {report.ticketCode + ' - ' + report.subject}
                    </button>
                ))}
            </div>
        );

}

export default FormReport;