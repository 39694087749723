import React from 'react'
import moment from 'moment'
export const NavLines = () => {
    return (
        <div className="nav-lines">
            <img src="./img/sports-logo-.png" alt="" />
            <span className="nav-lines__date--day">
                {
                    moment().format('hh:mm A')
                }
            </span>
        </div>
    )
}
