import { useState, useContext, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { redApi } from '../../config/Axios';
// import Logo from '../../assets/img/sports-logo.png';
import './login.scss';
import { AuthContext } from '../context/AuthContext';
import { socket } from '../../socket';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function LoginPage() {
    const loginAs = new URLSearchParams(useLocation().search).get('as');
    const [loginAsState, setLoginAsState] = useState(null);
    useEffect(() => {
        if (loginAs !== null) {
            setLoginAsState(loginAs);
        }
    }, [loginAs !== null && loginAs]);

    useEffect(() => {
        socket.emit("loginPage");
    }, []);

    const { login } = useContext(AuthContext);
    const [errorMsg, setErrorMsg] = useState({ active: false, msg: '' });


    const onLogin = async () => {
        try {
            const res = await redApi.post(`/login`, {
                username: data.name,
                password: data.password
            });
            
            if (res.data.token) {
                // const jwt = res.data.token;
                // axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
                // axios.defaults.headers.common['xsrfCookieName'] = 'jwt';
                // axios.defaults.headers.common['xsrfHeaderName'] = 'jwt';
                // document.cookie = `jwt=${jwt}`;
                sessionStorage.setItem("token", res.data.token);
                // console.log(axios.defaults.headers.common, 'headers');
            };

            sessionStorage.setItem("userId", res.data.user_id);
            // sessionStorage.setItem("username", res.data.username);
            socket.emit('initSession', res.data.user_id)
            let TMobile = loginAsState === "TMobile" ? true : false;
            localStorage.setItem("TMobile", TMobile);
            // console.log({userData: res.data});
            login(res.data.token, res.data.userLevel, res.data.theme, res.data.user_id, res.data.username, res.data.dailyMessage, TMobile, res.data);
        } catch (error) {

            if (error.response.data.message == "Sesión Activa") {
                Swal.fire({
                    title: "Existe un sesión Activa \n¿Deseas cerrarla?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: `Si`,
                    denyButtonText: `No`,
                })
                    .then(async (result) => {
                        if (result.isConfirmed) {
                            const res = await redApi.post(`/closeSession`, {
                                username: data.name,
                                password: data.password
                            })

                            if (res.data.token) {
                                // const jwt = res.data.token;
                                // axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
                                // axios.defaults.headers.common['xsrfCookieName'] = 'jwt';
                                // axios.defaults.headers.common['xsrfHeaderName'] = 'jwt';
                                // document.cookie = `jwt=${jwt}`;
                                sessionStorage.setItem("token", res.data.token);
                                // console.log(axios.defaults.headers.common, 'headers');
                            }
                            sessionStorage.setItem("userId", res.data.user_id);
                            // sessionStorage.setItem("username", res.data.username);
                            socket.emit('initSession', res.data.user_id)
                            let TMobile = loginAsState === "TMobile" ? true : false;
                            localStorage.setItem("TMobile", TMobile);
                            // console.log({userData: res.data});
                            login(res.data.token, res.data.userLevel, res.data.theme, res.data.user_id, res.data.username, res.data.dailyMessage, TMobile, res.data);
                        }
                    })
            }

            // alert('Invalid Username | Password');
            setErrorMsg({ active: true, msg: error.response.data.message });
            setTimeout(() => {
                setErrorMsg({ active: false, msg: '' });
            }, 3000);
        }

    };

    const handleEnter = (e) => {
        if (e.key == "Enter") {
            e.preventDefault()
            document.querySelector('#password').focus();
            document.querySelector('#password').select();
        }
    }

    const [data, setData] = useState({
        name: '',
        password: '',
    });

    const handleInputChange = (e) => {
        e.preventDefault();
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onLogin()
    }


    return (
        <Container id="bodyLogin" fluid className='containerLogin'>
            <Row className="rowLogin d-flex justify-content-center align-items-center">
                <Col xs={12} md={4} className="colLogin d-flex flex-column align-items-center">
                    {/* <img src={Logo} alt="Sports" /> */}
                    <Form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control name="name" type="text" onChange={handleInputChange} onKeyDown={handleEnter} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control name="password" type="password" onChange={handleInputChange} id="password" />
                        </Form.Group>
                        <Button type="submit">
                            Login {loginAsState !== null && `as ${loginAsState}`}
                        </Button>
                        {errorMsg.active && (
                            <Form.Label style={{ color: '#CC2F48', textAlign: 'center', marginTop: '20px' }}>
                                {errorMsg.msg}
                            </Form.Label>
                        )}
                    </Form>
                    <span className="subtitleLogin">
                        Login as:
                    </span>
                    {
                        loginAsState !== null &&
                        <Link to="/" onClick={() => setLoginAsState(null)}>
                            <Button>
                                Terminal
                            </Button>
                        </Link>
                    }
                    <Link to="/login?as=WebUser">
                        <Button>
                            WebUser
                        </Button>
                    </Link>
                    <Link to="/login?as=TMobile">
                        <Button>
                            TMobile
                        </Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}
