import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../auth';
import { redApi as api } from '../../../../config/Axios';
import moment from 'moment';
import { Printer } from '../../../printer/Print';
import Swal from 'sweetalert2';
import WebuserModal from '../../../admin/pages/reportes/WebuserModals/WebuserModal';
import ReportesModal from '../reportes/ReportesModalWebuser';
import { WhatsAppModal } from '../whatsapp/WhatsAppModal';

export default function TmobileFooter({handleBetPost}) {
  const { logout, userId, userName } = useContext(AuthContext);


  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    navigate('/', {
      replace: true
    });
  }

  const [whatsappModal, setWhatsappModal] = useState(false)
  const [showReports, setShowReports] = useState(false)
  const [tickets, setTickets] = useState([])
  const [inLoading, setInLoading] = useState(false)
  const [reportsType, setReportsType] = useState('')
  const handleReports = (show) => {
    setShowReports(!showReports)
    setReportsType(show)
  }
  const [reportsModalOpen, setReportsModalOpen] = useState(false)
  const handleReportsModal = () => {
    setReportsModalOpen(!reportsModalOpen)
  }
  useEffect(() => {
    if (showReports) {
      setInLoading(true)
      let initDate = moment().format('YYYY-MM-DD')
      let finalDate = moment().format('YYYY-MM-DD') + 'T23:59:59'
      api.get(`/tickets?initDate=${initDate}&finalDate=${finalDate}`) //today tickets
        .then(res => {
          let tickets = res.data.filter(ticket => {
            if (reportsType === 'reports') return ticket
            if (reportsType === 'cancelticket') return ticket.ticketState === 'Play'
            if (reportsType === 'reprint') return ticket.ticketState !== 'Cancelled'
            if (reportsType === 'salesResults') return ticket.ticketState === 'Winner' || ticket.ticketState === 'Loser'
          })
          setTickets(tickets)
          setInLoading(false)
        })
        .catch(err => console.log(err))
    }
  }, [showReports])



  const [printTicket, setPrintTicket] = useState({
    ticketId: 0,
    print: false
  });
  const handleRePrint = (ticketId) => {
    setPrintTicket({ ticketId, print: true })
  }

  const handleCancelTicket = (ticketId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        api.put(`/tickets/${ticketId}/cancel`)
          .then(res => {
            if (res.status === 200) {
              Swal.fire(
                'Canceled!',
                'Your ticket has been canceled.',
                'success'
              )
              // change ticket status 
              let newTickets = tickets.map(ticket => {
                if (ticket.ticketId === ticketId) {
                  ticket.status = 'Cancelled'
                  return ticket
                }
                return ticket
              })
              setTickets(newTickets)
              return
            }
          })
          .catch((err) => {
            console.log(err.response.data.message, 'err')
            Swal.fire(
              'Error!',
              `${err.response.data.message || 'Something went wrong'}`,
              'error',

            )
          })
      }
    })
  }


  // help modal 
  const [showHelp, setShowHelp] = useState(false)
  const hadnleshowHelp = () => {
    setShowHelp(!showHelp)
  }

  let helpTexts = [
    { key: 'm', text: 'Money Line' },
    { key: 'mh', text: 'Money Line H1' },
    { key: 'mt1', text: 'Money Line P1' },
    { key: 'mt2', text: 'Money Line P2' },
    { key: 'mt3', text: 'Money Line P3' },
    { key: 'r', text: 'Run Line' },
    { key: 'h', text: 'Run Line H' },
    { key: 'q1', text: 'Run Line Q1' },
    { key: 'q2', text: 'Run Line Q2' },
    { key: 'q3', text: 'Run Line Q3' },
    { key: 'q4', text: 'Run Line Q4' },
    { key: 't1', text: 'Run Line P1' },
    { key: 't2', text: 'Run Line P2' },
    { key: 't3', text: 'Run Line P3' },
    { key: 'hr2', text: 'Run Line H2' },
    { key: '+', text: 'Over' },
    { key: 'h+', text: 'Over H' },
    { key: 'q1+', text: 'Over Q1' },
    { key: 'q2+', text: 'Over Q2' },
    { key: 'q3+', text: 'Over Q3' },
    { key: 'q4+', text: 'Over Q4' },
    { key: 't1+', text: 'Over P1' },
    { key: 't2+', text: 'Over P2' },
    { key: 't3+', text: 'Over P3' },
    { key: 'h2+', text: 'Over H2' },
    { key: '-', text: 'Under' },
    { key: 'h-', text: 'Under H' },
    { key: 'q1-', text: 'Under Q1' },
    { key: 'q2-', text: 'Under Q2' },
    { key: 'q3-', text: 'Under Q3' },
    { key: 'q4-', text: 'Under Q4' },
    { key: 't1-', text: 'Under P1' },
    { key: 't2-', text: 'Under P2' },
    { key: 't3-', text: 'Under P3' },
    { key: 'h2-', text: 'Under H2' },
    { key: 's+', text: 'Solo +' },
    { key: 'h1s+', text: 'Solo +H' },
    { key: 'q1s+', text: 'Solo +Q1' },
    { key: 'q2s+', text: 'Solo +Q2' },
    { key: 'q3s+', text: 'Solo +Q3' },
    { key: 'q4s+', text: 'Solo +Q4' },
    { key: 'h2s+', text: 'Solo +H' },
    { key: 's-', text: 'Solo -' },
    { key: 'h1s-', text: 'Solo -H' },
    { key: 'q1s-', text: 'Solo -Q1' },
    { key: 'q2s-', text: 'Solo -Q2' },
    { key: 'q3s-', text: 'Solo -Q3' },
    { key: 'q4s-', text: 'Solo -Q4' },
    { key: 'h2s-', text: 'Solo -H' },
    { key: 'p', text: 'Super Run Line' },
    { key: 'y', text: 'Yes anotaran' },
    { key: 'n', text: 'No anotaran' },
    { key: 'k+', text: 'Strikeout +' },
    { key: 'k-', text: 'Strikeout -' },
    { key: 'f', text: 'First run' },
    { key: 'c', text: 'Buying' },
    { key: 'v', text: 'Senta' },
    { key: 'c+', text: 'B Over' },
    { key: 'c-', text: 'B Under' },
    { key: 'j1', text: 'Players 1' },
    { key: 'j2', text: 'Players 2' },
    { key: 'j3', text: 'Players 3' },
    { key: 'j4', text: 'Players 4' },
    { key: 'j5', text: 'Players 5' },
    { key: 'g', text: 'GS ML' },
    { key: 'g+', text: 'GS Over' },
    { key: 'g-', text: 'GS Under' },
    { key: 'se', text: 'Serie' },
    { key: 'ar', text: 'Run Line Alternativo' },
    { key: 't', text: 'Tercio' },
    { key: 't+', text: 'Tercio Mas' },
    { key: 't-', text: 'Tercio Menos' },
    { key: 'v+', text: 'S a mas' },
    { key: 'v-', text: 'S a menos' },
    { key: 'e', text: 'Draw' },
    { key: 'hr1', text: 'Run Line H' },
    { key: 'h1+', text: 'Over H' },
    { key: 'h1-', text: 'Under H' }
  ]


  const HelpModal = () => {
    return (
      <Modal
        open={showHelp}
        onCancel={hadnleshowHelp}
        footer={null}
        width="100%"
      >
        <div className="row">
          <div className="col">
            <h3>How to use the system</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Enter</th>
                  <th scope="col">A Juego</th>
                </tr>
              </thead>
              <tbody>
                {
                  helpTexts.map((helpText, index) => (
                    <tr key={index}>
                      <td>{helpText.key}</td>
                      <td>{helpText.text}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    )
  }

  // date filters
  const handleFilters = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
  }

  const [filters, setFilters] = useState({
    fromDate: '',
    toDate: ''
  })

  // get tickets if filters change
  const handleGetTickets = async () => {
    let { fromDate, toDate } = filters
    // if toDate is today, add 24 hours to get all tickets
    if (toDate === moment().format('YYYY-MM-DD')) {
      toDate = moment().add(1, 'days').format('YYYY-MM-DD')
    }

    setInLoading(true)
    await api.get(`/tickets?initDate=${fromDate}&finalDate=${toDate}`)
      .then(res => {
        let tickets = res.data.filter(ticket => {
          if (reportsType === 'reports') return ticket
          if (reportsType === 'cancelticket') return ticket.ticketState === 'Play'
          if (reportsType === 'reprint') return ticket.ticketState !== 'Cancelled'
          if (reportsType === 'salesResults') return ticket.ticketState === 'Winner' || ticket.ticketState === 'Loser'
        })
        setTickets(tickets)
        setInLoading(false)
      })
      .catch(err => {
        console.log(err)
      }
      )
  }

  const [modalCrear, setModalCrear] = useState(false)
  const [userSelected, setUserSelected] = useState(null)
  const [modalEditar, setModalEditar] = useState(false)
  const [data, setData] = useState({
    // username: "",
    isGroup: "",
    // email: "",
    theme: 1,
    ownedBy: userId ? userId : '',
  });

  const habdleModalCrear = () => {
    setModalCrear(!modalCrear)
  }

  return (
    <footer className="footer">
      {
        printTicket.print ?

          <Printer ticketId={printTicket.ticketId} printTicket={printTicket} setPrintTicket={setPrintTicket} />
          : null
      }
      <HelpModal />
      <div className="footer_container">
        <button className="col footer_button" onClick={() => handleReports('cancelticket')}>Cancel Ticket</button>
        <button className="col footer_button" onClick={() => handleReports('reprint')}>Re-print</button>
        <button className="col footer_button" disabled>Save</button>
        <button className="col footer_button" onClick={() => {setWhatsappModal(true)}}>WhatsApp</button>
        <WhatsAppModal open={whatsappModal} setOpen={setWhatsappModal} handleBetPost={handleBetPost}/>
        {/* <select className="col footer_button" disabled>
          <option value={'Print'}>Print</option>
        </select> */}

        <button className="col footer_button" onClick={() => handleReports('salesResults')}>Sales Results </button>
        <button className="col footer_button d-flex align-items-center justify-content-center gap-1" onClick={hadnleshowHelp}>
          <i className="fas fa-question-circle"></i>
          Help
        </button>
        <button className="col footer_button"
          onClick={() => handleReportsModal()}
        >Reports</button>
        <button className="col footer_button d-flex align-items-center justify-content-center gap-1" onClick={onLogout}>
          <i className="fas fa-sign-out-alt"></i>
          Exit
        </button>
      </div>
      <Modal
        open={showReports}
        onCancel={handleReports}
        footer={null}
        width="100%"
        style={{ top: 10 }}
        className="modal_reports_webusers"
      >
        <div className="row">
          <div className="col-12 d-flex justify-content-center flex-column align-items-center gap-4">
            {/* date filters */}
            <div className="d-flex gap-4 align-items-end justify-content-center flex-column">
              <div className='d-flex gap-2'>
                <div className="col">
                  <label htmlFor="fromDate">From Date</label>
                  <input type="date" className="form-control" name="fromDate" onChange={handleFilters} defaultValue={moment().format('YYYY-MM-DD')} />
                </div>
                <div className="col">
                  <label htmlFor="toDate">To Date</label>
                  <input type="date" className="form-control" name="toDate" onChange={handleFilters} defaultValue={moment().format('YYYY-MM-DD')} />
                </div>
              </div>
              <div className="col mx-auto">
                <button className="btn btn-primary" onClick={handleGetTickets}>Get Tickets</button>
              </div>
            </div>
            <table className="table-mobile">
              <thead>
                <tr>
                  {/* <th scope="col">Consorcio</th> */}
                  <th scope="col" className='text-center'>Ticket #</th>
                  <th scope="col" className='text-center'>Fecha
                    <br />
                    Hora</th>
                  {/* {
                    reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>POS</th>
                  } */}
                  {/* <th scope="col" className='text-center'>Usuario</th> */}
                  {
                    reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>Tipo de <br /> Ticket</th>
                  }

                  <th scope="col" className='text-center'>Apostado</th>
                  {
                    reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>A pagar</th>
                  }
                  <th scope="col" className='text-center'>Status</th>
                </tr>
              </thead>
              <tbody className='position-relative'>
                {
                  inLoading ? (
                    <tr className="spinner-border text-primary position-absolute top-50 start-50" role="status">
                      <td colSpan={7} className="visually-hidden">Loading...</td>
                    </tr>
                  ) : (
                    tickets.length === 0 ? (
                      <tr>
                        <td colSpan={7} className='text-center'>No hay tickets</td>
                      </tr>
                    ) : (
                      tickets.map((ticket, index) => (

                        <tr key={index} onClick={reportsType === 'reprint' ? () => handleRePrint(ticket.ticketId) : reportsType === 'cancelticket' ? () => handleCancelTicket(ticket.ticketId) : null}>
                          {/* <td>{ticket.consortium || 'N/A'}</td> */}
                          <td className='text-center'>#{ticket.ticketId || 'N/A'}</td>
                          <td className='text-center'>{moment(ticket.createdAt).format('DD/MM/YYYY hh:mm A')}</td>
                          {/* {
                            reportsType === 'salesResults' ? null : <td className='text-center'>{ticket.createdBy.username || 'N/A'}</td>
                          } */}

                          {/* <td className='text-center'>{ticket.user || 'N/A'}</td> */}
                          {
                            reportsType === 'salesResults' ? null : <td className='text-center'>{ticket.bets.length === 1 ? 'Direct' : ticket.bets.length === 2 ? 'Pale' : ticket.bets.length === 3 ? 'Tripleta' : ticket.bets.length === 4 ? 'Cuadruple' : ticket.bets.length === 5 ? 'Quintuple' : ticket.bets.length === 6 ? 'Sextuple' : ticket.bets.length === 7 ? 'Septuple' : ticket.bets.length === 8 ? 'Octuple' : ticket.bets.length === 9 ? 'Nonuple' : ticket.bets.length === 10 ? 'Decuple' : 'N/A'}</td>
                          }
                          <td className='text-center'>${ticket.amount || 'N/A'}</td>
                          {
                            reportsType === 'salesResults' ? null : <td className='text-center'>${ticket.toCollect || 'N/A'}</td>
                          }
                          <td className={`text-center ${ticket.ticketState === 'Play' ? 'text-warning' : ticket.ticketState === 'Winner' ? 'text-success' : ticket.ticketState === 'Loser' ? 'text-danger' : 'text-secondary'}`}
                          >{ticket.ticketState || 'N/A'}</td>
                        </tr>
                      ))
                    )
                  )
                }
              </tbody>
              {
                reportsType !== 'reports' ? null : (
                  <tfoot>
                    <tr>
                      <td colSpan={4} className='text-center'>Total</td>
                      <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.amount, 0)}</td>
                      <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.toCollect, 0)}</td>
                      <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.toCollect, 0) - tickets.reduce((acc, ticket) => acc + ticket.amount, 0)}</td>
                    </tr>
                  </tfoot>
                )
              }

            </table>
          </div>
        </div>
      </Modal>
      <ReportesModal
        open={reportsModalOpen}
        onCancel={handleReportsModal}
      />
    </footer>
  )
}
