import { redApi } from "../config/Axios";


export const getConsortiums = async () => {
    try {
        const res = await redApi.get(`/users/getconsortiums`);
        return res.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const getTerminals = async (ownedBy) => {
    try {
        if (!ownedBy) {
            const res = await redApi.get(`/users?populates=limits,income,leagues,ownedBy&userLevel=3`);
            return res.data;
        };
        const res = await redApi.get(`/users/getusers?ownedBy=${ownedBy}`);
        return res.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const getTerminalsWithBalance = async (ownedBy) => {
    try {
        const res = await redApi.get(`/users/getuserswithbalance?ownedBy=${ownedBy}`);
        return res.data;
    } catch (error) {
        console.log(error)
        return [];
    }
}