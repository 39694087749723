import { useCallback, useContext, useEffect, useState } from "react"
import { TableDinamic } from "../../components/admin/pages/horarioylineasmodal/tablas/TableDinamic"
import { TableDinamicMatchups } from "../../components/admin/pages/horarioylineasmodal/tablas/TableMatchups "
import LoadingScreen from "../../components/layout/LoadingScreen";
import { HyRContext } from "../context/HyLContext";
// import { usegamesRender } from "../hooks/useGamesRender";

export default function HyLLinesTable({
    league,
    periods,
    selectedValue,
    gamesRender,
    gamesTableLoading,
    handleStatusGame,
    handleVisibleForGames,
    handleVisibleForLeagues,
    handlePeriodSelected,
    tableData,
    isPonche,
    handleChange,
    handleAuthLine,
    lineIdSelected,
    selectLineId,
    verifyOnChangeLines,
    linesChanged,
    linesChangedHashMap,
    setVisible,
    setEstatus,
    estatus,
    putAuthLine,
    putUnauthLine,
    handleTypeLineSelected,
    openSuperLiniero,
    loadGames
}) {
    // const { gamesRender_: gamesRender_9 } = usegamesRender();
    // useEffect(() => {
    //     console.log({ league }, "league9")
    // }, [league]);
    // useEffect(() => {
    //     console.log({ gamesRender_9 }, "gamesRender_9")
    // }, [gamesRender_9]);
    const [lineMode, setLineMode] = useState("activas");
    const onDblClickByLineMode = useCallback(
        (game, indexGame) => {
            switch (lineMode) {
                case "activas": return handleVisibleForGames(game);
                case "superliniero": return openSuperLiniero(game, indexGame);
                // case "donbestC": break;
                // case "donbestI": break;
                default: return handleVisibleForGames(game);
            }
        },
        [lineMode]
    )
    const {
        leaguesWithGames
    } = useContext(HyRContext);
    const refresh = () => {
        loadGames()
    };
    if(league && league.lineType){
        const indicesAEliminar = ["Si Anotarán", "No Anotarán"]
        league.lineType = league.lineType.filter((index)=> !indicesAEliminar.includes(index.name))
    }
    return (
        <>
            <div className="body row m-0 mb-2 gap-2 px-2 px-lg-4">
                <ul className="nav_container col d-flex justify-content-center gap-4">
                    <li className="box-hyl-nav">
                        <ul className="px-2">
                            <li>
                                <button className="button" onClick={putAuthLine}>
                                    Autorizar
                                </button>
                                <button className="button" onClick={putUnauthLine}>
                                    Desautorizar
                                </button>
                                <button className="button" onClick={()=>refresh()}>Refrescar</button>
                            </li>
                            <li>
                                <select defaultValue="eliminar" className="form-select">
                                    <option value="eliminar">Eliminar</option>
                                    <option value="moneyline">Money Line</option>
                                    <option value="runline">Run Line</option>
                                    <option value="totalline">Total Line</option>
                                    <option value="sololine">Solo Line</option>
                                    <option value="todas">Todas</option>
                                </select>
                            </li>
                            <li>
                                <button className="button">Procesar</button>
                                <select defaultValue="all" className="form-select">
                                    <option disabled>Imprimir</option>
                                    <option value="all">Todas</option>
                                    <option value="auth">Autorizadas</option>
                                </select>
                            </li>
                        </ul>
                    </li>
                    <li className="box-hyl-nav">
                        <ul className="px-2">
                            <li>
                                <div className="dropdown">
                                    <button className="button dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" >
                                        Tipo de Linea
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {
                                            league?.lineType?.map((lineType, index) => (
                                                <button key={index} value={index} name={lineType.name} onClick={() => handleTypeLineSelected(index)}>{lineType.name}</button>
                                            ))
                                        }
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label>Modo de linea</label>
                                <select
                                    value={lineMode}
                                    className="form-select"
                                    onChange={(event) => { setLineMode(event.target.value) }}
                                >
                                    <option value="activas">Activas</option>
                                    <option value="superliniero">Super Liniero</option>
                                    <option value="donbestC">Don Best C</option>
                                    <option value="donbestI">Don Best I</option>
                                </select>
                            </li>
                        </ul>
                    </li>
                    <li className="box-hyl-nav">
                        <ul className="px-2">
                            <li>
                                <div className="dropdown">
                                    <button className="button dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" >
                                        Periodos
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {
                                            periods && periods?.map((period, index) => (
                                                <button key={index} type="button" onClick={() => handlePeriodSelected(index)}>
                                                    {period?.period}
                                                </button>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="dropdown" id="selectStatus">
                                    <button className="button dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                        Estado
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <button type="button" onClick={() => { setEstatus("all") }}
                                            className={`${estatus === "all" ? "active" : ""}`}
                                        >
                                            Todos
                                        </button>
                                        <button type="button" onClick={() => { setEstatus("not inited") }}
                                            className={`${estatus === "not inited" ? "active" : ""}`}
                                        >
                                            No iniciado
                                        </button>
                                        <button type="button" onClick={() => { setEstatus("playing") }}
                                            className={`${estatus === "playing" ? "active" : ""}`}
                                        >
                                            En juego
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>
            <>
                {
                    league && gamesRender.length > 0 ?
                        (
                            <>
                                {

                                    league && league?.name !== "NBA-S" && league?.name !== "WNBA-S" && league?.name !== "NFL-S" && league?.name !== "NHL-S" ?
                                        <TableDinamic
                                            botSelected={selectedValue}
                                            games={gamesRender}
                                            isLoading={gamesTableLoading}
                                            handleStatusGame={handleStatusGame}
                                            handleVisibleForGames={handleVisibleForGames}
                                            handleVisibleForLeagues={handleVisibleForLeagues}
                                            head={tableData.head}
                                            isPonches={isPonche}
                                            lineahandleChange={handleChange}
                                            lineahandleClick={handleAuthLine}
                                            lineIdSelected={lineIdSelected}
                                            selectLineId={selectLineId}
                                            linesChanged={{ verifyOnChangeLines, linesChanged, linesChangedHashMap }}
                                            onDblClickByLineMode={onDblClickByLineMode}
                                            putAuthLine={putAuthLine}
                                        /> :
                                        (
                                            <TableDinamicMatchups
                                                games={gamesRender}
                                                isLoading={gamesTableLoading}
                                                handleStatusGame={handleStatusGame}
                                                handleVisibleForGames={handleVisibleForGames}
                                                handleVisibleForLeagues={handleVisibleForLeagues}
                                                head={tableData.head}
                                                isPonches={isPonche}
                                                lineahandleChange={handleChange}
                                                lineahandleClick={handleAuthLine}
                                                TrHeadOnClick={() => setVisible(true)}
                                                TronDoubleClick={() => setVisible(true)}
                                            />
                                        )
                                }
                            </>
                        )
                        :
                        leaguesWithGames.length > 0 ?
                            <TableDinamic
                                botSelected={selectedValue}
                                games={gamesRender}
                                isLoading={true}
                                handleStatusGame={handleStatusGame}
                                handleVisibleForGames={handleVisibleForGames}
                                handleVisibleForLeagues={handleVisibleForLeagues}
                                head={tableData.head}
                                isPonches={isPonche}
                                lineahandleChange={handleChange}
                                lineahandleClick={handleAuthLine}
                                lineIdSelected={lineIdSelected}
                                selectLineId={selectLineId}
                                linesChanged={{ verifyOnChangeLines, linesChanged, linesChangedHashMap }}
                                onDblClickByLineMode={onDblClickByLineMode}
                                putAuthLine={putAuthLine}
                            />
                            : <div className="no-lines-container">
                                <span>NO HAY LINEAS PARA VER, POR FAVOR CARGA NUEVAS LINEAS!</span>
                            </div>
                }
            </>
        </>
    )
}
