import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { redApi } from "../../../../config/Axios";
import Swal from "sweetalert2";
import moment from "moment";
import './HyLModalCrear.css';

const HyLModalCrear = ({
  show,
  setShow,
  setGamesRender,
  userCodesTeams,
  loadGames,
  leagueSelected,
  localTeamSelected,
  visitingTeamSelected,
  setTeamsSelected
}) => {


  const [ligas, setLigas] = useState([]);
  const [equipos, setEquipos] = useState([]);
  const [loading, setLoading] = useState(false);
  const getTeams = async () => {
    if (createGame.league) {
      setLoading(true);
      let response = await redApi
        .get(`/teams?league=${createGame.league}`)
        .then((res) => {
          setLoading(false);
          return res;
        });
      setEquipos(response.data);
      return;
    }
  };
  useEffect(() => {
    redApi
      .get("/leagues")
      .then((res) => {
        setLigas(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const [dateTime, setDateTime] = useState({
    date: moment().format("YYYY-MM-DD"),
    time: moment().add(1, "hours").format("HH:mm"),
  });
  const [createGame, setCreateGame] = useState({
    time: "",
    localTeam: localTeamSelected ? localTeamSelected : "",
    visitingTeam: visitingTeamSelected ? visitingTeamSelected : "",
    league: leagueSelected?._id ? leagueSelected?._id : "",
    codeLocal:"",
    codeVisitor:"",
    manual: true
  });
  useEffect(() => {
    setCreateGame({
      ...createGame,
      localTeam: localTeamSelected ? localTeamSelected : "",
      visitingTeam: visitingTeamSelected ? visitingTeamSelected : "",
      league: leagueSelected?._id ? leagueSelected?._id : "",
    });
  }, [leagueSelected, localTeamSelected, visitingTeamSelected]);

  useEffect(() => {
    getTeams();
  }, [leagueSelected, createGame.league]);

  const [editableCodeLocal, setEditableCodeLocal] = useState('');
  const [editableCodeVisitor, setEditableCodeVisitor] = useState('');

  
  const handleCodeChange = (e, field) => {
    const newValue = e.target.value;
    if (field === 'codeLocal') {
      setEditableCodeLocal(newValue);
    } else if (field === 'codeVisitor') {
      setEditableCodeVisitor(newValue);
    }
    setCreateGame((prevCreateGame) => ({
      ...prevCreateGame,
      [field]: newValue
    }));
  };
  
  // const handleInputChange = (e) => {

  //   setCreateGame({
  //     ...createGame,
  //     [e.target.name]: e.target.value,
 
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if(showManual){
      if (name === 'localTeam') {
        setCreateGame((prevCreateGame) => ({
          ...prevCreateGame,
          [name]: value,
          codeLocal: editableCodeLocal,
          "manual": true
        }));
      } else if (name === 'visitingTeam') {
      
        setCreateGame((prevCreateGame) => ({
          ...prevCreateGame,
          [name]: value,
          codeVisitor: editableCodeVisitor,
          "manual": true
        }));
      } else {
        setCreateGame((prevCreateGame) => ({
          ...prevCreateGame,
          [name]: value,
          "manual": true
        }));
      }
    } else {  
        if (name === 'localTeam') {
          const selectedTeam = equipos.find(equipo => equipo._id === value);
          const newCodeLocal = selectedTeam && userCodesTeams === "rd" ? selectedTeam?.codeRD : selectedTeam?.code;
          
          setEditableCodeLocal(newCodeLocal);
      
          setCreateGame((prevCreateGame) => ({
            ...prevCreateGame,
            [name]: value,
            codeLocal: newCodeLocal,
            "manual": true
          }));
        } else if (name === 'visitingTeam') {
          const selectedTeam = equipos.find(equipo => equipo._id === value);
          const newCodeVisitor = selectedTeam && userCodesTeams === "rd" ? selectedTeam?.codeRD : selectedTeam?.code;
          setEditableCodeVisitor(newCodeVisitor)
          setCreateGame((prevCreateGame) => ({
            ...prevCreateGame,
            [name]: value,
            codeVisitor: newCodeVisitor,
            "manual": true
          }));
        } else {
          setCreateGame((prevCreateGame) => ({
            ...prevCreateGame,
            [name]: value,
            "manual": true
          }));
        }
      }
  };

  const handleDateTimeChange = (e) => {
    setDateTime({
      ...dateTime,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateGame = async () => {

    if (
      createGame.league == "" ||
      createGame.localTeam == "" ||
      createGame.visitingTeam == "" ||
      dateTime.time == ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Debe llenar el campo ${createGame.league == "" ? "Liga" : ""}${createGame.localTeam == "" ? "Equipo local" : ""}${createGame.visitingTeam == "" ? "Equipo visitante" : ""}${dateTime.time == "" ? "Fecha y hora" : ""}`,
      });
      return;
    }
    Swal.fire({
      title: "Creando juego...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await redApi
      .post("/games", {
        ...createGame,
        time: `${dateTime.date}T${dateTime.time}:00.000Z`,
      })
      .then(async (res) => {
        Swal.fire({
          icon: "success",
          title: "Juego creado",
          showConfirmButton: false,
          timer: 1500,
        });
        await loadGames();
        setShow(false);
        setEditableCodeLocal("");
        setEditableCodeVisitor("")
        setEditableCodeVisitor("");
        setCreateGame({
          ...createGame,
          localTeam: localTeamSelected ? localTeamSelected : "",
          visitingTeam: visitingTeamSelected ? visitingTeamSelected : "",
          league: leagueSelected?._id ? leagueSelected?._id : "",
          codeLocal:"",
          codeVisitor:"",
          manual: true
        });
        setTeamsSelected({
          localTeamSelected: "",
          visitingTeamSelected: "",
        })
      })
      .catch((err) => console.log(err));
  };
  const handleCreateGameManual = async () => {
    if (
      createGame.league == "" ||
      createGame.localTeam == "" ||
      createGame.visitingTeam == "" ||
      dateTime.time == ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Debe llenar el campo ${createGame.league == "" ? "Liga" : ""}${createGame.localTeam == "" ? "Equipo local" : ""}${createGame.visitingTeam == "" ? "Equipo visitante" : ""}${dateTime.time == "" ? "Fecha y hora" : ""}`,
      });
      return;
    }
    Swal.fire({
      title: "Creando juego...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await redApi
      .post("/games/createManual", {
        ...createGame,
        time: `${dateTime.date}T${dateTime.time}:00.000Z`,
      })
      .then(async (res) => {
        Swal.fire({
          icon: "success",
          title: "Juego Manual creado",
          showConfirmButton: false,
          timer: 1500,
        });
        await loadGames();
        setShow(false);
        setShowManual(false);
        setEditableCodeLocal("");
        setEditableCodeVisitor("")
        setEditableCodeVisitor("");
        setCreateGame({
          ...createGame,
          localTeam: localTeamSelected ? localTeamSelected : "",
          visitingTeam: visitingTeamSelected ? visitingTeamSelected : "",
          league: leagueSelected?._id ? leagueSelected?._id : "",
          codeLocal:"",
          codeVisitor:"",
          manual: true
        });
        setTeamsSelected({
          localTeamSelected: "",
          visitingTeamSelected: "",
        })
      })
      .catch((error) => {
        console.log("error: " , error)
        let errorMessage = "Error al Crear Juego Manual"; 
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
    
        Swal.fire({
          icon: "error",
          title: errorMessage,
          showConfirmButton: true,
          // timer: 1500,
        });
        setShow(false);
        setShowManual(false);
        setActiveEditCode(false);
        setEditableCodeLocal("");
        setEditableCodeVisitor("")
        setIsCodeLocalDisabled(true)
        setCreateGame({
          ...createGame,
          localTeam: localTeamSelected ? localTeamSelected : "",
          visitingTeam: visitingTeamSelected ? visitingTeamSelected : "",
          league: leagueSelected?._id ? leagueSelected?._id : "",
          codeLocal:"",
          codeVisitor:"",
          manual: true
        });
        throw error
      
      });
  };

  const [showManual, setShowManual] = useState(false);

  const handleShowManual = () => {
    setShowManual(!showManual);
    if(showManual){
      setEditableCodeLocal("");
      setEditableCodeVisitor("");
      setCreateGame({
        ...createGame,
        localTeam: localTeamSelected ? localTeamSelected : "",
        visitingTeam: visitingTeamSelected ? visitingTeamSelected : "",
        league: leagueSelected?._id ? leagueSelected?._id : "",
        codeLocal:"",
        codeVisitor:"",
        manual: true
      });
    }
  }

  const buttonStyles = showManual
    ? { backgroundColor: '#dc3545', color: 'white', cursor: 'pointer', width: '150px', height: '40px', borderRadius: '5px', marginLeft: '400px', marginTop: '7px'}
    : { backgroundColor: '#dc3545', color: 'white', cursor: 'pointer', width: '150px', height: '40px', borderRadius: '5px', marginLeft: '400px', marginTop: '7px'};


 const [activeEditCode, setActiveEditCode] = useState(false);
 const [isCodeLocalDisabled, setIsCodeLocalDisabled] = useState(true);

 const handleActiveCode = () => {
  setActiveEditCode(!activeEditCode);
  setIsCodeLocalDisabled(!isCodeLocalDisabled);
  if(activeEditCode){
    setEditableCodeLocal("");
    setEditableCodeVisitor("");
    setCreateGame({
      ...createGame,
      localTeam: localTeamSelected ? localTeamSelected : "",
      visitingTeam: visitingTeamSelected ? visitingTeamSelected : "",
      league: leagueSelected?._id ? leagueSelected?._id : "",
      codeLocal:"",
      codeVisitor:"",
      manual: true
    });
  }
}


const handleCancel = () => {
  setShow(false)
  setEditableCodeLocal("");
  setEditableCodeVisitor("");
  setCreateGame({
    ...createGame,
    localTeam: localTeamSelected ? localTeamSelected : "",
    visitingTeam: visitingTeamSelected ? visitingTeamSelected : "",
    league: leagueSelected?._id ? leagueSelected?._id : "",
    codeLocal:"",
    codeVisitor:"",
    manual: true
  });
}

const updateCode = async () => {
  try {
    if(activeEditCode){
      await redApi
      .put("teams/editCode", {
        ...createGame
      })
      
        Swal.fire({
          icon: "success",
          title: "Exito al editar codigo del Equipo",
          showConfirmButton: false,
          timer: 1500,
        });
        setShow(false);
        setActiveEditCode(false);
        setEditableCodeLocal(false);
        setEditableCodeLocal("");
        setEditableCodeVisitor("")
        setIsCodeLocalDisabled(true)
        setCreateGame({
          ...createGame,
          localTeam: localTeamSelected ? localTeamSelected : "",
          visitingTeam: visitingTeamSelected ? visitingTeamSelected : "",
          league: leagueSelected?._id ? leagueSelected?._id : "",
          codeLocal:"",
          codeVisitor:"",
          manual: true
        });
      }
  } catch (error) {
    console.log("error", error)
  
    let errorMessage = "Error al editar el código"; 
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    Swal.fire({
      icon: "error",
      title: errorMessage,
      showConfirmButton: true,
      // timer: 1500,
    });
    setShow(false);
    setActiveEditCode(false);
    setEditableCodeLocal("");
    setEditableCodeVisitor("")
    setIsCodeLocalDisabled(true)
    setCreateGame({
      ...createGame,
      localTeam: localTeamSelected ? localTeamSelected : "",
      visitingTeam: visitingTeamSelected ? visitingTeamSelected : "",
      league: leagueSelected?._id ? leagueSelected?._id : "",
      codeLocal:"",
      codeVisitor:"",
      manual: true
    });
    throw error
  }
}

  return (
    <Modal
      title={<div style={{ color: 'Gray', fontSize: '30px', fontWeight: 'bold' }}>Crear Juego</div>}
      open={show}
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      width={"80%"}
      style={{ top: 20 }}
      footer={[
        <button
        key={"Crear"}
          className="btn btn-primary px-4 mx-2"
          // onClick={handleCreateGame}
          onClick={showManual ? handleCreateGameManual : handleCreateGame}
        >
          Crear
        </button>,
        <button className="btn btn-danger px-4" onClick={()=>{handleCancel()}} key={"Cerrar"} >
          Cerrar
        </button>,
      ]}
    >
      <div className="row d-flex flex-column p-0 m-0">
        <div className="row p-0 m-0">
          <div className="col-12 col-md-6 d-flex gap-2">
            <div className="col-4 d-flex justify-content-center">
              <span className="font-weight-bold d-flex justify-content-center align-items-center" style={{fontWeight:'bolder', fontSize:'15px'}}>
                Inicio del juego
              </span>
            </div>
            <div className="col-8 d-flex justify-content-center align-items-center gap-2">
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <input
                  type="date"
                  className="mb-2 form-control"
                  onChange={handleDateTimeChange}
                  name="date"
                  value={dateTime.date}
                />
              </div>
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <input
                  type="time"
                  className="mb-2 form-control"
                  onChange={handleDateTimeChange}
                  name="time"
                  defaultValue={moment().format("HH:mm")}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex gap-2">
            <div className="col-4 d-flex justify-content-center">
              <span className="font-weight-bold d-flex justify-content-end align-items-center" style={{fontWeight:'bolder', fontSize:'15px'}}>
                Liga
              </span>
            </div>
            <div className="col-8 d-flex justify-content-center align-items-center gap-2">
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <select
                  className="mb-2 form-select text-center"
                  onChange={handleInputChange}
                  name="league"
                  value={createGame.league}
                >
                  <option value="">Seleccione una liga</option>
                  {ligas.map((liga, index) => {
                    return (
                      <option key={index} value={liga._id}>
                        {liga.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
       
        <div className="row p-0 m-0">
          <div className="col-12 col-md-6 d-flex gap-2">
            <div className="col-4 d-flex justify-content-center">
              <span className="font-weight-bold d-flex justify-content-end align-items-center" style={{fontWeight:'bolder', fontSize:'15px'}}>
                Equipo local
              </span>
            </div>
            { !showManual ? 
            (
            <div className="col-8 d-flex justify-content-center align-items-center gap-2">
              <div className="col d-flex flex-column justify-content-center align-items-center">
              <div className="flex-container">
                        <input
                              type="text"
                              className="mb-1 form-control text-center"
                              value={editableCodeLocal}
                              // onChange={handleCodeChangeLocal}
                              onChange={(e) => handleCodeChange(e, 'codeLocal')}
                              placeholder="Code"
                              style={{width:'80px'}}
                              disabled={isCodeLocalDisabled}
                            />
                <select
                  className="mb-2 form-select text-center"
                  onChange={handleInputChange}
                  name="localTeam"
                  value={createGame.localTeam}
                  style={{marginTop: "5px"}}
                >
                  {loading ? (
                    <option value="">Cargando...</option>
                  ) : equipos.length > 1 ? (
                    <>
                      <option value="">Seleccione un equipo</option>
                      {equipos.map((equipo, index) => {
                        return (
                       
                          <option
                            key={index}
                            value={equipo._id}
                            disabled={equipo._id == createGame.visitingTeam}
                          >
                            {/* {userCodesTeams === "rd"
                              ? equipo.codeRD
                              : equipo.code}{" "} */}
                            {equipo.name}
                          </option>
                        
                        );
                      })}
                    </>
                  ) : (
                    <option value="">Seleccione una liga</option>
                  )}
                </select>
               </div>
              </div>
            </div>
            )
            :
            (
              <div className="col-8 d-flex justify-content-center align-items-center gap-2">
              <div className="d-flex flex-row justify-content-center align-items-center">                
                <input type="text" className="form-control" placeholder="code" style={{ width: '100px' }} onChange={(e) => handleCodeChange(e, 'codeLocal')}/>        
                <input type="text" name="localTeam" className="form-control ms-2" placeholder="Equipo Local" onChange={handleInputChange}/>
              </div>
            </div>
            
            )
            }
          </div>
          
          <div className="col-12 col-md-6 d-flex gap-2">
            <div className="col-4 d-flex justify-content-center">
              <span className="font-weight-bold d-flex justify-content-center align-items-center" style={{fontWeight:'bolder', fontSize:'15px'}}>
                Equipo visitante
              </span>
            </div>
          
            { !showManual ? 
            (
            <div className="col-8 d-flex justify-content-center align-items-center gap-2">
              <div className="col d-flex flex-column justify-content-center align-items-center">
              <div className="flex-container">
                            <input
                              type="text"
                              className="mb-1 form-control text-center"
                              value={editableCodeVisitor}
                              // onChange={handleCodeChangeVisitor}
                              onChange={(e) => handleCodeChange(e, 'codeVisitor')}
                              placeholder="Code"
                              style={{width:'80px'}}
                              disabled={isCodeLocalDisabled}
                            />
                <select
                  className="mb-2 form-select text-center"
                  onChange={handleInputChange}
                  name="visitingTeam"
                  value={createGame.visitingTeam}
                >
                  {loading ? (
                    <option value="">Cargando...</option>
                  ) : equipos.length > 1 ? (
                    <>
                      <option value="">Seleccione un equipo</option>
                      {equipos.map((equipo, index) => {
                        return (
                          <option
                            key={index}
                            value={equipo._id}
                            disabled={equipo._id == createGame.visitingTeam}
                          >
                            {/* {userCodesTeams === "rd"
                              ? equipo.codeRD
                              : equipo.code}{" "} */}
                             {equipo.name}
                          </option>
                        );
                      })}
                     
                    </>
                   
                  ) : (
                    <option value="">Seleccione una liga</option>
                  )}
                </select>
                </div>
              </div>
            </div>
            )
            :
            (
              <div className="col-8 d-flex justify-content-center align-items-center gap-2">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <input type="text" className="form-control" placeholder="code" style={{ width: '100px' }} onChange={(e) => handleCodeChange(e, 'codeVisitor')}/>
                <input type="text" className="form-control ms-2" placeholder="Equipo Visitante" onChange={handleInputChange} name="visitingTeam"/>
              </div>
            </div>
            
            )
            }
          </div>
         
        </div>
                     
         <div className="row p-0 m-0 d-flex justify-content-end">
                    { activeEditCode &&
                      <div className="row p-0 m-0 d-flex justify-content-end">
                      <div className="col-12 col-md-6 d-flex justify-content-center">
                        <button className= 'heartbeat'  style={{backgroundColor: '#dc3545', color: 'white', cursor: 'pointer', width: '150px', height: '40px', borderRadius: '5px', marginTop: '10px'}} onClick={()=>{updateCode()}}> Aceptar Cambios </button>
                      </div>
                    </div>
                    }
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <button className={activeEditCode ? 'heartbeat' : ''} style={buttonStyles} onClick={()=>{handleActiveCode()}}>{ activeEditCode ? "Desactivar Edicion" : "Editar Code" }</button>
          </div>
        </div> 
    


        <div className="row p-0 m-0 d-flex justify-content-end">
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <button className={showManual ? 'heartbeat' : ''} style={buttonStyles} onClick={()=>{handleShowManual()}}> {showManual ? 'Desactivar Manual' : 'Activar Manual'}</button>
          </div>
        </div>
        {/* <div className="row p-0 m-0 d-flex justify-content-center">
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <span className="col">Visitante</span>
            <span className="col text-center">
              Pitchers <br /> Jugadores
            </span>
            <span className="col text-center">Local</span>
          </div>
        </div> */}
        {ligas.map((liga, index) => {
          return liga == "baseball" ? (
            <div className="row p-0 m-0 d-flex justify-content-center" key={index}>
              <div className="col-6 col-md-3 d-flex justify-content-center flex-column">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <select className="mb-2 form-select text-center">
                    <option value="ninguno">Ninguno</option>
                  </select>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <select className="mb-2 form-select text-center" disabled>
                    <option value="ninguno">Ninguno</option>
                  </select>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <select className="mb-2 form-select text-center" disabled>
                    <option value="ninguno">Ninguno</option>
                  </select>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <select className="mb-2 form-select text-center" disabled>
                    <option value="ninguno">Ninguno</option>
                  </select>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <select className="mb-2 form-select text-center" disabled>
                    <option value="ninguno">Ninguno</option>
                  </select>
                </div>
              </div>
              <div className="col-6 col-md-3 d-flex justify-content-center flex-column">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <select className="mb-2 form-select text-center">
                    <option value="ninguno">Ninguno</option>
                  </select>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <select className="mb-2 form-select text-center" disabled>
                    <option value="ninguno">Ninguno</option>
                  </select>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <select className="mb-2 form-select text-center" disabled>
                    <option value="ninguno">Ninguno</option>
                  </select>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <select className="mb-2 form-select text-center" disabled>
                    <option value="ninguno">Ninguno</option>
                  </select>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <select className="mb-2 form-select text-center" disabled>
                    <option value="ninguno">Ninguno</option>
                  </select>
                </div>
              </div>
            </div>
          ) : null;
        })}
      </div>
    </Modal>
  );
};

export default HyLModalCrear;
