import React, { useState, useEffect } from 'react'
import { Container } from "react-bootstrap";
import Layout from "../../structures/layout";
import { WhatsAppSession } from "./WhatsAppSession";
import { WhatsAppNoSession } from "./WhatsAppNoSession";
import { redApi } from '../../../../config/Axios';
import { LoadingSpinner } from '../../../../utils/LoadingSpinner';

export const WhatsappSection = () => {
  const [session, setSession] = useState({loading: true, session: false});
  const fetchSession = async () => {
    try {
      const req = await redApi.get("/whatsapp/checksession");
      if (req.status === 200) {
        setSession({loading: false, session: true});
      } else {
        setSession({loading: false, session: false});
      }
    } catch (error) {
      setSession({loading: false, session: false});
      console.log(error);
    }
  }
  useEffect(() => fetchSession(), []);
  
  return (
      <Layout>
        <Container className="bg-card p-4">
          <div className="row">
            <div className="col-12 mb-4">
              <h2>WhatsApp</h2>
            </div>
            <div className="col-12 mb-2 d-flex justify-content-center">
              {
                session.loading
                ? <div className="mt-5"><LoadingSpinner color="#a0a0a0"/></div>
                : session.session
                 ? <WhatsAppSession setSession={setSession}/>
                 : <WhatsAppNoSession setSession={setSession}/>
              }
            </div>
          </div>
        </Container>
      </Layout>
  )
}
