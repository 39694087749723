import React from 'react'

const CancelTicket_t4_mobile = () => {
  return (
    <div className='menu_container'>
      <div className='top_container'>
        <div>
          <span>
            select a ticket to cancel
          </span>
        </div>
      </div>
      <div className='bottom_container'>
        <div className='tables_container'>
          <div role='alert' className='alert alert-danger' style={{ textAlign: 'center' }}>
            No records where found
          </div>
        </div>
      </div>
    </div>
  )
}

export default CancelTicket_t4_mobile