import React, {useState} from 'react'

import ModalGrupoCrear from './usuariosTablas/modalGrupoCrear'
import ModalUsuarios from './usuariosTablas/modalUsuarios'
import { useUsuarios } from '../../../../hooks/usersNGroups/useUsuarios';

const Usuarios = () => {
    const buttons = ['Crear', 'Editar', 'Eliminar', 'Refrescar'];
    const { states, setStates, methods } = useUsuarios();
    const {
        allConsorcios, 
        allgroups, 
        dataTable, 
        groupSelect, 
        modalCrear, 
        modalCrearGrupo, 
        modalEditar, 
        onLoad, 
        userSelected, 
    } = states;
    const {
        setGroupSelect, 
        setModalCrear, 
        setModalCrearGrupo, 
        setModalEditar, 
    } = setStates;
    const {
        getusuarios, 
        handleClick, 
        handleClickSelect, 
        handleUserSelected, 
    } = methods;

    const [expandedRows, setExpandedRows] = useState([]);
    const [expandir, setExpandir] = useState(false);
    const [hoveredRow, setHoveredRow] = useState(null);


    const handleToggleRow = (index) => {
      setExpandedRows((prevExpandedRows) => {
        if (prevExpandedRows.includes(index)) {
          return prevExpandedRows.filter((rowIndex) => rowIndex !== index);
        } else {
          return [...prevExpandedRows, index];
        }
      });
    };

    const handleExpandir = () => {
        setExpandir(!expandir)
    }

    const handleMouseEnter = (index) => {
        setHoveredRow(index);
      };
    
      const handleMouseLeave = () => {
        setHoveredRow(null);
      };
    return (
        <div className="container-fluid" id='usuariosContainer'>
            <div className="row gap-4">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="row gap-1">
                                {buttons.map((item, index) => {
                                    return (
                                        <div key={index} className="col-2 p-0 m-0">
                                            {
                                                item !== 'Crear' && <button className="btn btn-outline-primary" onClick={handleClick} style={{ fontSize: '12px' }}>{item}</button>
                                            }
                                            {
                                                item === 'Crear' && <div className="dropdown" >
                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{
                                                        fontSize: '12px'
                                                    }}>
                                                        Crear
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <button
                                                            value={'CrearUsuario'}
                                                            onClick={handleClick}
                                                            className="dropdown-item"
                                                            type="button"
                                                            style={{ fontSize: '12px' }}
                                                        >Crear Usuario</button>
                                                        <button
                                                            value={'CrearGrupo'}
                                                            onClick={handleClick}
                                                            className="dropdown-item"
                                                            type="button"
                                                            style={{ fontSize: '12px' }}
                                                        >Crear Grupo</button>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row d-flex align-items-center justify-content-end">
                                <div className="col-6 d-flex align-items-center justify-content-center gap-3">
                                    <label htmlFor="consorcio">Consorcio</label>
                                    <select className="form-select" id="consorcio">
                                        <option value="todos">Todos</option>
                                        {
                                            allConsorcios?.map((item, index) => {
                                                return (
                                                    <option key={index} value={item._id}>{item.username}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12" id='table_container'>
                    <table className="">
                        <thead>
                            <tr className="table-primary w-100">
                                <th scope="col">#</th>
                                <th scope="col">Grupo</th>
                                {/* <th scope="col">Usuario</th>
                                <th scope="col ">Nombre</th>
                                <th scope="col ">Consorcio</th> */}
                                <th scope="col ">Expandir</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                onLoad
                                ? (
                                    <tr>
                                        <td colSpan={6}>
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                :
                                    dataTable.map((item, index) => (
                                        <React.Fragment key={index}>
                                          {item.name && (
                                            <>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td
                                                  onDoubleClick={() => {
                                                    setGroupSelect(item);
                                                    setModalCrearGrupo(true);
                                                  }}
                                                  style={{cursor: 'pointer', color: hoveredRow === index ? '#cc2f48' : ''}}
                                                  onMouseEnter={() => handleMouseEnter(index)}
                                                  onMouseLeave={handleMouseLeave}
                                                >
                                                  {item.name}
                                                </td>
                                                <td onClick={() => handleToggleRow(index)} style={{  display:'flex', alignItems:'center', justifyContent:'center' }}>
                                                  <button className="button-expand" style={{backgroundColor:'#cc2f48'}} onClick={(e)=> handleExpandir()}>
                                                    {expandedRows.includes(index) ? (
                                                      <i className="fas fa-minus" style={{ color: 'white' }}></i>
                                                    ) : (
                                                      <i className="fas fa-plus" style={{ color: 'white' }}></i>
                                                    )}
                                                  </button>
                                                  {expandir ? "Minimizar" : "Expandir" }
                                                </td>
                                              </tr>
                                              {expandedRows.includes(index) && (
                                                <tr>
                                                  <td colSpan="3">
                                                    <table style={{}}>
                                                      <thead>
                                                        <tr>
                                                          <th>Usuario</th>
                                                          <th>Nombre</th>
                                                          <th>Consorcio</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {
                                                        item.users.length === 0 ? (
                                                            <tr>
                                                            <td colSpan={3}>
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                    <span >No hay datos</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        )
                                                        :
                                                        (
                                                            item.users?.map((it, ind) => (
                                                                    <tr key={ind}  className={userSelected?.username === it?.username ? "userSelected" : ""} style={{ cursor: 'pointer',marginTop: '25px', backgroundColor:userSelected?.username === it?.username ? '#dc3545' : ''}}  onClick={() => handleUserSelected(it)}>
                                                                        <td>{it?.username}</td>
                                                                        <td>{it?.name || 'N/A'}</td>
                                                                        <td>{it?.ownedBy?.username}</td>
                                                                    </tr>
                                                            ))
                                                        )
                                                        
                                                        }
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          )}
                                        </React.Fragment>
                                      ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {
                modalCrear ? <ModalUsuarios getusuarios={getusuarios} setModalCrear={setModalCrear} modalCrear={modalCrear} allgroups={allgroups} allConsorcios={allConsorcios} /> : null
            }
            {
                modalEditar ? <ModalUsuarios getusuarios={getusuarios} setModalEditar={setModalEditar} modalEditar={modalEditar} usuario={userSelected} allgroups={allgroups} allConsorcios={allConsorcios} /> : null
            }
            {
                modalCrearGrupo ? <ModalGrupoCrear groupSelect={groupSelect} getAllGroups={getusuarios} setModalCrearGrupo={setModalCrearGrupo} modalCrearGrupo={modalCrearGrupo} /> : null
            }
        </div>
    )
}

export default Usuarios