import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import Layout from "../../structures/webuser/webuserLayout";
import { redApi } from "../../../../config/Axios";
import Swal from "sweetalert2";
import { Printer } from "../../../printer/Print.js";
import { socket } from "../../../../socket";
import moment from "moment";
import validateBets from "../../../../utils/validateBets";
import { Modal } from "antd";
export default function WebuserClient({ setUserTheme }) {

    const params = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [gamesForUser, setGamesForUser] = useState(null);
    const [ticketNumber, setTicketNumber] = useState(0);
    const [selectedBet, setSelectedBet] = useState({
        teamCode: "",
        playType: "",
    });

    var isRunning = false;

    useEffect(async () => {
        setIsLoading(true);
        if (params.league !== "index") {
            try {
                await redApi
                    .get(
                        params.league == "WNBA-S" || params.league == "NBA-S" || params.league == "NFL-S" ? `/games?leagueName=${params.league}&date=${moment().format(
                            "YYYY-MM-DD"
                        )}&matchUps=${true}` : `/games?leagueName=${params.league}&date=${moment().format(
                            "YYYY-MM-DD"
                        )}`
                    )
                    .then((res) => {
                        console.log({ res });
                        // const games = res.data.filter(game => {
                        //     const gameDate = moment(game.time).format('DD MMMM YYYY');
                        //     let today = moment().format('DD MMMM YYYY');
                        //     return gameDate === today
                        // });
                        setGamesForUser(res.data);
                    })
                    .catch((res) => {
                        // navigate('/client/' + res.response.data.redirect)
                    });

                const response2 = await redApi.get("/tickets?lastId=true");
                setTicketNumber(response2.data.lastTicketId);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        } else {
            setIsLoading(false);
        }
    }, [params]);

    useEffect(async () => {
        socket.on("lineUpdate", async () => {
            await redApi.get(`/games?leagueName=${params.league}`).then((res) => {
                setGamesForUser(res.data);
            });
        });
        socket.on("gameStart", async () => {
            await redApi.get(`/games?leagueName=${params.league}`).then((res) => {
                setGamesForUser(res.data);
            });
        });
    }, []);

    // bets
    const [bets, setBets] = useState({
        ammount: 0,
        bets: [],
    });
    const [betToAdd, setBetToAdd] = useState({
        TeamCode: "",
        PlayType: "",
        Quantity: 0,
        IfWin: 0,
        Spread: "",
    });

    useEffect(() => {
        window.addEventListener("keydown", handleBetAddToTable);
        return () => {
            window.removeEventListener("keydown", handleBetAddToTable);
        };
    }, [betToAdd]);

    useEffect(() => {
        window.addEventListener("keydown", handleDeleteBet);
        return () => {
            window.removeEventListener("keydown", handleDeleteBet);
        };
    }, [selectedBet]);

    const handleDeleteBet = (e) => {
        if (e.key === "Delete" && selectedBet.teamCode !== "" && bets.bets.length > 0) {
            let newBets = bets.bets.filter(bet => {
                return bet.teamCode !== selectedBet.teamCode || bet.playType !== selectedBet.playType
            })
            setBets({
                ammount: 0,
                bets: newBets,
            });
            setSelectedBet({
                teamCode: "",
                playType: "",
            });

            document.querySelector("#ifWinInput").disabled = false;
            document.querySelector("#quantityInput").disabled = false;
            setTotalToWin(0);
        }
    };
    const [modalBuyPoints, setModalBuyPoints] = useState(false);

    const handleBetAddToTable = async (e) => {
        if (
            e.target.name === "PlayType" &&
            e.key === "Enter" &&
            betToAdd.TeamCode !== ""
        ) {
            let game = gamesForUser.find(
                (game) =>
                    game.localTeam.code == betToAdd.TeamCode || game.visitingTeam.code == betToAdd.TeamCode
            );

            if (!game) game = gamesForUser.find(
                (game) =>
                    game.localTeam.codeRD == betToAdd.TeamCode ||
                    game.visitingTeam.codeRD == betToAdd.TeamCode
            );

            if (game) {
                const localLine = game.localLine;
                const visitingLine = game.visitingLine;
                const line = betToAdd.TeamCode == game.localTeam.codeRD || betToAdd.TeamCode == game.localTeam.code ? localLine : visitingLine;

                let lineToCheck;
                let price;
                let spreadChanged = false
                function returnLine(lineR) {
                    if (line[lineR] === null) return null
                    else if (['j1', 'j2', 'j3', 'j4', 'j5',].includes(lineR) && line[lineR] !== null) {
                        lineToCheck = line[lineR].moneyLine
                        return line[lineR].moneyLine.value
                    }
                    lineToCheck = line[lineR]
                    return line[lineR].value
                }
                if (betToAdd.PlayType == '') {
                    betToAdd.PlayType = 'R'
                }
                switch (betToAdd.PlayType) {
                    case "M":
                        price = returnLine('moneyLine')
                        break;
                    case "R":
                        price = returnLine('price')
                        spreadChanged = true
                        betToAdd.Spread = line.gavela.value
                        break;
                    case "-":
                        price = returnLine('posMinus')
                        spreadChanged = true
                        betToAdd.Spread = visitingLine.total.value
                        break;
                    case "+":
                        price = returnLine('posPlus')
                        spreadChanged = true
                        betToAdd.Spread = localLine.total.value
                        break;
                    case "S+":
                        price = returnLine('singlePlus')
                        spreadChanged = true
                        betToAdd.Spread = line.single.value
                        break;
                    case "S-":
                        price = returnLine('singleMinus')
                        spreadChanged = true
                        betToAdd.Spread = line.single.value
                        break;
                    case "P":
                        price = returnLine('superRunLine')
                        spreadChanged = true
                        betToAdd.Spread = line.superGavela.value
                        break;
                    case "AR":
                        price = returnLine('altRunLine')
                        spreadChanged = true
                        betToAdd.Spread = line.altGavela.value
                        break;
                    case "H":
                        price = returnLine('h1Rl')
                        spreadChanged = true
                        betToAdd.Spread = line.h1RlGavela.value
                        break;
                    case "MH":
                        price = returnLine('h1')
                        break;
                    case "Y":
                        price = returnLine('sia')
                        break;
                    case "N":
                        price = returnLine('noa')
                        break;
                    case "F":
                        price = returnLine('firstRun')
                        break;
                    case "K+":
                        price = returnLine('strikeoutsPlus')
                        spreadChanged = true
                        betToAdd.Spread = line.strikeoutsGavela.value
                        break;
                    case "K-":
                        price = returnLine('strikeoutsMinus')
                        spreadChanged = true
                        betToAdd.Spread = line.strikeoutsGavela.value
                        break;
                    case "T":
                        price = returnLine('third')
                        break;
                    case "T+":
                        price = returnLine('moneyLineT3')
                        break;
                    case "T-":
                        price = returnLine('moneyLineT3')
                        break;
                    case "E":
                        price = returnLine('draw')
                        break;
                    case "Q1":
                        price = returnLine('RunLineQ1')
                        spreadChanged = true
                        betToAdd.Spread = line.RunLineQ1Gavela.value
                        break;
                    case "Q2":
                        price = returnLine('RunLineQ2')
                        spreadChanged = true
                        betToAdd.Spread = line.RunLineQ2Gavela.value
                        break;
                    case "Q3":
                        price = returnLine('RunLineQ3')
                        spreadChanged = true
                        betToAdd.Spread = line.RunLineQ3Gavela.value
                        break;
                    case "Q4":
                        price = returnLine('RunLineQ4')
                        spreadChanged = true
                        betToAdd.Spread = line.RunLineQ4Gavela.value
                        break;
                    case "Q1+":
                        price = returnLine('priceQ1Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ1Over.value
                        break;
                    case "Q2+":
                        price = returnLine('priceQ2Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ2Over.value
                        break;
                    case "Q3+":
                        price = returnLine('priceQ3Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ3Over.value
                        break;
                    case "Q4+":
                        price = returnLine('priceQ4Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ4Over.value
                        break;
                    case "Q1-":
                        price = returnLine('priceQ1Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ1Under.value
                        break;
                    case "Q2-":
                        price = returnLine('priceQ2Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ2Under.value
                        break;
                    case "Q3-":
                        price = returnLine('priceQ3Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ3Under.value
                        break;
                    case "Q4-":
                        price = returnLine('priceQ4Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ4Under.value
                        break;
                    case "Q1S+":
                        price = returnLine('priceQ1SOver')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ1S.value
                        break;
                    case "Q2S+":
                        price = returnLine('priceQ2SOver')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ2S.value
                        break;
                    case "Q3S+":
                        price = returnLine('priceQ3SOver')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ3S.value
                        break;
                    case "Q4S+":
                        price = returnLine('priceQ4SOver')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ4S.value
                        break;
                    case "Q1S-":
                        price = returnLine('priceQ1SUnder')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ1S.value
                        break;
                    case "Q2S-":
                        price = returnLine('priceQ2SUnder')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ2S.value
                        break;
                    case "Q3S-":
                        price = returnLine('priceQ3SUnder')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ3S.value
                        break;
                    case "Q4S-":
                        price = returnLine('priceQ4SUnder')
                        spreadChanged = true
                        betToAdd.Spread = line.totalQ4S.value
                        break;
                    case "T1":
                        price = returnLine('runLineT1')
                        spreadChanged = true
                        betToAdd.Spread = line.RLT1Gavela.value
                        break;
                    case "T2":
                        price = returnLine('runLineT2')
                        spreadChanged = true
                        betToAdd.Spread = line.RLT2Gavela.value
                        break;
                    case "T3":
                        price = returnLine('runLineT3')
                        spreadChanged = true
                        betToAdd.Spread = line.RLT3Gavela.value
                        break;
                    case "T1+":
                        price = returnLine('priceT1Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT1.value
                        break;
                    case "T2+":
                        price = returnLine('priceT2Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT2.value
                        break;
                    case "T3+":
                        price = returnLine('priceT3Over')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT2.value
                        break;
                    case "T1-":
                        price = returnLine('priceT1Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT1.value
                        break;
                    case "T2-":
                        price = returnLine('priceT2Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT2.value
                        break;
                    case "T3-":
                        price = returnLine('priceT3Under')
                        spreadChanged = true
                        betToAdd.Spread = line.totalT2.value
                        break;
                    case "MT1":
                        price = returnLine('moneyLineT1')
                        break;
                    case "MT2":
                        price = returnLine('moneyLineT2')
                        break;
                    case "MT3":
                        price = returnLine('moneyLineT3')
                        break;
                    case "HR2":
                        price = returnLine('moneyLineT3')
                        break;
                    case "H+":
                        spreadChanged = true
                        betToAdd.Spread = line.HGavela.value
                        price = returnLine('HOver')
                        break;
                    case "H-":
                        spreadChanged = true
                        betToAdd.Spread = line.HGavela.value
                        price = returnLine('HUnder')
                        break;
                    case "H2+":
                        spreadChanged = true
                        betToAdd.Spread = line.H2Gavela.value
                        price = returnLine('H2Over')
                        break;
                    case "H2-":
                        spreadChanged = true
                        betToAdd.Spread = line.H2Gavela.value
                        price = returnLine('H2Over')
                        break;
                    case "H1S+":
                        spreadChanged = true
                        betToAdd.Spread = line.HSGavela.value
                        price = returnLine('HSOver')
                        break;
                    case "H1S-":
                        spreadChanged = true
                        betToAdd.Spread = line.HSGavela.value
                        price = returnLine('HSUnder')
                        break;
                    case "H2S+":
                        spreadChanged = true
                        betToAdd.Spread = line.H2SGavela.value
                        price = returnLine('H2SOver')
                        break;
                    case "H2S-":
                        spreadChanged = true
                        betToAdd.Spread = line.H2SGavela.value
                        price = returnLine('H2SUnder')
                        break;
                    case "J1":
                        price = returnLine('j1')
                        if (price === undefined || price === null) {
                            return await Swal.fire({
                                title: "Error",
                                text: 'Esta línea no está disponible',
                                icon: "error",
                                timer: 3000,
                                showConfirmButton: false,
                            });
                        }
                        break;
                    case "J2":
                        price = returnLine('j2')
                        if (price === undefined || price === null) {
                            return await Swal.fire({
                                title: "Error",
                                text: 'Esta línea no está disponible',
                                icon: "error",
                                timer: 3000,
                                showConfirmButton: false,
                            });
                        }
                        break;
                    case "J3":
                        price = returnLine('j3')
                        if (price === undefined || price === null) {
                            return await Swal.fire({
                                title: "Error",
                                text: 'Esta línea no está disponible',
                                icon: "error",
                                timer: 3000,
                                showConfirmButton: false,
                            });
                        }
                        break;
                    case "J4":
                        price = returnLine('j4')
                        if (price === undefined || price === null) {
                            return await Swal.fire({
                                title: "Error",
                                text: 'Esta línea no está disponible',
                                icon: "error",
                                timer: 3000,
                                showConfirmButton: false,
                            });
                        }
                        break;
                    case "J5":
                        price = returnLine('j5')
                        if (price === undefined || price === null) {
                            return await Swal.fire({
                                title: "Error",
                                text: 'Esta línea no está disponible',
                                icon: "error",
                                timer: 3000,
                                showConfirmButton: false,
                            });
                        }
                        break;
                    case "C":
                        setModalBuyPoints(true)
                        return
                    case "C+":
                        setModalBuyPoints(true)
                        return
                    case "C-":
                        setModalBuyPoints(true)
                        return
                    case "":
                        price = returnLine('moneyLine')
                        break;
                    default:
                        Swal.fire({
                            title: "Error",
                            text: 'Este tipo de jugada no existe',
                            icon: "error",
                            timer: 3000,
                            showConfirmButton: false,
                        });
                        return;
                }

                if (lineToCheck && lineToCheck.status !== "approved") {
                    Swal.fire({
                        title: "Error",
                        text: 'Esta línea no está aprobada',
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
                }

                if (spreadChanged == true && betToAdd.Spread == "") {
                    Swal.fire({
                        title: "Error",
                        text: 'Este línea requiere un spread',
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
                }

                const { valid, message } = validateBets(bets.bets, betToAdd, game, game.player ? true : false);



                if (!valid) {
                    Swal.fire({
                        title: "Error",
                        text: message,
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
                }
                if (price === "" || price === "~") {
                    Swal.fire({
                        title: "Error",
                        text: `La apuesta ${betToAdd.PlayType} no tiene precio`,
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
                }
                if (price === "Bet not found") {
                    Swal.fire({
                        title: "Error",
                        text: "Por el momento no se puede apostar a este juego 1",
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
                }

                if (bets.bets.length > 0) {
                    await redApi.get('/verify').then(res => {
                        let multPlays = res.data.multPlays;
                        if (multPlays) {
                            bets.bets.forEach(bet => {
                                if (bet.Price === '-110' || bet.Price === -110)
                                    bet.Price = multPlays;
                            });
                            if (price === '-110' || price === -110) {
                                price = multPlays;
                            }
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                }

                const TeamName = betToAdd.TeamCode == game.localTeam.code || betToAdd.TeamCode == game.localTeam.codeRD ? game.localTeam.name : game.visitingTeam.name;
                const newBet = {
                    teamCode: betToAdd.TeamCode,
                    playType: betToAdd.PlayType === "" ? "M" : betToAdd.PlayType,
                    Spread: betToAdd.Spread,
                    Price: price,
                    TeamName: TeamName,
                    line: line._id,
                    game: game._id,
                };

                setBets({
                    ammount: parseInt(bets.ammount) + parseInt(betToAdd.Quantity),
                    bets: [...bets.bets, newBet],
                });

                setBetToAdd({
                    TeamCode: "",
                    PlayType: "",
                    Quantity: 0,
                    IfWin: 0,
                });

                // document.getElementById('quantityInput').focus();
                document.querySelector("#TeamCode").focus();
            } else {
                console.log("no game found");
            }
        }
    };

    const [printTicket, setPrintTicket] = useState({
        ticketId: 0,
        print: false,
    });

    const handleBetPost = async (e) => {
        if (e.key === "*" && !isRunning) {
            try {
                isRunning = true;
                let ticket = {
                    amount: bets.ammount,
                    bets: bets.bets.map((bet) => {
                        return {
                            teamCode: bet.teamCode,
                            playType: bet.playType,
                            game: bet.game,
                            line: bet.line,
                        };
                    }),
                    totalToWin,
                };
                await redApi.post("/tickets", ticket).then((res) => {
                    setBets([]);
                    setPrintTicket({
                        ticketId: res.data.ticketId,
                        print: true,
                    });
                    setTotalToWin(0);
                    setTicketNumber(ticketNumber + 1);
                    setBets({
                        ammount: 0,
                        bets: [],
                    });
                    let quantityInput = document.querySelector("#quantityInput");
                    let ifWinInput = document.querySelector("#ifWinInput");
                    quantityInput.value = "";
                    ifWinInput.disabled = false;
                    quantityInput.disabled = false;
                    setBetToAdd({
                        TeamCode: "",
                        PlayType: "",
                        Quantity: 0,
                        IfWin: 0,
                    });
                    isRunning = false;
                });
            } catch (error) {
                console.log(error);
                isRunning = true;
                Swal.fire({
                    title: "Error",
                    text: "Ha ocurrido un error al realizar tu apuesta",
                    html: `<p>Error: ${error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data[0]?.msg ? "Monto inválido" : "Error desconocido, por favor comunicarse con soporte técnico"}</p>`,
                    icon: "error",
                    showConfirmButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#d33",
                });
            }
        }
    };
    const handleBetAddToTable2 = async (e) => {

        let game = gamesForUser.find(
            (game) =>
                game.localTeam.codeRD == betToAdd.TeamCode ||
                game.visitingTeam.codeRD == betToAdd.TeamCode
        );

        if (game) {
            const localLine = game.localLine;
            const visitingLine = game.visitingLine;
            const line =
                betToAdd.TeamCode == game.localTeam.codeRD ? localLine : visitingLine;

            let error;
            let price;
            let spreadChanged = false
            let points = Math.abs(e.target.value);

            switch (betToAdd.PlayType) {
                case "C":

                    await redApi.get(`/linesControlPlayType?playType=C&points=${points}&league=${game.league._id}&polarity=${line.isBully ? 'MACHO' : 'HEMBRA'}&lineId=${line._id}`).then(res => {
                        price = res.data.price || res.data.price
                    }).catch(err => {
                        console.log(err);
                        error = err.response.data.message
                    })

                    break;
                case "C+":
                    await redApi.get(`/linesControlPlayType?playType=CPlus&points=${points}&league=${game.league._id}&polarity=${line.isBully ? 'MACHO' : 'HEMBRA'}&lineId=${line._id}`).then(res => {
                        price = res.data.price || res.data.price
                    }).catch(err => {
                        console.log(err);
                        error = err.response.data.message
                    })

                    break;
                case "C-":
                    await redApi.get(`/linesControlPlayType?playType=CMinus&points=${points}&league=${game.league._id}&polarity=${line.isBully ? 'MACHO' : 'HEMBRA'}&lineId=${line._id}`).then(res => {
                        price = res.data.price || res.data.price
                    }).catch(err => {
                        console.log(err);
                        error = err.response.data.message
                    })
                    break;

                default:
                    Swal.fire({
                        title: "Error",
                        text: 'Este tipo de jugada no está habilitada',
                        icon: "error",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                    return;
            }


            if (error) {
                Swal.fire({
                    title: "Error",
                    text: error,
                    icon: "error",
                    timer: 3000,
                    showConfirmButton: false,
                });
                return;
            }

            if (!price || price === "~") {
                Swal.fire({
                    title: "Error",
                    text: `La apuesta ${betToAdd.PlayType} no tiene precio`,
                    icon: "error",
                    timer: 3000,
                    showConfirmButton: false,
                });
                return;
            }
            if (price === "Bet not found") {
                Swal.fire({
                    title: "Error",
                    text: "Por el momento no se puede apostar a este juego 1",
                    icon: "error",
                    timer: 3000,
                    showConfirmButton: false,
                });
                return;
            }

            if (bets.bets.length > 0) {
                await redApi.get('/verify').then(res => {
                    let multPlays = res.data.multPlays;
                    if (multPlays) {
                        bets.bets.forEach(bet => {
                            if (bet.Price === '-110' || bet.Price === -110)
                                bet.Price = multPlays;
                        });
                        if (price === '-110' || price === -110) {
                            price = multPlays;
                        }
                    }
                }).catch(err => {
                    console.log(err);
                })
            }

            const TeamName =
                betToAdd.TeamCode == game.localTeam.codeRD
                    ? game.localTeam.name
                    : game.visitingTeam.name;
            const newBet = {
                teamCode: betToAdd.TeamCode,
                playType: betToAdd.PlayType === "" ? "M" : betToAdd.PlayType,
                Spread: parseFloat(line.gavela.value) + parseFloat(points),
                Price: price,
                TeamName: TeamName,
                line: line._id,
                game: game._id,
            };

            setBets({
                ammount: parseInt(bets.ammount) + parseInt(betToAdd.Quantity),
                bets: [...bets.bets, newBet],
            });

            setBetToAdd({
                TeamCode: "",
                PlayType: "",
                Quantity: 0,
                IfWin: 0,
            });

            // document.getElementById('quantityInput').focus();
            document.querySelector("#TeamCode").focus();
        } else {
        }

    }
    useEffect(() => {
        window.addEventListener("keydown", handleBetPost);
        return () => {
            window.removeEventListener("keydown", handleBetPost);
        };
    }, [bets]);
    const [inputBuyPoints, setInputBuyPoints] = useState(document.querySelector("#inputBuyPoints") ? document.querySelector("#inputBuyPoints").value : 0);

    const [totalToWin, setTotalToWin] = useState(0);
    const handlemodalBuyPoints = () => {
        setModalBuyPoints(!modalBuyPoints);
    }
    const handleInputBuyPoints = (e) => {
        if (e.target.name === 'buyPoints') {
            setInputBuyPoints(e.target.value);
        }
        switch (e.target.value) {
            case "Enter":
                handleBetAddToTable2({ target: { name: "buyPoints", value: inputBuyPoints } })
                handlemodalBuyPoints()
                setInputBuyPoints("")
                document.querySelector("#inputBuyPoints").value = ""
                break
            default:
                let inptByPoints = document.querySelector("#inputBuyPoints");
                setInputBuyPoints(inptByPoints.value += e.target.value);
        }
    }

    return (
        <Layout
            bets={bets}
            setBets={setBets}
            betToAdd={betToAdd}
            setBetToAdd={setBetToAdd}
            gamesForUser={gamesForUser}
            setGamesForUser={setGamesForUser}
            isLoading={isLoading}
            setUserTheme={setUserTheme}
            totalToWin={totalToWin}
            setTotalToWin={setTotalToWin}
        >
            {printTicket.print ? (
                <Printer
                    ticketId={printTicket.ticketId}
                    printTicket={printTicket}
                    setPrintTicket={setPrintTicket}
                />
            ) : null}
            <Modal
                open={modalBuyPoints}
                onClose={() => handlemodalBuyPoints()}
                footer={null}
            >
                <div className="w-100 d-flex flex-column justify-content-center align-items-center py-4c gap-2 mb-4">
                    <span
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        Cuantos puntos desea comprar o vender?
                    </span>
                    <input type="text" className="form-control text-center" id="inputBuyPoints" name="buyPoints" onChange={handleInputBuyPoints} />
                </div>
                <div className="parent">
                    <div className="div1">
                        {/* <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'NumLock'} disabled>NumLock</button> */}
                    </div>
                    <div className="div2">
                        {/* <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'/'} disabled>/</button> */}
                    </div>
                    <div className="div3">
                        {/* <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'*'} disabled>*</button> */}
                    </div>
                    <div className="div4">
                        {/* <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'-'} disabled>-</button> */}
                    </div>
                    <div className="div5">
                        <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={7}>7</button>
                    </div>
                    <div className="div6">
                        <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={8}>8</button>
                    </div>
                    <div className="div7">
                        <button className="btn btn-primary w-100 w-100" onClick={handleInputBuyPoints} value={9}>9</button>
                    </div>
                    <div className="div8">
                        {/* <button className="btn btn-primary w-100 w-100" onClick={handleInputBuyPoints} value={'+'} disabled>+</button> */}
                    </div>
                    <div className="div9">
                        <button className="btn btn-primary w-100 w-100" onClick={handleInputBuyPoints} value={4}>4</button>
                    </div>
                    <div className="div10">
                        <button className="btn btn-primary w-100 w-100" onClick={handleInputBuyPoints} value={5}>5</button>
                    </div>
                    <div className="div11">
                        <button className="btn btn-primary w-100 w-100" onClick={handleInputBuyPoints} value={6}>6</button>
                    </div>
                    <div className="div12">
                        <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={1}>1</button>
                    </div>
                    <div className="div13">
                        <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={2}>2</button>
                    </div>
                    <div className="div14">
                        <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={3}>3</button>
                    </div>
                    <div className="div15">
                        <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'Enter'}>Enter</button>
                    </div>
                    <div className="div16">
                        <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={0}>0</button>
                    </div>
                    <div className="div17">
                        <button className="btn btn-primary w-100" onClick={handleInputBuyPoints} value={'.'}>.</button>
                    </div>
                </div>
            </Modal>
            <div className="container-fluid d-flex flex-column justify-content-center align-items-center h-100 bg-card px-2">
                <div className="row w-100 d-flex flex-row justify-content-center align-items-center">
                    <div className="col d-flex flex-row justify-content-center align-items-center gap-1">
                        <input type="radio" name="Normal" />
                        <label htmlFor="Normal">Normal</label>
                    </div>
                    <div className="col-6 d-flex flex-row justify-content-center align-items-center border-top border-bottom border-1 border-dark py-1">
                        <span className="font-weight-bold">SportsCom</span>
                    </div>
                    <div className="col d-flex flex-row justify-content-center align-items-center gap-1">
                        <input type="radio" name="Teaser" />
                        <label htmlFor="Teaser">Teaser</label>
                    </div>
                </div>
                <div className="row w-100 d-flex flex-row justify-content-center align-items-center bg-white mt-2 p-2 ">
                    <span className="col font-weight-bold d-flex flex-row justify-content-center align-items-center text-dark">
                        Ticket #{ticketNumber ? ticketNumber + 1 : 1}
                    </span>
                </div>
                <div className="" id="table_bets_render_container">
                    <table className="table table-striped table-hover table-bordered table-sm">
                        <thead>
                            <tr>
                                <th scope="col" className="text-center">
                                    Code
                                </th>
                                <th scope="col" className="text-center">
                                    Team
                                </th>
                                <th scope="col" className="text-center">
                                    Type
                                </th>
                                <th scope="col" className="text-center">
                                    Spread
                                </th>
                                <th scope="col" className="text-center">
                                    Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                                <td className="text-center">2026</td>
                                <td className="text-center">
                                    Cleveland Cavaliers vs Philadelphia 76ers
                                </td>
                                <td className="text-center">OVER Q1</td>
                                <td className="text-center">56.0</td>
                                <td className="text-center">-110</td>
                            </tr> */}
                            {bets.bets?.map((bet, index) => {
                                return (
                                    <tr
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setSelectedBet({
                                                teamCode: bet.teamCode,
                                                playType: bet.playType,
                                            });
                                        }}
                                        key={index}
                                        className={
                                            "tr_table_bets " +
                                            (selectedBet.teamCode === bet.teamCode && selectedBet.playType === bet.playType ? "bet-selected" : "")
                                        }
                                    >
                                        <td className="text-center">{bet.teamCode}</td>
                                        <td className="text-center">{bet.TeamName}</td>
                                        <td className="text-center">{bet.playType}</td>
                                        <td className="text-center">{bet.Spread}</td>
                                        <td className="text-center">{bet.Price}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    );
}
