import React from 'react'

function Baseball() {
  return (
    <div className="rules-sport">
      <h1>Baseball</h1>
      <div>
        <ul>
          <li>
            All bets on plus (+) or less (-) will be valid when the losing team has exhausted its ninth (9th) Inning. If the game is tied, it must have played a minimum of nine complete innings to be valid.
          </li>
          <li>
            All bets on the run line (-1.5) and money line (J.C.) will be valid when the losing team has hit their fifth (5th) inning. If the match is tied, a minimum of five complete innings must have been played to be valid.
          </li>
          <li>
            All bets on play at halftime will be valid when the losing team has hit their fifth (5th) inning. If the match is tied, a minimum of five complete innings must have been played to be valid.
          </li>
          <li>
            Plays plus (+) or minus (-) at half time will be valid when five complete innings have been played.
          </li>
          <li>
            All individual team race bets (solo) will be valid when the losing team has batted its ninth (9th) inning. If the match is tied, a minimum of nine complete innings must have been played to be valid (extra inning races are valid for more (+) or less (-) listing purposes).
          </li>
          <li>
            The first race (PA) bets will be valid to the team that scores first, not to the full inning and it does not matter the number of innings played for this modality (the game does not necessarily have to be official).
          </li>
          <li>
            The strikeout plays will be valid when the game is official, that is, the team that is losing has batted its fifth (5th) inning.
          </li>
          <li>
            Proposals for decisions (yes or no) will be valid when the full inning has been exhausted.
          </li>
          <li>
            Las apuestas a bases recorridas serán válidas cuando el equipo que esté perdiendo haya bateado su quinta (5ta.) entrada.
          </li>
        </ul>
        <div>
          <h2>
            Rules for Betting with Baseball Propositions
          </h2>
          <ul>
            <li>
              Base bets run are calculated as follows:
            </li>
          </ul>
          <div className='rules-sport__tabledivs'>
            <div>
              <span>
                Hit
              </span>
              <span>
                1 base
              </span>
            </div>
            <div>
              <span>
                Double
              </span>
              <span>
                2 bases
              </span>
            </div>
            <div>
              <span>
                Triple
              </span>
              <span>
                3 bases
              </span>
            </div>
            <div>
              <span>
                Home run
              </span>
              <span>
                4 bases
              </span>
            </div>
          </div>
          <div className='rules-sport__span'>
            <span className='rules-sport__tabledivs__span'>
              <strong>Note: </strong>
              Bases reached by another player’s bat are not valid for betting purposes, such as (bases per ball, deadball or hit by pitch, stolen bases, step and run, error, force out or any other move that motivates advancing one or more bases).
            </span>
          </div>
          <div className='rules-sport__list'>
            <ul>
              <li>
                In propositions where there are two players, both must start the game and exhaust at least one full turn. Otherwise, they are suspended.
              </li>
              <li>
                In propositions where there is only one player, the player must start the game and exhaust at least one complete turn. Otherwise, they are suspended.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Baseball