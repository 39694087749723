import { redApi } from "../../../../config/Axios"

export const sendlimitLine = async (consorcio) => {
    try {
        const resp = await redApi.get(consorcio ? `/linesLimits?consort=${consorcio}` : '/linesLimits')
        // console.log("resp : ", resp)
        return resp
    } catch (error) {
        console.log("Error: ", error)
        throw error
    }
}
export const sendNewLine = async (data) => {
    try {
        const resp = await redApi.post('/linesLimits', data)
        // console.log("resp : ", resp)
        return resp
    } catch (error) {
        console.log("Error: ", error)
        throw error
    }
}