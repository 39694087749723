import { useCallback, useContext, useEffect, useState } from 'react';
import Theme4_menu from '../../navigation/theme4/desktop/theme4_menu';
import Theme4_leagues from '../../navigation/theme4/desktop/theme4_leagues';
import Theme4_addbets from '../../navigation/theme4/desktop/theme4_addbets';
import Theme4_games from '../../navigation/theme4/desktop/theme4_games';
import './style/theme4_layout.scss'
import Swal from 'sweetalert2';
import { MenuOutlined, UnorderedListOutlined } from '@ant-design/icons';
import Theme4_menu_mobile from '../../navigation/theme4/mobile/theme4_menu_mobile';
import Theme4_addbets_mobile from '../../navigation/theme4/mobile/theme4_addbets_mobile';
import { Modal, Switch } from 'antd';
import Theme4_leagues_mobile from '../../navigation/theme4/mobile/theme4_leagues_mobile';
// import TeamsMobile from '../../navigation/theme3/Legacy_teamsMobile';
import Theme4_teams_mobile from '../../navigation/theme4/mobile/theme4_teams_mobile';
// import MobileKeyboard from '../../navigation/theme3/MobileKeyboard';
import Theme4_Keyboard from '../../navigation/theme4/mobile/theme4_Keyboard';
import Sales_t4_mobile from '../../navigation/theme4/mobile/menu/Sales_t4_mobile';
import HistoricSales_t4_mobile from '../../navigation/theme4/mobile/menu/HistoricSales_t4_mobile';
import Monitoring_t4_mobile from '../../navigation/theme4/mobile/menu/Monitoring_t4_mobile';
import PendingOfPayment_t4_mobile from '../../navigation/theme4/mobile/menu/PendingOfPayment_t4_mobile';
import CancelTicket_t4_mobile from '../../navigation/theme4/mobile/menu/CancelTicket_t4_mobile';
import CopyTicket_t4_mobile from '../../navigation/theme4/mobile/menu/CopyTicket_t4_mobile';
import MoneyTransactionsConfirmation_t4_mobile from '../../navigation/theme4/mobile/menu/MoneyTransactionsConfirmation_t4_mobile';
import OnlineAccounts_t4_mobile from '../../navigation/theme4/mobile/menu/OnlineAccounts_t4_mobile';
import ShowOdds_t4_mobile from '../../navigation/theme4/mobile/menu/ShowOdds_t4_mobile';
import { redApi } from '../../../../config/Axios';
import { AuthContext } from '../../../../auth';
import TicketPayout_t4_mobile from '../../navigation/theme4/mobile/menu/TicketPayout_t4_mobile';


const Theme4_layout = ({
    children, bets, setBets, betToAdd, setBetToAdd, gamesForUser, isLoading, totalToWin, setTotalToWin, totalToWinIF, setTotalToWinIF, handleBetPost, handleBetPostEnter, setMobile, mobile, handleBetAddToTable, setIfwinActive, ifwinActive, betsIf, setBetsIf, clearBets, toggleKeyboard, setOnlyKyboard, onlyKyboard, filteredGames, handleFilterTeam, filterTeam
}) => {
    const { logout, userTheme: themeofuser } = useContext(AuthContext);

    const handleChangeBetToAdd = (e) => {
        if (e.target.name === 'Quantity') {
            setBets({
                ...bets,
                ammount: (e.target.value).toUpperCase()
            });
        } else if (e.target.name === 'IfWin') {
            setBets({
                ...bets,
                IfWinQuantity: parseInt(e.target.value)
            });
        }
        setBetToAdd({
            ...betToAdd,
            [e.target.name]: (e.target.value).toUpperCase()
        });

        if (e.target.name === 'TeamCode' && e.target.value.length === 4) {
            document.querySelector('#betTypeSelect').focus();
        }
    }

    function getToWin(bets, amount) {
        let result = 0;
        for (let i = 0; i < bets?.length; i++) {

            // if (bets[i].price.toString().includes('+') && bets[i].price.toString().includes('-')) {
            // }
            const multiplier = bets[i].price >= 0
                ? bets[i].price / 100
                : 1 / ((bets[i].price * -1) / 100);
            result = Math.round((result + amount) * multiplier + result);
        }
        result = Math.round(result);
        return result
    }

    useEffect(() => {
        if (mobile) return;
        if (bets.bets?.length === 0) {
            setTotalToWin(0);
            document.querySelector('#t_aganar').value = 0;
            document.querySelector('#monto_winbet').value = 0;
            return;
        }
        if (bets.bets?.length > 0 && parseInt(bets.ammount) > 0) {
            let betsTowin = bets.bets?.map((bet) => {
                let price = bet.Price.toString().slice(-4);
                return {
                    price: price.toString().includes('−') ? parseInt(price.toString().replace('−', '-')) : parseInt(price),
                }
            })
            if (ifwinActive) {
                let betsIf = bets.betsIf;
                let towin;
                let towinIF;

                if (betsIf < bets.bets.length) {
                    towinIF = getToWin(betsTowin.slice(betsIf, betsTowin.length), parseInt(bets.IfWinQuantity));
                    setTotalToWinIF(towinIF);
                    document.querySelector('#t_aganar_if').value = towinIF;
                    document.querySelector('#monto_winif').value = towinIF + parseInt(bets.IfWinQuantity);
                    towin = getToWin(betsTowin.slice(0, betsIf), parseInt(bets.ammount));
                    setTotalToWin(towin);
                    document.querySelector('#t_aganar').value = towin;
                    document.querySelector('#monto_winbet').value = towin + parseInt(bets.ammount);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'La cantidad de jugadas a ganar no puede ser mayor a la cantidad de jugadas',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setBets({
                        ...bets,
                        IfWinQuantity: 0
                    })
                    document.querySelector('#ifWinInput').value = 0;
                }
            } else {
                const toWin = getToWin(betsTowin, parseInt(bets.ammount));
                document.querySelector('#t_aganar').value = toWin;
                document.querySelector('#monto_winbet').value = toWin + parseInt(bets.ammount);
            }
        }
    }, [bets])

    useEffect(() => {
        if (!mobile) return;
        if (bets.bets?.length === 0) {
            setTotalToWin(0);
            setTotalToWinIF(0);
            document.querySelector('#t_aganar') && (document.querySelector('#t_aganar').value = 0);
            document.querySelector('#monto_winbet') && (document.querySelector('#monto_winbet').value = 0);
            return;
        }
        if (bets.bets?.length > 0 && parseInt(bets.ammount) > 0) {
            let betsTowin = bets.bets?.map((bet) => {
                let price = bet.Price.toString().slice(-4);
                return {
                    price: price
                }
            })
            if (ifwinActive) {
                let betsIf = bets.betsIf;
                let towin;
                let towinIF;

                if (betsIf < bets.bets.length) {
                    towinIF = getToWin(betsTowin.slice(betsIf, betsTowin.length), parseInt(bets.IfWinQuantity));
                    setTotalToWinIF(towinIF);
                    document.querySelector('#t_aganar_if').value = towinIF;
                    document.querySelector('#monto_winif').value = towinIF + parseInt(bets.IfWinQuantity);
                    towin = getToWin(betsTowin.slice(0, betsIf), parseInt(bets.ammount));
                    setTotalToWin(towin);
                    document.querySelector('#t_aganar').value = towin;
                    document.querySelector('#monto_winbet').value = towin + parseInt(bets.ammount);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'La cantidad de jugadas a ganar no puede ser mayor a la cantidad de jugadas',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setBets({
                        ...bets,
                        IfWinQuantity: 0
                    })
                    document.querySelector('#ifWinInput').value = 0;
                }
            } else {
                const toWin = getToWin(betsTowin, parseInt(bets.ammount));
                document.querySelector('#t_aganar').value = toWin;
                document.querySelector('#monto_winbet').value = toWin + parseInt(bets.ammount);
            }
        }
    }, [bets])

    // ************  menu mobile  ************
    const [menuOpen, setMenuOpen] = useState(false);

    const handleOpenMenu = () => {
        let menu_mobile = document.querySelector('#menu_mobile');
        if (menuOpen) {
            menu_mobile.classList.remove('open');
            menu_mobile.classList.add('close');
            setMenuOpen(false);
        } else {
            menu_mobile.classList.remove('close');
            menu_mobile.classList.add('open');
            setMenuOpen(true);
        }
    }

    const [menuMobile, setMenuMobile] = useState('HOME');

    const handleChangeMenuMobile = (item) => {
        setMenuMobile(item);
    }
    const [userTheme, setUserTheme] = useState(1);
    const handleChangeTheme = (e) => {
        setUserTheme(e.target.value);
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    }
    const handleOk = async () => {
        setIsModalVisible(false);
        await redApi.get("/verify").then(async (res) => {
            if (res.data) {
                if (res.data._id) {
                    await redApi
                        .put(`/users/${res.data._id}`, { theme: userTheme })
                        .then((res) => {
                            Swal.fire({
                                icon: "success",
                                title: "Theme changed",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                            window.location.reload();
                        });
                }
            }
        });
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };



    const renderMenu = () => {
        switch (menuMobile) {
            case 'Sales':
                return <Sales_t4_mobile />
            case 'Historic Sales':
                return <HistoricSales_t4_mobile />
            case 'Monitoring':
                return <Monitoring_t4_mobile />
            case 'Pending Of Payment':
                return <PendingOfPayment_t4_mobile />
            case 'Cancel ticket':
                return <CancelTicket_t4_mobile />
            case 'Ticket payout':
                return <TicketPayout_t4_mobile />
            case 'Copy ticket':
                return <CopyTicket_t4_mobile />
            case 'Money transactions confirmation':
                return <MoneyTransactionsConfirmation_t4_mobile />
            case 'Online accounts':
                return <OnlineAccounts_t4_mobile />
            case 'Show odds':
                return <ShowOdds_t4_mobile gamesForUser={gamesForUser} />
            case 'Change theme':
                showModal();
                return;
            default:
                break;
        }
    }

    // **************************
    const saveBet = () => {
        let event = new KeyboardEvent('keydown', { key: 'Enter' });
        if (bets.bets.length < 1) {
            Swal.fire({
                icon: 'error',
                title: 'No has agregado ninguna jugada',
                timer: 3500
            })
            return;
        }
        handleBetPostEnter(event);
    }

    const [printActive, setPrintActive] = useState(true);

    const handleChangePrint = () => {
        setPrintActive(!printActive);
    }

    const [menuActive, setMenuActive] = useState('HOME');
    const [onlyNumbers, setOnlyNumbers] = useState(false)

    const handleEnterKey = useCallback((e) => {
        if (e.key === 'Enter' && bets.bets.length > 0) {
            let ifWinInput = document.querySelector('#ifWinInput');
            if (parseInt(ifWinInput.value) > 0) {
                let ifWinContainer = document.querySelector('#ifWinContainer');
                // console.log({ ifWinContainer });
                if (ifWinContainer.children.length === 1) {
                    let newInput = document.createElement('input');
                    newInput.type = 'number';
                    newInput.className = 'form-control col';
                    newInput.name = 'IfWinQuantity';
                    newInput.id = 'IfWinQuantity';
                    ifWinContainer.appendChild(newInput);
                    newInput.focus();
                    setIfWinQuantity(newInput);
                    return;
                } else {
                    document.querySelector('#IfWinQuantity').focus();
                    return;
                }
            }
            let quantityInput = document.querySelector('#quantityInput');
            let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
            if (parseInt(ammountBet) > 0) {
                let betsTowin = bets.bets.map((bet) => {
                    let price = bet.Price.toString().slice(-4);
                    return {
                        price: price
                    }
                })

                ifWinInput.disabled = true;
                quantityInput.disabled = true;

                let totalToWin = getToWin(betsTowin, parseInt(ammountBet));
                setTotalToWin(totalToWin);
            }

            setBets({
                ...bets,
                ammount: parseInt(ammountBet),
                readyToSubmit: true
            })
            setBetToAdd({
                TeamCode: '',
                PlayType: '',
                Quantity: 0,
                IfWin: 0
            })

        }
    }, [bets]);
    const handleIfWinChange = (e) => {
        if (e.key === 'Enter') {
            // e.target.disabled = true;
            let ifWinInput = document.querySelector('#ifWinInput');
            // if (!parseInt(ifWinInput.value) > 0) return;
            let quantityInput = document.querySelector('#quantityInput');
            let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
            if (parseInt(ammountBet) > 0) {
                let betsTowin = bets.bets.map((bet) => {
                    let price = bet.Price.toString();
                    return {
                        price: price
                    }
                })
                let betsTowinIF = bets.bets.map((bet) => {
                    let price = bet.Price.toString();
                    return {
                        price: price
                    }
                })
                // ifWinInput.disabled = true;
                // quantityInput.disabled = true;

                let totalToWiniF = getToWin(betsTowinIF.slice(0, betsIf.length), parseInt(ifWinInput.value));
                let totalToWin = getToWin(betsTowin.slice(betsIf.length, betsTowin.length), parseInt(ammountBet));
                // console.log({ totalToWin });
                // console.log({ totalToWiniF });
                document.querySelector('#amount_win').value = `$${totalToWin.toFixed(2)}`;
                document.querySelector('#amount_if').value = `$${totalToWiniF.toFixed(2)}`;
                setTotalToWin(totalToWin);
                setTotalToWinIF(totalToWiniF);
            }

            setBets({
                ...bets,
                ammount: parseInt(ammountBet),
                // betsIf: parseInt(e.target.value),
                IfWinQuantity: parseInt(ifWinInput.value),
                readyToSubmit: true
            })

            // console.log({
            //     ...bets,
            //     ammount: parseInt(ammountBet),
            //     betsIf: parseInt(betsIf.length),
            //     IfWinQuantity: parseInt(ifWinInput.value),
            //     readyToSubmit: true
            // }, `{
            //     ...bets,
            //     ammount: parseInt(ammountBet),
            //     // betsIf: parseInt(e.target.value),
            //     IfWinQuantity: parseInt(ifWinInput.value),
            //     readyToSubmit: true
            // }`);

            setBetToAdd({
                TeamCode: '',
                PlayType: '',
                Quantity: 0,
                IfWin: 0
            })
            // e.target.value = '';

        }
    }

    return (
        <>

            {
                mobile
                    ? <section id="LayoutClient4" className="main-sports4 mobile">
                        <Modal
                            title="Change Theme"
                            open={isModalVisible}
                            onCancel={handleCancel}
                            width={"30%"}
                            onOk={handleOk}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center py-2">
                                <i className="fas fa-adjust px-1 h1"></i>
                            </div>
                            <div className="row d-flex flex-column justify-content-center align-items-center">
                                <select
                                    defaultValue={themeofuser || 0}
                                    className="form-select col-6"
                                    aria-label="Default select example"
                                    onChange={handleChangeTheme}
                                >
                                    <option disabled value={0}>Choose theme</option>
                                    <option value="1" selected={themeofuser === 1} disabled={themeofuser === 1}>Legacy</option>
                                    <option value="2" selected={themeofuser === 1} disabled={themeofuser === 2}>Red</option>
                                    <option value="3" selected={themeofuser === 1} disabled={themeofuser === 3}>Azul</option>
                                    <option value="4" selected={themeofuser === 1} disabled={themeofuser === 4}>Theme 4</option>
                                    <option value="5" selected={themeofuser === 1} disabled={themeofuser === 4}>F83</option>
                                </select>
                            </div>
                        </Modal>
                        <header className='header_mobile'>
                            <img src="/img/sports-logo-.png" alt="sports_favicon" />
                            <div className='header_mobile_right'>
                                <svg className='changetheme' onClick={showModal} viewBox="64 64 896 896" focusable="false" data-icon="bg-colors" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M766.4 744.3c43.7 0 79.4-36.2 79.4-80.5 0-53.5-79.4-140.8-79.4-140.8S687 610.3 687 663.8c0 44.3 35.7 80.5 79.4 80.5zm-377.1-44.1c7.1 7.1 18.6 7.1 25.6 0l256.1-256c7.1-7.1 7.1-18.6 0-25.6l-256-256c-.6-.6-1.3-1.2-2-1.7l-78.2-78.2a9.11 9.11 0 00-12.8 0l-48 48a9.11 9.11 0 000 12.8l67.2 67.2-207.8 207.9c-7.1 7.1-7.1 18.6 0 25.6l255.9 256zm12.9-448.6l178.9 178.9H223.4l178.8-178.9zM904 816H120c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8z"></path></svg>
                                <div className="mobile_opener_t4" onClick={handleOpenMenu}>
                                    <MenuOutlined />
                                </div>
                            </div>
                        </header>
                        <Theme4_menu_mobile handleBetPost={handleBetPost} menuOpen={menuOpen} handleChangeMenuMobile={handleChangeMenuMobile} handleOpenMenu={handleOpenMenu} />
                        {
                            menuMobile === 'HOME' ?
                                <div className='layoutbotton_container'>
                                    <div className='bets-inputs_conatiner'>
                                        <Theme4_addbets_mobile ifwinActive={ifwinActive} setIfwinActive={setIfwinActive} setBetToAdd={setBetToAdd} handleChangeBetToAdd={handleChangeBetToAdd} betToAdd={betToAdd} bets={bets} setBets={setBets} totalToWin={totalToWin} setTotalToWin={setTotalToWin} totalToWinIF={totalToWinIF} setTotalToWinIF={setTotalToWinIF} handleBetPostEnter={handleBetPostEnter} setMenuActive={setMenuActive} setOnlyNumbers={setOnlyNumbers} onlyNumbers={onlyNumbers} />
                                    </div>
                                    <div className='amounts_container'>
                                        <div className='input_label div'>
                                            <label>betted</label>
                                            <input type="text" disabled value={`$${bets.ammount === 0 ? "0.00" : bets.ammount?.toFixed(2)}`} id='amount_betted' inputMode='none' />
                                        </div>
                                        <div className='input_label div'>
                                            <label>Win</label>
                                            <input type="text" disabled defaultValue={`$0.00`} id='amount_win' inputMode='none' />
                                        </div>
                                        <div className='input_label div'>
                                            <label>IF</label>
                                            <input type="text" disabled defaultValue={`$0.00`} id='amount_if' inputMode='none' />
                                        </div>
                                        <div className='div'>
                                            <label>Activate IF</label>
                                            <Switch
                                                checked={ifwinActive}
                                                onChange={() => setIfwinActive(!ifwinActive)}
                                                color="primary"
                                                name="checkedB"
                                                inputprops={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                        <div className='div'>
                                            <label>Print</label>
                                            <Switch
                                                checked={printActive}
                                                onChange={handleChangePrint}
                                                color="primary"
                                                name="checkedB"
                                                inputprops={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                    </div>
                                    <div className='leagues_container'
                                        style={{
                                            display: menuActive === 'HOME' && !onlyKyboard ? 'flex' : 'none'
                                        }}
                                    >
                                        <Theme4_leagues_mobile setMenuActive={setMenuActive} />
                                    </div>
                                    <div className='leagues_container'
                                        style={{
                                            display: menuActive !== 'HOME' && menuActive === 'TEAMCODE' && !onlyKyboard ? 'flex' : 'none'
                                        }}
                                    >
                                        <Theme4_teams_mobile gamesForUser={gamesForUser} betToAdd={betToAdd} setBetToAdd={setBetToAdd} isLoading={isLoading} />
                                    </div>
                                    <div className='w-100'
                                        style={{
                                            display: menuActive === 'BETLIST' ? 'flex' : 'none'
                                        }}
                                    >
                                        {children}
                                    </div>
                                    <div className='w-100'
                                        style={{
                                            display: menuActive === 'PLAYTYPE' || menuActive === 'BETAMOUNT' || menuActive === 'IFWIN' || onlyKyboard ? 'flex' : 'none'
                                        }}
                                    >
                                        <Theme4_Keyboard setMenuActive={setMenuActive} menuActive={menuActive} onlyNumbers={onlyNumbers} setOnlyNumbers={setOnlyNumbers} setBets={setBets} bets={bets} setBetToAdd={setBetToAdd} betToAdd={betToAdd} handleBetAddToTable={handleBetAddToTable} handleBetPost={handleBetPost} handleEnterKey={handleEnterKey} handleIfWinChange={handleIfWinChange} setBetsIf={setBetsIf} betsIf={betsIf} />
                                    </div>
                                    <div className='left_container'>
                                        <div className="typeOfBet">
                                            <input type="radio" id="normalBet" name="typeOfBet" value="0" defaultChecked />
                                            <label htmlFor="normalBet">NORMAL</label>
                                            <input type="radio" id="teaserBet" name="typeOfBet" value="1" />
                                            <label htmlFor="teaserBet" >TEASER</label>
                                        </div>
                                        <div className='footer_left_container'>
                                            <div className='bet'>
                                                <input type="text" disabled value={bets?.bets?.length} id='nro_jugadasbet' />
                                                <input type="text" name='Quantity' value={betToAdd.Quantity} onChange={handleChangeBetToAdd} id="quantityInput" />
                                                <input type="text" id='t_aganar' defaultValue={0} />
                                                <input type="text" id='monto_winbet' defaultValue={0} />
                                            </div>
                                            <div className='bet'>
                                                <input type="text" id='nro_jugadasif'
                                                    onFocus={(e) => {
                                                        setBets({
                                                            ...bets,
                                                            betsIf: ''
                                                        });
                                                    }}
                                                    onChange={(e) => {
                                                        setBets({
                                                            ...bets,
                                                            betsIf: e.target.value
                                                        });
                                                    }}
                                                    value={bets?.betsIf || 0}
                                                />
                                                <input type="text" name='IfWin' value={betToAdd.IfWin || 0} onChange={handleChangeBetToAdd} id="ifWinInput" />
                                                <input type="text" id='t_aganar_if' defaultValue={0} />
                                                <input type="text" id='monto_winif' defaultValue={0} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='righ_container'>
                                        <div className='league_selected'>
                                            {
                                                gamesForUser && gamesForUser[0] && gamesForUser[0]?.league?.name ?
                                                    <>
                                                        <img src={gamesForUser[0]?.league?.image} alt="" />
                                                        <span>{gamesForUser[0]?.league?.name}</span>
                                                        <span>
                                                            -
                                                        </span>
                                                        <span>{gamesForUser.length} Active events</span>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                        <Theme4_games gamesForUser={gamesForUser} isLoading={isLoading} setUserTheme={setUserTheme} />
                                    </div>
                                    <div className='footer_mobile'>
                                        <div onClick={() => setMenuActive('HOME')}>
                                            <i className="fa fa-home" aria-hidden="true"></i>
                                        </div>
                                        <div onClick={saveBet}>
                                            <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                        </div>
                                        <div onClick={() => setMenuActive(menuActive === 'BETLIST' ? 'HOME' : 'BETLIST')}
                                            style={{
                                                backgroundColor: menuActive === 'BETLIST' ? '#f5f5f5' : '#e9ac53',
                                                color: menuActive === 'BETLIST' ? '#333' : '#f5f5f5'
                                            }}
                                        >
                                            {
                                                menuActive === 'BETLIST' ?
                                                    <i className="fa fa-undo" aria-hidden="true"></i>
                                                    :
                                                    <UnorderedListOutlined />
                                            }
                                            {
                                                menuActive !== 'BETLIST' &&
                                                <div className='bets'>
                                                    {
                                                        bets?.bets?.length > 0 ? bets?.bets?.length : 0
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div onClick={toggleKeyboard}
                                            style={{
                                                backgroundColor: onlyKyboard ? '#f5f5f5' : '#64cbdd',
                                                color: onlyKyboard ? '#333' : '#f5f5f5'
                                            }}
                                        >
                                            {
                                                onlyKyboard ?
                                                    <i className="fa fa-picture-o" aria-hidden="true"></i>
                                                    :
                                                    <i className="fa fa-keyboard-o" aria-hidden="true"></i>
                                            }
                                        </div>
                                        <div onClick={clearBets}>
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='layoutbotton_container'>
                                    {
                                        renderMenu()
                                    }
                                    <div className='footer_mobile'>
                                        <div onClick={() => {
                                            setMenuActive('HOME')
                                            setMenuMobile('HOME')
                                        }}>
                                            <i className="fa fa-home" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                        }

                    </section>
                    : <section id="LayoutClient4" className="main-sports4">
                        <Theme4_menu handleBetPost={handleBetPost} />
                        <div className='layoutbotton_container'>
                            <div className='left_container'>
                                <Theme4_addbets ifwinActive={ifwinActive} setIfwinActive={setIfwinActive} setBetToAdd={setBetToAdd} handleChangeBetToAdd={handleChangeBetToAdd} betToAdd={betToAdd} bets={bets} setBets={setBets} totalToWin={totalToWin} setTotalToWin={setTotalToWin} totalToWinIF={totalToWinIF} setTotalToWinIF={setTotalToWinIF} handleBetPostEnter={handleBetPostEnter}
                                    filterTeam={filterTeam}
                                    handleFilterTeam={handleFilterTeam}
                                    filteredGames={filteredGames}
                                />
                                <div className="typeOfBet">
                                    <input type="radio" id="normalBet" name="typeOfBet" value="0" defaultChecked />
                                    <label htmlFor="normalBet">NORMAL</label>
                                    <input type="radio" id="teaserBet" name="typeOfBet" value="1" />
                                    <label htmlFor="teaserBet" >TEASER</label>
                                </div>
                                {children}
                                <div className='footer_left_container'>
                                    <div className='bet'>
                                        <input type="text" disabled value={bets?.bets?.length} id='nro_jugadasbet' />
                                        <input type="text" name='Quantity' value={betToAdd.Quantity} onChange={handleChangeBetToAdd} id="quantityInput" />
                                        <input type="text" id='t_aganar' defaultValue={0} />
                                        <input type="text" id='monto_winbet' defaultValue={0} />
                                    </div>
                                    <div className='bet'>
                                        <input type="text" id='nro_jugadasif'
                                            onFocus={(e) => {
                                                setBets({
                                                    ...bets,
                                                    betsIf: ''
                                                });
                                            }}
                                            onChange={(e) => {
                                                setBets({
                                                    ...bets,
                                                    betsIf: e.target.value
                                                });
                                            }}
                                            value={bets?.betsIf || 0}
                                        />
                                        <input type="text" name='IfWin' value={betToAdd.IfWin} onChange={handleChangeBetToAdd} id="ifWinInput" />
                                        <input type="text" id='t_aganar_if' defaultValue={0} />
                                        <input type="text" id='monto_winif' defaultValue={0} />
                                    </div>
                                </div>
                            </div>
                            <div className='righ_container'>
                                <Theme4_leagues />
                                <div className='league_selected'>
                                    {
                                        gamesForUser && gamesForUser[0] && gamesForUser[0]?.league?.name ?
                                            <>
                                                <img src={gamesForUser[0]?.league?.image} alt="" />
                                                <span>{gamesForUser[0]?.league?.name}</span>
                                                <span>
                                                    -
                                                </span>
                                                <span>{gamesForUser.length} Active events</span>
                                            </>
                                            :
                                            null
                                    }

                                </div>
                                <Theme4_games gamesForUser={gamesForUser} isLoading={isLoading} setUserTheme={setUserTheme} filteredGames={filteredGames} />
                            </div>
                        </div>
                    </section>
            }
        </>
    );


}

export default Theme4_layout;