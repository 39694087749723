import React, { useState, useEffect } from 'react'


const LimitesGral = ({ setCreateConsorcios, createConsorcios, consorcioSeleccionado }) => {

    let list = [
        { text: "Limit de jugadas por Ticket", value: "ticketPlays" },
        { text: "Limit de jugadas por Ticket IF", value: "ticketPlaysIF" },
        { text: "Directo", value: "direct" },
        { text: "Noneta", value: "ninth" },
        { text: "Pale", value: "pale" },
        { text: "Decima", value: "tenth" },
        { text: "Tripleta", value: "triplet" },
        { text: "Decima Primera", value: "eleventh" },
        { text: "Cuarteta", value: "quatrain" },
        { text: "Decima Segunda", value: "twelfth" },
        { text: "Quinteta", value: "quintet" },
        { text: "Decima Tercera", value: "thirteenth" },
        { text: "Sexteta", value: "sextet" },
        { text: "Decima Cuarta", value: "fourteenth" },
        { text: "Septeta", value: "septet" },
        { text: "Decima Quinta", value: "fifteenth" },
        { text: "Octeta", value: "octet" },
        { text: "Decima Sexta", value: "sixteenth" },
        { text: "T2", value: "t2" },
        { text: "If Play", value: "ifMove" },
        { text: "T3", value: "t3" },
        { text: "T4", value: "t4" },
    ]


    const handleInputs = (e) => {
        setCreateConsorcios({
            ...createConsorcios,
            limits: {
                ...createConsorcios.limits,
                [e.target.name]: e.target.value
            }
        })

    }
    const [ligas, setLigas] = useState(createConsorcios.limits.leagues)

    const handleChange = (event) => {
        let ligasArray = event.target.value.split(',');
        setLigas(event.target.value);
        setCreateConsorcios({
            ...createConsorcios,
            limits: {
                ...createConsorcios.limits,
                leagues: ligasArray
            }
        })
    }

    return (
        <div className="row" id='ConsorcioTlmtGral'>
            <div className="col-12">
                <h6 className='text-center h2 m-2'>Limites Generales</h6>
                <ul className="nav">
                    {
                        list.map((item, index) => {
                            return (
                                <li key={index} className="nav-item">
                                    <label className='labelGeneral '>{item.text}</label>
                                    {
                                        item.value !== 'leagues' ?
                                            <input type="number" className="form-control" name={item.value} onChange={handleInputs} value={createConsorcios.limits[item.value]} />
                                            :
                                            <input type="text" className="form-control" name={item.value} onChange={handleChange} value={ligas || ''} />
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default LimitesGral