import moment from 'moment'
import React, { useState, useEffect, useContext } from 'react'
import { Button } from 'react-bootstrap'
import { redApi } from '../../../../config/Axios'
import { getToday } from '../../../../utils/dateToday'
import { Modal } from 'antd'
import { getScoreStructure } from '../../../../utils/scoreStructures'
import Swal from 'sweetalert2'
import { Swals } from '../../../../utils/swals'
import { AuthContext } from '../../../../auth'
import { ReportsContext } from './context/ReportesContext'
import './ScoreBox.css'
export default function ScoreBox() {
    const [data, setData] = useState([])
    const [filters, setFilters] = useState({
        league: '',
        team: '',
        status: '',
        date: getToday()
    })

   


    const [showModal, setShowModal] = useState(false);
    const [selectedGame, setSelectedGame] = useState(null);
    const [scoreStructure, setScoreStructure] = useState([]);
    const [modalUpdateGame, setModalUpdateGame] = useState(false);
    const [casillla, setCasilla] = useState(false);
    const [gameToUpdate, setGameToUpdate] = useState({});
    const [scoresToUpdate, setScoresToUpdate] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [firstRender, setFirtsRender] = useState(true);
    const [periods, setPeriods] = useState([]);
    const [periodsMlb, setPeriodsMlb] = useState([]);
    const [additionalPeriods, setAdditionalPeriods] = useState([]);
    const [newScoresMlb, setNewScoresMlb] = useState({localMlb:[], visitorMlb: []});
    const [newScoresLocal, setNewScoresLocal] = useState({ localScores: []});
    const [newScoresVisitor, setNewScoresVisitor] = useState({visitingScores: []});
    const [URL, setURL] = useState('');
    const [user, setUser] = useState(null);

    const handleNewScoresLocal = (index, value) => {
        const updatedScores = [...newScoresLocal.localScores];
        updatedScores[index] = value;
        setNewScoresLocal({localScores: updatedScores});
    }
    const handleNewScoresVisitor = (index, value) => {
        const updatedScores = [...newScoresVisitor.visitingScores];
        updatedScores[index] = value;
        setNewScoresVisitor({visitingScores: updatedScores});
    }

    const handleNewScoresLocalMlb = (index, value) => {
        const updatedScores = [...newScoresMlb.localMlb];
        updatedScores[index] = value;
        setNewScoresMlb({ ...newScoresMlb, localMlb: updatedScores });
    }

    const handleNewScoresVisitorMlb = (index, value) => {
        const updatedScores = [...newScoresMlb.visitorMlb];
        updatedScores[index] = value;
        setNewScoresMlb({ ...newScoresMlb, visitorMlb: updatedScores });
    }
   
    const { userLevel } = useContext(AuthContext);
   

    const {
        leagues,
        teams,
        getTeams,
        games,
        loading
    } = useContext(ReportsContext);



    useEffect(() => {
        getTeams(filters.league)
    }, [filters.league])

    const addPeriod = () => {
        const newPeriod = `${periods.length + additionalPeriods.length + 1}`;
        setAdditionalPeriods([...additionalPeriods, newPeriod]);
    };

    const removePeriod = (periodToRemove) => {
        // setAdditionalPeriods(additionalPeriods.filter(period => period !== periodToRemove));
        setAdditionalPeriods(additionalPeriods.slice(0, -1));
    };

    const handleSelectGame = (game) => {
        setSelectedGame(game);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedGame(null);
        setNewScoresLocal({localScores:[]})
        setNewScoresVisitor({visitingScores:[]})
        setNewScoresMlb({localMlb:[],visitorMlb:[]})
    };

  
    const handleFilters = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        })
    }

  
    useEffect(() => {
        if (selectedGame) {      
            let newPeriods;
            if (selectedGame.league.name === 'TEN') {
                newPeriods = ["1", "2", "3", "4", "5"];
            } else if(selectedGame.league.name === 'MLB') {
                newPeriods = ["1", "2", "3", "4", "5", "6", "7","8", "9"];
                const period = ['R', 'H', 'E']
                setPeriodsMlb(period)
            } else if(selectedGame.league.name === 'SOC'){
                newPeriods = ["1", "2", "3", "4",];
            } else if(selectedGame.league.name === 'BPS'){
                newPeriods = ["1"];
            }
            else {
                newPeriods = selectedGame.league.periods || [];
            }
            setPeriods(newPeriods);

            const localScores = newPeriods.map(period => selectedGame.localLine.scores?.[period] || '');
            const visitingScores = newPeriods.map(period => selectedGame.visitingLine.scores?.[period] || '');
            
            setNewScoresLocal({ localScores });
            setNewScoresVisitor({ visitingScores });

            setNewScoresMlb({localMlb: localScores, visitorMlb: visitingScores})

            setURL(selectedGame._id);

        }
    }, [selectedGame]);


 

   const sendNewScores = async () => {
        try {
            setShowModal(false)        
            const alertLoading =  Swal.fire({
                icon: "success",
                title: "Procesando...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                  Swal.showLoading()
                }
              })

            const data = await redApi.put(`games/${URL}/set_scores`,{newScoresLocal, newScoresVisitor, newScoresMlb, user})
            console.log("actualizado", data) 
            alertLoading.close()
             Swal.fire({
                icon: "success",
                title: "Scores Enviados",
                showConfirmButton: false,
                timer: 2000
            })
            setNewScoresLocal({localScores:[]})
            setNewScoresVisitor({visitingScores:[]})
            setNewScoresMlb({localMlb:[],visitorMlb:[]})
            getDataWithFilters()
        } catch (error) {
            console.log("error", error)
            setNewScoresLocal({localScores:[]})
            setNewScoresVisitor({visitingScores:[]})
            setNewScoresMlb({localMlb:[],visitorMlb:[]})
        }
   }
   
    useEffect(() => {
        setFirtsRender(false)
    }, []);


    const getDataWithFilters = async () => {
        setLoading(true);
        const { data } = await redApi.get(`/verify`);
        setUser(data.name)
        if (!loading && firstRender) {
            setData(games)
            setLoading(false);
        } else {
            await redApi.get(`/games?date=${filters.date}${filters.league !== 'Todos' ? `&league=${filters.league}` : ''}${filters.team !== 'Todos' ? `&team=${filters.team}` : ''}${filters.status !== 'Todos' ? `&estatus=${filters.status}` : ''}`)
                .then(res => {
                    setData(res.data)
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err)
                })

        }

    }



    const handleUpdateScores = async (game) => {
        if (userLevel !== 1) return Swal.fire({
            icon: "error",
            title: "No tienes permisos para realizar esta acción",
            showConfirmButton: false,
            timer: 2000
        })

        let missing = false;
        const scoresLocal = document.getElementsByName('scoresLocal');
        const scoresVisit = document.getElementsByName('scoresVisit');
        let scoresObj = { local: {}, visiting: {} };
        for (const score of scoresLocal) {
            if (score.value === '') {
                missing = true;
                break;
            }
            scoresObj.local[score.getAttribute('data-id')] = Number(score.value);
        };
        for (const score of scoresVisit) {
            if (score.value === '') {
                missing = true
                break;
            }
            scoresObj.visiting[score.getAttribute('data-id')] = Number(score.value);

        };
        if (missing) {
            return Swal.fire({
                icon: "error",
                title: "Faltan Parámetros",
                showConfirmButton: false,
                timer: 2000
            })
        }
        Swal.fire({
            icon: "success",
            title: "Scores Completos",
            showConfirmButton: false,
            timer: 2000
        })
        console.log({ scoresObj });
        Swal.fire({
            title: "¿Desea actualizar los marcadores?",
            showCancelButton: true,
            confirmButtonText: `Si`,
            cancelButtonColor: "#832AC0",
            confirmButtonColor: "#BF2A61",
        }).then(async (result) => {
            if (result.isConfirmed) {
                await redApi.put(`/games/${gameToUpdate._id}/set_scores`, { scores: scoresObj, scoreStructure }
                ).then(() => {
                    Swal.fire({
                        icon: "success",
                        title: "Marcadores actualizados",
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(() => {
                        setGameToUpdate({});
                        window.location.reload();
                    });
                });
            }
        });
    };

    const handleReCalcScores = async () => {
        setLoading(true);
        try {
            await redApi.post(`/games/reload_scores?date=${filters.date}`).then((r) => {
                console.log("DATAAAA", r)
            });

        } catch {
            await Swals.error(
                "Error",
                "Ha ocurrido un error al limpiar los cambios"
            );
        } finally {
            await getDataWithFilters();
            await Swals.success(
                "Completado",
                "Se han actualizado los tickets"
            );
        }
    }
    useEffect(() => {
        getDataWithFilters()
    }, [filters])

    let status = ["pending", "approved", "finished", "playing"]

    const handleShowModalSetScores = async (game) => {
        setGameToUpdate(game);
        // console.log({scoreStructure});
        console.log(game, { scoresLocal: game.localLine.scores, scoresVisit: game.visitingLine.scores })
        const structure = getScoreStructure(game.league.name)
        console.log({ structure, game: game.league.name })
        setScoreStructure(structure);
        setScoresToUpdate({ scoresLocal: game.localLine.scores, scoresVisit: game.visitingLine.scores });
        setModalUpdateGame(true);
        // console.log({game})
    }
    const handleCancelModal = () => {
        setModalUpdateGame(false);
        setGameToUpdate({});
    };

    const handleRefresh = () => {
        getDataWithFilters();
    };
    const allPeriods = [...periods, ...additionalPeriods];

    return (
        <div className="container-fluid p-0  m-0">
            <Modal
                title="Actualizar juego"
                open={modalUpdateGame}
                onCancel={handleCancelModal}
                width={1280}
                footer={[
                    <Button onClick={handleCancelModal}>
                        Cancelar
                    </Button>,
                    <Button type="primary" onClick={handleUpdateScores}>
                        Guardar
                    </Button>,
                ]}
            >
                <div className="row">
                    <div className="col-12">
                        <div className="head d-flex justify-content-center align-items-center">
                            <h6 className="title">
                                {`${gameToUpdate?.localTeam?.name} vs ${gameToUpdate?.visitingTeam?.name} | ${moment(gameToUpdate?.time).format('hh:mm A')}`}
                            </h6>
                        </div>
                        <div className="body">
                            <div className="row">
                                <div className="col-12">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Equipo</th>
                                                {scoreStructure.map((item, index) => (
                                                    <th key={index} scope="col">{item}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <img width={35} height={35} src={gameToUpdate?.localTeam?.image} />
                                                        {gameToUpdate?.localTeam?.name}
                                                    </div>
                                                </td>
                                                {scoreStructure.map((item, index) => (
                                                    <td key={index}>
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            className="form-control border-0 bg-transparent"
                                                            data-id={item}
                                                            name="scoresLocal"
                                                        // value={gameToUpdate.status === 'finished' ? gameToUpdate.localLine?.scores[item] : ''}
                                                        />
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <img width={35} height={35} src={gameToUpdate?.visitingTeam?.image} />
                                                        {gameToUpdate?.visitingTeam?.name}
                                                    </div>
                                                </td>
                                                {scoreStructure.map((item, index) => (
                                                    <td key={index}>
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            className="form-control border-0 bg-transparent"
                                                            data-id={item}
                                                            name="scoresVisit"
                                                        // value={gameToUpdate.status === 'finished' ? gameToUpdate.visitingLine?.scores[item] : ''}
                                                        />
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="row m-0 p-0">
                <div className="col-12  m-0 p-0 bg-gray-800 py-4">
                    <div className="row m-0 p-0 gap-3">
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2"
                            style={{
                                maxWidth: "300px"
                            }}
                        >
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Liga</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-select" name="league" onChange={handleFilters}>
                                                <option value="">Todos</option>
                                                {leagues.map((league, index) => (
                                                    <option key={index} value={league._id}>{league.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Equipo</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-select" name="team" onChange={handleFilters}>
                                                <option value="">Todos</option>
                                                {teams.map((team, index) => (
                                                    <option key={index} value={team._id}>{team.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2"
                            style={{
                                maxWidth: "300px"
                            }}
                        >
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Fecha</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="date" className="form-date" name="date" format="MM/DD/YYYY" onChange={handleFilters} value={filters.date} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Status</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name="status" onChange={handleFilters}>
                                                <option value="">Todos</option>
                                                {status.map((cons, index) => (
                                                    <option key={index} value={cons}>{cons}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2"
                            style={{
                                maxWidth: "300px"
                            }}
                        >
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" onClick={handleRefresh} value="Refresh"></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <input type="button" value="Imprimir" />
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <input type="button" onClick={handleReCalcScores} value="Recalcular Score" style={{ padding: "1px" }} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-12 m-0 p-0 py-4" id="table_container">
                    <div className="table-responsive" style={{ maxHeight: "65vh" }}>
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Equipo</th>
                                    <th scope="col">Half</th>
                                    <th scope="col">Full</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    isLoading ? <tr><td colSpan="5" className="text-center">Cargando...</td></tr> :
                                        data?.map((game, index) => (
                                            <tr
                                                key={index}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleSelectGame(game)}
                                            >
                                                <td>{moment(game.time).format('MM/DD/YYYY')}</td>
                                                <td>
                                                    <div>
                                                        <div>
                                                            {game.localTeam.name}
                                                            <span style={{ fontSize: '0.8rem', }} >
                                                                {" - "}
                                                                ({game.league.name})
                                                            </span>
                                                        </div>
                                                        <div>
                                                            {game.visitingTeam.name}
                                                            <span style={{ fontSize: '0.8rem', }} >
                                                                {" - "}
                                                                ({game.league.name})
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div>
                                                            {game.localLine?.scores?.half || "~"}
                                                        </div>
                                                        <div>
                                                            {game.visitingLine?.scores?.half || "~"}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div>
                                                            {game.localLine?.scores?.total || "~"}
                                                        </div>
                                                        <div>
                                                            {game.visitingLine?.scores?.total || "~"}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{game.status}</td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal
            open={showModal}
            onCancel={handleClose}
            footer={null}
            width='980px'
        >
            {selectedGame && (
                <div>
                    <div className="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                        <div style={{ flex: '1', textAlign: 'left' }}>
                            <p style={{ fontSize: 30 }}>{selectedGame.league.name}</p>
                            <p style={{ marginTop: '-3%', fontSize: 20, color: 'gray' }}>{moment(selectedGame.time).format('DD/MM/YYYY')}</p>
                        </div>
                        <div style={{ flex: '1', textAlign: 'right' }}>
                            <p style={{ fontWeight: 'bold', fontSize: 20, color: selectedGame.status === "finished" ? 'green' : 'red' }}>
                                {selectedGame.status === "finished" ? 'Final' : 'Pendiente'}
                            </p>
                        </div>
                    </div>
                    <div className="row" style={{ width: '80%', margin: '0 auto', display: 'flex', justifyContent: 'space-between' }}>
                        <div className="col" style={{ textAlign: 'center', flex: '1' }}>
                            <img src={selectedGame.localTeam.image} alt="Primera Imagen" style={{ width: '80%' }} />
                            <p>{selectedGame.localTeam.name}</p>
                        </div>
                        <div className="col" style={{ textAlign: 'center', flex: '1', marginTop: '10%' }}>
                            <p style={{ fontSize: 20, }}>{selectedGame.localLine?.scores?.total || "~"}</p>
                        </div>
                        <div className="col" style={{ textAlign: 'center', flex: '1', marginTop: '10%' }}>
                            <p style={{ fontSize: 20, }}>VS</p>
                        </div>
                        <div className="col" style={{ textAlign: 'center', flex: '1', marginTop: '10%' }}>
                            <p style={{ fontSize: 20, }}>{selectedGame.visitingLine?.scores?.total || "~"}</p>
                        </div>
                        <div className="col" style={{ textAlign: 'center', flex: '1' }}>
                            <img src={selectedGame.visitingTeam.image} alt="Segunda Imagen" style={{ width: '80%' }} />
                            <p>{selectedGame.visitingTeam.name}</p>
                        </div>
                    </div>
                    <div style={{ width: '80%', marginLeft: '5%', height: 1, backgroundColor: 'gray' }}></div>
                    {
                    selectedGame.league.name === 'TEN' ? (
                        <div>
                            <div className="row" style={{ width: '100%', margin: '0 auto', display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                <table className="table table-bordered" style={{ width: '100%', textAlign: 'center' }}>
                                    <thead>
                                        <tr>
                                            <td>Player</td>
                                            {periods?.map((period, index) => (
                                                <th key={index} style={{ fontSize: '1.2em' }}>Set {index + 1}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{selectedGame.localTeam.name}</td>
                                            {periods?.map((period, index) => (
                                                <td key={index}>
                                                    <input
                                                        type='text'
                                                        style={{ fontSize: '1.2em', border: 'none', width: '100%', boxSizing: 'border-box' }}
                                                        // value={newScoresLocal[index] || selectedGame.localLine?.scores?.[index]}
                                                        // value={newScoresLocal[index] || []}
                                                        value={newScoresLocal?.localScores[index] || ''}
                                                        onChange={(e)=> handleNewScoresLocal(index,e.target.value)}
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>{selectedGame.visitingTeam.name}</td>
                                            {periods?.map((period, index) => (
                                                <td key={index}>
                                                    <input
                                                        type='text'
                                                        // value={selectedGame.visitingLine?.scores?.[index] || "~"}
                                                        style={{ fontSize: '1.2em', border: 'none', width: '100%', boxSizing: 'border-box' }}
                                                        value={newScoresVisitor.visitingScores[index] || ''}
                                                        onChange={(e)=> handleNewScoresVisitor(index,e.target.value)}
                                                        
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <button style={{ backgroundColor: 'green', color: 'white', fontSize: '1.2em', padding: '10px 20px', borderRadius: '5px', border: 'none', marginLeft: '88%', marginTop: '20px' }} onClick={()=>{sendNewScores()}}>Procesar</button>
                        </div>
                    ) :
                    selectedGame.league.name == "MLB" ? (
                        <div className="row" style={{ width: '100%', margin: '0 auto', display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <table className="table table-bordered" style={{ width: '100%', textAlign: 'center', tableLayout: 'fixed' }}>
                            <thead>
                                <tr>
                                    <td>Team</td>
                                    {allPeriods?.map((period, index) => (
                                        <th key={index} style={{ fontSize: '1.2em' }}>{period}</th>
                                    ))}
                                   
                                        {periodsMlb.map((period, index) => {
                                                let displayText;
                                                switch (index) {
                                                case 0:
                                                    displayText = 'R';
                                                    break;
                                                case 1:
                                                    displayText = 'H';
                                                    break;
                                                case 2:
                                                    displayText = 'E';
                                                    break;
                                                default:
                                                    displayText = period;
                                                }
                                                return (
                                                <th key={index} style={{ fontSize: '1.2em' }}>{displayText}</th>
                                                );
                                            })

                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{selectedGame.localTeam.name}</td>
                                    {allPeriods?.map((period, index) => (
                                        <td key={index}>
                                            <input
                                                type='text'
                                                // value={selectedGame.localLine?.scores?.[index] || "~"}
                                                style={{ fontSize: '1.2em', border: 'none', width: '100%', boxSizing: 'border-box' }}
                                                value={newScoresLocal.localScores[index] || []}
                                                onChange={(e)=> handleNewScoresLocal(index,e.target.value)}
                                            />
                                        </td>
                                    ))}
                                    {periodsMlb?.map((period, index)=> (
                                         <td key={index}>
                                         <input
                                             type='text'
                                             style={{ fontSize: '1.2em', border: 'none', width: '100%', boxSizing: 'border-box' }}
                                             value={newScoresMlb.localMlb[index] || ''}
                                             onChange={(e)=> handleNewScoresLocalMlb(index,e.target.value)}
                                         />
                                     </td>
                                    ))

                                    }
                                </tr>
                                <tr>
                                    <td>{selectedGame.visitingTeam.name}</td>
                                    {allPeriods?.map((period, index) => (
                                        <td key={index}>
                                            <input
                                                type='text'
                                                // value={selectedGame.visitingLine?.scores?.[index] || "~"}
                                                style={{ fontSize: '1.2em', border: 'none', width: '100%', boxSizing: 'border-box' }}
                                                value={newScoresVisitor.visitingScores[index] || []}
                                                onChange={(e)=> handleNewScoresVisitor(index,e.target.value)}
                                            />
                                        </td>
                                    ))}
                                     {periodsMlb?.map((period, index)=> (
                                         <td key={index}>
                                         <input
                                             type='text'
                                            //  value={selectedGame.visitingLine?.scores?.[index] || "~"}
                                             style={{ fontSize: '1.2em', border: 'none', width: '100%', boxSizing: 'border-box' }}
                                             value={newScoresMlb.visitorMlb[index] || ''}
                                             onChange={(e)=> handleNewScoresVisitorMlb(index,e.target.value)}
                                         />
                                     </td>
                                    ))

                                    }
                                </tr>
                            </tbody>
                        </table>
                  
                            <button style={{ 
                                    backgroundColor: 'green', 
                                    color: 'white', 
                                    fontSize: '1.2em', 
                                    padding: '0', 
                                    width: '30px', 
                                    height: '25px', 
                                    borderRadius: '5px', 
                                    border: 'none', 
                                    display: additionalPeriods.length > 0 ? 'flex' : 'none',
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    margin: '5px'
                                }} onClick={() => removePeriod()}>-</button>
                       
      
                        <button style={{ 
                            backgroundColor: 'green', 
                            color: 'white', 
                            fontSize: '1.2em', 
                            padding: '0', 
                            width: '30px', 
                            height: '25px', 
                            borderRadius: '5px', 
                            border: 'none', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center',
                            margin: '5px'
                        }} onClick={() => addPeriod()}>+</button>

                        <button style={{ 
                            backgroundColor: 'green', 
                            color: 'white', 
                            fontSize: '1.2em', 
                            padding: '10px 20px', 
                            borderRadius: '5px', 
                            border: 'none', 
                            width: '200px', 
                            marginLeft: 'auto' 
                        }}
                        onClick={()=>{sendNewScores()}}
                        >Procesar</button>
                    </div>
                    ) :
                    selectedGame.league.name === 'BPS' ? (
                        <div className="row" style={{ width: '100%', margin: '0 auto', display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <table className="table table-bordered" style={{ width: '100%', textAlign: 'center', tableLayout: 'fixed' }}>
                            <thead>
                                <tr>
                                    <td>Team</td>
                                    {allPeriods?.map((period, index) => (
                                        <th key={index} style={{ fontSize: '1.2em' }}>{period}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{selectedGame.localTeam.name}</td>
                                    {allPeriods?.map((period, index) => (
                                        <td key={index}>
                                            <input
                                                type='text'
                                                // value={selectedGame.localLine?.scores?.[index] || "~"}
                                                style={{ fontSize: '1.2em', border: 'none', width: '100%', boxSizing: 'border-box' }}
                                                value={newScoresLocal.localScores[index] || []}
                                                onChange={(e)=> handleNewScoresLocal(index,e.target.value)}
                                            />
                                        </td>
                                    ))}
                                  
                                </tr>
                                <tr>
                                    <td>{selectedGame.visitingTeam.name}</td>
                                    {allPeriods?.map((period, index) => (
                                        <td key={index}>
                                            <input
                                                type='text'
                                                // value={selectedGame.visitingLine?.scores?.[index] || "~"}
                                                style={{ fontSize: '1.2em', border: 'none', width: '100%', boxSizing: 'border-box' }}
                                                value={newScoresVisitor.visitingScores[index] || []}
                                                onChange={(e)=> handleNewScoresVisitor(index,e.target.value)}
                                            />
                                        </td>
                                    ))}
                                  
                                </tr>
                            </tbody>
                        </table>
                  
                            {/* <button style={{ 
                                    backgroundColor: 'green', 
                                    color: 'white', 
                                    fontSize: '1.2em', 
                                    padding: '0', 
                                    width: '30px', 
                                    height: '25px', 
                                    borderRadius: '5px', 
                                    border: 'none', 
                                    display: additionalPeriods.length > 0 ? 'flex' : 'none',
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    margin: '5px'
                                }} onClick={() => removePeriod()}>-</button>
                       
      
                        <button style={{ 
                            backgroundColor: 'green', 
                            color: 'white', 
                            fontSize: '1.2em', 
                            padding: '0', 
                            width: '30px', 
                            height: '25px', 
                            borderRadius: '5px', 
                            border: 'none', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center',
                            margin: '5px'
                        }} onClick={() => addPeriod()}>+</button> */}

                        <button style={{ 
                            backgroundColor: 'green', 
                            color: 'white', 
                            fontSize: '1.2em', 
                            padding: '10px 20px', 
                            borderRadius: '5px', 
                            border: 'none', 
                            width: '200px', 
                            marginLeft: 'auto' 
                        }}
                        onClick={()=>{sendNewScores()}}
                        >Procesar</button>
                    </div>
                    ) : 
                    (
                        <div className="row" style={{ width: '100%', margin: '0 auto', display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <table className="table table-bordered" style={{ width: '100%', textAlign: 'center', tableLayout: 'fixed' }}>
                                <thead>
                                    <tr>
                                        <td>Team</td>
                                     
                                        {periods?.map((period, index) => {
                                                let periodRender;
                                                if (selectedGame.league.name === 'SOC') {
                                                switch (index) {
                                                    case 2:
                                                    periodRender = 'T.E';
                                                    break;
                                                    case 3:
                                                    periodRender = 'PENALES';
                                                    break;
                                                    default:
                                                    periodRender = period;
                                                }
                                                } else {
                                                periodRender = period;
                                                }
                                                return (
                                                <th key={index} style={{ fontSize: '1.2em' }}>{periodRender}</th>
                                                );
                                            })}
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{selectedGame.localTeam.name}</td>
                                        {periods?.map((period, index) => (
                                            <td key={index}>
                                                <input
                                                    type='text'
                                                    // value={selectedGame.localLine?.scores?.[index] || "~"}
                                                    style={{ fontSize: '1.2em', border: 'none', width: '100%', boxSizing: 'border-box' }}
                                                    value={newScoresLocal.localScores[index] || []}
                                                    onChange={(e)=> handleNewScoresLocal(index,e.target.value)}
                                                />
                                            </td>
                                        ))}
                                     
                                   
                                    </tr>
                                    <tr>
                                        <td>{selectedGame.visitingTeam.name}</td>
                                        {periods?.map((period, index) => (
                                            <td key={index}>
                                                <input
                                                    type='text'
                                                    // value={selectedGame.visitingLine?.scores?.[index] || "~"}
                                                    style={{ fontSize: '1.2em', border: 'none', width: '100%', boxSizing: 'border-box' }}
                                                    value={newScoresVisitor.visitingScores[index] || []}
                                                    onChange={(e)=> handleNewScoresVisitor(index,e.target.value)}
                                                />
                                            </td>
                                        ))}
                                     
                                        
                                    </tr>
                                </tbody>
                            </table>
                            
                            {/* <button style={{ 
                                backgroundColor: 'green', 
                                color: 'white', 
                                fontSize: '1.2em', 
                                padding: '0', 
                                width: '20px', 
                                height: '20px', 
                                borderRadius: '5px', 
                                border: 'none', 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center'
                            }} onClick={() => removePeriod()}>+</button> */}
                            <button style={{ 
                                backgroundColor: 'green', 
                                color: 'white', 
                                fontSize: '1.2em', 
                                padding: '10px 20px', 
                                borderRadius: '5px', 
                                border: 'none', 
                                width: '200px', 
                                marginLeft: 'auto' 
                            }}
                            onClick={()=>{sendNewScores()}}
                            >Procesar</button>
                        </div>
                    )}
                </div>
            )}
        </Modal>

        </div>
    )
}
