import React, { useState, useEffect } from 'react'
import { redApi } from '../../../../../config/Axios'

const CrearGrupogeneral = ({ grupo, setGrupo , showTable, data, checkboxState, handleCheckboxChange, showDelete, showEditar, dataGroup, showCreate, handleCheckboxChangeEdit}) => {

    
    const handleInputChange = (e) => {
        console.log(e.target.name, e.target.value)
        setGrupo({
            ...grupo,
            [e.target.name]: e.target.value
        })
    }

   
    
    


    return (
        <div className="row" >
            {showDelete ? ( 
            <div className="col-12" style={{textAlign:'center'}}>
                <label style={{fontWeight:'bold',color:'lightgray', fontSize:'20px'}}>Nombre:</label>
                <input type="text" className="form-control" name="name" onChange={handleInputChange} value={grupo.name} style={{border:'solid 2px gray', width:'60%',margin:'auto'}}/>
            </div>
            ) 
            : showEditar ?
            (
                <>
                    <div className="col-12" style={{textAlign:'center'}}>
                        <label style={{fontWeight:'bold',color:'lightgray', fontSize:'20px'}}>Nombre:</label>
                        <input type="text" className="form-control" name="name" onChange={handleInputChange} value={grupo.name} style={{border:'solid 2px gray', width:'60%',margin:'auto'}}/>
                    </div>
                    <div className="col-12"  style={{textAlign:'center'}}>
                        <label style={{fontWeight:'bold',color:'lightgray', fontSize:'20px',}}>Descripción:</label>
                        <input type="text" className="form-control" name="description" onChange={handleInputChange} value={grupo.description} style={{border:'solid 2px gray', width:'60%',margin:'auto'}}/>
                    </div>

                    <div className="col-12"  style={{textAlign:'center', marginTop:'10px',  overflowX: 'auto', maxHeight: '300px',marginLeft:'10px'}}>
                       <table style={{border:'solid 3px gray', width:'62%',margin:'auto', borderRadius:'5px'}}> 
                          <thead>
                           <tr>
                               <th style={{fontWeight:'bold',color:'lightgray', fontSize:'20px', backgroundColor:'red'}}>Terminales</th>
                                  </tr>
                              </thead>
                              <tbody>
                                 {
                                       data?.map((row,i)=>(
                                         <tr key={i}>
                                            <td style={{display: 'flex', alignItems: 'center', fontWeight:'bold',color:'darkgray', borderBottom: '1px solid #ddd', padding: '8px'}}>
                                          <div>
                                            <input
                                            type="checkbox"
                                            // checked={grupo[row.username] || false}
                                            checked={grupo.terminals.some(terminal => terminal.terminalId === row._id && terminal.checked)}
                                            onChange={(e) => handleCheckboxChangeEdit(e, row)}
                                            style={{width: '20px', height: '20px', marginRight:'5px'}}
                                                />
                                                </div>
                                                <div>
                                                {row.name} - {row.username} 
                                                </div>
                                              </td>
                                      </tr>

                                ))
                            }
                    </tbody>
                </table>
            </div>
                </>
            )  
            : showCreate ?
            (
                <>
                <div className="col-12" style={{textAlign:'center'}}>
                        <label style={{fontWeight:'bold',color:'lightgray', fontSize:'20px'}}>Nombre:</label>
                        <input type="text" className="form-control" name="name" onChange={handleInputChange} value={grupo.name} style={{border:'solid 2px gray', width:'60%',margin:'auto'}}/>
                    </div>
                    <div className="col-12"  style={{textAlign:'center'}}>
                        <label style={{fontWeight:'bold',color:'lightgray', fontSize:'20px',}}>Descripción:</label>
                        <input type="text" className="form-control" name="description" onChange={handleInputChange} value={grupo.description} style={{border:'solid 2px gray', width:'60%',margin:'auto'}}/>
                    </div>

                   <div className="col-12"  style={{textAlign:'center', marginTop:'10px',  overflowX: 'auto', maxHeight: '300px',marginLeft:'10px'}}>
                <table style={{border:'solid 3px gray', width:'62%',margin:'auto', borderRadius:'5px'}}> 
                    <thead>
                        <tr>
                            <th style={{fontWeight:'bold',color:'lightgray', fontSize:'20px', backgroundColor:'red'}}>Terminales</th>
                        </tr>
                    </thead>
                    <tbody>
                            {
                                data?.map((row,i)=>(
                        <tr key={i}>
                                    <td style={{display: 'flex', alignItems: 'center', fontWeight:'bold',color:'darkgray', borderBottom: '1px solid #ddd', padding: '8px'}}>
                                        <div>
                                         <input
                                            type="checkbox"
                                            checked={grupo[row.username] || false}
                                            // checked={grupo.terminals.some(terminal => terminal.terminalId === row._id && terminal.checked)}
                                            onChange={(e) => handleCheckboxChange(e, row)}
                                            style={{width: '20px', height: '20px', marginRight:'5px'}}
                                        />
                                        </div>
                                        <div>
                                          {row.name} - {row.username} 
                                        </div>
                                    </td>
                        </tr>

                                ))
                            }
                    </tbody>
                </table>
               </div>
                </>

            )
            :
            (
                    <>
                    <div className="col-12" style={{textAlign:'center'}}>
                        <label style={{fontWeight:'bold',color:'lightgray', fontSize:'20px'}}>Nombre:</label>
                        <input type="text" className="form-control" name="name" onChange={handleInputChange} value={grupo.name} style={{border:'solid 2px gray', width:'60%',margin:'auto'}}/>
                    </div>
                    <div className="col-12"  style={{textAlign:'center'}}>
                        <label style={{fontWeight:'bold',color:'lightgray', fontSize:'20px',}}>Descripción:</label>
                        <input type="text" className="form-control" name="description" onChange={handleInputChange} value={grupo.description} style={{border:'solid 2px gray', width:'60%',margin:'auto'}}/>
                    </div>
                    </>
            )
            }

            {/* {
                showTable &&
            <div className="col-12"  style={{textAlign:'center', marginTop:'10px',  overflowX: 'auto', maxHeight: '300px',marginLeft:'10px'}}>
                <table style={{border:'solid 3px gray', width:'62%',margin:'auto', borderRadius:'5px'}}> 
                    <thead>
                        <tr>
                            <th style={{fontWeight:'bold',color:'lightgray', fontSize:'20px', backgroundColor:'red'}}>Terminales</th>
                        </tr>
                    </thead>
                    <tbody>
                            {
                                data?.map((row,i)=>(
                        <tr key={i}>
                                    <td style={{display: 'flex', alignItems: 'center', fontWeight:'bold',color:'darkgray', borderBottom: '1px solid #ddd', padding: '8px'}}>
                                        <div>
                                         <input
                                            type="checkbox"
                                            checked={grupo[row.username] || false}
                                            // checked={grupo.terminals.some(terminal => terminal.terminalId === row._id && terminal.checked)}
                                            onChange={(e) => handleCheckboxChange(e, row)}
                                            style={{width: '20px', height: '20px', marginRight:'5px'}}
                                        />
                                        </div>
                                        <div>
                                          {row.name} - {row.username} 
                                        </div>
                                    </td>
                        </tr>

                                ))
                            }
                    </tbody>
                </table>
            </div>
            } */}
        </div>
    )
}

export default CrearGrupogeneral