import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import ModalTerminales from "./terminaleTablas/modalTerminales.js";
import { redApi } from "../../../../config/Axios.js";
import UserForm from "../../../forms/UserForm";
import { UserServices } from "../../../../services/userServices";
import validPrivileges from "../../../../utils/validatePriv.js";
import { AuthContext } from "../../../../auth/index.js";
import "./Terminales.css"



let privilegesRequired = {
  create: 19,
  edit: 20,
  delete: 21,
}

function ToggleButton({ terminal, unblockUser, blockUser }) {
  const [isBlocked, setIsBlocked] = useState(terminal.isBlocked);

  const toggleUserStatus = async (e) => {
  
    if (isBlocked) {
      await unblockUser(terminal._id);
    } else {
      await blockUser(terminal._id);
    }
    setIsBlocked(!isBlocked);
  };


  return (
    <div className={`toggle-container ${isBlocked ? 'blocked' : 'unblocked'}`} onClick={toggleUserStatus}>
      {/* <span className="status">{isBlocked ? 'No' : 'Yes'}</span> */}
    <div className="ball"></div>
    </div>
  );
}


const Terminales = () => {
  let buttons = ["Crear", "Editar", "Eliminar", "Refrescar"];
  const [modalCrear, setModalCrear] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [terminalSelected, setTerminalSelected] = useState(null);
  const [userPrivileges, setUserPrivileges] = useState([]);
  const { userLevel } = useContext(AuthContext);
  useEffect(async () => {
    if (userLevel == 10) {
      let userPriv = await UserServices.getUserPrivileges('Terminales');
      setUserPrivileges(userPriv.values);
    }
  }, [UserServices]);

  const handleClick = (e) => {
    let text = e.target.innerText;
    if (text === "Crear") {
      if (userLevel === 10) {
        let valid = validPrivileges(userPrivileges, [privilegesRequired.create]);
        if (valid !== true) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${valid}`,
          });
          return;
        }
      }
      setModalCrear(true);
    }
    if (text === "Editar") {
      if (userLevel === 10) {
        let valid = validPrivileges(userPrivileges, [privilegesRequired.edit]);
        if (valid !== true) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${valid}`,
          });
          return;
        }
      }
      if (terminalSelected === null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes seleccionar un usuario para editar!",
        });
        return;
      }
      setModalEditar(true);
    }
    if (text === "Eliminar") {
      if (userLevel === 10) {
        let valid = validPrivileges(userPrivileges, [privilegesRequired.delete]);
        if (valid !== true) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${valid}`,
          });
          return;
        }
      }

      if (terminalSelected === null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes seleccionar un usuario para eliminar!",
        });
        return;
      }

      Swal.fire({
        title: "Eliminar",
        text: "¿Estas seguro de eliminar el usuario?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await redApi
            .delete(`/users/${terminalSelected._id}`)
            .then(async (res) => {
              Swal.fire(
                "Eliminado!",
                "El usuario ha sido eliminado.",
                "success"
              );
              await redApi
                .get("/users?userLevel=3&populates=limits,leagues,income")
                .then((res) => {
                  setTerminales(res.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    if (text === "Refrescar") {
      Swal.fire({
        title: "Refrescar",
        text: "¿Estas seguro de refrescar la tabla?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Refrescar",
      }).then((result) => {
        if (result.isConfirmed) {
          loadTerms();
          // Swal.fire("Refrescado!", "La tabla se ha refrescado.", "success");
        }
      });
    }
  };

  const handleTerminalSelected = (terminal) => {
    if (terminalSelected === terminal) {
      setTerminalSelected(null);
      return;
    }
    setTerminalSelected(terminal);
  };

  const [terminales, setTerminales] = useState([]);
  const [consorcios, setConsorcios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadTerms();
  }, []);

  const loadTerms = async () => {
    setIsLoading(true);
    const terms = await UserServices.getUsers({ userLevel: 3 });
    const consors = await UserServices.getUsers({ userLevel: 2 });
    setTerminales(terms);
    setConsorcios(consors);
    setIsLoading(false);
  };

  const handleConsor = async (e) => {
    const terms = await UserServices.getUsers({ userLevel: 3, ownedBy: e.target.value });
    setTerminales(terms);
  }

/*************************************************************************** */

const blockUser = async (id) => {
  const res = await redApi.put(`/users/block/${id}`);
  loadTerms();
};

const unblockUser = async (id) => {
  const res = await redApi.put(`/users/desblock/${id}`);
  loadTerms();
};

const handleRowClick = (e) => {
  e.stopPropagation(); 
  handleTerminalSelected();
};

  return (
    <div className="container-fluid" id="terminalesContainer">
      <div className="row gap-3">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="row gap-1">
                {buttons.map((item, index) => {
                  return (
                    <div key={index} className="col-2 p-0 m-0">
                      <button className="btn btn-outline-primary" onClick={handleClick} style={{ fontSize: '12px' }}>{item}</button>
                    </div>
                  )
                }
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="row d-flex align-items-center justify-content-end">
                <div className="col-6 d-flex align-items-center justify-content-center gap-3">
                  <label htmlFor="consorcio">Consorcio</label>
                  <select className="form-select" id="consorcio" onChange={handleConsor}>
                    <option value="todos">Todos</option>
                    {consorcios?.map((item, index) => {
                      return (
                        <option value={item._id} key={index}>{item.username}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12" id="table_container">
          <table>
            <thead>
              <tr className="table-primary w-100">
                <th scope="col w-25">Nombre</th>
                <th scope="col w-25">descripcion</th>
                <th scope="col w-25">Consorcio</th>
                <th scope="col w-25">Activa</th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading ? <tr><td colSpan="4" className="text-center">Cargando...</td></tr> :
                  terminales?.map((item, index) => {
                    return (
                      <tr key={index} onClick={() => handleTerminalSelected(item)}
                        onDoubleClick={() => {
                          setTerminalSelected(item);
                          setModalEditar(true);
                        }}
                        className={terminalSelected === item ? "row-selected" : ""}>
                        <td>
                          {item.username}
                        </td>
                        <td
                        >
                          {item.description}
                        </td>
                        <td
                        >
                          {item.ownedBy?.username || "Admin"}
                        </td>
                        <td  onClick={handleRowClick}> 
                        
                            <ToggleButton
                            terminal={item}
                            unblockUser={unblockUser}
                            blockUser={blockUser}
                            handleTerminalSelected={handleTerminalSelected}
                          />
                         
                        </td>
                      </tr>
                    );
                  })}
                  
            </tbody>
          </table>
        </div>
      </div>
      {modalCrear && (
        <UserForm
          loadUsers={loadTerms}
          setShow={setModalCrear}
          show={modalCrear}
          userLevel={userLevel}
          consorcios={consorcios}
        />
      )}
      {modalEditar && (
        <UserForm
          loadUsers={loadTerms}
          setShow={setModalEditar}
          show={modalEditar}
          formMode={"edit"}
          toUpdate={terminalSelected}
          userLevel={userLevel}
          terminalSelected={terminalSelected}
          setTerminalSelected={setTerminalSelected}
        />
      )}
    </div>
  );
};

export default Terminales;


