
export const Privileges = {
    1: 'Anular tickets', //*
    2: 'Anular Tickets sin límete de tiempo', //*
    3: 'Reportes', //*
    4: 'Corredor',
    5: 'Reporte Corredor',
    6: 'Pagar ticket',
    7: 'Acceso a lineas', //*
    8: 'Acceso administrativo',
    9: 'Jugar', //*
    10: 'Jugar sin restricciones',
    11: 'Ingresar transacciones',  //*
    12: 'Ver transacciones', //*
    13: 'Configurar consorcio', //*
    14: 'Configurar restricciones', //* 
    15: 'Ver CxC', //*
    16: 'Anular sin restricciones',
    17: 'Reportes administrativos', //*
    18: 'Ver terminales', //*
    19: 'Crear terminales',//*
    20: 'Editar terminales', //*
    21: 'Eliminar terminales', //*
    22: 'Ver usuarios', //*
    23: 'Crear usuarios', //*
    24: 'Editar usuarios', //*
    25: 'Eliminar usuarios', //*
    26: 'Ver grupos', //*
    27: 'Crear grupos', //*
    28: 'Editar grupos', //*
    29: 'Eliminar grupos', //*
    30: 'Ver consorcio', //*
    31: 'Crear consorcio', //*
    32: 'Editar consorcio', //*
    33: 'Eliminar consorcio' //*
}

const validPrivileges = (userpPrivileges = [], requiredPrivileges = []) => {
    let missingPrivileges = [];
    requiredPrivileges.forEach(privilege => {
        if (!userpPrivileges.includes(privilege)) {
            missingPrivileges.push(privilege);
        }
    });
    if (missingPrivileges.length > 0) {
        let message = 'No tiene los privilegios necesarios para realizar esta acción, le faltan los siguientes privilegios: ';
        missingPrivileges.forEach(privilege => {
            message += Privileges[privilege] + ', ';
        });
        return message.substring(0, message.length - 2);
    } else {
        return true;
    }
}

export default validPrivileges;