import React from 'react'
import moment from 'moment'

export const TableLines = ({
    games,
    head
}) => {
    return (
        <div id='table-dinamic-responsive'>
            <table className="w-100">
                <thead>
                    <tr>
                        <th>Hora</th>
                        <th>Juegos Actuales</th>
                        {
                            head?.map((item, index) => (
                                <th key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>{item.name}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        games?.map((game, indexGame) => (
                            <tr key={indexGame}>
                                <td> {moment(game.time).format("hh:mm A")}</td>
                                <td
                                    className={`games_container ${game.status}`}
                                >
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={game.localTeam.image} alt="" />
                                            {" | "}
                                            <span>
                                                {game.localTeam.codeRD}
                                            </span>
                                            {" - "}
                                            <span>
                                                {
                                                    game.localTeam.name.length > 20
                                                        ? game.localTeam.name.slice(0, 20) + "..."
                                                        : game.localTeam.name
                                                }
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={game.visitingTeam.image} alt="" />
                                            {" | "}
                                            <span>
                                                {game.visitingTeam.codeRD}
                                            </span>
                                            {" - "}
                                            <span>
                                                {
                                                    game.visitingTeam.name.length > 20
                                                        ? game.visitingTeam.name.slice(0, 20) + "..."
                                                        : game.visitingTeam.name
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                {
                                    head?.map((item, index) => (
                                        <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>
                                            <div className="container_inputs">
                                                <div
                                                >
                                                    <input type="text" value={game.localLine[item.property]?.value}

                                                    />
                                                </div>
                                                <div>
                                                    <input type="text" value={game.visitingLine[item.property]?.value}

                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
