import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment-timezone';
import { redApi } from '../../../../../config/Axios';
import Cookies from "universal-cookie";
const cookie = new Cookies();
import { Button, Divider, Space, Tooltip } from 'antd';


const customColors = ['#f50', '#2db7f5', '#87d068', '#108ee9'];
export default function Theme4_addbets_mobile({ setBetToAdd, handleChangeBetToAdd, betToAdd, bets, setBets, totalToWin, setTotalToWin, totalToWinIF, setTotalToWinIF, handleBetPostEnter, setIfwinActive, ifwinActive, setMenuActive, onlyNumbers, setOnlyNumbers }) {

    function getToWin(bets, amount) {
        let result = 0;
        for (let i = 0; i < bets.length; i++) {

            if (bets[i].price.toString().includes('+') && bets[i].price.toString().includes('-')) {
            }
            console.log("Resultttt", result, bets[i].price)
            const multiplier = bets[i].price >= 0
                ? bets[i].price / 100
                : 1 / ((bets[i].price * -1) / 100);
            result = Math.round((result + amount) * multiplier + result);
        }
        result = Math.round(result);
        return result
    }

    // let ifWinContainer = document.querySelector('#ifWinContainer');
    // let newInput = document.createElement('input');
    // newInput.type = 'number';
    // newInput.className = 'form-control col';
    // newInput.name = 'IfWinQuantity';
    // ifWinContainer.appendChild(newInput);
    // newInput.focus();

    useEffect(() => {
        if (bets.bets?.length > 0) {
            setIfWinQuantity(null);
            let IfWinQuantity = document.querySelector('#IfWinQuantity');
            if (IfWinQuantity) {
                IfWinQuantity.remove();
            }
        }
    }, [bets]);

    const [IfWinQuantity, setIfWinQuantity] = useState(null);
    const handleEnterKey = useCallback((e) => {
        if (e.key === 'Enter' && bets.bets.length > 0) {
            let ifWinInput = document.querySelector('#ifWinInput');
            if (parseInt(ifWinInput.value) > 0) {
                let ifWinContainer = document.querySelector('#ifWinContainer');
                console.log({ ifWinContainer });
                if (ifWinContainer.children.length === 1) {
                    let newInput = document.createElement('input');
                    newInput.type = 'number';
                    newInput.className = 'form-control col';
                    newInput.name = 'IfWinQuantity';
                    newInput.id = 'IfWinQuantity';
                    ifWinContainer.appendChild(newInput);
                    newInput.focus();
                    setIfWinQuantity(newInput);
                    return;
                } else {
                    document.querySelector('#IfWinQuantity').focus();
                    return;
                }
            }
            let quantityInput = document.querySelector('#quantityInput');
            let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
            if (parseInt(ammountBet) > 0) {
                let betsTowin = bets.bets.map((bet) => {
                    let price = bet.Price.toString().slice(-4);
                    return {
                        price: price
                    }
                })

                ifWinInput.disabled = true;
                quantityInput.disabled = true;

                let totalToWin = getToWin(betsTowin, parseInt(ammountBet));
                setTotalToWin(totalToWin);
            }

            setBets({
                ...bets,
                ammount: parseInt(ammountBet),
                readyToSubmit: true
            })
            setBetToAdd({
                TeamCode: '',
                PlayType: '',
                Quantity: 0,
                IfWin: 0
            })

        }
    }, [bets])

    const handleIfWinChange = (e) => {
        if (e.key === 'Enter') {
            e.target.disabled = true;
            let ifWinInput = document.querySelector('#ifWinInput');
            // if (!parseInt(ifWinInput.value) > 0) return;
            let quantityInput = document.querySelector('#quantityInput');
            let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
            if (parseInt(ammountBet) > 0) {
                let betsTowin = bets.bets.map((bet) => {
                    let price = bet.Price.toString().slice(-4);
                    return {
                        price: price
                    }
                })

                ifWinInput.disabled = true;
                quantityInput.disabled = true;

                let totalToWin = getToWin(betsTowin.slice(0, e.target.value), parseInt(ammountBet));
                // console.log({ totalToWin });
                let totalToWiniF = getToWin(betsTowin.slice(e.target.value, betsTowin.length), parseInt(ifWinInput.value));
                // console.log({ totalToWiniF });
                let toCollect = totalToWin + totalToWiniF;
                setTotalToWin(totalToWin);
                setTotalToWinIF(totalToWiniF);
            }

            setBets({
                ...bets,
                ammount: parseInt(ammountBet),
                betsIf: parseInt(e.target.value),
                IfWinQuantity: parseInt(ifWinInput.value),
                readyToSubmit: true
            })

            console.log({
                ...bets,
                ammount: parseInt(ammountBet),
                IfWin: parseInt(e.target.value),
                IfWinQuantity: parseInt(ifWinInput.value)
            });

            setBetToAdd({
                TeamCode: '',
                PlayType: '',
                Quantity: 0,
                IfWin: 0
            })
            e.target.value = '';

        }
    }

    const handleIfWinblur = (e) => {
        // when blur and bets length is bigger than 0 set in bets 
        if (e.target.value && bets.bets.length > 0) {
            setBets({
                ...bets,
                readyToSubmit: true
            })
        }
    }
    useEffect(() => {
        let IfWinQuantity = document.querySelector('#IfWinQuantity');
        if (IfWinQuantity) {
            IfWinQuantity.addEventListener('keydown', handleIfWinChange);
            // un focus event listener
            IfWinQuantity.addEventListener('blur', handleIfWinblur);
            return () => {
                IfWinQuantity.removeEventListener('keydown', handleIfWinChange);
                IfWinQuantity.removeEventListener('blur', handleIfWinChange);
            }
        }
    }, [IfWinQuantity])

    const handleUnFocusTeamCode = (e) => {
        // if(
        // reset all inputs
        let ifWinInput = document.querySelector('#ifWinInput');
        let quantityInput = document.querySelector('#quantityInput');
        let IfWinQuantity = document.querySelector('#IfWinQuantity');
        if (IfWinQuantity) {
            IfWinQuantity.remove();
        }
        if (ifWinInput) {
            ifWinInput.disabled = false;
            ifWinInput.value = '';
        }
        if (quantityInput) {
            quantityInput.disabled = false;
            quantityInput.value = '';
        }
        setTotalToWin(0);
        setTotalToWinIF(0);
        // set bets to empty only save the bets.bets array
        let betsToSave = bets.bets;
        setBets({
            ...bets,
            bets: betsToSave,
            betsIf: 0,
            ammount: 0,
            IfWinQuantity: 0,
            readyToSubmit: false
        })
    }
    useEffect(() => {
        if (bets.bets?.length > 0) {
            let teamCode = document.querySelector('#TeamCode');
            teamCode.addEventListener('focus', handleUnFocusTeamCode);

            return () => {
                teamCode.removeEventListener('focus', handleUnFocusTeamCode);
            }
        }
    }, [bets])

    useEffect(() => {
        let ifWinInput = document.querySelector('#ifWinInput');
        ifWinInput.addEventListener('keydown', handleEnterKey);

        return () => {
            ifWinInput.removeEventListener('keydown', handleEnterKey);
        }
    }, [bets])

    useEffect(() => {
        let quantityInput = document.querySelector('#quantityInput');
        quantityInput.addEventListener('keydown', handleQuantityEnterKey);

        return () => {
            quantityInput.removeEventListener('keydown', handleQuantityEnterKey);
        }
    }, []);

    const handleFocusTeamCode = (e) => {
        if (e.key === 'Enter' && bets.bets.length === 0 && e.target.id !== 'TeamCode') {
            document.querySelector('#TeamCode').focus();
            document.querySelector('#TeamCode').select();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleFocusTeamCode);

        return () => {
            document.removeEventListener('keydown', handleFocusTeamCode);
        }

    }, [bets])

    const handleQuantityEnterKey = (e) => {
        if (e.key === 'Enter') {
            document.querySelector('#ifWinInput').focus();
            document.querySelector('#ifWinInput').select();
        }
    }

    const handleSpaceKey = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
            if (bets.bets.length > 0) {
                document.querySelector('#quantityInput').focus();
                document.querySelector('#quantityInput').select();
            } else {
                document.querySelector('#TeamCode').focus();
                document.querySelector('#TeamCode').select();
            }
        }
    }

    const handleEnterCodeTeam = async (e) => {
        // console.log("EEEEEEEEEE", e)
        if (e.target.value == '' && e.key === 'Enter') {
            e.preventDefault();
            // console.log("PASANDOOO")
            document.querySelector('#quantityInput').focus();
            document.querySelector('#quantityInput').select();
        }
    }

    const handleLogin = async (e) => {
        await redApi.get(`/users/login/as/${e.target.value}`).then((res) => {
            if (!cookie.get("loginAs")) {
                cookie.set("loginAs", res.data.token, { path: "/" });
                window.location.href = "/client";
                return;
            }
            cookie.remove("loginAs", { path: "/" });
            cookie.set("loginAs", res.data.token, { path: "/" });
            // window.location.href = "/client";
        });
    }

    useEffect(() => {
        document.addEventListener('keydown', handleSpaceKey);

        return () => {
            document.removeEventListener('keydown', handleSpaceKey);
        }
    }, [bets])




    const getCurrentUser = async () => {
        await redApi.get(`/verify`)
            .then(res => {
                setTerminalLogged(res.data)
            })
            .catch(err => console.log(err));
    }
    const [terminalLogged, setTerminalLogged] = useState({})
    const [terminalsToLogin, setTerminalsToLogin] = useState([]);
    const getTerminals = async () => {
        if (terminalLogged && terminalLogged.userLevel < 3) {
            await redApi.get('/users/getterminalsofconsortium')
                .then(res => {
                    setTerminalsToLogin(res.data);
                }).catch(err => console.log(err));
        }
    }
    useEffect(() => {
        getCurrentUser();
    }, [])
    useEffect(() => {
        getTerminals().catch(err => console.log(err));;
    }, [terminalLogged])

    return (
        <div className="left_create-ticket">
            <div>
                <label htmlFor="TeamCode">Team</label>
                <input type="number" className="" name='TeamCode' id='TeamCode' value={betToAdd.TeamCode} onChange={handleChangeBetToAdd} onKeyDown={handleEnterCodeTeam} placeholder='COD'
                    onFocus={(e) => {
                        setMenuActive('HOME');
                        setOnlyNumbers(false);
                    }}
                />
            </div>
            <div>
                <label htmlFor="betTypeSelect">Type</label>
                <input type="text" className="" name='PlayType' value={betToAdd.PlayType} onChange={handleChangeBetToAdd} id="betTypeSelect" placeholder='PlayType'
                    onFocus={(e) => {
                        setMenuActive('PLAYTYPE');
                        setOnlyNumbers(false);
                    }}
                />
            </div>
            <div>
                <label htmlFor="quantityInput">Amount</label>
                <input type="number" name='Quantity' value={betToAdd.Quantity} onChange={handleChangeBetToAdd} id="quantityInput" onFocus={(e) => {
                    setMenuActive('BETAMOUNT');
                    setOnlyNumbers(true);
                }} />
            </div>
            <div>
                <label htmlFor="ifWinInput">IF bet</label>
                <input type="number" name='IfWin' value={betToAdd.IfWin} onChange={handleChangeBetToAdd} id="ifWinInput"
                    onFocus={(e) => {
                        setMenuActive('IFWIN');
                        setOnlyNumbers(true);
                    }} />
            </div>
        </div>
    )
}


