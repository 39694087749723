import React from 'react'

const TicketSettings = () => {
  return (
    <>
      <div className='d-flex flex-column gap-2 align-items-center'>
        <label htmlFor="ticketFooter1" className='text-nowrap'>Sports Footer #1</label>
        <textarea name="ticketFooter1" id="ticketFooter1" cols="100" rows="3"></textarea>
      </div>
      <div className='d-flex flex-column gap-2 align-items-center'>
        <label htmlFor="ticketFooter2" className='text-nowrap'>Lottery Footer #2</label>
        <textarea name="ticketFooter2" id="ticketFooter2" cols="100" rows="3"></textarea>
      </div>
      <div className='d-flex flex-column gap-2 align-items-center'>
        <label htmlFor="ticketFooter3" className='text-nowrap'>Global Footer</label>
        <textarea name="ticketFooter3" id="ticketFooter3" cols="100" rows="3"></textarea>
      </div>
    </>
  )
}

export default TicketSettings