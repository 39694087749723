import React from 'react'
import styled from 'styled-components';

const Layout = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px;
    height: 100vh;
    width: 100vw;
    background-color: #FCFCFC;
        `;
function F83Layout({ children }) {
  return (
    <Layout>
      {children}
    </Layout>
  )
}

export default F83Layout