import React, { useCallback, useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { redApi } from "../../../../config/Axios";

const Header = () => {
  const { league } = useParams();

  useEffect(() => {
    setLeagueActive(league);
  }, [league]);

  const [leagueActive, setLeagueActive] = useState("");
  const [menuItems, setMenuItems] = useState([]);

  useEffect(async () => {
    await redApi.get("/leagues?leaguesWithGames=true").then((res) => {
      let leaguesWithGames = res.data;
      if (leaguesWithGames.length > 0) {
        leaguesWithGames?.map((league) => {
          switch (league.name) {
            case "NBA":
              league.image = "/logos/NBA.png";
              break;
            case "MLB":
              league.image = "/logos/MLB.png";
              break;
            case "NHL":
              league.image = "/logos/NHL.png";
              break;
            case "Dominican":
              league.image = "/logos/Dominican.png";
              break;
            case "WNBA":
              league.image = "/logos/WNBA.png";
              break;
            case "NCAAF":
              league.image = "/logos/NCAAF.png";
              break;
            case "NFL":
              league.image = "/logos/NFL.png";
              break;
            case "UFC":
              league.image = "/logos/UFC.png";
              break;
            case "Boxing":
              league.image = "/logos/boxing.png";
              break;
            case "NCAAB":
              league.image = "/logos/NCAAB.png";
              break;
            case "UEFA Nations":
              league.image = "/logos/UEFANations.png";
              break;
            case "NPB":
              league.image = "/logos/NPB.png";
              break;
            case "CONCACAF":
              league.image = "/logos/CONCACAF.png";
              break;
            case "Libertador":
              league.image = "/logos/conmebollibertadores.png";
              break;
            case "NBA-S":
              league.image = "/logos/NBA-S.png";
              break;
            case "Belgium":
              league.image = "/logos/Belgium.png";
              break;
            case "England":
              league.image = "/logos/England.png";
              break;
            case "France":
              league.image = "/logos/France.png";
              break;
            case "Germany":
              league.image = "/logos/Germany.png";
              break;
            case "MLS":
              league.image = "/logos/MLS.png";
              break;
            case "Netherland":
              league.image = "/logos/Netherland.png";
              break;
            case "BOX/UFC":
              league.image = "/logos/BOX_UFC.png";
            default:
              if (!league.image) league.image = "/logos/logodefault.png";
              break;
          }
        });
      }
      setMenuItems(leaguesWithGames);
      console.log(leaguesWithGames);
    });
  }, []);

  return (
    <header className="bg-white bg-card">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg navbar-light bg-white d-flex justify-content-center py-0">
              {menuItems.length > 0 ? (
                menuItems?.map((item, index) => {
                  return (
                    <NavLink
                      key={index}
                      to={`/client/${item.name
                        .replaceAll(" ", "%20")
                        .replaceAll("/", "_")}`}
                      className={`navbar-brand ${leagueActive === item.name ? "active" : ""
                        }`}
                      onClick={() => setLeagueActive(item.name)}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "contain",
                        }}
                      />
                    </NavLink>
                  );
                })
              ) : (
                <div>No hay ligas con juegos</div>
              )}
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
