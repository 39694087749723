import Modal from "antd/es/modal/Modal"
import { useEffect, useState } from "react"
import "./reportes.scss"
import Tickets from "./menu/Tickets"
import Sales from "./menu/Sale"
import WinLoss from "./menu/WinLoss"
import Accounting from "./menu/Accounting"
import ScoreBox from "./menu/ScoreBox"
import WebUsers from "./menu/WebUsers"
import { redApi as api } from "../../../../config/Axios"

const ReportesModal = ({
    open,
    onCancel
}) => {

    const [menu, setMenu] = useState([
        {
            name: "Tickets",
            active: true,
            icon: "fas fa-ticket-alt"
        },
        {
            name: "Sales",
            active: false,
            icon: "fas fa-dollar-sign"
        },
        {
            name: "Winnings/Losses",
            active: false,
            icon: "fas fa-hand-holding-usd"
        },
        {
            name: "Accounting",
            active: false,
            icon: "fas fa-calculator"
        },
        {
            name: "Score Box",
            active: false,
            // icon scores
            icon: "fas fa-futbol"
        },
        // {
        //     name: "Web Users",
        //     active: false,
        //     icon: "fas fa-users"
        // }
    ])

    const handleMenu = (index) => {
        const newMenu = menu.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    active: true
                }
            } else {
                return {
                    ...item,
                    active: false
                }
            }
        })
        setMenu(newMenu)
    }

    const [posTerminals, setPosTerminals] = useState([]);
    const [consorcios, setConsorcios] = useState([]);
    useEffect(() => {
        api.get("/verify").then((res) => {
            setPosTerminals([res.data])
            setConsorcios([res.data.ownedBy])
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    return (
        <Modal
            title="Reportes"
            open={open}
            footer={null}
            width="100%"
            style={{ top: 20 }}
            onCancel={onCancel}
        >
            <div className="reportes-modal">
                <div className="reportes-modal__menu">
                    {
                        menu.map((item, index) => (
                            <div
                                key={index}
                                className={`reportes-modal__menu-item ${item.active ? "active" : ""}`}
                                onClick={() => handleMenu(index)}
                            >
                                <i className={item.icon}></i>
                                <span>{item.name}</span>
                            </div>
                        ))
                    }
                </div>
                <div className="reportes-modal__content"
                    style={{
                        maxHeight: "calc(100vh - 200px)",
                        overflowY: "auto"

                    }}
                >
                    {
                        menu.map((item, index) => (
                            <div
                                key={index}
                                className={`reportes-modal__content-item ${item.active ? "active" : ""}`}
                            >
                                {
                                    item.active && item.name === "Tickets" && <Tickets posTerminals={posTerminals} consorcios={consorcios} />
                                }
                                {
                                    item.active && item.name === "Sales" && <Sales posTerminals={posTerminals} consorcios={consorcios} />
                                }
                                {
                                    item.active && item.name === "Winnings/Losses" && <WinLoss posTerminals={posTerminals} consorcios={consorcios} />
                                }
                                {
                                    item.active && item.name === "Accounting" && <Accounting posTerminals={posTerminals} consorcios={consorcios} />
                                }
                                {
                                    item.active && item.name === "Score Box" && <ScoreBox posTerminals={posTerminals} consorcios={consorcios} />
                                }
                                {
                                    item.active && item.name === "Web Users" && <WebUsers posTerminals={posTerminals} consorcios={consorcios} />
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </Modal>
    )
}

export default ReportesModal