import React, { useCallback, useState, useEffect } from "react";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import { redApi } from "../../../../../config/Axios";


const Theme4_leagues_mobile = ({ setMenuActive }) => {
  const { league } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLeagueActive(league);
  }, [league]);

  const [leagueActive, setLeagueActive] = useState("");
  const [menuItems, setMenuItems] = useState([]);

  useEffect(async () => {
    await redApi.get("/leagues?leaguesWithGames=true").then((res) => {
      let leaguesWithGames = res.data;
      if (leaguesWithGames.length > 0) {
        leaguesWithGames?.map((league) => {
          league.image = `${process.env.REACT_APP_SERVER_URL}/LeagueImages/${league.name}.png`;
        });
      }
      setMenuItems(leaguesWithGames);
      console.log(leaguesWithGames);
    });
  }, []);

  // useEffect(() => {
  //     menuItems?.map(item => {
  //         if (league !== item.name) {
  //             navigate(`/client/${item.name.replaceAll(' ', '%20').replaceAll('/', '_')}`)
  //         }
  //     })
  // }, [menuItems]);

  return (
    <>
      {menuItems.length > 0 ? (
        <>
          {
            menuItems?.map((item, index) => {
              return (
                <div className="theme4_leagues_mobile" key={index}
                  onClick={() => setMenuActive("TEAMCODE")}
                >
                  <NavLink
                    to={`/client/${item.name
                      .replaceAll(" ", "%20")
                      .replaceAll("/", "_")}`}
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/logos/logodefault.png";
                      }}
                    />
                  </NavLink>
                  <div
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    {item.name.length > 10
                      ? item.name.substring(0, 10).split("-").join(" ") + "..."
                      : item.name}
                  </div>
                </div>
              );
            })
          }
        </>
      )
        : (
          <div>No hay ligas con juegos</div>
        )}
    </>
  );
};

export default Theme4_leagues_mobile;
