import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../structures/layout'
import CreateTerminal from './terminals/CreateTerminal'
import EditTerminal from './terminals/EditTerminal'

export default function Terminals() {
  return (
    <Layout>
        <Container className="bg-card p-4">
            <div className="row button-nav mb-4 d-flex align-items-center">
                <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
                    <button data-bs-toggle="modal" data-bs-target="#createUserModal">Crear</button>
                    <CreateTerminal />
                </div>
                <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
                    <button data-bs-toggle="modal" data-bs-target="#editTerminal">Editar</button>
                    <EditTerminal/>
                </div>
                <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
                    <button data-bs-toggle="modal" data-bs-target="#deleteUser">Eliminar</button>
                    <div className="modal fade" id="deleteUser" tabIndex="-1" aria-labelledby="deleteUserLabel"
                        aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                            <h5 className="modal-title" id="deleteUserLabel">Eliminar Usuario</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <Container fluid>
                                <div className="row">
                                    <form>
                                    <div className="col-12 mb-2 d-flex">
                                        <label htmlFor="editPass">Username:</label>
                                        <select name="deleteUsername" id="deleteUsername"></select>
                                    </div>
                                    </form>
                                </div>
                            </Container>
                            </div>
                            <div className="modal-footer d-flex justify-content-center">
                            <button type="button" className="btn btn-ok mb-2" data-bs-dismiss="modal" >Eliminar</button>
                            <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-2 col-lg-auto d-flex justify-content-center">
                    <button type="button">Refrescar</button>
                </div>
            </div>
            <form>
                <div className="row button-nav mb-4 d-flex align-items-center">
                    <div className="mb-2 col-12 col-md-2">
                        <label htmlFor="selectConsorcioTerminales">Consorcio</label>
                    </div>
                    <div className="mb-2 col-12 col-md-10">
                        <select name="selectConsorcioTerminales" id="selectConsorcioTerminales">
                            <option value="todos">Todos</option>
                        </select>
                    </div>
                </div>
            </form>

            <div className="row mb-4">
                <div className="col-12">
                    <div className="table-responsive-lg">
                        <table className="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Descripción</th>
                                    <th>Comprador?</th>
                                    <th>Consorcio</th>
                                </tr>
                            </thead>
                            <tbody id="tableTerminals">
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Container>
    </Layout>
  )
}
