import React,{useState} from 'react'
import './tableBody.css'

const TableBody = ({dataGroup, edit, selectedRow, setSelectedRow}) => {

  const handleRowClick = (row) => {
    setSelectedRow(row);
    edit(row);
  };
  return (
    <tbody className='table-body-container'>
      {
        dataGroup.map((row, i)=>(
          <tr 
          key={i} 
          onClick={() => handleRowClick(row)}
          style={{ cursor: 'pointer', backgroundColor: selectedRow === row ? 'red' : '' }}
          >
           <td>{row.name}</td>
           <td>{row.description}</td>
           <td>{row.terminals.length}</td>
          </tr>
        ))
      }
    </tbody>
  )
}

export default TableBody