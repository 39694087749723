import React, { useContext, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import ModalConsorcio from './modalConsorcio.jsx';
import { redApi } from '../../../../config/Axios';
import './consorcioTablas/consorcio.scss';
import { UserServices } from "../../../../services/userServices";
import validPrivileges from "../../../../utils/validatePriv.js";
import { AuthContext } from '../../../../auth/index.js';

let privilegesRequired = {
    create: 31,
    edit: 32,
    delete: 33,
}

const Consorcio = () => {
    const mountedRef = useRef(true);

    let buttons = ['Crear', 'Editar', 'Eliminar', 'Refrescar']
    const [modalCrear, setModalCrear] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [userPrivileges, setUserPrivileges] = useState([]);
    const { userLevel } = useContext(AuthContext);

    useEffect(() => {
        const loadUserPrivileges = async () => {
            if (userLevel !== 10) return
            const userPriv = await UserServices.getUserPrivileges('Consorcio');
            if (!mountedRef.current) return;
            setUserPrivileges(userPriv.values);
        }
        loadUserPrivileges();

        return () => mountedRef.current = false;
    }, []);

    const handleClick = (e) => {
        let text = e.target.innerText
        if (text === 'Crear') {
            if (userLevel === 2) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No tienes permisos para crear'
                })
                return
            }
            if (userLevel === 10) {
                let valid = validPrivileges(userPrivileges, [privilegesRequired.create]);
                if (valid !== true) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `${valid}`
                    })
                    return
                }
            }
            setModalCrear(true)
        }
        if (text === 'Editar') {
            if (userLevel === 10) {
                let valid = validPrivileges(userPrivileges, [privilegesRequired.edit]);
                if (valid !== true) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `${valid}`
                    })
                    return
                }
            }
            if (Object.keys(consorcioSeleccionado).length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No has seleccionado un consorcio'
                })
                return
            }
            setModalEditar(true)
        }
        if (text === 'Eliminar') {
            if (userLevel === 2) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No tienes permisos para eliminar'
                })
                return
            }
            if (userLevel === 10) {
                let valid = validPrivileges(userPrivileges, [privilegesRequired.delete]);
                if (valid !== true) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `${valid}`
                    })
                    return
                }
            }
            Swal.fire({
                title: 'Eliminar',
                text: `¿Estas seguro que deseas eliminar el consorcio ${consorcioSeleccionado.username}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar'
            }).then((result) => {
                if (result.isConfirmed) {
                    redApi.delete(`/users/${consorcioSeleccionado._id}`)
                        .then(() => {
                            Swal.fire(
                                'Eliminado!',
                                `El consorcio ${consorcioSeleccionado.username} ha sido eliminado.`,
                                'success'
                            )
                            getConsorcios()
                        })
                        .catch((err) => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: `${err}`
                            })
                        })
                }
            }
            )
        }
        if (text === 'Refrescar') {
            Swal.fire({
                title: 'Refrescar',
                text: '¿Estas seguro de refrescar la tabla?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Refrescar'
            }).then((result) => {
                if (result.isConfirmed) {
                    getConsorcios().then(() => {
                        Swal.fire(
                            'Refrescado!',
                            'La tabla ha sido refrescada.',
                            'success'
                        )
                    })
                }
            })
        }
    }

    const [consorcios, setConsorcios] = useState([])
    const [consorcioSeleccionado, setConsorcioSeleccionado] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const getConsorcios = async () => {
        setIsLoading(true)
        const { data } = await redApi.get('/users?userLevel=2&populates=limits,LimitRl,hembraLimits,buySellControl,priceSingle,leagueLimits,ponches')
        if (!mountedRef.current) return;
        setConsorcios(data)
        setIsLoading(false)
    }

    const [buySellControl, setBuySellControl] = useState([
        {
            "_id": "64c9944624c86f003ed30c40",
            "buyLimitLineRl": 15,
            "buyLimitRl": 7,
            "buyLimitOverUnder": 7,
            "buyLimitTicket": "Directo",
            "sellLimitLineRl": 1,
            "sellLimitRl": 1,
            "sellLimitOverUnder": 0,
            "sellLimitTicket": "Ninguno",
            "league": {
                "_id": "6447e2b929d06c3563c0f6c2",
                "name": "NBA",
            },
        },
        {
            "_id": "64c9944624c86f003ed30c43",
            "buyLimitLineRl": 20,
            "buyLimitRl": 10,
            "buyLimitOverUnder": 10,
            "buyLimitTicket": "Ninguno",
            "sellLimitLineRl": 10,
            "sellLimitRl": 0,
            "sellLimitOverUnder": 0,
            "sellLimitTicket": "Ninguno",
            "league": {
                "_id": "6447e2b929d06c3563c0f6e0",
                "name": "Soccer",
            },
        },
        {
            "_id": "64c9944724c86f003ed30c46",
            "buyLimitLineRl": 14,
            "buyLimitRl": 7,
            "buyLimitOverUnder": 7,
            "buyLimitTicket": "Directo",
            "sellLimitLineRl": 7,
            "sellLimitRl": 0,
            "sellLimitOverUnder": 0,
            "sellLimitTicket": "Directo",
            "league": {
                "_id": "64599eeba67a2fa501beb30d",
                "name": "WNBA",
            },
        },
        {
            "_id": "64c9944724c86f003ed30c49",
            "buyLimitLineRl": 15,
            "buyLimitRl": 10,
            "buyLimitOverUnder": 10,
            "buyLimitTicket": "Directo",
            "sellLimitLineRl": 7,
            "sellLimitRl": 0,
            "sellLimitOverUnder": 0,
            "sellLimitTicket": "Directo",
            "league": {
                "_id": "647638273c099ee0baa6cbcc",
                "name": "CFL",
            },
        },
        {
            "_id": "64c9944724c86f003ed30c4c",
            "buyLimitLineRl": 12,
            "buyLimitRl": 7,
            "buyLimitOverUnder": 7,
            "buyLimitTicket": "Directo",
            "sellLimitLineRl": 7,
            "sellLimitRl": 0,
            "sellLimitOverUnder": 0,
            "sellLimitTicket": "Directo",
            "league": {
                "_id": "647638273c099ee0baa6cbc9",
                "name": "NFL",
            },
        },
        {
            "_id": "64c9944724c86f003ed30c4f",
            "buyLimitLineRl": 25,
            "buyLimitRl": 7,
            "buyLimitOverUnder": 7,
            "buyLimitTicket": "Directo",
            "sellLimitLineRl": 7,
            "sellLimitRl": 0,
            "sellLimitOverUnder": 0,
            "sellLimitTicket": "Directo",
            "league": {
                "_id": "647638273c099ee0baa6cbcb",
                "name": "MNCAAB",
            },
        },
        {
            "_id": "64c9944724c86f003ed30c52",
            "buyLimitLineRl": 20,
            "buyLimitRl": 7,
            "buyLimitOverUnder": 7,
            "buyLimitTicket": "Directo",
            "sellLimitLineRl": 7,
            "sellLimitRl": 0,
            "sellLimitOverUnder": 0,
            "sellLimitTicket": "Directo",
            "league": {
                "_id": "64f0b69c796ce45fbfc65fcc",
                "name": "NCAAF",
            },
        },
        {
            "_id": "64c9944724c86f003ed30c5b",
            "buyLimitLineRl": 20,
            "buyLimitRl": 10,
            "buyLimitOverUnder": 10,
            "buyLimitTicket": "Ninguno",
            "sellLimitLineRl": 10,
            "sellLimitRl": 0,
            "sellLimitOverUnder": 0,
            "sellLimitTicket": "Ninguno",
            "league": {
                "_id": "64c989da075476d5a8185d72",
                "name": "WNCAAB",
            },
        },
        {
            "_id": "64c9944724c86f003ed30c61",
            "buyLimitLineRl": 15,
            "buyLimitRl": 10,
            "buyLimitOverUnder": 10,
            "buyLimitTicket": "Directo",
            "sellLimitLineRl": 10,
            "sellLimitRl": 0,
            "sellLimitOverUnder": 0,
            "sellLimitTicket": "Directo",
            "league": {
                "_id": "64c98a71075476d5a8185d74",
                "name": "College Hockey",
            },

        },
        {
            "_id": "64c9944724c86f003ed30c64",
            "buyLimitLineRl": 15,
            "buyLimitRl": 5,
            "buyLimitOverUnder": 5,
            "buyLimitTicket": "Directo",
            "sellLimitLineRl": 10,
            "sellLimitRl": 0,
            "sellLimitOverUnder": 0,
            "sellLimitTicket": "Directo",
            "league": {
                "_id": "64c98f5f075476d5a8185d75",
                "name": "Arena FootBall",
            },
        }
    ])
    const [leagueLimits, setLeagueLimits] = useState([])
    const [ponches, setPonches] = useState([])
    useEffect(() => {
        const getConsorciosAndGetCreateInfo = async () => {
            getConsorcios();
            const { data } = await redApi.get('/users/getCreateInfo')
            if (!mountedRef.current) return;
            setLeagueLimits(data.limitLeague)
            setPonches([
                {
                    "_id": "6466d34ecdc3de9df6b98634",
                    "status": false,
                    "league": {
                        "_id": "64483155774ea89f367d6c88",
                        "name": "CPBL",
                    },
                    "price": "-110",
                    "user": "6466d347cdc3de9df6b97810",
                    "__v": 0,
                    "lines": [
                        "strikeoutsPlus",
                        "strikeoutsMinus"
                    ]
                },
                {
                    "_id": "6466d34ecdc3de9df6b98635",
                    "status": false,
                    "league": {
                        "_id": "6447e2b829d06c3563c0f662",
                        "name": "KBO",
                    },
                    "price": "-110",
                    "user": "6466d347cdc3de9df6b97810",
                    "__v": 0,
                    "lines": [
                        "strikeoutsPlus",
                        "strikeoutsMinus"
                    ]
                },
                {
                    "_id": "6466d34ecdc3de9df6b98636",
                    "status": false,
                    "league": {
                        "_id": "64483159774ea89f367d6cc2",
                        "name": "LMB",
                    },
                    "price": "-110",
                    "user": "6466d347cdc3de9df6b97810",
                    "__v": 0,
                    "lines": [
                        "strikeoutsPlus",
                        "strikeoutsMinus"
                    ]
                },
                {
                    "_id": "6466d34ecdc3de9df6b98637",
                    "status": false,
                    "league": {
                        "_id": "6447e2b729d06c3563c0f5e1",
                        "name": "MLB",
                    },
                    "price": "-110",
                    "user": "6466d347cdc3de9df6b97810",
                    "__v": 0,
                    "lines": [
                        "strikeoutsPlus",
                        "strikeoutsMinus"
                    ]
                },
                {
                    "_id": "6466d34ecdc3de9df6b98638",
                    "status": false,
                    "league": {
                        "_id": "6447e2b929d06c3563c0f692",
                        "name": "NPB",
                    },
                    "price": "-110",
                    "user": "6466d347cdc3de9df6b97810",
                    "__v": 0,
                    "lines": [
                        "strikeoutsPlus",
                        "strikeoutsMinus"
                    ]
                }
            ])
        }
        getConsorciosAndGetCreateInfo();

        return () => mountedRef.current = false;
    }, [])
    const handleClickSelect = (cons) => {
        if (consorcioSeleccionado._id === cons._id) {
            setConsorcioSeleccionado({})
            return
        }
        setConsorcioSeleccionado(cons)
    }
    const [lineLimitRlDefault, setLineLimitRlDefault] = useState(
        [
            {
                "_id": "647829809dfbd84a508cf774",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "6447e2b729d06c3563c0f5e1",
                    "name": "MLB",
                },
            },
            {
                "_id": "647829809dfbd84a508cf777",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "6447e2b829d06c3563c0f662",
                    "name": "KBO",
                },
            },
            {
                "_id": "647829809dfbd84a508cf77a",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "6447e2b929d06c3563c0f692",
                    "name": "NPB",
                },
            },
            {
                "_id": "647829809dfbd84a508cf77d",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "6447e2b929d06c3563c0f6c2",
                    "name": "NBA",
                },
            },
            {
                "_id": "647829819dfbd84a508cf780",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "6447e2b929d06c3563c0f6e0",
                    "name": "Soccer",
                },
            },
            {
                "_id": "647829819dfbd84a508cf783",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "6447e2ba29d06c3563c0f6f5",
                    "name": "NHL",
                },
            },
            {
                "_id": "647829819dfbd84a508cf789",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "64483155774ea89f367d6c88",
                    "name": "CPBL",
                },
            },
            {
                "_id": "647829829dfbd84a508cf78c",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "64483159774ea89f367d6cc2",
                    "name": "LMB",
                },
            },
            {
                "_id": "647829829dfbd84a508cf78f",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "64483161774ea89f367d6d3c",
                    "name": "BPS",
                },
            },
            {
                "_id": "647829829dfbd84a508cf792",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "6448316e774ea89f367d6e1c",
                    "name": "NBA-S",
                },
            },
            {
                "_id": "647829839dfbd84a508cf795",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "64599eeba67a2fa501beb30d",
                    "name": "WNBA",
                },
            },
            {
                "_id": "647829839dfbd84a508cf79b",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "647638273c099ee0baa6cbcc",
                    "name": "CFL",
                },
            },
            {
                "_id": "647829839dfbd84a508cf79e",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "647638273c099ee0baa6cbca",
                    "name": "Liga Invernal",
                },
            },
            {
                "_id": "647829839dfbd84a508cf7a1",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "647638273c099ee0baa6cbc9",
                    "name": "NFL",
                },
            },
            {
                "_id": "647829849dfbd84a508cf7a4",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "647638273c099ee0baa6cbcb",
                    "name": "MNCAAB",
                },
            },
            {
                "_id": "647829849dfbd84a508cf7ad",
                "minRl": "0.0",
                "maxRl": "0.0",
                "league": {
                    "_id": "6477a4c659ea9f186575e2a2",
                    "name": "XFL",
                },
            }
        ]
    )
    const getlineLimitRlDefault = async () => {

        return [
            [
                {
                    "_id": "647829809dfbd84a508cf774",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "6447e2b729d06c3563c0f5e1",
                        "name": "MLB",
                    },
                },
                {
                    "_id": "647829809dfbd84a508cf777",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "6447e2b829d06c3563c0f662",
                        "name": "KBO",
                    },
                },
                {
                    "_id": "647829809dfbd84a508cf77a",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "6447e2b929d06c3563c0f692",
                        "name": "NPB",
                    },
                },
                {
                    "_id": "647829809dfbd84a508cf77d",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "6447e2b929d06c3563c0f6c2",
                        "name": "NBA",
                    },
                },
                {
                    "_id": "647829819dfbd84a508cf780",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "6447e2b929d06c3563c0f6e0",
                        "name": "Soccer",
                    },
                },
                {
                    "_id": "647829819dfbd84a508cf783",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "6447e2ba29d06c3563c0f6f5",
                        "name": "NHL",
                    },
                },
                {
                    "_id": "647829819dfbd84a508cf786",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "6447fa77a71c4927378334a0",
                        "name": "Do Brasil",
                    },
                },
                {
                    "_id": "647829819dfbd84a508cf789",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "64483155774ea89f367d6c88",
                        "name": "CPBL",
                    },
                },
                {
                    "_id": "647829829dfbd84a508cf78c",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "64483159774ea89f367d6cc2",
                        "name": "LMB",
                    },
                },
                {
                    "_id": "647829829dfbd84a508cf78f",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "64483161774ea89f367d6d3c",
                        "name": "BPS",
                    },
                },
                {
                    "_id": "647829829dfbd84a508cf792",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "6448316e774ea89f367d6e1c",
                        "name": "NBA-S",
                    },
                },
                {
                    "_id": "647829839dfbd84a508cf795",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "64599eeba67a2fa501beb30d",
                        "name": "WNBA",
                    },
                },
                {
                    "_id": "647829839dfbd84a508cf798",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "645e4f198c7dcb18ffff6c80",
                        "name": "BOX/UFC",
                    },
                },
                {
                    "_id": "647829839dfbd84a508cf79b",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "647638273c099ee0baa6cbcc",
                        "name": "CFL",
                    },
                },
                {
                    "_id": "647829839dfbd84a508cf79e",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "647638273c099ee0baa6cbca",
                        "name": "Liga Invernal",
                    },
                },
                {
                    "_id": "647829839dfbd84a508cf7a1",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "647638273c099ee0baa6cbc9",
                        "name": "NFL",
                    },
                },
                {
                    "_id": "647829849dfbd84a508cf7a4",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "647638273c099ee0baa6cbcb",
                        "name": "MNCAAB",
                    },
                },
                {
                    "_id": "647829849dfbd84a508cf7aa",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "647638273c099ee0baa6cbcd",
                        "name": "EuroLeague",
                    },
                },
                {
                    "_id": "647829849dfbd84a508cf7ad",
                    "minRl": "0.0",
                    "maxRl": "0.0",
                    "league": {
                        "_id": "6477a4c659ea9f186575e2a2",
                        "name": "XFL",
                    },
                }
            ]
        ]




        await redApi.get('/linesConfig/lineLimitRl')
            .then(res => {
                console.log("a", res)
                setLineLimitRlDefault(res.data)
            })
    }
    useEffect(() => {
        getlineLimitRlDefault();

        return () => mountedRef.current = false;
    }, [])
    return (
        <div className="container-fluid">
            <div className="row gap-3">
                <div className="col-6 mb-1">
                    <div className="row gap-1">
                        {buttons.map((item, index) => {
                            return (
                                <div key={index} className="col-2 p-0 m-0">
                                    <button className="btn btn-outline-primary" onClick={handleClick} style={{ fontSize: '12px' }}>{item}</button>
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
                <div className="col-12" id='table_container'>
                    <table className="">
                        <thead>
                            <tr className="">
                                <th className="">Nombre</th>
                                <th className="">Descripcion</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ? <tr><td colSpan="2" className="text-center">Cargando...</td></tr> :
                                    consorcios && consorcios.map((item, index) => {
                                        return (
                                            <tr key={index} onClick={() => handleClickSelect(item)} className={`${consorcioSeleccionado._id === item._id ? 'row-selected' : ''}`}>
                                                <td className="">{item.username}</td>
                                                <td className={``} style={item.description ? null : { fontSize: '12px' }}>{item.description ? item.description : 'N/A'}</td>
                                            </tr>
                                        )
                                    }
                                    )}
                        </tbody>
                    </table>
                </div>
            </div>
            {
                modalEditar ? <ModalConsorcio userLevel={userLevel} ponches={ponches} lineLimitRlDefault={lineLimitRlDefault} buySellControl={buySellControl} leagueLimits={leagueLimits} setModalEditar={setModalEditar} modalEditar={modalEditar} setConsorcioSeleccionado={setConsorcioSeleccionado} consorcioSeleccionado={consorcioSeleccionado} getConsorcios={getConsorcios} /> : null
            }
            {
                modalCrear ? <ModalConsorcio userLevel={userLevel} ponches={ponches} lineLimitRlDefault={lineLimitRlDefault} buySellControl={buySellControl} leagueLimits={leagueLimits} setModalCrear={setModalCrear} modalCrear={modalCrear} getConsorcios={getConsorcios} setConsorcios={setConsorcios} /> : null
            }

        </div>
    )
}

export default Consorcio
