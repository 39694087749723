
const PreciosPonches = ({ createConsorcios, setCreateConsorcios }) => {

    const handleChangeInputs = (e, index) => {
        const { name, value } = e.target;
        if (name !== 'status') {
            const list = [...createConsorcios.ponches];
            list[index][name] = value;
            setCreateConsorcios({ ...createConsorcios, ponches: list });
        } else {
            const list = [...createConsorcios.ponches];
            list[index][name] = e.target.checked;
            setCreateConsorcios({ ...createConsorcios, ponches: list });
        }
    }

    return (
        <div className="row" id='ConsorcioTlmtLiga'>
            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <h6 className='text-center h2 m-2'>Precios de los Ponches</h6>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-5 d-flex justify-content-center align-items-center gap-4">
                                <label className='labelGeneral'>Ligas</label>
                                <select className="form-control">
                                    <option value="todas">Todas</option>
                                    <option value="mayor-league-baseball">Major League Baseball</option>
                                    <option value="nfl">NFL</option>
                                    <option value="nba">NBA</option>
                                    <option value="ncaa">NCAA</option>
                                    <option value="nhl">NHL</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col">Liga</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    createConsorcios.ponches.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input type="checkbox" checked={item.status} name="status" onChange={(e) => handleChangeInputs(e, index)}
                                                    />
                                                </td>
                                                <td>{item.league.name}</td>
                                                <td>
                                                    <input type="number" className="form-control" value={item.price} name="price" onChange={(e) => handleChangeInputs(e, index)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreciosPonches