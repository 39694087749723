import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../auth';
import consorcio1 from "../assets/consorcio_img/consorcio1.png"
import consorcio2 from "../assets/consorcio_img/consorcio2.png"
const FaviconChanger = () => {
    const { userName } = useContext(AuthContext);
    console.log("data", userName)


  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/png';
    link.rel = 'shortcut icon';

    if (userName === 'caracas') {
      link.href = consorcio2;
    } else if (userName === 'boston') {
      link.href = consorcio1;
    } else {
    //   link.href = '%PUBLIC_URL%/default_favicon.png';
      link.href = "/sports_favicon.png";
    }

    document.getElementsByTagName('head')[0].appendChild(link);
  }, [userName]);

  return null;
};

export default FaviconChanger;
