
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';

//ADMIN
import HomeAdmin from '../components/admin/pages/Home';
import Users from '../components/admin/pages/Users';
// import NotFoundAdmin from '../components/admin/pages/NotFound';
import Central from '../components/admin/pages/Central';
import Terminals from '../components/admin/pages/Terminals';
import Prizes from '../components/admin/pages/Prizes';
import Accounting from '../components/admin/pages/Accounting';
import Boat from '../components/admin/pages/Boat';
import BoatBankers from '../components/admin/pages/BoatBankers';
import Draw from '../components/admin/pages/Draw';
import VentaAdmin from '../components/admin/pages/VentaAdmin';
import Monitor from '../components/admin/pages/Monitor';
import Messages from '../components/admin/pages/Messages';
import AdminTools from '../components/admin/pages/AdminTools';
import WebUsers from '../components/admin/pages/WebUsers';
import Reports from '../components/admin/pages/Reports';
import { Horarioslineas } from '../components/admin/pages/Horarioslineas';
import { Configuracion } from '../components/admin/pages/Configuracion';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../auth/context/AuthContext';
import validPrivileges from '../utils/validatePriv';
import { UserServices } from '../services/userServices';
import { WhatsappSection } from '../components/admin/pages/whatsapp/WhatsAppSection';
import Body from '../components/admin/pages/support/support';
import Table from '../components/admin/pages/limitXconsorcio/table/Table';
import Index from '../components/admin/pages/limitXconsorcio/index/Index';
import IndexGR from '../components/admin/pages/grupoDeReportes/indexGR/IndexGR';
// import { redApi } from '../config/Axios';
// import { useEffect, useState } from 'react';
/*
import Ventas from '../components/admin/pages/Ventas';
import Consorcios from '../components/admin/pages/configuracion/Consorcios';
import Terminales from '../components/admin/pages/configuracion/Terminales';
import Restricciones from '../components/admin/pages/configuracion/Restricciones';
import Jugadores from '../components/admin/pages/configuracion/Jugadores';
import ControlCompra from '../components/admin/pages/configuracion/ControlCompra';
import ControlVenta from '../components/admin/pages/configuracion/ControlVenta';
import Precios from '../components/admin/pages/configuracion/Precios';
import Tickets from '../components/admin/pages/Reportes/Tickets';
import Monitor from '../components/admin/pages/Reportes/Monitor';
import GananciaPerdida from '../components/admin/pages/Reportes/GananciaPerdida';
import CxcV2 from '../components/admin/pages/Reportes/CxCv2';
import IngresoEgreso from '../components/admin/pages/Reportes/IngresoEgreso';
import VentaGeneral from '../components/admin/pages/Reportes/VentaGeneral';
import Resumen from '../components/admin/pages/Reportes/Resumen';
import ScoreBox from '../components/admin/pages/Reportes/ScoreBox';
import WebUsers from '../components/admin/pages/Reportes/WebUsers';
import Mensajeria from '../components/admin/pages/Mensajeria';
*/
let privilegesRequired = {
  hyr: 7,
}

export default function AdminRoutes() {
  const { logged, userLevel } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userPrivileges, setUserPrivileges] = useState([]);
  useEffect(async () => {
    let userPriv = await UserServices.getUserPrivileges('General');
    if (userPriv) {
      setUserPrivileges(userPriv.values);
    }
  }, [UserServices]);

  const [validPriv, setValidPriv] = useState(true);
  useEffect(() => {
    if (userPrivileges.length === 0) return;
    let valid = validPrivileges(userPrivileges, [privilegesRequired.hyr]);
    if (valid !== true) {
      setValidPriv(false);
    } else {
      setValidPriv(true);
    }
  }, [userPrivileges]);


  // useEffect(() => {
  //   console.log('validPriv', validPriv);
  // }, [validPriv]);


  if (!logged) {
    return navigate('/')
  }

  if (userLevel >= 3 && userLevel !== 10 && userLevel !== 5) {
    return (
      <Routes>
        <Route path='/*' element={<Navigate to="/client/NBA" />} />
      </Routes>
    )
  }
  // if (userLevel === 10) {
  //   return (
  //     <Routes>
  //       <Route path='/horarioslineas/:liga' element={<Horarioslineas />} />
  //       <Route path='/*' element={<Navigate to="/admin/horarioslineas/MLB?table=lineas" />} />
  //     </Routes>
  //   )
  // }

  return (
    <Routes>

      {/* ADMIN */}

      <Route path='/' element={<HomeAdmin />} />
      <Route path='/users' element={<Users />} />
      <Route path='/central' element={<Central />} />
      <Route path='/terminals' element={<Terminals />} />
      <Route path='/prizes' element={<Prizes />} />
      <Route path='/accounting' element={<Accounting />} />
      <Route path='/reports' element={<Reports />} />
      <Route path='/boat' element={<Boat />} />
      <Route path='/boatbankers' element={<BoatBankers />} />
      <Route path='/draw' element={<Draw />} />
      <Route path='/venta-admin' element={<VentaAdmin />} />
      <Route path='/monitor' element={<Monitor />} />
      <Route path='/messages' element={<Messages />} />
      <Route path='/tools' element={<AdminTools />} />
      <Route path='/webusers' element={<Reports currentTable="Web users" />} />
      <Route path='/support' element={<Body />} />
      <Route path='/limit-consorcio' element={<Index />} />
      <Route path='/group-reports' element={<IndexGR />} />

      {userLevel === 1 && <Route path='/whatsapp' element={<WhatsappSection />} />}
      {
        validPriv && userLevel !== 2 ? <Route path='/horarioslineas/:liga' element={<Horarioslineas />} /> : null
      }
      {
        !validPriv ? <Route path='/horarioslineas/:liga' element={<Navigate to="/admin" />} /> : null
      }

      <Route path='/configuracion' element={<Configuracion />} />

      <Route path='/*' element={<Navigate to="/admin" />} />

    </Routes>
  )
}
