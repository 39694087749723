import React from 'react';

function LoadingScreen({children = null}) {
    return (
        <div className='d-flex justify-content-center flex-column align-items-center w-100 vh-100'>
            <span className="loader"></span>
            <span className="loader-words"></span>
        </div>
    );
}

export default LoadingScreen;
