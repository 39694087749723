import moment from 'moment'
import React from 'react'

const Monitoring_t4_mobile = () => {
  return (
    <div className='menu_container'>
      <div className='top_container'>
        <div>
          <div className='_3_inputs p-0'>
            <div className='date_container'>
              <label htmlFor="date">From</label>
              <input type="date" name="" id="" defaultValue={moment().format("YYYY-MM-DD")} />
            </div>
            <div className='date_container'>
              <label htmlFor="date">To</label>
              <input type="date" name="" id="" defaultValue={moment().format("YYYY-MM-DD")} />
            </div>
            <div className='date_container'>
              <label htmlFor="date">Type:</label>
              <select name="" id="">
                <option value="">All</option>
                <option value="cancelled">Cancelled</option>
                <option value="pending">Pending</option>
                <option value="winner">Winner</option>
                <option value="loser">Loser</option>
              </select>
            </div>
          </div>
          <button type="button">
            search
          </button>
        </div>
      </div>
      <div className='bottom_container'>
        <div className='tables_container'>
          <div role='alert' className='alert alert-danger' style={{ textAlign: 'center' }}>
            No tickets where found
          </div>
        </div>
      </div>
    </div>
  )
}

export default Monitoring_t4_mobile