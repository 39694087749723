import { useContext, useEffect, useState } from "react"
import Swal from 'sweetalert2'
import { AuthContext } from "../../auth"
import { redApi } from "../../config/Axios";
import { UserServices } from "../../services/userServices";
import validPrivileges from "../../utils/validatePriv";

const privilegesRequired = {
    create: 23,
    edit: 24,
    delete: 25,
}
const privilegesRequiredGrupo = {
    create: 27,
    edit: 28,
    delete: 29,
}

export const useUsuarios = () => {
    const [allConsorcios, setAllConsorcios] = useState([])
    const [allgroups, setAllGroups] = useState([])
    const [dataTable, setDataTable] = useState([]);
    const [modalCrear, setModalCrear] = useState(false)
    const [modalCrearGrupo, setModalCrearGrupo] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [onLoad, setOnLoad] = useState(false)
    const [userPrivileges, setUserPrivileges] = useState([]);
    const [userSelected, setUserSelected] = useState(null)
    const [groupSelect, setGroupSelect] = useState(null)

    const { userLevel } = useContext(AuthContext);

    const getAllGroups = async () => {
        setOnLoad(true)
        await redApi.get(`/users/groups/get`)
            .then(async (res) => {
                let groups = res.data
                setAllGroups(groups)
                setDataTable(groups)
                await redApi.get(`/users?userLevel=2&populates=ownerOf,limits,LimitRl,hembraLimits,buySellControl`)
                    .then(res => {
                        setAllConsorcios(res.data)
                        setOnLoad(false)
                    })
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    }
    const deleteGroup = async (group) => {
        await redApi.delete(`/users/groups/delete/${group._id}`)
            .then(res => {
                Swal.fire({
                    icon: 'success',
                    title: `Grupo ${group?.name} eliminado!`,
                    showConfirmButton: false,
                    timer: 1500
                })
                getAllGroups()
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo salio mal!',
                })
            });
    }
    const handleClickSelect = (group) => {
        setUserSelected(null)
        if (groupSelect?._id === group._id) {
            setGroupSelect(null)
            return
        }
        setGroupSelect(group)
    }
    const handleUserSelected = (user) => {
        if (userSelected?._id === user._id) {
            setUserSelected(null)
            return
        }
        setUserSelected(user)
    }
    const eliminarUsuario = async (user) => {
        await redApi.delete(`/users/${user._id}`)
            .then(res => {
                Swal.fire({
                    icon: 'success',
                    title: `Usuario ${user?.username} eliminado!`,
                    showConfirmButton: false,
                    timer: 1500
                })
                getusuarios()
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo salio mal!',
                })
            });
    }
    const getusuarios = async () => {
        await redApi.get(`/users/groups/get`)
            .then(res => {
                let groups = res.data
                setAllGroups(groups)
                setDataTable(groups)
                // setDataTable(res.data)
            })
            .catch(err => console.log(err, 'error'));
    }

    const handleClick = (e) => {
        let text = e.target.innerText;
        let value = e.target.value;

        if (value === 'CrearUsuario') {
            clickCrearUsuario();
        }
        if (value === 'CrearGrupo') {
            setGroupSelect(null)
            clickCrearGrupo();
        }
        if (text === 'Editar') {
            clickEditar();
        }
        if (text === 'Eliminar') {
            clickEliminar();
        }
        if (text === 'Refrescar') {
            clickRefrescar();
        }
    }
    const clickCrearUsuario = () => {
        if (userLevel === 10) {
            let valid = validPrivileges(userPrivileges, [privilegesRequired.create]);
            if (valid !== true) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${valid}`,
                })
                return
            }
        }
        setModalCrear(true)
    }
    const clickCrearGrupo = () => {
        if (userLevel === 10) {
            let valid = validPrivileges(userPrivileges, [privilegesRequiredGrupo.create]);
            if (valid !== true) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${valid}`,
                })
                return
            }
        }
        setModalCrearGrupo(true)
    }
    const clickEditar = () => {
        if (userSelected) {
            if (userLevel === 10) {
                let valid = validPrivileges(userPrivileges, [privilegesRequired.edit]);
                if (valid !== true) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `${valid}`,
                    })
                    return
                }
            }
            setModalEditar(true)
            return
        }
        else if (groupSelect) {
            if (userLevel === 10) {
                let valid = validPrivileges(userPrivileges, [privilegesRequiredGrupo.edit]);
                if (valid !== true) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `${valid}`,
                    })
                    return
                }
            }
            setModalCrearGrupo(true)
            return
        }
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Debes seleccionar un usuario o grupo para editar!',
        })
    }
    const clickEliminar = () => {
        if (userSelected) {
            if (userLevel !== 1) {
                const valid = validPrivileges(userPrivileges, [privilegesRequired.delete]);
                if (valid !== true) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `${valid}`,
                    })
                    return
                }
            }
            Swal.fire({
                title: 'Eliminar',
                text: `¿Estas seguro de eliminar el usuario ${userSelected?.username}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar'
            }).then((result) => {
                if (result.isConfirmed) {
                    eliminarUsuario(userSelected)
                    return
                }
            })
            return
        } else if (groupSelect) {
            if (userLevel !== 1) {
                const valid = validPrivileges(userPrivileges, [privilegesRequiredGrupo.delete]);
                if (valid !== true) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `${valid}`,
                    })
                    return
                }
            }
            Swal.fire({
                title: 'Eliminar',
                text: `¿Estas seguro de eliminar el grupo ${groupSelect.name}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar'
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteGroup(groupSelect)
                    return
                }
            })
            return
        }
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Debes seleccionar un usuario o grupo para eliminar!',
        })
    }
    const clickRefrescar = () => {
        Swal.fire({
            title: 'Refrescar',
            text: '¿Estas seguro de refrescar la tabla?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Refrescar'
        }).then((result) => {
            if (result.isConfirmed) {
                getAllGroups().then(() => {
                    Swal.fire(
                        'Refrescado!',
                        'La tabla se ha refrescado.',
                        'success'
                    )
                })
            }
        })
    }

    useEffect(() => {
        getAllGroups()
    }, []);
    useEffect(() => {
        const loadUserSercives = async () => {
            if (userLevel == 10) {
                const userPriv = await UserServices.getUserPrivileges('Usuarios');
                const userPriv2 = await UserServices.getUserPrivileges('Grupos');
                setUserPrivileges([...userPriv.values, ...userPriv2.values]);
            }
        };
        loadUserSercives();
    }, [UserServices]);


    const states = {
        // userPrivileges, 
        allConsorcios,
        allgroups,
        dataTable,
        groupSelect,
        modalCrear,
        modalCrearGrupo,
        modalEditar,
        onLoad,
        userSelected,
    };
    const setStates = {
        // setAllConsorcios, 
        // setAllGroups, 
        // setDataTable, 
        // setOnLoad, 
        // setUserPrivileges, 
        // setUserSelected, 
        setGroupSelect,
        setModalCrear,
        setModalCrearGrupo,
        setModalEditar,
    };
    const methods = {
        // deleteGroup, 
        // eliminarUsuario, 
        // getAllGroups, 
        getusuarios,
        handleClick,
        handleClickSelect,
        handleUserSelected,
    };

    return {
        states,
        setStates,
        methods,
    }
}
