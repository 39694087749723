import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Swal from 'sweetalert2';
import { redApi } from '../../../config/Axios';
import { getConsortiums, getTerminalsWithBalance } from '../../../utils/getUsers';
import Layout from '../structures/layout'
import { getToday } from '../../../utils/dateToday';
import moment from 'moment';
import { AuthContext } from '../../../auth';

export default function Accounting() {
    const { userLevel } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState(null);
    const [consorcios, setConsorcios] = useState([]);
    const [consorcioSelected, setConsorcioSelected] = useState('');
    const [balance, setBalance] = useState(0);
    const [transactionType, setTransactionType] = useState('Ingreso');
    const [transactionSelected, setTransactionSelected] = useState(-1);
    const [transactions, setTransactions] = useState([]);
    const [transactionsHistory, setTransactionsHistory] = useState([]);
    const loadConsortiums = async () => {
        const consorcios = await getConsortiums();
        setConsorcios(consorcios);
    }

    const handlechangetransactionType = (e) => {
        setTransactionType(e.target.value);
        console.log(e.target.value)
        console.log({ transactionType })
    }

    const getTransactions = async () => { // pos, initDate and finalDate only used for get in range of last 30 days using moment
        try {
            const { data } = await redApi.get("/accountings/transHist", {
                params: {
                    pos: userSelected ? userSelected._id : '',
                    initDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                    finalDate: moment().format('YYYY-MM-DD')
                }
            });
            setTransactionsHistory(data.balances.filter(e => e.ingreso !== 0 || e.egreso !== 0));
        } catch (error) {
            setTransactionsHistory([]);
        }

    }

    useEffect(() => {
        document.getElementById('FechaAcc').value = getToday();
        loadConsortiums().catch(e => console.log(e));
    }, [])

    const loadTerminals = async () => {
        const terminals = await getTerminalsWithBalance(consorcioSelected);
        setUsers(terminals);
        setUserSelected(terminals[0]);
        setBalance(terminals[0].balance ? terminals[0].balance : 0)
    }

    useEffect(() => {
        loadTerminals().catch(e => console.log(e));
    }, [consorcios, consorcioSelected])
    useEffect(() => {
        if (userSelected?._id) {
            const posBalance = userSelected?.balance ? userSelected?.balance : 0;
            setBalance(posBalance)
            getTransactions().catch(e => console.log(e));
        }
    }, [userSelected?._id])


    const handlerPOSchanged = (user) => {
        const userInfo = users.find(e => e._id === user);
        setUserSelected(userInfo);
    }
    const handlerSubmitTransaction = (e) => {
        e.preventDefault();

        if (!e.target[5].value || e.target[5].value <= 0) {
            return Swal.fire({
                icon: "error",
                title: "Error en los parámetros",
                showConfirmButton: false,
                timer: 1500
            })
        }
        const userInfo = users.find(us => us._id === e.target[2].value);
        const user = userInfo.username;
        const exist = transactions.find(tr => tr.user === user && tr.transactionType === e.target[3].value);
        if (exist) {
            let transactionUpdated = exist;
            transactionUpdated.amount += Number(e.target[5].value);
            let newTransactions = transactions.filter(tr => tr !== exist);
            setTransactions([...newTransactions, transactionUpdated])
        } else {
            setTransactions([...transactions,
            {
                date: e.target[0].value,
                user,
                userId: e.target[2].value,
                transactionType: e.target[3].value,
                description: e.target[4].value,
                amount: Number(e.target[5].value)
            }
            ])
        }
        document.getElementById('selectPOSIngreso').focus();
    }
    const focusNextField = (evt, id) => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            document.getElementById(id).focus();
            id === 'cantidadIngreso' && document.getElementById(id).select();
        }
        return true;
    }

    useEffect(() => {
        window.addEventListener('keydown', handleDeleteTransaction)
        return () => {
            window.removeEventListener('keydown', handleDeleteTransaction)
        }

    }, [transactionSelected]);

    const handleDeleteTransaction = (e) => {
        if (transactionSelected >= 0 && e.key === 'Delete') {
            const newTrans = transactions.filter(e => transactions.indexOf(e) !== transactionSelected);
            setTransactions(newTrans)
            setTransactionSelected(newTrans.length - 1);
        }
    }

    const sendTransactions = async () => {
        // console.log("transactions.length: ", transactions.length)
        if (transactions.length > 0) {
            const res = await redApi.put('/accountings/addtransactions', { transactions });

            Swal.fire({
                icon: res.data.icon,
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
            });
            if (res.data.icon !== "error") {
                setTransactions([]);
                setTransactionSelected(-1);
                // loadTerminals()
                getTransactions()

            }
        } else {
            Swal.fire({
                icon: "error",
                title: "No hay transacciones.",
                showConfirmButton: false,
                timer: 1500
            })
        }

    }

  
    return (
        <Layout id="accounting">
            <Container className="bg-card p-4">
                <Row>
                    <Col xs={12}>
                        <h3 className="text-center">Ingresos y Egresos</h3>
                    </Col>
                    <Col xs={6}>
                        <form onSubmit={(e) => { handlerSubmitTransaction(e) }}>
                            {/* <form onSubmit="addTransaction() ;return false;"> */}
                            <Row className="button-nav mb-4 d-flex justify-content-end">
                                <Col xs={10}>
                                    <Row>
                                        <Col xs={3} className="mb-1">
                                            <label htmlFor="FechaAcc"
                                                style={{
                                                    color: "#202020",
                                                    fontSize: "0.8rem",
                                                    fontWeight: "600"
                                                }}
                                            >Fecha: </label>
                                        </Col>
                                        <Col xs={6} className="mb-1">
                                            <input type="date" id="FechaAcc" style={{
                                                color: "#202020",
                                                fontSize: "0.8rem",
                                                fontWeight: "600"
                                            }}
                                                defaultValue={moment().format("YYYY-MM-DD")}
                                            />
                                        </Col>
                                        <Col xs={3} className="mb-1"></Col>

                                        <Col xs={3} className="mb-1">
                                            <label htmlFor="selectConsorcioIngreso"
                                                style={{
                                                    color: "#202020",
                                                    fontSize: "0.8rem",
                                                    fontWeight: "600"
                                                }}
                                            >Consorcio</label>
                                        </Col>
                                        <Col xs={6} className="mb-1">
                                            <select defaultValue="" id="selectConsorcioIngreso" style={{
                                                color: "#202020",
                                                fontSize: "0.8rem",
                                                fontWeight: "600"
                                            }}
                                                onChange={(e) => { setConsorcioSelected(e.target.value) }}
                                                disabled={userLevel === 1 ? false : true}
                                            >
                                                {
                                                    userLevel === 1 ? (
                                                        <>
                                                            <option value="">Todos</option>
                                                            {consorcios.map((cons, index) => {
                                                                return (
                                                                    <option key={index} value={cons._id}>{cons.username}</option>
                                                                )
                                                            })}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {consorcios.map((cons, index) => {
                                                                return (
                                                                    <option key={index} value={cons._id}>{cons.username}</option>
                                                                )
                                                            })}
                                                        </>
                                                    )
                                                }

                                            </select>
                                        </Col>
                                        <Col xs={3} className="mb-1"></Col>

                                        <Col xs={3} className="mb-1">
                                            <label htmlFor="selectPOSIngreso" style={{
                                                color: "#202020",
                                                fontSize: "0.8rem",
                                                fontWeight: "600"
                                            }}>POS</label>
                                        </Col>
                                        <Col xs={6} className="mb-1">
                                            <select style={{
                                                color: "#202020",
                                                fontSize: "0.8rem",
                                                fontWeight: "600"
                                            }} id="selectPOSIngreso" value={userSelected?._id} onKeyDown={(e) => { focusNextField(e, 'selectTransIngreso') }} onChange={(e) => { handlerPOSchanged(e.target.value) }}>
                                                {/* <select id="selectPOSIngreso" onChange="loadBalanceOnChange()"> */}
                                                {
                                                    users.map((user, index) => (
                                                        <option key={index} value={user._id}>{user.username}</option>
                                                    ))
                                                }
                                            </select>
                                        </Col>
                                        <Col xs={3} className="mb-1 d-flex align-items-center">
                                            <span style={{ color: balance < 0 ? "red" : "#212529", fontWeight: "bold" }} id="balancePOSAccounting">Balance: {balance}</span>
                                        </Col>

                                        <Col xs={3} className="mb-1">
                                            <label htmlFor="selectTransIngreso" style={{
                                                color: "#202020",
                                                fontSize: "0.8rem",
                                                fontWeight: "600"
                                            }}>Tipo</label>
                                        </Col>
                                        <Col xs={6} className="mb-1">
                                            <select style={{
                                                color: "#202020",
                                                fontSize: "0.8rem",
                                                fontWeight: "600"
                                            }} value={transactionType} id="selectTransIngreso" onKeyDown={(e) => { focusNextField(e, 'cantidadIngreso') }} onChange={handlechangetransactionType}>
                                                {/* <select id="selectTransIngreso" onChange="changeDescription(); focusAmount();" onFocus={(this) => {this.selectedIndex = -1}}> */}
                                                <option value="Ingreso">Ingreso</option>
                                                <option value="Egreso">Egreso</option>
                                            </select>
                                        </Col>
                                        <Col xs={3} className="mb-1"></Col>


                                        <Col xs={3} className="mb-1">
                                            <label htmlFor="DescripcionIngreso" style={{
                                                color: "#202020",
                                                fontSize: "0.8rem",
                                                fontWeight: "600"
                                            }}>Descripcion</label>
                                        </Col>
                                        <Col xs={6} className="mb-1">
                                            <input style={{
                                                color: "#202020",
                                                fontSize: "0.8rem",
                                                fontWeight: "600"
                                            }} type="text" readOnly id="DescripcionIngreso" value={transactionType === "Ingreso" ? "Abono" : "Pago Ticket"} />
                                        </Col>
                                        <Col xs={3} className="mb-1"></Col>

                                        <Col xs={3} className="mb-1">
                                            <label style={{
                                                color: "#202020",
                                                fontSize: "0.8rem",
                                                fontWeight: "600"
                                            }} htmlFor="cantidadIngreso">Cantidad</label>
                                        </Col>
                                        <Col xs={6} className="mb-1">
                                            <input style={{
                                                color: "#202020",
                                                fontSize: "0.8rem",
                                                fontWeight: "600"
                                            }} type="number" id="cantidadIngreso" placeholder="0" />
                                        </Col>
                                        <Col xs={3} className="mb-1"></Col>

                                        <Col xs={3} className="mb-1">
                                        </Col>
                                        <Col xs={6} className="mb-1">
                                            <button id="btnSubmitIngresoEgreso" style={{
                                                fontSize: "0.8rem",
                                                fontWeight: "600",
                                                margin: "0px"
                                            }} type="submit">Ingresar Transacción</button>
                                        </Col>
                                        <Col xs={3} className="mb-1"></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                    <Col xs={6}>
                        <div className="table-responsive w-100" style={{ maxHeight: '400px' }} id='table_container'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>POS</th>
                                        <th>Tipo</th>
                                        <th>Descripción</th>
                                        <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactions.map((trs, i) => (
                                            
                                            <tr key={i}
                                                className={transactionSelected === i ? 'tr-active' : ''}
                                                onClick={() => setTransactionSelected(i)}
                                            >
                                                <td>{trs.date}</td>
                                                <td>{trs.user}</td>
                                                <td>{trs.transactionType}</td>
                                                <td>{trs.description}</td>
                                                <td>{trs.amount}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            {
                                transactions.length > 0 ? (
                                    <div className="d-flex justify-content-center">
                                        <button
                                            className="btn btn-primary"
                                            style={{ width: '200px', marginTop: '10px' }}
                                            onClick={() => {
                                                sendTransactions()
                                            }}
                                        >
                                            Enviar Transacciones
                                        </button>
                                    </div>
                                ) : null
                            }
                        </div>
                    </Col>
                </Row>

                {/* separator */}
                <div
                    style={{
                        width: '100%',
                        margin: '20px 0',
                        border: '1px #202020 dashed',
                        height: '0px',
                    }}
                ></div>

                <Row className="mb-4 d-flex justify-content-center">
                    <Col xs={12}>
                        <h3 className="text-center">Historial de Transacciones</h3>
                    </Col>
                    <Col xs={12}>
                        <div className="table-responsive border" style={{ height: "43vh" }} id='table_container'>
                            <table style={{ minHeight: "30px" }}>
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>POS</th>
                                        <th>Neto</th>
                                        <th>Ingreso</th>
                                        <th>Egreso</th>
                                        {/* <th>Tipo de Transaccion</th>
                                        <th>Descripción</th>
                                        <th>Cantidad</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactionsHistory.map((trs, i) => (
                                            <tr key={i}
                                            // className={transactionSelected === i ? 'tr-active' : ''} onClick={() => { setTransactionSelected(i) }}
                                            >
                                                <td>{moment(trs.date,'YYYY/MM/DD (ddd)').format('DD/MM/YYYY (ddd)')}</td>
                                                <td>{trs.user}</td>
                                                <td>{trs.neto}</td>
                                                <td>{trs.ingreso}</td>
                                                <td>{trs.egreso}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}
