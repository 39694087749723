import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../structures/layout'
import moment from 'moment'
import axios from 'axios'

export default function AdminTools() {

    const [token, setToken] = useState(null)
    useEffect(() => {
        axios.post(`http://localhost:8090/auth/login`, {
            "user": {
                "email": "admin@admin.com",
                "password": "admin"
            }
        })
            .then(res => {
                setToken(res.data.token.code)
            })
    }, [])

    const handleGetScores = async () => {
        axios.get(`http://localhost:8090/donbest/getTicketsScores`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                console.log(res.data)
            })
    }
    let menus = [
        {
            name: "Get Scores",
            action: handleGetScores
        }
    ]

    return (
        <Layout>
            <Container className="container bg-card p-4">
                <div className="row admin-tools">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="resultados-manual-tab" data-bs-toggle="tab" data-bs-target="#resultados-manual" type="button" role="tab" aria-controls="resultados-manual" aria-selected="true">Resultados</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="mensaje-ticket-tab" data-bs-toggle="tab" data-bs-target="#mensaje-ticket" type="button" role="tab" aria-controls="mensaje-ticket" aria-selected="false">Mensaje Ticket</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="cancel-time-tab" data-bs-toggle="tab" data-bs-target="#cancel-time" type="button" role="tab" aria-controls="cancel-time" aria-selected="false">Cancel Time</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="daily-message-tab" data-bs-toggle="tab" data-bs-target="#daily-message" type="button" role="tab" aria-controls="daily-message" aria-selected="false">Mensaje Diario</button>
                        </li>
                        {menus.map((menu, index) => {
                            return (
                                <li key={index}>
                                    <button onClick={menu.action}>{menu.name}</button>
                                </li>
                            )
                        })}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="resultados-manual" role="tabpanel" aria-labelledby="resultados-manual-tab">
                            <div className="row my-2">
                                <h2>Resultados</h2>
                            </div>
                            <form>
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-4 mb-2">
                                        <label htmlFor="typeLotResults"></label>
                                        <select id="typeLotResults">
                                            <option value="RD">Normal</option>
                                            <option value="US">Bolita</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-2">
                                        <label htmlFor="lotNameResults"></label>
                                        <select id="lotNameResults">
                                        </select>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-2">
                                        <label htmlFor="dateResults"></label>
                                        <input type="date" id="dateResults" defaultValue={moment().format("YYYY-MM-DD")} />
                                    </div>
                                </div>
                                <div id="inputsNormal" className="row mb-3">
                                    <div className="col-12 col-lg-4 mb-2 d-flex flex-column align-items-center">
                                        <span>Directo</span>
                                        <input type="number" min="0" max="99" maxlength="2" id="directResults" />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-2 d-flex flex-column align-items-center">
                                        <span>Pale</span>
                                        <input type="number" min="0" max="99" maxlength="2" id="paleResults" />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-2 d-flex flex-column align-items-center">
                                        <span>Tripleta</span>
                                        <input type="number" min="0" max="99" maxlength="2" id="tripletResults" />
                                    </div>
                                </div>
                                <div id="inputsBolita" className="row mb-3 d-none d-flex justify-content-center">
                                    <div className="col-12 col-lg-4 mb-2 d-flex flex-column align-items-center">
                                        <span>Pick 3</span>
                                        <input type="number" min="0" max="999" maxlength="3" id="pick3Results" />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-2 d-flex flex-column align-items-center">
                                        <span>Pick 4</span>
                                        <input type="number" min="0" max="9999" maxlength="4" id="pick4Results" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <button type="submit">Enviar</button>
                                    </div>
                                </div>
                            </form>
                            <div className="row pt-4 mb-3 border-top">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-striped table-hover">
                                            <thead id="theadResults">
                                                <tr>
                                                    <th>Lotería</th>
                                                    <th>Primero</th>
                                                    <th>Segundo</th>
                                                    <th>Tercero</th>
                                                </tr>
                                            </thead>
                                            <tbody id="tbodyResults"></tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="mensaje-ticket" role="tabpanel" aria-labelledby="mensaje-ticket-tab">
                            <div className="row my-2">
                                <h2>Mensaje del Ticket</h2>
                            </div>
                            <form id="newMessageForm">
                                <div className="row mb-2 d-flex justify-content-center align-items-center">
                                    <div className="col-12 col-lg-4 mb-2">
                                        <input type="checkbox" checked id="checkUsersMessage" />
                                        <label htmlFor="checkUsersMessage">Todos</label>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-2">
                                        <div id="divConsorcio" className="d-none">
                                            <label htmlFor="consorcioSelectMessage">Consorcio</label>
                                            <select id="consorcioSelectMessage"></select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2 d-flex justify-content-center">
                                    <div className="col-12 col-lg-4 mb-2">
                                        <label htmlFor="lotTypeMessage">Tipo: </label>
                                        <select id="lotTypeMessage">
                                            <option value="RD">Normal</option>
                                            <option value="US">Bolita</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-2">
                                        <label htmlFor="lotNameMessage">Lotería: </label>
                                        <select id="lotNameMessage">
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3 d-flex justify-content-center">
                                    <div className="col-8 d-flex flex-column align-items-center">
                                        <span>Mensaje Actual</span>
                                        <textarea disabled id="currentMessage" cols="30" rows="5"></textarea>
                                    </div>
                                    <div className="col-8 d-flex flex-column align-items-center">
                                        <span>Nuevo Mensaje</span>
                                        <textarea id="messagePrint" cols="30" rows="5"></textarea>
                                    </div>
                                </div>
                                <div className="row mb-3 d-flex justify-content-center">
                                    <div className="col-4">
                                        <button type="submit">Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="tab-pane fade" id="cancel-time" role="tabpanel" aria-labelledby="cancel-time-tab">

                            <div className="row my-2">
                                <h2>Tiempo de Cancelación</h2>
                            </div>
                            <form id="timeCancelForm">
                                <div className="row mb-3 d-flex justify-content-center">
                                    <div className="col-8 d-flex flex-column align-items-center">
                                        <span>Tiempo Actual</span>
                                        <input type="text" id="actualTimmer" disabled />
                                    </div>
                                    <div className="col-8 d-flex flex-column align-items-center">
                                        <span>Tiempo máximo para cancelar (minutos)</span>
                                        <input type="number" id="newTimmer" />
                                    </div>
                                </div>
                                <div className="row mb-3 d-flex justify-content-center">
                                    <div className="col-4">
                                        <button id="send" type="submit">Enviar</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="tab-pane fade" id="daily-message" role="tabpanel" aria-labelledby="daily-message-tab">
                            <div className="row my-2">
                                <h2>Mensaje Diario</h2>
                            </div>
                            <form id="newMessageForm" >

                                <div className="row mb-3 d-flex justify-content-center">
                                    <div className="col-8 d-flex flex-column align-items-center">
                                        <span>Mensaje Actual</span>
                                        <textarea disabled id="currentDailyMessage" cols="30" rows="5"></textarea>
                                    </div>
                                    <div className="col-8 d-flex flex-column align-items-center">
                                        <span>Nuevo Mensaje</span>
                                        <textarea id="messageDailyPrint" cols="30" rows="5"></textarea>
                                    </div>
                                </div>
                                <div className="row mb-3 d-flex justify-content-center">
                                    <div className="col-4">
                                        <button type="submit">Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Container>
        </Layout>
    )
}
