import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

function Theme4_Keyboard({
  setBetToAdd,
  betToAdd,
  handleBetAddToTable,
  onlyNumbers,
  setOnlyNumbers,
  menuActive,
  setMenuActive,
  handleBetPost,
  bets,
  setBets,
  ifBetSwitch = false,
  handleEnterKey,
  handleIfWinChange,
  setTotalToWin,
  setTotalToWinIF,
  betsIf,
  setBetsIf,
}) {

  function getToWin(bets, amount) {
    let result = 0;
    for (let i = 0; i < bets.length; i++) {

      if (bets[i].price.toString().includes('+') && bets[i].price.toString().includes('-')) {
      }
      const multiplier = bets[i].price >= 0
        ? bets[i].price / 100
        : 1 / ((bets[i].price * -1) / 100);
      result = ((result + amount) * multiplier + result);
    }
    result = Math.round(result);
    return result
  }
  let keysTeamCode = [
    7, 8, 9, 4, 5, 6, 1, 2, 3, 0
  ]
  let betsType = [
    'M',
    'MH',
    'MT1',
    'MT2',
    'MT3',
    'R',
    'H',
    'Q1',
    'Q2',
    'Q3',
    'Q4',
    'T1',
    'T2',
    'T3',
    'HR2',
    'AR',
    'P',
    '+',
    'H+',
    'Q1+',
    'Q2+',
    'Q3+',
    'Q4+',
    'T1+',
    'T2+',
    'T3+',
    'H2+',
    '-',
    'H-',
    'Q1-',
    'Q2-',
    'Q3-',
    'Q4-',
    'T1-',
    'T2-',
    'T3-',
    'H2-',
    'S+',
    'H1S+',
    'Q1S+',
    'Q2S+',
    'Q3S+',
    'Q4S+',
    'H2S+',
    'S-',
    'H1S-',
    'Q1S-',
    'Q2S-',
    'Q3S-',
    'Q4S-',
    'H2S-',
    'Y',
    'N',
    'K+',
    'K-',
    'F',
    'C',
    'V',
    'C+',
    'C-',
    'J1',
    'J2',
    'J3',
    'J4',
    'J5',
    'SE',
    'T',
    'T+',
    'T-',
    'V+',
    'V-',
    'E',
  ]
  function getCharacters() {
    let keys = []
    // Only push a letter if it doesn't exist already
    for (let i = 0; i < betsType.length; i++) {
      betsType[i].split('').forEach(letter => {
        if (!keys.includes(letter) && isNaN(letter)) {
          keys.push(letter)
        }
      })
    }
    // order alphabetically
    keys = [...keys, ...keysTeamCode]
    keys.sort()
    return keys
  }
  const [keysBets, setKeysBets] = useState(getCharacters())
  useEffect(() => {
    switch (menuActive) {
      case 'BETAMOUNT':
      case 'IFWIN':
        setOnlyNumbers(true)
        setKeysBets(keysTeamCode)
        break;
      default:
        setOnlyNumbers(false)
        setKeysBets(getCharacters())
        break;
    }
  }, [menuActive])

  function addKeyInputs(key) {
    switch (key) {
      case 'ENTER':
        console.log('ENTER')
        setOnlyNumbers(false)
        if (menuActive === 'BETAMOUNT') {
          console.log('BETAMOUNT')
          if (betsIf.length < 1) {
            let event = new KeyboardEvent('keydown', { key: 'Enter' })
            // handleBetPost(event)
            handleEnterKey(event)
            setMenuActive('HOME')
            return
          }
          // let event = new KeyboardEvent('keydown', { key: 'Enter' })
          // document.querySelector('#BETAMOUNT').dispatchEvent(event)
          document.querySelector('#ifWinInput').focus()
          setMenuActive('IFWIN')
          return
        }
        if (menuActive === 'IFWIN') {
          console.log('IFWIN')
          let event = new KeyboardEvent('keydown', { key: 'Enter' })
          // document.querySelector('#IFWIN').dispatchEvent(event)
          // setBets({
          //   ...bets,
          //   IfWinQuantity: betToAdd.IfWin
          // })
          handleIfWinChange(event)
          setMenuActive('IfWinQuantity')
          return
        }
        if (menuActive === 'IfWinQuantity') {
          console.log('IfWinQuantity', { bets })

          let event = new KeyboardEvent('keydown', { key: 'Enter' })
          handleIfWinChange(event)
          // handleEnterKey(event)
          setMenuActive('printing')
          let IfWinQuantity = document.querySelector('#IfWinQuantity2')
          IfWinQuantity.value = 0
          console.log('IfWinQuantity222', { bets })

          return
        }
        if (menuActive === 'printing') {
          console.log('printing')
          // const handleBetPost = async (e) => {
          //   if ((e.key === "*" || e.key === "Enter" || e === "Print&Send") && !isRunning) {
          let event = new KeyboardEvent('keydown', { key: 'Enter' })
          handleBetPost(event)
          setMenuActive('TeamCode')
          return
        }
        handleBetAddToTable({ key: 'Enter', target: { name: 'PlayType' } })
        break;
      default:
        switch (menuActive) {
          case 'HOME':
          case 'TEAMCODE':
            console.log('TeamCode')
            if (betToAdd.TeamCode.length >= 4) {
              setMenuActive('PlayType')
              setBetToAdd({
                ...betToAdd,
                PlayType: betToAdd.PlayType + key,
              })
              break;
            }
            setBetToAdd({
              ...betToAdd,
              TeamCode: betToAdd.TeamCode + key,
            })
            break;
          case 'PLAYTYPE':
            if (key === 'DEL') {
              setBetToAdd({
                ...betToAdd,
                PlayType: betToAdd.PlayType.slice(0, -1),
              })
              return
            }
            console.log('PLAYTYPE')
            setBetToAdd({
              ...betToAdd,
              PlayType: betToAdd?.PlayType + key,
            })
            break;
          case 'BetAmount':
            console.log('BetAmount')
            setBetToAdd({
              ...betToAdd,
              BetAmount: betToAdd.BetAmount + key,
            })
            break;
          case 'betTypeSelect':
            if (key === 'DEL') {
              setBetToAdd({
                ...betToAdd,
                PlayType: betToAdd.PlayType.slice(0, -1),
              })
              return
            }
            console.log('betTypeSelect')
            setBetToAdd({
              ...betToAdd,
              PlayType: betToAdd.PlayType + key,
            })
            break;
          case 'BETAMOUNT':
            if (key === 'DEL') {
              let quantity = (bets.ammount).toString().slice(0, -1)
              setBets({
                ...bets,
                ammount: parseInt(quantity),
              })
              setBetToAdd({
                ...betToAdd,
                Quantity: parseInt(quantity),
              })
              return
            }
            let quantityString = betToAdd.Quantity.toString()
            quantityString = quantityString + key.toString()
            console.log('quantityString', quantityString)
            setBetToAdd({
              ...betToAdd,
              Quantity: parseInt(quantityString),
            })
            setBets({
              ...bets,
              ammount: parseInt(quantityString),
            })
            // update total to win:
            // let BETAMOUNT = document.querySelector('#BETAMOUNT');
            // let ammountBet = (BETAMOUNT.value).toString() + key.toString();
            // if (parseInt(ammountBet) > 0) {
            //   let betsTowin = bets.bets.map((bet) => {
            //     let price = bet.Price.toString().slice(-4);
            //     return {
            //       price: price
            //     }
            //   })

            //   let totalToWin = getToWin(betsTowin, parseInt(ammountBet));
            //   console.log({ totalToWin, betsTowin, ammountBet })
            //   setTotalToWin(totalToWin);
            // }
            break;
          case 'IFWIN':
            if (key === 'DEL') {
              let ifwin = (betToAdd.IfWin).toString().slice(0, -1)
              setBetToAdd({
                ...betToAdd,
                IfWin: ifwin,
              })
              document.querySelector('#ifWinInput').value = parseInt(ifwin)
              return
            }
            let ifWinString = betToAdd.IfWin.toString()
            ifWinString = ifWinString + key.toString()
            // console.log('ifWinString', ifWinString)
            setBetToAdd({
              ...betToAdd,
              IfWin: parseInt(ifWinString),
            })
            document.querySelector('#ifWinInput').value = parseInt(ifWinString)
            break;
          case 'IfWinQuantity':
            let IfWinQ = document.querySelector('#IfWinQuantity2');

            if (key === 'DEL') {
              IfWinQ.value = (bets?.betsIf).toString().slice(0, -1)
              return
            }
            let IfWinQuantity = bets?.betsIf ? (bets?.betsIf).toString() : '';
            IfWinQuantity = IfWinQuantity + key.toString()
            // setBets({
            //   ...bets,
            //   betsIf: IfWinQuantity
            // })
            if (IfWinQ)
              IfWinQ.value = IfWinQuantity
            break;
          default:
            console.log('default', menuActive)
            break;
        }
        break;
    }
  }

  return (
    <div className={`mobileKeyboard ${menuActive === 'BETAMOUNT' || menuActive === 'IFWIN' ? 'mobileKeyboard--numbers' : ''}`}>
      {
        keysBets.map((key, index) => {
          return (
            <button key={index} className="keybutton" onClick={() => addKeyInputs(key)}>{key}</button>
          )
        })
      }
      <button
        className="keybutton"
        onClick={() => addKeyInputs('DEL')}
      >DEL</button>
      <button
        className="keybutton"
        onClick={() => addKeyInputs('ENTER')}
      >ENTER</button>
    </div>
  )
}

export default Theme4_Keyboard

