import React from 'react'
import F83Layout from '../../structures/theme-F83/F83Layout'
import F83left from '../../structures/theme-F83/F83left'
import F83right from '../../structures/theme-F83/F83right'
import styled from 'styled-components';
import F83events from '../../structures/theme-F83/F83events';

const F83Theme = () => {
  return (
    <F83Layout>
      <F83left />
      <F83events />
      <F83right />
    </F83Layout>
  )
}

export default F83Theme