import React, { useState, useEffect, useContext } from 'react'
import { redApi } from '../../../../config/Axios'
import { getToday } from '../../../../utils/dateToday'
import { ReportsContext } from './context/ReportesContext'

export default function IngEgr() {

    const {
        terminals,
        consortiums: consorcios,
        loading,
        ventas
    } = useContext(ReportsContext)
    

    const [showcheck, setShowcheck] = useState(false)

    const handleCheck = (e) => {
        if (e.target.checked) {
            setShowcheck(true)
        } else {
            setShowcheck(false)
        }
    }
    const [onLoading,setOnLoading ] = useState(loading);
    const [dataTable, setDataTable] = useState({});
    const [firstRender , setFirstRender] = useState(true);


    const [filters, setFilters] = useState({
        consorcioId: '',
        terminalId: '',
        initDate: getToday(),
        finalDate: ''
    })
    useEffect(() => {
        getVentaGral();
        setFirstRender(false);
    }, [])
    const getVentaGral = async () => {
        setOnLoading(true)
        if(!loading && firstRender){
            setDataTable(ventas)
        } else{
            const res = await redApi.get(`/reports/getventagral`)
            setDataTable(res.data)
        }
        setOnLoading(false)
    }

    const handleChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        })
    }

    useEffect(async () => {
        setOnLoading(true)
        if (filters.consorcioId !== '' || filters.terminalId !== '' || filters.initDate !== '' || filters.finalDate !== '') {
            const res = await redApi.get(`/reports/getventagral?${filters.initDate ? `initDate=${filters.initDate}` : ''}${filters.finalDate ? `&finalDate=${filters.finalDate}` : ''}${filters.consorcioId ? `&cons=${filters.consorcioId}` : ''}${filters.terminalId ? `&pos=${filters.terminalId}` : ''}`)
            setDataTable(res.data)
            setOnLoading(false)
            return
        }
    }, [filters])

    return (
        <div className="container-fluid p-0  m-0">
            <div className="row m-0 p-0">
                <div className="col-12  m-0 p-0 bg-reportes-head py-4">
                    <div className="row m-0 p-0 gap-3">
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">POS</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" onChange={(e) => handleChange(e)} name='terminalId'>
                                                <option value="all">Todos</option>
                                                {terminals.map((pos, index) => (
                                                    <option key={index} value={pos._id}>{pos.username}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Consorcio</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" onChange={(e) => handleChange(e)} name='consorcioId'>
                                                <option value="all">Todos</option>
                                                {consorcios.map((cons, index) => (
                                                    <option key={index} value={cons._id}>{cons.username}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-start">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <input type="checkbox" onChange={handleCheck} />
                                            <label className="m-0 p-0">Rango</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Refrescar" className="btn btn-primary" onClick={() => getVentaGral()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            {
                                                showcheck ? <label className="m-0 p-0 text-start text-nowrap">Fecha inicial</label> : <label className="m-0 p-0 text-start text-nowrap">Fecha</label>
                                            }
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="date" className="form-control" name='initDate' value={filters.initDate} onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className={`m-0 p-0 text-start text-nowrap ${!showcheck ? 'invisible' : ''}`}>Fecha final</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="date" className={`form-control ${!showcheck ? 'invisible' : ''}`} name='finalDate' onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 py-4" id="table_container">
                    {
                        onLoading ? <div className="d-flex justify-content-center align-items-center"><div className="spinner-border text-primary" role="status"></div></div> : (
                            <div className="table-responsive" style={{maxHeight: "65vh"}}>
                                <table className="table table-striped table-hover" style={{ minHeight: 30 }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Venta</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {
                                            dataTable?.tickets?.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data.date}</td>
                                                    <td>${data.total}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th scope="col">Total</th>
                                            <th scope="col">${dataTable?.total}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )
}
