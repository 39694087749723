import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Container, Grid, TextField } from '@mui/material';
import MySelect from './mySelect';
import { useEffect, useState } from 'react';
import SearchTextField from './search';
import Layout from '../../structures/layout';
import HeaderSupport from './HeaderSupport';
import HeaderMain from './HeaderMain';
import { editTickets, sendDataTickets, sendAssigned } from './servicesSupport';
import { socket } from '../../../../socket';
import './headerMain.scss'

const Body = () => {

    const [serch, setSerch] = useState("");
    const [inputValue, setInputValue] = useState("")
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    //     const [tickets, setTickets] = React.useState([{
    //         ticketCode: 1234, 
    //         createdAt: '2023-12-20', 
    //         subject: 'subjectetst', 
    //         note: 'note testt', 
    //         assigned: 'Pending', 
    //         status: 'In progresing' },
    //         {
    //         ticketCode: 9999, 
    //         createdAt: '2023-12-20', 
    //         subject: 'subjectetst', 
    //         note: 'note testt', 
    //         assigned: 'Alejandro', 
    //         status: 'Open' },
    //    {
    //             ticketCode: 7999, 
    //             createdAt: '2023-12-20', 
    //             subject: 'subjectetst', 
    //             note: 'note testt', 
    //             assigned: 'Alejandro', 
    //             status: 'Open' },
    //        {
    //                 ticketCode: 6999, 
    //                 createdAt: '2023-12-20', 
    //                 subject: 'subjectetst', 
    //                 note: 'note testt', 
    //                 assigned: 'Alejandro', 
    //                 status: 'Open' },
    //     ]);


    const [tickets, setTickets] = useState([]);


    const result = !serch
        ? tickets
        : tickets.filter((datos) => datos.ticketCode.toString().includes(serch));


    const [selectedStatus, setSelectedStatus] = useState({});

    const text = 'Closed'
    const text2 = 'Active'


    const sendData = async () => {
        try {
            const sendInfo = await sendDataTickets();
            const dataArray = Array.isArray(sendInfo.data) ? sendInfo.data : [sendInfo.data];
            setTickets(dataArray)
            console.log("data: ", tickets);
        } catch (error) {
            console.log("Error: ", error)
            throw error
        }
    }

    const handleKeyDown = async (e, ticketCode) => {
        if (e.key === 'Enter') {
            try {
                await sendAssigned(ticketCode, inputValue);
                console.log('Asignación exitosa');
                sendData()
            } catch (error) {
                console.error('Error al asignar:', error);
            }
        }
    };

    useEffect(() => {
        sendData()
        socket.on('createReport', () => {
            alert('LLEGANDO')
        })
    }, [])



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    console.log("inputValue: : ", inputValue)





    return (
        <Layout>
            <HeaderMain />
            <HeaderSupport />
            <Container style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '70vh',
                backgroundColor: 'white',
                borderRadius: '10px',
                filter: 'drop-shadow(0px 10px 60px rgba(226, 236, 249, 0.50))',
                marginTop: '30px',
                width: '175vh'
            }}>
                {/* <Grid container style={{width:'968px',height:'812px', flexShrink:0,filter:'filter: drop-shadow(0px 10px 60px rgba(226, 236, 249, 0.50))',display:'flex',alignItems:'center',justifyContent:'center'}}> */}


                <Paper sx={{ width: '100%', overflow: 'hidden', objectFit: 'cover' }} style={{ padding: '5px', borderRadius: '20px', marginTop: '15px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: 'max-content', }}>
                        <div style={{ marginLeft: '50px' }}>
                            <h1 style={{ color: '#000', fontFamily: 'Poppins', fontSize: '22px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal', letterSpacing: '-0.22px' }}>All Tickets</h1>
                            {/* <p style={{ color: '#16C098', fontFamily: 'Poppins', fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', letterSpacing: '-0.14px' }}>Active Tickets</p> */}
                        </div>
                        <div style={{ marginLeft: '550px' }}>
                            <SearchTextField setSearch={setSerch} />
                        </div>

                    </div>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table" style={{ borderRadius: '100px' }}>
                            <TableHead>

                                <TableRow>
                                    <TableCell colSpan={6} align="left" style={{ width: '2px' }}>Ticket #</TableCell>
                                    <TableCell colSpan={6} align="left">Date</TableCell>
                                    <TableCell colSpan={6} align="left">Subject</TableCell>
                                    <TableCell colSpan={6} align="left">Description</TableCell>
                                    <TableCell colSpan={6} align="left">Assigned</TableCell>
                                    <TableCell colSpan={6} align="left">Status</TableCell>

                                </TableRow>

                            </TableHead>
                            <TableBody>

                                {
                                    result.length === 0 ? <TableRow><TableCell colSpan={3}>No hay datos disponibles</TableCell></TableRow>
                                        :
                                        result.map((ticket) => {
                                            const formattedDate = new Date(ticket.createdAt).toISOString().split('T')[0];

                                            return (
                                                <tr key={ticket.ticketCode}>
                                                    <TableCell colSpan={6} align="left" style={{ fontSize: '12px', width: '2px' }}>#{ticket.ticketCode}</TableCell>
                                                    <TableCell colSpan={6} align="left" style={{ fontSize: '12px', }}>{formattedDate}</TableCell>
                                                    <TableCell colSpan={6} align="left" style={{ fontSize: '12px', width: '6vw' }}>{ticket.subject}</TableCell>
                                                    <TableCell colSpan={6} align="left" style={{ fontSize: '12px', }}>{ticket.note}</TableCell>
                                                    <TableCell colSpan={6} align="left" ><input className='assigned' style={{
                                                        width: '6vw', fontSize: '13px',
                                                        background: ticket.assigned ? 'green' : 'red',
                                                        color: ticket.assigned ? 'white' : 'white',
                                                        padding: '4px 8px',
                                                        borderRadius: '4px',

                                                        fontSize: '11px',
                                                        fontWeight: 'bold',
                                                        height: '30px'
                                                    }}
                                                        onChange={(e) => setInputValue(e.target.value)}
                                                        onKeyDown={(e) => handleKeyDown(e, ticket.ticketCode)}
                                                        value={ticket.assigned || "Sin asignar"}

                                                    ></input>
                                                    </TableCell>

                                                    <TableCell colSpan={6} align="left" style={{ width: '70px' }}>
                                                        <select
                                                            name={`STATE_${ticket.ticketCode}`}
                                                            id={`STATE_${ticket.ticketCode}`}
                                                            value={selectedStatus[ticket.ticketCode] || ''}
                                                            // onChange={(e) => setSelectedStatus(e.target.value)}
                                                            onChange={(e) => {
                                                                setSelectedStatus((prev) => {
                                                                    const updatedStatus = {
                                                                        ...prev,
                                                                        [ticket.ticketCode]: e.target.value,
                                                                    };
                                                                    console.log("status: ", updatedStatus[ticket.ticketCode]);
                                                                    return updatedStatus;
                                                                });

                                                                // Llamar a la función para editar el ticket
                                                                editTickets(ticket.ticketCode, e.target.value);
                                                            }}
                                                            style={{
                                                                backgroundColor: selectedStatus[ticket.ticketCode] === 'Open' ? 'green' :
                                                                    selectedStatus[ticket.ticketCode] === 'Completed' ? 'green' :
                                                                        selectedStatus[ticket.ticketCode] === 'Assigned' ? 'blue' :
                                                                            selectedStatus[ticket.ticketCode] === 'In Progress' ? '#FFBF00' : 'inherit',
                                                                color: selectedStatus[ticket.ticketCode] === 'Open' ? 'white' :
                                                                    selectedStatus[ticket.ticketCode] === 'Completed' ? 'white' :
                                                                        selectedStatus[ticket.ticketCode] === 'Assigned' ? 'white' :
                                                                            selectedStatus[ticket.ticketCode] === 'In Progress' ? 'black' : 'inherit',
                                                                padding: '4px 8px',
                                                                borderRadius: '4px',
                                                                width: '100%',
                                                                fontSize: '11px',
                                                                fontWeight: 'bold',
                                                                height: '30px'

                                                            }}

                                                        >
                                                            <option value="" disabled hidden>{ticket.status}</option>
                                                            <option value="Open">Open</option>
                                                            <option value="Assigned">Assigned</option>
                                                            <option value="In Progress">In Progress</option>
                                                            <option value="Completed">Completed</option>
                                                        </select>
                                                    </TableCell>

                                                </tr>

                                            )
                                        })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        // count={rows.length}
                        //  rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                {/* </Grid> */}

            </Container>
        </Layout>


    );
}

export default Body