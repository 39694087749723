import React from 'react'
import { Container } from 'react-bootstrap'

import Layout from '../structures/layout'

export default function Central() {
  return (
    <Layout>
        <Container className="bg-card p-4">
            <div className="row button-nav mb-4 d-flex align-items-center">
                <div className="col-12 col-md-6 col-lg-auto d-flex justify-content-center">
                    <h3 className="mr-2">Configuración de la Central</h3>
                </div>
                <div className="col-12 col-md-6 col-lg-auto d-flex justify-content-center">
                    <button data-bs-toggle="modal" data-bs-target="#boteAutomatico">Bote Automático</button>
                    <div className="modal fade" id="boteAutomatico" tabIndex="-1" aria-labelledby="boteAutomaticoLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="BoteAutomaticoLabel">Bote Automático</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                <form>
                                    <div className="row mb-3">
                                        <div className="col-12 mb-2 d-flex align-items-center">
                                            <input type="checkbox" name="checkboxBoteAuto" id="checkboxBoteAuto" />
                                            <label htmlFor="checkboxBoteAuto">Activar Bote Automático</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 mb-2 d-flex align-items-center">
                                            <label htmlFor="selectConsOrigen">Consorcio de Origen</label>
                                            <select id="selectConsOrigen">
                                                <option value="todos">Todos</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 mb-2 col-md-auto d-flex align-items-center">
                                            <label htmlFor="selectConsDestino">Consorcio de Destino</label>
                                            <select id="selectConsDestino">
                                                <option value="todos">Todos</option>
                                            </select>
                                        </div>
                                        <div className="col-12 mb-2 col-md-auto d-flex align-items-center">
                                            <label htmlFor="selectConsPOS">POS</label>
                                            <select name="selectConsPOS" id="selectConsPOS">
                                                <option value="todos">Todos</option>
                                            </select>
                                        </div>
                                        <div className="col-12 mb-2 col-md-auto d-flex align-items-center">
                                            <label htmlFor="selectConsUsuario">Usuario</label>
                                            <select name="selectConsUsuario" id="selectConsUsuario">
                                                <option value="todos">Todos</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
    
                                <div className="row mb-3">
                                    <div className="table-responsive-lg">
                                        <table className="table table-sm table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>Lotería</th>
                                            <th>Minutos</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody id="tableBoteAuto">
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-center">
                                <button type="button" className="btn btn-ok mb-2">Salvar</button>
                                <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cerrar</button>
                            </div>
                            </div>
                        </div>
                        </div>

                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-4">
                    <h2>Mantenimiento de los Consorcios</h2>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="row mb-4">
                        <div className="col-12"><h5>Consorcios</h5></div>
                        <div className="col-12">
                            <div className="table-responsive table-custom-bordered" style={{height: '330px'}}>
                                <table className="table table-sm table-bordered table-hover" style={{minHeight: '30px'}} >
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id="tableConsorciosCentral">
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 col-lg-8">
                    <form id="formcentral">
                        <div className="row">
                            <div className="col-12 col-md-2 mb-2">
                                <label htmlFor="nombreConsorcio">Nombre</label>
                            </div>
                            <div className="col-12 col-md-10 mb-2">
                                <input type="text" name="nombreConsorcio" id="nombreConsorcio" readOnly/>
                            </div>

                            <div className="col-12 col-md-2 mb-2">
                                <label htmlFor="subtituloConsorcio">Subtítulo</label>
                            </div>
                            <div className="col-12 col-md-10 mb-2">
                                <input type="text" name="subtituloConsorcio" id="subtituloConsorcio" readOnly/>
                            </div>

                            <div className="col-12 col-md-2 mb-2">
                                <label htmlFor="mensajeConsorcio">Mensaje de ticket</label>
                            </div>
                            <div className="col-12 col-md-10 mb-2">
                                <textarea name="mensajeConsorcio" id="mensajeConsorcio"></textarea>
                            </div>

                            <div className="col-12 col-md-2 mb-2">
                                <label htmlFor="mensajeConsorcio">Mensaje del día</label>
                            </div>
                            <div className="col-12 col-md-10 mb-2">
                                <select name="selectMensajeConsorcio" id="selectMensajeConsorcio">
                                    <option value="todos">Todos</option>
                                    <option value="ninguno">ninguno</option>
                                </select>
                            </div>

                            <div className="col-12 col-md-2 mb-2"></div>
                            <div className="col-12 col-md-10 mb-2">
                                <textarea name="mensajeDia" id="mensajeDia"></textarea>
                            </div>

                            <div className="col-12 col-md-2 mb-2 mb-2">
                                <label htmlFor="minutosConsorcio">Minutos Anulación</label>
                            </div>
                            <div className="col-12 col-md-10 mb-2">
                                <input type="number" min="0" name="minutosConsorcio" id="minutosConsorcio"/>
                            </div>

                            <div className="col-12 col-md-2 mb-2">
                                <label htmlFor="timeoutConsorcio">Timeout</label>
                            </div>
                            <div className="col-12 col-md-10 mb-2">
                                <input type="number" name="timeoutConsorcio" id="timeoutConsorcio" readOnly/>
                            </div>

                            <div className="col-12 mt-4 mb-2">
                                <input type="checkbox" name="devolucionConsorcio" id="devolucionConsorcio"/>
                                <label htmlFor="devolucionConsorcio">Devolución</label>
                            </div>
                            <div className="col-12 mt-4 mb-2">
                                <button type="button">Salvar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Container>
    </Layout>
  )
}
