import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { redApi } from "../../config/Axios";
import moment from "moment";
import consorcio1 from "../../assets/consorcio_img/consorcio1.png";
import consorcio2 from "../../assets/consorcio_img/consorcio2.png";
import consorcio3 from "../../assets/consorcio_img/consorcio3.png";

import "./printer.scss";
import Swal from "sweetalert2";
moment.locale('en');
// /ticket/id
export const Printer = ({ ticketId, printTicket, setPrintTicket, sendWs = false, userId = null }) => {

  const [ticketInfo, setTicketInfo] = useState({
    id: "000000000000",
    pos: "",
    consortium: "",
    titleTicket: "",
    ticketReady: false
  });
  const [betsArray, setBetsArray] = useState([
    {
      teamCode: "",
      team: { name: "" },
      league: { name: "" },
      playType: "",
      price: "",
    },
  ]);
  const [betAmount, setBetAmount] = useState({
    amount: 0,
    toWin: 0,
    total: 0,
  });

  const componentRef = useRef();
  window.onafterprint = (event) => {
    console.log("After print");
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Print Ticket #${ticketId}`,
  });

  useEffect(() => {
    if (!printTicket.ticketId) return;
    try {
      const fetchTicket = async () => {
        const { data } = sendWs
          ? await redApi.get(`/ticketprint`, { params: { userId, ticketId: printTicket.ticketId } })
          : await redApi.get(`/tickets/${printTicket.ticketId}`);
          console.log("data", data)
        setTicketInfo({
          id: data.ticketId,
          pos: data.createdBy.username,
          consortium: data.createdBy.ownedBy.username,
          titleTicket: data.createdBy.ticketTitle,
          ticketReady: true,
          game: data.bets
        });
        setBetsArray(data.bets);
        setBetAmount({
          amount: data.amount,
          toWin: data.toWin,
          total: data.toCollect,
        });
        !sendWs && handlePrint();
        setPrintTicket({ ticketId: 0, print: false });
        Swal.fire({
          title: "Ticket Printed",
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      };
      fetchTicket().catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  }, []);


  return (
    <div style={sendWs ? {} : { display: "none" }}>
      <div ref={componentRef} className="ticket-print" id={ticketInfo.ticketReady ? "ticket-ready" : ""}>
        <div className="ticket-header">
          <div className="consortium">
            {/* <img src="/sports_favicon.png" alt="Sports" className={sendWs ? "" : "filter-img"} /> */}
            <img src={ ticketInfo.consortium === 'boston' ? consorcio1 : ticketInfo.consortium === 'caracas' ? consorcio2 : "/sports_favicon.png" } alt="Sports" className={sendWs ? "" : "filter-img"} />
            <h1>
              {ticketInfo?.consortium}
            </h1>
          </div>
          {/* <h2>{moment().format("DD[/]MMM[/]YYYY hh:mm A")}</h2> */}
          <h2>{moment().format("DD/MM/YYYY, h:mm:ss A")}</h2>
          <h2>POS: {ticketInfo?.pos}</h2>
          <p className={sendWs ? "background-send" : ""}>Ticket #{ticketInfo?.id}</p>
          <p className={sendWs ? "background-send" : ""}>{moment().format('dddd, h:mm:ss A')}</p>
          {ticketInfo?.titleTicket ? <h2>{ticketInfo?.titleTicket}</h2> : null}
  
        </div>
        <div className="info-game">
          {
            ticketInfo?.game?.map((row,i)=>{
                 console.log("row", row)

              return(
                <div key={i}>
                  <p>Juego: {row.game.localTeam.name} vs {row.game.visitingTeam.name}</p>
                  <p>Hora de Comienzo: {moment(row.game.time).format('h:mm A')}</p>
                  
                </div>
              )
            })
          }
        </div>
        <div className="ticket-body">
          <table>
            <thead>
              <tr>
                <th>Code</th>
                <th>Team</th>
                <th>League</th>
                <th>Type</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {betsArray.map((bet, index) => (
                <tr key={index}>
                  <td>{bet?.teamCode}</td>
                  <td>
                    {bet?.team?.name}
                  </td>
                  <td>({bet?.league?.name})</td>
                  <td>{bet?.playType}</td>
                  <td>{bet?.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table className="table-footer">
            <thead>
              <tr>
                <th>Bet Amount</th>
                <th>To Win</th>
                <th>Total</th>
          
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{betAmount?.amount}</td>
                <td>{betAmount?.toWin}</td>
                <td>{betAmount?.total}</td>
              
              </tr>
            </tbody>
          </table>
        </div>
        <div className="ticket-footer">
          <p>REVISE SU TICKET AL RECIBIRLO.</p>
          <p>NO SE PAGA ERROR DE LÍNEA NI JUEGO INICIADO.</p>
          <p>NO PAGAMOS JUGADAS EN ESCALERA</p>
          <p>POLARSPORTS.DDNS.NET</p>
        </div>
      </div>
    </div>
  );
};
