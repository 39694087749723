export const TIPO_DE_LINEA = [
  {
    label: "ML",
    value: "ML",
    checked: false,
  },
  {
    label: "RL",
    value: "RL",
    checked: false,
  },
  {
    label: "TL",
    value: "TL",
    checked: false,
  },
  {
    label: "SOLO",
    value: "SOLO",
    checked: false,
  },
  {
    label: "SI",
    value: "SI",
    checked: false,
  },
  {
    label: "NO",
    value: "NO",
    checked: false,
  },
  {
    label: "PC",
    value: "PC",
    checked: false,
  },
  {
    label: "SRL",
    value: "SRL",
    checked: false,
  },
  {
    label: "TERCIO ML",
    value: "TML",
    checked: false,
  },
  {
    label: "TERCIO TL",
    value: "TTL",
    checked: false,
  },
  {
    label: "ARL",
    value: "ARL",
    checked: false,
  },
];

export const PERIODOS = [
  {
    label: "FULL",
    checked: true,
  },
  {
    label: "HALF",
    checked: false,
  },
  {
    label: "HALF 2",
    checked: false,
  },
  {
    label: "Q1",
    checked: false,
  },
  {
    label: "Q2",
    checked: false,
  },
  {
    label: "Q3",
    checked: false,
  },
  {
    label: "Q4",
    checked: false,
  },
  {
    label: "P1",
    checked: false,
  },
  {
    label: "P2",
    checked: false,
  },
  {
    label: "P3",
    checked: false,
  },
];

export const halfItems = [
  "h1",
  "h1Rl",
  "h1RlGavela",
  "HGavela",
  "HOver",
  "HUnder",
  "HSGavela",
  "HSOver",
  "HSUnder",
]