import React, { useState, useEffect, useRef, useContext } from 'react'
import { redApi } from '../../../../config/Axios'
import moment from 'moment'
import { getRedValueClassName } from '../../../../utils/returnClassNames'
import { ReportsContext } from './context/ReportesContext'

const DATA_INIT_STATE = {Terminales: [], amount: 0, toCollect: 0, total: 0}
export default function Ganancia_Perdida() {

    const rangeRef = useRef(null);

    const {
        terminals,
        consortiums: consorcios,
        loading: onLoading,
        setLoading: setOnLoading, 
        earLost,
    } = useContext(ReportsContext)


    const [data, setData] = useState(DATA_INIT_STATE);
    const [showcheck, setShowcheck] = useState(false)
    const handleCheck = (e) => {
        setFirtsRender(false);
        if (e.target.checked) {
            setShowcheck(true);
            setFilter(prev => {
                if (prev.initDate) {
                    return {
                        ...prev,
                        finalDate: moment(prev.initDate).add(1, "day").format('YYYY-MM-DD')
                    }
                } else {
                    return {
                        ...prev,
                        initDate: moment().format('YYYY-MM-DD'),
                        finalDate: moment().add(1, "day").format('YYYY-MM-DD')
                    }
                }
            });
        } else {
            setFilter(prev => ({
                ...prev,
                initDate: moment().format('YYYY-MM-DD'),
                finalDate: moment().format('YYYY-MM-DD')
            }))
            setShowcheck(false);
        }
    };
    const [filter, setFilter] = useState({
        consorcio: '',
        pos: '',
        initDate: moment().format('YYYY-MM-DD'),
        finalDate: ""
    })    
    const [ firtsRender , setFirtsRender] = useState(true)

    const handleFilter = async (e) => {
        setFirtsRender(false);
        if (e.target.name === 'refresh') {
            fetchEarningLosses();
        }
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        })
    }

    const fetchEarningLosses = async () => {
        if (!filter.initDate) return;

        setOnLoading(true);
        try {
            if(!onLoading && firtsRender){
                setData(earLost)
            }else {
                const { data } = await redApi.get("/EarnLosses", {
                    params: {
                        initDate: filter.initDate,
                        finalDate: rangeRef.current.checked ? filter.finalDate : "",
                        pos: filter.pos,
                        consorcio: filter.consorcio
                    }
                });
                setData(data)
            }

        } catch (error) {
            console.log(error)
            setData(DATA_INIT_STATE)
        }
        setOnLoading(false)
    };

    useEffect(() => {
        fetchEarningLosses();
    }, [filter])

    return (
        <div className="container-fluid p-0  m-0">
            <div className="row m-0 p-0">
                <div className="col-12  m-0 p-0 bg-gray-800 py-4">
                    <div className="row m-0 p-0 gap-3">
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">POS</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name="pos" onChange={handleFilter}>
                                                <option value="">Todos</option>
                                                {
                                                    terminals && terminals?.map((terminal, index) => (
                                                        <option key={index} value={terminal.username}>{terminal.username}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Consorcio</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name="consorcio" onChange={handleFilter}>
                                                <option value="">Todos</option>
                                                {
                                                    consorcios && consorcios?.map((consorcio, index) => (
                                                        <option key={index} value={consorcio.username}>{consorcio.username}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <input ref={rangeRef} type="checkbox" onChange={handleCheck} />
                                            <label className="m-0 p-0">Rango</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Refrescar" name="refresh" className="btn btn-primary"
                                                style={{
                                                    border: 'none',
                                                }}
                                                onClick={handleFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className="m-0 p-0 text-start text-nowrap">Fecha</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="date" className="form-control" value={filter.initDate} name="initDate" onChange={handleFilter} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">

                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Imprimir" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className={`m-0 p-0 text-start text-nowrap ${!showcheck ? 'invisible' : ''}`}>Fecha final</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="date" className={`form-control ${!showcheck ? 'invisible' : ''}`} name="finalDate" onChange={handleFilter} value={filter.finalDate} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 py-4" id="table_container">
                    <div className="table-responsive" style={{maxHeight: "65vh"}}>
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center" scope="col">Consorcio</th>
                                    <th className="text-center" scope="col">POS</th>
                                    <th className="text-center" scope="col">Venta</th>
                                    <th className="text-center" scope="col">Premios</th>
                                    <th className="text-center" scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    onLoading
                                    ? <tr><td colSpan="5" className="text-center">Cargando...</td></tr>

                                    : !data.Terminales.length
                                        ? <tr><td colSpan="5" className="text-center">No hay datos</td></tr>

                                        : data.Terminales.map((terminal, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{terminal.consorcio}</td>
                                                <td className="text-center">{terminal.username}</td>
                                                <td className="text-center">{terminal.amount}</td>
                                                <td className={getRedValueClassName(terminal.toCollect > 0, "text-center")}>{terminal.toCollect}</td>
                                                <td className="text-center">{terminal.total}</td>
                                            </tr>
                                        ))
                                }

                            </tbody>
                            <tfoot>
                                {
                                    onLoading
                                    ? <tr><td colSpan="5" className="text-center">Cargando...</td></tr>
                                    : data.Terminales.length > 0 
                                        ? (
                                            <tr>

                                                <td colSpan="2" className="text-center">Total</td>
                                                <td className="text-center">{data.amount}</td>
                                                <td className={getRedValueClassName(data.toCollect > 0, "text-center")}>{data.toCollect}</td>
                                                <td className="text-center">{data.total}</td>
                                            </tr>

                                            ) 
                                        : (
                                            <tr>
                                                <td colSpan="5" className="text-center">No hay datos</td>
                                            </tr>
                                            )
                                }
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
