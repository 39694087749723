import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useLeagues } from '../../../../utils/Hooks';
import { TableMatchUps } from '../LegacyTableMatchUps';
import { TableGames } from '../TableGames';
import { matchupLeagues } from '../../../../utils/matchUpLeagues';
import { redApi } from '../../../../config/Axios';
import moment from 'moment';
import { useGames } from '../../../../hooks/clientHooks/useGames';
import { useTickets } from '../../../../hooks/clientHooks/useTickets';

const Eventscontainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  min-width: 350px;
  max-width: 35vw;
  `;

const CardContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  border: 1px solid #00000010;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 5px 0 20px #00000008;
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
   &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A6BD48;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 10px;
  }
  // select {
  //   width: 100%;
  //   padding: 5px 7px;
  //   border: 1px solid #ddd;
  //   border-radius: 5px;
  //   background-color: #fff;
    
  // }
`;

const IconsButtons = styled.button`
  background-color: #333;
  border: none; 
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: #fff;
    stroke: #fff;
    width: auto;
    height: 20px;
  }
`;

const OneEvent = styled.div`
  width: 100%;
  background-color: transparent;
`;

const CardGame = styled.div`
  background-color: #fff;
  border: 1px solid #00000010;
  border-radius: 10px;
  padding: 10px 0;
`;

const IconContainer = styled.div`
  svg {
    fill: #c94e24;
    stroke: #c94e24;
    width: auto;
    height: 23px;
  }
`;

const GamesContainer = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  thead th {
    color: #333;
    font-weight: 700;
    text-align: center;
  }
  tbody tr {
    text-align: center;

    &:nth-child(even) {
      background-color: #f5f5f5;
    }

  }
  tbody tr td {
    padding: 5px;
    &:nth-child(2) {
      font-weight: 600;
    }
  }
  tbody tr td div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  tbody tr td div button {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  position: relative;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A6BD48;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 10px;
  }

`;

const LeagueContainer = styled.div`
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: ${({ $selected }) => $selected ? '5px' : '0'};
  border: ${({ $selected }) => $selected ? '1px solid #0158AD' : 'none'};
  background-color: ${({ $selected }) => $selected ? '#0158AD70' : '#fff'};
  padding:0 5px;
  img {
    width: 52px;
    height: 52px;
    object-fit: contain;
  }
`;

const LoadingLeagues = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1.5em;
  font-weight: 500;
  color: #333;
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  select {
    width: 100%;
    height: 100%;
    padding: 0px 7px;
    border: 1px solid #ddd;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #fff;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
  `;

const LoadingBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #2186F910;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  color: #202025;
  font-size: 1.3em;
  font-weight: 700;
  backdrop-filter: blur(1px);
`;
const F83events = () => {
  const { gamesToday, ticketNumber, isLoading, getGamesToday } = useGames();
  const { leagues, loadingLeagues, getLeagues, leagueSelected, handleLeagueSelected } = useTickets();
  useEffect(() => {
    getLeagues()
  }, []);

  useEffect(() => {
    if (leagues.length) {
      handleLeagueSelected(leagues[0])
    };
  }, [leagues]);

  const [eventsRender, setEventsRender] = useState(1);
  const handleEventsRender = (e) => {
    setEventsRender(e.target.value);
  }

  // [
  //   {
  //     sport: 'soccer',
  //     leagues: [
  //       {
  //         name: 'UEFA',
  //         games: [...]
  //       }
  //     ]
  //   }
  // ]

  useEffect(() => {
    if (leagueSelected) {
      getGamesToday(leagueSelected)
    };
  }, [leagueSelected]);

  const renderTable = () => {
    if (!leagueSelected) return <></>;
    return (
      leagueSelected?.lineType?.map((lineType, indexLineType) => (
        <div key={indexLineType} className="lines-games-vertical__table mb-4">
          {
            lineType?.periods?.map((period, indexPeriod) => (
              period.period !== "Completo / 1ra Mitad " && (
                <div key={indexPeriod} className="lines-games-vertical__table__period mb-4">
                  <div className="lines-games-vertical__table__period__title">
                    <span>{period.period}1</span>
                  </div>
                  <div
                    style={{
                      overflowX: "auto",
                      width: "100%",

                    }}
                  >
                    {
                      matchupLeagues.includes(leagueSelected.name)
                        ? (<TableMatchUps head={period.lines} games={gamesToday} />)
                        : (<TableGames head={period.lines} games={gamesToday} />)
                    }
                  </div>
                </div>
              )
            ))
          }
        </div>
      ))
    )
  }
  const arrayLeagues = Array.isArray(leagues) ? leagues : [leagues]
  return (
    <Eventscontainer>
      <CardContainer>
        {
          loadingLeagues ? <LoadingLeagues>Cargando...</LoadingLeagues> : (
            arrayLeagues?.map((league) => (
              <LeagueContainer key={league._id} onClick={() => {
                handleLeagueSelected(league)
              }} $selected={leagueSelected?._id === league._id}>
                <img src={league.image} alt="" />
              </LeagueContainer>
            ))
          )
        }
        {/* <div style={{ display: "flex" }}>
          <IconsButtons style={{
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
          }}>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .-3.1 .5-4.6 1z"></path></svg>
          </IconsButtons>
          <IconsButtons>
            <svg stroke="currentColor" fill="none" style={{
              fill: "none",
            }} stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>
          </IconsButtons>
          <IconsButtons style={{
            backgroundColor: "#0158AD30",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
          }}>
            <svg stroke="currentColor" fill="currentColor" style={{
              fill: "#0158AD80",
            }} stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
          </IconsButtons>
        </div>
        <div>
          <select name="" id="">
            <option value="">--SELECT--</option>
          </select>
        </div> */}
      </CardContainer>
      <SelectContainer>
        <IconsButtons style={{
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}>
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .-3.1 .5-4.6 1z"></path></svg>
        </IconsButtons>
        <select name="eventsrender" id="eventsrender" value={eventsRender} onChange={handleEventsRender}>
          <option value="1">Liga seleccionada</option>
          <option value="2">--Todos--</option>
        </select>
      </SelectContainer>
      {
        eventsRender === "2" ? (
          <ScrollContainer>
            <OneEvent>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <IconContainer>
                  <svg stroke="currentColor" fill="#c94e24" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M122.06 122.06c-44.37 44.37-66.71 100.61-78 145.28l200.6 200.56c44.67-11.25 100.91-33.59 145.28-78s66.71-100.61 78-145.28L267.34 44.1c-44.67 11.25-100.91 33.59-145.28 77.96zm256.73 256.72zM300.65 189 323 166.71A15.78 15.78 0 0 1 345.29 189L323 211.35l11.16 11.17a15.78 15.78 0 0 1-22.32 22.32l-11.16-11.16L278.32 256l11.16 11.16a15.78 15.78 0 1 1-22.32 22.32L256 278.32l-22.32 22.33 11.16 11.16a15.78 15.78 0 1 1-22.32 22.32L211.35 323 189 345.29A15.78 15.78 0 0 1 166.71 323L189 300.65l-11.16-11.17a15.78 15.78 0 0 1 22.32-22.32l11.16 11.16L233.68 256l-11.16-11.16a15.78 15.78 0 1 1 22.32-22.32L256 233.68l22.32-22.33-11.16-11.16a15.78 15.78 0 0 1 22.32-22.32zm175.92 10.63c7.31-54.53 4-120.26-20-144.21s-89.68-27.3-144.21-20c-2.51.34-5.16.72-7.91 1.15l171 171c.4-2.78.78-5.43 1.12-7.94zM35.43 312.37c-7.31 54.53-4 120.26 20 144.21C72.17 473.33 109.34 480 148.84 480a387 387 0 0 0 50.79-3.43c2.51-.34 5.16-.72 7.91-1.15l-171-171c-.39 2.79-.77 5.44-1.11 7.95z"></path></svg>
                </IconContainer>
                <span style={{
                  fontSize: "1.5em",
                  fontWeight: "bold",
                }}>
                  Football Línea
                </span>
              </div>
              <CardGame>
                <div>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0 1.2rem" }}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/a/a2/National_Football_League_logo.svg/1200px-National_Football_League_logo.svg.png" alt="" width={20} />
                        <span style={{ fontWeight: "500", fontSize: "1.2em" }}>
                          National Football League (NFL)
                        </span>
                      </div>
                      <div
                        style={{
                          width: "fit-content",
                          backgroundColor: "#0158AD",
                          color: "#fff",
                          borderRadius: "5px",
                          padding: "2px 6px",
                          fontWeight: "500",
                        }}
                      >
                        2 Eventos
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 1.2rem", borderBottom: "1px solid #ddd" }}>
                      <span style={{ fontWeight: "500", color: "#0158AD" }}>
                        Hoy
                      </span>
                    </div>
                  </div>
                  <div style={{ display: "flex", width: "100%" }}>
                    <button style={styles.buttonsFilters}>
                      Full game
                    </button>
                    <button style={{
                      ...styles.buttonsFilters,
                      borderBottomColor: "#ddd",
                      color: "#999",
                    }}>
                      1st half
                    </button>
                    <button style={{
                      ...styles.buttonsFilters,
                      borderBottomColor: "#ddd",
                      color: "#999",
                    }}>
                      1st quarter
                    </button>
                    <button style={{
                      ...styles.buttonsFilters,
                      borderBottomColor: "#ddd",
                      color: "#999",
                    }}>
                      Extras
                    </button>
                  </div>
                </div>
                <GamesContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Calendario
                        </th>
                        <th>
                          Evento
                        </th>
                        <th>
                          Money Line
                        </th>
                        <th>
                          Spread
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            12:00 PM
                          </td>
                          <td>
                            <div>
                              <span>
                                345 KC
                              </span>
                              <span>
                                298 BAL
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            12:00 PM
                          </td>
                          <td>
                            <div>
                              <span>
                                345 KC
                              </span>
                              <span>
                                298 BAL
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            12:00 PM
                          </td>
                          <td>
                            <div>
                              <span>
                                345 KC
                              </span>
                              <span>
                                298 BAL
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </GamesContainer>
              </CardGame>
            </OneEvent>
            <OneEvent>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <IconContainer>
                  <svg stroke="currentColor" fill="#c94e24" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M122.06 122.06c-44.37 44.37-66.71 100.61-78 145.28l200.6 200.56c44.67-11.25 100.91-33.59 145.28-78s66.71-100.61 78-145.28L267.34 44.1c-44.67 11.25-100.91 33.59-145.28 77.96zm256.73 256.72zM300.65 189 323 166.71A15.78 15.78 0 0 1 345.29 189L323 211.35l11.16 11.17a15.78 15.78 0 0 1-22.32 22.32l-11.16-11.16L278.32 256l11.16 11.16a15.78 15.78 0 1 1-22.32 22.32L256 278.32l-22.32 22.33 11.16 11.16a15.78 15.78 0 1 1-22.32 22.32L211.35 323 189 345.29A15.78 15.78 0 0 1 166.71 323L189 300.65l-11.16-11.17a15.78 15.78 0 0 1 22.32-22.32l11.16 11.16L233.68 256l-11.16-11.16a15.78 15.78 0 1 1 22.32-22.32L256 233.68l22.32-22.33-11.16-11.16a15.78 15.78 0 0 1 22.32-22.32zm175.92 10.63c7.31-54.53 4-120.26-20-144.21s-89.68-27.3-144.21-20c-2.51.34-5.16.72-7.91 1.15l171 171c.4-2.78.78-5.43 1.12-7.94zM35.43 312.37c-7.31 54.53-4 120.26 20 144.21C72.17 473.33 109.34 480 148.84 480a387 387 0 0 0 50.79-3.43c2.51-.34 5.16-.72 7.91-1.15l-171-171c-.39 2.79-.77 5.44-1.11 7.95z"></path></svg>
                </IconContainer>
                <span style={{
                  fontSize: "1.5em",
                  fontWeight: "bold",
                }}>
                  Football Línea
                </span>
              </div>
              <CardGame>
                <div>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0 1.2rem" }}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/a/a2/National_Football_League_logo.svg/1200px-National_Football_League_logo.svg.png" alt="" width={20} />
                        <span style={{ fontWeight: "500", fontSize: "1.2em" }}>
                          National Football League (NFL)
                        </span>
                      </div>
                      <div
                        style={{
                          width: "fit-content",
                          backgroundColor: "#0158AD",
                          color: "#fff",
                          borderRadius: "5px",
                          padding: "2px 6px",
                          fontWeight: "500",
                        }}
                      >
                        2 Eventos
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 1.2rem", borderBottom: "1px solid #ddd" }}>
                      <span style={{ fontWeight: "500", color: "#0158AD" }}>
                        Hoy
                      </span>
                    </div>
                  </div>
                  <div style={{ display: "flex", width: "100%" }}>
                    <button style={styles.buttonsFilters}>
                      Full game
                    </button>
                    <button style={{
                      ...styles.buttonsFilters,
                      borderBottomColor: "#ddd",
                      color: "#999",
                    }}>
                      1st half
                    </button>
                    <button style={{
                      ...styles.buttonsFilters,
                      borderBottomColor: "#ddd",
                      color: "#999",
                    }}>
                      1st quarter
                    </button>
                    <button style={{
                      ...styles.buttonsFilters,
                      borderBottomColor: "#ddd",
                      color: "#999",
                    }}>
                      Extras
                    </button>
                  </div>
                </div>
                <GamesContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Calendario
                        </th>
                        <th>
                          Evento
                        </th>
                        <th>
                          Money Line
                        </th>
                        <th>
                          Spread
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            12:00 PM
                          </td>
                          <td>
                            <div>
                              <span>
                                345 KC
                              </span>
                              <span>
                                298 BAL
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            12:00 PM
                          </td>
                          <td>
                            <div>
                              <span>
                                345 KC
                              </span>
                              <span>
                                298 BAL
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            12:00 PM
                          </td>
                          <td>
                            <div>
                              <span>
                                345 KC
                              </span>
                              <span>
                                298 BAL
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </GamesContainer>
              </CardGame>
            </OneEvent>
            <OneEvent>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <IconContainer>
                  <svg stroke="currentColor" fill="#c94e24" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M122.06 122.06c-44.37 44.37-66.71 100.61-78 145.28l200.6 200.56c44.67-11.25 100.91-33.59 145.28-78s66.71-100.61 78-145.28L267.34 44.1c-44.67 11.25-100.91 33.59-145.28 77.96zm256.73 256.72zM300.65 189 323 166.71A15.78 15.78 0 0 1 345.29 189L323 211.35l11.16 11.17a15.78 15.78 0 0 1-22.32 22.32l-11.16-11.16L278.32 256l11.16 11.16a15.78 15.78 0 1 1-22.32 22.32L256 278.32l-22.32 22.33 11.16 11.16a15.78 15.78 0 1 1-22.32 22.32L211.35 323 189 345.29A15.78 15.78 0 0 1 166.71 323L189 300.65l-11.16-11.17a15.78 15.78 0 0 1 22.32-22.32l11.16 11.16L233.68 256l-11.16-11.16a15.78 15.78 0 1 1 22.32-22.32L256 233.68l22.32-22.33-11.16-11.16a15.78 15.78 0 0 1 22.32-22.32zm175.92 10.63c7.31-54.53 4-120.26-20-144.21s-89.68-27.3-144.21-20c-2.51.34-5.16.72-7.91 1.15l171 171c.4-2.78.78-5.43 1.12-7.94zM35.43 312.37c-7.31 54.53-4 120.26 20 144.21C72.17 473.33 109.34 480 148.84 480a387 387 0 0 0 50.79-3.43c2.51-.34 5.16-.72 7.91-1.15l-171-171c-.39 2.79-.77 5.44-1.11 7.95z"></path></svg>
                </IconContainer>
                <span style={{
                  fontSize: "1.5em",
                  fontWeight: "bold",
                }}>
                  Football Línea
                </span>
              </div>
              <CardGame>
                <div>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0 1.2rem" }}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/a/a2/National_Football_League_logo.svg/1200px-National_Football_League_logo.svg.png" alt="" width={20} />
                        <span style={{ fontWeight: "500", fontSize: "1.2em" }}>
                          National Football League (NFL)
                        </span>
                      </div>
                      <div
                        style={{
                          width: "fit-content",
                          backgroundColor: "#0158AD",
                          color: "#fff",
                          borderRadius: "5px",
                          padding: "2px 6px",
                          fontWeight: "500",
                        }}
                      >
                        2 Eventos
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 1.2rem", borderBottom: "1px solid #ddd" }}>
                      <span style={{ fontWeight: "500", color: "#0158AD" }}>
                        Hoy
                      </span>
                    </div>
                  </div>
                  <div style={{ display: "flex", width: "100%" }}>
                    <button style={styles.buttonsFilters}>
                      Full game
                    </button>
                    <button style={{
                      ...styles.buttonsFilters,
                      borderBottomColor: "#ddd",
                      color: "#999",
                    }}>
                      1st half
                    </button>
                    <button style={{
                      ...styles.buttonsFilters,
                      borderBottomColor: "#ddd",
                      color: "#999",
                    }}>
                      1st quarter
                    </button>
                    <button style={{
                      ...styles.buttonsFilters,
                      borderBottomColor: "#ddd",
                      color: "#999",
                    }}>
                      Extras
                    </button>
                  </div>
                </div>
                <GamesContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Calendario
                        </th>
                        <th>
                          Evento
                        </th>
                        <th>
                          Money Line
                        </th>
                        <th>
                          Spread
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            12:00 PM
                          </td>
                          <td>
                            <div>
                              <span>
                                345 KC
                              </span>
                              <span>
                                298 BAL
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            12:00 PM
                          </td>
                          <td>
                            <div>
                              <span>
                                345 KC
                              </span>
                              <span>
                                298 BAL
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            12:00 PM
                          </td>
                          <td>
                            <div>
                              <span>
                                345 KC
                              </span>
                              <span>
                                298 BAL
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                          <td>
                            <div>
                              <button>
                                -110
                              </button>
                              <button>
                                -110
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </GamesContainer>
              </CardGame>
            </OneEvent>
          </ScrollContainer>
        ) : (
          <ScrollContainer>
            {
              isLoading ? (
                <LoadingBackground>
                  <span>
                    Cargando eventos...
                  </span>
                </LoadingBackground>
              ) : null
            }
            {
              renderTable()
            }
          </ScrollContainer>
        )
      }
    </Eventscontainer>
  )
}

const styles = {
  buttonsFilters: {
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    borderBottom: "2px solid #0158AD",
    color: "#0158AD",
    padding: "5px 10px",
    fontWeight: "500",
    whiteSpace: "nowrap",
  }
}
export default F83events