import React from 'react'

function Tennis() {
  return (
    <div className="rules-sport tennis">
      <h1>
        TENNIS
      </h1>
      <ul>
        <li>
          All bets will be valid when the match is completed.
        </li>
        <li>
          If a player retires, all bets will be suspended.
        </li>
        <li>
          If a player retires in the first set, all bets will be suspended.
        </li>
        <li>
          If a player retires in the second set, all bets will be suspended.
        </li>
        <li>
          If a player retires in the third set, all bets will be suspended.
        </li>
        <li>
          If a player retires in the fourth set, all bets will be suspended.
        </li>
        <li>
          If a player retires in the fifth set, all bets will be suspended.
        </li>
        <li>
          If a player retires before the match starts, all bets will be suspended.
        </li>
        <li>
          If a player retires before the match starts, all bets will be suspended.
        </li>
        <li>
          If a player retires before the match starts, all bets will be suspended.
        </li>
        <li>
          If a player retires before the match starts, all bets will be suspended.
        </li>
        <li>
          If a player retires before the match starts, all bets will be suspended.
        </li>
        <li>
          If a player retires before the match starts, all bets will be suspended.
        </li>
        <li>
          If a player retires before the match starts, all bets will be suspended.
        </li>
        <li>
          If a player retires before the match starts, all bets will be suspended.
        </li>
        <li>
          If a player retires before the match starts, all bets will be suspended.
        </li>
      </ul>
    </div>
  )
}

export default Tennis