import Title from "../title/Title";
import Layout from "../../../structures/layout"
import Table from "../table/Table";
import Buttons from "../button/Buttons";
import { useEffect, useState } from "react";
import {Modal,Button} from "antd";
import CrearGrupogeneral from "../../configuracion/usuariosTablas/CrearGrupo.general";
import { redApi } from "../../../../../config/Axios";
import { createGlobalStyle } from "styled-components";
import Swal from "sweetalert2";
const IndexGR = () => {
  const [showCreate, setShowCreate] = useState(false)
  const [showEditar, setShowEditar] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [data, setData] = useState([])
  const [dataGroup, setDataGroup] = useState([])
  const [selectedRow, setSelectedRow] = useState(null);

  const [grupo, setGrupo] = useState({
    name:  "",
    description: ""
  });


 

  const handleCancel = () => {
    setShowCreate(false);
    setShowEditar(false);
    setShowTable(false);
    setShowDelete(false);
    setSelectedRow(null)
    setGrupo(prevGrupo => ({
      name: "",
      description: "",
      createdBy: prevGrupo.createdBy
    }));
  }

  const getData = async () => {
    try {
      const resp = await redApi.get('users/sendTerminalsGR')
      setData(resp.data)
    } catch (error) {
      console.log("error", error)
    }
  }

  
  const getUser = async () => {
    try {
      const { data } = await redApi.get(`/verify`);
      grupo.createdBy = data._id
    } catch (error) {
      console.log(error);
    } 
  };

  const getGroup = async () => {
    try {
      const resp = await redApi.get('users/sendGroupGR')
      setDataGroup(resp.data)
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(()=>{
    const fetchData = async () => {
      await getGroup();
      await getData(); // Espera a que getData se complete
      await getUser(); // Espera a que getUser se complete
    };

    fetchData();
  },[])
  

  const handleCheckboxChange = (event, row) => {
    const { username, _id } = row;
    const newIdArray = [...(grupo.terminals || []), _id]; 
    const newState = {
      ...grupo,
      [username]: { ...grupo[username], checked: event.target.checked },
      terminals: newIdArray, 
    };
    setGrupo(newState);
  };

  const handleCheckboxChangeEdit = (e, terminal) => {
    const terminalId = terminal._id;
    const isChecked = e.target.checked;

    // Busca el índice del terminal en el array de terminales del grupo
    const terminalIndex = grupo.terminals.findIndex(t => t.terminalId === terminalId);

    // Si el terminal ya está en el array de terminales del grupo
    if (terminalIndex !== -1) {
        // Crea una copia del array de terminales del grupo
        const updatedTerminals = [...grupo.terminals];

        // Actualiza el estado checked del terminal correspondiente
        updatedTerminals[terminalIndex].checked = isChecked;

        // Si el checkbox se desmarca, elimina el terminal del array
        if (!isChecked) {
            updatedTerminals.splice(terminalIndex, 1);
        }

        // Actualiza el estado del grupo con los terminales actualizados
        setGrupo(prevGrupo => ({
            ...prevGrupo,
            terminals: updatedTerminals,
        }));
    } else {
        // Si el terminal no está en el array de terminales del grupo y el checkbox está marcado
        if (isChecked) {
            // Agrega un nuevo objeto terminal al array con el estado checked
            setGrupo(prevGrupo => ({
                ...prevGrupo,
                terminals: [
                    ...prevGrupo.terminals,
                    {
                        terminalId,
                        checked: true,
                    },
                ],
            }));
        }
    }
};
  
  

  const handleSubmit = async () => {
    if (showCreate) {
      await createdGroup();
    } else if (showEditar) {
      await editGroup();
    } else {
      await deleteGroup();
    }
  };

  const createdGroup = async () => {
    try {
      const result = await redApi.post('users/addTerminalsGR',
        {
          grupo
        }
      )
      setShowCreate(false);
      setShowEditar(false);
      setShowTable(false);
      setGrupo(prevGrupo => ({
        name: "",
        description: "",
        createdBy: prevGrupo.createdBy
      }));
      Swal.fire({
        title: "Grupo Creado",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      getGroup()
      return result
    } catch (error) {
      console.log("error ", error)
      Swal.fire({
        title: "Error al crear grupo",
        icon: "error",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  }

  const editGroup = async () => {
    try {
      const result = await redApi.put('users/editTerminalsGR',{
        grupo
      })   
      setShowEditar(false);
      setGrupo(prevGrupo => ({
        name: "",
        description: "",
        createdBy: prevGrupo.createdBy
      }));
      Swal.fire({
        title: "Grupo Editado con exito",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      getGroup()
      return result
    } catch (error) {
      console.log("error: ", error)
      Swal.fire({
        title: "Error al Editar grupo",
        icon: "error",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const deleteGroup = async () => {
    try {
      const result = await redApi.delete('users/deleteTerminalsGR', {
        data: { grupo }
       });
      setShowDelete(false)
      setGrupo(prevGrupo => ({
        name: "",
        description: "",
        createdBy: prevGrupo.createdBy
      }));
      Swal.fire({
        title: "Grupo Eliminado con exito",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      getGroup()
      return result
    } catch (error) {
      console.log("error: ", error)
      Swal.fire({
        title: "Error al Eliminar grupo",
        icon: "error",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const edit = (obj) => {
    setGrupo({
      _id: obj._id,
      name: obj.name,
      description: obj.description,
      createdBy: obj.createdBy,
      terminals: obj.terminals
    })
  }
 

  return (
    <>
        <Layout>
            <Title />
            <Buttons setShowCreate={setShowCreate} setShowEditar={setShowEditar} setShowTable={setShowTable} setShowDelete={setShowDelete} setSelectedRow={setSelectedRow} selectedRow={selectedRow} getGroup={getGroup} />
            <Table dataGroup={dataGroup} edit={edit} selectedRow={selectedRow} setSelectedRow={setSelectedRow}/>
          
          <Modal
          title={showCreate ? 'Crear Grupo' : 'Editar Grupo'}
          open={showCreate ||  showDelete || showEditar }
          width={800}
          footer={[
            <Button key="back" onClick={handleCancel} style={{color:'lightgray', backgroundColor:'#dc3545'}} >
              Cancelar
            </Button>,
            <Button key="submit" type="primary" style={{color:'lightgray', backgroundColor:'#dc3545'}} onClick={()=>{handleSubmit()}}>
              {showCreate ? "Crear" :  showDelete ? "Eliminar" : showEditar ? "Editar" : ""}
            </Button>,
          ]}
          >
            <CrearGrupogeneral grupo={grupo} setGrupo={setGrupo} showTable={showTable} data={data} handleCheckboxChange={handleCheckboxChange} showDelete={showDelete} showEditar={showEditar} dataGroup={dataGroup} showCreate={showCreate} handleCheckboxChangeEdit={handleCheckboxChangeEdit} />
          </Modal>
          

        </Layout>
    </>
  )
}

export default IndexGR