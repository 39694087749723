import { Container, Grid } from '@mui/material'
import React from 'react'
import { useEffect, useState } from 'react';
import svg from '../../../../assets/profile-2user.svg'
import svg2 from '../../../../assets/Ellipse3.svg'
import svg3 from '../../../../assets/arrow-up1.svg'
import svg4 from '../../../../assets/monitor.svg'
import { getTicketCount } from './servicesSupport';
import './headerMain.scss'

const rows = [
  {
    tickets: '0',
    // ticketsPorcet: '16%',
    // ticketsTime: 'This month',
    ticketsClosed: '56'
  }
]

const HeaderSupport = () => {
  const [ticketsO, setTicketsO] = useState(0);
  const [ticketsC, setTicketsC] = useState(0);

  useEffect(async () => {
    const ticketsOpen = await getTicketCount('Open')
    const ticketsClosed = await getTicketCount('Completed')

    setTicketsO(ticketsOpen.data)
    setTicketsC(ticketsClosed.data)

    console.log("TICKETSS", ticketsOpen, ticketsClosed)
  }, [])


  return (
    <Container className='headerContainer' style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20vh',
      backgroundColor: 'white',
      borderRadius: '20px',
      filter: 'drop-shadow(0px 10px 60px rgba(226, 236, 249, 0.50))',
      padding: '0px',
    }}>
      <Grid container spacing={2} className='containerHeaderSupport' style={{ filter: 'drop-shadow(0px 10px 60px rgba(226, 236, 249, 0.50))', fill: '#FFF', width: '968px', height: '15vh', flexShrink: 0, color: '#FFFFFF', padding: '15px', marginLeft: 'auto' }}>
        {/* <Grid item md={6} style={{  width: '350px', height: '100px',overflow: 'hidden', position:'relative' }}>
            <img src={svg2} alt="" style={{ width:'84px',height:'77.325px',fill:'linear-gradient(201deg, #D3FFE7 3.14%, #EFFFF6 86.04%)', flexShrink:0,objectFit: 'cover',background:'linear-gradient(#D3FFE7, #EFFFF6)', borderRadius:'50%', position:'absolute',top: '0', left: '0' }}/>
            <img src={svg} alt="" style={{ width:'42px',height:'38.662px',color:'#00AC4F', flexShrink:0,objectFit: 'cover', position:'absolute',top: '18', left: '24' }}/>
            <h3 style={{color:'#ACACAC',fontFamily:'Poppins',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'normal', letterSpacing:'0.14px', objectFit:'cover', position:'relative',left:'80px',top:'-5px'}}>Open tickets</h3>
            <h1 style={{color:'#333',fontFamily:'Poppins',fontSize:'32px', fontStyle:'normal',fontWeight:600,lineHeight:'100%',letterSpacing:'-0.32px', width:'87px',height:'29.457px',flexShrink:0,position:'relative',left:'80px',top:'-15px'}}>23</h1>
            <img src={svg3} alt='' style={{width:'20px',height:'18.411px',flexShrink:0,color:'#00AC4F',position:'relative',left:'80px',top:'-25px'}}/>
            <p style={{color:'#00AC4F', fontFamily:'Poppins',width:'89px',height:'16.57px',flexShrink:0,fontSize:'12px',fontStyle:'normal',fontWeight:700,lineHeight:'normal',letterSpacing:'-0.12px',position:'relative',left:'99px',top:'-40px'}}>16%</p>
            <p style={{color:'#292D32', fontFamily:'Poppins',width:'89px',height:'16.57px',flexShrink:0,fontSize:'12px',fontStyle:'normal',fontWeight:400,lineHeight:'normal',letterSpacing:'-0.12px',position:'relative',left:'126px',top:'-72px'}}>this month</p>
        </Grid> */}
        <Grid item md={6} className='contenerLeftSupport' style={{ width: '350px', height: '100px', overflow: 'hidden', position: 'relative' }}>
          <img className='imagHeaderSupport' src={svg2} alt="" style={{ width: '84px', height: '77.325px', fill: 'linear-gradient(201deg, #D3FFE7 3.14%, #EFFFF6 86.04%)', flexShrink: 0, objectFit: 'cover', background: 'linear-gradient(#D3FFE7, #EFFFF6)', borderRadius: '50%', position: 'absolute', top: '0', left: '0' }} />
          <img className='imgUserSupport' src={svg} alt="" style={{ width: '42px', height: '38.662px', color: '#00AC4F', flexShrink: 0, objectFit: 'cover', position: 'absolute', top: '18', left: '24' }} />
          <h3 className='h3Support' style={{ color: 'red', fontFamily: 'Poppins', fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', letterSpacing: '0.14px', objectFit: 'cover', position: 'relative', left: '80px', top: '-5px', fontWeight: 'bold' }}>Open tickets</h3>
          <h1 className='h1Support' style={{ color: '#333', fontFamily: 'Poppins', fontSize: '32px', fontStyle: 'normal', fontWeight: 600, lineHeight: '100%', letterSpacing: '-0.32px', width: '87px', height: '29.457px', flexShrink: 0, position: 'relative', left: '80px', top: '-15px' }}>{ticketsO ? ticketsO : '--'}</h1>
          {/* <img src={svg3} alt='' style={{ width: '20px', height: '18.411px', flexShrink: 0, color: '#00AC4F', position: 'relative', left: '80px', top: '-25px' }} /> */}
          {/* <p style={{ color: '#00AC4F', fontFamily: 'Poppins', width: '89px', height: '16.57px', flexShrink: 0, fontSize: '12px', fontStyle: 'normal', fontWeight: 700, lineHeight: 'normal', letterSpacing: '-0.12px', position: 'relative', left: '99px', top: '-40px' }}>{row.ticketsPorcet ? row.ticketsPorcet : '--'}</p> */
                /* <p style={{ color: '#292D32', fontFamily: 'Poppins', width: '89px', height: '16.57px', flexShrink: 0, fontSize: '12px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', letterSpacing: '-0.12px', position: 'relative', left: '126px', top: '-72px' }}>{row.ticketsTime ? row.ticketsTime : '--'}</p> */}
        </Grid>
        <Grid item md={6} style={{ width: '350px', height: '100px', overflow: 'hidden', position: 'relative' }}>
          <img className='imgFondo' src={svg2} alt="" style={{ width: '84px', height: '77.325px', fill: 'linear-gradient(201deg, #D3FFE7 3.14%, #EFFFF6 86.04%)', flexShrink: 0, objectFit: 'cover', background: 'linear-gradient(#D3FFE7, #EFFFF6)', borderRadius: '50%', position: 'absolute', top: '0', left: '0' }} />
          <img className='imgMonitor' src={svg4} alt="" style={{ width: '42px', height: '38.662px', color: '#00AC4F', flexShrink: 0, objectFit: 'cover', position: 'absolute', top: '18', left: '24' }} />
          <h3 className='h3HeaderSupport' style={{ color: 'green', fontFamily: 'Poppins', fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', letterSpacing: '0.14px', objectFit: 'cover', position: 'relative', left: '80px', top: '-5px', fontWeight: 'bold' }}>Completed</h3>
          <h1 className='h1HeaderSupport' style={{ color: '#333', fontFamily: 'Poppins', fontSize: '32px', fontStyle: 'normal', fontWeight: 600, lineHeight: '100%', letterSpacing: '-0.32px', width: '87px', height: '29.457px', flexShrink: 0, position: 'relative', left: '80px', top: '-15px' }}>{ticketsC ? ticketsC : '--'}</h1>
        </Grid>
      </Grid>
    </Container>
  )
}

export default HeaderSupport