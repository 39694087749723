import React, { useCallback, useState, useEffect } from "react";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import { redApi } from "../../../../config/Axios";
import useGetLeague from "../../../../hooks/configuracion/useGetLeague";


const Header = ({ mobile, handleclickLeague, isLoading }) => {
  const { league } = useParams();
  const { leagues } = useGetLeague();
  const navigate = useNavigate();

  useEffect(() => {
    setLeagueActive(league);
  }, [league]);

  const [leagueActive, setLeagueActive] = useState("");
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (Array.isArray(leagues) && leagues.length > 0) {
      setMenuItems([...leagues]);
    }
  }, [leagues]);


  useEffect(() => {
    if (menuItems.length > 0) {
      navigate(`/client/${menuItems[0].name.replaceAll(" ", "%20").replaceAll("/", "_")}`);
    }
  }, [menuItems]);
  return (
    <header className="bg-white bg-card3" id="header">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {
              mobile ? (
                <nav className="navbar navbar-expand-lg navbar-light bg-white d-flex justify-content-center">
                  {menuItems.length > 0 ? (
                    menuItems?.map((item, index) => {
                      return (
                        <NavLink
                          key={index}
                          to={`/client/${item.name
                            .replaceAll(" ", "%20")
                            .replaceAll("/", "_")}`}
                          className={`navbar-brand ${leagueActive === item.name ? "active" : ""
                            }`}
                          onClick={() => {
                            setLeagueActive(item.name)
                            handleclickLeague();
                          }}
                        >
                          <img
                            src={item.image}
                            alt={item.name}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/logos/logodefault.png";
                            }}
                            style={{
                              width: "80px",
                              height: "80px",
                              objectFit: "contain",
                            }}
                          />
                        </NavLink>
                      );
                    })
                  ) : (
                    isLoading ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    ) :
                      <div>No hay ligas con juegos</div>
                  )}
                </nav>
              ) : (
                <nav className="navbar navbar-expand-lg navbar-light bg-white d-flex justify-content-center">
                  {menuItems.length > 0 ? (
                    menuItems?.map((item, index) => {
                      return (
                        <NavLink
                          key={index}
                          to={`/client/${item.name
                            .replaceAll(" ", "%20")
                            .replaceAll("/", "_")}`}
                          className={`navbar-brand ${leagueActive === item.name ? "active" : ""
                            }`}
                          onClick={() => setLeagueActive(item.name)}
                        >
                          <img
                            src={item.image}
                            alt={item.name}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/logos/logodefault.png";
                            }}
                            style={{
                              width: "80px",
                              height: "80px",
                              objectFit: "contain",
                            }}
                          />
                        </NavLink>
                      );
                    })
                  ) : (
                    isLoading ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    ) :
                      <div>No hay ligas con juegos</div>
                  )}
                </nav>
              )
            }
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
