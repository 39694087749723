import React from 'react'
import TableHeader from '../tableHeader/TableHeader'
import TableBody from '../tableBody/TableBody'
import './table.css'
const Table = ({dataGroup, edit, selectedRow,setSelectedRow}) => {

  return (
    <div className='container-GR'>
    <table className='table-container'>
        <TableHeader />
        <TableBody dataGroup={dataGroup} edit={edit} selectedRow={selectedRow} setSelectedRow={setSelectedRow}/>
    </table>
    </div>
  )
}

export default Table