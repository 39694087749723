import React from 'react'

const ShowTicket = () => {
  return (
    <>
      <div className='sales-report-date'>
        <div className='sales-report-search'>
          <input type="number" style={{
            width: '300px'
          }} placeholder='Enter Ticket #ID' />
        </div>
        <button>
          Find Ticket
        </button>
      </div>
      <div className='sales-report-tables'>
        <div>
          <table>
            <thead>
              <tr>
                <th>
                  date
                </th>
                <th>
                  cat
                </th>
                <th>
                  team
                </th>
                <th>
                  type
                </th>
                <th>
                  spread
                </th>
                <th>
                  price
                </th>
                <th>
                  status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  05:40 PM
                </td>
                <td>
                  NBA
                </td>
                <td>
                  NY Knicks
                </td>
                <td>
                  RL
                </td>
                <td>
                  +4.0
                </td>
                <td>
                  -175
                </td>
                <td>
                  Ganador
                </td>
              </tr>
              <tr>
                <td>
                  05:40 PM
                </td>
                <td>
                  NBA
                </td>
                <td>
                  LA Lakers
                </td>
                <td>
                  ML
                </td>
                <td>
                  +2.0
                </td>
                <td>
                  -115
                </td>
                <td>
                  Caido
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>apostado</td>
                <td>$100.00</td>
                <td colSpan={3}>
                </td>
                <td>
                  P. Premio
                </td>
                <td>
                  $1,947.00
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  )
}

export default ShowTicket