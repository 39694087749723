import React, { useState, useEffect } from "react";
import { redApi } from "../../../../config/Axios";
import { Modal } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLeagues } from "../../../../utils/Hooks";
import { TableGamest3 } from "../../structures/theme3/TableGames";
import { TableGamesT3 } from "../../structures/theme3/TableGamesT3";

export default function Legacy_rightMobile({
  isLoading,
  setIsLoading,
  gamesForUser,
  setBetToAdd,
  betToAdd,
  setBets,
  bets,
  handleBetAddToTable,
  mobile
}) {
  const navigate = useNavigate();
  // change theme
  const [userTheme, setUserTheme] = useState(1);
  const handleChangeTheme = (e) => {
    setUserTheme(e.target.value);
  };

  // modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    setIsModalVisible(false);
    await redApi.get("/verify").then(async (res) => {
      console.log(res.data);
      if (res.data) {
        if (res.data._id) {
          await redApi
            .put(`/users/${res.data._id}`, { theme: userTheme })
            .then((res) => {
              window.location.reload();
              Swal.fire({
                icon: "success",
                title: "Theme changed",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      }
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { leagues, getLeagues } = useLeagues();
  useEffect(() => {
    getLeagues();
  }, []);
  const [currentleague, setCurrentLeague] = useState({});//new

  useEffect(() => {
    let current = leagues?.find(league => league._id === gamesForUser?.[0]?.league._id);
    setCurrentLeague(current);//new
    console.log({ current });
    // setHead(current?.lineType[0]?.periods[1]?.lines);
    // setHead2(current?.lineType[0]?.periods[2]?.lines);
  }, [gamesForUser, leagues])


  const [tableToView, setTableToView] = useState("lineas");
  const handletableToView = (e) => {
    setTableToView(e.target.value);
  };

  const handleUpScroll = () => {
    let scrrollcontainer = document.getElementById("scrollvertical");
    let scrollPos = scrrollcontainer.scrollTop - scrrollcontainer.offsetHeight;
    scrrollcontainer.scrollTop = scrollPos;
  }
  const handleDownScroll = () => {
    let scrrollcontainer = document.getElementById("scrollvertical");
    let scrollPos = scrrollcontainer.scrollTop + scrrollcontainer.offsetHeight;
    scrrollcontainer.scrollTop = scrollPos;
  }

  const handleRefresh = () => { }
  return (
    <>
      <div className="right_container_header">
        <div>
          <button
            className="right_button"
            onClick={handleUpScroll}
          >
            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.144"></g><g id="SVGRepo_iconCarrier"> <g id="Arrow / Arrow_Up_MD"> <path id="Vector" d="M12 19V5M12 5L6 11M12 5L18 11" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g> </g></svg>
          </button>
        </div>
        <div>
          <button
            className="right_button"
            onClick={handleDownScroll}
          >
            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#fff"
              style={{
                transform: "rotate(180deg)",
              }}
            ><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.144"></g><g id="SVGRepo_iconCarrier"> <g id="Arrow / Arrow_Up_MD"> <path id="Vector" d="M12 19V5M12 5L6 11M12 5L18 11" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g> </g></svg>
          </button>
        </div>
      </div>
      <div className="tables_container">
        {
          currentleague?.lineType?.map((lineType, indexLineType) => (
            <div key={indexLineType} className="lines-games-vertical__tablem mb-4">
              {
                lineType?.periods?.map((period, indexPeriod) => (
                  period.period !== "Completo / 1ra Mitad " && (
                    <div key={indexPeriod} className="lines-games-vertical__table__period mb-4">
                      <div className="lines-games-vertical__table__period__title hide_mobile">
                        <span className="periodight">{period.period}</span>
                      </div>
                      <TableGamesT3 head={period.lines} games={gamesForUser} setBetToAdd={setBetToAdd} betToAdd={betToAdd} setBets={setBets} bets={bets} handleBetAddToTable={handleBetAddToTable} />
                    </div>
                  )
                ))
              }
            </div>
          ))
        }
      </div>
    </>
  );
}
