import React from "react";
import "./loadingspinner.scss"

export const LoadingSpinner = ({color = "white", autoSize = false}) => {
  const style = {};
  if (autoSize) {
    style.width = "auto";
    style.height = "auto";
  }
  return (
    <svg className="loadingSpinner" style={style} viewBox="0 0 50 50">
        <circle className="path" style={{stroke: color}} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg>
  )
}
