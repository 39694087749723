import React, { useState, useEffect, useContext } from 'react'
import PrintResumen from '../../../printer/PrintResumen.jsx'
import { redApi } from '../../../../config/Axios.js'
import moment from 'moment'
import { ReportsContext } from './context/ReportesContext.jsx'

export default function Resumen() {

    const {
        terminals,
        loading: isLoading,
        setLoading: setIsLoading,
    } = useContext(ReportsContext)

    const [summary, setSummary] = useState([]);
    const [isPrinting, setIsPrinting] = useState(false)
    const [filters, setFilters] = useState({})

    useEffect(() => {
        setIsLoading(true)
        if (filters.terminal !== "all" || !filters.terminal && !filters.initDate) {
            redApi.get(`/reports/getSummary?initDate=${moment().format('YYYY-MM-DD')}${filters.terminal ? `&terminal=${filters.terminal}` : ""}`)
                .then((res) => {
                    setSummary(res.data);
                    setIsLoading(false)
                }
                )
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                });
        } else {
            redApi.get(`/reports/getSummary?initDate=${moment().format('YYYY-MM-DD')}`)
                .then((res) => {
                    console.log(res.data);
                    setSummary(res.data);
                    setIsLoading(false)
                }
                )
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                });
        }
    }, [filters])

    const handleChanges = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="container-fluid p-0  m-0">
            <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0 bg-gray-800 h-screen py-4 d-flex flex-column justify-content-center align-items-center gap-3">
                    <h6 className="text-center text-white">Resumen de venta</h6>
                    <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                        <div className="row m-0 p-0 d-flex justify-content-center align-items-center gap-2">
                            <div className="col m-0 p-0">
                                <label className="m-0 p-0">TERMINAL: </label>
                            </div>
                            <div className="col m-0 p-0">
                                <select className="form-control" name="terminal" onChange={handleChanges}>
                                    <option value="all">
                                        Todos
                                    </option>
                                    {terminals.map((pos, index) => (
                                        <option key={index} value={pos._id}>{pos.username}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                        <div className="row m-0 p-0 d-flex justify-content-center align-items-center gap-2">
                            <div className="col m-0 p-0">
                                <button
                                    onClick={() => setIsPrinting(!isPrinting)}
                                    className="px-4"
                                    style={{
                                        backgroundColor: "#202020",
                                        color: "#fff",
                                        borderRadius: "5px",
                                    }}
                                >
                                    Imprimir
                                </button>
                            </div>
                            {/* <div className="m-0 p-0">
                                <button onClick={() => setIsPrinting(!isPrinting)}
                                    style={{
                                        backgroundColor: "#202020",
                                        borderRadius: "5px",
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    Imprimir Tickets Premiados
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {
                isPrinting && !isLoading &&
                <PrintResumen isPrint={isPrinting} summary={summary} setIsPrinting={setIsPrinting} />
            }
        </div>
    )
}