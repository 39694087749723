import { io } from "socket.io-client";

export const socket = io(
    process.env.REACT_APP_WEBSOCKET_URL,
    // 'ws://localhost:5002',
    {
        reconnectionDelayMax: 10000,
        path: '/sockets',
        transports: ['websocket']
    }
);