import { useState, useEffect } from "react";
import { redApi as api } from "../../../../../config/Axios";
import LoadingScreen from "../../../../layout/LoadingScreen";
import moment from "moment";
import { Modal } from "antd";
import { Printer } from "../../../../printer/Print";
import Swal from "sweetalert2";
let tipoDeTicket = ["Todos"];
let tipoDeJugada = ["Todas"];
let tipoDeUsuario = ["Todos"];
let pagado = ["Todos"]; // , "Si", "No"
let statusJugada = ["Play", "Winner", "Loser", "Cancelled"];

export default function Tickets({
    posTerminals,
    consorcios,
}) {
    const [showcheck, setShowcheck] = useState(false);

    const handleCheck = (e) => {
        if (e.target.checked) {
            setShowcheck(true);
        } else {
            setShowcheck(false);
        }
    };

    const [tickets, setTickets] = useState([]);
    // const [posTerminals, setPosTerminals] = useState([]);
    // const [consorcios, setConsorcios] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        consorcio: consorcios?.length > 0 ? consorcios[0]?._id : "all",
        liga: "all",
        equipo: "all",
        pos: posTerminals?.length > 0 ? posTerminals[0]?._id : "all",
        status: "all",
        tipoDeTicket: "all",
        tipoDeJugada: "all",
        tipoDeUsuario: "all",
        pagado: "all",
        statusJugada: "all",
        fechaInicio: moment().format("YYYY-MM-DD"),
        fechaFinal: "",
        ticketId: "",
    });
    console.log(consorcios, posTerminals)
    const [sendWs, setSendWs] = useState(false);
    const [userId, setUserId] = useState(null);

    const [ticketsData, setTicketsData] = useState([]);

    const getDatos = async () => {
        setLoading(true);
        setLoadingMessage("Cargando tickets...");

        await api
            .get(`/tickets?initDate=${moment().format("YYYY-MM-DD")}${filter.consorcio !== "all" ? `&consorcio=${filter.consorcio}` : ""}${filter.pos !== "all" ? `&pos=${filter.pos}` : ""}`)
            .then((res) => {
                setTickets(res.data);
                console.log(res.data);
                setTicketsData(res.data);
            });
        await api.get("/leagues").then(async (res) => {
            setLeagues(res.data);
            await api.get(`/teams?league=${res.data[0]._id}`).then((res) => {
                setTeams(res.data);
                setLoading(false);
            });
        });
        setFilter({
            ...filter,
            consorcio: '',
            pos: '',
        });
    };

    useEffect(async () => {
        await getDatos();
    }, []);

    const handleFilter = (e) => {
        if (e.target.name === "liga") {
            api
                .get(
                    `/teams?league=${e.target.value}&initDate=${moment().format(
                        "YYYY-MM-DD"
                    )}`
                )
                .then((res) => {
                    setTeams(res.data);
                });
        }
        setFilter({
            ...filter,
            [e.target.name]: e.target.value,
        });
        console.log(e.target.value, e.target.name);
        setTicketId(e.target.value)
    };
    console.log('estos tickets', ticketsData);
    const [showPrint, setShowPrint] = useState(false)
    const handlePrint = () => {
        setReportsModalOpen(!reportsModalOpen)
    }
    const [printTicket, setPrintTicket] = useState({ ticketId: 0, print: false });
    const [ticketId, setTicketId] = useState(null);
    const handleSelectChange = (value) => {
        setTicketId(value);
    };

    const handleOpenPrintModal = () => {
        console.log("ticketId", ticketId)
        if (ticketId === "") {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Debes de poner el # del ticket que deseas Imprimir...!",
                focusConfirm: true,
            });

        } else {
            setPrintTicket({ ticketId, print: true });
            setShowPrint(true);
        }


    };

    // set all filters in tickets
    useEffect(async () => {
        let ticketsFiltered;
        if (filter.fechaInicio !== "") {
            if (filter.fechaFinal !== "") {
                await api
                    .get(
                        `/tickets?initDate=${filter.fechaInicio}&finalDate=${moment(
                            filter.fechaFinal
                        )
                            .endOf("day")
                            .format("YYYY-MM-DD")}`
                    )
                    .then((res) => {
                        ticketsFiltered = res.data;
                    });
            } else {
                await api
                    .get(
                        `/tickets?initDate=${filter.fechaInicio}&finalDate=${moment(
                            filter.fechaInicio
                        )
                            .endOf("day")
                            .format("YYYY-MM-DD")}
             `
                    )
                    .then((res) => {
                        ticketsFiltered = res.data;
                    });
            }
        } else {
            await api
                .get(`/tickets?initDate=${moment().format("YYYY-MM-DD")}`)
                .then((res) => {
                    ticketsFiltered = res.data;
                });
        }

        // if (filter.consorcio !== "all") {
        //     ticketsFiltered = ticketsFiltered.filter((ticket) => {
        //         if (ticket?.createdBy?.ownedBy) {
        //             if (ticket.createdBy.ownedBy._id === filter.consorcio) {
        //                 return ticket;
        //             }
        //         }
        //     });
        // }
        if (filter.liga !== "all") {
            ticketsFiltered = ticketsFiltered.filter((ticket) => {
                if (ticket.bets[0].league._id === filter.liga) {
                    return ticket;
                }
            });
        }
        if (filter.equipo !== "all") {
            ticketsFiltered = ticketsFiltered.filter((ticket) => {
                if (ticket.bets[0].team?._id === filter.equipo) {
                    return ticket;
                }
            });
        }
        // if (filter.pos !== "all") {
        //     ticketsFiltered = ticketsFiltered.filter((ticket) => {
        //         if (ticket.createdBy._id === filter.pos) {
        //             return ticket;
        //         }
        //     });
        // }
        if (filter.statusJugada !== "all") {
            ticketsFiltered = ticketsFiltered.filter((ticket) => {
                if (ticket.ticketState === filter.statusJugada) {
                    return ticket;
                }
            });
        }
        if (filter.ticketId !== "") {
            ticketsFiltered = ticketsFiltered.filter((ticket) => {
                if (ticket.ticketId == filter.ticketId) {
                    return ticket;
                }
            });
        }
        setTickets(ticketsFiltered);
    }, [filter]);

    const [showModalTicket, setShowModalTicket] = useState(false);
    const [ticketSelected, setTicketSelected] = useState({});

    const handleSelectTicket = (ticket) => {
        setShowModalTicket(true);
        setTicketSelected(ticket);

    };
    const getPriceValue = (playType, betLine) => {

        let price;
        switch (playType) {
            case "M":
                price = betLine.moneyLine.value
                break;
            case "R":
                price = betLine.price.value
                break;
            case "-":
                price = betLine.posMinus.value
                break;
            case "+":
                price = betLine.posPlus.value
                break;
            case "S+":
                price = betLine.singlePlus.value
                break;
            case "S-":
                price = betLine.singleMinus.value
                break;
            case "P":
                price = betLine.superRunLine.value
                break;
            case "AR":
                price = betLine.altRunLine.value
                break;
            case "H":
                price = betLine.h1Rl.value
                break;
            case "MH":
                price = betLine.h1.value
                break;
            case "Y":
                price = betLine.sia.value
                break;
            case "N":
                price = betLine.noa.value
                break;
            case "F":
                price = betLine.firstRun.value
                break;
            case "K+":
                price = betLine.strikeoutsPlus.value
                break;
            case "K-":
                price = betLine.strikeoutsMinus.value
                break;
            case "T":
                price = betLine.third.value
                break;
            case "E":
                price = betLine.draw.value
                break;
            default:
                price = betLine.moneyLine.value
                break;
        }
        return price;
    }

    let totalAmount = 0
    tickets.reduce((acc, ele) => {
        return totalAmount = acc + ele.toCollect
    }, 0)

    if (loading) return <LoadingScreen>{loadingMessage}</LoadingScreen>;
    const rePrintTicket = () => {
        // setPrintTicket({ ticketId: 0, print: false });
        setPrintTicket({ ticketId: ticketId, print: true });
        setSendWs(false);
        setUserId(ticketId);
        setTicketId(ticketId);
    }
    console.log('soy el ticket ', ticketId)
    return (



        <div className="container-fluid p-0  m-0 position-relative">
            {
                printTicket.print ?

                    <Printer ticketId={printTicket.ticketId} printTicket={printTicket} setPrintTicket={setPrintTicket} />
                    : null
            }
            <Modal
                open={showModalTicket}
                // hidde close button
                closable={false}
                onCancel={() => setShowModalTicket(false)}
                onOk={() => setShowModalTicket(false)}
                width="80%"
            >
                <div
                    className="row m-0 md-p-4"
                    style={{
                        fontSize: "15px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        fontWeight: "bold",
                        border: "1px solid #131313",
                    }}
                >
                    <div className="col-12 m-0">
                        <div className="d-flex gap-2 col mx-auto list-label_and_p">
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>Ticket #: </label>
                                <p>{ticketSelected.ticketId}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>Consorcio: </label>
                                <p>{ticketSelected.createdBy?.ownedBy?.username || "Admin"}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container">
                                <label>Status: </label>
                                <p>{ticketSelected.ticketState}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 m-0">
                        <div className="d-flex gap-2 col mx-auto list-label_and_p">
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>POS: </label>
                                <p>{ticketSelected.createdBy?.username}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>Fecha/Hora de venta: </label>
                                <p>
                                    {moment(ticketSelected.createdAt).format("DD/MM/YYYY HH:mm")}
                                </p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container">
                                <label>Usuario: </label>
                                <p>{ticketSelected?.user?.username || "N/A"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 m-0">
                        <div className="d-flex gap-2 col mx-auto">
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>Apostado: </label>
                                <p>{ticketSelected.amount}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>A ganar: </label>
                                <p>{ticketSelected?.toWin || "N/A"}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container">
                                <label>Tipo de ticket: </label>
                                <p>
                                    {ticketSelected?.bets?.length == 1
                                        ? "Direct"
                                        : ticketSelected?.bets?.length == 2
                                            ? "Pale"
                                            : ticketSelected?.bets?.length == 3
                                                ? "Tripleta"
                                                : ticketSelected?.bets?.length == 4
                                                    ? "Cuarteta"
                                                    : ticketSelected?.bets?.length == 5
                                                        ? "Quinteta"
                                                        : ticketSelected?.bets?.length == 6
                                                            ? "Sexteta"
                                                            : ticketSelected?.bets?.length == 7
                                                                ? "Septeta"
                                                                : ticketSelected?.bets?.length == 8
                                                                    ? "Octeta"
                                                                    : ticketSelected?.bets?.length == 9
                                                                        ? "Noneta"
                                                                        : ticketSelected?.bets?.length == 10
                                                                            ? "Decima"
                                                                            : ticketSelected?.bets?.length == 11
                                                                                ? "Decima primera"
                                                                                : ticketSelected?.bets?.length == 12
                                                                                    ? "Decima segunda"
                                                                                    : ticketSelected?.bets?.length == 13
                                                                                        ? "Decima tercera"
                                                                                        : ticketSelected?.bets?.length == 14
                                                                                            ? "Decima cuarta"
                                                                                            : ticketSelected?.bets?.length == 15
                                                                                                ? "Decima quinta"
                                                                                                : ticketSelected?.bets?.length == 16
                                                                                                    ? "Decima sexta"
                                                                                                    : "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 m-0">
                        <div className="d-flex gap-2 col mx-auto">
                            <div className="d-flex col gap-2 label_and_p_container border-end">
                                <label>A pagar: </label>
                                <p>{ticketSelected?.toCollect || "0"}</p>
                            </div>
                            <div className="d-flex col gap-2 label_and_p_container border-end"></div>
                            <div className="d-flex col gap-2 label_and_p_container"></div>
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0 mt-4">
                    <div className="col-12 m-0 p-0" id="customers"
                    >
                        <table
                        // className="table table-striped table-hover table-sm"
                        >
                            <thead>
                                <tr>
                                    {/* <th scope="col">Codigo</th> */}
                                    <th scope="col">Equipo</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Spread</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ticketSelected?.bets?.map((bet, index) => {
                                    return (
                                        <tr key={index}>
                                            {/* <td>{bet?.team.code}</td> */}
                                            <td>{`${bet?.team.code || bet?.team.codeRD || bet.teamCode} -  ${bet?.team.name}  | (${bet?.league.name})`}</td>
                                            <td>{bet?.playType}</td>
                                            <td>{bet?.line?.spread?.value || "-"}</td>
                                            <td>
                                                {
                                                    getPriceValue(bet?.playType, bet?.line)
                                                }
                                            </td>
                                            <td>{bet?.betState}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
            <div className="row m-0 p-0 d-flex flex-column justify-content-start align-items-center">
                <div className="col-12  m-0 p-0 bg-gray-800 py-4 hide_mobile"
                    style={{
                        position: "sticky",
                        top: "0px",
                        zIndex: "999",
                        backgroundColor: "#fff",
                        borderBottom: "3px solid #ddd",
                    }}
                >
                    <div className="row m-0 p-0 gap-3">
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">POS</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select
                                                className="form-control"
                                                name="pos"
                                                onChange={handleFilter}
                                                disabled
                                                value={posTerminals?.length > 0 ? posTerminals[0]._id : ""}
                                            >
                                                {posTerminals?.map((pos, index) => (
                                                    <option key={index} value={pos._id} selected>
                                                        {pos.username}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Consorcio</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select
                                                className="form-control"
                                                name="consorcio"
                                                onChange={handleFilter}
                                                disabled
                                                value={consorcios?.length > 0 ? consorcios[0]?._id : ""}
                                            >
                                                {consorcios?.map((cons, index) => (
                                                    <option key={index} value={cons?._id} selected>
                                                        {cons?.username}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Liga</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select
                                                className="form-control"
                                                name="liga"
                                                onChange={handleFilter}
                                            >
                                                <option value="all">Todos</option>
                                                {leagues.map((lig, index) => (
                                                    <option key={index} value={lig._id}>
                                                        {lig.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                                            <label className="m-0 p-0">Equipo</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select
                                                className="form-control"
                                                name="equipo"
                                                onChange={handleFilter}
                                            >
                                                <option value="all">Todos</option>
                                                {teams.map((equ, index) => (
                                                    <option key={index} value={equ._id}>
                                                        {equ.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                  <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                    <div className="col-4 m-0 d-flex justify-content-start align-items-center pl-2">
                      <label className="m-0 p-0">Usuario</label>
                    </div>
                    <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                      <select className="form-control">
                        <option value="all">Todos</option>
                        {usuario.map((usu, index) => (
                          <option key={index} value={usu}>{usu}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div> */}
                        </div>
                        <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <input type="checkbox" onChange={handleCheck} />
                                            <label className="m-0 p-0">Rango</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input
                                                type="button"
                                                value="Refrezcar"
                                                onClick={getDatos}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            {showcheck ? (
                                                <label className="m-0 p-0 text-start text-nowrap">
                                                    Fecha inicial
                                                </label>
                                            ) : (
                                                <label className="m-0 p-0 text-start text-nowrap">
                                                    Fecha
                                                </label>
                                            )}
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="fechaInicio"
                                                onChange={handleFilter}
                                                defaultValue={moment().format("YYYY-MM-DD")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                  <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                    <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                      <label className="m-0 p-0 text-start text-nowrap">Staus</label>
                    </div>
                    <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                      <select className="form-control">
                        {status.map((sta, index) => (
                          <option key={index}>{sta}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div> */}
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className="m-0 p-0 text-start text-nowrap">
                                                Tipo de ticket
                                            </label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control">
                                                {tipoDeTicket.map((tip, index) => (
                                                    <option key={index}>{tip}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className="m-0 p-0 text-start text-nowrap">
                                                Tipo de jugada
                                            </label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control">
                                                {tipoDeJugada.map((tip, index) => (
                                                    <option key={index}>{tip}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className="m-0 p-0 text-start text-nowrap">
                                                Tipo de usuario
                                            </label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control">
                                                {tipoDeUsuario.map((tip, index) => (
                                                    <option key={index}>{tip}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label
                                                className={`m-0 p-0 text-start text-nowrap ${!showcheck ? "invisible" : ""
                                                    }`}
                                            >
                                                Fecha final
                                            </label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input
                                                type="date"
                                                className={`form-control ${!showcheck ? "invisible" : ""
                                                    }`}
                                                name="fechaFinal"
                                                onChange={handleFilter}
                                                defaultValue={moment().add(1, "days").format("YYYY-MM-DD")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked
                                            />
                                            <label className="m-0 p-0 text-start text-nowrap">
                                                Ticket #
                                            </label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input
                                                type="text"
                                                className="form-control"
                                                // onBlur={handleFilter}
                                                onChange={handleFilter}
                                                name="ticketId"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className="m-0 p-0 text-start text-nowrap">
                                                Pagado
                                            </label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control">
                                                {pagado.map((pag, index) => (
                                                    <option key={index}>{pag}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 p-0 d-flex justify-content-start align-items-center">
                                            <label className="m-0 p-0 text-start text-nowrap">
                                                Status jugada
                                            </label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select
                                                className="form-control"
                                                name="statusJugada"
                                                onChange={handleFilter}
                                            >
                                                <option value="all" selected>
                                                    Todos
                                                </option>
                                                {statusJugada.map((sta, index) => (
                                                    <option key={index} value={sta}>
                                                        {sta}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 m-0 p-0 d-flex flex-column gap-2">
                            {/*                             
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control">
                                                option
                                                <option value="Imprimir" selected>
                                                    <button onClick={() => setShowPrint(true)}>Imprimir</button>
                                                </option>
                                                <button type="primary" onClick={handleOpenPrintModal} disabled={!ticketId}>
                                                    Print Ticket
                                                </button>
                                                <option value="Exportar">Exportar</option>
                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>
                             */}
                            {/* <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Buscar" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Imprimir" onClick={handleOpenPrintModal} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Exportar" onClick={handleOpenPrintModal} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Email" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 py-4 position-relative" id="customers">
                    <table className="table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Consorcio</th>
                                <th scope="col">POS</th>
                                {/* <th scope="col">Usuario</th> */}
                                <th scope="col">Ticket #</th>
                                <th scope="col">Fecha/Hora</th>
                                <th scope="col">Tipo de Ticket</th>
                                <th scope="col">Apostado</th>
                                <th scope="col">A pagar</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets?.map((ticket, index) => (
                                <tr
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleSelectTicket(ticket)}
                                >
                                    <td>{ticket?.createdBy?.ownedBy?.username || "Admin"}</td>
                                    <td>{ticket?.createdBy ? ticket.createdBy?.username : "N/A"}</td>
                                    <td>#{ticket?.ticketId ? ticket.ticketId : "N/A"}</td>
                                    <td>
                                        {ticket?.createdAt
                                            ? ticket.createdAt.slice(0, 10) + " " + ticket.createdAt.slice(11, 16)
                                            : "N/A"}
                                    </td>
                                    <td>
                                        {ticket?.bets.length == 1
                                            ? "Direct"
                                            : ticket?.bets.length == 2
                                                ? "Pale"
                                                : ticket?.bets.length == 3
                                                    ? "Tripleta"
                                                    : ticket?.bets.length == 4
                                                        ? "Cuarteta"
                                                        : ticket?.bets.length == 5
                                                            ? "Quinteta"
                                                            : ticket?.bets.length == 6
                                                                ? "Sexteta"
                                                                : ticket?.bets.length == 7
                                                                    ? "Septeta"
                                                                    : ticket?.bets.length == 8
                                                                        ? "Octeta"
                                                                        : ticket?.bets.length == 9
                                                                            ? "Noneta"
                                                                            : ticket?.bets.length == 10
                                                                                ? "Decima"
                                                                                : ticket?.bets.length == 11
                                                                                    ? "Decima primera"
                                                                                    : ticket?.bets.length == 12
                                                                                        ? "Decima segunda"
                                                                                        : ticket?.bets.length == 13
                                                                                            ? "Decima tercera"
                                                                                            : ticket?.bets.length == 14
                                                                                                ? "Decima cuarta"
                                                                                                : ticket?.bets.length == 15
                                                                                                    ? "Decima quinta"
                                                                                                    : ticket?.bets.length == 16
                                                                                                        ? "Decima sexta"
                                                                                                        : "N/A"}
                                    </td>
                                    <td>{ticket?.amount ? ticket?.amount : "N/A"}</td>
                                    <td>{ticket?.toCollect ? ticket?.toCollect : "N/A"}</td>
                                    <td>{ticket?.ticketState ? ticket?.ticketState : "N/A"}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            {
                                tickets?.length > 0 ? (
                                    <tr>

                                        <td colSpan="2" className="text-center">Total de tickets: {tickets.length}</td>
                                        <td colSpan="3" className="text-center">Total a pagar: {totalAmount}</td>

                                    </tr>

                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">No hay datos</td>
                                    </tr>

                                )
                            }
                        </tfoot>

                    </table>
                </div>
            </div>
            <Modal
                open={showPrint}
                onCancel={handlePrint}
                footer={null}
                width='980px'
                title={<label style={{ fontWeight: 'bold', color: 'darkgray', fontSize: '25px' }}>Pay Ticket</label>}
            >
                {printTicket.ticketId !== 0 && (
                    <Printer
                        ticketId={printTicket.ticketId}
                        printTicket={printTicket}
                        setPrintTicket={setPrintTicket}
                    />
                )}
            </Modal>
        </div>

    );
}
