import React, { useEffect, useState } from 'react'
import { redApi } from '../../../../../config/Axios'

const LimitePorleague = ({ createConsorcios, setCreateConsorcios }) => {


    const hanleChange = (e, index1, index2) => {
        let leagueLimits = [...createConsorcios.leagueLimits]
        leagueLimits[index1].ticketType[index2].value = Number(e.target.value)
        setCreateConsorcios({
            ...createConsorcios,
            leagueLimits
        })
    }

    const [leagues, setleagues] = useState([])

    const typePlays = [
        "Todos",
        "ML",
        "TL",
        "RL",
        "SRL",
        "Si anotaran",
        "No anotaran",
        "Primera carrera",
        "Ponche",
        "Solo",
        "Jugadores",
        "GS ML",
        "GS TL",
        "Tercios",
        "Serie",
        "R/L A",
        "Tercios TL",
    ]

    useEffect(() => {
        redApi.get('leagues')
            .then(res => {
                setleagues(res.data)
            })
    }, [])
    return (
        <div className="row" id='ConsorcioTlmtleague'>
            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <h6 className='text-center h2 m-2'>Limite por Liga</h6>
                    </div>
                    <div className="col-12">
                        <div className="row mb-4">
                            <div className="col-5 d-flex justify-content-center align-items-center gap-4">
                                <label className='labelGeneral'>leagues</label>
                                <select className="">
                                    <option value="all" selected>Todas</option>
                                    {
                                        leagues?.map((item, index) => {
                                            return (
                                                <option key={index} value={item._id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-5 d-flex justify-content-center align-items-center gap-4">
                                <label className='labelGeneral'>Tipo de jugada</label>
                                <select className="">
                                    {
                                        typePlays?.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" id='limiteporligas_table'>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">league</th>
                                <th scope="col">Tipo de jugada</th>
                                {
                                    createConsorcios.leagueLimits ? createConsorcios?.leagueLimits[0]?.ticketType.map((item, index) => {
                                        return (
                                            <th key={index} scope="col">{item.betsNumber}</th>
                                        )
                                    })
                                        : [
                                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16
                                        ].map((item, index) => {
                                            return (
                                                <th key={index} scope="col">{item}</th>
                                            )
                                        })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                createConsorcios.leagueLimits ? createConsorcios?.leagueLimits?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.league.name}</td>
                                            <td>{item.playType}</td>
                                            {
                                                item.ticketType.map((item, ind) => {
                                                    return (
                                                        <td key={ind}>
                                                            <input type="number" name={item.betsNumber} className="form-control" value={item.value} onChange={(e) => hanleChange(e, index, ind)} />
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                                    : <td colSpan="18" className='text-center text-danger'>No hay datos</td>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default LimitePorleague