import React, { useState, useEffect, useContext, useRef  } from "react";
import { redApi } from "../../../../../config/Axios";
import { Modal } from "antd";
import { getTerminals } from "../../../../../utils/getUsers";
import { AuthContext } from "../../../../../auth";

const General = ({
  terminalsSelected,
  setTerminalsSelected,
  setIsWebUser,
  isWebUser,
  ...props
}) => {

  let user = props?.usuario;

  // Create reference to set default values
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const emailRef = useRef(null);
  const isGroupRef = useRef(null)
  const consorcioRef = useRef(null)

  const handleInputChange = (e) => {
    console.log(e.target.value)
    if (
      e.target?.selectedOptions?.[0]?.attributes?.group?.value == "WebUsers"
    ) {
      setIsWebUser(true);
    } else if (e.target?.selectedOptions?.[0]?.attributes?.group) {
     // setIsWebUser(false);
    }
    props.setData({
      ...props.data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    // Default values conditions
    const defaultValueUsername = user?.username || null;
    const defaultValuePassword = '';
    const defaultValueIsGroup = user?.isGroup || '';
    const defaultValueEmail = user ? user.email : null;
    const defaultValueOwnedBy = user?.ownedBy?._id || '';

    // Assing default values to inputs
    usernameRef.current.value = defaultValueUsername;
    passwordRef.current.value = defaultValuePassword;
    isGroupRef.current.value = defaultValueIsGroup;
    emailRef.current.value = defaultValueEmail;
    consorcioRef.current.value = defaultValueOwnedBy;

    handleInputChange({
      target: {
        name: 'username',
        value: defaultValueUsername,
      },
    });

    handleInputChange({
      target: {
        name: 'password',
        value: defaultValuePassword,
      },
    });

    handleInputChange({
      target: {
        name: 'isGroup',
        value: defaultValueIsGroup,
      },
    });

    handleInputChange({
      target: {
        name: 'email',
        value: defaultValueEmail,
      },
    });

    handleInputChange({
      target: {
        name: 'ownedBy',
        value: defaultValueOwnedBy,
      },
    });
  }, [])

  useEffect(() => {
    if (props.allgroups.length === 1) {
      props.setData({
        ...props.data,
        isGroup: props.allgroups[0]._id,
      });
    }
  }, [props.allgroups.length]);

  const [showPassword, setShowPassword] = useState(false);

  const addNewTerminal = (terminal) => {
    setTerminalsSelected([...terminalsSelected, terminal]);
  };

  const removeTerminal = () => {
    if (!terminalSelected) return;
    setTerminalsSelected(
      terminalsSelected.filter((item) => item._id !== terminalSelected._id)
    );
  };

  const [terminalSelected, setTerminalSelected] = useState([]);

  // check if select all or select one, two, three, etc
  const [checkAll, setCheckAll] = useState(true);

  const handleCheckAll = () => {
    setCheckAll(!checkAll);
  };

  const [terminals, setTerminals] = useState([]);
  useEffect(() => {
    getTerminals().then((res) => {
      setTerminals(res);
    });
  }, []);

  // modal to add new terminal
  const [modalAddTerminal, setModalAddTerminal] = useState(false);

  const handleModalAddTerminal = () => {
    setModalAddTerminal(!modalAddTerminal);
  };

  const [terminalToAdd, setTerminalToAdd] = useState({});
  const { userLevel } = useContext(AuthContext);
  useEffect(() => {
    if (userLevel === 2) {
      setCheckAll(false);
    }
  }, [userLevel]);
  const handleterminalToAdd = (e, terminal) => {
    if (userLevel === 2) {
      setTerminalsSelected([terminal]);
      return;
    }
    if (e.target.checked) {
      setTerminalsSelected([terminal]);
    } else {
      setTerminalsSelected(
        terminalsSelected.filter((item) => item._id !== terminal._id)
      );
    }
  };

  const handleOkModal = () => {
    if (terminalToAdd) {
      addNewTerminal(terminalToAdd);
      setTerminalToAdd({});
    }
    setModalAddTerminal(false);
  };

  return (
    <div className="row">
      <div className="col">
        {/* <div className="col-12">
                <label>Nombres</label>
                <input type="text" className="form-control" defaultValue={user ? user : null} />
            </div>
            <div className="col-12">
                <label>Apellidos</label>
                <input type="text" className="form-control" defaultValue={user ? user : null} />
            </div> */}
        <div className="col-12">
          <label>username</label>
          <input
            type="text"
            className="form-control"
            defaultValue={user?.username || ""}
            name="username"
            onChange={handleInputChange}
            disabled={props.modalEditar ? true : false}
            ref={usernameRef}
          />
        </div>
        <div className="col-12">
          <label>password</label>
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            placeholder={props.modalEditar ? "********" : ""}
            name="password"
            onChange={handleInputChange}
            ref={passwordRef}
            // disabled={props.modalEditar ? true : false}
          />
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
            />
            <label className="form-check-label">Show Password</label>
          </div>
        </div>
        <div className="col-12">
          <label>Group</label>
          <select
            className="form-control"
            name="isGroup"
            onChange={handleInputChange}
            ref={isGroupRef}
          >
            {props.allgroups.length === 1 ? null : (
              <option value="">Selecciona grupo</option>
            )}
            {props.allgroups?.map((group, index) => {
              return (
                <option key={index} value={group._id} group={group.name}>
                  {group.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-12">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            onChange={handleInputChange}
            ref={emailRef}
          />
        </div>
        <div className="col-12">
          <label>Consorcio</label>
          <select
            className="form-control"
            name="ownedBy"
            onChange={handleInputChange}
            ref={consorcioRef}
          >
            <option value="">Selecciona consorcio</option>
            {props.allConsorcios?.map((consorcio, index) => {
              return (
                <option
                  key={index}
                  value={consorcio._id}
                >
                  {consorcio.username}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-12 d-flex gap-2">
          {/* <input type="checkbox" className="form-control" style={{ width: '20px' }} />
        <label>Idioma</label> */}
        </div>
      </div>
      <div className="col">
        <div className="col-12">
          <label>Todas</label>
          <input
            type="checkbox"
            className="form-check"
            style={{ width: "20px" }}
            onChange={handleCheckAll}
            checked={checkAll}
            disabled={userLevel === 2}
          />
        </div>
        {!checkAll ? (
          <div className="col-12">
            <label>Terminales</label>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  {/* NDI */}
                  <th scope="col">Select</th>
                  <th scope="col">Id</th>
                  <th scope="col">Nombre</th>
                </tr>
              </thead>
              <tbody>
                {terminals.length > 0 ? (
                  terminals?.map((terminal, index) => {
                    return (
                      <tr
                        key={index}
                        className={
                          terminalSelected?._id === terminal?._id
                            ? "bg-warning"
                            : ""
                        }
                      >
                        <td>
                          <input
                            type="checkbox"
                            className="form-check"
                            style={{ width: "20px" }}
                            id={terminal._id}
                            onClick={
                              userLevel === 2
                                ? (e) => handleterminalToAdd(e, terminal)
                                : null
                            }
                            checked={
                              userLevel === 2
                                ? terminalsSelected[0]?._id === terminal?._id
                                : null
                            }
                            onChange={
                              userLevel !== 2
                                ? (e) => handleterminalToAdd(e, terminal)
                                : null
                            }
                          />
                        </td>
                        <td>{terminal?.NDI}</td>
                        <td>{terminal.username}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className={"bg-warning"}>
                    <td colSpan={3} className="text-center">
                      CARGANDO..
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default General;
