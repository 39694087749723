import React from 'react'
import { Container } from 'react-bootstrap'

export default function EditTerminal() {
  return (
    <form id="formEditTerminal">
      <div className="modal fade modal-terminals" id="editTerminal" tabIndex="-1" aria-labelledby="editTerminalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="EditTerminalLabel">Configuración de Terminales</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Container fluid>
                <div className="row">
                  <div className="col-6 col-md-3 mb-2">
                    <span>ID 01</span>
                  </div>
                  <div className="col-6 col-md-3 mb-2">
                    <span>01 RL</span>
                  </div>
                  <div className="col-6 col-md-3 mb-2">
                    <span>Rojo: </span>
                  </div>
                  <div className="col-6 col-md-3 mb-2 d-flex">
                    <label htmlFor="">Limite</label>
                    <input min="0" type="number" id="editLimit"/>
                  </div>
                </div>
                <div className="row mb-3 border-bottom">
                  <div className="col-6 col-md-3 mb-2">
                      <input type="checkbox" name="" id="editCheckActive"/>
                      <label htmlFor="">Activado</label>
                  </div>
                  <div className="col-6 col-md-3 mb-2">
                    <span>Balance: </span>
                  </div>
                  <div className="col-6 col-md-3 mb-2">
                    <span>Caída Acumulada: 0</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="true">General</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="lotteries-tab" data-bs-toggle="tab" data-bs-target="#lotteries" type="button" role="tab" aria-controls="lotteries" aria-selected="false">Loterias</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages" type="button" role="tab" aria-controls="messages" aria-selected="true">Mensajes</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="prizes-tab" data-bs-toggle="tab" data-bs-target="#prizes" type="button" role="tab" aria-controls="prizes" aria-selected="false">Premios</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="caida-tab" data-bs-toggle="tab" data-bs-target="#caida" type="button" role="tab" aria-controls="caida" aria-selected="true">Caída</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="local-tab" data-bs-toggle="tab" data-bs-target="#local" type="button" role="tab" aria-controls="local" aria-selected="false">Local</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="general-limit-tab" data-bs-toggle="tab" data-bs-target="#general-limit" type="button" role="tab" aria-controls="general-limit" aria-selected="true">Límite General</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="number-control-tab" data-bs-toggle="tab" data-bs-target="#number-control" type="button" role="tab" aria-controls="number-control" aria-selected="false"># en Control</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="abonados-tab" data-bs-toggle="tab" data-bs-target="#abonados" type="button" role="tab" aria-controls="abonados" aria-selected="true">Abonados</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="rojo-tab" data-bs-toggle="tab" data-bs-target="#rojo" type="button" role="tab" aria-controls="rojo" aria-selected="false">Rojo</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="prestamos-tab" data-bs-toggle="tab" data-bs-target="#prestamos" type="button" role="tab" aria-controls="prestamos" aria-selected="true">Préstamos</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="users-tab" data-bs-toggle="tab" data-bs-target="#users" type="button" role="tab" aria-controls="users" aria-selected="false">Usuarios</button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                          <form>
                            <div className="row mt-3">
                                <div className="col-12 col-lg-6 mb-2">
                                  <label htmlFor="editUsername">Usuario:</label>
                                  <select name="editUsername" id="editUsername">
                                  </select>
                                </div>

                                <div className="col-12 col-lg-6 mb-2">
                                  <label htmlFor="editDescription">Descripción:</label>
                                  <input type="text" id="editDescription"/>
                                </div>


                                <div className="col-12 mb-2">
                                  <div className="row d-flex align-items center">
                                    <div className="col-6 col-lg-3 mb-2 d-flex align-items-center">
                                      <input type="checkbox" id="checkBonus"/>
                                      <label htmlFor="">Bonos</label>
                                    </div>
                                    <div className="col-6 col-lg-3 mb-2 d-flex flex-column">
                                      <label htmlFor="">Múltiplo $</label>
                                      <input min="0" type="number" id="editMultiplo"/>
                                    </div>
                                    <div className="col-6 col-lg-3 mb-2 d-flex flex-column">
                                      <label htmlFor="">Cantidad</label>
                                      <input min="0" type="number" id="editQuantity"/>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12 col-lg-6 mb-2">
                                  <label htmlFor="tituloRecibo">Título del Recibo:</label>
                                  <input type="text" id="tituloRecibo"/>
                                </div>

                                <div className="col-12 col-lg-6 mb-2">
                                  <label htmlFor="editConsorcio">Consorcio: </label>
                                  <select disabled id="editConsorcio"></select>
                                </div>
                            </div>
                          </form>
                        </div>

                        <div className="tab-pane fade" id="lotteries" role="tabpanel" aria-labelledby="lotteries-tab">
                          <ul className="nav nav-tabs mt-3" id="lotsTabs" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="normal-lotteries-tab" data-bs-toggle="tab" data-bs-target="#normal-lotteries" type="button" role="tab" aria-controls="normal-lotteries" aria-selected="true">Normal</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="bolita-lotteries-tab" data-bs-toggle="tab" data-bs-target="#bolita-lotteries" type="button" role="tab" aria-controls="bolita-lotteries" aria-selected="false">Bolita</button>
                            </li>
                          </ul>
                          <div className="tab-content" id="lotsTabsContent">
                            <div className="tab-pane fade show active" id="normal-lotteries" role="tabpanel" aria-labelledby="normal-lotteries-tab">
                              <div className="row mt-3">
                                <div className="table-responsive-lg">
                                  <table className="table table-sm table-bordered table-striped table-hovered">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>Loterías</th>
                                      </tr>
                                    </thead>
                                    <tbody id="tableLotteries">
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="bolita-lotteries" role="tabpanel" aria-labelledby="bolita-lotteries-tab">
                              <div className="row mY-3">
                                <div className="table-responsive-lg">
                                  <table className="table table-sm table-bordered table-striped table-hovered">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>Loterías</th>
                                      </tr>
                                    </thead>
                                    <tbody id="tableLotteriesUS">
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>

                        <div className="tab-pane fade" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                          <ul className="nav nav-tabs mt-3" id="messageTabs" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="normal-message-tab" data-bs-toggle="tab" data-bs-target="#normal-message" type="button" role="tab" aria-controls="normal-message" aria-selected="true">Normal</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="bolita-message-tab" data-bs-toggle="tab" data-bs-target="#bolita-message" type="button" role="tab" aria-controls="bolita-message" aria-selected="false">Bolita</button>
                            </li>
                          </ul>
                          <div className="tab-content" id="messageTabsContent">
                            <div className="tab-pane fade show active" id="normal-message" role="tabpanel" aria-labelledby="normal-message-tab">
                              <form>
                                <div className="row mt-3 d-flex justify-content-center">
                                  <div className="col-8 mb-2 d-flex">
                                    <label htmlFor="lotteryMessageSelect">Lotería</label>
                                    <select id="lotteryMessageSelect"></select>
                                  </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                  <div className="col-8 mb-2 d-flex">
                                    <label htmlFor="lotteryMessage">Mensajes</label>
                                    <textarea id="lotteryMessage" cols="50" rows="4"></textarea>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="tab-pane fade" id="bolita-message" role="tabpanel" aria-labelledby="bolita-message-tab">
                              <form>
                                <div className="row mt-3 d-flex justify-content-center">
                                  <div className="col-8 mb-2 d-flex">
                                    <label htmlFor="lotteryMessageSelectUS">Lotería</label>
                                    <select id="lotteryMessageSelectUS"></select>
                                  </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                  <div className="col-8 mb-2 d-flex">
                                    <label htmlFor="lotteryMessageUS">Mensajes</label>
                                    <textarea id="lotteryMessageUS" cols="50" rows="4"></textarea>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>

                        </div>

                        <div className="tab-pane fade" id="prizes" role="tabpanel" aria-labelledby="prizes-tab">
                          <ul className="nav nav-tabs mt-3" id="prizeTabs" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="normal-prizes-tab" data-bs-toggle="tab" data-bs-target="#normal-prizes" type="button" role="tab" aria-controls="normal-prizes" aria-selected="true">Normal</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="bolita-prizes-tab" data-bs-toggle="tab" data-bs-target="#bolita-prizes" type="button" role="tab" aria-controls="bolita-prizes" aria-selected="false">Bolita</button>
                            </li>
                          </ul>
                          <div className="tab-content" id="prizeTabsContent">
                            <div className="tab-pane fade show active" id="normal-prizes" role="tabpanel" aria-labelledby="normal-prizes-tab">
                              <div className="row mt-3">
                                <div className="col-12 col-lg-3 mb-2">
                                  <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-striped table-hovered">
                                      <thead>
                                        <tr>
                                          <th></th>
                                          <th>Loterías</th>
                                        </tr>
                                      </thead>
                                      <tbody id="tablePrizes" style={{maxHeight: '50px'}}>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div id="prizesRD" className="col-12 col-lg-9 mb-2">
                                  <div className="row">
                                    <div className="col-12 mb-2 d-flex justify-content-center">
                                      <span>Directos</span>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="directFirst">Primera</label>
                                      <input type="number" name="prizesDirect" min="0" id="directFirst"/>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="directSecond">Segunda</label>
                                      <input type="number" name="prizesDirect" min="0" id="directSecond"/>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="directThird">Tercera</label>
                                      <input type="number" name="prizesDirect" min="0" id="directThird"/>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="directPercent">Porciento</label>
                                      <input type="number" name="prizesDirect" min="0" max="100" id="directPercent"/>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 mb-2 d-flex justify-content-center">
                                      <span>Pale</span>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="paleFirst">1ra y 2da</label>
                                      <input type="number" name="prizesPale" min="0" id="paleFirst"/>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="paleSecond">2da y 3ra</label>
                                      <input type="number" name="prizesPale" min="0" id="paleSecond"/>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="paleThird">1ra y 3ra</label>
                                      <input type="number" name="prizesPale" min="0" id="paleThird"/>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="palePercent">Porciento</label>
                                      <input type="number" name="prizesPale" min="0" max="100" id="palePercent"/>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 mb-2 d-flex justify-content-center">
                                      <span>Tripleta</span>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="tripletFirst">1ra-2da-3ra</label>
                                      <input type="number" name="prizesTriplet" min="0" id="tripletFirst"/>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="tripletSecond">1ra-2da y 1ra-3ra</label>
                                      <input type="number" name="prizesTriplet" min="0" id="tripletSecond"/>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="tripletThird">2da-3ra</label>
                                      <input type="number" name="prizesTriplet" min="0" id="tripletThird"/>
                                    </div>
                                    <div className="col-3 mb-2 d-flex flex-column">
                                      <label htmlFor="tripletPercent">Porciento</label>
                                      <input type="number" name="prizesTriplet" min="0" max="100" id="tripletPercent"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                            <div className="tab-pane fade" id="bolita-prizes" role="tabpanel" aria-labelledby="bolita-prizes-tab">
                              <div className="row mt-3">
                                <div className="col-12 col-lg-3 mb-2">
                                  <div className="table-responsive">
                                    <table className="table table-sm table-bordered table-striped table-hovered">
                                      <thead>
                                        <tr>
                                          <th></th>
                                          <th>Loterías</th>
                                        </tr>
                                      </thead>
                                      <tbody id="tablePrizesUS" style={{maxHeight: '50px'}}>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div id="prizesUS" className="col-12 col-lg-9 mb-2">
                                  <div className="row mb-3">
                                    <div className="col-12 d-flex justify-content-center mb-2"><span>Pick 3</span></div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="straightPick3">Straight</label>
                                      <input type="number" name="pick3" id="straightPick3"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="Pick3">Straight-2</label>
                                      <input disabled value="0" type="number" name="pick3" id="Pick3"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="box3Pick3">Box 3-way</label>
                                      <input disabled value="0" type="number" name="pick3" id="box3Pick3"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="box6Pick3">Box 6-way</label>
                                      <input disabled value="0" type="number" name="pick3" id="box6Pick3"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="frontPick3">Front pair</label>
                                      <input type="number" name="pick3" id="frontPick3"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="backPick3">Back pair</label>
                                      <input type="number" name="pick3" id="backPick3"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="frontBoxPick3">Front pair Box</label>
                                      <input disabled value="0" type="number" name="pick3" id="frontBoxPick3"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="backBoxPick3">Back pair box</label>
                                      <input disabled value="0" type="number" name="pick3" id="backBoxPick3"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="percentPick3">Porciento</label>
                                      <input type="number" name="pick3" min="0" max="100" id="percentPick3"/>
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 d-flex justify-content-center mb-2"><span>Pick 4</span></div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="straightPick4">Straight</label>
                                      <input type="number" name="pick4" id="straightPick4"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="Pick4">Straight-2</label>
                                      <input disabled value="0" type="number" name="pick4" id="Pick4"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="box4Pick4">Box 4-way</label>
                                      <input disabled value="0" type="number" name="pick4" id="box4Pick4"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="box6Pick4">Box 6-way</label>
                                      <input disabled value="0" type="number" name="pick4" id="box6Pick4"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="box12Pick4">Box 12-way</label>
                                      <input disabled value="0" type="number" name="pick4" id="box12Pick4"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="box24Pick4">Box 24-way</label>
                                      <input disabled value="0" type="number" name="pick4" id="box24Pick4"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="frontPick4">Front pair</label>
                                      <input type="number" name="pick4" id="frontPick4"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="backPick4">Back pair</label>
                                      <input type="number" name="pick4" id="backPick4"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="percentPick4">Porciento</label>
                                      <input type="number" name="pick4" min="0" max="100" id="percentPick4"/>
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 mb-2"><span>Vene D</span></div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="firstVeneD">Primera</label>
                                      <input type="number" name="veneD" id="firstVeneD"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="secondVeneD">Segunda</label>
                                      <input type="number" name="veneD" id="secondVeneD"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="thirdVeneD">Tercera</label>
                                      <input type="number" name="veneD" id="thirdVeneD"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="percentVeneD">Porciento</label>
                                      <input type="number" name="veneD" min="0" max="100" id="percentVeneD"/>
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 mb-2"><span>Vene P</span></div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="firstVeneP">1ra y 2do o 3ra</label>
                                      <input type="number" name="veneP" id="firstVeneP"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="secondVeneP">2da y 3ra</label>
                                      <input type="number" name="veneP" id="secondVeneP"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="percentVeneP">Porciento</label>
                                      <input type="number" name="veneP" min="0" max="100" id="percentVeneP"/>
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 mb-2"><span>Vene T</span></div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="firstVeneT">1ra-2da-3ra</label>
                                      <input type="number" name="veneT" id="firstVeneT"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="secondVeneT">1ra-2da 1ra-3ra</label>
                                      <input type="number" name="veneT" id="secondVeneT"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="thirdVeneT">2da-3ra</label>
                                      <input type="number" name="veneT" id="thirdVeneT"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="percentthirdVeneT">Porciento</label>
                                      <input type="number" name="veneT" min="0" max="100" id="percentthirdVeneT"/>
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 mb-2"><span>Pulito</span></div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="firstPulito">1</label>
                                      <input type="number" name="pulito" id="firstPulito"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="secondPulito">2</label>
                                      <input type="number" name="pulito" id="secondPulito"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="thirdPulito">3</label>
                                      <input type="number" name="pulito" id="thirdPulito"/>
                                    </div>
                                    <div className="col-3 mb-2">
                                      <label htmlFor="percentPulito">Porciento</label>
                                      <input type="number" name="pulito" min="0" max="100" id="percentPulito"/>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                          
                          
                        </div>

                        <div className="tab-pane fade" id="caida" role="tabpanel" aria-labelledby="caida-tab">
                          <div className="row mt-3 d-flex justify-content-center">
                            <div className="col-6 mb-3 d-flex align-items-center">
                              <input type="checkbox" id="activarCaidaCheck"/>
                              <label htmlFor="activarCaidaCheck">Activar</label>
                            </div>
                            <div className="col-6 mb-3 d-flex flex-column">
                              <label htmlFor="percentageCaida">% de Caída</label>
                              <input type="number" min="0" max="100" id="percentageCaida"/>
                            </div>
                            <div className="col-12 col-lg-8 mb-2">
                              <div className="row">
                                <div className="col-6 mb-2">
                                  <input type="radio" checked name="radioCaida" id="radioAcumulativa" value="Caida acumulativa"/>
                                  <label htmlFor="radioAcumulativa">Caída Acumulativa</label>
                                </div>
                                <div className="col-6 mb-2">
                                  <input type="radio" name="radioCaida" id="radioCobro" value="Caida por cobro"/>
                                  <label htmlFor="radioCobro">Caída por Cobro</label>
                                </div>
                                <div className="col-12 col-lg-6 mb-2">
                                  <label htmlFor="frecuencyCaida">Frecuencia</label>
                                  <select id="frecuencyCaida">
                                    <option value="diario">Diario</option>
                                    <option value="semanal">Semanal</option>
                                    <option value="mensual">Mensual</option>
                                  </select>
                                </div>
                                <div className="col-12 col-lg-6 mb-2">
                                  <label htmlFor="dayCaida">Dia</label>
                                  <select disabled id="dayCaida">
                                    <option value=""></option>
                                  </select>
                                </div>
                                <div className="col-12 col-lg-6 mb-2">
                                  <button type="button">Ver detalles del Acumulado</button>
                                </div>
                                <div className="col-12 col-lg-6 mb-2">
                                  <button type="button">Borrar Acumulado</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="local" role="tabpanel" aria-labelledby="local-tab">
                          <div className="row mt-3">
                            <div className="col-12 col-lg-4 mb-2">
                              <div className="row">
                                <div className="col-12 mb-2">
                                  <label htmlFor="localFinalHour">Hora final</label>
                                  <input type="time" id="localFinalHour"/>
                                </div>
                                <div className="col-12 mb-2 d-flex justify-content-center">
                                  <input type="checkbox" id="activateGeneralLimit"/>
                                  <label htmlFor="activateGeneralLimit">Activar Límite General</label>
                                </div>
                                <div className="col-12 mb-2">
                                  <div className="table-responsive-lg">
                                    <table className="table table-sm table-bordered table-striped table-hovered">
                                      <thead>
                                        <tr>
                                          <th></th>
                                          <th>Loterías</th>
                                        </tr>
                                      </thead>
                                      <tbody id="tableLocal">
                                        <tr>
                                          <td><input type="checkbox" name="editLocalChecks" id="checkboxLocalAll" value="todas"/></td>
                                          <td><label htmlFor="checkboxLocalAll">Todas</label></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-8 mb-2">
                              <div className="row">
                                <div className="col-4 mb-2 d-flex flex-column">
                                  <label htmlFor="localDirect">Directo</label>
                                  <input type="number" min="0" id="localDirect"/>
                                </div>
                                <div className="col-4 mb-2 d-flex flex-column">
                                  <label htmlFor="localPale">Pale</label>
                                  <input type="number" min="0" id="localPale"/>
                                </div>
                                <div className="col-4 mb-2 d-flex flex-column">
                                  <label htmlFor="localTriplet">Tripleta</label>
                                  <input type="number" min="0" id="localTriplet"/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="general-limit" role="tabpanel" aria-labelledby="general-limit-tab">
                          <ul className="nav nav-tabs mt-3" id="gralLimitsTabs" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="normal-gral-limits-tab" data-bs-toggle="tab" data-bs-target="#normal-gral-limits" type="button" role="tab" aria-controls="normal-gral-limits" aria-selected="true">Normal</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="bolita-gral-limits-tab" data-bs-toggle="tab" data-bs-target="#bolita-gral-limits" type="button" role="tab" aria-controls="bolita-gral-limits" aria-selected="false">Bolita</button>
                            </li>
                          </ul>
                          <div className="tab-content" id="gralLimitsTabsContent">
                            <div className="tab-pane fade show active" id="normal-gral-limits" role="tabpanel" aria-labelledby="normal-gral-limits-tab">
                              <div className="row mt-3">
                                <div className="col-12 col-lg-4 mb-2">
                                      <div className="table-responsive-lg">
                                        <table className="table table-sm table-bordered table-striped table-hovered">
                                          <thead>
                                            <tr>
                                              <th></th>
                                              <th>Loterías</th>
                                            </tr>
                                          </thead>
                                          <tbody id="tableLimitGral">
                                          </tbody>
                                        </table>
                                      </div>
                                </div>
                                <div id="generalLimitsRD" className="col-12 col-lg-8 mb-2">
                                  <div className="row">
                                    <div className="col-4 mb-2 d-flex flex-column">
                                      <label htmlFor="generalLimitDirect">Directo</label>
                                      <input type="number" min="0" id="generalLimitDirect"/>
                                    </div>
                                    <div className="col-4 mb-2 d-flex flex-column">
                                      <label htmlFor="generalLimitPale">Pale</label>
                                      <input type="number" min="0" id="generalLimitPale"/>
                                    </div>
                                    <div className="col-4 mb-2 d-flex flex-column">
                                      <label htmlFor="generalLimitTriplet">Tripleta</label>
                                      <input type="number" min="0" id="generalLimitTriplet"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="bolita-gral-limits" role="tabpanel" aria-labelledby="bolita-gral-limits-tab">
                              <div className="row mt-3">
                                <div className="col-12 col-lg-4 mb-2">
                                      <div className="table-responsive-lg">
                                        <table className="table table-sm table-bordered table-striped table-hovered">
                                          <thead>
                                            <tr>
                                              <th></th>
                                              <th>Loterías</th>
                                            </tr>
                                          </thead>
                                          <tbody id="tableLimitGralUS">
                                          </tbody>
                                        </table>
                                      </div>
                                </div>
                                <div id="generalLimitsUS" className="col-12 col-lg-8 mb-2">
                                  <div className="row">
                                    <div className="col-4 mb-2 d-flex flex-column">
                                      <label htmlFor="generalLimitPick3">Pick 3</label>
                                      <input type="number" min="0" id="generalLimitPick3"/>
                                    </div>
                                    <div className="col-4 mb-2 d-flex flex-column">
                                      <label htmlFor="generalLimitPick4">Pick 4</label>
                                      <input type="number" min="0" id="generalLimitPick4"/>
                                    </div>
                                    <div className="col-4 mb-2 d-flex flex-column">
                                      <label htmlFor="generalLimitVeneD">Vene D</label>
                                      <input type="number" min="0" id="generalLimitVeneD"/>
                                    </div>
                                    <div className="col-4 mb-2 d-flex flex-column">
                                      <label htmlFor="generalLimitVeneP">Vene P</label>
                                      <input type="number" min="0" id="generalLimitVeneP"/>
                                    </div>
                                    <div className="col-4 mb-2 d-flex flex-column">
                                      <label htmlFor="generalLimitVeneT">Vene T</label>
                                      <input type="number" min="0" id="generalLimitVeneT"/>
                                    </div>
                                    <div className="col-4 mb-2 d-flex flex-column">
                                      <label htmlFor="generalLimitPulito">Pulito</label>
                                      <input type="number" min="0" id="generalLimitPulito"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>

                        <div className="tab-pane fade" id="number-control" role="tabpanel" aria-labelledby="number-control-tab">
                          <div className="row mt-3">
                            <div className="col-12 col-lg-5 mb-2">
                              <div className="row">
                                <div className="col-12 mb-2">
                                  <label htmlFor="lotteryNumberControl">Lotería</label>
                                  <select id="lotteryNumberControl"></select>
                                </div>
                                <div className="col-12 mb-2 d-flex align-items-center">
                                  <input className="me-2" id="numberControlInput" type="number" min="0"/>
                                  <input className="me-2" id="numberControlInput2" type="number" min="0"/>
                                  <input type="checkbox" id="localCheckNumberControl"/>
                                  <label htmlFor="localCheckNumberControl">Local</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-7 mb-2">
                              <div className="table-responsive-lg">
                                <table className="table table-sm table-bordered table-striped table-hovered">
                                  <thead>
                                    <tr>
                                      <th>Tipo</th>
                                      <th>Combinación</th>
                                      <th>Cantidad</th>
                                      <th>Local</th>
                                    </tr>
                                  </thead>
                                  <tbody id="tableNumberControl"></tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="abonados" role="tabpanel" aria-labelledby="abonados-tab">
                          <div className="row mt-3">
                            <div className="col-12 col-xl-4 mb-2">
                              <div className="row">
                                <div className="col-12 mb-2 d-flex">
                                  <label htmlFor="addTicket">Ticket#</label>
                                  <input type="text" id="addTicket"/>
                                </div>
                                <div className="col-12 mb-2 d-flex justify-content-between">
                                  <button type="button">Agregar</button>
                                  <button type="button">Eliminar</button>
                                </div>
                                <div className="col-12 mb-2">
                                  <div className="table-responsive-lg">
                                    <table className="table table-sm table-bordered table-striped table-hovered">
                                      <thead>
                                        <tr>
                                          <th># Ticket</th>
                                          <th>Lotería</th>
                                          <th># Jugada</th>
                                          <th>Cantidad</th>
                                        </tr>
                                      </thead>
                                      <tbody id="addTicketTable">
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-xl-4 mb-2">
                              <div className="row">
                                <div className="col-12 mb-2">
                                  <label htmlFor="lotteryAbonados">Lotería</label>
                                  <select id="lotteryAbonados"></select>
                                </div>
                                <div className="col-12 mb-2">
                                  <label htmlFor="timeAbonados">Hora Final</label>
                                  <input type="time" id="timeAbonados"/>
                                </div>
                                <div className="col-12 mb-2">
                                  <table className="table table-sm table-bordered table-striped table-hovered">
                                    <thead>
                                      <tr>
                                        <th>Tickets Abonados</th>
                                      </tr>
                                    </thead>
                                    <tbody id="tbodyTicketsAbonados">
                                      <tr>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-xl-4 mb-2">
                              <div className="row">
                                <div className="col-12 mb-2 d-flex align-items-center justify-content-between">
                                  <input className="me-2" id="abonadoInput1" type="number" min="0"/>
                                  <input className="me-2" id="abonadoInput2" type="number" min="0"/>
                                </div>
                                <div className="col-12 mb-2">
                                  <div className="table-responsive-lg">
                                    <table className="table table-sm table-bordered table-striped table-hovered">
                                      <thead>
                                        <tr>
                                          <th>Tipo</th>
                                          <th>Combinación</th>
                                          <th>Cantidad</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody id="tableAbonados">
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="rojo" role="tabpanel" aria-labelledby="rojo-tab">
                          <div className="row mt-3 d-flex justify-content-center">
                            <div className="col-12 col-lg-8">
                              <div className="row">
                                <div className="col-12 col-lg-6 mb-2">
                                  <label htmlFor="initialRojo">Rojo Inicial</label>
                                  <input type="number" min="0" id="initialRojo"/>
                                </div>
                                <div className="col-12 col-lg-6 mb-2">
                                  <label htmlFor="adjustRojo">Ajuste Rojo</label>
                                  <input type="number" min="0" id="adjustRojo"/>
                                </div>
                                <div className="col-12 col-lg-6 mb-2">
                                  <label>Borrar Rojo</label>
                                </div>
                                <div className="col-12 col-lg-6 mb-2">
                                  <button type="button">Borrar</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="prestamos" role="tabpanel" aria-labelledby="prestamos-tab">
                          <div className="row mt-3">
                            <div className="col-12 col-lg-6 mb-2">
                              <label htmlFor="principalPrestamo">Principal</label>
                              <input type="number" min="0" id="principalPrestamo"/>
                            </div>
                            <div className="col-12 col-lg-6 mb-2">
                              <label htmlFor="montoCuotasPrestamo">Monto de las Cuotas</label>
                              <input disabled type="number" min="0" id="montoCuotasPrestamo"/>
                            </div>
                            <div className="col-12 col-lg-6 mb-2">
                              <label htmlFor="frecuenciaPrestamo">Frecuencia</label>
                              <select id="frecuenciaPrestamo">
                                <option value="manual">Manual</option>
                                <option value="semanal">Semanal</option>
                                <option value="quincenal">Quincenal</option>
                                <option value="mensual">Mensual</option>
                              </select>
                            </div>
                            <div className="col-12 col-lg-6 mb-2">
                              <label htmlFor="diaCobroPrestamo">Día de Cobro</label>
                              <select disabled id="diaCobroPrestamo">
                                <option value=""></option>
                              </select>
                            </div>
                            <div className="col-12 mt-4 mb-2 d-flex justify-content-between">
                              <button className="me-2" type="button">Agregar Préstamo</button>
                              <button className="me-2" type="button">Agregar Préstamo Negativo</button>
                              <button className="me-2" type="button">Agregar Transacción Automática</button>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="table-responsive-lg">
                              <table className="table table-sm table-bordered table-striped">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Principal</th>
                                    <th>Monto de las Cuotas</th>
                                    <th>Status</th>
                                    <th>Deuda</th>
                                    <th>Frecuencia</th>
                                    <th>Dia Cobro</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody id="tablePrestamos">
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
                          <div className="row mt-3">
                            <div className="col-12 mb-2 bg-card" style={{minHeight: '250px'}}></div>
                            <div className="col-12 mb-2 d-flex justify-content-center">
                              <button className="mx-2" type="button">Crear</button>
                              <button className="mx-2" type="button">Eliminar</button>
                              <button className="mx-2" type="button">Desactivar</button>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                
              </Container>
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <button type="button" className="btn btn-ok mb-2">Salvar</button>
              <button type="button" className="btn btn-cancelar mb-2" data-bs-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
      </form>
  )
}
