
import moment from 'moment';

const Sales_t4_mobile = () => {

  return (
    <div className='menu_container'>
      <div className='top_container'>
        <div>
          <label htmlFor="date">Select a Date</label>
          <input type="date" name="" id="" defaultValue={moment().format("YYYY-MM-DD")} />
          <button type="button">
            search
          </button>
        </div>
      </div>
      <div className='bottom_container'>
        <div className='tables_container'>
          <table>
            <thead>
              <tr>
                <th colSpan={2}>
                  <div>
                    <span>
                      sales summary
                    </span>
                    <i className="fa fa-print" aria-hidden="true"></i>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  balance per day:
                </td>
                <td>
                  $ 100.00
                </td>
              </tr>
              <tr>
                <td>
                  owner:
                </td>
                <td>
                  {

                  }
                </td>
              </tr>
              <tr>
                <td>
                  username
                </td>
                <td>
                  {

                  }
                </td>
              </tr>
              <tr>
                <td>
                  pending payment
                </td>
                <td>
                  $ 0.00
                </td>
              </tr>
              <tr>
                <td>
                  pending tickets:
                </td>
                <td>
                  0
                </td>
              </tr>
              <tr>
                <td>
                  loser tickets:
                </td>
                <td>
                  0
                </td>
              </tr>
              <tr>
                <td>
                  winner tickets:
                </td>
                <td>
                  0
                </td>
              </tr>
              <tr>
                <td>
                  total tickets:
                </td>
                <td>
                  0
                </td>
              </tr>
              <tr>
                <td>
                  total sales:
                </td>
                <td>
                  $ 0.00
                </td>
              </tr>
              <tr>
                <td>
                  deposits:
                </td>
                <td>
                  $ 0.00
                </td>
              </tr>
              <tr>
                <td>
                  total prizes:
                </td>
                <td>
                  $ 0.00
                </td>
              </tr>
              <tr>
                <td>
                  withdrawals:
                </td>
                <td>
                  $ 0.00
                </td>
              </tr>
              <tr>
                <td>
                  credits:
                </td>
                <td>
                  $ 0.00
                </td>
              </tr>
              <tr>
                <td>
                  bonus:
                </td>
                <td>
                  $ 0.00
                </td>
              </tr>
              <tr>
                <td>
                  balance payments:
                </td>
                <td>
                  $ 0.00
                </td>
              </tr>
              <tr>
                <td>
                  cash payments:
                </td>
                <td>
                  $ 0.00
                </td>
              </tr>
              <tr>
                <th>
                  profits:
                </th>
                <th>
                  $ 0.00
                </th>
              </tr>
              <tr>
                <td>
                  actual balance:
                </td>
                <td>
                  $ 100.00
                </td>
              </tr>
              <tr>
                <td>
                  balance final:
                </td>
                <td>
                  $ 100.00
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th colSpan={4}>
                  <div>
                    <span>
                      winner tickets
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <td>
                  ID
                </td>
                <td>
                  BET
                </td>
                <td>
                  PRIZE
                </td>
                <td>
                  STATUS
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4}>
                  <div className="alert alert-danger" role="alert" style={{ textAlign: 'center' }}>
                    No records where found..
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th colSpan={5}>
                  <div>
                    <span>
                      web tickets winners
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <td>
                  ID
                </td>
                <td>
                  username
                </td>
                <td>
                  BET
                </td>
                <td>
                  PRIZE
                </td>
                <td>
                  STATUS
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={5}>
                  <div className="alert alert-danger" role="alert" style={{ textAlign: 'center' }}>
                    No records where found..
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Sales_t4_mobile