import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment-timezone';
import { redApi } from '../../../config/Axios';
import Cookies from "universal-cookie";
const cookie = new Cookies();


export default function LeftContainer({ setBetToAdd, handleChangeBetToAdd, betToAdd, bets, setBets, totalToWin, setTotalToWin, totalToWinIF, setTotalToWinIF, handleBetPost }) {

    const [todayAndTime, setTodayAndTime] = useState(moment.tz('America/New_York').format('YYYY-MM-DD hh:mm A'));

    useEffect(() => {
        const interval = setInterval(() => {
            let mommentResult = moment.tz('America/New_York').format('YYYY-MM-DD hh:mm A');
            setTodayAndTime(mommentResult);
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    function getToWin(bets, amount) {
        let result = 0;
        for (let i = 0; i < bets.length; i++) {

            if (bets[i].price.toString().includes('+') && bets[i].price.toString().includes('-')) {
            }
            const multiplier = bets[i].price >= 0
                ? bets[i].price / 100
                : 1 / ((bets[i].price * -1) / 100);
            result = Math.round((result + amount) * multiplier + result);
        }
        result = Math.round(result);
        return result
    }

    // let ifWinContainer = document.querySelector('#ifWinContainer');
    // let newInput = document.createElement('input');
    // newInput.type = 'number';
    // newInput.className = 'form-control col';
    // newInput.name = 'IfWinQuantity';
    // ifWinContainer.appendChild(newInput);
    // newInput.focus();

    useEffect(() => {
        if (bets.bets?.length > 0) {
            setIfWinQuantity(null);
            let IfWinQuantity = document.querySelector('#IfWinQuantity');
            if (IfWinQuantity) {
                IfWinQuantity.remove();
            }
        }
    }, [bets]);
    const [mobile, setMobile] = useState(window.innerWidth <= 768)
    useEffect(() => {
        window.addEventListener('resize', () => {
            setMobile(window.innerWidth <= 768)
        })
    }, [])
    const [IfWinQuantity, setIfWinQuantity] = useState(null);
    const handleEnterKey = useCallback((e) => {
        if (e.key === 'Enter' && bets.bets.length > 0) {
            let ifWinInput = document.querySelector('#ifWinInput');
            if (parseInt(ifWinInput.value) > 0) {
                let ifWinContainer = document.querySelector('#ifWinContainer');
                console.log({ ifWinContainer });
                if (ifWinContainer.children.length === 1) {
                    let newInput = document.createElement('input');
                    newInput.type = 'number';
                    newInput.className = 'form-control col';
                    newInput.name = 'IfWinQuantity';
                    newInput.id = 'IfWinQuantity';
                    ifWinContainer.appendChild(newInput);
                    newInput.focus();
                    setIfWinQuantity(newInput);
                    return;
                } else {
                    document.querySelector('#IfWinQuantity').focus();
                    return;
                }
            }
            let quantityInput = document.querySelector('#quantityInput');
            let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
            if (parseInt(ammountBet) > 0) {
                let betsTowin = bets.bets.map((bet) => {
                    let price = bet.Price.toString().slice(-4);
                    return {
                        price: price
                    }
                })

                ifWinInput.disabled = true;
                quantityInput.disabled = true;

                let totalToWin = getToWin(betsTowin, parseInt(ammountBet));
                setTotalToWin(totalToWin);
            }

            setBets({
                ...bets,
                ammount: parseInt(ammountBet),
                readyToSubmit: true
            })
            setBetToAdd({
                TeamCode: '',
                PlayType: '',
                Quantity: 0,
                IfWin: 0
            })

        }
    }, [bets])

    const handleIfWinChange = (e) => {
        if (e.key === 'Enter') {
            e.target.disabled = true;
            let ifWinInput = document.querySelector('#ifWinInput');
            // if (!parseInt(ifWinInput.value) > 0) return;
            let quantityInput = document.querySelector('#quantityInput');
            let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
            if (parseInt(ammountBet) > 0) {
                let betsTowin = bets.bets.map((bet) => {
                    let price = bet.Price.toString().slice(-4);
                    return {
                        price: price
                    }
                })

                ifWinInput.disabled = true;
                quantityInput.disabled = true;

                let totalToWin = getToWin(betsTowin.slice(0, e.target.value), parseInt(ammountBet));
                // console.log({ totalToWin });
                let totalToWiniF = getToWin(betsTowin.slice(e.target.value, betsTowin.length), parseInt(ifWinInput.value));
                // console.log({ totalToWiniF });
                let toCollect = totalToWin + totalToWiniF;
                setTotalToWin(totalToWin);
                setTotalToWinIF(totalToWiniF);
            }

            setBets({
                ...bets,
                ammount: parseInt(ammountBet),
                betsIf: parseInt(e.target.value),
                IfWinQuantity: parseInt(ifWinInput.value),
                readyToSubmit: true
            })

            console.log({
                ...bets,
                ammount: parseInt(ammountBet),
                IfWin: parseInt(e.target.value),
                IfWinQuantity: parseInt(ifWinInput.value)
            });

            setBetToAdd({
                TeamCode: '',
                PlayType: '',
                Quantity: 0,
                IfWin: 0
            })
            e.target.value = '';

        }
    }

    const handleIfWinblur = (e) => {
        // when blur and bets length is bigger than 0 set in bets 
        if (e.target.value && bets.bets.length > 0) {
            setBets({
                ...bets,
                readyToSubmit: true
            })
        }
    }
    useEffect(() => {
        let IfWinQuantity = document.querySelector('#IfWinQuantity');
        if (IfWinQuantity) {
            IfWinQuantity.addEventListener('keydown', handleIfWinChange);
            // un focus event listener
            IfWinQuantity.addEventListener('blur', handleIfWinblur);
            return () => {
                IfWinQuantity.removeEventListener('keydown', handleIfWinChange);
                IfWinQuantity.removeEventListener('blur', handleIfWinChange);
            }
        }
    }, [IfWinQuantity])

    const handleUnFocusTeamCode = (e) => {
        // if(
        // reset all inputs
        let ifWinInput = document.querySelector('#ifWinInput');
        let quantityInput = document.querySelector('#quantityInput');
        let IfWinQuantity = document.querySelector('#IfWinQuantity');
        if (IfWinQuantity) {
            IfWinQuantity.remove();
        }
        if (ifWinInput) {
            ifWinInput.disabled = false;
            ifWinInput.value = '';
        }
        if (quantityInput) {
            quantityInput.disabled = false;
            quantityInput.value = '';
        }
        setTotalToWin(0);
        setTotalToWinIF(0);
        // set bets to empty only save the bets.bets array
        let betsToSave = bets.bets;
        setBets({
            ...bets,
            bets: betsToSave,
            betsIf: 0,
            ammount: 0,
            IfWinQuantity: 0,
            readyToSubmit: false
        })
    }
    useEffect(() => {
        if (bets.bets?.length > 0) {
            let teamCode = document.querySelector('#TeamCode');
            teamCode.addEventListener('focus', handleUnFocusTeamCode);

            return () => {
                teamCode.removeEventListener('focus', handleUnFocusTeamCode);
            }
        }
    }, [bets])

    useEffect(() => {
        let ifWinInput = document.querySelector('#ifWinInput');
        ifWinInput.addEventListener('keydown', handleEnterKey);

        return () => {
            ifWinInput.removeEventListener('keydown', handleEnterKey);
        }
    }, [bets])

    useEffect(() => {
        let quantityInput = document.querySelector('#quantityInput');
        quantityInput.addEventListener('keydown', handleQuantityEnterKey);

        return () => {
            quantityInput.removeEventListener('keydown', handleQuantityEnterKey);
        }
    }, []);

    const handleFocusTeamCode = (e) => {
        if (e.key === 'Enter' && bets.bets.length === 0 && e.target.id !== 'TeamCode') {
            document.querySelector('#TeamCode').focus();
            document.querySelector('#TeamCode').select();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleFocusTeamCode);

        return () => {
            document.removeEventListener('keydown', handleFocusTeamCode);
        }

    }, [bets])

    const handleQuantityEnterKey = (e) => {
        if (e.key === 'Enter') {
            document.querySelector('#ifWinInput').focus();
            document.querySelector('#ifWinInput').select();
        }
    }

    const handleSpaceKey = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
            if (bets.bets.length > 0) {
                document.querySelector('#quantityInput').focus();
                document.querySelector('#quantityInput').select();
            } else {
                document.querySelector('#TeamCode').focus();
                document.querySelector('#TeamCode').select();
            }
        }
    }

    const handleEnterCodeTeam = async (e) => {
        // console.log("EEEEEEEEEE", e)
        if (e.target.value == '' && e.key === 'Enter') {
            e.preventDefault();
            // console.log("PASANDOOO")
            document.querySelector('#quantityInput').focus();
            document.querySelector('#quantityInput').select();
        }
    }

    const handleLogin = async (e) => {
        await redApi.get(`/users/login/as/${e.target.value}`).then((res) => {
            if (!cookie.get("loginAs")) {
                cookie.set("loginAs", res.data.token, { path: "/" });
                window.location.href = "/client";
                return;
            }
            cookie.remove("loginAs", { path: "/" });
            cookie.set("loginAs", res.data.token, { path: "/" });
            // window.location.href = "/client";
        });
    }

    useEffect(() => {
        document.addEventListener('keydown', handleSpaceKey);

        return () => {
            document.removeEventListener('keydown', handleSpaceKey);
        }
    }, [bets])




    const getCurrentUser = async () => {
        await redApi.get(`/verify`)
            .then(res => {
                setTerminalLogged(res.data)
            })
            .catch(err => console.log(err));
    }
    const [terminalLogged, setTerminalLogged] = useState({})
    const [terminalsToLogin, setTerminalsToLogin] = useState([]);
    const getTerminals = async () => {
        if (terminalLogged && terminalLogged.userLevel < 3) {
            await redApi.get('/users/getterminalsofconsortium')
                .then(res => {
                    setTerminalsToLogin(res.data);
                }).catch(err => console.log(err));
        }
    }
    useEffect(() => {
        getCurrentUser();
    }, [])
    useEffect(() => {
        getTerminals().catch(err => console.log(err));;
    }, [terminalLogged])
    return (
        // <div className="h-100 d-flex flex-column justify-content-start align-items-center">
        <div className="bg-card" style={{
            minHeight: '220px',
        }}>
            <div className='ticket-top position-relative'>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <input type="date" disabled value={new Date().toISOString().slice(0, 10)} className="text-center form-control" />
                    </div>
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <select disabled={terminalLogged?.userLevel < 3 ? false : true} className="form-select" onChange={handleLogin}>
                            {
                                terminalLogged?.userLevel < 3
                                    ? terminalsToLogin.map((item, index) => (
                                        <option key={index} value={item._id}>{item.username}</option>
                                    ))
                                    : (<option value={terminalLogged?._id}>{terminalLogged?.username}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <p className="text-center mb-1">Code</p>
                        <input type="number" className="form-control" name='TeamCode' id='TeamCode' value={betToAdd.TeamCode} onChange={handleChangeBetToAdd} onKeyDown={handleEnterCodeTeam} />
                    </div>
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <p className="text-center mb-1">Play type</p>
                        <input type="text" className="form-control" name='PlayType' value={betToAdd.PlayType} onChange={handleChangeBetToAdd} id="betTypeSelect" />
                        {/* <select className="form-select betType" name='PlayType' value={betToAdd.PlayType} onChange={handleChangeBetToAdd} id="betTypeSelect">
                                <option value="" disabled></option>
                                {
                                    betType.map((item, index) => {
                                        return (
                                            <option key={index} value={item}>{item}</option>
                                        )
                                    })
                                }
                            </select> */}
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <p className="text-center mb-1">Quantity</p>
                        <input type="number" className="form-control" name='Quantity' value={betToAdd.Quantity} onChange={handleChangeBetToAdd} id="quantityInput" />
                    </div>
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <p className="text-center mb-1">IF</p>
                        <div id='ifWinContainer' className="col d-flex flex-row justify-content-center align-items-center gap-2">
                            <input type="number" className="form-control col" name='IfWin' value={betToAdd.IfWin} onChange={handleChangeBetToAdd} id="ifWinInput" />
                        </div>
                    </div>

                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-column justify-content-center align-items-center">
                        <button className='btn btn-danger my-2' id='postbetmobile'
                            style={{
                                display: "none"
                            }}
                            onClick={() => {
                                console.log("POST BET");
                                handleBetPost("Print&Send");
                            }}
                        >
                            POST BET
                        </button>
                    </div>
                </div>
                {/* <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2 my-1">
                        <div className="col d-flex flex-row justify-content-center align-items-center">
                            <input type="checkbox" className="form-check-input" />
                        </div>
                        <div className="col d-flex flex-row justify-content-start align-items-center">
                            <span className="text-left">Client´s Name</span>
                        </div>
                    </div> */}
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <span className="text-center mb-1">
                            Server Date/Time:
                            {'  ' + todayAndTime}
                        </span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">Closing Time</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <input type="time" className="form-control" value={new Date().toLocaleTimeString()} disabled />
                    </div>
                </div>
                {/* <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                        <div className="col d-flex flex-row justify-content-center align-items-center">
                            <p className="text-center mb-1">User:</p>
                        </div>
                        <div className="col d-flex flex-row justify-content-start align-items-center">
                            <span className="text-left">Admin</span>
                        </div>
                    </div> */}
                <div className="border-bottom-ticket"></div>
            </div>
            <div className="ticket-bottom">

                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">Ticket Total</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <span className="text-left">$ {bets.ammount > 0 ? bets.ammount : ''}</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">Ticket Total</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <span className="text-left">$ {bets.ammount > 0 ? bets.ammount : ''}</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">To win</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <span className="text-left">${totalToWin > 0 ? totalToWin : 0.00}</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">To win IF</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <span className="text-left">$ {totalToWinIF > 0 ? totalToWinIF : 0.00}</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                    <div className="col d-flex flex-row justify-content-center align-items-center">
                        <p className="text-center mb-1">To Collect</p>
                    </div>
                    <div className="col d-flex flex-row justify-content-start align-items-center">
                        <span className="text-left">$ {totalToWin > 0 ? totalToWin + bets.ammount : 0.00}</span>
                    </div>
                </div>
            </div>
        </div>
        // </div>
    )
}