import React, {useState, useEffect} from 'react'
import Swal from 'sweetalert2';
import { redApi } from '../../../../config/Axios';

export const ManageContacts = ({getContacts, close, contact}) => {

    const [bodyForm, setBodyForm] = useState({name: "", phone: ""})

    const handlerOnChange = (e) => {
        setBodyForm({...bodyForm, [e.target.name]: e.target.value});
    }

    const finishOperation = () => {
        setBodyForm({name: "", phone: ""})
        getContacts();
        close();
    }
    const handlerSubmit = async (e) => {
        e.preventDefault();
        Swal.showLoading()
        if (contact.action === "create") {
            try {
                await redApi.post("/whatsapp/contacts", bodyForm);
                Swal.fire({
                    icon: "success",
                    title: "Contact Saved!",
                    timer: 1500,
                    showConfirmButton: false
                })
                finishOperation();
            } catch (error) {
                console.log(error)
                Swal.fire({
                    icon: "error",
                    title: error.response.data.message,
                    timer: 1500,
                    showConfirmButton: false
                })
            }
        } else if (contact.action === "edit") {
            try {
                await redApi.patch(`/whatsapp/contacts/${contact.contact.phone}`, {
                    newName: bodyForm.name,
                    newPhone: bodyForm.phone
                });
                Swal.fire({
                    icon: "success",
                    title: "Contact Saved!",
                    timer: 1500,
                    showConfirmButton: false
                })
                finishOperation();
            } catch (error) {
                console.log(error)
                Swal.fire({
                    icon: "error",
                    title: error.response.data.message,
                    timer: 1500,
                    showConfirmButton: false
                })
            }
        }
    }

    useEffect(() => {
      if (contact.contact) {
        setBodyForm({name: contact.contact.name, phone: contact.contact.phone})
      } else {
        setBodyForm({name: "", phone: ""})
      }
    
    }, [contact])
    
    return (
        <div className="container-fluid p-0 m-0 form-contacts" style={{minHeight: "50vh"}}>
            <form onSubmit={handlerSubmit}>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <span className="my-2">{contact.action === "create" ? "Crear" : "Editar"} contacto</span>
                        {
                            contact.action === "create" 
                            ? (
                                <div className="form-group">
                                    <label htmlFor="contactName">Nombre</label>
                                    <input value={bodyForm.name} onChange={handlerOnChange} name="name" type="text" className="form-control" placeholder="Nombre" required/>
                                </div>
                            ) 
                            : (
                                <>
                                    <div className="form-group">
                                        <label htmlFor="contactName">Nombre</label>
                                        <input type="text" readOnly className="form-control" placeholder="Nombre" value={contact.contact.name}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="contactName">Nuevo nombre</label>
                                        <input value={bodyForm.name} onChange={handlerOnChange} name="name" type="text" className="form-control" placeholder="Nuevo Nombre" required/>
                                    </div>
                                </>
                            )
                        }
                        <div className="form-group">
                            <label htmlFor="contactPhone">Número</label>
                            <input minLength={10} maxLength={10} value={bodyForm.phone} type="tel" className="form-control" onChange={handlerOnChange} name="phone" placeholder="(123)4567890"/>
                        </div>
                        <div className='d-flex justify-content-between mt-3'>
                            <button className="btn p-5" onClick={close}>Volver</button>
                            <button className="btn p-5" type="submit">
                                {contact.action === "create" ? "Crear" : "Editar"}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
  )
}
