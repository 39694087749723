import React, { useEffect } from 'react'

const KeyboardVrtual = ({ menuActive }) => {
  let keyboard = ['7', '8', '9', '4', '5', '6', '1', '2', '3', '<i class="fa fa-arrow-left" aria-hidden="true"></i>', '0', 'OK']


  const handleKeyboard = (key) => {
    switch (menuActive) {
      case 'Deposit':
      case 'Withdrawals':
        let inputAmount = document.getElementById('inputAmount')
        if (key === '<i class="fa fa-arrow-left" aria-hidden="true"></i>') {
          inputAmount.value = inputAmount.value.slice(0, -1)
        }
        else if (key === 'OK') {
          if (inputAmount.value === '') {
            return
          }
          else {
            // handleBetPost()
          }
        }
        else {
          inputAmount.value += key
        }
    }
  }


  return (
    <div className='keyboard'>
      {keyboard.map((key, index) => (
        <button key={index} className={key === 'OK' ? 'btn-keyboard btn-keyboard-ok' : key === '<i class="fa fa-arrow-left" aria-hidden="true"></i>' ? 'btn-keyboard btn-keyboard-back' : 'btn-keyboard'} onClick={() => handleKeyboard(key)}>
          <span dangerouslySetInnerHTML={{ __html: key }}></span>
        </button>
      ))}
    </div>
  )
}

export default KeyboardVrtual