import React from 'react'

const QuickBet = () => {
  return (
    <>
      <div className='d-flex justify-content-center gap-4'>
        <div className='d-flex flex-column'>
          <label htmlFor="Sport">Sport:</label>
          <select name="Sport" id="Sport">
            <option value="0">All</option>
            <option value="1">Football</option>
            <option value="2">Basketball</option>
            <option value="3">Baseball</option>
            <option value="4">Hockey</option>
            <option value="5">Soccer</option>
            <option value="6">Tennis</option>
          </select>
        </div>
        <div className='d-flex flex-column'>
          <label htmlFor="League">League:</label>
          <select name="League" id="League">
            <option value="0">All</option>
          </select>
        </div>
        <div className='d-flex flex-column'>
          <label htmlFor="Picks"># Picks:</label>
          <input type="text" style={{ width: '300px' }} />
        </div>
        <div className='d-flex flex-column'>
          <label htmlFor="Amount">Amount:</label>
          <input type="text" style={{ width: '300px' }} />
        </div>
      </div>
      <div className='d-flex justify-content-center mt-4'>
        <button className='btn btn-primary'>Generate</button>
      </div>
    </>
  )
}

export default QuickBet