import React, { useState, useEffect, useContext } from 'react'
// import { AuthContext } from '../../../../../auth';

const General = ({ setCreateConsorcios, createConsorcios, modalEditar, userLevel, setEditConsorcio, editConsorcio }) => {
    // const { userLevel: lvl } = useContext(AuthContext);
    const handleInputs = (e) => {
        if (modalEditar) {
            setEditConsorcio({
                ...editConsorcio,
                [e.target.name]: e.target.value

            })
        }
        if (e.target.name === 'maxClients' && modalEditar && userLevel === 2) {
            return
        }
        setCreateConsorcios({
            ...createConsorcios,
            [e.target.name]: e.target.value

        })
        if (e.target.name === 'tieAllowed') {
            if (modalEditar) {
                setEditConsorcio({
                    ...editConsorcio,
                    [e.target.name]: e.target.checked
                })
            }
            setCreateConsorcios({
                ...createConsorcios,

                [e.target.name]: e.target.checked
            })
        }
    }

    const handleLanguage = (e) => {
        if (modalEditar) {
            setEditConsorcio({
                ...editConsorcio,
                language: e.target.value
            })
        }
        setCreateConsorcios({
            ...createConsorcios,
            language: e.target.value
        })
    }

    const handleCodes = (e) => {
        if (modalEditar) {
            setEditConsorcio({
                ...editConsorcio,
                codesToUse: e.target.value
            })
        }
        setCreateConsorcios({
            ...createConsorcios,
            codesToUse: e.target.value
        })
    }

    const [language, setLanguage] = useState(true)

    const checkLanguage = (e) => {
        if (e.target.checked) {
            if (modalEditar) {
                setEditConsorcio({
                    ...editConsorcio,
                    language: 'es'
                })
            }
            setLanguage(true)
            setCreateConsorcios({
                ...createConsorcios,
                language: e.target.value
            })
        } else {
            setLanguage(false)
        }
    }
    useEffect(() => {
        console.log({ userLevel })
    }, [userLevel])


    return (
        <div className="row p-0 m-0" id='ConsorcioTgeneral'>
            <div className="col-12 p-0 m-0">
                <ul className="nav gap-1 d-flex flex-column w-100">
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Nombre:</label>
                        <input type="text" className="form-control" name='username' onChange={handleInputs} value={createConsorcios.username} />
                    </li>
                    {
                        modalEditar && userLevel !== 1 ? null :
                            <li className="nav-item d-flex align-items-center justify-content-center w-100">
                                <label className='labelGeneral'>Password:</label>
                                <input type="password" className="form-control" name='password' onChange={handleInputs} value={createConsorcios.password} />
                            </li>
                    }
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Descripcion:</label>
                        <input type="text" className="form-control" name='description' onChange={handleInputs} value={createConsorcios.description} />
                    </li>
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Hora de Juego:</label>
                        <div className='d-flex gap-2'>
                            <input type="time" className="form-control" name='playTime' onChange={handleInputs} value={createConsorcios.playTime} />
                            <input type="time" className="form-control" name='playTime2' onChange={handleInputs} value={createConsorcios.playTime2} />
                        </div>
                    </li>
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Minutos Anulacion:</label>
                        <input type="number" className="form-control" value={createConsorcios.cancellTicket} name='cancellTicket' onChange={handleInputs} />
                    </li>
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Empate IF:</label>
                        <div className='d-flex'>
                            <input type="checkbox" className="form-check-input" defaultChecked={createConsorcios.tieAllowed} name='tieAllowed' onChange={handleInputs} />
                        </div>
                    </li>
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Precio de jugadas multiples:</label>
                        <input type="number" className="form-control" value={createConsorcios.multPlays} name='multPlays' onChange={handleInputs} />
                    </li>
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Limites de terminales:</label>
                        <input type="number" className="form-control" value={createConsorcios.maxClients} name='maxClients' onChange={handleInputs} readOnly={modalEditar && userLevel === 2} disabled={modalEditar && userLevel === 2}
                        />
                    </li>
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Minimo de venta:</label>
                        <input type="number" className="form-control" value={createConsorcios.minSell} name='minSell' onChange={handleInputs} />
                    </li>
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Limite de Hembras:</label>
                        <input type="number" className="form-control" value={createConsorcios.hembraLimit} name='hembraLimit' onChange={handleInputs} />
                    </li>
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Devoluciones:</label>
                        <div className='w-100 mx-4'>
                            <select className="form-control" aria-label="Default select example" value={createConsorcios.devolutions} name='devolutions' onChange={handleInputs}>
                                <option value={true}>Desactivado</option>
                                <option value={false}>Activado</option>
                            </select>
                        </div>
                    </li>
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Idioma</label>
                        <div className='d-flex'>
                            <input type="checkbox" className="form-check-input" name='language' onChange={checkLanguage} checked />
                        </div>
                    </li>
                    {
                        language ?
                            <li className="nav-item d-flex align-items-center justify-content-center w-100">
                                <label className='labelGeneral'>Idioma:</label>
                                <div className='w-100 mx-4'>
                                    <select className="form-select" aria-label="Default select example" value={createConsorcios.language} name='language' onChange={handleLanguage}>
                                        <option value="es" selected>Español</option>
                                        <option value="en">Ingles</option>
                                    </select>
                                </div>
                            </li>
                            :
                            null

                    }
                    <li className="nav-item d-flex align-items-center justify-content-center w-100">
                        <label className='labelGeneral'>Codigos:</label>
                        <div className='w-100 mx-4'>
                            <select className="form-select" aria-label="Default select example" value={createConsorcios.codesToUse} name='language' onChange={handleCodes}>
                                <option value="Legacy" selected>Legacy</option>
                                <option value="LaCentralRD">LaCentralRD</option>
                            </select>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default General