import React, { useState, useEffect, useContext, useRef } from 'react'
import moment from 'moment';
import { redApi } from '../../../../config/Axios';
import { getToday } from '../../../../utils/dateToday';
import { getRedValueClassName } from '../../../../utils/returnClassNames';
import { ReportsContext } from './context/ReportesContext';

// let consorcio = ['Todos', 'MNB', 'AAREN SPORT', 'CIBAO SPORT', 'BANCA JETS PRO'];
// let POS = ["Todos", "02 CITYONLINE", "03 CITYONLINE", "04 CITYONLINE", "05 CITYONLINE", "06 CITYONLINE", "07 CITYONLINE", "08 CITYONLINE", "09 CITYONLINE", "10 CITYONLINE", "11 CITYONLINE", "12 CITYONLINE", "13 CITYONLINE", "14 CITYONLINE", "15 CITYONLINE", "16 CITYONLINE", "17 CITYONLINE", "18 CITYONLINE", "19 CITYONLINE", "20 CITYONLINE"];


export default function Monitor() {
    const firstRender = useRef(true)

    const {
        terminals: users,
        consortiums: consorcios,
        loading,
        getTerminalsByOwner,
        monitor : monitors
    } = useContext(ReportsContext)

    const [dataTable, setDataTable] = useState({ monitor: [], total: [] })
    const [fetchQuery, setFetchQuery] = useState({ pos: '', consortium: '', date: moment().format('YYYY-MM-DD') });

    useEffect(() => {
        if ( firstRender.current ) return;
        getTerminalsByOwner(fetchQuery.consortium).catch(e => console.log(e));
    }, [fetchQuery.consortium])
    const [render, setRender] = useState(true)

    useEffect(() => {
        setRender(false)
        firstRender.current = false
    }, [])




    const handlerOnChange = (e) => {
        setFetchQuery({ ...fetchQuery, [e.target.name]: e.target.value });
    }
    const refreshTable = async () => {
        if (render &&  !loading) {
               setDataTable(monitors) 
        }else {
            try {
                const res = await redApi.get(`/reports/getmonitor?date=${fetchQuery.date}&pos=${fetchQuery.pos}&consortium=${fetchQuery.consortium}`);
                setDataTable(res.data);
              } catch (err) {
                console.error(err);
              }
        }
      };
      
    useEffect(() => {
        refreshTable();
    }, [fetchQuery]);

    return (

        <div className="container-fluid p-0  m-0">
            <div className="row m-0 p-0">
                <div className="col-12  m-0 p-0 bg-gray-800 py-4">
                    <div className="row m-0 p-0 gap-3">
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center">
                                            <label className="m-0 p-0">POS</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name="pos" onChange={(e) => handlerOnChange(e)}>
                                                <option value="">Todos</option>
                                                {users.map((pos, index) => (
                                                    <option key={index} value={pos._id}>{pos.username}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center">
                                            <label className="m-0 p-0">Consorcio</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <select className="form-control" name="consortium" onChange={(e) => handlerOnChange(e)}>
                                                <option value="">Todos</option>
                                                {consorcios.map((cons, index) => (
                                                    <option key={index} value={cons._id}>{cons.username}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 m-0 p-0 d-flex flex-column gap-2">
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center">
                                            <label className="m-0 p-0">Fecha</label>
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="date" className="form-control" id="monitorDate" name="date" onChange={(e) => handlerOnChange(e)} value={ fetchQuery.date } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
                                        <div className="col-4 m-0 d-flex justify-content-start align-items-center">
                                        </div>
                                        <div className="col-8 m-0 p-0 d-flex justify-content-center align-items-center">
                                            <input type="button" value="Refrescar" onClick={refreshTable} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 py-4" id="table_container">
                    <div className="table-responsive" style={{maxHeight: "65vh"}}>
                        <table className="table table-striped table-hover" style={{ minHeight: "30px" }}>
                            <thead>
                                <tr>
                                    <th scope="col">Consorcio</th>
                                    <th scope="col">POS</th>
                                    <th scope="col">Venta</th>
                                    <th scope="col">Tickets</th>
                                    <th scope="col">Riesgo</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataTable.monitor.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.consorcio}</td>
                                        <td>{data.pos}</td>
                                        <td>{data.venta}</td>
                                        <td>{data.tickets}</td>
                                        <td className={getRedValueClassName(data.riesgo > 0)}>{data.riesgo}</td>
                                        <td>{data.total}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="2">Total</td>
                                    <td>${dataTable.total.venta}</td>
                                    <td>{dataTable.total.tickets}</td>
                                    <td className={getRedValueClassName(dataTable.total.riesgo > 0)}>${dataTable.total.riesgo}</td>
                                    <td>${dataTable.total.total}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}
