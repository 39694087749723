
export const playTypesDictionary = {
    "moneyLine": "M",
    // "runLine": "R",
    "price": "R",
    "posMinus": "-",
    "posPlus": "+",
    "singlePlus": "S+",
    "singleMinus": "S-",
    "superRunLine": "P",
    "altRunLine": "AR",
    "h1Rl": "H",
    "h1": "MH",
    "sia": "Y",
    "noa": "N",
    "firstRun": "F",
    "strikeoutsPlus": "K+",
    "strikeoutsMinus": "K-",
    "third": "T",
    "moneyLineT3": "T+",
    "moneyLineT3": "T-",
    "draw": "E",
    "RunLineQ1": "Q1",
    "RunLineQ2": "Q2",
    "RunLineQ3": "Q3",
    "RunLineQ4": "Q4",
    "priceQ1Over": "Q1+",
    "priceQ2Over": "Q2+",
    "priceQ3Over": "Q3+",
    "priceQ4Over": "Q4+",
    "priceQ1Under": "Q1-",
    "priceQ2Under": "Q2-",
    "priceQ3Under": "Q3-",
    "priceQ4Under": "Q4-",
    "priceQ1SOver": "Q1S+",
    "priceQ2SOver": "Q2S+",
    "priceQ3SOver": "Q3S+",
    "priceQ4SOver": "Q4S+",
    "priceQ1SUnder": "Q1S-",
    "priceQ2SUnder": "Q2S-",
    "priceQ3SUnder": "Q3S-",
    "priceQ4SUnder": "Q4S-",
    "runLineT1": "T1",
    "runLineT2": "T2",
    "runLineT3": "T3",
    "priceT1Over": "T1+",
    "priceT2Over": "T2+",
    "priceT3Over": "T3+",
    "priceT1Under": "T1-",
    "priceT2Under": "T2-",
    "priceT3Under": "T3-",
    "moneyLineT1": "MT1",
    "moneyLineT2": "MT2",
    "moneyLineT3": "MT3",
    "moneyLineT3": "HR2",
    "HOver": "H+",
    "HUnder": "H-",
    "H2Over": "H2+",
    "H2Over": "H2-",
    "HSOver": "H1S+",
    "HSUnder": "H1S-",
    "H2SOver": "H2S+",
    "H2SUnder": "H2S-",
    "j1": "J1",
    "j2": "J2",
    "j3": "J3",
    "j4": "J4",
    "j5": "J5",
    "buyPoints": "C",
    "buyPoints": "C+",
    "buyPoints": "C-",
}

export const playTypesHeadDictionary = {
    "MONEY": "M",
    "ML": "M",
    "GAVELA R/L": "R",
    "PRECIO R/L": "R",
    "TOTAL": "P",
    "SOLO (+)": "S+",
    "SOLO (-)": "S-",
    "GAVELA RL/H": "H",
    "TOTAL H": "H2",
    "SOLO (+) H": "H1S+",
    "SOLO (-) H": "H1S-",
    "SI": "Y",
    "NO": "N",
    "altGavela": "AR", 
}