import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { redApi } from "../../config/Axios";
// import { useHyRStore } from "../hooks/useGamesRender";

export const HyRContext = createContext();

export const HyRProvider = ({ children }) => {
  // const { gamesRender_ } = useHyRStore();
  const liga = useLocation();
  const ligaName = liga.pathname.split("/").at(-1);
  const table = liga.search.split("=")[1];

  const [leagueActive, setLeagueActive] = useState("");
  const [onLoading, setOnLoading] = useState(false);
  //   const [leaguesWithOutGames, setLeaguesWithOutGames] = useState([]);
  const [leaguesWithGames, setLeaguesWithGames] = useState([]);
  const [ligaActiva, setLigaActiva] = useState("");
  const [reloadData, setReloadData] = useState(false);
  // useEffect(() => {
  //   if (!gamesRender_) return;
  //   let league_ = gamesRender_[0]?.league?.name;
  //   console.log({ league_ }, "league_");
  // }, [gamesRender_]);
  useEffect(() => {
    // change path and state with the league games

  }, [leagueActive]);

  const fetchLeagues = async () => {
    setOnLoading(true);
    try {
      const { data } = await redApi.get("/leagues?leaguesWithGames=true");
      
      const leaguesWithGames = data.leaguesWithGames.map((league) => {
        league.image = `${process.env.REACT_APP_SERVER_URL}/LeagueImages/${league.name.replace('/', '_')}.png`;
        return league;
      });
      // const leaguesWithoutGames = data.leaguesWithoutGames.map((league) => {
      //     league.image = `${process.env.REACT_APP_SERVER_URL}/LeagueImages/${league.name}.png`;
      //     return league;
      // });
      setLeaguesWithGames(leaguesWithGames);
      // setLeaguesWithOutGames(leaguesWithoutGames);
    } catch (error) {
      console.log(error);
    }
    setOnLoading(false);
  }
  useEffect(() => {
    fetchLeagues();
  }, [reloadData]);



  return (
    <HyRContext.Provider
      value={{
        // leaguesWithOutGames,
        leaguesWithGames,
        onLoading,
        setLeagueActive,
        ligaName,
        table,
        ligaActiva,
        reloadData,
        setReloadData,

      }}
    >
      {children}
    </HyRContext.Provider>
  );
};
