import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { redApi } from '../../../../../config/Axios'

const SalesReport = () => {
  const [salesReport, setSalesReport] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState({
    initDate: moment().startOf('day').format("YYYY-MM-DD"),
    finalDate: moment().endOf('day').format("YYYY-MM-DD"),
  })

  const getSalesReport = async () => {
    setIsLoading(true)
    document.querySelector('.ant-modal-body').classList.add('loading')
    try {
      const { data } = await redApi.get(`/salesReport?initDate=${filters.initDate}&finalDate=${filters.finalDate}`)
      setSalesReport(data)
      console.log(data)
      setIsLoading(false)
      document.querySelector('.ant-modal-body').classList.remove('loading')
    } catch (error) {
      console.log(error)
    }
  }

  const changeFilter = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    getSalesReport()
  }, [filters])

  return (
    <>
      <div className='sales-report-loading'>
        <div className="container">
          <div className="dot dot-1"></div>
          <div className="dot dot-2"></div>
          <div className="dot dot-3"></div>
        </div>
      </div>
      <div className='sales-report-date'>
        <div>
          <input type="date" data-date="" data-date-format="DD MMMM YYYY" defaultValue={moment().startOf('day').format("YYYY-MM-DD")} name='initDate' onChange={changeFilter} />
          <span>
            to
          </span>
          <input type="date" data-date="" data-date-format="DD MMMM YYYY" defaultValue={moment().endOf('day').format("YYYY-MM-DD")} name='finalDate' onChange={changeFilter} />
        </div>
        <button onClick={getSalesReport}>
          Show Reports
        </button>
      </div>
      <div>
        <h3>
          Balance pos
        </h3>
        <hr />
      </div>
      <div className='sales-report-tables'>
        <div>
          <table>
            <thead>
              <tr>
                <th colSpan={4}>
                  POS TICKET REPORT
                </th>
              </tr>
              <tr>
                <th>
                  pending
                </th>
                <th>
                  winner
                </th>
                <th>
                  loser
                </th>
                <th>
                  total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {
                    salesReport.terminalInfo?.pending
                  }
                </td>
                <td>
                  {
                    salesReport.terminalInfo?.winner
                  }
                </td>
                <td>
                  {
                    salesReport.terminalInfo?.loser
                  }
                </td>
                <td>
                  {
                    salesReport.terminalInfo?.total
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th colSpan={4}>
                  WEB TICKETS REPORT
                </th>
              </tr>
              <tr>
                <th>
                  pending
                </th>
                <th>
                  winner
                </th>
                <th>
                  loser
                </th>
                <th>
                  total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {
                    salesReport.webUserInfo?.pending
                  }
                </td>
                <td>
                  {
                    salesReport.webUserInfo?.winner
                  }
                </td>
                <td>
                  {
                    salesReport.webUserInfo?.loser
                  }
                </td>
                <td>
                  {
                    salesReport.webUserInfo?.total
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='sales-report-tables'>
        <div>
          <table>
            <thead>
              <tr>
                <th>
                  TKS
                </th>
                <th>
                  pos sales
                </th>
                <th>
                  deposits
                </th>
                <th>
                  pos winners
                </th>
                <th>
                  online winners
                </th>
                <th>
                  withdrawals
                </th>
                <th>
                  profits
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {
                    salesReport.generalInfo?.tks
                  }
                </td>
                <td>
                  ${
                    salesReport.generalInfo?.posSales.toFixed(2)
                  }
                </td>
                <td>
                  ${
                    salesReport.generalInfo?.deposits.toFixed(2)
                  }
                </td>
                <td>
                  ${
                    salesReport.generalInfo?.posWinners.toFixed(2)
                  }
                </td>
                <td>
                  ${
                    salesReport.generalInfo?.posOnlineWinners.toFixed(2)
                  }
                </td>
                <td>
                  ${
                    salesReport.generalInfo?.WithDrawals.toFixed(2)
                  }
                </td>
                <td>
                  ${
                    salesReport.generalInfo?.profits.toFixed(2)
                  }
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  total:
                </td>
                <td colSpan={2}>
                  ${
                    (salesReport.generalInfo?.posSales + salesReport.generalInfo?.deposits).toFixed(2)
                  }
                </td>
                <td>
                  ${
                    salesReport.generalInfo?.posWinners.toFixed(2)
                  }
                </td>
                <td>
                  -
                </td>
                <td>
                  ${
                    salesReport.generalInfo?.WithDrawals.toFixed(2)
                  }
                </td>
                <td>
                  ${
                    salesReport.generalInfo?.profits.toFixed(2)
                  }
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      {/* <div>
        <h3>
          CREDITS BALANCE
        </h3>
        <hr />
      </div>
      <div className='sales-report-tables'>
        <div>
          <table>
            <thead>
              <tr>
                <th>
                  Total credits
                </th>
                <th>
                  paid in cash
                </th>
                <th>
                  paid balance
                </th>
                <th>
                  total debt
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  $0.00
                </td>
                <td>
                  $0.00
                </td>
                <td>
                  $0.00
                </td>
                <td>
                  $0.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
      {/* <div>
        <h3>
          BONUS GRANTED
        </h3>
        <hr />
      </div>
      <div className='sales-report-tables'>
        <div>
          <table>
            <thead>
              <tr>
                <th>
                  TOTAL BONUS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  $0.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
    </>
  )
}

export default SalesReport