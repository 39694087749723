import React, { useEffect, useState } from 'react'
import LineHome from '../../../../../public/home/LineHome'
import { redApi } from '../../../../../../config/Axios'
import moment from 'moment'
import { matchupLeagues } from '../../../../../../utils/matchUpLeagues'
import "./styles/showodds.scss"
const ShowOdds_t4_mobile = () => {
  const [leagueSelected, setLeagueSelected] = useState(null)
  const [games, setGames] = useState([])
  const [leagues, setLeagues] = useState([])


  useEffect(() => {
    let leagueName;
    redApi.get('/leagues?leaguesWithGames=true')
      .then(res => {
        setLeagues(res.data)
        console.log(res.data, "res.data")
        leagueName = res.data[0].name
        setLeagueSelected(res.data[0])

        redApi.get(matchupLeagues.includes(leagueName)
          ? `/games?leagueName=${leagueName}&date=${moment().format("YYYY-MM-DD")}&matchUps=true`
          : `/games?leagueName=${leagueName}&date=${moment().format("YYYY-MM-DD")}`)
          .then(res => {
            console.log({ allgames: res.data })
            setGames(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err)
      })

  }, [])
  return (
    <div className="menu_container">
      <div className="line-home-container__leagues">
        {
          leagues?.map((league, index) => {
            return (
              <div
                key={index}
                className={`league ${leagueSelected?.name === league.name ? "active" : ""}`}
                onClick={() => {
                  setLeagueSelected(league)
                  redApi.get(`/games?leagueName=${league.name}&date=${moment().format("YYYY-MM-DD")}`)
                    .then(res => {
                      setGames(res.data)
                    })
                    .catch(err => {
                      console.log(err)
                    })
                }}
              >
                <span>{league.name}</span>
              </div>
            )
          })
        }
      </div>
      <div className="line-home-container__table">
        {
          leagueSelected?.lineType[0]?.periods?.map((period, index) => {
            if (period.period === "Completo / 1ra Mitad ") {
              return null
            }
            return (
              <div key={index}
                id='table-dinamic-mobile'
              >
                {
                  matchupLeagues.includes(leagueSelected.name) ?
                    <table style={{
                      textAlign: "center",
                      margin: "0",
                      width: "100%",
                    }}
                    >
                      <thead>
                        <tr>
                          <th colSpan={6}>
                            <span>
                              {
                                `${leagueSelected.name}: ${period.period}`
                              }
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={2}>
                            <div className='d-flex justify-content-around align-items-center'>
                              <h4>Hora</h4>
                              <h4>Juegos Actuales</h4>
                            </div>
                          </th>
                          <th colSpan={1}>
                            <h4>Categoría</h4>
                          </th>
                          {
                            period.lines?.slice(3, 6).map((line, index) => {
                              console.log({ line })
                              return (
                                <th key={index}
                                  style={{
                                    padding: "0 2px",
                                  }}
                                >{line.name}</th>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          games?.map((game, indexGame) => {
                            return (
                              <tr className="tr_matchups" key={indexGame} onDoubleClick={() => handleVisibleForGames(game)}>
                                <td colSpan={2}>
                                  <div className="d-flex gap-2">
                                    <div className="d-flex flex-column ps-1">
                                      <div
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {moment(game.time).format("MM/DD")}
                                      </div>
                                      <div style={{
                                        fontSize: "12px",
                                        fontWeight: "900",
                                        whiteSpace: "nowrap"
                                      }}>
                                        {moment(game.time).format("hh:mm A")}
                                      </div>
                                    </div>
                                    <div
                                      className={`d-flex flex-column px-1 games_container ${game.status}`}
                                      onClick={() => handleStatusGame(game._id,
                                        game.status === "pending"
                                          ? "approved"
                                          : game.status === "approved"
                                            ? "playing"
                                            : game.status === "playing"
                                              ? "finished"
                                              : game.status === "finished"
                                                ? "pending"
                                                : game.status)}
                                    >
                                      <div className="d-flex align-items-center gap-1">
                                        <img src={game?.player?.image} alt={game?.player?.name} />
                                        {" | "}
                                        <span>
                                          {localStorage.getItem('userCodesTeams') == 'rd' ? game?.player?.codeRD : game?.player?.code}
                                        </span>
                                        {" - "}
                                        <span>
                                          {`${game?.player?.name}`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td key={indexGame} className={`tr_matchups ${game.status}`}>
                                  <div className="d-flex align-items-center gap-2 container_inputs container_inputs_matchups">
                                    <span>
                                      {`${game.matchType}`}
                                    </span>
                                  </div>
                                </td>

                                {
                                  period.lines?.slice(3, 6).map((item, index) =>
                                    <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>
                                      <div className="container_inputs container_inputs_matchups">
                                        <div className={`${(game.playerLine[item?.property]?.isManual) ? "manual" : game.playerLine[item?.property]?.status} `}

                                        >
                                          <input type="text" value={game.playerLine[item.property]?.value} onChange={(e) =>
                                            lineahandleChange(
                                              e,
                                              index = indexGame,
                                              "playerLine",
                                              item.property
                                            )}
                                            onClick={() => lineahandleClick(game.playerLine._id,
                                              item.property,
                                              game.playerLine[item.property]?.value,
                                              "playerLine"
                                            )}
                                            style={{
                                              color: (game.playerLine[item.property]?.isManual) ? "white" : "black"
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  )
                                }
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    : <table
                      style={{
                        textAlign: "center",
                        margin: "0",
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th colSpan={6}>
                            <span>
                              {
                                `${leagueSelected.name}: ${period.period}`
                              }
                            </span>
                          </th>
                        </tr>
                        <tr style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          color: "#333",
                        }}>
                          <th>Game</th>
                          <th>Cod</th>
                          <th>Teams</th>
                          {
                            period.lines?.slice(0, 3).map((line, index) => {
                              console.log({ line })
                              return (
                                <th key={index}
                                  style={{
                                    padding: "0 2px",
                                  }}
                                >{line.name}</th>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          games?.map((game, index) => {
                            cons
                            return (
                              <tr key={index}
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                              >
                                <td>
                                  <div
                                  >
                                    <div>
                                      {moment(game.time).format("h:mm A")}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <div style={{
                                    }}>
                                      {game.localTeam.codeRD}
                                    </div>
                                    <div style={{
                                    }}>
                                      {game.visitingTeam.codeRD}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <div>
                                      <div style={{
                                        width: "100%",
                                        textAlign: "start",
                                        paddingLeft: "10px"
                                      }}>
                                        {game.localTeam.name}
                                      </div>
                                    </div>
                                    <div>
                                      <div style={{
                                        width: "100%",
                                        textAlign: "start",
                                        paddingLeft: "10px"
                                      }}>
                                        {game.visitingTeam.name}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                {
                                  period.lines?.slice(0, 3).map((item, index) => (
                                    <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>
                                      <div className="container_inputs">
                                        <div
                                          style={{
                                            color: game.localLine[item.property]?.value > 0 ? "#022e01" : "#2e0101"
                                          }}
                                        >
                                          {game.localLine[item.property]?.value}
                                        </div>
                                        <div
                                          style={{
                                            color: game.visitingLine[item.property]?.value > 0 ? "#022e01" : "#2e0101"
                                          }}
                                        >
                                          {game.visitingLine[item.property]?.value}
                                        </div>
                                      </div>
                                    </td>
                                  ))
                                }
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                }

              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default ShowOdds_t4_mobile